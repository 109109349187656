import { Button } from "antd";
import React, { useEffect } from "react";
import styled from "styled-components";
import useLocalStorage from "../hooks/useLocalStorage";
import worker from "../mockServer";
const StyledContainer = styled.div`
  position: fixed;
  bottom: 0.5em;
  left: 4em;
`;
export default function MSWToggleButton() {
  const { storedValue, setValue } = useLocalStorage<boolean>("msw", false);
  useEffect(() => {
    storedValue ? worker.start() : worker.stop();
  });
  const onClick = () => {
    storedValue ? setValue(false) : setValue(true);
  };
  return (
    <>
      {process.env.NODE_ENV === "development" && (
        <StyledContainer>
          <Button
            onClick={onClick}
            style={{
              backgroundColor: storedValue ? "green" : "gray",
              color: "white",
            }}
          >
            {storedValue ? "Mocking Enabled" : "Mocking Disabled"}
          </Button>
        </StyledContainer>
      )}
    </>
  );
}
