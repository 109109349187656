import { useMutation, useQueryClient } from "react-query";
import { HOME_PAGE_ORDER_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { putData } from "../../../utils/axiosHelpers";
import { HomePageCardOrder } from "../HomePageTypes";
import { KEY } from "./useHomePageOrder";

export default function useEditHomePageOrder() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    (values) => putData(HOME_PAGE_ORDER_URL, { cards: values }),
    {
      onMutate: async (newHomePageOrder: any) => {
        await queryClient.cancelQueries(KEY);

        const previousCardOrder: any = queryClient.getQueryData(KEY);

        const CALL_REASON = `Save changes to playlist`;

        queryClient.setQueryData(KEY, { cards: newHomePageOrder });

        return {
          previousCardOrder,
          CALL_REASON,
        };
      },
      onSuccess: (data, _, context) => {
        onSuccessNotification(data, context?.CALL_REASON);
      },
      onError: (data, _, context) => {
        onErrorNotification(data, context?.CALL_REASON);
        if (context?.previousCardOrder) {
          queryClient.setQueryData<HomePageCardOrder>(
            [KEY],
            context.previousCardOrder
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(KEY);
      },
    }
  );
}
