import { useMutation, useQueryClient } from "react-query";
import { SCHEDULE_RUN_REPORT_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { putData } from "../../../utils/axiosHelpers";
import { Report } from "../ReportsPageTypes";
import { KEY } from "./useReports";

export default function useScheduledReport() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation((values) => putData(SCHEDULE_RUN_REPORT_URL, values), {
    onMutate: async (reportToSchedule: any) => {
      await queryClient.cancelQueries(KEY);
      const CALL_REASON = `Scheduled Report ${reportToSchedule.name}`;
      const previousReportList: any = queryClient.getQueryData(KEY);

      const newReportList = previousReportList.reports.map((report: Report) => {
        if (reportToSchedule.id === report.id) {
          const { schedulable, ...reportRest } = report;
          return { ...reportRest, schedulable: true };
        }
        return report;
      });

      queryClient.setQueryData(KEY, {
        reports: newReportList,
      });

      return {
        CALL_REASON,
      };
    },
    onSuccess: (data, _, context) => {
      onSuccessNotification(data, context?.CALL_REASON);
    },
    onError: (data, _, context) => {
      onErrorNotification(data, context?.CALL_REASON);
    },

    onSettled: (data, _, context) => {
      queryClient.invalidateQueries(KEY);
    },
  });
}
