import { useContext } from "react";
import { NotificationsContext } from "../context/NotificationsContext";

export default function useNotifications() {
  const { updateNotifications } = useContext(NotificationsContext);

  const onSuccessNotification = (data: any, callReason?: string) => {
    const { message } = data;

    const notificationObject: any = {
      message: callReason,
      description: message,
      placement: "bottomRight",
    };

    updateNotifications((lastValue) => {
      return [
        ...lastValue,
        { ...notificationObject, type: "success", timestamp: Date() },
      ];
    });
  };

  const onErrorNotification = (error: any, callReason?: string) => {
    const message = error.response.data.message
      ? error.response.data.message
      : error.message;

    const notificationObject = {
      message: callReason,
      description: message,
    };

    updateNotifications((lastValue) => [
      ...lastValue,
      { ...notificationObject, type: "error", timestamp: Date() },
    ]);
  };

  return {
    onSuccessNotification,
    onErrorNotification,
  };
}
