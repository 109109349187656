import React from "react";

export default function DesktopSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 55 55"
    >
      <g>
        <g>
          <path
            fill="#0b89ff"
            d="M0 18C0 8.059 8.059 0 18 0h19c9.941 0 18 8.059 18 18v19c0 9.941-8.059 18-18 18H18C8.059 55 0 46.941 0 37z"
          />
        </g>
        <g>
          <g>
            <g>
              <g>
                <path
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="20"
                  stroke-width="2"
                  d="M12 16.5a2 2 0 0 1 2-2h26a2 2 0 0 1 2 2v17a2 2 0 0 1-2 2H14a2 2 0 0 1-2-2z"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="20"
                  stroke-width="2"
                  d="M21 41.5h12"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="20"
                  stroke-width="2"
                  d="M27 35.5v6"
                />
              </g>
            </g>
          </g>
          <g />
        </g>
      </g>
    </svg>
  );
}
