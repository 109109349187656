import React, { useContext } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, MemoryRouter } from "react-router-dom";
import { Routes } from "../components/Routes";
import {
  LanguageContext,
  LanguageProvider,
  MessageProvider,
  WithUsersContext,
} from "../context";
import WithBackground from "./withBackground";
import WithNagiosContext from "./withNagiosContext";
import { WithRouterContext } from "./withRouterContext";

interface Props {
  children: JSX.Element[] | JSX.Element;
  language?: string;
  route?: string;
}

interface WithBackgroundProps extends Props {
  color?: string;
}

const queryClient = new QueryClient();
export function WithContext({ children, language = "en", route }: Props) {
  const { updateLanguage } = useContext(LanguageContext);

  if (language !== "en") updateLanguage(language);

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <MemoryRouter initialEntries={[route ? route : "/"]}>
          <LanguageProvider>
            <MessageProvider>
              <WithNagiosContext>
                <WithUsersContext>
                  <WithRouterContext>
                    {children}
                    <Routes />
                  </WithRouterContext>
                </WithUsersContext>
              </WithNagiosContext>
            </MessageProvider>
          </LanguageProvider>
        </MemoryRouter>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export function WithContextAndBackground({
  children,
  language,
  color,
}: WithBackgroundProps) {
  return (
    <WithBackground color={color ? color : "#EDF2F9"}>
      <WithContext language={language}>{children}</WithContext>
    </WithBackground>
  );
}
