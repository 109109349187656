import { Select } from "antd";
import React from "react";
const { Option } = Select;

export type Props = {
  data: any[];
  filterBy: string;
  listOf: string;
  onChange?: any;
};

export default function Dropdown({ data, filterBy, listOf, onChange }: Props) {
  return (
    <Select
      placeholder={`${data.length.toString()} ${listOf}s available`}
      onChange={onChange}
    >
      {data.map((row, i) => (
        <Option value={row[filterBy]} key={i}>
          {row[filterBy]}
        </Option>
      ))}
    </Select>
  );
}
