import { CardData } from "./HomePageCardData";
import styled from "styled-components";
import React from "react";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import IconButton from "../../../Shared/Molecules/IconButton/IconButton";
import IconCard from "../../../Shared/Atoms/IconCard";
import { useHistory } from "react-router-dom";

const FlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StartMonitoring = () => {
  const history = useHistory();
  return (
  <FlexBox>
    {CardData.startMonitoring.map(
      (data): JSX.Element => (
        <IconButton
          onClick={() => history.push(data.link)}
          color1={data.color1}
          icon={data.icon}
          label={data.label}
          alert={data.alert}
          num={data.num}
          text={data.text}
          key={data.label}
        />
      )
    )}
  </FlexBox>
  );
};

export const SetupHostsServices = () => {
  const theme = useMasterTheme().theme;
  const history = useHistory();

  const cards = CardData.setupHostsServices.filter((el) => el.type === "card");
  const buttons = CardData.setupHostsServices.filter(
    (el) => el.type === "button"
  );

  return (
    <>
      <FlexBox>
        {cards.map((data) => (
          <IconCard
            onclick={() => history.push(data.link)}
            color={eval(data.color1)}
            icon={data.icon}
            title={data.title ? data.title : ""}
            description={data.description ? data.description : ""}
            sub={data.sub}
            label={data.label}
            key={data.label}
          />
        ))}
      </FlexBox>
      <FlexBox>
        {buttons.map((data) => (
          <IconButton
            onClick={() => history.push(data.link)}
            color1={eval(data.color1)}
            color2={data.color2 ? data.color2 : null}
            icon={data.icon}
            label={data.label}
            alert={data.alert}
            num={data.num}
            text={data.text}
            key={data.label}
          />
        ))}
      </FlexBox>
    </>
  );
};

export const ViewHostsServices = () => {
  const theme = useMasterTheme().theme;
  const history = useHistory();

  return (
    <FlexBox>
      {CardData.ViewHostsServices.map((data) => (
        <IconButton
          onClick={() => history.push(data.link)}
          color1={eval(data.color1)}
          color2={data.color2 ? data.color2 : null}
          icon={data.icon}
          label={data.label}
          alert={data.alert}
          num={data.num}
          text={data.text}
          key={data.label}
        />
      ))}
    </FlexBox>
  );
};

export const MostViewed = () => {
  const theme = useMasterTheme().theme;
  const history = useHistory();

  return (
    <FlexBox>
      {CardData.MostVisited.map((data) => (
        <IconButton
          onClick={() => history.push(data.link)}
          color1={eval(data.color1)}
          icon={data.icon}
          label={data.label}
          alert={data.alert}
          num={data.num}
          text={data.text}
          key={data.label}
        />
      ))}
    </FlexBox>
  );
};

export const ViewHostGroupsServiceGroups = () => {
  const theme = useMasterTheme().theme;
  const history = useHistory();

  return (
    <FlexBox>
      {CardData.ViewHostgroupsServicegroups.map((data) => (
        <IconButton
          onClick={() => history.push(data.link)}
          color1={eval(data.color1)}
          color2={data.color2 ? data.color2 : null}
          icon={data.icon}
          label={data.label}
          alert={data.alert}
          num={data.num}
          text={data.text}
          key={data.label}
        />
      ))}
    </FlexBox>
  );
};

export const Administration = () => {
  const theme = useMasterTheme().theme;
  const history = useHistory();

  return (
    <FlexBox>
      {CardData.Administration.map((data) => (
        <IconButton
          onClick={() => history.push(data.link)}
          color1={eval(data.color1)}
          icon={data.icon}
          label={data.label}
          alert={data.alert}
          num={data.num}
          text={data.text}
          key={data.label}
        />
      ))}
    </FlexBox>
  );
};
