import { Button } from "antd";
import styled from "styled-components";
import React from "react";
import { IconCheck } from "../../../Shared/Atoms/FontIcons/FontIcons";
import CustomTable from "../../../Shared/Organisms/CustomTable/CustomTable";

const StyledTable = styled(CustomTable)`
  & table {
    table-layout: fixed !important;
    width: 100%;
    & tr > :last-child {
      text-align: right;
    }
  }
`;

export type DashboardProps = {
  dashboardData: {
    name: string;
    added: boolean;
    dashboardId: number;
  }[];
  addBtnClicked: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  removeBtnClicked: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const MyDashboardTable = (props: any) => {
  const columns = [
    {
      title: "name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "button",
      render: ({ added, name }) => (
        <Button
          block
          type={added ? "primary" : "default"}
          onClick={() =>
            added
              ? props.removeBtnClicked(name, added)
              : props.addBtnClicked(name, added)
          }
        >
          {added ? (
            <>
              <IconCheck size="1.4em" /> Added
            </>
          ) : (
            "Add to Playlist"
          )}
        </Button>
      ),
    },
  ];

  return (
    <StyledTable
      dataSource={props.dashboardData}
      columns={columns}
      pagination={false}
      showHeader={false}
    />
  );
};

export default MyDashboardTable;
