import { useMutation, useQueryClient } from "react-query";
import { PLAYLIST_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { putData } from "../../../utils/axiosHelpers";
import { Playlist, Playlists } from "../ViewsPageTypes";
import { KEY } from "./usePlaylists";

type MutateProps = {
  playlistToEdit: Playlist;
  newTime: number;
};

export default function useUpdateDurationToGlobal() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    ({ playlistToEdit, newTime }) =>
      putData(`${PLAYLIST_URL}/${playlistToEdit.playlist_id}`, {
        ...playlistToEdit,
        play_duration: newTime,
      }),
    {
      onMutate: async ({ playlistToEdit, newTime }: MutateProps) => {
        const { playlist_id } = playlistToEdit;

        await queryClient.cancelQueries(KEY);

        const previousPlaylists: any = queryClient.getQueryData(KEY);

        const CALL_REASON = `Edit Playlist ${playlistToEdit.name}`;

        const newPlaylists = previousPlaylists.playlists.map(
          (playlist: Playlist) => {
            if (playlist.playlist_id === playlist_id) {
              const { screens, ...playlistRest } = playlist;

              const updatedViews = Object.assign(
                {},
                ...Object.values(screens).map((value) => {
                  const { play_duration, play_order, ...valueRest } = value;
                  return {
                    [play_order]: {
                      play_order,
                      play_duration: newTime,
                      ...valueRest,
                    },
                  };
                })
              );

              return { ...playlistRest, screens: updatedViews };
            }
            return playlist;
          }
        );

        queryClient.setQueryData(KEY, {
          playlists: newPlaylists,
        });

        return {
          previousPlaylists,
          newPlaylists,
          CALL_REASON,
        };
      },
      onSuccess: (data, _, context) => {
        onSuccessNotification(data, context?.CALL_REASON);
      },
      onError: (data, _, context) => {
        onErrorNotification(data, context?.CALL_REASON);
        if (context?.previousPlaylists) {
          queryClient.setQueryData<Playlists>([KEY], context.previousPlaylists);
        }
      },

      onSettled: () => {
        queryClient.invalidateQueries(KEY);
      },
    }
  );
}
