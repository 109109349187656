import { Col, Row } from "antd";
import React from "react";
import { LoginForm, LoginPageDialogBox } from "./Organisms";
import { useMasterTheme } from "../../context/MasterThemeContext";
import styled from "styled-components";

export default function LoginPage() {
  const theme = useMasterTheme().theme;
  const Page = styled.div`
    background-color: ${theme.bodyBackgroundColor};
    padding: 100px 0;
  `;

  return (
    <Page>
      <Row justify="center">
        <Col>
          <LoginForm />
          <LoginPageDialogBox />
        </Col>
      </Row>
    </Page>
  );
}
