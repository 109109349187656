import React, { memo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { default as ReactIframe } from "react-iframe";
import styled from "styled-components";

export type Props = {
  url: string;
};

interface LocationParams {
  params: any;
}

interface Filter {
  filter: string;
}

const specialCases = {
  "/status/services/ok": { servicestatustypes: 2 },
  "/status/services/warning": { servicestatustypes: 4 },
  "/status/services/unknown": { servicestatustypes: 8 },
  "/status/services/critical": { servicestatustypes: 16 },
  "/status/services/pending": { servicestatustypes: 1 },
  "/status/services/unhandled": {
    servicestatustypes: 28,
    serviceattr: 10,
  },
  "/status/services/problems": { servicestatustypes: 28 },
  "/status/hosts/up": { hoststatustypes: 2 },
  "/status/hosts/down": { hoststatustypes: 4 },
  "/status/hosts/unreachable": { hoststatustypes: 8 },
  "/status/hosts/pending": { hoststatustypes: 1 },
  "/status/hosts/unhandled": { hoststatustypes: 12, hostattr: 10 },
  "/status/hosts/problems": { hoststatustypes: 12 },
  "/config/wizards/servers": { },
  "/config/wizards/network": { },
  "/config/wizards/desktop": { },
  "/config/wizards/website": { },
};

// TODO: Clean up...
//
const StyledIframe = styled(ReactIframe)`
  display: block;
  width: 100%;
  border: none;
  height: calc(100vh);
  overflow-y: scroll;
`;

const MemoIframe = memo(({ url }: { url: string }) => (
  <StyledIframe url={url} />
));

export default function Iframe({ url }: Props) {
  const { origin } = window.location;
  let host = origin;
  let extra = "";
  if (process.env.REACT_APP_DEV_HOST) {
    host = `${window.location.protocol}//${process.env.REACT_APP_DEV_HOST}`;
  }

  // Add special params to the url
  // <Link to={{ pathname: "/path", state: { params: { someparam: 2 } } }}>
  const location = useLocation<LocationParams>();
  if (location.state?.params !== undefined) {
    Object.entries(location.state.params).forEach(([key, value]) => {
      let opt = url.indexOf("?") ? "&" : "?";
      extra += `${opt}${key}=${value}`;
    });
  }

  // Add special cases to params
  // TODO: maybe clean this up a better way? it works for now but not pretty...
  const { filter } = useParams<Filter>();
  let filters = specialCases[location.pathname];
  if (filter !== undefined && filters !== undefined) {
    Object.entries(filters).forEach(([key, value]) => {
      let opt = url.indexOf("?") ? "&" : "?";
      extra += `${opt}${key}=${value}`;
    });
  }

  const fullUrl = `${host}/${url}${extra}`;

  return <MemoIframe url={fullUrl} />;
}
