import React from "react";

export default function ManageUsersSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 55 55"
    >
      <g>
        <g>
          <path
            fill="#fde5e1"
            d="M0 18C0 8.059 8.059 0 18 0h19c9.941 0 18 8.059 18 18v19c0 9.941-8.059 18-18 18H18C8.059 55 0 46.941 0 37z"
          />
        </g>
        <g>
          <g>
            <g>
              <path d="M31 21a7 7 0 1 1-14 0 7 7 0 0 1 14 0zm-12 0a5 5 0 1 0 5-5z" />
            </g>
            <g>
              <path d="M34 42h-2v-5a5 5 0 0 0-5-5h-6a5 5 0 0 0-5 5v5h-2v-5a7 7 0 0 1 7-7h6a7 7 0 0 1 7 7z" />
            </g>
            <g>
              <path d="M34 16h10v2H34z" />
            </g>
            <g>
              <path d="M34 21h10v2H34z" />
            </g>
            <g>
              <path d="M34 26h7v2h-7z" />
            </g>
          </g>
          <g />
        </g>
      </g>
    </svg>
  );
}
