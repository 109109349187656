import React from "react";

export default function HostgroupsSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 55 55"
    >
      <g>
        <g>
          <path
            fill="#eceafb"
            d="M0 18C0 8.059 8.059 0 18 0h19c9.941 0 18 8.059 18 18v19c0 9.941-8.059 18-18 18H18C8.059 55 0 46.941 0 37z"
          />
        </g>
        <g>
          <g>
            <g>
              <path d="M18.16 23H30v18.88H18.16zm14 20V22a1.08 1.08 0 0 0-1.08-1.08h-14A1.08 1.08 0 0 0 16 22v21a1.08 1.08 0 0 0 1.08 1h14a1.08 1.08 0 0 0 1.08-1z" />
            </g>
            <g>
              <path d="M20.08 24.96h8v2h-8z" />
            </g>
            <g>
              <path d="M36.1 37.81h-2V19h-12v-2h13a1 1 0 0 1 1 1z" />
            </g>
            <g>
              <path d="M40.08 33h-2V15h-11v-2h12a1 1 0 0 1 1 1z" />
            </g>
            <g>
              <path d="M23.08 37.88h2v2.16h-2z" />
            </g>
          </g>
          <g />
        </g>
      </g>
    </svg>
  );
}
