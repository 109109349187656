import React, { useState } from "react";
import { Card, Button } from "antd";
import styled from "styled-components";
import usePlaylist from "./hooks/usePlaylists";
import useViewPageEvents from "./hooks/useViewsPageEvents";
import useCreateFavorite from "../Frame/hooks/useCreateFavorite";
import useCommonScreens from "./hooks/useCommonScreens";
import useMyDashboards from "./hooks/useMyDashboards";
import { useGlobalModal } from "../../context/GlobalModalContext";
import { useToggle } from "../../hooks";
import { IconTrash, IconAdd } from "../Shared/Atoms/FontIcons/FontIcons";
import ViewsFrame from "../Shared/Molecules/ViewsFrame/ViewsFrame";
import CustomTable, {
  ExpandButton,
} from "../Shared/Organisms/CustomTable/CustomTable";
import { AddAScreenMockData } from "./Molecules/AddAScreenModal/AddAScreenMockData";
import AddAScreenModal from "./Molecules/AddAScreenModal/AddAScreenModal";
import PlaylistNestedTable from "./Organisms/PlaylistNestedTable/PlaylistNestedTable";
import PageHeader from "../Shared/Organisms/PageHeader/PageHeader";
//import useAvailableScreens from "../Frame/hooks/useAvailableScreens";
const otherIdeas = AddAScreenMockData.otherIdeas;

const StyledBody = styled.div`
  padding: 20px;
  position: relative;
`;

export default function PlaylistPage(props) {
  const [selectedView, setSelectedView] = useState(0);
  const { open: screenModalOpen, toggleOpen: toggleScreenModalOpen } =
    useToggle();
  const { toggleAddAPlaylistModalOpen } = useGlobalModal();
  const { data: playlistData } = usePlaylist();
  //const { data: screens } = useAvailableScreens();

  //console.log(screens);

  const {
    onPlayButtonClick,
    onDeleteButtonClick,
    onDeletePlaylistButtonClick,
    onSetActiveToggle,
  } = useViewPageEvents();

  const { mutate: createFavorite } = useCreateFavorite();

  const commonScreen = useCommonScreens();
  const myDashboards = useMyDashboards();

  const showScreenModal = (view: number) => {
    toggleScreenModalOpen();
    setSelectedView(view);
  };

  const closeScreenModal = () => {
    toggleScreenModalOpen();
  };

  const [iframeVisible, setIframeVisible] = useState(false);

  const iframeSource = "https://nagios.com";

  const playPlaylist = () => {
    setIframeVisible(true);
    onPlayButtonClick();
  };

  const columns = [
    {
      title: "Playlist",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Modify",
      dataIndex: "id",
      key: "id",
      width: "150px",
      render: (id: number, record) => (
        <Button
          type="text"
          danger
          style={{ textAlign: "left", padding: 0 }}
          onClick={() => onDeletePlaylistButtonClick(record)}
        >
          <IconTrash size="1.5em" /> Delete
        </Button>
      ),
    },
  ];

  return (
    <StyledBody>
      <ViewsFrame
        iframeVisible={iframeVisible}
        viewsSource={iframeSource}
        hideIframe={() => setIframeVisible(false)}
      />
      <PageHeader
        title="Views"
        sub="An animated sequence of pre-selected or custom monitoring screens"
        expandClicked={props.expandClicked}
        settingsClicked={props.settingsClicked}
        infoClicked={props.infoClicked}
        favoriteClicked={() =>
          createFavorite({ title: "Views", url: "/views/playlists/manage/" })
        }
      />
      {[[playlistData ? playlistData : []], commonScreen, myDashboards].every(
        (list) => list.length >= 1
      ) && (
        <AddAScreenModal
          playlist={playlistData[selectedView]}
          visible={screenModalOpen}
          close={closeScreenModal}
          csData={commonScreen[selectedView]}
          dashboardData={myDashboards[selectedView]}
          oiData={otherIdeas}
        />
      )}

      <Card>
        <h3>Manage Playlists</h3>
        <CustomTable
          rowKey={"playlist_id"}
          pagination={false}
          dataSource={playlistData}
          columns={columns}
          expandable={{
            expandedRowRender: (record, i) => (
              <>
                <PlaylistNestedTable
                  record={playlistData[i]}
                  addScreen={() => showScreenModal(i)}
                  playlistData={Object.values(playlistData[i].screens)}
                  deleteBtn={(id: string | number) => {
                    const { screens, playlist_id: playlistId } =
                      playlistData[i];

                    onDeleteButtonClick(screens[`${id}`], playlistId);
                  }}
                  activeCheckbox={(id: number, activate: boolean) => {
                    onSetActiveToggle(activate, id, playlistData[i].id);
                  }}
                  play={() => playPlaylist()}
                />
              </>
            ),
            expandIcon: (props) => (
              <span onClick={(e) => props.onExpand(props.record, e)}>
                {ExpandButton(props.expanded)}
              </span>
            ),
            rowExpandable: () => {
              return true;
            },
          }}
        />

        <Button
          data-testid="AddAPLaylist"
          block
          onClick={() => {
            toggleAddAPlaylistModalOpen();
          }}
          style={{ height: "60px" }}
        >
          <IconAdd size="1.5em" /> Add a Playlist
        </Button>
      </Card>
    </StyledBody>
  );
}
