import { Button, Form, Modal, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";
import { Dashboard, Dashlet } from "../../../DashboardPage/DashboardPageTypes";
import useDashboards from "../../../DashboardPage/hooks/useDashboards";
import useDashletPageEvents from "../../hooks/useDashletPageEvents";
const { Option } = Select;

export type Props = {
  dashlet: Dashlet;
  dashboard: Dashboard;
  open: boolean;
  toggleOpen: Function;
};

export default function DuplicateDashletModal({
  dashlet,
  dashboard,
  open,
  toggleOpen,
}) {
  const { onDuplicateDashletButtonClick } = useDashletPageEvents(dashboard.id);
  const [form] = useForm();
  const { data: dashboards } = useDashboards({ name: "", params: "" });

  const onSubmit = (values) => {
    console.log(values);
    onDuplicateDashletButtonClick(dashlet, values.dashboardToCopyTo);
    toggleOpen();
  };
  return (
    <Modal
      visible={open}
      footer={[
        <Button key="back" onClick={() => toggleOpen()}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={() => form.submit()}
        >
          Submit
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item name="dashboardToCopyTo" label="Copy to Dashboard">
          <Select>
            {[...(dashboards ? dashboards : [])].map(
              (dashboard: Dashboard, i) => (
                <Option
                  value={dashboard.dashboard_id}
                  key={dashboard.dashboard_id}
                >
                  {dashboard.name}
                </Option>
              )
            )}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
