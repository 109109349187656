import { rest } from "msw";

const createMock = (
  url: string,
  method: string,
  status: number,
  dataReturn: object
) =>
  rest[method](url, async (req, res, ctx) => {
    return res(
      ctx.delay(method === "get" ? 0 : 2000),
      ctx.status(status),
      ctx.json(dataReturn)
    );
  });

export const createGetMock = (
  url: string,
  status: number,
  dataReturn: object
) => createMock(url, "get", status, dataReturn);

export const createPostMock = (
  url: string,
  status: number,
  dataReturn: object
) => createMock(url, "post", status, dataReturn);

export const createPutMock = (
  url: string,
  status: number,
  dataReturn: object
) => createMock(url, "put", status, dataReturn);

export const createDeleteMock = (
  url: string,
  status: number,
  dataReturn: object
) => createMock(url, "delete", status, dataReturn);
