import { Card, Input } from "antd";
import styled from "styled-components";

export const CenteredCard = styled(Card)`
  text-align: center;
`;

export const Centered = styled.div`
  text-align: center;
`;

export const RoundedInput = styled(Input)`
  border-radius: 25px;
`;

export const ErrorText = styled.p`
  color: ${(props) => props.theme.warningColor};
`;

export const StyledParagraph = styled.p`
  color: #ffffff;
  letter-spacing: normal;
  font-size: 14px;
  font-weight: 500;
`;

export const StyledDiv = styled.div`
  text-align: center;

  h2 {
    font-size: 14px;
  }
`;

export const CardTitle = styled.div`
  font-size: px;
  font-weight: 700;
`;
