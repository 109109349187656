import { useMutation, useQueryClient } from "react-query";
import { SUBMENU_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { postData } from "../../../utils/axiosHelpers";
import { Favorites } from "../FrameTypes";
import { KEY } from "./useUser";

export default function useEditSubMenuState() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    ({ subMenuKey, subMenuName }) => {
      const menuState: any = queryClient.getQueryData(KEY);

      /*
      // LEAVING THIS CUZ IDK WHAT IM DOING, BUT PRETTY SURE ITS NOT NEEDED?

      const { menu } = previousUserState;

      const { [subMenuName]: previousSubMenuState, ...subMenuStateRest } = menu;

      const newSubMenuState = previousSubMenuState.includes(subMenuKey)
        ? previousSubMenuState.filter(
            (subMenuItem) => subMenuItem !== subMenuKey
          )
        : [...previousSubMenuState, subMenuKey];

      console.log(previousUserState);
      console.log(newSubMenuState);

      const menuState = { ...subMenuStateRest, [subMenuName]: newSubMenuState };*/

      return postData(SUBMENU_URL, {
        menu: menuState.menu,
      });
    },

    {
      onMutate: async ({ subMenuKey, subMenuName }: any) => {
        const CALL_REASON = "Update SubMenu State";
        await queryClient.cancelQueries(KEY);

        const previousUserState: any = queryClient.getQueryData(KEY);

        const { menu, ...rest } = previousUserState;

        const { [subMenuName]: previousSubMenuState, ...subMenuStateRest } =
          menu;

        const newSubMenuState = previousSubMenuState.includes(subMenuKey)
          ? previousSubMenuState.filter(
              (subMenuItem) => subMenuItem !== subMenuKey
            )
          : [...previousSubMenuState, subMenuKey];

        const newUserState = {
          ...rest,
          menu: { ...subMenuStateRest, [subMenuName]: newSubMenuState },
        };

        console.log(newUserState);

        queryClient.setQueryData(KEY, newSubMenuState);

        return {
          previousUserState,
          newUserState,
          CALL_REASON,
        };
      },
      onSuccess: (data, _, context) => {
        onSuccessNotification(data, context?.CALL_REASON);
      },
      onError: (data, _, context) => {
        onErrorNotification(data, context?.CALL_REASON);
        if (context?.previousUserState) {
          queryClient.setQueryData<Favorites>([KEY], context.previousUserState);
        }
      },
    }
  );
}
