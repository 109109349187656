import { Input } from "antd";
import React from "react";
import { useTranslator } from "../../../../hooks";
import { useFilterDashboard } from "../../Organisms/AllDashboardsCard/AllDashboardsCard";

const { Search } = Input;

export default function DashboardSearchBox() {
  const { SEARCH_INPUT_PLACEHOLDER } = useTranslator("DashboardSearchBox");
  const { setFilterOption } = useFilterDashboard();

  const onSearch = (value: any) => {
    setFilterOption({ name: "Search", params: value });
  };

  return (
    <Search
      data-testid="dashboardSearch"
      placeholder={SEARCH_INPUT_PLACEHOLDER}
      onSearch={onSearch}
      onChange={(e) => onSearch(e.target.value)}
    />
  );
}
