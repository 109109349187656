import { Modal, Button, Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";
import { RoundedInput } from "../../../Shared";
import useUsersPageEvents from "../../hooks/useUsersPageEvents";
import { Users } from "../../UsersPageTypes";

export type Props = {
  toggleHook: any;
  users: Users;
};

export default function EmailUserModal({ toggleHook, users }: Props) {
  const [form] = useForm();
  const { toggleOpen, open } = toggleHook;
  const { onEmailUsers } = useUsersPageEvents();

  const onSubmit = (values) => {
    onEmailUsers({ ...values, users });
  };
  return (
    <Modal
      title={users.length > 1 ? "Email All Users" : `Email ${users[0]?.name}`}
      visible={open}
      width={1500}
      onCancel={() => toggleOpen()}
      footer={
        <>
          <Button onClick={() => toggleOpen()}>Close</Button>,
          <Button
            onClick={() => {
              form.submit();
              toggleOpen();
            }}
            type="primary"
          >
            Send Email
          </Button>
        </>
      }
    >
      <Form form={form} onFinish={onSubmit}>
        <Form.Item name="subject" label="Email Subject">
          <RoundedInput />
        </Form.Item>
        <Form.Item name="body" label="Email Body">
          <RoundedInput />
        </Form.Item>
      </Form>
    </Modal>
  );
}
