import { Col, Progress as AntProgress, Row } from "antd";
import React from "react";
import styled from "styled-components";

export type Props = {
  whatToProcess: string;
};

const ProgressText = styled.p`
  position: relative;
  text-align: center;
`;
const Progress = styled(AntProgress)`
  position: absolute;
  top: 0;
  left: 0;
`;

export default function ProgressBar({ whatToProcess }: Props) {
  return (
    <Row justify="center">
      <Col span={24}>
        <Progress
          percent={100}
          showInfo={false}
          strokeColor="#DAE1EC"
          status="active"
          strokeWidth={25}
        />
        <ProgressText>
          Processing: you will receive a notification when this {whatToProcess}{" "}
          is ready
        </ProgressText>
      </Col>
    </Row>
  );
}
