import { useForm } from "antd/lib/form/Form";
import { Dashboard, Dashboards } from "../DashboardPageTypes";
import useCreateDashboard from "./useCreateDashboard";
import useDeleteDashboard from "./useDeleteDashboard";
import useDeployDashboard from "./useDeployDashboard";
import useDuplicateDashboard from "./useDuplicateDashboard";
import useEditDashboard from "./useEditDashboard";

export default function useDashboardPageEvents() {
  const [createNewDashboardForm] = useForm();
  const [editDashboardForm] = useForm();
  const { mutate: createDashboard } = useCreateDashboard();
  const { mutate: editDashboard } = useEditDashboard();
  const { mutate: deleteDashboard } = useDeleteDashboard();
  const { mutate: duplicateDashboard } = useDuplicateDashboard();
  const { mutate: deployDashboard } = useDeployDashboard();

  const onCreateButtonClick = () => {
    const formValues = createNewDashboardForm.getFieldsValue();
    const { isSharable, isEditable, ...rest } = formValues;

    const tagList = [
      isSharable ? "Public" : "",
      isEditable ? "Editable" : "Read Only",
    ];

    createDashboard({ ...rest, tagList });
  };

  const onEditButtonClick = (dashboardToEdit: Dashboard) =>
    editDashboard(dashboardToEdit);

  const onDeleteButtonClick = (dashboardToDelete: Dashboard) =>
    deleteDashboard(dashboardToDelete);

  const onDuplicateButtonClick = (dashboardToDuplicate: Dashboard) =>
    duplicateDashboard(dashboardToDuplicate);

  const onDeployButtonClick = (dashboardToDeploy: Dashboard) =>
    deployDashboard(dashboardToDeploy);

  const onMassDeployButtonClick = (dashboardsToDeploy: Dashboards) =>
    deployDashboard(dashboardsToDeploy);

  const onImportDashboard = (dashboardToImport: any) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      createDashboard(JSON.parse(e?.target?.result));
    };

    reader.readAsText(dashboardToImport.originFileObj);
  };

  const onShareButtonClick = (dashboardToShare: Dashboards) => {
    console.log(dashboardToShare);
  };

  return {
    editDashboardForm,
    createNewDashboardForm,
    onCreateButtonClick,
    onEditButtonClick,
    onDuplicateButtonClick,
    onDeleteButtonClick,
    onDeployButtonClick,
    onMassDeployButtonClick,
    onImportDashboard,
    onShareButtonClick,
  };
}
