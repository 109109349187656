import { Card } from "antd";
import React, { createContext, useContext, useState } from "react";
import { useTranslator } from "../../../../hooks";
import { CardTitle } from "../../../Shared";
import { DashboardViewTable, DashboardViewActionRow } from "../../Molecules";

export type Props = {
  sample: string;
};
type FilterOption = {
  name: string;
  params: any;
};

type FilteredDashboardObject = {
  filterOption: FilterOption;
  setFilterOption: Function;
};

export const FilteredDashboardContext = createContext<FilteredDashboardObject>({
  filterOption: { name: "", params: "" },
  setFilterOption: Function,
});

export default function AllDashboardsCard() {
  const { CARD_TITLE } = useTranslator("AllDashboardsCard");
  const [filterOption, setFilterOption] = useState({ name: "", params: "" });

  return (
    <Card>
      <CardTitle>{CARD_TITLE}</CardTitle>

      <FilteredDashboardContext.Provider
        value={{
          filterOption,
          setFilterOption,
        }}
      >
        <DashboardViewActionRow />
        <DashboardViewTable />
      </FilteredDashboardContext.Provider>
    </Card>
  );
}

export function useFilterDashboard() {
  return useContext(FilteredDashboardContext);
}
