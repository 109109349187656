import { Input, Space, Button, Form } from "antd";
import React from "react";
import styled from "styled-components";
import useViewPageEvents from "../../hooks/useViewsPageEvents";
import { Playlist } from "../../ViewsPageTypes";

const StyledLabel = styled.label`
  font-size: 0.75em;
  font-weight: 600;
  & span {
    font-weight: 300;
    color: #666;
  }
`;

const SubLabel = styled.p`
  font-size: 0.75em;
  font-weight: 300;
  color: #666;
`;

export type formProps = {
  playlist: Playlist;
  newTitle: string | undefined;
  newURL: string | undefined;
  createCustomURL: () => void;
};

const CustomUrlForm = (props: formProps) => {
  const { createCustomUrlForm, onCreateButtonClick } = useViewPageEvents();
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Form
        form={createCustomUrlForm}
        onFinish={() => onCreateButtonClick("Custom URL", props.playlist)}
      >
        <Input.Group>
          <StyledLabel>Title</StyledLabel>
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Please input view name!" }]}
          >
            <Input />
          </Form.Item>
        </Input.Group>
        <Input.Group>
          <StyledLabel>
            URL <span>(Can be a Nagios XI page or any URL)</span>
          </StyledLabel>
          <Form.Item
            name="url"
            rules={[{ required: true, message: "Please input url!" }]}
          >
            <Input />
          </Form.Item>
        </Input.Group>
        <SubLabel>
          Include the protocol when specifying a URL. Example:
          https://www.nagios.com
        </SubLabel>
        <Button
          block
          onClick={() => {
            createCustomUrlForm.submit();
          }}
        >
          Add to Playlist
        </Button>
      </Form>
    </Space>
  );
};

export default CustomUrlForm;
