import { ResponsiveBar } from "@nivo/bar";
import { uniq } from "lodash";
import React from "react";
import { useQuery } from "react-query";
import { MONITORING_ENGINE_EVENT_QUEUE_DASHLET_URL } from "../../../../api.config";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import { fetchData } from "../../../../utils/axiosHelpers";
import { dashletMockData } from "../../DashletMockData";
import { DashletProps } from "../../DashletViewPageTypes";

export default function MonitoringEngineQueueDashlet({
  demo,
  dashletId,
}: DashletProps) {
  const theme = useMasterTheme().theme;

  const formatData = (data) =>
    data !== undefined
      ? data.event_counts.map((load, i) => ({
          time: (i + 1) * 10,
          load,
        }))
      : [];

  const { data, isLoading } = useQuery(
    ["Fetch Heatmap Data", dashletId],
    async () => await fetchData(MONITORING_ENGINE_EVENT_QUEUE_DASHLET_URL),
    demo
      ? {
          enabled: false,
        }
      : {
          select: (data) => formatData(data),
        }
  );

  if (isLoading) {
    return <p>Is Loading</p>;
  }

  const queueData = demo
    ? formatData(dashletMockData["monitoring_engine_event_queue"])
    : data
    ? data
    : [];

  console.log(queueData);

  return (
    <ResponsiveBar
      data={queueData}
      theme={{
        textColor: theme.typographyPrimaryColor,
        tooltip: {
          container: {
            background: theme.cardBackgroundColor,
          },
        },
      }}
      indexBy={"time"}
      keys={["load"]}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "nivo" }}
      colorBy={"id"}
      animate={true}
      enableLabel={false}
      margin={{ top: 50, bottom: 100, left: 50, right: 50 }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendPosition: "middle",
        legendOffset: -40,
        tickValues: uniq(
          [...(queueData ? queueData : [])].map(({ load }) => load)
        ),
      }}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: ``,
        legendPosition: "middle",
        legendOffset: 32,
        format: (value) => {
          if (value === 10) {
            return "Now";
          }
          if (value === 210) {
            return "+5 mins";
          }
          return "";
        },
      }}
    />
  );
}
