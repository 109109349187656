import { useQuery } from "react-query";
import { DASHLET_PROPERTIES_URL } from "../../../api.config";
import { fetchData } from "../../../utils/axiosHelpers";

export const KEY = "Fetch Dashlet Properties";

export const preFetchDashletProperties = (queryClient) =>
  queryClient.prefetchQuery(KEY, fetchDashletProperties);

const fetchDashletProperties = async () => fetchData(DASHLET_PROPERTIES_URL);

export default function useDashletProperties(
  dashletType?: string,
  options?: any
) {
  return useQuery(KEY, fetchDashletProperties, {
    ...options,
    select: ({ data }) => data,
  });
}
