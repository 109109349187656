import { useContext } from "react";
import { LanguageContext } from "../context";
import languageObject from "./languageObject";

export default function useTranslator(componentName: string) {
  const { currentLanguageFlagObject } = useContext(LanguageContext);

  const { languageCode } = currentLanguageFlagObject;

  const { [componentName]: constants } = languageObject[languageCode];

  return { ...constants };
}
