import { useQuery } from "react-query";
import { HOST_URL } from "../../../api.config";
import { fetchData } from "../../../utils/axiosHelpers";

export const KEY = "Fetch Hosts";

const fetchHosts = async () => fetchData(HOST_URL);

export const preFetchHosts = (queryClient) =>
  queryClient.prefetchQuery(KEY, fetchHosts);

export default function useHosts(options?: any) {
  return useQuery(KEY, fetchHosts, options);
}
