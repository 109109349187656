import { useMutation, useQueryClient } from "react-query";
import { VIEW_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { putData } from "../../../utils/axiosHelpers";
import { Playlist, Screen } from "../ViewsPageTypes";
import { KEY } from "./usePlaylists";

export default function useSetActiveView() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    (values) => putData(VIEW_URL(values.playlistId, values.viewId), values),
    {
      onMutate: async ({ activate, viewId, playlistId }: any) => {
        await queryClient.cancelQueries(KEY);

        const previousPlaylists: any = queryClient.getQueryData(KEY);

        const CALL_REASON = `Save changes to playlist`;

        const newPlaylists = previousPlaylists.playlists.map(
          (playlist: Playlist) => {
            if (playlist.playlist_id === playlistId) {
              const { screens, ...rest } = playlist;
              const newScreensList = Object.values(screens).map(
                (screen: Screen) => {
                  const { active, ...screenRest } = screen;

                  if (screen.screen_id === viewId) {
                    return { active: !activate, ...screenRest };
                  }
                  return screen;
                }
              );
              return {
                screens: newScreensList,
                ...rest,
              };
            }
            return playlist;
          }
        );

        queryClient.setQueryData(KEY, {
          playlists: newPlaylists,
        });

        return {
          previousPlaylists,
          newPlaylists,
          CALL_REASON,
        };
      },
      onSuccess: (data, _, context) => {
        onSuccessNotification(data, context?.CALL_REASON);
      },
      onError: (data, _, context) => {
        onErrorNotification(data, context?.CALL_REASON);
        if (context?.previousPlaylists) {
          queryClient.setQueryData<Playlist>([KEY], context.previousPlaylists);
        }
      },

      onSettled: () => {
        queryClient.invalidateQueries(KEY);
      },
    }
  );
}
