import { createContext, useContext, useState } from "react";
import { Dashboard } from "../DashboardPageTypes";

type CurrentDashboardContextObject = {
  currentDashboard: Dashboard | null;
  setCurrentDashboard: Function;
};

const CurrentDashboardContext = createContext<CurrentDashboardContextObject>({
  currentDashboard: null,
  setCurrentDashboard: Function,
});

export default function CurrentDashboardProvider({ children }) {
  const [currentDashboard, setCurrentDashboard] = useState(null);
  console.log(currentDashboard);
  return (
    <CurrentDashboardContext.Provider
      value={{
        currentDashboard,
        setCurrentDashboard,
      }}
    >
      {children}
    </CurrentDashboardContext.Provider>
  );
}

export function useCurrentDashboard() {
  return useContext(CurrentDashboardContext);
}
