import { orderBy } from "lodash";
import { useQuery } from "react-query";
import { HOME_PAGE_ORDER_URL } from "../../../api.config";
import { fetchData } from "../../../utils/axiosHelpers";

export const KEY = "Fetch Most Viewed ";

const fetchHomeCardOrder = async () => fetchData(HOME_PAGE_ORDER_URL);

export const preFetchHomeCardOrder = (queryClient) =>
  queryClient.prefetchQuery(KEY, fetchHomeCardOrder);

export default function useHomeCardOrder(options?: any) {
  return useQuery(KEY, fetchHomeCardOrder, {
    select: (data) => orderBy(data.cards, ["display_order"]),
    ...options,
    initalData: true,
    placeholderData: [],
  });
}
