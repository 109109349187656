import { ResponsiveLine } from "@nivo/line";
import { Skeleton } from "antd";
import { useQuery } from "react-query";
import { PERFORMANCE_DATA_LINE_DASHLET_URL } from "../../../../api.config";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import { fetchData } from "../../../../utils/axiosHelpers";
import { dashletMockData } from "../../DashletMockData";
import { DashletProps } from "../../DashletViewPageTypes";

export default function LineChartDashlet({
  properties,
  demo,
  dashletId,
}: DashletProps) {
  const theme = useMasterTheme().theme;
  const { y_axis_title, x_axis_title, colors: colorScheme } = properties;

  const { data: graphData, isLoading } = useQuery(
    ["Fetch Performance Line Data", dashletId],
    async () => await fetchData(PERFORMANCE_DATA_LINE_DASHLET_URL),
    demo
      ? {
          enabled: false,
        }
      : {
          select: (data) => data.nivo_data,
        }
  );

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <ResponsiveLine
          data={demo ? dashletMockData["perfdata_line"].nivo_data : graphData}
          theme={{
            textColor: theme.typographyPrimaryColor,
            tooltip: {
              container: {
                background: theme.cardBackgroundColor,
              },
            },
          }}
          colors={{ scheme: colorScheme ? colorScheme : "nivo" }}
          isInteractive={true}
          useMesh={true}
          margin={{
            top: 50,
            bottom: 100,
            left: 50,
            right: 50,
          }}
          enableSlices="x"
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: y_axis_title,
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: x_axis_title,
            legendOffset: -40,
            legendPosition: "middle",
          }}
        />
      )}
    </>
  );
}
