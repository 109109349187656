import React from "react";
import clipart1 from "../Images/asset-1.svg";
import clipart2 from "../Images/asset-2.svg";
import clipart3 from "../Images/asset-3.svg";
import clipart4 from "../Images/asset-4.svg";
import clipart5 from "../Images/asset-5.svg";
import clipart6 from "../Images/asset-6.svg";
import clipart7 from "../Images/asset-7.svg";

type clipProps = { width?: string };

const getStyleFromProps = (props: clipProps): clipProps => {
  let style = {};
  style = props.width ? { ...style, width: props.width } : style;
  return style;
};

export const ImageActiveAgents = (props: clipProps) => (
  <img src={clipart1} style={getStyleFromProps(props)} alt="clip art graphic" />
);

export const ImageConfigWizard = (props: clipProps) => (
  <img src={clipart2} style={getStyleFromProps(props)} alt="clip art graphic" />
);

export const ImageCreateReport = (props: clipProps) => (
  <img src={clipart6} style={getStyleFromProps(props)} alt="clip art graphic" />
);

export const ImageCustomToolkit = (props: clipProps) => (
  <img src={clipart5} style={getStyleFromProps(props)} alt="clip art graphic" />
);

export const ImageMonitorHost = (props: clipProps) => (
  <img src={clipart7} style={getStyleFromProps(props)} alt="clip art graphic" />
);

export const ImageMonitorService = (props: clipProps) => (
  <img src={clipart4} style={getStyleFromProps(props)} alt="clip art graphic" />
);

export const ImageSharedToolkit = (props: clipProps) => (
  <img src={clipart3} style={getStyleFromProps(props)} alt="clip art graphic" />
);
