import {
  IconView,
  IconEdit,
  IconDelete,
  IconDuplicate,
  IconEmail,
  IconDisable,
  IconMask,
  IconCheck,
} from "../../Atoms/FontIcons/FontIcons";
import { Button } from "antd";
import { PushpinTwoTone } from "@ant-design/icons";
import styled from "styled-components";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";

type ActionProps = {
  viewClicked?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  editClicked?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  duplicateClicked?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  deleteClicked?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  emailClicked?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  enableClicked?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  disableClicked?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  masqueradeClicked?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  pinClicked?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  isPinned?: boolean;
  mutipleUsers?: boolean;
};
type DivProps = {
  masqueradeClicked: Function;
  mutipleUsers: boolean;
};
const StyledDiv: any = styled.div`
  width: ${(props: DivProps) =>
    props?.masqueradeClicked || props?.mutipleUsers ? "200px" : "100px"};
  & button {
    color: ${themeVariables.colorWhite};
    text-align: left;
    &:hover {
      color: ${themeVariables.colorGrayLight3};
      background-color: ${themeVariables.colorBlack};
    }
  }
  & i {
    margin-right: 8px;
  }
`;

const Actions = (props: ActionProps) => {
  return (
    <StyledDiv
      masqueradeClicked={props.masqueradeClicked}
      mutipleUsers={props.mutipleUsers}
    >
      {props.viewClicked && (
        <Button
          onClick={props.viewClicked}
          type="text"
          block
          data-testid="View"
        >
          <IconView />
          View
        </Button>
      )}
      {props.editClicked && (
        <Button
          onClick={props?.editClicked}
          type="text"
          block
          data-testid="Edit"
        >
          <IconEdit />
          Edit
        </Button>
      )}
      {props.duplicateClicked && (
        <Button
          onClick={props.duplicateClicked}
          type="text"
          block
          data-testid="Duplicate"
        >
          <IconDuplicate />
          Duplicate
        </Button>
      )}
      {props.emailClicked && (
        <Button
          onClick={props.duplicateClicked}
          type="text"
          block
          data-testid="Edit"
        >
          <IconEmail />
          Email User
        </Button>
      )}
      {props.enableClicked && (
        <Button
          onClick={props.enableClicked}
          type="text"
          block
          data-testid="Enable"
        >
          <IconCheck />
          Enable {props.mutipleUsers ? "Selected Users" : "User"}
        </Button>
      )}
      {props.disableClicked && (
        <Button
          onClick={props.disableClicked}
          type="text"
          block
          data-testid="Disable"
        >
          <IconDisable />
          Disable {props.mutipleUsers ? "Selected Users" : "User"}
        </Button>
      )}
      {props.masqueradeClicked && (
        <Button
          onClick={props.duplicateClicked}
          type="text"
          block
          data-testid="Masquerade"
        >
          <IconMask />
          Masquerade as User
        </Button>
      )}

      {props.deleteClicked && (
        <Button
          onClick={props.deleteClicked}
          type="text"
          block
          data-testid="Delete"
        >
          <IconDelete />
          {props.mutipleUsers ? "Delete Selected Users" : "Delete"}
        </Button>
      )}
      {props.pinClicked && (
        <Button onClick={props.pinClicked} type="text" block data-testid="Pin">
          <PushpinTwoTone twoToneColor={props.isPinned ? "blue" : "red"} />
          {props.isPinned ? "Unpin" : "Pin"}
        </Button>
      )}
    </StyledDiv>
  );
};

export default Actions;
