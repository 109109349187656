const isNumber = (x: number, y: number) => x === y;
export const isZero = (x: number) => isNumber(x, 0);
export const is200 = (x: number) => isNumber(x, 200);

export const isLengthZero = (x: any[] | string) => isNumber(x.length, 0);

export const isIn = (x: string, array: any[]) => array.includes(x);

export const isNill = (x: any) => x === undefined || x === null;

export const getRandomNumberInRange = (max: number) =>
  Math.floor(Math.random() * Math.floor(max));
