import { useMutation, useQueryClient } from "react-query";
import { USER_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { postData } from "../../../utils/axiosHelpers";
import { User } from "../UsersPageTypes";
import { KEY } from "./useUsers";

export default function useCreateUser() {
  const CALL_REASON = `Create New User`;

  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation((values) => postData(USER_URL, values), {
    onMutate: async (userToCreate: any) => {
      await queryClient.cancelQueries(KEY);
      const previousUsersList: any = queryClient.getQueryData(KEY);

      const newUsersList = [
        ...previousUsersList?.users,
        {
          user_id: Math.random().toString(),
          ...userToCreate,
        },
      ];

      queryClient.setQueryData(KEY, {
        users: newUsersList,
      });

      console.log(newUsersList);

      return {
        previousUsersList,
        newUsersList,
      };
    },
    onSuccess: (data, _, context) => {
      onSuccessNotification(data, CALL_REASON);

      queryClient.setQueryData(KEY, {
        users: context?.newUsersList,
      });
    },
    onError: (data, _, context) => {
      onErrorNotification(data, CALL_REASON);
      if (context?.previousUsersList) {
        queryClient.setQueryData<User>([KEY], context.previousUsersList);
      }
    },

    onSettled: () => {
      queryClient.invalidateQueries(KEY);
    },
  });
}
