import { Space } from "antd";
import { DynamicDashlet } from "./Organisms/DynamicDashlet";
import { Responsive, WidthProvider } from "react-grid-layout";
import styled from "styled-components";
import { useDashboard } from "../DashboardPage/hooks/useDashboards";
import { Dashboard, Dashlet } from "../DashboardPage/DashboardPageTypes";
import { DashletViewPageContext } from "../../context/DashletViewPageContext";
import useDashletEvents from "./hooks/useDashletEvents";
import { useHistory, useParams } from "react-router-dom";
import React, { useLayoutEffect, useRef, useState } from "react";
import { Loader } from "../Shared";
import useCreateFavorite from "../Frame/hooks/useCreateFavorite";
import PageHeader from "../Shared/Organisms/PageHeader/PageHeader";
import StyledButton from "../Shared/Atoms/Button/StyledButton";
import { useMasterTheme } from "../../context/MasterThemeContext";
import { CreateDashletModal } from "./Organisms/CreateDashletModal";
import { useToggle } from "../../hooks";
import useCreateDashletArray from "./hooks/useCreateDashletArray";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const GridLayout = styled(ResponsiveReactGridLayout)`
  .react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 100%;
    border-right: 2px solid
      ${(props) =>
        props.cardBackgroundColor === "#EEF2F9"
          ? `rgba(0, 0, 0, 0.4)`
          : `rgba(255, 255, 255, 0.5)`};
    border-bottom: 2px solid
      ${(props) =>
        props.cardBackgroundColor === "#EEF2F9"
          ? `rgba(0, 0, 0, 0.4)`
          : `rgba(255, 255, 255, 0.5)`};
  }
`;

const Inherit = styled.div`
  width: inherit;
  height: inherit;
`;

type Props = {
  dashboard: Dashboard;
};

export const DivWrapper = ({
  dashlet,
  dashboard,
  height: startingHeight,
  width: startWidth,
}): JSX.Element => {
  const ref = useRef<any>(null);
  const [height, setHeight] = useState(startingHeight);
  const [width, setWidth] = useState(startWidth);

  useLayoutEffect(() => {
    if (ref.current !== null) {
      const { clientHeight, clientWidth } = ref.current;
      setHeight(clientHeight);
      setWidth(clientWidth);
    }
  }, [startWidth, startingHeight]);

  return (
    <Inherit ref={ref}>
      <DynamicDashlet
        dashlet={dashlet}
        height={height}
        width={width}
        dashboard={dashboard}
      />
    </Inherit>
  );
};

export function DashletViewPage({ demo }) {
  const { dashboardId } = useParams<any>();
  const { data: dashboard, isLoading } = useDashboard(demo ? 3 : dashboardId);

  return (
    <>
      {isLoading ? null : dashboard === undefined ? (
        <p>Dashboard couldn't be loaded</p>
      ) : (
        <DashletViewPageContent dashboard={dashboard} />
      )}
    </>
  );
}

function DashletViewPageContent({ dashboard }: Props) {
  const theme = useMasterTheme().theme;
  const history = useHistory();
  const dashletsArray = useCreateDashletArray(dashboard.dashlets);

  const { mutate: createFavorite } = useCreateFavorite();
  const {
    dimensionsArray,
    toggleCreateDashletModal,
    isEnabled,
    toggleIsEnabled,
    onLayoutChange,
    onResize,
  } = useDashletEvents(dashboard);

  const {
    open: createDashletModalOpen,
    toggleOpen: toggleCreateDashletModalIsOpen,
  } = useToggle();

  return (
    <DashletViewPageContext.Provider
      value={{
        dashboard,
        isEnabled,
        toggleIsEnabled,
        toggleCreateDashletModal,
      }}
    >
      <PageHeader
        title={dashboard?.name}
        sub=""
        favoriteClicked={() =>
          createFavorite({
            title: dashboard?.name,
            url: `/dashboards/${dashboard.dashboard_id}`,
          })
        }
        buttons={
          <Space style={{ marginRight: "10px" }}>
            <StyledButton block onClick={() => history.push("/dashboards/")}>
              Back
            </StyledButton>
            <StyledButton
              block
              onClick={() =>
                history.push(
                  `/dashboards/${dashboard.dashboard_id}/add_dashlet`
                )
              }
            >
              Add Dashlet
            </StyledButton>
          </Space>
        }
      />
      {dashletsArray.length !== dimensionsArray.length ? (
        <Loader />
      ) : (
        <GridLayout
          style={{ background: "#EEF2F9" }}
          breakpoints={{
            lg: 1200,
            md: 996,
            sm: 786,
            xs: 786,
            xxs: 320,
          }}
          col={{
            lg: 12,
            md: 8,
            sm: 4,
            xs: 1,
            xxs: 1,
          }}
          onLayoutChange={onLayoutChange}
          onResizeStart={onResize}
          onResizeStop={onResize}
          measureBeforeMount={false}
          useCSSTransforms={true}
          isDroppable={false}
          isResizeable={isEnabled}
          isDraggable={isEnabled}
          isBounded={true}
          maxRows={12}
          color={dashboard?.color}
          cardcolor={theme.cardBackgroundColor}
        >
          {dashletsArray.map((dashlet: Dashlet, i: number) => {
            const [{ height, width }] = dimensionsArray.filter(
              ({ index }) => index === `${i}`
            );

            const {
              properties: { pinned },
            } = dashlet;

            const isVisable =
              dashlet?.type !== "placeholder" ? "visible" : "hidden";
            return (
              <div
                style={{
                  visibility: isVisable,
                }}
                data-grid={{
                  x: dashlet.position_x,
                  y: dashlet.position_y,
                  w: dashlet.dimension_width,
                  h: dashlet.dimension_height,
                  minW: 3,
                  minH: 3,
                  maxH: 12,
                  maxW: 12,
                  static: pinned,
                  resizeHandles: pinned ? [] : ["se"],
                }}
                key={i}
              >
                <DivWrapper
                  dashlet={dashlet}
                  height={height}
                  width={width}
                  dashboard={dashboard}
                />
              </div>
            );
          })}
        </GridLayout>
      )}
      <CreateDashletModal
        dashboard={dashboard}
        open={createDashletModalOpen}
        toggleOpen={toggleCreateDashletModalIsOpen}
      />
    </DashletViewPageContext.Provider>
  );
}
