import { useMutation, useQueryClient } from "react-query";
import { REPORT_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { deleteData } from "../../../utils/axiosHelpers";
import { Report, Reports } from "../ReportsPageTypes";
import { KEY } from "./useReports";

export default function useDeleteReport() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation((values) => deleteData(REPORT_URL, values), {
    onMutate: async (reportToDelete: any) => {
      const CALL_REASON = `Delete Report ${reportToDelete.name}`;
      const { id } = reportToDelete;

      await queryClient.cancelQueries(KEY);
      const previousReportList: any = queryClient.getQueryData(KEY);

      const newReportList = previousReportList.reports.filter(
        (report: Report) => report.id !== id
      );

      queryClient.setQueryData(KEY, {
        reports: newReportList,
      });

      return {
        previousReportList,
        newReportList,
        CALL_REASON,
      };
    },
    onSuccess: (data, _, context) => {
      onSuccessNotification(data, context?.CALL_REASON);

      queryClient.setQueryData(KEY, {
        reports: context?.newReportList,
      });
    },
    onError: (data, _, context) => {
      onErrorNotification(data, context?.CALL_REASON);
      if (context?.previousReportList) {
        queryClient.setQueryData<Reports>([KEY], context.previousReportList);
      }
    },

    onSettled: () => {
      queryClient.invalidateQueries(KEY);
    },
  });
}
