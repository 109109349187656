import { find } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { VIEW_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { putData } from "../../../utils/axiosHelpers";
import { Screen, Screens, Playlist } from "../ViewsPageTypes";
import { KEY } from "./usePlaylists";

export default function useEditView() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    (values) => putData(VIEW_URL(values.playlist_id, values.screen_id), values),
    {
      onMutate: async (viewToEdit: any) => {
        console.log(viewToEdit);
        const { screen_id, playlist_id } = viewToEdit;

        await queryClient.cancelQueries(KEY);

        const previousPlaylists: any = queryClient.getQueryData(KEY);

        const playlistData: Playlist[] = Object.values(
          previousPlaylists.playlists
        );

        const oldPlayList: any = find(playlistData, { playlist_id });

        const CALL_REASON = `Edit View ${viewToEdit.name} in ${oldPlayList.name}`;

        const newPlaylists = playlistData.map((playlist: Playlist, i) =>
          playlist.playlist_id === playlist_id
            ? {
                playlist_id: playlist.playlist_id,
                name: playlist.name,
                screens: Object.assign(
                  {},
                  ...Object.values(playlist.screens).map((screen: Screen) => ({
                    [`${screen.play_order}`]:
                      screen.screen_id === screen_id ? viewToEdit : screen,
                  }))
                ),
              }
            : playlist
        );

        queryClient.setQueryData(KEY, {
          playlists: newPlaylists,
        });

        return {
          previousPlaylists,
          newPlaylists,
          CALL_REASON,
        };
      },
      onSuccess: (data, _, context) => {
        onSuccessNotification(data, context?.CALL_REASON);
      },
      onError: (data, _, context) => {
        onErrorNotification(data, context?.CALL_REASON);
        if (context?.previousPlaylists) {
          queryClient.setQueryData<Screens>([KEY], context.previousPlaylists);
        }
      },

      onSettled: () => {
        queryClient.invalidateQueries(KEY);
      },
    }
  );
}
