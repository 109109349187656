import { IconHighlight } from "../../Atoms/FontIcons/FontIcons";
import { Card, Button } from "antd";
import styled from "styled-components";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";

type ButtonProps = {
  disabled?: boolean;
  color: string;
  icon: JSX.Element;
  heading?: string;
  sub?: string;
  buttonOneClicked?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  buttonOneLabel?: string;
  buttonTwoClicked?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  buttonTwoLabel?: string;
};

const StyledCard = styled(Card)`
  margin: 10px !important;
  border-width: 2px;
  flex: 1;
  & .ant-card-body {
    padding: 20px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    & > i {
      margin-bottom: 20px;
    }
    & > h4 {
      font-size: 16px;
      width: 100%;
    }
    & > p {
      color: ${themeVariables.colorGrayLight2};
      width: 100%;
      font-size: 12px;
    }
    & > button {
      margin: 0 4px;
    }
  }
  &:hover {
    border-color: ${themeVariables.colorPrimary};
  }
`;

const DisabledCard = styled(StyledCard)`
  background-color: ${themeVariables.colorBluePale};
  border-width: 0;
`;

const IconButtonLarge = (props: ButtonProps) => {
  return props.disabled ? (
    <DisabledCard>
      <IconHighlight color={props.color} size={"78px"}>
        {props.icon}
      </IconHighlight>
      <h4>{props.heading}</h4>
      <Button onClick={props.buttonOneClicked}>{props.buttonOneLabel}</Button>
    </DisabledCard>
  ) : (
    <StyledCard>
      <IconHighlight color={props.color} size={"78px"}>
        {props.icon}
      </IconHighlight>
      <h4>{props.heading}</h4>
      {props.sub && <p>{props.sub}</p>}
      <Button type="primary" onClick={props.buttonOneClicked}>
        {props.buttonOneLabel}
      </Button>
      {props.buttonTwoLabel && (
        <Button type="primary" onClick={props.buttonTwoClicked}>
          {props.buttonTwoLabel}
        </Button>
      )}
    </StyledCard>
  );
};

export default IconButtonLarge;
