import { Card } from "antd";
import React from "react";
import { ImageCustomToolkit } from "../../../Shared/Atoms/ClipArt/ClipArt";
import EmptyTable from "../../../Shared/Atoms/EmptyTable/EmptyTable";
import ToolkitTable from "../../Molecules/ToolkitTable/ToolkitTable";

const CustomToolkit = (props) => {
  return (
    <Card>
      <h3>My Custom Toolkit</h3>
      {props.data && props.data.length > 0 ? (
        <ToolkitTable data={props.data} />
      ) : (
        <EmptyTable>
          <ImageCustomToolkit />
          <h4>You haven't saved any tools yet!</h4>
          <p>
            Once you've setup a location, URL or a dashboard, you will see it
            here.
          </p>
        </EmptyTable>
      )}
    </Card>
  );
};

export default CustomToolkit;
