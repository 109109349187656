import { Radio } from "antd";
import React, { useState } from "react";
import { useFilterDashboard } from "../../Organisms/AllDashboardsCard/AllDashboardsCard";
const { Group } = Radio;

export default function DashboardTableFilter() {
  const [currentOption, setCurrentOption] = useState("All");

  const { setFilterOption } = useFilterDashboard();

  const filterGroupOnChange = (e: any) => {
    const value = e.target.value;
    setCurrentOption(value);
    setFilterOption(
      value === "All"
        ? { name: "", params: "" }
        : {
            name: "Type",
            params: value,
          }
    );
  };

  const options = ["All", "Read Only", "Editable", "Public"];

  return (
    <Group
      options={options}
      value={currentOption}
      onChange={filterGroupOnChange}
      optionType="button"
      buttonStyle="solid"
    />
  );
}
