import { ResponsiveBar } from "@nivo/bar";
import { Skeleton } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { MONITORING_ENGINE_STATS_DASHLET } from "../../../../api.config";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import { fetchData } from "../../../../utils/axiosHelpers";
import { dashletMockData } from "../../DashletMockData";
import { DashletProps } from "../../DashletViewPageTypes";

export default function MonitoringEngineCheckDashlet({
  demo,
  dashletId,
}: DashletProps) {
  const formatData = (data) =>
    data
      ? data.length !== 0
        ? Object.entries(data).map(([METRIC, VALUES]: any) => ({
            metric: METRIC,
            ...VALUES,
          }))
        : []
      : [];

  const theme = useMasterTheme().theme;
  const { data, isLoading }: any = useQuery(
    ["Fetch Mointoring Engine Stats", dashletId],
    () => fetchData(MONITORING_ENGINE_STATS_DASHLET),
    demo
      ? {
          enabled: false,
        }
      : {
          select: (data) => formatData(data),
        }
  );

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <ResponsiveBar
      data={
        demo ? formatData(dashletMockData["monotoring_engine_check"]) : data
      }
      keys={["1m", "5m", "15m"]}
      indexBy="metric"
      margin={{ top: 50, right: 130, bottom: 50, left: 100 }}
      padding={0.3}
      groupMode="grouped"
      layout="horizontal"
      theme={{
        textColor: theme.typographyPrimaryColor,
        tooltip: {
          container: {
            background: theme.cardBackgroundColor,
          },
        },
      }}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "nivo" }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Metric",
        legendPosition: "middle",
        legendOffset: -60,
      }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Checks",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "top-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
}
