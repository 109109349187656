import React, { createContext, useState } from "react";

type MessageContextObject = {
  messageObject: MessageObject | null;
  setErrorMessage: Function;
  setSucessMessage: Function;
  setWarningMessage: Function;
};

type MessageObject = {
  message: string;
  type: MessageType;
};

type MessageType = "error" | "warning" | "success" | "info";

export const MessageContext = createContext<MessageContextObject>({
  messageObject: null,
  setErrorMessage: Function,
  setSucessMessage: Function,
  setWarningMessage: Function,
});

type Props = {
  children: JSX.Element[] | JSX.Element;
};

export function MessageProvider({ children }: Props) {
  const [messageObject, setMessageObject] = useState<MessageObject | null>(
    null
  );

  const createMessageFunction = (type: MessageType) => (message: string) =>
    setMessageObject({ message, type });

  const setErrorMessage = createMessageFunction("error");
  const setWarningMessage = createMessageFunction("warning");
  const setSucessMessage = createMessageFunction("success");

  return (
    <MessageContext.Provider
      value={{
        messageObject,
        setErrorMessage,
        setWarningMessage,
        setSucessMessage,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
}

export const WithMessageContext = ({ children }) => (
  <MessageProvider>{children}</MessageProvider>
);
