import React from "react";

export default function BackupSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 55 55"
    >
      <g>
        <g>
          <path
            fill="#dbfbf5"
            d="M0 18C0 8.059 8.059 0 18 0h19c9.941 0 18 8.059 18 18v19c0 9.941-8.059 18-18 18H18C8.059 55 0 46.941 0 37z"
          />
        </g>
        <g>
          <g>
            <g>
              <path d="M16 30h24v2H16z" />
            </g>
            <g>
              <path d="M40.84 21.372a1.94 1.94 0 0 0-1.91-1.37h-2.38a3 3 0 0 1-.14 2h2.54c1.05 2.94 2.77 7.65 3.05 8.48v7.52H14v-7.52c.28-.83 3.05-8.48 3.05-8.48h2.53a3 3 0 0 1-.14-2h-2.37a1.92 1.92 0 0 0-1.9 1.32C12 30.002 12 30.102 12 30.331v7.67a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2v-7.67c0-.23 0-.33-3.16-8.96z" />
            </g>
            <g>
              <path d="M27.996 27.84l6.38-6.35a1 1 0 1 0-1.38-1.41l-4 3.92V12a1 1 0 1 0-2 0v12l-4-3.95a1 1 0 0 0-1.41 1.42z" />
            </g>
          </g>
          <g />
        </g>
      </g>
    </svg>
  );
}
