import { useMutation, useQueryClient } from "react-query";
import { PLAYLIST_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { deleteData } from "../../../utils/axiosHelpers";
import { Playlists, Playlist } from "../ViewsPageTypes";
import { KEY } from "./usePlaylists";

export default function useDeletePlaylist() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    (values) => deleteData(`${PLAYLIST_URL}/${values.playlist_id}`, values),
    {
      onMutate: async (playlistToDelete: any) => {
        const CALL_REASON = `Delete Playlist ${playlistToDelete.name}`;
        const { playlist_id } = playlistToDelete;

        console.log(playlist_id);
        await queryClient.cancelQueries(KEY);
        const previousPlaylists: any = queryClient.getQueryData(KEY);

        const newPlayList =
          previousPlaylists.playlists.length === 1
            ? []
            : previousPlaylists.playlists.filter(
                (playlist: Playlist) => playlist.playlist_id !== playlist_id
              );

        queryClient.setQueryData(KEY, {
          playlists: newPlayList,
        });

        return {
          previousPlaylists,
          newPlayList,
          CALL_REASON,
        };
      },
      onSuccess: (data, _, context) => {
        onSuccessNotification(data, context?.CALL_REASON);
      },
      onError: (data, _, context) => {
        onErrorNotification(data, context?.CALL_REASON);
        if (context?.previousPlaylists) {
          queryClient.setQueryData<Playlists>([KEY], context.previousPlaylists);
        }
      },

      onSettled: () => {
        queryClient.invalidateQueries(KEY);
      },
    }
  );
}
