import { useMutation, useQueryClient } from "react-query";
import { REPORT_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { putData } from "../../../utils/axiosHelpers";
import { Reports, Report } from "../ReportsPageTypes";
import { KEY } from "./useReports";

export default function useEditReport() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation((values) => putData(REPORT_URL, values), {
    onMutate: async (reportToEdit: any) => {
      console.log(reportToEdit);
      const { id } = reportToEdit;

      await queryClient.cancelQueries(KEY);

      const previousReportList: any = queryClient.getQueryData(KEY);

      const [oldReport] = previousReportList.reports.map(
        (report: Report) => report.id === id
      );

      const CALL_REASON = `Edit Report ${oldReport.name}`;

      const newReportList = previousReportList.reports.map((report: Report) =>
        report.id === id ? { ...report, ...reportToEdit } : report
      );

      queryClient.setQueryData(KEY, {
        reports: newReportList,
      });

      return {
        previousReportList,
        newReportList,
        CALL_REASON,
      };
    },
    onSuccess: (data, _, context) => {
      onSuccessNotification(data, context?.CALL_REASON);

      queryClient.setQueryData(KEY, {
        reports: context?.newReportList,
      });
    },
    onError: (data, _, context) => {
      onErrorNotification(data, context?.CALL_REASON);
      if (context?.previousReportList) {
        queryClient.setQueryData<Reports>([KEY], context.previousReportList);
      }
    },

    onSettled: () => {
      queryClient.invalidateQueries(KEY);
    },
  });
}
