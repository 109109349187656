import { ResponsiveBullet } from "@nivo/bullet";
import { Skeleton } from "antd";
import { useQuery } from "react-query";
import { MONITORING_ENGINE_PERFORMANCE_DASHLET_URL } from "../../../../api.config";
import { fetchData } from "../../../../utils/axiosHelpers";
import { dashletMockData } from "../../DashletMockData";
import { DashletProps } from "../../DashletViewPageTypes";

export default function MonitoringEnginePerformanceDashlet({
  demo,
  dashletId,
}: DashletProps) {
  const formatData = (data) =>
    Object.entries(data).map(([METRIC, VALUES]: any) => ({
      id: METRIC,
      ranges: [],
      measures: [],
      markers: [VALUES.avg, VALUES.min, VALUES.max],
    }));

  const { data, isLoading }: any = useQuery(
    ["Fetch Mointoring Engine Stats", dashletId],
    () => fetchData(MONITORING_ENGINE_PERFORMANCE_DASHLET_URL),
    {
      select: (data) => formatData(data),
    }
  );

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <ResponsiveBullet
      data={
        demo
          ? formatData(dashletMockData["monitoring_engine_performance"])
          : data
      }
      margin={{ top: 50, right: 90, bottom: 50, left: 90 }}
      spacing={46}
      titleAlign="start"
      titleOffsetX={-70}
      measureSize={0.2}
    />
  );
}
