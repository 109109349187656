import { useHistory } from "react-router";
import useSideNav from "./useSideNav";

export default function useSearch() {
  const { flattenSideNav } = useSideNav();
  const history = useHistory();

  const search = (searchBy: string) => {
    const [{ link, action }] = flattenSideNav.filter((value) =>
      value.name?.includes(searchBy)
    );

    if (link) {
      history.push(link);
    } else {
      action();
    }
  };

  return {
    search,
  };
}
