import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";
import * as Icon from "../../../Shared/Atoms/FontIcons/FontIcons";

export const CardData = {
  startMonitoring: [
    {
      type: "button",
      color1: themeVariables.colorGreenDark,
      icon: <Icon.IconServers color={themeVariables.colorGreenLight} size="34px" />,
      label: "Servers",
      alert: false,
      num: null,
      text: null,
      link: "/config/wizards/servers",
    },
    {
      type: "button",
      color1: themeVariables.colorYellowDark,
      icon: (
        <Icon.IconArrowTwoWayThin color={themeVariables.colorYellowLight} size="36px" />
      ),
      label: "Network",
      alert: false,
      num: null,
      text: null,
      link: "/config/wizards/network",
    },
    {
      type: "button",
      color1: themeVariables.colorBlueDark,
      icon: <Icon.IconMonitor color={themeVariables.colorBlueLight} size="30px" />,
      label: "Desktop",
      alert: false,
      num: null,
      text: null,
      link: "/config/wizards/desktop",
    },
    {
      type: "button",
      color1: themeVariables.colorRedDark,
      icon: <Icon.IconWebGlobal color={themeVariables.colorRedLight} size="36px" />,
      label: "Website",
      alert: false,
      num: null,
      text: null,
      link: "/config/wizards/website",
    },
    {
      type: "button",
      color1: themeVariables.colorPinkDark,
      icon: <Icon.IconGridOutline color={themeVariables.colorPinkLight} size="36px" />,
      label: "More",
      alert: false,
      num: null,
      text: null,
      link: "/config/wizards",
    },
  ],
  setupHostsServices: [
    {
      type: "card",
      color1: "theme.iconLightGreenBG",
      icon: <Icon.IconMagicWand color={themeVariables.colorGreenDark} size="48px" />,
      title: "Monitoring Wizards",
      description:
        "Start monitoring devices, servers, applications, services and more in minutes.",
      sub: "Diverse • Fast • Flexible",
      link: "/config/wizards",
      label: "Show All",
    },
    {
      type: "card",
      color1: "theme.iconLightPurpleBG",
      icon: <Icon.IconNetworkOutline color={themeVariables.colorPurpleDark} size="48px" />,
      title: "Auto-Discovery",
      description:
        "Find hardware, devices and services with a quick network scan",
      sub: "Fast • Automatic • Basic",
      link: "/config/autodiscovery",
      label: "Start",
    },
    {
      type: "button",
      color1: "theme.iconLightBlueBG",
      color2: themeVariables.colorBlueDark,
      icon: null,
      label: "Configuration Manager",
      alert: false,
      num: null,
      text: "CM",
      link: "/config/manager",
    },
    {
      type: "button",
      color1: "theme.iconLightYellowBG",
      icon: <Icon.IconQuestion color={themeVariables.colorYellowDark} size="36px" />,
      label: "Help",
      alert: false,
      num: null,
      text: null,
      link: "/help",
    },
  ],
  ViewHostsServices: [
    {
      type: "button",
      color1: "theme.iconLightPurpleBG",
      icon: <Icon.IconHost color={themeVariables.colorPurpleDark} size="36px" />,
      label: "All Hosts",
      alert: false,
      num: null,
      text: null,
      link: "/status/hosts",
    },
    {
      type: "button",
      color1: "theme.iconLightBlueBG",
      icon: <Icon.IconNodeTree3 color={themeVariables.colorBlueDark} size="32px" />,
      label: "All Services",
      alert: false,
      num: null,
      text: null,
      link: "/status/services",
    },
    {
      type: "button",
      color1: "theme.iconLightGreenBG",
      color2: themeVariables.colorGreenDark,
      icon: null,
      label: "Host Problems",
      alert: false,
      num: "0",
      text: null,
      link: "/status/hosts/problems",
    },
    {
      type: "button",
      color1: "theme.iconLightRedBG",
      color2: themeVariables.colorRedDark,
      icon: null,
      label: "Service Problems",
      alert: true,
      num: 5,
      text: null,
      link: "/status/services/problems",
    },
    {
      type: "button",
      color1: "theme.iconLightYellowBG",
      color2: themeVariables.colorYellowDark,
      icon: null,
      label: "Operations Center (NOC)",
      alert: false,
      num: null,
      text: "NOC",
      link: "/components/noc",
    },
  ],
  MostVisited: [
    {
      type: "button",
      color1: "theme.iconLightGreenBG",
      icon: <Icon.IconServers color={themeVariables.colorGreenDark} size="36px" />,
      label: "Servers",
      alert: false,
      num: null,
      text: null,
      link: "#",
    },
    {
      type: "button",
      color1: "theme.iconLightYellowBG",
      icon: <Icon.IconArrowTwoWayThin color={themeVariables.colorYellowDark} size="36px" />,
      label: "Network",
      alert: false,
      num: null,
      text: null,
      link: "#",
    },
    {
      type: "button",
      color1: "theme.iconLightBlueBG",
      icon: <Icon.IconMonitor color={themeVariables.colorBlueDark} size="30px" />,
      label: "Desktop",
      alert: false,
      num: null,
      text: null,
      link: "#",
    },
  ],
  ViewHostgroupsServicegroups: [
    {
      type: "button",
      color1: "theme.iconLightPurpleBG",
      icon: <Icon.IconHostGroups3 color={themeVariables.colorPurpleDark} size="36px" />,
      label: "All Hostgroups",
      alert: false,
      num: null,
      text: null,
      link: "/status/hostgroups",
    },
    {
      type: "button",
      color1: "theme.iconLightBlueBG",
      icon: <Icon.IconNodeTree3 color={themeVariables.colorBlueDark} size="32px" />,
      label: "All Servicegroups",
      alert: false,
      num: null,
      text: null,
      link: "/status/servicegroups",
    },
    {
      type: "button",
      color1: "theme.iconLightYellowBG",
      color2: themeVariables.colorYellowDark,
      icon: null,
      label: "Business Process Intelligence (BPI)",
      alert: false,
      num: null,
      text: "BPI",
      link: "/components/bpi",
    },
  ],
  Administration: [
    {
      type: "button",
      color1: `theme.iconLightRedBG`,
      icon: <Icon.IconUserData color={themeVariables.colorRedDark} size="32px" />,
      label: "Manage Users",
      alert: false,
      num: null,
      text: null,
      link: "/admin/manage_users",
    },
    {
      type: "button",
      color1: "theme.iconLightBlueBG",
      icon: (
        <Icon.IconNotificationOutline color={themeVariables.colorBlueDark} size="36px" />
      ),
      label: "Notification Management",
      alert: false,
      num: null,
      text: null,
      link: "/admin/users/notification-management",
    },
    {
      type: "button",
      color1: "theme.iconLightYellowBG",
      icon: <Icon.IconLicensing color={themeVariables.colorYellowDark} size="36px" />,
      label: "Licensing",
      alert: false,
      num: null,
      text: null,
      link: "/admin/system/license-managment",
    },
    {
      type: "button",
      color1: "theme.iconLightPurpleBG",
      icon: <Icon.IconConfig color={themeVariables.colorPurpleDark} size="36px" />,
      label: "System Settings",
      alert: false,
      num: null,
      text: null,
      link: "/admin/system/settings",
    },
    {
      type: "button",
      color1: "theme.iconLightGreenBG",
      icon: <Icon.IconLog color={themeVariables.colorGreenDark} size="32px" />,
      label: "Audit Log",
      alert: false,
      num: null,
      text: null,
      link: "#",
    },
    {
      type: "button",
      color1: "theme.iconLightPurpleBG",
      icon: <Icon.IconExtensions color={themeVariables.colorPurpleDark} size="36px" />,
      label: "Components",
      alert: false,
      num: null,
      text: null,
      link: "/admin/system/components",
    },
    {
      type: "button",
      color1: "theme.iconLightGreenBG",
      icon: <Icon.IconBackups color={themeVariables.colorGreenDark} size="36px" />,
      label: "Backups",
      alert: false,
      num: null,
      text: null,
      link: "#",
    },
  ],
};
