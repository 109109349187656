import { Checkbox, Button, Space, Select } from "antd";
import styled from "styled-components";
import { ColumnType } from "antd/lib/table";
import { range } from "lodash";
import React from "react";
import CustomDragTable from "../../../Shared/Organisms/CustomDragTable/CustomDragTable";
import useViewPageEvents from "../../hooks/useViewsPageEvents";
import { Screens, Playlist, Screen } from "../../ViewsPageTypes";
import { IconTrash } from "../../../Shared/Atoms/FontIcons/FontIcons";

const { Option } = Select;

export const CheckBox = styled.div`
  & .ant-checkbox-inner {
    border-radius: 2px;
  }
`;

const ExplicitDragWidths = (className?: string) => `
  tr > :last-child {
      & button {
        padding-left: 0px;
      }
    }
`;

const StyledPlaylistTable = styled(CustomDragTable)`
  ${ExplicitDragWidths()}
`;

export type PlaylistTableProps = {
  editBtn: (id: string | number) => void;
  playlistData: Screens;
  deleteBtn: (id: string | number) => void;
  activeCheckbox: (id: string | number, activate: boolean) => void;
  record: Playlist;
};

const PlaylistTable = (props: any) => {
  const { onEditPlaylistButtonClick, onEditViewDurationButtonClick } =
    useViewPageEvents();
  const columns: ColumnType<Screen>[] = [
    {
      title: "Title",
      dataIndex: "name",
      key: "playOrder",
      width: "60%",
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "playOrder",
      width: "15%",
      render: (active, record: Screen) => (
        <CheckBox>
          <Checkbox
            checked={record?.active}
            onChange={() =>
              props.activeCheckbox(record.screen_id, record.active)
            }
            style={{ borderRadius: "4px" }}
          >
            {record.active ? "Active" : "Inactive"}
          </Checkbox>
        </CheckBox>
      ),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "playOrder",
      width: "15%",
      render: (active, record: Screen) => (
        <Select
          style={{ width: "140px" }}
          placeholder="Set Duration"
          defaultValue={record.play_duration}
          onChange={(value) => onEditViewDurationButtonClick(record, value)}
        >
          {range(5, 31).map((rangeValue, i) => (
            <Option value={rangeValue} key={i}>
              {rangeValue} seconds
            </Option>
          ))}
        </Select>
      ),
    },

    {
      title: "Modify",
      dataIndex: "playOrder",
      key: "playOrder",
      width: "15%",
      render: (playOrder: number, record: Screen) => (
        <Space>
          <Button
            data-testid="DeleteView"
            type="text"
            danger
            onClick={() => props.deleteBtn(record.screen_id)}
          >
            <IconTrash size="1.5em" /> Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <StyledPlaylistTable
      draggableTableProps={{
        dataSource: props.playlistData,
        columns: columns,
        rowKey: "play_order",
        showHeader: true,
        loading: false,
      }}
      onTableUpdate={(updatedTable) => {
        onEditPlaylistButtonClick(updatedTable, props.record.id);
      }}
    />
  );
};

export default PlaylistTable;
