import styled from "styled-components";
import { Space } from "antd";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";

type styleProps = {
  children?: JSX.Element;
};

const StyledDiv = styled.div`
  height: calc(100vh - 65px);
  width: 300px;
  background-color: ${themeVariables.colorBluePale};
`;

export const TopSpace = styled(Space)`
  width: calc(100% - 40px);
  margin: 20px;
`;

export const HalfSpace = styled(Space)`
  width: 100%;
  & .ant-space-item {
    width: 50%;
  }
`;

export const SideNavPopup = (props: styleProps) => (
  <StyledDiv>{props.children}</StyledDiv>
);
