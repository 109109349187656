export const supportTopic = [
  { name: "Administrator", url: "#" },
  { name: "API's", url: "#" },
  { name: "Configuration", url: "#" },
  { name: "Configuration Manager (CM)", url: "#" },
  { name: "Dashboards", url: "#" },
  { name: "Documentation (Developer)", url: "#" },
  { name: "Hosts", url: "#" },
  { name: "Host and Service Groups", url: "#" },
  { name: "Incident Management", url: "#" },
  { name: "Reports", url: "#" },
  { name: "System Settings", url: "#" },
];

export const documentationGuides = [
  { name: "Administrator Guide", url: "#" },
  { name: "API Documentation", url: "#" },
  { name: "Documentation (Developer)", url: "#" },
  { name: "Documentation (General)", url: "#" },
  { name: "Get Monitoring Series", url: "#" },
  { name: "Reporting + Graphing Series", url: "#" },
  { name: "Startup Guide", url: "#" },
  { name: "Tutorials", url: "#" },
  { name: "User Guide", url: "#" },
  { name: "Reports", url: "#" },
  { name: "System Settings", url: "#" },
];
