import { Card as AntCard } from "antd";
import React from "react";
import styled from "styled-components";
import { useTranslator } from "../../../../hooks";
import { CardTitle } from "../../../Shared";
import { CreateDashboardForm } from "../../Molecules";

const Card = styled(AntCard)`
  width: 255px;
  height: 458px;
`;

export default function CreateDashboardCard() {
  const { CARD_TITLE } = useTranslator("CreateDashboardCard");

  return (
    <Card>
      <CardTitle>{CARD_TITLE}</CardTitle>
      <CreateDashboardForm />
    </Card>
  );
}
