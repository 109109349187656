import { useMutation, useQueryClient } from "react-query";
import { TOOLS_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { postData } from "../../../utils/axiosHelpers";
import { Tools } from "../ToolsPageTypes";
import { KEY } from "./useTools";

export default function useCreateTool() {
  const CALL_REASON = `Create New Tool`;

  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    (values) => {
      return postData(TOOLS_URL, values);
    },
    {
      onMutate: async (toolToCreate: any) => {
        const { shared, ...rest } = toolToCreate;
        await queryClient.cancelQueries(KEY);
        const previousToolList: any = queryClient.getQueryData(KEY);

        const newToolList = [
          ...previousToolList?.tool,
          {
            id: Math.random().toString(),
            ...{ ...rest, shared: shared ? 1 : 0 },
          },
        ];

        queryClient.setQueryData(KEY, {
          tool: newToolList,
        });

        return {
          previousToolList,
          newToolList,
        };
      },
      onSuccess: (data, _, context) => {
        onSuccessNotification(data, CALL_REASON);

        queryClient.setQueryData(KEY, {
          tool: context?.newToolList,
        });
      },
      onError: (data, _, context) => {
        onErrorNotification(data, CALL_REASON);
        if (context?.previousToolList) {
          queryClient.setQueryData<Tools>([KEY], context.previousToolList);
        }
      },

      onSettled: () => {
        queryClient.invalidateQueries(KEY);
      },
    }
  );
}
