import React, { useState } from "react";
import { Col, Form, Modal, Row } from "antd";
import styled from "styled-components";
import CreateReportModalFooter from "../../Molecules/CreateReportModalFooter/CreateReportModalFooter";
import CreateReportForm from "../../Molecules/CreateReportForm/CreateReportForm";
import ScheduleReportForm from "../../Molecules/ScheduleReportsForm/ScheduleReportForm";
import { useGlobalModal } from "../../../../context/GlobalModalContext";
import { useEditReportContext } from "../../context/EditReportContent";
import useReportPageEvents from "../../hooks/useReportPageEvents";

const StyledModal = styled(Modal)`
  .ant-modal-body {
    height: 500px;
    overflow: auto;
    padding-top: 0px;
  }
`;

function ReportPreview({ form }) {
  const values = form.getFieldsValue();

  return (
    <div style={{ background: "lightgray", position: "fixed" }}>
      {values?.reportBasic?.type}
    </div>
  );
}

const CreateReportModal = () => {
  const [page, setPage] = useState(0);
  const { reportToEdit, setReportToEdit } = useEditReportContext();
  const [footerPage, setFooterPage] = useState(0);
  const [formValues, setFormValues] = useState({});

  const {
    createAReportModalOpen,
    toggleCreateAReportModalOpen,
  } = useGlobalModal();

  const {
    onCreateReportButtonClick,
    onScheduledButtonClick,
    onEditButtonClick,
    createReportForm,
  } = useReportPageEvents();

  const runReport = (values) => {
    console.log(reportToEdit, values);
    reportToEdit
      ? onEditButtonClick(formValues)
      : onCreateReportButtonClick(formValues);
  };

  const schedule = () => onScheduledButtonClick();

  return (
    <StyledModal
      afterClose={() => createReportForm.resetFields()}
      forceRender
      width={1500}
      title={
        reportToEdit
          ? `Edit ${reportToEdit.name ? reportToEdit.name : "Report"}`
          : "Create a Report"
      }
      visible={createAReportModalOpen}
      onCancel={() => {
        toggleCreateAReportModalOpen();
        setReportToEdit(null);
        setPage(0);
        setFooterPage(0);
      }}
      footer={
        <CreateReportModalFooter
          toggleReportOpitions={() => {
            setPage(0);
            setFooterPage(0);
          }}
          toggleScheduledOptions={() => {
            setPage(1);
            setFooterPage(1);
          }}
          page={footerPage}
          runReport={() => {
            createReportForm.submit();
            toggleCreateAReportModalOpen();
          }}
          schedule={schedule}
        />
      }
    >
      <Form.Provider
        onFormChange={(name, { forms }) => {
          if (name) {
            const values = forms[name].getFieldsValue();
            setFormValues(Object.assign({}, formValues, values));
          }
        }}
      >
        <Row>
          <Col span={12}>
            <Form form={createReportForm} onFinish={runReport}>
              {page === 0 && <CreateReportForm form={createReportForm} />}
              {page === 1 && <ScheduleReportForm form={createReportForm} />}
            </Form>
          </Col>
          <Col span={12}>
            <ReportPreview form={createReportForm} />
          </Col>
        </Row>
      </Form.Provider>
    </StyledModal>
  );
};
export default CreateReportModal;
