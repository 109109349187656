import { useMutation, useQueryClient } from "react-query";
import { DASHBOARD_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { postData } from "../../../utils/axiosHelpers";
import { Dashboard } from "../DashboardPageTypes";
import { KEY } from "./useDashboards";

export default function useCreateDashboard() {
  const CALL_REASON = "Create New Dashboard";
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    (values) => {
      const { tagList, ...rest } = values;
      const includesTag = (tagName: string) => tagList.includes(tagName);

      return postData(DASHBOARD_URL, {
        ...rest,
        destination_name: values.name,
        color: "#16C79B",
        editable: includesTag("Editable"),
        public: includesTag("Public"),
        read_only: !includesTag("Editable"),
      });
    },
    {
      onMutate: async (dashboardsToCreate: any) => {
        console.log(dashboardsToCreate);
        await queryClient.cancelQueries(KEY);
        const previousDashboards: any = queryClient.getQueryData(KEY);
        const id = Math.random().toString();

        const newDashboards = Array.isArray(dashboardsToCreate)
          ? dashboardsToCreate
          : [dashboardsToCreate];

        const updatedDashboards = [
          ...previousDashboards?.dashboards,
          ...newDashboards.map((newDashboard) => ({
            id,
            ...newDashboard,
            dashlets: newDashboard.dashlets ? newDashboard.dashlets : [],
          })),
        ];

        queryClient.setQueryData(KEY, {
          dashboards: updatedDashboards,
        });

        return { previousDashboards, updatedDashboards };
      },
      onSuccess: (data, _, context) => {
        onSuccessNotification(data, CALL_REASON);
        queryClient.setQueryData(KEY, {
          dashboards: context?.updatedDashboards,
        });
      },
      onError: (data, _, context) => {
        onErrorNotification(data, CALL_REASON);
        if (context?.previousDashboards) {
          queryClient.setQueryData<Dashboard[]>(
            KEY,
            context.previousDashboards
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(KEY);
      },
    }
  );
}
