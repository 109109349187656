let host = "";
if (process.env.REACT_APP_DEV_HOST) {
  host = `http://${process.env.REACT_APP_DEV_HOST}`;
}
const url = `${host}/backend/api/v1/`;

//Nagios
export const HOST_URL = `${url}hosts`;
export const AVAILABLE_SCREENS_URL = `${url}screens/available `;
export const HOST_OVERVIEW_URL = `${HOST_URL}/overview`;
export const WIZARDS_URL = `${url}wizards`;
export const SERVICE_URL = `${url}services`;
export const NAGIOS_STATUS_URL = `${url}system/status`;
export const NAGIOS_INFO_URL = `${url}system/info`;
export const FETCH_RRDEXPORT_URL = `${url}rrdexport`;
export const SIDENAV_URL = `${url}sidenav`;
export const SUBMENU_URL = `${url}menu`;
export const FAVORITES_URL = `${url}favorites`;
export const DELETE_FAVORITES_URL = `${url}favorites/delete`;
export const CREATE_FAVORITES_URL = `${url}favorites/edit`;

//LoginPage
export const USER_URL = `${url}users`;
export const AUTH_SERVER_URL = `${url}auth_servers`;
export const DUPLICATE_USER_URL = `${url}/clone`;
export const LOGIN_URL = `${USER_URL}/login`;
export const LOGOUT_URL = `${USER_URL}/logout`;
export const REFRESH_URL = `${USER_URL}/refresh`;
export const PASSWORD_RESTART_URL = `${USER_URL}/password_restart`;
export const USER_CURRENT_URL = `${USER_URL}/profile`;
export const USER_SETTINGS_URL = `${url}settings`;
export const USER_FAVORITES_URL = `${url}favorites`;

//HomePage
export const HOME_PAGE_ORDER_URL = `${url}cards`;
export const MOST_VIEWED_URL = `${url}/most_viewed`;

//DashboardPage
export const DASHBOARD_URL = `${url}dashboards`;
export const FETCH_DASHBOARD_URL = (dashboard_id) =>
  `${DASHBOARD_URL}/${dashboard_id}?dashlet_detail_level=3&include_placeholders=1`;
export const DUPLICATE_DASHBOARD_URL = `${DASHBOARD_URL}/clone`;
export const UPDATE_DASHBOARD_URL = `${DASHBOARD_URL}/update`;
export const DEPLOY_DASHBOARD_URL = `${DASHBOARD_URL}/deploy`;

//DashletViewPage
export const DASHLET_URL = `${url}dashlets`;
export const SAVE_DASHLETS_URL = (dashboard_id: number) =>
  `${DASHLET_URL}/grid/${dashboard_id}`;
export const DASHLET_PROPERTIES_URL = `${url}props/dashlets/`;
export const DUPLICATE_DASHLET_URL = `${DASHLET_URL}/clone`;
export const UPDATE_DASHLET_URL = `${DASHLET_URL}/update`;
export const PIN_DASHLET_URL = `${DASHLET_URL}/pinned`;

//Dashlet Data
export const DASHLET_DATA_URL = `${url}dashlet_data/`;
export const AVAILABILITY_DASHLET_URL = `${DASHLET_DATA_URL}availability`;
export const HEATMAP_DASHLET_URL = `${DASHLET_DATA_URL}heatmap`;
export const PERFORMANCE_DATA_LINE_DASHLET_URL = `${DASHLET_DATA_URL}perfdata_measurements`;
export const PERFORMANCE_DATA_GAUGE_DASHLET_URL = `${DASHLET_DATA_URL}perfdata_gauge`;
export const SLA_DASHLET_URL = `${DASHLET_DATA_URL}sla`;
export const NETWORK_DASHLET_URL = `${DASHLET_DATA_URL}network`;
export const LATEST_ALERTS_DASHLET_URL = `${DASHLET_DATA_URL}latest_alerts`;
export const METRICS_OVERVIEW_DASHLET_URL = `${DASHLET_DATA_URL}metrics_overview`;
export const SERVICEGROUPS_DASHLET_URL = `${DASHLET_DATA_URL}status_summary/servicegroups`;
export const HOSTGROUPS_DASHLET_URL = `${DASHLET_DATA_URL}status_summary/hostgroups`;
export const NETWORK_OUTAGES_DASHLET_URL = `${DASHLET_DATA_URL}network_outages`;
export const CORE_COMPONENT_STATUS_DASHLET_URL = `${DASHLET_DATA_URL}core_component_status`;
export const STATUS_SUMMARY_HOST_DASHLET_URL = `${DASHLET_DATA_URL}status_summary/hosts`;
export const STATUS_SUMMARY_SERVICES_DASHLET_URL = `${DASHLET_DATA_URL}status_summary/services`;
//Mointoring Engine
export const MONITORING_ENGINE_DASHLET_URL = `${DASHLET_DATA_URL}monitoring_engine/`;
export const MONITORING_ENGINE_STATS_DASHLET = `${MONITORING_ENGINE_DASHLET_URL}stats`;
export const MONITORING_ENGINE_EVENT_QUEUE_DASHLET_URL = `${MONITORING_ENGINE_DASHLET_URL}event_queue`;
export const MONITORING_ENGINE_PERFORMANCE_DASHLET_URL = `${MONITORING_ENGINE_DASHLET_URL}performance`;
export const MONITORING_ENGINE_PROCESSES_DASHLET_URL = `${MONITORING_ENGINE_DASHLET_URL}processes`;
export const MONITORING_ENGINE_SERVER_STATS_DASHLET_URL = `${DASHLET_DATA_URL}server_stats`;

//ToolsPage
export const TOOLS_URL = `${url}tools`;

//ViewsPage
export const PLAYLIST_URL = `${url}playlists`;
export const VIEW_URL = (playlistId: number, viewId: number) =>
  `${url}playlists/${playlistId}/views/${viewId}`;
export const CREATE_VIEW_URL = (playlistId: number) =>
  `${PLAYLIST_URL}/${playlistId}/views`;

//ReportsPage
export const REPORT_URL = `${url}reports`;
export const REPORT_TYPES_URL = `${REPORT_URL}/templates`;
export const FAVORITE_REPORT_URL = `${REPORT_URL}/favorites`;
export const RUN_REPORT_URL = `${REPORT_URL}/run`;
export const SCHEDULE_RUN_REPORT_URL = `${REPORT_URL}/schedule`;
