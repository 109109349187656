import React from "react";

type Props = {
  hasStrokes?: boolean;
};
export default function ShareSvg({ hasStrokes }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="13"
      viewBox="0 0 15 13"
    >
      <g>
        <g>
          <path
            stroke={hasStrokes ? "blue" : ""}
            fill="#fff"
            d="M14.529 4.351L10.333.156A.504.504 0 0 0 9.965 0a.504.504 0 0 0-.37.156.504.504 0 0 0-.155.369v2.097H7.605c-3.896 0-6.286 1.101-7.17 3.303C.144 6.657 0 7.566 0 8.654c0 .906.347 2.138 1.04 3.695a20.463 20.463 0 0 0 .197.443.928.928 0 0 0 .107.18c.065.093.142.14.23.14a.24.24 0 0 0 .192-.083.306.306 0 0 0 .07-.204c0-.05-.007-.122-.02-.218a1.695 1.695 0 0 1-.021-.192 13.84 13.84 0 0 1-.041-1.008c0-.552.047-1.046.143-1.483.096-.437.228-.815.397-1.135.17-.32.388-.596.656-.828a3.85 3.85 0 0 1 .864-.57 4.689 4.689 0 0 1 1.09-.348c.418-.084.839-.143 1.262-.176.424-.033.903-.049 1.439-.049H9.44v2.098c0 .142.052.265.156.369a.505.505 0 0 0 .368.155.505.505 0 0 0 .37-.155l4.195-4.196a.504.504 0 0 0 .156-.369.505.505 0 0 0-.156-.369z"
          />
        </g>
      </g>
    </svg>
  );
}
