import { Card } from "antd";
import styled from "styled-components";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";

const TeamCard = styled(Card)`
  & .ant-card-body {
    display: flex;
    flex-wrap: wrap;
    & h4 {
      width: 100%;
      font-size: 16px;
    }
    & p {
      width: 100%;
      font-size: 14px;
      color: ${themeVariables.colorGrayLight1};
    }
    & div {
      width: calc(33% - 10px);
      &:not(:nth-of-type(3n)) {
        margin-right: 15px;
      }
      & img {
        width: 100%;
      }
    }
  }
`;

export type TeamProps = {
  teamMembers: {
    name: string;
    image: string;
  }[];
};

const SupportTeam = (props: TeamProps) => {
  return (
    <TeamCard>
      <h4>Your Nagios XI Support Team</h4>
      <p>
        Our knowledgeable techs are happy to help you with any questions or
        problems you may have getting Nagios up and running.
      </p>
      {props.teamMembers.map((member) => (
        <div>
          <img src={member.image} alt={member.name} />
          <p>{member.name}</p>
        </div>
      ))}
    </TeamCard>
  );
};

export default SupportTeam;
