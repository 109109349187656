import { Button, Space, Tooltip } from "antd";
import React from "react";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import { Dashboard } from "../../../DashboardPage/DashboardPageTypes";
import * as Icon from "../../../Shared/Atoms/FontIcons/FontIcons";
import { PushpinTwoTone } from "@ant-design/icons";

type TableIcon = {
  iconType: "share" | "duplicate" | "edit" | "delete" | "download" | "pin";
  onClick?: () => void;
  withTooltip?: boolean;
};

export type TableIconsProps = {
  icons: TableIcon[];
  dashboard?: Dashboard;
  isPinned?: boolean;
};

export default function TableIcons(props: TableIconsProps) {
  const theme = useMasterTheme().theme;

  const shareColor = theme.primaryColor;
  const duplicateColor = theme.primaryColor;
  const editColor = theme.primaryColor;
  const deleteColor = theme.warningColor;
  const size = "20px";

  return (
    <Space size="large">
      {props.icons.map((item, index) => {
        const withTooltip = item.withTooltip === false ? false : true;
        switch (item.iconType) {
          case "share":
            return (
              <Space key={"table-button_" + index.toString()} align="center">
                <Tooltip placement="top" title={withTooltip ? "Share" : ""}>
                  <Icon.IconShare
                    color={shareColor}
                    size={size}
                    onClick={item.onClick ? item.onClick : undefined}
                  />
                </Tooltip>
              </Space>
            );
          case "duplicate":
            return (
              <Space key={"table-button_" + index.toString()}>
                <Tooltip placement="top" title={withTooltip ? "Duplicate" : ""}>
                  <Icon.IconDuplicate
                    color={duplicateColor}
                    size={size}
                    onClick={item.onClick ? item.onClick : undefined}
                  />
                </Tooltip>
              </Space>
            );
          case "edit":
            return (
              <Space key={"table-button_" + index.toString()}>
                <Tooltip placement="top" title={withTooltip ? "Edit" : ""}>
                  <Icon.IconEdit
                    color={editColor}
                    size={size}
                    onClick={item.onClick ? item.onClick : undefined}
                  />
                </Tooltip>
              </Space>
            );
          case "delete":
            return (
              <Space key={"table-button_" + index.toString()}>
                <Tooltip placement="top" title={withTooltip ? "Delete" : ""}>
                  <Icon.IconDelete
                    color={deleteColor}
                    size={size}
                    onClick={item.onClick ? item.onClick : undefined}
                  />
                </Tooltip>
              </Space>
            );
          case "pin":
            return (
              <Space key={"table-button_" + index.toString()}>
                <Tooltip
                  placement="top"
                  title={withTooltip ? (props.isPinned ? "Unpin" : "Pin") : ""}
                >
                  <Button
                    onClick={item.onClick ? item.onClick : undefined}
                    type="text"
                    block
                    data-testid="Pin"
                  >
                    <PushpinTwoTone
                      twoToneColor={props.isPinned ? "blue" : "red"}
                    />
                  </Button>
                </Tooltip>
              </Space>
            );

          case "download":
            return (
              <Space key={"table-button_" + index.toString()}>
                <Tooltip placement="top" title={withTooltip ? "Export" : ""}>
                  <a
                    href={`data:text/json;charset=utf-8,${encodeURIComponent(
                      JSON.stringify(props.dashboard, null, 2)
                    )}`}
                    download={`Nagios_${props?.dashboard?.name}_Export.json`}
                  >
                    <Icon.IconDownload color={editColor} size={size} />
                  </a>
                </Tooltip>
              </Space>
            );

          default:
            return <></>;
        }
      })}
    </Space>
  );
}
