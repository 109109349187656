import { ResponsiveHeatMap } from "@nivo/heatmap";
import { Skeleton } from "antd";
import { useQuery } from "react-query";
import { SERVICEGROUPS_DASHLET_URL } from "../../../../api.config";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import { fetchData } from "../../../../utils/axiosHelpers";
import { dashletMockData } from "../../DashletMockData";
import { DashletProps } from "../../DashletViewPageTypes";

export default function ServiceStatusGroupSummaryDashlet({
  demo,
  dashletId,
}: DashletProps) {
  const theme = useMasterTheme().theme;
  const formatData = (data) =>
    Object.entries(data).map(([NAME, VALUES]: any) => ({
      name: VALUES.alias,
      ...VALUES.host,
      ...VALUES.service,
    }));

  let { data, isLoading }: any = useQuery(
    ["Fetch Service Group Summary Services", dashletId],
    async () => await fetchData(SERVICEGROUPS_DASHLET_URL),
    demo
      ? {
          enabled: false,
        }
      : {
          select: (data) =>
            data.length === 0 ? "No Services" : formatData(data),
        }
  );

  if (isLoading) {
    return <Skeleton />;
  }

  data = demo
    ? formatData(dashletMockData["status_summary_servicegroups"])
    : data;

  return (
    <>
      {data.length === 0 ? (
        "No Services"
      ) : (
        <ResponsiveHeatMap
          data={data}
          theme={{
            textColor: theme.typographyPrimaryColor,
            tooltip: {
              container: {
                background: theme.cardBackgroundColor,
              },
            },
          }}
          labelTextColor={{ from: "#ffffff" }}
          indexBy="name"
          keys={[
            "up",
            "down",
            "unreachable",
            "ok",
            "warning",
            "unknown",
            "critical",
          ]}
          enableGridY={true}
          margin={{ top: 150, bottom: 100, left: 100, right: 50 }}
          axisTop={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: -90,
            legend: "Alerts",
            legendOffset: 36,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Host",
            legendOffset: -40,
          }}
        />
      )}{" "}
    </>
  );
}
