import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToggle } from "../../../hooks";
import { Tool } from "../ToolsPageTypes";
import useCreateTool from "./useCreateTool";
import useDeleteTool from "./useDeleteTool";
import useEditTool from "./useEditTool";

export default function useToolPageEvents() {
  const [selectedTool, setSelectedTool] = useState<Tool | null>(null);
  const { open, toggleOpen } = useToggle();
  const [createNewToolForm] = useForm();
  const [editToolForm] = useForm();
  const history = useHistory();

  const { mutate: createTool, isLoading: createToolIsLoading } =
    useCreateTool();

  const { mutate: deleteTool, isLoading: deleteToolIsLoading } =
    useDeleteTool();

  const { mutate: editTool, isLoading: editToolIsLoading } = useEditTool();

  const onSaveButtonClick = (values) => {
    const formValues = createNewToolForm.getFieldsValue();
    createTool(formValues);
  };

  const openEditModal = (toolToEdit: Tool) => {
    setSelectedTool(toolToEdit);
    toggleOpen();
  };

  const onEditButtonClick = (toolToEdit: Tool) => {
    editTool(toolToEdit);
    toggleOpen();
  };

  const onEditModalCancel = () => toggleOpen();

  const onDeleteButtonClick = (toolToDelete: Tool) => {
    deleteTool(toolToDelete);
  };
  const onViewButtonClick = (tool: Tool) => {
    history.push(`tools/${tool.tool_id}`);
  };

  return {
    open,
    selectedTool,
    createNewToolForm,
    editToolForm,
    openEditModal,
    onEditButtonClick,
    onSaveButtonClick,
    onEditModalCancel,
    onDeleteButtonClick,
    onViewButtonClick,
    loading: createToolIsLoading || deleteToolIsLoading || editToolIsLoading,
  };
}
