import React, { createContext, useContext, useState } from "react";

type SideNavObject = {
  showMask: boolean;
  setShowMask: Function;
};

export const SideNavMaskContext = createContext<SideNavObject>({
  showMask: false,
  setShowMask: Function,
});

type Props = {
  children: JSX.Element[] | JSX.Element;
};

export default function SideNavMaskProvider({ children }: Props) {
  const [showMask, setShowMask] = useState(true);
  return (
    <SideNavMaskContext.Provider
      value={{
        showMask,
        setShowMask,
      }}
    >
      {children}
    </SideNavMaskContext.Provider>
  );
}

export function useSideNavMask() {
  return useContext(SideNavMaskContext);
}
