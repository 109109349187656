import { Button, Checkbox, Form, Input, Modal } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { useGlobalModal } from "../../../../context/GlobalModalContext";
import useToolPageEvents from "../../hooks/useToolPageEvents";

const StyledLabel = styled.label`
  font-size: 0.75em;
  font-weight: 600;
  & i {
    font-size: 15px;
  }
`;

export default function AddAToolModal() {
  const { createNewToolForm, onSaveButtonClick } = useToolPageEvents();

  const [checked, setChecked] = useState(false);
  const { toggleAddAToolModalOpen, addAToolModalOpen } = useGlobalModal();
  return (
    <Modal
      title="Add a Tool"
      onOk={() => {
        createNewToolForm.submit();
        toggleAddAToolModalOpen();
      }}
      onCancel={() => toggleAddAToolModalOpen()}
      visible={addAToolModalOpen}
      footer={
        <Button
          key="submit"
          type="primary"
          onClick={() => createNewToolForm.submit()}
        >
          Create Tool
        </Button>
      }
    >
      <Form
        form={createNewToolForm}
        onFinish={(values) => onSaveButtonClick(values)}
        initialValues={{ shared: false }}
      >
        <Input.Group>
          <StyledLabel>Tool Name</StyledLabel>
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Please input tool name!" }]}
          >
            <Input data-testid="ToolName" />
          </Form.Item>
        </Input.Group>
        <Input.Group>
          <StyledLabel>URL</StyledLabel>
          <Form.Item
            name="url"
            rules={[{ required: true, message: "Please input url name!" }]}
          >
            <Input data-testid="URL" />
          </Form.Item>
        </Input.Group>
        <Form.Item name="shared" valuePropName="checked">
          <Checkbox
            style={{ marginBottom: "4px" }}
            onChange={() => setChecked(!checked)}
          >
            Make this viewable to everyone
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
}
