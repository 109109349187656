import { IconHighlight, IconAlert } from "../../Atoms/FontIcons/FontIcons";
import styled from "styled-components";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";

export type ButtonProps = {
  color1: string;
  color2?: string | null;
  icon?: JSX.Element | null;
  label: string | JSX.Element;
  alert?: boolean;
  num?: number | string | null;
  text?: string | null;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const StyledButton = styled.button`
  position: relative;
  width: 120px;
  min-height: 120px;
  padding: 20px 10px;
  margin: 10px !important;
  background-color: transparent;
  border: 1px solid ${themeVariables.colorGrayLight3};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  i:first-child {
    margin-bottom: 8px;
    & span {
      line-height: 1;
    }
  }
  span {
    line-height: 1.2;
  }
  &:hover {
    border-color: ${themeVariables.colorPrimary};
    cursor: pointer;
    box-shadow: 0 0 0 1px ${themeVariables.colorPrimary};
  }
  i:last-child:not(:first-child) {
    position: absolute;
    top: 3px;
    right: 3px;
    size: 8px;
  }
`;

const Text = styled.span`
  font-family: "DM Sans";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.3em;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const LargeText = styled(Text)`
  font-size: 0.5em;
`;

const IconButton = (props: ButtonProps) => {
  return (
    <StyledButton onClick={props.onClick}>
      <IconHighlight color={props.color1} size="55px">
        {props.icon ? (
          props.icon
        ) : props.num ? (
          <LargeText style={{ color: props.color2 ? props.color2 : "inherit" }}>
            {props.num}
          </LargeText>
        ) : (
          <Text style={{ color: props.color2 ? props.color2 : "inherit" }}>
            {props.text}
          </Text>
        )}
      </IconHighlight>
      <BoldText>{props.label}</BoldText>
      {props.alert ? <IconAlert color={themeVariables.colorDanger} /> : null}
    </StyledButton>
  );
};

export default IconButton;
