import { useForm } from "antd/lib/form/Form";
import { User, Users } from "../UsersPageTypes";
import useCreateUser from "./useCreateUser";
import useDeleteUser from "./useDeleteUser";
import useDuplicateUser from "./useDuplicateUser";
import useEditUser from "./useEditUser";
import useEnableUser from "./useEnableUser";
import useMasqueradeAsUser from "./useMasqueradeAsUser";

type EmailProps = {
  usersToEmail: Users;
  emailObject: {
    submit: string;
    body: string;
  };
};
export default function useUsersPageEvents() {
  const userForm = useForm();

  const { mutate: deleteUser } = useDeleteUser();
  const { mutate: createUser } = useCreateUser();
  const { mutate: editUser } = useEditUser();
  const { mutate: enableUser } = useEnableUser();
  const { mutate: duplicateUser } = useDuplicateUser();
  const { mutate: masqueradeAsUser } = useMasqueradeAsUser();

  const onDeleteUserClick = (usersToDelete: Users) => deleteUser(usersToDelete);
  const onCreateUserClick = (userToCreate: User) => {
    console.log(userToCreate);
    createUser(userToCreate);
  };
  const onEditUserClick = (userToEdit: User) => editUser(userToEdit);
  const onDuplicateUserClick = (userToDuplicate: User) =>
    duplicateUser(userToDuplicate);

  const onDisableUserClick = (users: Users, action: "Disable" | "Enable") => {
    enableUser({
      users,
      action,
    });
  };

  const onMasqueradeClicked = (userToMasquerade: User) =>
    masqueradeAsUser(userToMasquerade);

  const onEmailUsers = ({ usersToEmail: Users, emailObject }: EmailProps) => {};

  return {
    userForm,
    onDeleteUserClick,
    onCreateUserClick,
    onEditUserClick,
    onDisableUserClick,
    onDuplicateUserClick,
    onMasqueradeClicked,
    onEmailUsers,
  };
}
