import { Card, Button } from "antd";
import styled from "styled-components";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";
import { IconHighlight } from "../../Atoms/FontIcons/FontIcons";

type CardProps = {
  color: string;
  icon: JSX.Element | null;
  title: string;
  description: string;
  sub?: string;
  onclick?: (event: React.MouseEvent<HTMLElement>) => void;
  label?: string;
};

const StyledCard = styled(Card)`
  border: 1px solid ${themeVariables.colorGrayLight3} !important;
  margin: 10px !important;
  max-width: 325px;
  & .ant-card-body {
    line-height: 1.2;
    display: flex;
    align-items: flex-start;
    & div {
      margin-left: 20px;
      width: calc(100% - 100px);
      & h4 {
        font-size: 14px;
      }
      & p {
        font-size: 12px;
        color: ${themeVariables.colorGrayLight1};
      }
      & h5 {
        font-size: 12px;
        font-style: italic;
        margin-bottom: 10px;
      }
    }
  }
`;

const IconCard = (props: CardProps) => (
  <StyledCard>
    <IconHighlight color={props.color} size={"75px"}>
      {props.icon ? props.icon : undefined}
    </IconHighlight>
    <div>
      <h4>{props.title}</h4>
      <p>{props.description}</p>
      <h5>{props.sub}</h5>
      <Button type={"primary"} onClick={props.onclick}>
        {props.label}
      </Button>
    </div>
  </StyledCard>
);

export default IconCard;
