import { useState, useEffect } from "react";
import { useQueryClient, useQuery } from "react-query";
import { FETCH_RRDEXPORT_URL } from "../../../api.config";
import { fetchData } from "../../../utils/axiosHelpers";
import useDashletProperties from "./useDashletProperties";
import { find, isEqual } from "lodash";
import useHosts from "../../Frame/hooks/useHosts";
import useServices from "../../Frame/hooks/useServices";

export default function useDashletModalEvents(
  toggleOpen,
  form,
  dashletToEdit,
  selectedDashletType
) {
  const values = form.getFieldsValue();
  const { url, name, dashletType, whatToGraph } = form.getFieldsValue();

  const [color, setColor] = useState(
    dashletToEdit ? dashletToEdit?.color : "#FFFF"
  );
  const [formValues, setFormValues] = useState(values);

  const { data: hostData, isLoading: isHostsLoading } = useHosts();
  const { data: serviceData, isLoading: isServicesLoading } = useServices();
  const queryClient = useQueryClient();

  const { data: dashletProperties, isLoading: isDashletPropertiesIsLoading } =
    useDashletProperties();

  const { data: initalData, refetch: refetchInitalData }: any = useQuery(
    ["Fetching Inital Form Data", url ? url : whatToGraph],
    () => {
      if (whatToGraph) {
        return fetchData(`${FETCH_RRDEXPORT_URL}/${whatToGraph}`);
      } else if (/https:(.*).com/.test(url)) {
        return fetchData(url);
      }
    }
  );

  const dashletToEditData = queryClient.getQueryData(
    dashletToEdit ? `Fetching ${dashletToEdit?.name}` : ``
  );

  const onCancel = () => {
    toggleOpen();
  };

  useEffect(() => {
    const formValues = form.getFieldsValue();
    if (!isEqual(formValues, values)) {
      setFormValues(formValues);
    }
  }, [form, values]);

  const afterFetchData =
    (initalData !== undefined || dashletToEditData !== undefined) &&
    dashletProperties !== undefined;

  const showIfNotDashletType = (dashletType: any) =>
    !["heatmap", "customUrl"].includes(dashletType);

  const isHostOrServiceLoading =
    isHostsLoading || isServicesLoading || isDashletPropertiesIsLoading;

  return {
    form,
    setFormValues,
    color,
    setColor,
    dashletName: name,
    onCancel,
    afterFetchData,
    showIfNotDashletType,
    hostData,
    serviceData,
    dashletToEditData,
    dashletProperties: find(dashletProperties, { name: dashletType })
      ? find(dashletProperties, { name: dashletType })
      : [],

    // refetchDashletProperties,
    initalData,
    refetchInitalData,
    formValues,
    isHostOrServiceLoading,
  };
}
