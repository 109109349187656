import { useQuery } from "react-query";
import { USER_CURRENT_URL } from "../../../api.config";
import { fetchData } from "../../../utils/axiosHelpers";

export const KEY = "Fetch User Info";

const fetchUser = async () => fetchData(USER_CURRENT_URL);

export const preFetchUser = (queryClient) =>
  queryClient.prefetchQuery(KEY, fetchUser);

export default function useUser() {
  return useQuery(KEY, fetchUser, {
    staleTime: Infinity,
  });
}

export function useSubMenuState() {
  return useQuery(KEY, fetchUser, {
    select: (data) => data.menu,
    staleTime: Infinity,
  });
}
