import styled from "styled-components";
import React from "react";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";
import UpgradeFooter from '../../Atoms/UpgradeFooter/UpgradeFooter';

const StyledFooter = styled.footer`
  background-color: ${themeVariables.colorBlackCharade};
  padding: 12px;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  > div {
    color: ${themeVariables.colorGrayLight2};
    a {
      color: ${themeVariables.colorGrayLight3};
      text-decoration: underline;
      :hover {
        color: ${themeVariables.colorWhite};
        text-decoration: none;
      }
    }
    > span:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

const Footer = (props) => {
  return (
    <StyledFooter style={{ width: props.width ? props.width : "" }}>
      {props.loggedIn && <UpgradeFooter />}
      <div>
        <span>
          <a href="https://nagios.com" target="_blank" rel="noopener noreferrer">Visit Nagios.com</a>
        </span>
        <span>
          <a href="https://www.nagios.com/about-nagios-enterprises/" target="_blank" rel="noopener noreferrer">About</a>
        </span>
        <span>
          <a href="https://www.nagios.com/legal/" target="_blank" rel="noopener noreferrer">Legal</a>
        </span>
        <span>Nagios Enterprises, LLC &copy;{new Date().getFullYear()}</span>
      </div>
    </StyledFooter>
  );
};

export default Footer;
