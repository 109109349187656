import styled from "styled-components";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";

const StyledDiv = styled.div`
  width: 300px;
  margin: 0 auto;
  text-align: center;
  & img {
    width: 75%;
    display: block;
    margin: 0 auto;
  }
  & p {
    color: ${themeVariables.colorGrayLight2};
  }
`;

const EmptyTable = (props) => <StyledDiv>{props.children}</StyledDiv>;

export default EmptyTable;
