import { Modal, Button } from "antd";
import AddScreenModalBody from "../../Organisms/AddScreenModalBody/AddScreenModalBody";
import CommonScreens from "../../Molecules/CommonScreens/CommonScreens";
import MyDashboardTable, {
  DashboardProps,
} from "../../Molecules/MyDashboardTable/MyDashboardTable";
import CustomURLForm, {
  formProps,
} from "../../Molecules/CustomURLForm/CustomURLForm";
import OtherIdeas from "../../Molecules/OtherIdeas/OtherIdeas";
import styled from "styled-components";
import { useGlobalModal } from "../../../../context/GlobalModalContext";
import useViewPageEvents from "../../hooks/useViewsPageEvents";
import { Playlist } from "../../ViewsPageTypes";
const StyledModal = styled(Modal)`
  & .ant-modal-header {
    padding-bottom: 0px;
  }
  & .ant-modal-body {
    padding: 0 10px 10px;
  }
`;

export type modalProps = DashboardProps &
  formProps & {
    playlist: Playlist;
    visible: boolean;
    close: () => void;
    csData: {
      title: string;
      image: string;
      description: string;
      sub?: string;
      added: boolean;
      id: number;
    }[];
    imgClicked: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    btnClicked: (id: number) => void;
    createNewDashboard: () => void;
    oiData: {
      title: string;
      desc: string;
      directLink: boolean;
      url: string;
    }[];
  };

const AddAScreenModal = (props: any) => {
  const { toggleCreateDashboardModalOpen } = useGlobalModal();
  const {
    onCreateButtonClick,
    onDeleteFromAddModalClick,
  } = useViewPageEvents();

  return (
    <StyledModal
      title={
        props.playlist.name
          ? `Add a Screen to ${props.playlist.name}`
          : "Add a Screen"
      }
      visible={props.visible}
      width={"600px"}
      onCancel={props.close}
      footer={
        <Button key="close" onClick={props.close}>
          Close Window
        </Button>
      }
    >
      <AddScreenModalBody
        commonScreen={props.csData.map((el) => (
          <CommonScreens
            key={el.id}
            title={el.title}
            image={el.image}
            description={el.description}
            sub={el.sub && el.sub}
            added={el.added}
            id={el.id}
            imgClicked={props.imgClicked}
            addbtnClicked={() =>
              onCreateButtonClick("Common Screens", props.playlist, el.title)
            }
            removeBtnClicked={() => {
              onDeleteFromAddModalClick(el.title, props.playlist);
            }}
          />
        ))}
        myDashboards={
          <div>
            <MyDashboardTable
              dashboardData={props.dashboardData}
              addBtnClicked={(title: string) => {
                onCreateButtonClick("My Dashboards", props.playlist, title);
              }}
              removeBtnClicked={(title: string) => {
                console.log(title);
                onDeleteFromAddModalClick(title, props.playlist);
              }}
            />
            <Button
              block
              onClick={() => {
                props.close();
                toggleCreateDashboardModalOpen();
              }}
              style={{ height: "70px" }}
            >
              Create New Dashboard
            </Button>
          </div>
        }
        customURL={
          <CustomURLForm
            playlist={props.playlist}
            newTitle={props.newTitle}
            newURL={props.newURL}
            createCustomURL={props.createCustomURL}
          />
        }
        otherIdeas={props.oiData.map((el) => (
          <OtherIdeas
            title={el.title}
            key={el.title}
            desc={el.desc}
            directLink={el.directLink}
          />
        ))}
      />
    </StyledModal>
  );
};

export default AddAScreenModal;
