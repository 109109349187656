import { createContext, useContext, useState } from "react";
import { Report } from "../ReportsPageTypes";

type EditReportContextObject = {
  reportToEdit: Report | undefined;
  setReportToEdit: Function;
};

const EditReportContext = createContext<EditReportContextObject>({
  reportToEdit: undefined,
  setReportToEdit: Function,
});

export default function EditReportProvider({ children }) {
  const [reportToEdit, setReportToEdit] = useState(undefined);
  return (
    <EditReportContext.Provider
      value={{
        reportToEdit,
        setReportToEdit,
      }}
    >
      {children}
    </EditReportContext.Provider>
  );
}

export function useEditReportContext() {
  return useContext(EditReportContext);
}
