import { Progress, Skeleton } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { METRICS_OVERVIEW_DASHLET_URL } from "../../../../api.config";
import { fetchData } from "../../../../utils/axiosHelpers";
import CustomTable from "../../../Shared/Organisms/CustomTable/CustomTable";
import { dashletMockData } from "../../DashletMockData";
import { DashletProps } from "../../DashletViewPageTypes";

export default function MetricsOverviewDashlet({
  demo,
  dashletId,
}: DashletProps) {
  let { data, isLoading } = useQuery(
    ["Fetch Metrics Overview", dashletId],
    async () => await fetchData(METRICS_OVERVIEW_DASHLET_URL),
    {
      select: (data) => {
        return data;
      },
    }
  );

  if (isLoading) {
    return <Skeleton />;
  }

  data = demo ? dashletMockData["metrics_overview"] : data;
  const subTableColumns = [
    {
      title: "Host",
      dataIndex: "host",
    },
    {
      title: "Service",
      dataIndex: "service",
    },
    {
      title: "% Utilization",
      dataIndex: "value",
      render: (value) => (
        <Progress percent={Number(value.replace("%", ""))} size="small" />
      ),
    },
    {
      title: "Details",
      dataIndex: "status_text",
    },
  ];

  return (
    <CustomTable
      style={{ height: "100%", width: "100%" }}
      size="large"
      loading={isLoading}
      dataSource={data?.rows}
      columns={subTableColumns}
      bordered
      pagination={false}
    />
  );
}
