import { Card, Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { isLengthZero } from "../../../../utils";
import { IconAdd } from "../../../Shared/Atoms/FontIcons/FontIcons";
import CustomTable from "../../../Shared/Organisms/CustomTable/CustomTable";
import { Dashboard, Dashlet } from "../../DashboardPageTypes";
import DashletTableActions from "./DashletTableActions";

type Props = {
  dashboard: Dashboard;
};

export default function DashletViewTable({ dashboard }: Props) {
  const history = useHistory();
  const dashletTableColumns = (dashboard: Dashboard) => [
    {
      title: "",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      key: "action",
      render: (row: Dashlet) => (
        <DashletTableActions dashlet={row} dashboard={dashboard} />
      ),
    },
  ];

  return (
    <>
      <Card>
        {!isLengthZero(dashboard.dashlets) && (
          <CustomTable
            pagination={{ hideOnSinglePage: true }}
            dataSource={dashboard.dashlets}
            showHeader={false}
            columns={dashletTableColumns(dashboard)}
          />
        )}
        <Button
          block
          onClick={() =>
            history.push(`/dashboards/${dashboard.dashboard_id}/add_dashlet`)
          }
          style={{ height: "60px" }}
        >
          <IconAdd size="1.5em" /> Add a Dashlet
        </Button>
      </Card>
    </>
  );
}
