import { Card, Button } from "antd";
import React from "react";
import styled from "styled-components";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";
import { IconArrowRight } from "../../../Shared/Atoms/FontIcons/FontIcons";

type ReportTypeProps = {
  image: string;
  title: string;
  description: string;
  previewClicked?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  runClicked?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  scheduleClicked?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const ReportCard = styled(Card)`
  margin: 10px !important;
  max-width: 350px;
  width: calc(33.33% - 20px);
  padding: 0;
  & .ant-card-body {
    padding: 20px;
    line-height: 1.2;
    & img {
      border-radius: 8px;
      border: 2px solid ${themeVariables.colorGrayLight2};
      margin-bottom: 20px;
      width: 100%;
    }
    & > h4 {
      font-size: 16px;
    }
    & > p {
      margin-bottom: 0;
      font-size: 14px;
      color: ${themeVariables.colorGrayLight2};
    }
    & > button {
      text-align: center;
      padding: 0;
      margin-bottom: 40px;
    }
    & div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  }
`;

const ReportTypeCard = (props: ReportTypeProps) => (
  <ReportCard>
    <img src={props.image} alt="report preview" />
    <h4>{props.title}</h4>
    <p>{props.description}</p>
    <Button type="link" onClick={props.previewClicked}>
      Preview
    </Button>
    <div>
      <Button type="primary" onClick={props.runClicked}>
        <IconArrowRight size="1.5em" /> Run
      </Button>
      <Button type="link" onClick={props.scheduleClicked}>
        Schedule
      </Button>
    </div>
  </ReportCard>
);

export default ReportTypeCard;
