import React from "react";
import { RouterProvider, createRouterObjectWithIframe } from ".";
import { DashboardPage } from "../components/DashboardPage";
import { HelpPage } from "../components/HelpPage";
import { HomePage } from "../components/HomePage";
import { ReportsPage } from "../components/ReportsPage";
import { ToolsPage } from "../components/ToolsPage";
import PlaylistPage from "../components/ViewsPage/PlaylistsPage";
import { createRouterObject } from "../utils/withRouterContext";
import { LanguageProvider } from "./LanguageContext";

type Props = {
  children: JSX.Element[] | JSX.Element;
};

export function AfterAuthProvider({ children }: Props) {
  return (
    <LanguageProvider>
      <RouterProvider
        context={{
          routerObjectArray: [
            createRouterObject("Home", () => <HomePage />, "/home"),
            createRouterObject(
              "Dashboards",
              () => <DashboardPage />,
              "/dashboards"
            ),

            createRouterObject(
              "Views",
              () => <PlaylistPage />,
              "/views/playlists/manage"
            ),
            createRouterObject("Reports", () => <ReportsPage />, "/reports/"),

            createRouterObjectWithIframe("Visualizations"),
            createRouterObjectWithIframe("Incidents"),
            createRouterObjectWithIframe("Configure"),
            createRouterObjectWithIframe("Administration"),
            createRouterObject("Help", () => <HelpPage />, "/help"),
            createRouterObject("Tools", () => <ToolsPage />, "/tools"),
          ],
        }}
      >
        {children}
      </RouterProvider>
    </LanguageProvider>
  );
}
