import { createContext, useContext, useState } from "react";
import { Dashlet } from "../DashboardPageTypes";

type CurrentDashletContextObject = {
  currentDashlet: Dashlet | null;
  setCurrentDashlet: Function;
  currentDashletType: string | null;
  setCurrentDashletType: Function;
};

const CurrentDashletContext = createContext<CurrentDashletContextObject>({
  currentDashlet: null,
  setCurrentDashlet: Function,
  currentDashletType: null,
  setCurrentDashletType: Function,
});

export default function CurrentDashletProvider({ children }) {
  const [currentDashlet, setCurrentDashlet] = useState(null);
  const [currentDashletType, setCurrentDashletType] = useState(null);

  return (
    <CurrentDashletContext.Provider
      value={{
        currentDashlet,
        setCurrentDashlet,
        currentDashletType,
        setCurrentDashletType,
      }}
    >
      {children}
    </CurrentDashletContext.Provider>
  );
}

export function useCurrentDashlet() {
  return useContext(CurrentDashletContext);
}
