import React, { createContext, useState } from "react";

type SideNavObject = {
  isCollapsed: boolean;
  setIsCollapsed: Function;
};

export const SideNavContext = createContext<SideNavObject>({
  isCollapsed: false,
  setIsCollapsed: Function,
});

type Props = {
  children: JSX.Element[] | JSX.Element;
};

export function SideNavProvider({ children }: Props) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <SideNavContext.Provider
      value={{
        isCollapsed,
        setIsCollapsed,
      }}
    >
      {children}
    </SideNavContext.Provider>
  );
}
