import { Form, Select } from "antd";
import { useQueryClient } from "react-query";
import { KEY } from "../../../Frame/hooks/useHosts";
/* import { KEY as FETCH_SERVICE_KEY } from "../../../HomePage/hooks/useServices"; */
const { Option } = Select;

export default function HeatMapDashletForm() {
  const queryClient = useQueryClient();
  const hostData: any = queryClient.getQueryData(KEY);
  const serviceData: any = ["Test", "Check CPU Usage", "Printer Queue"];

  return (
    <>
      <Form.Item label="Filter By Host" name="filter_by_host">
        <Select>
          <Option value={""}> </Option>
          {hostData?.all.array.map(({ host_name }, i) => (
            <Option value={host_name} key={i}>
              {host_name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Filter By Service" name="filter_by_service">
        <Select>
          <Option value={""}> </Option>
          {serviceData.map((display_name, i) => (
            <Option value={display_name} key={i}>
              {display_name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
}
