import { ResponsiveHeatMap } from "@nivo/heatmap";
import { Skeleton } from "antd";
import { uniq } from "lodash";
import React from "react";
import { useQuery } from "react-query";
import { SLA_DASHLET_URL } from "../../../../api.config";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import { fetchData } from "../../../../utils/axiosHelpers";
import { dashletMockData } from "../../DashletMockData";
import { DashletProps } from "../../DashletViewPageTypes";

export default function SLADashlet({ demo, dashletId }: DashletProps) {
  const theme = useMasterTheme().theme;
  const formatData = (data) => {
    console.log(data);
    const services = Object.assign(
      {},
      ...Object.entries(data.services).map(([NAME, VALUES]: any) => ({
        [NAME]: Object.assign(
          {},
          ...Object.entries(VALUES).map(
            ([SERVICE_NAME, SERVICE_VALUES]: any) => ({
              [SERVICE_NAME]: SERVICE_VALUES.passed,
            })
          )
        ),
      }))
    );

    return {
      hosts: Object.entries(data.hosts).map(([NAME, VALUES]: any) => ({
        hostName: NAME,
        [NAME]: VALUES.passed,
        [`${NAME}Color`]: VALUES.passed ? "green" : "red",
        host_status: VALUES.passed,
        ...services[NAME],
      })),

      services: Object.values(services),
    };
  };

  let { data, isLoading }: any = useQuery(
    ["Fetch SLA", dashletId],
    async () => fetchData(SLA_DASHLET_URL),
    demo
      ? {
          enabled: false,
        }
      : {
          select: (data) => formatData(data),
        }
  );

  if (isLoading) {
    return <Skeleton />;
  }

  data = demo ? formatData(dashletMockData["sla"]) : data;

  const serviceNames: any = uniq(
    data?.services.map((service) => Object.keys(service)).flat()
  );

  return (
    <ResponsiveHeatMap
      data={[...data?.hosts]}
      theme={{
        textColor: theme.typographyPrimaryColor,
        tooltip: {
          container: {
            background: theme.cardBackgroundColor,
          },
        },
      }}
      label={(datum, key) => (datum[key] ? "Passed" : "Failed")}
      labelTextColor={{ from: "#ffffff" }}
      indexBy="hostName"
      keys={["host_status", ...serviceNames]}
      enableGridY={true}
      margin={{ top: 150, bottom: 100, left: 100, right: 50 }}
      axisTop={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: -90,
        legend: "Alerts",
        legendOffset: 36,
      }}
      colors={["#FF8067", "#B6FF67"]}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Host",
        legendOffset: -40,
      }}
    />
  );
}
