import { Button, Col, Form, Input, Modal, Row, Select, TimePicker } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { useGlobalModal } from "../../../../context/GlobalModalContext";
import { AdvForm, StyledCheckbox } from "../../../Shared/Atoms/Form/Form";
import { required, whitespace } from "../../../Shared/ReportValidation";
import { useCurrentReport } from "../../context/CurrentReportContext";
import useReportPageEvents from "../../hooks/useReportPageEvents";
import useReportTypes from "../../hooks/useReportTypes";
import { ReportType } from "../../ReportsPageTypes";
const { Option } = Select;

export default function ScheduleReportsModal() {
  const { data: reportTypes } = useReportTypes();
  const { currentReport } = useCurrentReport();
  const { toggleScheduleReportModalOpen, scheduleReportModal } =
    useGlobalModal();
  const { onScheduledButtonClick, scheduleReportForm } = useReportPageEvents();

  const days: number[] = Array.from(Array(31), (_, i) => i + 1);

  const frequencys = [
    { id: 1, value: 0.5, text: "12 hours" },
    { id: 2, value: 1, text: "24 hours" },
    { id: 3, value: 7, text: "1 Week" },
    { id: 4, value: 14, text: "2 Weeks" },
    { id: 5, value: 30, text: "1 Month" },
    { id: 6, value: 365, text: "1 Year" },
  ];

  return (
    <Modal
      title="Schedule Report"
      onOk={() => {
        toggleScheduleReportModalOpen();
      }}
      onCancel={() => {
        toggleScheduleReportModalOpen();
        scheduleReportForm.resetFields();
      }}
      visible={scheduleReportModal}
      footer={[
        <Button
          onClick={() => {
            toggleScheduleReportModalOpen();
            scheduleReportForm.resetFields();
          }}
        >
          Cancel
        </Button>,
        <Button
          type="primary"
          onClick={() => {
            toggleScheduleReportModalOpen();
            scheduleReportForm.submit();
            scheduleReportForm.resetFields();
          }}
        >
          Schedule Report
        </Button>,
      ]}
    >
      <AdvForm
        form={scheduleReportForm}
        layout="vertical"
        name="createReportRunTest"
        onFinish={onScheduledButtonClick}
        initialValues={{
          scheduleReport: {
            reportType: currentReport,
          },
        }}
      >
        <Form.Item
          name={"reportType"}
          label="Report Type"
          rules={[
            required("Please choose report type!"),
            whitespace("Please choose report type!"),
          ]}
        >
          <Select>
            {[...(reportTypes ? reportTypes : [])].map(
              (reportType: ReportType, i) => (
                <Option value={reportType.type_title} key={i}>
                  {reportType.type_title}
                </Option>
              )
            )}
          </Select>
        </Form.Item>
        <Form.Item
          name="frequency"
          label="Schedule Frequency"
          rules={[required("Please pick a frequency!")]}
        >
          <Select>
            {frequencys.map((frequency, i) => (
              <Select.Option value={frequency.text} key={i}>
                {frequency.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="timeToRun"
          label="Hour / Minute for report to run"
          className="short float"
          rules={[required("Please choose what time to run!")]}
        >
          <TimePicker use12Hours format="h:mm a" />
        </Form.Item>
        <Form.Item
          name="dayMonth"
          label="Day Of The Month"
          className="short"
          rules={[required("Please choose what day to run!")]}
        >
          <Select>
            {days.map((data) => (
              <Select.Option value={data} key={data}>
                {data}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="attachments"
          label="Report Attachments (Choose as many as apply)"
        >
          <Row>
            <Col span={8}>
              <StyledCheckbox value="pdf">PDF</StyledCheckbox>
            </Col>
            <Col span={16}>
              <StyledCheckbox value="csv1">
                CSV (Hosts and Services)
              </StyledCheckbox>
            </Col>
            <Col span={8}>
              <StyledCheckbox value="jpg">JPG</StyledCheckbox>
            </Col>
            <Col span={16}>
              <StyledCheckbox value="csv2">
                CSV (Hosts Data Only)
              </StyledCheckbox>
            </Col>
            <Col span={8}>
              <StyledCheckbox value="csv">CSV</StyledCheckbox>
            </Col>
            <Col span={16}>
              <StyledCheckbox value="csv3">
                CSV (Services Data Only)
              </StyledCheckbox>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          name="recipients"
          label="Recipients (Comma-separated list of email addresses)"
          rules={[required("Please choose who to send the report to!")]}
        >
          <Select
            mode="tags"
            tokenSeparators={[", "]}
            onChange={() => Function}
          ></Select>
        </Form.Item>
        <Form.Item
          name="subject"
          label="Subject"
          rules={[required("Please enter in subject!")]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="emailMessage"
          label="Email Message"
          rules={[required("Please enter in email message!")]}
        >
          <TextArea rows={4} />
        </Form.Item>
      </AdvForm>
    </Modal>
  );
}
