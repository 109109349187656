import {
  AVAILABILITY_DASHLET_URL,
  CORE_COMPONENT_STATUS_DASHLET_URL,
  DASHLET_PROPERTIES_URL,
  DASHLET_URL,
  DUPLICATE_DASHLET_URL,
  HEATMAP_DASHLET_URL,
  HOSTGROUPS_DASHLET_URL,
  LATEST_ALERTS_DASHLET_URL,
  METRICS_OVERVIEW_DASHLET_URL,
  MONITORING_ENGINE_EVENT_QUEUE_DASHLET_URL,
  MONITORING_ENGINE_PERFORMANCE_DASHLET_URL,
  MONITORING_ENGINE_PROCESSES_DASHLET_URL,
  MONITORING_ENGINE_SERVER_STATS_DASHLET_URL,
  MONITORING_ENGINE_STATS_DASHLET,
  NETWORK_DASHLET_URL,
  NETWORK_OUTAGES_DASHLET_URL,
  PERFORMANCE_DATA_GAUGE_DASHLET_URL,
  PERFORMANCE_DATA_LINE_DASHLET_URL,
  PIN_DASHLET_URL,
  SAVE_DASHLETS_URL,
  SERVICEGROUPS_DASHLET_URL,
  SLA_DASHLET_URL,
  STATUS_SUMMARY_HOST_DASHLET_URL,
  STATUS_SUMMARY_SERVICES_DASHLET_URL,
  UPDATE_DASHLET_URL,
} from "../../api.config";
import {
  createDeleteMock,
  createGetMock,
  createPostMock,
  createPutMock,
} from "../../mockServer/createMockHandler";
import { dashboardArray } from "../DashboardPage/DashboardsPageMock";
import { dashletMockData } from "./DashletMockData";

export const CREATE_DASHLET_SUCCESS_MESSAGE =
  "Successfully created dashlet 1234";
export const DELETE_DASHLET_SUCCESS_MESSAGE =
  "Successfully deleted dashlet 1234";
export const DUPLICATE_DASHLET_SUCCESS_MESSAGE =
  "Successfully duplicated dashboard 1234 to 12345";
export const EDIT_DASHLET_SUCCESS_MESSAGE = "Successfully edited dashlet 1234";
export const UPDATE_DASHLETS_SUCCESS_MESSAGE =
  "Successfully updated dashlets for dashboard 1234";
export const PIN_DASHLET_SUCESSS_MESSAGE =
  "Successfully pinned dashlets for dashboard 1234";

export const mocks = [
  createPostMock(DASHLET_URL, 200, {
    message: CREATE_DASHLET_SUCCESS_MESSAGE,
  }),

  ...dashboardArray
    .map((dashboard) =>
      dashboard.dashlets.map((dashlet) => [
        createDeleteMock(`${DASHLET_URL}/${dashlet.dashlet_id}`, 200, {
          message: DELETE_DASHLET_SUCCESS_MESSAGE,
        }),

        createPostMock(`${DUPLICATE_DASHLET_URL}/${dashlet.dashlet_id}`, 200, {
          message: DUPLICATE_DASHLET_SUCCESS_MESSAGE,
        }),
        createPutMock(`${DASHLET_URL}/${dashlet.dashlet_id}`, 200, {
          message: EDIT_DASHLET_SUCCESS_MESSAGE,
        }),

        createPutMock(`${PIN_DASHLET_URL}/${dashlet.dashlet_id}`, 200, {
          message: PIN_DASHLET_SUCESSS_MESSAGE,
        }),

        createPostMock(`${UPDATE_DASHLET_URL}/${dashlet.dashlet_id}`, 200, {
          message: UPDATE_DASHLETS_SUCCESS_MESSAGE,
        }),
      ])
    )
    .flat()
    .flat(),

  createGetMock(DASHLET_PROPERTIES_URL, 200, {
    data: {
      availability: [],
      bar: [
        {
          prop_name: "index_by",
          prop_type: "string",
        },
        {
          prop_name: "x_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "y_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "x_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "y_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "keys",
          prop_type: "keyList",
        },
        {
          prop_name: "graph_color",
          prop_type: "string",
        },
      ],
      core_component_status: [
        {
          prop_name: "index_by",
          prop_type: "string",
        },
        {
          prop_name: "keys",
          prop_type: "keyList",
        },
        {
          prop_name: "x_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "y_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "x_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "y_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "filter_by_hosts",
          prop_type: "string",
        },
        {
          prop_name: "filter_by_service",
          prop_type: "string",
        },
      ],
      customurl: [],
      gadge: [
        {
          prop_name: "keys",
          prop_type: "keyList",
        },
        {
          prop_name: "max",
          prop_type: "integer",
        },
        {
          prop_name: "current",
          prop_type: "integer",
        },
      ],
      heatmap: [
        {
          prop_name: "index_by",
          prop_type: "string",
        },
        {
          prop_name: "keys",
          prop_type: "keyList",
        },
        {
          prop_name: "x_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "y_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "x_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "y_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "filter_by_hosts",
          prop_type: "string",
        },
        {
          prop_name: "filter_by_service",
          prop_type: "string",
        },
      ],
      latest_alerts: [
        {
          prop_name: "index_by",
          prop_type: "string",
        },
        {
          prop_name: "keys",
          prop_type: "keyList",
        },
        {
          prop_name: "x_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "y_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "x_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "y_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "filter_by_hosts",
          prop_type: "string",
        },
        {
          prop_name: "filter_by_service",
          prop_type: "string",
        },
      ],
      line: [
        {
          prop_name: "index_by",
          prop_type: "string",
        },
        {
          prop_name: "keys",
          prop_type: "keyList",
        },
        {
          prop_name: "x_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "y_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "x_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "y_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "graph_color",
          prop_type: "string",
        },
      ],
      metrics_overview: [
        {
          prop_name: "keys",
          prop_type: "keyList",
        },
        {
          prop_name: "max",
          prop_type: "integer",
        },
        {
          prop_name: "current",
          prop_type: "integer",
        },
      ],
      monitoring_engine_event_queue: [
        {
          prop_name: "index_by",
          prop_type: "string",
        },
        {
          prop_name: "keys",
          prop_type: "keyList",
        },
        {
          prop_name: "x_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "y_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "x_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "y_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "graph_color",
          prop_type: "string",
        },
      ],
      monitoring_engine_stats: [
        {
          prop_name: "index_by",
          prop_type: "string",
        },
        {
          prop_name: "keys",
          prop_type: "keyList",
        },
        {
          prop_name: "x_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "y_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "x_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "y_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "graph_color",
          prop_type: "string",
        },
      ],
      monitoring_engine_performance: [
        {
          prop_name: "index_by",
          prop_type: "string",
        },
        {
          prop_name: "keys",
          prop_type: "keyList",
        },
        {
          prop_name: "x_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "y_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "x_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "y_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "graph_color",
          prop_type: "string",
        },
      ],
      monitoring_engine_processes: [
        {
          prop_name: "index_by",
          prop_type: "string",
        },
        {
          prop_name: "keys",
          prop_type: "keyList",
        },
        {
          prop_name: "x_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "y_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "x_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "y_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "filter_by_hosts",
          prop_type: "string",
        },
        {
          prop_name: "filter_by_service",
          prop_type: "string",
        },
      ],
      network: [
        {
          prop_name: "nodes",
          prop_type: "object",
        },
      ],
      network_outages: [],
      perfdata_gauge: [
        {
          prop_name: "keys",
          prop_type: "keyList",
        },
        {
          prop_name: "max",
          prop_type: "integer",
        },
        {
          prop_name: "current",
          prop_type: "integer",
        },
      ],
      perfdata_line: [
        {
          prop_name: "index_by",
          prop_type: "string",
        },
        {
          prop_name: "keys",
          prop_type: "keyList",
        },
        {
          prop_name: "x_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "y_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "x_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "y_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "graph_color",
          prop_type: "string",
        },
      ],
      perfdata_measurements: [],
      server_stats: [
        {
          prop_name: "index_by",
          prop_type: "string",
        },
        {
          prop_name: "keys",
          prop_type: "keyList",
        },
        {
          prop_name: "x_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "y_axis_title",
          prop_type: "string",
        },
        {
          prop_name: "x_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "y_axis_scale",
          prop_type: "keyList",
        },
        {
          prop_name: "graph_color",
          prop_type: "string",
        },
      ],
      sla: [],
      status_summary_hosts: [],
      status_summary_services: [],
      status_summary_hostgroups: [],
      status_summary_servicegroups: [],
    },
  }),

  createGetMock(AVAILABILITY_DASHLET_URL, 200, {
    data: dashletMockData["availability"],
  }),

  createGetMock(HEATMAP_DASHLET_URL, 200, {
    data: dashletMockData["heatmap"],
  }),

  createGetMock(PERFORMANCE_DATA_LINE_DASHLET_URL, 200, {
    data: dashletMockData["perfdata_line"],
  }),

  createGetMock(PERFORMANCE_DATA_GAUGE_DASHLET_URL, 200, {
    data: dashletMockData["perfdata_gauge"],
  }),
  createGetMock(SLA_DASHLET_URL, 200, {
    data: dashletMockData["sla"],
  }),

  createGetMock(NETWORK_DASHLET_URL, 200, {
    data: dashletMockData["network"],
  }),

  createGetMock(LATEST_ALERTS_DASHLET_URL, 200, {
    data: dashletMockData["latest_alerts"],
  }),
  createGetMock(METRICS_OVERVIEW_DASHLET_URL, 200, {
    data: dashletMockData["metrics_overview"],
  }),
  createGetMock(MONITORING_ENGINE_EVENT_QUEUE_DASHLET_URL, 200, {
    data: dashletMockData["monitoring_engine_event_queue"],
  }),
  createGetMock(MONITORING_ENGINE_STATS_DASHLET, 200, {
    data: dashletMockData["monitoring_engine_stats"],
  }),
  createGetMock(MONITORING_ENGINE_PERFORMANCE_DASHLET_URL, 200, {
    data: dashletMockData["monitoring_engine_performance"],
  }),
  createGetMock(MONITORING_ENGINE_PROCESSES_DASHLET_URL, 200, {
    data: dashletMockData["monitoring_engine_processes"],
  }),
  createGetMock(MONITORING_ENGINE_SERVER_STATS_DASHLET_URL, 200, {
    data: dashletMockData["server_stats"],
  }),
  createGetMock(CORE_COMPONENT_STATUS_DASHLET_URL, 200, {
    data: dashletMockData["core_component_status"],
  }),
  createGetMock(STATUS_SUMMARY_HOST_DASHLET_URL, 200, {
    data: {
      up: 2,
      down: 0,
      unreachable: 0,
      pending: 0,
      unhandled_problems: 0,
      total_problems: 0,
      total_records: 2,
    },
  }),
  createGetMock(STATUS_SUMMARY_SERVICES_DASHLET_URL, 200, {
    data: {
      ok: 11,
      warning: 1,
      critical: 0,
      unknown: 0,
      pending: 0,
      unhandled_problems: 1,
      total_problems: 1,
      total_records: 12,
    },
  }),
  createGetMock(HOSTGROUPS_DASHLET_URL, 200, {
    data: {
      "linux-servers": {
        alias: "Linux Servers",
        host: {
          up: 1,
          down: 0,
          unreachable: 0,
        },
        service: {
          ok: 12,
          warning: 0,
          unknown: 0,
          critical: 0,
        },
      },
    },
  }),
  createGetMock(SERVICEGROUPS_DASHLET_URL, 200, {
    data: {
      six: {
        alias: "Its Just Six Services",
        host: {
          up: 1,
          down: 0,
          unreachable: 0,
        },
        service: {
          ok: 6,
          warning: 0,
          unknown: 0,
          critical: 0,
        },
      },
    },
  }),
  ...dashboardArray.map((dashboard) =>
    createPutMock(SAVE_DASHLETS_URL(dashboard.dashboard_id), 200, {
      message: "Mass Update",
    })
  ),

  createGetMock(NETWORK_OUTAGES_DASHLET_URL, 200, {
    data: dashletMockData["network_outages"],
  }),
];
