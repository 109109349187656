import { Card, Space, Input, Checkbox, Button } from "antd";
import styled from "styled-components";
import { Form } from "antd";
import useToolPageEvents from "../../hooks/useToolPageEvents";
import React from "react";
import { IconIdeas } from "../../../Shared/Atoms/FontIcons/FontIcons";

const StyledSpace = styled(Space)`
  & > .ant-space-item:nth-child(-n + 2) {
    flex: 1;
  }
  & .ant-checkbox-inner {
    border-radius: 4px;
  }
`;

const StyledLabel = styled.label`
  font-size: 0.75em;
  font-weight: 600;
  & i {
    font-size: 15px;
  }
`;

const AddTool = (props) => {
  const { createNewToolForm, onSaveButtonClick } = useToolPageEvents();

  return (
    <Form
      form={createNewToolForm}
      onFinish={(values) => onSaveButtonClick(values)}
      initialValues={{ shared: false }}
    >
      <Card>
        <h3>Add a Tool</h3>
        <StyledSpace
          align="end"
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <Input.Group>
            <StyledLabel>
              Tool Name <IconIdeas />
            </StyledLabel>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Please input tool name!" }]}
            >
              <Input data-testid="ToolName" />
            </Form.Item>
          </Input.Group>
          <Input.Group>
            <StyledLabel>
              URL <IconIdeas />
            </StyledLabel>

            <Form.Item
              name="url"
              rules={[{ required: true, message: "Please input url name!" }]}
            >
              <Input data-testid="URL" />
            </Form.Item>
          </Input.Group>
          <Form.Item name="shared" valuePropName="checked">
            <Checkbox style={{ marginBottom: "4px" }} onChange={props.checked}>
              Make this viewable to everyone
            </Checkbox>
          </Form.Item>
          <Button
            type="primary"
            onClick={() => createNewToolForm.submit()}
            style={{ marginBottom: "22.5px" }}
          >
            Save
          </Button>
        </StyledSpace>
      </Card>
    </Form>
  );
};

export default AddTool;
