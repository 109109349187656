import { Menu, Button } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { tail } from "lodash";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  TopSpace,
  HalfSpace
} from "../../../Shared/Atoms/SideNavPopup/SideNavPopup";
import useEditSubMenuState from "../../hooks/useEditSubMenuState";
import useSideNav from "../../hooks/useSideNav";
import useUser from "../../hooks/useUser";

export type Props = {
  activeSubMenu: string;
  toggleSubMenuIsOpen: Function;
};

export default function SideNavContent({
  activeSubMenu,
  toggleSubMenuIsOpen,
}: Props) {
  const history = useHistory();
  const { data: userData } = useUser();
  const { mutate: editSubMenuState } = useEditSubMenuState();
  const { sideNav } = useSideNav();

  const [openKeys, setOpenKeys] = useState(userData?.menu[activeSubMenu]);

  const onSubMenuStateChange = ({ key }) => {
    setOpenKeys((prev) =>
      prev.includes(key)
        ? prev.filter((subMenuKey) => subMenuKey !== key)
        : [...prev, key]
    );
    editSubMenuState({ subMenuKey: key, subMenuName: activeSubMenu });
  };

  return (
    <div>
      <Menu mode="inline" className="sideNavMenu" openKeys={openKeys}>
        <TopSpace direction="vertical" size={20}>
          {activeSubMenu === "Home" ? (
            <>
              <Button
                block
                onClick={() => {
                  const link = sideNav[activeSubMenu]?.buttons[0].link;
                  history.push(link ? link : "");
                  toggleSubMenuIsOpen();
                }}
              >
                {sideNav[activeSubMenu]?.buttons[0].name}
              </Button>
              <HalfSpace size={20}>
                {tail(sideNav[activeSubMenu]?.buttons).map((button) => (
                  <Button
                    block
                    onClick={() => {
                      history.push(button?.link ? button.link : "");
                      toggleSubMenuIsOpen();
                    }}
                  >
                    {button.name}
                  </Button>
                ))}
              </HalfSpace>
            </>
          ) : (
            <>
              {sideNav[activeSubMenu]?.buttons.map((button) => (
                <Button
                  block
                  onClick={() => {
                    button?.link ? history.push(button.link) : button.action();
                    toggleSubMenuIsOpen();
                  }}
                >
                  {button.name}
                </Button>
              ))}
            </>
          )}
        </TopSpace>
        {sideNav[activeSubMenu]?.submenus.map((submenu) => (
          <SubMenu
            key={submenu.menuKey}
            title={submenu.menuName}
            onTitleClick={onSubMenuStateChange}
          >
            {submenu.menuItems.map((el) => (
              <Menu.Item
                key={el.name}
                onClick={() => {
                  toggleSubMenuIsOpen();
                }}
              >
                <Link to={el.link}>
                  {el.name}
                </Link>
              </Menu.Item>
            ))}
          </SubMenu>
        ))}
      </Menu>
    </div>
  );
}
