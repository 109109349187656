import { createContext, useContext } from "react";
import { useToggle } from "../../../hooks";

type AuthObject = {
  isHomeOpen: boolean;
  toggleHomeOpen: Function;
  isDashboardOpen: boolean;
  toggleDashboardOpen: Function;
  isViewsOpen: boolean;
  toggleViewsOpen: Function;
  isConfigOpen: boolean;
  toggleConfigOpen: Function;
  isAdminOpen: boolean;
  toggleAdminOpen: Function;
  isReportsOpen: boolean;
  toggleReportsOpen: Function;
};

export const SideNavOpenContext = createContext<AuthObject>({
  isHomeOpen: false,
  toggleHomeOpen: Function,
  isDashboardOpen: false,
  toggleDashboardOpen: Function,
  isViewsOpen: false,
  toggleViewsOpen: Function,
  isConfigOpen: false,
  toggleConfigOpen: Function,
  isAdminOpen: false,
  toggleAdminOpen: Function,
  isReportsOpen: false,
  toggleReportsOpen: Function,
});

export function SideNavOpenProvider({ children }) {
  const { open: isHomeOpen, toggleOpen: toggleHomeOpen } = useToggle();
  const {
    open: isDashboardOpen,
    toggleOpen: toggleDashboardOpen,
  } = useToggle();
  const { open: isViewsOpen, toggleOpen: toggleViewsOpen } = useToggle();
  const { open: isConfigOpen, toggleOpen: toggleConfigOpen } = useToggle();
  const { open: isAdminOpen, toggleOpen: toggleAdminOpen } = useToggle();
  const { open: isReportsOpen, toggleOpen: toggleReportsOpen } = useToggle();

  return (
    <SideNavOpenContext.Provider
      value={{
        isHomeOpen,
        toggleHomeOpen,
        isDashboardOpen,
        toggleDashboardOpen,
        isViewsOpen,
        toggleViewsOpen,
        isConfigOpen,
        toggleConfigOpen,
        isAdminOpen,
        toggleAdminOpen,
        isReportsOpen,
        toggleReportsOpen,
      }}
    >
      {children}
    </SideNavOpenContext.Provider>
  );
}

export function useSideNavOpen() {
  return useContext(SideNavOpenContext);
}
