import { ResponsiveHeatMap } from "@nivo/heatmap";
import { Skeleton } from "antd";
import { find } from "lodash";
import { useQuery } from "react-query";
import { LATEST_ALERTS_DASHLET_URL } from "../../../../api.config";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import { fetchData } from "../../../../utils/axiosHelpers";
import { dashletMockData } from "../../DashletMockData";
import { DashletProps } from "../../DashletViewPageTypes";

const CustomCell = (cell, colorObject, properties, data) => {
  const {
    label,
    x,
    y,
    opacity,
    borderWidth,
    borderColor,
    onHover,
    onLeave,
    onClick,
  } = cell;

  const theme = useMasterTheme().theme;

  if (label === undefined) {
    return null;
  }

  let r = 29.73263888888889;

  return (
    <g
      transform={`translate(${x}, ${y})`}
      onMouseEnter={onHover}
      onMouseMove={onHover}
      onMouseLeave={onLeave}
      onClick={onClick}
    >
      <circle
        r={properties.height < 500 ? r / 3 : r / 1.5}
        fill={colorObject[label]}
        fillOpacity={opacity}
        strokeWidth={borderWidth}
        stroke={borderColor}
      >
        <text
          dominantBaseline="central"
          textAnchor="middle"
          style={{ fill: theme.typographyPrimaryColor }}
        >
          {label}
        </text>
      </circle>
    </g>
  );
};

export default function LatestAlertDashlet({
  properties,
  demo,
  dashletId,
}: DashletProps) {
  const formatData = (data) =>
    data.map(({ service_problems, ...rest }) =>
      Object.assign(
        {
          ...rest,
          service_problems: Object.entries(service_problems)
            .map(([SERVICE_STATE, SERVICES]: any) => {
              return SERVICES.map((service) => ({
                name: service,
                state: SERVICE_STATE,
              }));
            })
            .flat(),
        },
        ...Object.entries(service_problems)
          .map(([SERVICE_STATE, SERVICES]: any) => {
            return SERVICES.map((service) => ({
              [service]: SERVICE_STATE,
            }));
          })
          .flat()
      )
    );

  const theme = useMasterTheme().theme;
  let { data, isLoading } = useQuery(
    ["Fetch Latest Alerts", dashletId],
    () => fetchData(LATEST_ALERTS_DASHLET_URL),

    demo
      ? {
          enabled: false,
        }
      : {
          select: (data) => formatData(data),
        }
  );

  const colorObject = {
    0: "green",
    1: "red",
    warning: "yellow",
    critical: "red",
    unknown: "orange",
    handled: "gray",
  };

  if (isLoading) {
    return <p>Is Loading</p>;
  }

  data = demo ? formatData(dashletMockData["latest_alerts"]) : data;
  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : data.length === 0 ? (
        <p>No Alerts</p>
      ) : (
        <ResponsiveHeatMap
          data={data}
          theme={{
            textColor: theme.typographyPrimaryColor,
            tooltip: {
              container: {
                background: theme.cardBackgroundColor,
              },
            },
          }}
          indexBy="host"
          keys={[
            "host_state",
            ...data
              .map(({ service_problems }: any) => {
                return service_problems.map(({ name }: any) => name);
              })
              .flat(),
          ]}
          cellShape={(cell) =>
            CustomCell(
              cell,
              colorObject,
              properties,
              find(data, { host: cell.data.yKey })
            )
          }
          enableLabels={false}
          enableGridX={true}
          enableGridY={true}
          margin={{ top: 150, bottom: 100, left: 100, right: 50 }}
          axisTop={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: -90,
            legend: "Alerts",
            legendOffset: 36,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Source",
            legendOffset: 40,
          }}
        />
      )}
    </>
  );
}
