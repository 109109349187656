import styled from "styled-components";
import { Button, Divider } from "antd";
import { Link } from "react-router-dom";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";

type styleProps = {
  width?: string;
  align?: string;
  children?: JSX.Element;
};

const getStyleFrom = (props: styleProps): styleProps => {
  let style = {};
  style = { ...style, padding: "15px" };
  style = props.width ? { ...style, width: props.width } : style;
  style = props.align ? { ...style, textAlign: props.align } : style;
  return style;
};

export const StyledRow = styled.tr`
  & td {
    padding-bottom: 6px;
  }
  & > td:first-child {
    font-weight: 500;
    width: 140px;
  }
  & > td:nth-child(2) {
    text-align: right;
  }
  & > td:last-child {
    width: 60px;
    text-align: right;
  }
`;

export const StyledDiv = styled.div`
  padding: 20px 0px;
`;

export const StyledP = styled.p`
  color: ${themeVariables.colorGrayLight1};
  font-size: 0.8em;
  font-style: italic;
`;

export const LastCheck = styled.div`
  color: ${themeVariables.colorGrayLight1};
  font-size: 0.8em;
  font-style: italic;
`;

export const StyledP2 = styled.p`
  margin: 10px !important;
  & span {
    color: ${themeVariables.colorGrayLight1};
  }
`;

export const StyledH2 = styled.h2`
  color: ${themeVariables.colorWhite};
  font-size: 1.1em;
`;

export const StyledHR = styled.hr`
  border-color: ${themeVariables.colorGrayLight1};
  margin: 20px 0px;
`;

export const StyledSub = styled.p`
  color: ${themeVariables.colorGrayLight1};
  font-style: italic;
  font-size: 0.9em;
`;

export const StyledIcon = styled.p`
  & > i {
    margin-right: 15px;
  }
`;

export const StyledButton = styled(Button)`
  text-align: left !important;
`;

export const ViewLink = styled(Link)`
  color: ${themeVariables.colorWhite};
  text-decoration: underline;
`;

export const TableDivider = styled(Divider)`
  border-top: 1px solid rgba(255, 255, 255, 0.20);
  margin: 15px 0 !important;
`;

export const HeaderPopup = (props: styleProps) => (
  <div style={getStyleFrom(props)}>{props.children}</div>
);
