import alice from "../../../Shared/Atoms/Images/support-team-alice.png";
import anthony from "../../../Shared/Atoms/Images/support-team-anthony.png";
import clayton from "../../../Shared/Atoms/Images/support-team-clayton.png";
import karl from "../../../Shared/Atoms/Images/support-team-karl.png";
import marcia from "../../../Shared/Atoms/Images/support-team-marcia.png";
import trevor from "../../../Shared/Atoms/Images/support-team-trevor.png";

export const supportTeamMembers = [
  { name: "Alice T.", image: alice },
  { name: "Anthony M.", image: anthony },
  { name: "Clayton F.", image: clayton },
  { name: "Karl S.", image: karl },
  { name: "Marcia M.", image: marcia },
  { name: "Trevor H.", image: trevor },
];
