import React, { useContext } from "react";
import { Card, Divider, Button } from "antd";
import styled from "styled-components";
import { NagiosContext } from "../../../../context";
import { useTranslator } from "../../../../hooks";
import StatusRowList from "../StatusRowList";
import { Props as Row } from "../../Atoms/StatusRow/StatusRow";
import useServices from "../../../Frame/hooks/useServices";
import useHosts from "../../../Frame/hooks/useHosts";

const { Meta } = Card;

const StyledButton = styled(Button)`
  width: 289px;
  height: 40px;
  border-radius: 8px;
  border: 2px solid #000000;
  color: #0b89ff;
  font-family: "DM Sans - Bold";
  font-size: 14px;
  font-weight: 700;
`;

type Props = {
  title: string;
};

export default function StatusContent({ title }: Props) {
  let { [title]: context } = useContext(NagiosContext);
  const { BUTTON_NAME } = useTranslator("BUTTON_NAME");
  const { lastUpdated } = context;

  const isTitleHosts = title === "hosts";

  const { data: hostData } = useHosts();
  const { data: serviceData } = useServices();

  const createStatusRow: any = (name: string, count: number) => ({
    name: name.charAt(0).toUpperCase() + name.slice(1),
    color: "red",
    count,
  });

  /* const keyList: string[] = isTitleHosts */
  /*   ? ["up", "down", "unreachable", "pending", "all"] */
  /*   : ["OK", "Warning", "Critical", "Unknown", "All"]; */

  const keyList: string[] = ["OK", "Warning", "Critical", "Unknown", "All"];

  const rowList: Row[] = keyList.map((key) => {
    const count =
      key === "All"
        ? isTitleHosts
          ? hostData?.hosts.length
          : serviceData?.services.length
        : isTitleHosts
        ? hostData?.hosts.filter(({ state_type }) => state_type === key).length
        : serviceData?.services.filter(({ state_type }) => state_type === key)
            .length;

    return createStatusRow(key, count);
  });

  return (
    <>
      <StatusRowList rowList={rowList} />
      <Divider />
      <StyledButton>{BUTTON_NAME}</StyledButton>
      <Meta description={`${lastUpdated}`} />
    </>
  );
}
