import { setupWorker } from "msw";
import { mocks } from "./mocks";

const worker = setupWorker(...mocks);

// only show if in dev mode/mock turned on
if (process.env.NODE_ENV === "development" || process.env.REACT_APP_USE_MOCK === "on") {
	console.log(mocks);
	console.log(worker);
}

export * from "./createMockHandler";
export default worker;
