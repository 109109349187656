import { useQuery } from "react-query";
import { TOOLS_URL } from "../../../api.config";
import { fetchData } from "../../../utils/axiosHelpers";

export const KEY = "Fetch Tools";
export const FETCH_TOOL_KEY = "Fetch Tool";

const fetchTools = async () => fetchData(TOOLS_URL);

export const preFetchTools = (queryClient) =>
  queryClient.prefetchQuery(KEY, fetchTools);

export default function useTools(options?: any) {
  return useQuery(KEY, fetchTools, {
    select: (data) =>
      data.length === 0
        ? []
        : data.tool.map(({ shared, ...rest }) => ({
            ...rest,
            shared: shared === 1,
          })),
    ...options,
    placeholderData: [],
    notifyOnChangeProps: ["data"],
    refetchOnWindowFocus: false,
  });
}

export function useTool(toolId?: any, options?: any) {
  return useQuery(
    [FETCH_TOOL_KEY, toolId],
    () => fetchData(`${TOOLS_URL}/${toolId}`),
    {
      select: (data) => data.tool,
      ...options,
    }
  );
}
