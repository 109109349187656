import React from "react";
import { useToggle } from "../../../../hooks";
import useDashletPageEvents from "../../../DashletPage/hooks/useDashletPageEvents";
import { DashletModal } from "../../../DashletPage/Molecules/DashletModal";
import { DuplicateDashletModal } from "../../../DashletPage/Molecules/DuplicateDashletModal";
import { TableIcons } from "../../../Shared/Molecules/TableIcons";
import { Dashboard, Dashlet } from "../../DashboardPageTypes";

type Props = {
  dashlet: Dashlet;
  dashboard: Dashboard;
};
export default function DashletTableActions({ dashlet, dashboard }: Props) {
  const { onDeleteDashletButtonClick, onPinDashletButtonClick } =
    useDashletPageEvents(dashboard.dashboard_id);

  const { open: dashletModalOpen, toggleOpen: toggleDashletModalOpen } =
    useToggle();
  const {
    open: duplicateDashletModalOpen,
    toggleOpen: toggleDuplicateDashletModalOpen,
  } = useToggle();

  return (
    <>
      <TableIcons
        icons={[
          {
            iconType: "edit",
            onClick: () => toggleDashletModalOpen(),
            withTooltip: true,
          },
          {
            iconType: "duplicate",
            onClick: () => toggleDuplicateDashletModalOpen(),
            withTooltip: true,
          },
          {
            iconType: "delete",
            onClick: () => onDeleteDashletButtonClick(dashlet),
            withTooltip: true,
          },
          {
            iconType: "pin",
            onClick: () => onPinDashletButtonClick(dashlet),
            withTooltip: true,
          },
        ]}
        isPinned={dashlet.properties.pinned}
      />

      <DashletModal
        open={dashletModalOpen}
        toggleOpen={toggleDashletModalOpen}
        dashlet={dashlet}
        dashboard={dashboard}
        editDashlet={true}
      />
      <DuplicateDashletModal
        dashboard={dashboard}
        dashlet={dashlet}
        open={duplicateDashletModalOpen}
        toggleOpen={toggleDuplicateDashletModalOpen}
      />
    </>
  );
}
