import { useMutation, useQueryClient } from "react-query";
import { FAVORITES_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { postData } from "../../../utils/axiosHelpers";
import { Favorites } from "../FrameTypes";
import { KEY } from "./useFavorites";

export default function useCreateFavorite() {
  const CALL_REASON = `Create New Favorite`;

  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation((values) => postData(FAVORITES_URL, values), {
    onMutate: async (favoriteToCreate: any) => {
      await queryClient.cancelQueries(KEY);
      const previousFavoriteList: any = queryClient.getQueryData(KEY);

      const newFavoriteList = [
        ...previousFavoriteList?.favorites,
        {
          favorite_id: Math.random().toString(),
          ...favoriteToCreate,
        },
      ];

      queryClient.setQueryData(KEY, {
        favorites: newFavoriteList,
      });

      return {
        previousFavoriteList,
        newFavoriteList,
      };
    },
    onSuccess: (data, _, context) => {
      onSuccessNotification(data, CALL_REASON);

      queryClient.setQueryData(KEY, {
        favorites: context?.newFavoriteList,
      });
    },
    onError: (data, _, context) => {
      onErrorNotification(data, CALL_REASON);
      if (context?.previousFavoriteList) {
        queryClient.setQueryData<Favorites>(
          [KEY],
          context.previousFavoriteList
        );
      }
    },

    onSettled: () => {
      queryClient.invalidateQueries(KEY);
    },
  });
}
