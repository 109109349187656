import styled from "styled-components";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";

const Tasks = styled.div<{ bg: string, completed: number }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  h4 {
    width: 40px;
    margin-right: 10px;
    font-size: 16px;
  }
  .gaugeContainer {
    width: calc(100% - 50px);
    height: 10px;
    border-radius: 4px;
    position: relative;
    margin-bottom: 10px;
    align-self: center;
    background-color: ${(props) => props.bg};
  }
  .gaugeContainer__fill {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: calc(100% * ${(props) => props.completed} / 10);
    border-radius: 4px;
    background-color: ${themeVariables.colorSuccess};
  }
  p {
    margin-bottom: 0px;
  }
`;

const OnboardingAssistant = (props) => {
  const theme = useMasterTheme().theme;
  return (
    <Tasks bg={theme.bodyBackgroundColor} completed={props.completed}>
      <h4>{props.completed > 0 ? props.completed + "0%" : "0%"}</h4>
      <div className="gaugeContainer">
        <div className="gaugeContainer__fill" />
      </div>
      <p>
        {props.completed} of 10 <a href="/">tasks completed</a>
      </p>
    </Tasks>
  );
};

export default OnboardingAssistant;
