import { Skeleton } from "antd";
import { useQuery } from "react-query";
import { MONITORING_ENGINE_SERVER_STATS_DASHLET_URL } from "../../../../api.config";
import { fetchData } from "../../../../utils/axiosHelpers";
import { ResponsiveBullet } from "@nivo/bullet";
import { tail } from "lodash";
import { dashletMockData } from "../../DashletMockData";
import { DashletProps } from "../../DashletViewPageTypes";

const formatData = (data) => {
  return data
    ? Object.entries(data).map(([METRIC, VALUES]: any) => {
        return { metric: METRIC, values: VALUES };
      })
    : [];
};

export default function ServerStatisticsDashlet({
  demo,
  dashletId,
}: DashletProps) {
  let { data, isLoading }: any = useQuery(
    ["Fetch Server Statistics", dashletId],
    () => fetchData(MONITORING_ENGINE_SERVER_STATS_DASHLET_URL),
    demo
      ? {
          enabled: false,
        }
      : {
          select: (data) => formatData(data),
        }
  );

  if (isLoading) {
    return <Skeleton />;
  }

  data = demo ? formatData(dashletMockData["server_stats"]) : data;

  return (
    <ResponsiveBullet
      data={[
        ...Object.entries(data[0]?.values)
          .filter(([_, VALUES]) => typeof VALUES !== "string")
          .map(([NAME, { val, warn, crit }]: any) => ({
            id: NAME,
            ranges: [warn - 1, warn, crit, crit + 1],
            measures: [val],
            markers: [],
          })),
        ...Object.entries(data[1]?.values).map(
          ([NAME, { val, warn, crit }]: any) => ({
            id: NAME,
            ranges: [warn - 1, warn, crit, crit + 1],
            measures: [val],
            markers: [],
          })
        ),
        {
          id: data[2]?.metric,
          ranges: [
            data[2]?.values?.used?.warn - 1,
            data[2]?.values?.used?.warn,
            data[2]?.values?.used?.crit,
            data[2]?.values?.total,
          ],
          measures: [
            data[2]?.values?.used.val,
            data[2]?.values?.shared,
            data[2]?.values?.free,
            data[2]?.values?.buffers,
            data[2]?.values?.cached,
          ],
          markers: [],
        },
        {
          id: data[3]?.metric,
          ranges: [
            ...tail(Object.values(data[3]?.values?.used)),
            data[3]?.values?.total,
          ],
          measures: [data[3]?.values.used["0"], data[3]?.values?.free],
          markers: [],
        },
      ]}
      margin={{ top: 50, right: 90, bottom: 50, left: 90 }}
      spacing={46}
      titleAlign="start"
      titleOffsetX={-70}
      measureSize={0.2}
    />
  );
}
