import moment from "moment";
import { useQuery } from "react-query";
import { REPORT_URL } from "../../../api.config";
import { fetchData } from "../../../utils/axiosHelpers";

export const KEY = "Fetch Reports";
export const FETCH_TOOL_KEY = "Fetch Report";

const fetchReports = async () => fetchData(REPORT_URL);

export const preFetchReports = (queryClient) =>
  queryClient.prefetchQuery(KEY, fetchReports);

type FilterProps = {
  name: string;
  params: any;
};
export default function useReports(filters: FilterProps, options?: any) {
  return useQuery(KEY, fetchReports, {
    select: (data) => {
      if (filters.name === "") {
        return data.reports;
      }

      if (filters.name === "Search") {
        return data.reports.filter(({ name }) => name.includes(filters.params));
      }

      if (filters.name === "Type") {
        return data.reports.filter(({ type }) => type === filters.params);
      }

      if (filters.name === "DateRange") {
        const [startingDate, endingDate] = filters.params;
        return data.reports.filter(({ created_at }) =>
          moment(created_at).isBetween(startingDate, endingDate)
        );
      }

      if (filters.name === "Custom") {
        return filters.params
          ? data.reports.filter(({ name }) => name !== "")
          : data.reports;
      }

      if (filters.name === "Scheduled") {
        return filters.params
          ? data.reports.filter(({ scheduled }) => scheduled)
          : data.reports;
      }
    },

    ...options,
    initalData: true,
    placeholderData: [],
  });
}

export function useReport(reportId?: any, options?: any) {
  return useQuery(
    [FETCH_TOOL_KEY, reportId],
    () => fetchData(`${REPORT_URL}/${reportId}`),
    {
      select: (data) => data.reports,
      ...options,
    }
  );
}
