import { Card, Button } from "antd";
import React from "react";
import styled from "styled-components";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";
import { IconExternalLink } from "../../../Shared/Atoms/FontIcons/FontIcons";

const SupportCard = styled(Card)`
  & .ant-card-body {
    & h4 {
      font-size: 16px;
    }
    & a {
      padding: 0 !important;
      font-size: 14px;
    }
    & p {
      font-size: 14px;
      color: ${themeVariables.colorGrayLight1};
      margin-bottom: 20px;
    }
  }
`;

const ExternalResources = () => {
  return (
    <SupportCard>
      <h4>External Support Resources</h4>
      <Button
        type={"link"}
        href="https://support.nagios.com/forum/"
        target="_blank"
        rel="noreferrer noopener"
      >
        Nagios Support Forum (Public) <IconExternalLink size="1.5em" />
      </Button>
      <p>
        Visit to find how others out in the Nagios community use their software.
        Being an extensible product, the possibililites to discover and create
        are endless.
      </p>
      <Button
        type={"link"}
        href="https://support.nagios.com/kb/"
        target="_blank"
        rel="noreferrer noopener"
      >
        Knowledge Base <IconExternalLink size="1.5em" />
      </Button>
      <p>Find documentation from [lorem ipsum dit solor and vitoritum].</p>
      <Button
        type={"link"}
        href="https://www.youtube.com/user/nagiosvideo"
        target="_blank"
        rel="noreferrer noopener"
      >
        Nagios YouTube Channel <IconExternalLink size="1.5em" />
      </Button>
      <p>Watch videos from [lorem ipsum dit solor and vitoritum].</p>
      <Button
        type={"link"}
        href="https://library.nagios.com/"
        target="_blank"
        rel="noreferrer noopener"
      >
        Nagios Library <IconExternalLink size="1.5em" />
      </Button>
      <p>Find documentation from [lorem ipsum dit solor and vitoritum].</p>
    </SupportCard>
  );
};

export default ExternalResources;
