import { Dashboard } from "../../DashboardPage/DashboardPageTypes";
import useDashboards from "../../DashboardPage/hooks/useDashboards";
import usePlaylist from "./usePlaylists";

export default function useCommonScreens() {
  const { data: playlists } = usePlaylist();
  const { data: dashboards } = useDashboards({ name: "", params: "" });

  const isAdded = (title: string, playlist: any) =>
    Object.values(playlists ? playlist?.screens : [])
      .map(({ name }: any) => name)
      .includes(title);

  return [...(playlists ? playlists : [])].map((playlist: any) =>
    [...(dashboards ? dashboards : [])].map((dashboard: Dashboard) => ({
      dashboardId: dashboard.dashboard_id,
      name: dashboard.name,
      added: isAdded(dashboard.name, playlist),
    }))
  );
}
