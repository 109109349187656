import { useQuery } from "react-query";
import {
  DASHBOARD_URL,
  DASHLET_URL,
  FETCH_DASHBOARD_URL,
} from "../../../api.config";
import { fetchData } from "../../../utils/axiosHelpers";

export const KEY = "Fetch Dashboards";
export const FETCH_DASHLETS_KEY = "Fetch Dashlets";
export const FETCH_DASHBOARD_KEY = "Fetch Dashboard";

const fetchDashboards = async () =>
  fetchData(`${DASHBOARD_URL}?dashlet_detail_level=3
`);

const fetchDashlets = async () => fetchData(DASHLET_URL);

export const preFetchDashboards = (queryClient) =>
  queryClient.prefetchQuery(KEY, fetchDashboards);

export const preFetchDashlets = (queryClient) =>
  queryClient.prefetchQuery(FETCH_DASHLETS_KEY, fetchDashlets);

export const preFetchDashletData = (queryClient, dashlet) =>
  queryClient.prefetchQuery(
    `Fetching ${dashlet.name}`,
    fetchData(dashlet.url),
    {
      staleTime: 1000 * 60 * 60 * 24,
    }
  );

type FilterProps = {
  name: string;
  params: any;
};

export default function useDashboards(
  filters: FilterProps,
  dashboardId?: any,
  options?: any
) {
  return useQuery(KEY, fetchDashboards, {
    select: (data) => {
      if (data.length === 0) {
        return [];
      } else {
        const dataWithTagList = data.dashboards.map(
          ({ read_only, editable, public: publicValue, ...rest }) => {
            const isTagOne = (tag, name) => (tag === 1 ? name : "");
            return {
              ...rest,
              tagList: [
                isTagOne(read_only, "read_only"),
                isTagOne(editable, "editable"),
                isTagOne(publicValue, "public"),
              ].filter((tag) => tag !== ""),
            };
          }
        );

        if (filters.name === "") {
          return dataWithTagList;
        }

        if (filters.name === "Search") {
          return dataWithTagList.filter(({ name }) =>
            name.includes(filters.params)
          );
        }
        if (filters.name === "Type") {
          console.log(filters.params);
          return dataWithTagList.filter(({ tagList }) =>
            tagList.includes(filters.params)
          );
        }
      }
    },
    ...options,
    initalData: true,
    placeholderData: [],
    refetchOnWindowFocus: false,
  });
}

export function useDashlets(filters: FilterProps, options?: any) {
  return useQuery(FETCH_DASHLETS_KEY, fetchDashlets, {
    select: (data) => {
      if (filters.name === "") {
        return data;
      }

      if (filters.name === "Search") {
        return data.filter(({ name }) => name.includes(filters.params));
      }
    },
    ...options,
    placeholderData: [],
    refetchOnWindowFocus: false,
  });
}

export function useDashboard(dashboardId?: any, options?: any) {
  return useQuery(
    [FETCH_DASHBOARD_KEY, Number(dashboardId)],
    () => fetchData(FETCH_DASHBOARD_URL(dashboardId)),
    {
      select: (data) => data.dashboard,
      ...options,
    }
  );
}
