import { Checkbox, Col, Popover, Row } from "antd";
import React from "react";
import CustomTable from "../../../Shared/Organisms/CustomTable/CustomTable";
import { UserTableActions } from "../../Atoms/UserTableActions";
import useUsers from "../../hooks/useUsers";
import useUsersPageEvents from "../../hooks/useUsersPageEvents";
import { Users } from "../../UsersPageTypes";

export type Props = {
  filterOption: any;
  setSelectedUsers: Function;
};

export default function UsersTable({ filterOption, setSelectedUsers }: Props) {
  const { onEditUserClick } = useUsersPageEvents();
  const columns = [
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      render: (text, record) => (
        <Row>
          {text && (
            <>
              <Col span={8}>
                <p>{text}</p>
              </Col>
              <Col span={12}>
                <Popover
                  title={`Phone number ${
                    record.is_authenticated === 1 ? "is" : "is not"
                  } authenticated.`}
                >
                  <Checkbox checked={record.is_authenticated === 1} />
                </Popover>
              </Col>
            </>
          )}
        </Row>
      ),
    },

    {
      title: "Last Login",
      dataIndex: "last_login",
      key: "last_login",
    },
    {
      title: "Admin",
      dataIndex: "is_admin",
      key: "is_admin",
      render: (text, record) => (
        <Checkbox
          checked={record.is_admin === 1}
          onClick={() => {
            const { is_admin, ...rest } = record;
            onEditUserClick({ ...rest, is_admin: is_admin ? 0 : 1 });
          }}
        />
      ),
    },

    {
      title: "Auth Type",
      dataIndex: "auth_type",
      key: "auth_type",
    },
    {
      title: "Enabled",
      dataIndex: "enabled",
      key: "enabled",
      render: (text, record) => (
        <Checkbox
          checked={record.enabled}
          onClick={() => {
            const { enabled, ...rest } = record;
            onEditUserClick({ ...rest, enabled: enabled ? 0 : 1 });
          }}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => <UserTableActions user={record} />,
    },
  ];

  const { data: users, isLoading } = useUsers(filterOption);
  return (
    <CustomTable
      rowKey="user_id"
      dataSource={users}
      columns={columns}
      loading={isLoading}
      rowSelection={{
        type: "checkbox",
        onChange: (_, selectedRows: Users) => setSelectedUsers(selectedRows),
      }}
    />
  );
}
