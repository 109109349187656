import { Modal, Button } from "antd";
import styled from "styled-components";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";

const StyledModal = styled(Modal)`
  & .ant-modal-header {
    border-bottom: 1px solid ${themeVariables.colorGrayLight3};
  }
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export type ModalProps = {
  title: string;
  visible: boolean;
  close: () => void;
  next?: boolean;
  clickNext?: () => void;
  modalBody: JSX.Element;
};

const FAQModal = (props: ModalProps) => {
  return (
    <StyledModal
      width="800px"
      title={props.title}
      visible={props.visible}
      onCancel={props.close}
      footer={
        props.next ? (
          <FlexBox>
            <Button type="link" onClick={props.clickNext}>
              See next FAQ
            </Button>
            <Button key="close" onClick={props.close}>
              Close
            </Button>
          </FlexBox>
        ) : (
          <Button key="close" onClick={props.close}>
            Close
          </Button>
        )
      }
    >
      {props.modalBody}
    </StyledModal>
  );
};

export default FAQModal;
