import { ResponsiveHeatMap } from "@nivo/heatmap";
import React from "react";
import { useQuery } from "react-query";
import { MONITORING_ENGINE_PROCESSES_DASHLET_URL } from "../../../../api.config";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import { fetchData } from "../../../../utils/axiosHelpers";
import { dashletMockData } from "../../DashletMockData";
import { DashletProps } from "../../DashletViewPageTypes";
import CustomCell from "../Shared/Custom";

export default function MonitoringEngineProcessDashlet({
  properties,
  demo,
  dashletId,
}: DashletProps) {
  const theme = useMasterTheme().theme;

  const formatData = (data) =>
    Object.entries(data)
      .map(([NAME, VALUES]: any) => ({
        name: NAME,
        status: VALUES,
      }))
      .filter(({ status }) => typeof status !== "string")
      .filter(({ name }) => name !== "process_id");

  const { data, isLoading } = useQuery(
    ["Fetch Monitoring Engine Process", dashletId],
    async () => await fetchData(MONITORING_ENGINE_PROCESSES_DASHLET_URL),
    {
      select: ({ data }) => data,
    }
  );

  if (isLoading) {
    return <p>Is Loading</p>;
  }

  return (
    <ResponsiveHeatMap
      data={
        demo ? formatData(dashletMockData["monitoring_engine_processes"]) : data
      }
      keys={["status"]}
      indexBy="name"
      enableGridX={true}
      enableGridY={true}
      theme={{
        textColor: theme.typographyPrimaryColor,
        tooltip: {
          container: {
            background: theme.cardBackgroundColor,
          },
        },
      }}
      cellShape={(cell) =>
        CustomCell(
          cell,
          {
            0: "red",
            1: "green",
          },
          properties
        )
      }
      margin={{ top: 150, bottom: 100, left: 200, right: 50 }}
      axisTop={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: -90,
        legend: "",
        legendOffset: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Service",
        legendPosition: "middle",
        legendOffset: -150,
      }}
    />
  );
}
