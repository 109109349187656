export default function useShareReport() {
  const downloadAsPDF = (report) => {};
  const emailPDF = (report) => {};
  const shareToTeamsMyReports = (report) => {};

  return {
    downloadAsPDF,
    emailPDF,
    shareToTeamsMyReports,
  };
}
