
import styled from "styled-components";

const StyledLogo = styled.div`
  color: #fff;
  font-size: 24px;
  line-height: 40px;
  display: inline-block;
  font-weight: 700;
  margin: 0 30px 0 5px;
`;

const StyledLogoIcon = styled.div`
  margin-right: 15px;
`;

const Logo = () => {
  return (
    <StyledLogo>
      <StyledLogoIcon className="logo">XI</StyledLogoIcon>
      NAGIOS XI
    </StyledLogo>
  );
};

export default Logo;
