import React from "react";

export default function AuditSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 55 55"
    >
      <g>
        <g>
          <path
            fill="#dbfbf5"
            d="M0 18C0 8.059 8.059 0 18 0h19c9.941 0 18 8.059 18 18v19c0 9.941-8.059 18-18 18H18C8.059 55 0 46.941 0 37z"
          />
        </g>
        <g>
          <g>
            <g>
              <path d="M35.875 33.005c0-1.934-1.566-3.5-3.5-3.5a3.498 3.498 0 0 0-3.5 3.5c0 1.934 1.566 3.5 3.5 3.5s3.5-1.566 3.5-3.5zm3.793 6.225a.247.247 0 0 1 0 .35l-.968.968a.251.251 0 0 1-.175.072.243.243 0 0 1-.175-.072l-2.957-2.957a5.496 5.496 0 1 1 1.361-1.274zM26 37.88v1.75a.25.25 0 0 1-.25.25h-8.5c-.553 0-1-.447-1-1v-24.5c0-.553.447-1 1-1h20c.553 0 1 .447 1 1v12a.25.25 0 0 1-.25.25h-1.75a.25.25 0 0 1-.25-.25V15.63H18.5v22h7.25a.25.25 0 0 1 .25.25zm1-14.375a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25h-5.75a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25zm6.25-2.5h-12a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h12a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25z" />
            </g>
          </g>
          <g />
        </g>
      </g>
    </svg>
  );
}
