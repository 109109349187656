import { IconCircle, IconClose } from "../../Atoms/FontIcons/FontIcons";
const ViewsFrame = (props) => {
  return (
    <div
      className={props.iframeVisible ? "views-frame visible" : "views-frame"}
    >
      <IconCircle onClick={props.hideIframe} color="#fff" size="20px">
        <IconClose color={"#666"} />
      </IconCircle>
      <iframe
        title={"Views iFrame"}
        src={props.viewsSource}
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};

export default ViewsFrame;
