import { useHistory } from "react-router-dom";
import { useGlobalModal } from "../../../context/GlobalModalContext";
import { useMasterTheme } from "../../../context/MasterThemeContext";
import { Dashboard } from "../../DashboardPage/DashboardPageTypes";
import useDashboards from "../../DashboardPage/hooks/useDashboards";
import useReportTypes from "../../ReportsPage/hooks/useReportTypes";
import useTools from "../../ToolsPage/hooks/useTools";
import usePlaylist from "../../ViewsPage/hooks/usePlaylists";
import { Playlist } from "../../ViewsPage/ViewsPageTypes";
import useWizards from "./useWizards";

type Button = {
  name: string;
  action?: any;
  link?: string;
  legacyPath?: string;
};

type SubMenu = {
  name: string;
  link: string;
  path?: string;
  legacyPath?: string;
};

export default function useSideNav(options?: any) {
  const { data: dashboards } = useDashboards(
    { name: "", params: "" },
    undefined,
    { refetchOnWindowFocus: false }
  );

  const history = useHistory();
  const { data: playlists } = usePlaylist({ refetchOnWindowFocus: false });
  const { data: tools } = useTools({ refetchOnWindowFocus: false });
  const { data: wizards } = useWizards({ refetchOnWindowFocus: false });
  const { data: reportTypes } = useReportTypes();

  const { currentMasterTheme, setCurrentMasterTheme } = useMasterTheme();

  const {
    toggleCreateDashboardModalOpen,
    toggleAddAToolModalOpen,
    toggleCreateAReportModalOpen,
  } = useGlobalModal();

  const statusPath = `/status`;
  const componentPath = `/components`;
  const adminPath = `/admin`;

  const createSideNavObject = (buttons: Button[], submenus) => ({
    buttons,
    submenus,
  });
  const createSubMenuObject = (
    menuKey: string,
    menuName: string,
    menuItems: SubMenu[]
  ) => ({
    menuName,
    menuKey,
    menuItems,
  });

  const sideNav = {
    Home: createSideNavObject(
      [
        {
          name: "Home Dashboard",
          link: "/home",
        },
        {
          name: "Hosts",
          link: `${statusPath}/hosts`,
        },
        {
          name: "Services",
          link: `${statusPath}/services`,
        },
      ],
      [
        createSubMenuObject("details", "Status Details", [
          {
            name: "Host Status",
            link: `${statusPath}/hosts`,
            path: `${statusPath}/hosts/:filter?`,
            legacyPath:
              "nagiosxi/includes/components/xicore/status.php?show=hosts",
          },
          {
            name: "Service Status",
            link: `${statusPath}/services`,
            path: `${statusPath}/services/:filter?`,
            legacyPath:
              "nagiosxi/includes/components/xicore/status.php?show=services",
          },
          {
            name: "Hostgroup Summary",
            link: `${statusPath}/hostgroups`,
            legacyPath:
              "nagiosxi/includes/components/xicore/status.php?show=hostgroups&hostgroup=all&style=summary",
          },
          {
            name: "Servicegroup Summary",
            link: `${statusPath}/servicegroups`,
            legacyPath:
              "nagiosxi/includes/components/xicore/status.php?show=servicegroups&servicegroup=all&style=summary",
          },
        ]),
        createSubMenuObject("quickView", "Quick View", [
          {
            name: "Birdseye",
            link: `${componentPath}/birdseye`,
            legacyPath: "nagiosxi/includes/components/birdseye/birdseye.php",
          },
          {
            name: "Business Process Intelligence",
            link: `${componentPath}/bpi`,
            legacyPath: "nagiosxi/includes/components/nagiosbpi/index.php",
          },
          {
            name: "Operations Center (NOC)",
            link: `${componentPath}/noc`,
            legacyPath: "nagiosxi/includes/components/nocscreen/noc.php",
          },
          {
            name: "Operations Screen",
            link: `${componentPath}/operations-screen`,
            legacyPath: "nagiosxi/includes/components/opscreen/opscreen.php",
          },
          {
            name: "Tactical Overview",
            link: `${componentPath}/tactical-overview`,
            legacyPath: "nagiosxi/includes/components/xicore/tac.php",
          },
        ]),
        createSubMenuObject("monitoringProcess", "Monitoring Backend", [
          {
            name: "Monitoring Engine Status",
            link: `${componentPath}/monitoring-engine-status`,
            legacyPath:
              "nagiosxi/includes/components/xicore/status.php?show=process",
          },
          {
            name: "Monitoring Engine Perfromance",
            link: `${componentPath}/monitoring-engine-performance`,
            legacyPath:
              "nagiosxi/includes/components/xicore/status.php?show=performance",
          },
          {
            name: "Scheduling Queue",
            link: `${componentPath}/scheduling-queue`,
            legacyPath: "nagiosxi/includes/components/xicore/queue.php",
          },
        ]),
      ]
    ),

    Dashboards: createSideNavObject(
      [
        {
          name: "Create Dashboard",
          action: toggleCreateDashboardModalOpen,
        },
        {
          name: "Manage Dashboards",
          action: () => history.push("/dashboards"),
        },
      ],
      [
        createSubMenuObject("dashboardList", "Dashboard List", [
          ...[...(dashboards ? dashboards : [])].map(
            ({ name, dashboard_id }: Dashboard) => ({
              name,
              link: `/dashboards/${dashboard_id}`,
            })
          ),
        ]),
      ]
    ),

    Views: createSideNavObject(
      [
        {
          name: "Play",
          action: toggleCreateDashboardModalOpen,
        },
        {
          name: "Manage Playlists",
          action: () => history.push("/views/playlists/manage"),
        },
      ],
      [
        createSubMenuObject(
          "myPlaylist",
          "My Playlist",
          Object.values([...(playlists ? playlists : [])]).map(
            ({ name, playlist_id }: Playlist) => ({
              name,
              link: `/views/playlists/${playlist_id}`,
            })
          )
        ),
      ]
    ),

    Reports: createSideNavObject(
      [
        {
          name: "Create A Report",
          action: toggleCreateAReportModalOpen,
        },
        {
          name: "Manage Reports",
          action: () => history.push("/reports"),
        },
      ],
      [
        createSubMenuObject("myReports", "My Reports", [
          { name: "All Reports", link: "/reports" },
          { name: "Scheduled Reports", link: "/reports/scheduled" },
        ]),
        createSubMenuObject(
          "reportTypes",
          "Report Types",
          [...(reportTypes ? reportTypes : [])].map(
            ({ type_title, template_id }) => {
              const legacyFileName = {
                "Availability Summary": "nagiosxi/reports/availability.php",
                "Executive Summary": "nagiosxi/reports/execsummary.php",
                "SLA Report": "nagiosxi/reports/sla.php",
                "State History": "nagiosxi/reports/statehistory.php",
                "Top Alert Producers": "nagiosxi/reports/topalertproducers.php",
                "Top Alert Histogram": "nagiosxi/reports/histogram.php",
                Notifications: "nagiosxi/reports/notifications.php",
                "Event Log": "nagiosxi/reports/eventlog.php",
                "Bandwidth Usage":
                  "nagiosxi/includes/components/bandwidthreport/index.php",
                "Capacity Planning":
                  "nagiosxi/includes/components/capacityplanning/capacityplanning.php",
                "Network Report":
                  "nagiosxi/includes/components/nagiosna/nagiosna-reports.php",
                "Network Query":
                  "nagiosxi/includes/components/nagiosna/nagiosna-queries.php",
              };
              return {
                name: type_title,
                link: `/reports/${type_title.toLowerCase().replace(/ /g, "-")}`,
                legacyPath: legacyFileName[type_title],
              };
            }
          )
        ),
      ]
    ),

    Tools: createSideNavObject(
      [
        {
          name: "Add a Tool",
          action: toggleAddAToolModalOpen,
        },
        {
          name: "Manage Tools",
          action: () => history.push("/tools"),
        },
      ],
      [
        createSubMenuObject(
          "toolsList",
          "Tools List",
          [...(tools ? tools : [])].map(({ name, tool_id }) => ({
            name,
            link: `/tools/${tool_id}`,
          }))
        ),
      ]
    ),
    Configure: createSideNavObject(
      [
        {
          name: "Configure Dashboard",
          link: "/config",
          legacyPath: "nagiosxi/config/main.php",
        },
      ],
      [
        createSubMenuObject("quickConfig", "Quick Config", [
          {
            name: "Auto-Discovery",
            link: "/config/autodiscovery",
            legacyPath: "nagiosxi/includes/components/autodiscovery/",
          },
          {
            name: "Configuration Wizards",
            link: "/config/wizards",
            path: "/config/wizards/:category",
            legacyPath: "nagiosxi/config/monitoringwizard.php",
          },
          {
            name: "Deploy Agents",
            link: "/config/deploy",
            legacyPath: "nagiosxi/config/deployment/index.php",
          },
        ]),
        createSubMenuObject("advancedConfig", "Advanced Config", [
          {
            name: "Configuration Manager",
            link: "/config/manager",
            legacyPath: "nagiosxi/includes/components/ccm/index.php",
          },
          {
            name: "Configuration Snapshopts",
            link: "/config/snapshots",
            legacyPath:
              "nagiosxi/includes/components/ccm/?cmd=frame&window=snapshots",
          },
          {
            name: "Manage Deployed Agents",
            link: "/config/deploy/manage",
            legacyPath: "nagiosxi/config/deployment/manage.php",
          },
        ]),
        createSubMenuObject("configWizards", "Config Wizards", [
          {
            name: "See All Wizards",
            link: "/config/wizards",
            legacyPath: "nagiosxi/config/monitoringwizard.php",
          },
          ...[...(wizards ? wizards : [])].map(({ label, fileName }) => ({
            name: label,
            link: `/config/${fileName}`,
            legacyPath: `nagiosxi/config/monitoringwizard.php?update=1&nextstep=2&nsp=30b51f4649008c2c4c791a2d6b616be0e6e03b0ab9a404a492d49562a15385cf&wizard=${fileName}`,
          })),
        ]),
      ]
    ),
    Incidents: createSideNavObject(
      [],
      [
        createSubMenuObject("checkProblems", "Problems", [
          {
            name: "All Service Problems",
            link: `/incidents/services`,
            legacyPath:
              "nagiosxi/includes/components/xicore/status.php?show=services&servicestatustypes=28",
          },
          {
            name: "All Host Problems",
            link: `/incidents/hosts`,
            legacyPath:
              "nagiosxi/includes/components/xicore/status.php?show=hosts&hoststatustypes=12",
          },
          {
            name: "Network Outages",
            link: `/incidents/network-outages`,
            legacyPath:
              "nagiosxi/includes/components/xicore/status.php?show=outages",
          },
          {
            name: "Open Service Problems",
            link: `/incidents/services/open`,
            legacyPath:
              "nagiosxi/includes/components/xicore/status.php?show=services&hostattr=10&hoststatustypes=2&servicestatustypes=28&serviceattr=10",
          },
          {
            name: "Open Host Problems",
            link: `/incidents/hosts/open`,
            legacyPath:
              "nagiosxi/includes/components/xicore/status.php?show=hosts&hoststatustypes=12&hostattr=10",
          },
        ]),
        createSubMenuObject("management", "Manage Incidents", [
          {
            name: "Acknowledgements",
            link: `/incidents/acknowledgements`,
            legacyPath:
              "nagiosxi/includes/components/xicore/status.php?show=comments",
          },
          {
            name: "Latest Alerts",
            link: `/incidents/latest-alerts`,
            legacyPath: "nagiosxi/includes/components/latestalerts/index.php",
          },
          {
            name: "Mass Acknowledge",
            link: `/incidents/mass-acknowledge`,
            legacyPath:
              "nagiosxi/includes/components/massacknowledge/index.php",
          },
          {
            name: "Mass Immediate Check",
            link: `/incidents/mass-check`,
            legacyPath:
              "nagiosxi/includes/components/massimmediatecheck/index.php",
          },
          {
            name: "Recurring Downtime",
            link: `/incidents/recurring-downtime`,
            legacyPath:
              "nagiosxi/includes/components/xicore/recurringdowntime.php",
          },
          {
            name: "Schedule Downtime",
            link: `/incidents/schedule-downtime`,
            legacyPath: "nagiosxi/includes/components/xicore/downtime.php",
          },
        ]),
      ]
    ),
    Visualizations: createSideNavObject(
      [],
      [
        createSubMenuObject("graphs", "Graphs & Metrics", [
          {
            name: "Performance Graphs",
            link: `/visualizations/performance-graphs`,
            legacyPath: "nagiosxi/perfgraphs/",
          },
          {
            name: "Metrics",
            link: `/visualizations/metrics`,
            legacyPath: "nagiosxi/includes/components/metrics/",
          },
        ]),
        createSubMenuObject("maps", "Data Maps", [
          {
            name: "BBmap",
            link: "/visualizations/bbmap",
            legacyPath: "nagiosxi/includes/components/bbmap/",
          },
          {
            name: "Hypermap",
            link: "/visualizations/hypermap",
            legacyPath: "nagiosxi/includes/components/hypermap/",
          },
          {
            name: "Minemap",
            link: "/visualizations/minemap",
            legacyPath: "nagiosxi/includes/components/minemap/",
          },
          {
            name: "NagVis",
            link: "/visualizations/nagvis",
            legacyPath: "nagvis/frontend/nagvis-js/",
          },
          {
            name: "Network Status Map",
            link: "/visualizations/statusmap",
            legacyPath: "nagiosxi/includes/components/statusmap/index.php",
          },
          {
            name: "World Map",
            link: "/visualizations/worldmap",
            legacyPath: "nagiosxi/includes/components/map/index.php",
          },
        ]),
        createSubMenuObject("dataVisualizations", "Data Visualizations", [
          {
            name: "Alert Heatmap",
            link: "/visualizations/alert-heatmap",
            legacyPath: "nagiosxi/reports/alertheatmap.php",
          },
          {
            name: "Alert Stream",
            link: "/visualizations/alert-stream",
            legacyPath: "nagiosxi/includes/components/alertstream/",
          },
          {
            name: "Alert Timeline",
            link: "/visualizations/alert-timeline",
            legacyPath: "nagiosxi/includes/components/similetimeline/",
          },
          {
            name: "Network Replay",
            link: "/visualizations/network-replay",
            legacyPath: "nagiosxi/includes/components/hypermap_replay/",
          },
        ]),
      ]
    ),

    Administration: createSideNavObject(
      [
        {
          name: "Administration Dashboard",
          link: adminPath,
          legacyPath: "nagiosxi/admin/main.php",
        },
        {
          name: "System Status",
          link: `${adminPath}/system/status`,
          legacyPath: "nagiosxi/admin/sysstat.php",
        },
      ],
      [
        createSubMenuObject("userAdmin", "User Admin", [
          {
            name: "Manage Users",
            link: `/admin/mangage_users`,
          },
          {
            name: "Notification Management",
            link: `${adminPath}/users/notification-management`,
            legacyPath:
              "nagiosxi/includes/components/deploynotification/deploynotification.php",
          },
          {
            name: "User Sessions",
            link: `${adminPath}/users/sessions`,
            legacyPath: "nagiosxi/admin/sessions.php",
          },
          {
            name: "LDAP/AD Integration",
            link: `${adminPath}/users/ladp-ad-integration`,
            legacyPath:
              "nagiosxi/includes/components/ldap_ad_integration/manage.php",
          },
        ]),
        createSubMenuObject("systemConfig", "System Config", [
          {
            name: "Email Settings",
            link: `${adminPath}/system/email-settings`,
            legacyPath: "nagiosxi/admin/mailsettings.php",
          },
          {
            name: "License Managment",
            link: `${adminPath}/system/license-managment`,
            legacyPath: "nagiosxi/admin/license.php",
          },
          {
            name: "Mobile Carriers",
            link: `${adminPath}/system/mobile-carriers`,
            legacyPath: "nagiosxi/admin/mobilecarriers.php",
          },
          {
            name: "Performance Settings",
            link: `${adminPath}/system/performance-settings`,
            legacyPath: "nagiosxi/admin/performance.php",
          },
          {
            name: "Proxy Configuration",
            link: `${adminPath}/system/proxy`,
            legacyPath: "nagiosxi/includes/components/proxy/proxyconfig.php",
          },
          {
            name: "System Settings",
            link: `${adminPath}/system/settings`,
            legacyPath: "nagiosxi/admin/globalconfig.php",
          },
          {
            name: "System Profile",
            link: `${adminPath}/system/profile`,
            legacyPath: "nagiosxi/includes/components/profile/profile.php",
          },
        ]),
        createSubMenuObject("systemExtensions", "System Extensions", [
          {
            name: "Components",
            link: `${adminPath}/system/components`,
            legacyPath: "nagiosxi/admin/components.php",
          },
          {
            name: "Configuration Wizards",
            link: `${adminPath}/system/configuration-wizards`,
            legacyPath: "nagiosxi/admin/configwizards.php",
          },
          {
            name: "Custom Includes",
            link: `${adminPath}/system/custom-includes`,
            legacyPath:
              "nagiosxi/includes/components/custom-includes/manage.php",
          },
          {
            name: "MIBs",
            link: `${adminPath}/system/mibs`,
            legacyPath: "nagiosxi/admin/mibs.php",
          },
          {
            name: "Plugins",
            link: `${adminPath}/system/plugins`,
            legacyPath: "nagiosxi/admin/monitoringplugins.php",
          },
        ]),
      ]
    ),
    Profile: createSideNavObject(
      [
        {
          name: `Toggle ${
            currentMasterTheme === "light" ? "Dark" : "Light"
          } Mode`,
          action: () =>
            setCurrentMasterTheme(
              currentMasterTheme === "light" ? "dark" : "light"
            ),
        },
      ],
      [
        createSubMenuObject("profileSettings", "Profile Settings", [
          {
            name: "Profile Settings",
            link: "/profile/account",
            legacyPath: "nagiosxi/account/main.php",
          },
          {
            name: "My Sessions",
            link: "/profile/sessions",
            legacyPath: "nagiosxi/account/sessions.php",
          },
        ]),

        createSubMenuObject("myNotification", "My Notification", [
          {
            name: "Preferences",
            link: "/profile/preferences",
            legacyPath: "nagiosxi/account/notifyprefs.php",
          },
          {
            name: "Methods",
            link: "/profile/methods",
            legacyPath: "nagiosxi/account/notifymethods.php",
          },
          {
            name: "Messages",
            link: "/profile/messages",
            legacyPath: "nagiosxi/account/notifymsgs.php",
          },
          {
            name: "Send Test Notification",
            link: "/profile/testnotification",
            legacyPath: "nagiosxi/account/testnotification.php",
          },
        ]),
        createSubMenuObject("help", "Help", [
          {
            name: "FAQs",
            link: "https://support.nagios.com/kb/faq_redirect.php",
          },
          { name: "Help Page", link: "/help" },
          { name: "Nagios Library", link: "https://library.nagios.com/" },
          { name: "Support Forum", link: "https://support.nagios.com/forum/" },
          {
            name: "Support Wiki",
            link: "https://support.nagios.com/wiki/index.php/Main_Page",
          },
        ]),

        createSubMenuObject("customerSupport", "Customer Support", [
          {
            name: "Customer Support",
            link: "https://support.nagios.com/tickets/",
          },
        ]),
      ]
    ),
  };

  return {
    sideNav,
    flattenSideNav: [
      ...Object.values(sideNav).map((innerObject) => {
        const subMenuItems = innerObject.submenus.map(
          ({ menuItems }) => menuItems
        );
        return [...subMenuItems, ...innerObject.buttons];
      }),
    ]
      .flat()
      .flat(),
  };
}
