import { Skeleton } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { NETWORK_OUTAGES_DASHLET_URL } from "../../../../api.config";
import { fetchData } from "../../../../utils/axiosHelpers";
import CustomTable from "../../../Shared/Organisms/CustomTable/CustomTable";
import { dashletMockData } from "../../DashletMockData";
import { DashletProps } from "../../DashletViewPageTypes";

export default function NetworkOutageDashlet({
  demo,
  dashletId,
}: DashletProps) {
  let { data, isLoading } = useQuery(
    ["Fetch Network Outages", dashletId],
    async () => await fetchData(NETWORK_OUTAGES_DASHLET_URL),
    {
      select: (data) => data.outages,
    }
  );

  if (isLoading) {
    return <Skeleton />;
  }

  data = demo ? dashletMockData["network_outages"].outages : data;
  const subTableColumns = [
    {
      title: "Severity",
      dataIndex: "severity",
    },
    {
      title: "Host",
      dataIndex: "host",
    },
    {
      title: "State",
      dataIndex: "state",
      render: (value) => <p>{value}</p>,
    },
    {
      title: "Hosts Affected",
      dataIndex: "hosts_affected",
    },
    {
      title: "Services Affected",
      dataIndex: "services_affected",
    },
  ];

  return (
    <CustomTable
      style={{ height: "100%", width: "100%" }}
      size="large"
      loading={isLoading}
      dataSource={data}
      columns={subTableColumns}
      bordered
      pagination={false}
    />
  );
}
