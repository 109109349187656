import { Button } from "antd";
import {
  HeaderPopup,
  StyledRow,
  StyledDiv,
  StyledP,
  TableDivider,
  ViewLink,
  LastCheck
} from "../../Atoms/HeaderPopup/HeaderPopup";
import { IconClose } from "../../Atoms/FontIcons/FontIcons";
import { ServiceMenuObject } from "../../../Frame/FrameTypes";

type ServiceMenuProps = {
  data: ServiceMenuObject | undefined;
};

const ServiceStatus = (props: ServiceMenuProps) => {
  const handleButton = () => alert("You clicked the 'Clear Unhandled' button");

  return (
    <HeaderPopup width="240px" align="left">
      {props.data && (
        <div>
          <table>
            <tbody>
              <StyledRow>
                <td>Ok</td>
                <td>{props.data.ok}</td>
                <td>
                  <ViewLink to="/status/services/ok">View</ViewLink>
                </td>
              </StyledRow>
              <StyledRow>
                <td>Warning</td>
                <td>{props.data.warning}</td>
                <td>
                  <ViewLink to="/status/services/warning">View</ViewLink>
                </td>
              </StyledRow>
              <StyledRow>
                <td>Critical</td>
                <td>{props.data.critical}</td>
                <td>
                  <ViewLink to="/status/services/critical">View</ViewLink>
                </td>
              </StyledRow>
              <StyledRow>
                <td>Unknown</td>
                <td>{props.data.unknown}</td>
                <td>
                  <ViewLink to="/status/services/unknown">View</ViewLink>
                </td>
              </StyledRow>
              <StyledRow>
                <td>Pending</td>
                <td>{props.data.pending}</td>
                <td>
                  <ViewLink to="/status/services/pending">View</ViewLink>
                </td>
              </StyledRow>
              <StyledRow>
                <td>All</td>
                <td>{props.data.all}</td>
                <td>
                  <ViewLink to="/status/services">View</ViewLink>
                </td>
              </StyledRow>
            </tbody>
          </table>
          <TableDivider />
          <table>
            <tbody>
              <StyledRow>
                <td>Unhandled</td>
                <td>{props.data.unhandled}</td>
                <td>
                  <ViewLink to="/status/services/unhandled">View</ViewLink>
                </td>
              </StyledRow>
              <StyledRow>
                <td>Problems</td>
                <td>{props.data.problems}</td>
                <td>
                  <ViewLink to="/status/services/problems">View</ViewLink>
                </td>
              </StyledRow>
            </tbody>
          </table>
          {props.data.unhandled ?
            <StyledDiv>
              <Button ghost block onClick={handleButton}>
                <IconClose /> Clear Unhandled
              </Button>
            </StyledDiv>
            :
           <StyledP />
          }
          <LastCheck>Last check: {props.data.last_updated}</LastCheck>
        </div>
      )}
    </HeaderPopup>
  );
};

export default ServiceStatus;
