import { find } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { CREATE_VIEW_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { postData } from "../../../utils/axiosHelpers";
import { Playlists, Playlist, Screen } from "../ViewsPageTypes";
import { KEY } from "./usePlaylists";

export default function useCreateView() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    ({ newViewObject, playlist }) => {
      console.log({
        newViewObject,
        playlist,
      });
      return postData(CREATE_VIEW_URL(playlist.playlist_id), {
        views: [newViewObject],
      });
    },
    {
      onMutate: async ({ newViewObject, playlist }: any) => {
        const { playlist_id } = playlist;

        await queryClient.cancelQueries(KEY);

        const previousPlaylists: any = queryClient.getQueryData(KEY);

        const oldPlayList = find(previousPlaylists.playlists, {
          playlist_id,
        });

        const CALL_REASON = `Create New View ${newViewObject.name} in ${oldPlayList.name}`;

        const newPlaylists = previousPlaylists.playlists.map(
          (playlist: Playlist) => {
            const { screens, ...playlistRest } = playlist;
            return playlist.playlist_id === playlist_id
              ? {
                  ...playlistRest,
                  screens: Object.assign(
                    {},
                    ...[
                      ...Object.values(screens),
                      {
                        id: Math.random().toString(),
                        ...newViewObject,
                      },
                    ].map((screen: Screen) => ({
                      [`${screen.play_order}`]: screen,
                    }))
                  ),
                }
              : playlist;
          }
        );

        queryClient.setQueryData(KEY, {
          playlists: newPlaylists,
        });

        return {
          previousPlaylists,
          newPlaylists,
          CALL_REASON,
        };
      },
      onSuccess: (data, _, context) => {
        onSuccessNotification(data, context?.CALL_REASON);
      },
      onError: (data, _, context) => {
        onErrorNotification(data, context?.CALL_REASON);
        if (context?.previousPlaylists) {
          queryClient.setQueryData<Playlists>([KEY], context.previousPlaylists);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(KEY);
      },
    }
  );
}
