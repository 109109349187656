import { find } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { TOOLS_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { putData } from "../../../utils/axiosHelpers";
import { Tool, Tools } from "../ToolsPageTypes";
import { KEY } from "./useTools";

export default function useEditTool() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    ({ tool_id, shared, ...rest }) =>
      putData(`${TOOLS_URL}/${tool_id}`, {
        tool_id,
        shared: shared ? 1 : 0,
        ...rest,
      }),
    {
      onMutate: async (toolToEdit: any) => {
        const { tool_id, shared, ...rest } = toolToEdit;

        await queryClient.cancelQueries(KEY);

        const previousToolList: any = queryClient.getQueryData(KEY);

        const oldTool = find(previousToolList.tool, { tool_id });

        const CALL_REASON = `Edit Tool ${oldTool.name}`;

        const newToolList = previousToolList.tool.map((tool: Tool) =>
          tool.tool_id === tool_id
            ? { tool_id, shared: shared ? 1 : 0, ...rest }
            : tool
        );

        queryClient.setQueryData(KEY, {
          tool: newToolList,
        });

        return {
          previousToolList,
          newToolList,
          CALL_REASON,
        };
      },
      onSuccess: (data, _, context) => {
        onSuccessNotification(data, context?.CALL_REASON);

        queryClient.setQueryData(KEY, {
          tool: context?.newToolList,
        });
      },
      onError: (data, _, context) => {
        onErrorNotification(data, context?.CALL_REASON);
        if (context?.previousToolList) {
          queryClient.setQueryData<Tools>([KEY], context.previousToolList);
        }
      },

      onSettled: () => {
        queryClient.invalidateQueries(KEY);
      },
    }
  );
}
