export const FAQ = [
  {
    name: "Host Monitoring",
    filter: "basic",
    questions: [
      {
        title: "What's the fastest way to add hosts?",
        value: "hostMonitoring1",
      },
      {
        title: "What's the most customized, detailed way to add hosts?",
        value: "hostMonitoring2",
      },
      {
        title: "Lorem ipsum dit solor vitorum le tuoi salle andiamo?",
        value: "hostMonitoring3",
      },
    ],
  },
  {
    name: "Incident Management",
    filter: "advanced",
    questions: [
      { title: "Question 1", value: "incidentManagment1" },
      { title: "Question 2", value: "incidentManagment2" },
      { title: "Question 3", value: "incidentManagment3" },
    ],
  },
  {
    name: "Unseen Category",
    filter: "basic",
    questions: [
      { title: "Question 1", value: "unseenCategory1" },
      { title: "Question 2", value: "unseenCategory2" },
      { title: "Question 3", value: "unseenCategory3" },
    ],
  },
  {
    name: "Service Monitoring",
    filter: "advanced",
    questions: [
      { title: "Question 1", value: "serviceMonitoring1" },
      { title: "Question 2", value: "serviceMonitoring2" },
      { title: "Question 3", value: "serviceMonitoring3" },
    ],
  },
  {
    name: "Setting Up Dashboards",
    filter: "advanced",
    questions: [
      { title: "Question 1", value: "settingUpDashboards1" },
      { title: "Question 2", value: "settingUpDashboards2" },
      { title: "Question 3", value: "settingUpDashboards3" },
    ],
  },
  {
    name: "Wizard Setup",
    filter: "basic",
    questions: [
      { title: "Question 1", value: "wizardSetup1" },
      { title: "Question 2", value: "wizardSetup2" },
      { title: "Question 3", value: "wizardSetup3" },
    ],
  },
  {
    name: "Working With Host and Service Groups",
    filter: "advanced",
    questions: [
      { title: "Question 1", value: "serviceGroups1" },
      { title: "Question 2", value: "serviceGroups2" },
      { title: "Question 3", value: "serviceGroups3" },
    ],
  },
];
