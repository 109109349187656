import { Modal, Button, Col, Input, Row, Select } from "antd";
import { Form } from "antd";
import { useTranslator } from "../../../../hooks";
import { ColorPicker } from "../../../DashboardPage";
import { isEqual } from "lodash";
import { dashletTypeList } from "../../DashletViewPageConstants";
import { DashletPreview } from "../../Atoms";
import { DashletForm } from "../DashletForm";
import { HeatMapDashletForm } from "../HeatMapDashletForm";
import useDashletModalEvents from "../../hooks/useDashletModalEvents";
import { Dashboard, Dashlet } from "../../../DashboardPage/DashboardPageTypes";
import useDashletPageEvents from "../../hooks/useDashletPageEvents";

const { Option } = Select;
const { useForm } = Form;

type Props = {
  open: boolean;
  toggleOpen: Function;
  dashboard: Dashboard;
  dashlet?: Dashlet;
  editDashlet?: boolean;
};

export default function DashletModal({
  open,
  toggleOpen,
  dashboard,
  dashlet,
  editDashlet,
}: Props): JSX.Element {
  const [form] = useForm();

  const values = form.getFieldsValue();
  const { endpoint, name, type } = form.getFieldsValue();

  const { SELECT_BACKGROUND_COLOR } = useTranslator("CreateDashletModal");

  const {
    setFormValues,
    formValues,
    color,
    setColor,
    onCancel,
    afterFetchData,
    showIfNotDashletType,
    dashletToEditData,
    dashletProperties,
    // refetchDashletProperties,
    initalData,
    refetchInitalData,
  } = useDashletModalEvents(toggleOpen, form, dashlet, type);

  const { onEditDashletButtonClick, onCreateDashletButtonClick } =
    useDashletPageEvents(dashboard?.dashboard_id);

  const onSubmit = (values) => {
    const dashletProperties = {
      ...values,
      // dashlet?.dashlet_id,
      // position_x,
      // position_y,
      // dimension_height,
      // dimension_width,
    };

    editDashlet
      ? onEditDashletButtonClick(dashletProperties)
      : onCreateDashletButtonClick(dashletProperties);
    toggleOpen();
  };

  return (
    <Modal
      destroyOnClose={!dashlet}
      width={1500}
      visible={open}
      onCancel={() => toggleOpen}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={() => form.submit()}
        >
          {editDashlet
            ? `Edit Dashlet ${dashlet?.name}`
            : `Create ${type ? type : type} Dashlet`}
        </Button>,
      ]}
    >
      <Row justify="space-around">
        <Col span={9}>
          <Form.Provider
            onFormChange={() => {
              const formValues = form.getFieldsValue();
              if (!isEqual(formValues, values)) {
                setFormValues(formValues);
              }
            }}
          >
            <Form
              form={form}
              onFinish={onSubmit}
              layout="vertical"
              preserve={false}
              initialValues={
                afterFetchData
                  ? {
                      ...[dashletProperties ? dashletProperties : []].reduce(
                        (previous, current) => {
                          const keys = dashlet
                            ? Object.keys(dashlet?.properties)
                            : Object.keys(initalData ? initalData[0] : {});

                          const value = dashlet
                            ? dashlet.properties[current.name]
                            : current.type === "keyList"
                            ? [keys[0]]
                            : keys[0];

                          return [...previous, { [current.name]: value }];
                        },
                        []
                      ),

                      isCustomUrl: true,
                    }
                  : dashlet
                  ? {
                      ...Object.entries(dashlet.properties).map(
                        ([name, value]) => ({
                          [name]: () => {
                            return value;
                          },
                        })
                      ),
                      endpoint: dashlet.endpoint,
                      name: dashlet.name,
                      color: dashlet.color,
                      isCustomUrl: true,
                    }
                  : {
                      isCustomUrl: false,
                    }
              }
            >
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.name !== currentValues.name
                }
              >
                <Form.Item
                  shouldUpdate
                  label="Dashlet Title"
                  name="name"
                  rules={[
                    {
                      whitespace: true,
                      message: "Please input dashlet title!",
                    },
                    { required: true, message: "Please input dashlet title!" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Form.Item>
              <Form.Item
                shouldUpdate
                label="Dashlet Type"
                name="type"
                initialValue={dashlet?.type}
                rules={[
                  {
                    whitespace: true,
                    message: "Please select dashlet type!",
                  },
                ]}
              >
                <Select>
                  {dashletTypeList.map((type, i) => (
                    <Option value={type} key={i}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label={SELECT_BACKGROUND_COLOR}>
                <ColorPicker color={color} updateColor={setColor} />
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.endpoint !== currentValues.endpoint
                }
              >
                <Form.Item label="Enter in URL" name="endpoint">
                  <Input onChange={() => refetchInitalData()} />
                </Form.Item>
              </Form.Item>

              {(type ? type : type || type) === "heatmap" ? (
                <HeatMapDashletForm />
              ) : null}
              {afterFetchData && showIfNotDashletType(type) ? (
                <DashletForm
                  dashletProperties={dashletProperties}
                  dashletToEdit={dashlet}
                  initalData={initalData}
                />
              ) : null}
            </Form>
          </Form.Provider>
        </Col>
        <Col span={14}>
          <DashletPreview
            dashlet={{
              dashlet_id: "test",
              name,
              endpoint,
              type,
              position_x: 1,
              position_y: 1,
              dimension_height: 3,
              dimension_width: 3,
              color,
              properties: formValues,
            }}
            initalData={
              dashletToEditData
                ? dashletToEditData
                : initalData
                ? initalData
                : []
            }
            isCreate={!editDashlet}
          />
        </Col>
      </Row>
    </Modal>
  );
}
