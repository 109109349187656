import { Checkbox, Form, Input } from "antd";
import { useTranslator } from "../../../../hooks";
import { Dashboard } from "../../DashboardPageTypes";
import useDashboardPageEvents from "../../hooks/useDashboardPageEvents";

export type Props = {
  form: any;
  dashboard: Dashboard;
  toggleClose: Function;
};

const FormCheckbox = ({ label, name }) => (
  <Form.Item label={label} name={name} valuePropName="checked">
    <Checkbox />
  </Form.Item>
);

export default function EditDashboardForm({
  form,
  dashboard,
  toggleClose,
}: Props) {
  const {
    DASHBOARD_TITLE_INPUT,
    PUBLIC_SHARE_CHECKBOX,
    ALLOW_EDITING_CHECKBOX,
    DASHBOARD_INPUT_ERROR_MESSAGE,
  } = useTranslator("EditDashboardForm");

  const { onEditButtonClick } = useDashboardPageEvents();

  const onSubmit = (values) => {
    const { isSharable, isEditable, ...rest } = values;

    const tagList = [
      isSharable ? "Public" : "",
      isEditable ? "Editable" : "Read Only",
    ];

    if (dashboard) {
      onEditButtonClick({ ...dashboard, ...{ ...rest, tagList } });
      toggleClose();
    }
  };

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      layout="vertical"
      initialValues={{
        name: dashboard.name,
        isSharable: dashboard?.tagList.includes("Public"),
        isEditable: dashboard?.tagList.includes("Editable"),
      }}
    >
      <Form.Item
        label={`${DASHBOARD_TITLE_INPUT}`}
        name="name"
        rules={[{ required: true, message: DASHBOARD_INPUT_ERROR_MESSAGE }]}
      >
        <Input />
      </Form.Item>
      <FormCheckbox label={PUBLIC_SHARE_CHECKBOX} name="isSharable" />
      <FormCheckbox label={ALLOW_EDITING_CHECKBOX} name="isEditable" />
    </Form>
  );
}
