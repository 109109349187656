import ButtonArray, {
  ArrayProps,
} from "../../Molecules/ButtonArray/ButtonArray";
import styled from "styled-components";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";

export type HeaderProps = ArrayProps & {
  title: string;
  sub: string;
  buttons?: JSX.Element;
};

const StyledDiv = styled.div`
  padding: 10px 0 10px 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const StyledHeader = styled.h2`
  font-size: 2em;
  line-height: 1;
  margin-bottom: 10px;
`;

const StyledSub = styled.div`
  color: ${themeVariables.colorGrayLight1};
  line-height: 1;
  margin-bottom: 0;
`;

const PageHeader = (props: HeaderProps) => {
  return (
    <StyledDiv>
      <div>
        <StyledHeader>{props.title}</StyledHeader>
        <StyledSub>{props.sub}</StyledSub>
      </div>
      <div>
        {props.buttons}
        <ButtonArray
          expandClicked={props.expandClicked}
          settingsClicked={props.settingsClicked}
          infoClicked={props.infoClicked}
          favoriteClicked={props.favoriteClicked}
        />
      </div>
    </StyledDiv>
  );
};

export default PageHeader;
