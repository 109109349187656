import { Button } from "antd";
import {
  HeaderPopup,
  StyledRow,
  StyledDiv,
  StyledP,
  ViewLink,
  TableDivider,
  LastCheck
} from "../../Atoms/HeaderPopup/HeaderPopup";
import { IconClose } from "../../Atoms/FontIcons/FontIcons";
import { HostsMenuObject } from "../../../Frame/FrameTypes";

type HostMenuProps = {
  data: HostsMenuObject | undefined;
};

const HostStatus = (props: HostMenuProps) => {
  const handleButton = () => alert("You clicked the 'Clear Unhandled' button");

  return (
    <HeaderPopup width="240px" align="left">
      {props.data && (
        <div>
          <table>
            <tbody>
              <StyledRow>
                <td>Up</td>
                <td>{props.data.up}</td>
                <td>
                  <ViewLink to="/status/hosts/up">View</ViewLink>
                </td>
              </StyledRow>
              <StyledRow>
                <td>Down</td>
                <td>{props.data.down}</td>
                <td>
                  <ViewLink to="/status/hosts/down">View</ViewLink>
                </td>
              </StyledRow>
              <StyledRow>
                <td>Unreachable</td>
                <td>{props.data.unreachable}</td>
                <td>
                  <ViewLink to="/status/hosts/unreachable">View</ViewLink>
                </td>
              </StyledRow>
              <StyledRow>
                <td>Pending</td>
                <td>{props.data.pending}</td>
                <td>
                  <ViewLink to="/status/hosts/pending">View</ViewLink>
                </td>
              </StyledRow>
              <StyledRow>
                <td>All</td>
                <td>{props.data.all}</td>
                <td>
                  <ViewLink to="/status/hosts">View</ViewLink>
                </td>
              </StyledRow>
            </tbody>
          </table>
          <TableDivider />
          <table>
            <tbody>
              <StyledRow>
                <td>Unhandled</td>
                <td>{props.data.unhandled}</td>
                <td>
                  <ViewLink to="/status/hosts/unhandled">View</ViewLink>
                </td>
              </StyledRow>
              <StyledRow>
                <td>Problems</td>
                <td>{props.data.problems}</td>
                <td>
                  <ViewLink to="/status/hosts/problems">View</ViewLink>
                </td>
              </StyledRow>
            </tbody>
          </table>
          {props.data.unhandled ?
            <StyledDiv>
              <Button ghost block onClick={handleButton}>
                <IconClose /> Clear Unhandled
              </Button>
            </StyledDiv>
           :
           <StyledP />
          }
          <LastCheck>Last check: {props.data.last_updated}</LastCheck>
        </div>
      )}
    </HeaderPopup>
  );
};

export default HostStatus;
