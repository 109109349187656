import { useEffect, useState } from "react";
import { useToggle } from "../../../hooks";
import { Dashboard } from "../../DashboardPage/DashboardPageTypes";
import useCreateDashletArray from "./useCreateDashletArray";
import useDashletPageEvents from "./useDashletPageEvents";

type DashletDimension = {
  index: string;
  height: number;
  width: number;
};

export default function useDashletEvents(dashboard: Dashboard) {
  const { onSaveDashlets } = useDashletPageEvents(dashboard.dashboard_id);
  const [selectedTemplateName, setSelectedTemplateName] = useState("");
  const [isDroppable, setIsDroppable] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);
  const { open, toggleOpen } = useToggle();
  const dashletsArray = useCreateDashletArray(dashboard.dashlets);

  const [dimensionsArray, setDimensionsArray] = useState<DashletDimension[]>(
    []
  );

  const onLayoutChange = (layout) => {
    const { dashlets, ...rest } = dashboard;

    const newDashletList = dashletsArray.map((dashlet, i) => {
      const {
        position_x,
        position_y,
        dimension_height,
        dimension_width,
        ...rest
      } = dashlet;
      const { w, h, x, y } = layout[i];
      return {
        position_x: x,
        position_y: y,
        dimension_height: h,
        dimension_width: w,
        ...rest,
      };
    });

    onSaveDashlets({ ...rest, dashlets: newDashletList });
  };

  const onResize = (layout, _, newItem) => {
    const { i, w, h } = newItem;

    const newDashletDimensionObj: DashletDimension = {
      index: i,
      width: w,
      height: h,
    };

    setDimensionsArray((prev) => {
      return prev.map((dimension) =>
        dimension.index === i ? newDashletDimensionObj : dimension
      );
    });
  };

  const onCreateDashletOpen = (dashlet) => {
    setSelectedTemplateName(dashlet);
    toggleOpen();
  };

  useEffect(() => {
    setDimensionsArray(
      dashletsArray.map(
        ({ dimension_height, dimension_width }: any, key: number) => {
          return {
            index: `${key}`,
            height: dimension_height,
            width: dimension_width,
          };
        }
      )
    );
  }, [dashboard?.dashlets, dashletsArray]);

  return {
    dimensionsArray,
    selectedTemplateName,
    setSelectedTemplateName,
    createDashletModalIsOpen: open,
    toggleCreateDashletModal: () => toggleOpen(),
    isEnabled,
    isDroppable,
    toggleIsDroppable: setIsDroppable,
    toggleIsEnabled: setIsEnabled,
    onLayoutChange,
    onCreateDashletOpen,
    onResize,
    toggleOpen,
  };
}
