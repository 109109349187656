import styled from "styled-components";

import useTools from "./hooks/useTools";
import { groupBy } from "lodash";
import AddATool from "./Molecules/AddATool/AddTool";
import CustomToolkit from "./Organisms/CustomToolkit/CustomToolkit";
import SharedToolkit from "./Organisms/SharedToolkit/SharedToolkit";
import useCreateFavorite from "../Frame/hooks/useCreateFavorite";
import React from "react";
import PageHeader from "../Shared/Organisms/PageHeader/PageHeader";

const StyledDiv = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  & > div {
    width: calc(50% - 10px);
  }
`;

const ToolsPage = () => {
  const { data, isLoading } = useTools();
  const { mutate: createFavorite } = useCreateFavorite();

  if (isLoading) {
    return <p>Is Loading</p>;
  }
  const { false: customData, true: sharedData } = groupBy(data, "shared");

  return (
    <div style={{ padding: "20px" }}>
      <PageHeader
        title="Tools"
        sub="Locations, URLs, Dashboards you can view directly from with the Nagios XI application."
        favoriteClicked={() =>
          createFavorite({ title: "Tools", url: "/tools" })
        }
      />
      <AddATool />
      <StyledDiv>
        <CustomToolkit data={customData} />
        <SharedToolkit data={sharedData} />
      </StyledDiv>
    </div>
  );
};

export default ToolsPage;
