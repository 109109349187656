import React, { useContext, useState } from "react";
import { Table } from "antd";
import { UsersContext } from "../../../../context";
import { DeployContext } from "../../Organisms/MassDeployDialogBox/DeployDialogBox";

export default function RecipientTable() {
  const { users } = useContext(UsersContext);
  const { updateSelectedUsers } = useContext(DeployContext);

  const [selectedRowKeysList, setSelectedRowKeysList] = useState<any[]>([]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
  ];

  return (
    <Table
      rowSelection={{
        selectedRowKeys: selectedRowKeysList,
        onChange: (selectedRowKeys) => {
          setSelectedRowKeysList(selectedRowKeys);
          updateSelectedUsers(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
          name: record.name,
        }),
      }}
      columns={columns}
      dataSource={users.map((user, i) => ({ key: i, ...user }))}
    />
  );
}
