export const shareRecipients = [
  {
    key: 0,
    name: "Abed Nadir",
    username: "nadir.abed",
  },
  {
    key: 1,
    name: "Shirley Bennet",
    username: "bennet.shirley",
  },
  {
    key: 2,
    name: "Bryan Bloomquist",
    username: "bloomquist.bryan",
  },
  {
    key: 3,
    name: "David Kessler",
    username: "kessler.david",
  },
  {
    key: 4,
    name: "Aaron Berndt",
    username: "berndt.aaron",
  },
  {
    key: 5,
    name: "Alley Green",
    username: "green.alley",
  },
  {
    key: 6,
    name: "Jake Omann",
    username: "omann.jake",
  },
];
