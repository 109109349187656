import { useQuery } from "react-query";
import { FAVORITES_URL } from "../../../api.config";
import { fetchData } from "../../../utils/axiosHelpers";

export const KEY = "Fetch Favorites";

const fetchFavorites = async () => fetchData(FAVORITES_URL);

export const preFetchFavorites = (queryClient) =>
  queryClient.prefetchQuery(KEY, fetchFavorites);

export default function useFavorites(options?: any) {
  return useQuery(KEY, fetchFavorites);
}
