import { find } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { DASHLET_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { deleteData } from "../../../utils/axiosHelpers";
import { Dashboard, Dashlet } from "../../DashboardPage/DashboardPageTypes";
import {
  FETCH_DASHBOARD_KEY,
  KEY,
} from "../../DashboardPage/hooks/useDashboards";

export default function useDeleteDashlet() {
  const CALL_REASON = "Delete Dashlet";

  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    ({ dashletToDelete, dashboard_id }) =>
      deleteData(`${DASHLET_URL}/${dashletToDelete.dashlet_id}`, {
        ...dashletToDelete,
        dashboard_id,
      }),
    {
      onMutate: async ({ dashletToDelete, dashboard_id }: any) => {
        const queryKey = [FETCH_DASHBOARD_KEY, dashboard_id];

        await queryClient.cancelQueries(queryKey);
        const previousDashboards: any = queryClient.getQueryData(KEY);
        const previousDashboard: any = queryClient.getQueryData(queryKey);

        const { dashlets, ...rest } = find(previousDashboards.dashboards, {
          dashboard_id,
        });

        const { dashlet_id } = dashletToDelete;

        const newDashletList = dashlets.filter(
          (dashlet: Dashlet) => dashlet.dashlet_id !== dashlet_id
        );

        const newDashboardObject = {
          ...rest,
          dashlets: newDashletList,
        };

        queryClient.setQueryData(queryKey, {
          dashboard: newDashboardObject,
        });

        queryClient.setQueryData(KEY, {
          dashboards: previousDashboards.dashboards.map(
            (dashboard: Dashboard) => {
              if (dashboard.dashboard_id === dashboard_id) {
                return newDashboardObject;
              }
              return dashboard;
            }
          ),
        });

        return { previousDashboard, newDashboardObject, queryKey };
      },
      onSuccess: (data, _, context) => {
        onSuccessNotification(data, CALL_REASON);
      },
      onError: (data, _, context) => {
        onErrorNotification(data, CALL_REASON);
        if (context?.newDashboardObject) {
          queryClient.setQueryData<any>(
            context?.queryKey,
            context.newDashboardObject
          );
        }
      },
      onSettled: (data, _1, _2, context) => {
        queryClient.invalidateQueries(context?.queryKey);
        queryClient.invalidateQueries(KEY);
      },
    }
  );
}
