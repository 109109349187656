import { Table, TableProps } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import _ from "lodash";
import { styledTableBase } from "../CustomTable/CustomTable";
import { IconHorizontalFill } from "../../Atoms/FontIcons/FontIcons";
import { ColumnType } from "antd/lib/table";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";

const reorder = (data: any[], result: DropResult, rowKey: string) => {
  if (!result.destination) {
    return [];
  }
  let newData = _.cloneDeep(data);
  let [removed] = newData.splice(result.source.index, 1);
  newData.splice(result.destination.index, 0, removed);
  return newData.map((item, index) => {
    return { ...item, [rowKey]: index };
  });
};

export default function CustomDragTable(props: {
  draggableRowStyle?: string;
  draggableTableProps: Required<
    Pick<
      TableProps<any>,
      "columns" | "dataSource" | "rowKey" | "showHeader" | "loading"
    >
  >;
  onTableUpdate?: (updatedTable: any) => void;
}) {
  const [data, setData] = useState(
    props.draggableTableProps.dataSource
      ? (_.cloneDeep(props.draggableTableProps.dataSource) as any[])
      : []
  );

  const { theme, currentMasterTheme } = useMasterTheme();

  const columns: ColumnType<any>[] = [
    {
      title: "",
      dataIndex: "sort",
      key: "sort",
      width: "10%",
      render: () => <IconHorizontalFill size={"25px"} />,
    } as any,
  ].concat(
    props.draggableTableProps.columns ? props.draggableTableProps.columns : []
  );

  //adds declarative widths for react-beautiful-dnd draggable row
  const draggableRowStyle = () => {
    let style = "";
    let index = 1;
    for (let value of columns) {
      if (value.width) {
        style =
          style +
          `&& tr.active-drag > :nth-child(${index.toString()}) {width: ${
            value.width
          };}`;
        index++;
      }
    }
    return style;
  };

  const StyledTable = styled(Table)`
    ${(props) => styledTableBase(props.theme)}
    ${draggableRowStyle()}
  `;

  useEffect(() => {
    setData(
      props.draggableTableProps.dataSource
        ? (_.cloneDeep(props.draggableTableProps.dataSource) as any[])
        : []
    );
  }, [props.draggableTableProps.dataSource]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const reorderedData = reorder(
      data,
      result,
      props.draggableTableProps.rowKey as string
    );
    setData(reorderedData);
    if (props.onTableUpdate) {
      props.onTableUpdate(reorderedData);
    }
  };

  const DraggableRow = ({ index, className, style, ...restProps }) => {
    const key = data.findIndex(
      (x: any) =>
        x[props.draggableTableProps.rowKey as string] ===
        restProps["data-row-key"]
    );
    return (
      <Draggable index={key} key={key} draggableId={key.toString()}>
        {(provided, snapshot) => {
          return (
            <tr
              className={snapshot.isDragging ? "active-drag" : ""}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...{
                ...restProps,
                children:
                  restProps.children.length > 1
                    ? [
                        <td
                          {...provided.dragHandleProps}
                          key={key}
                          style={{ cursor: "ns-resize" }}
                          className="ant-table-cell"
                        >
                          <IconHorizontalFill
                            size={"25px"}
                            color={
                              currentMasterTheme === "light"
                                ? themeVariables.colorGrayLight2
                                : theme.typographySecondaryColor
                            }
                          />
                        </td>,
                      ].concat(
                        restProps.children.slice(1, restProps.children.length)
                      )
                    : restProps.children,
              }}
            />
          );
        }}
      </Draggable>
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="custom-drop-table">
        {(provided, snapshot) => (
          <>
            <StyledTable
              {...props}
              rowKey={props.draggableTableProps.rowKey}
              columns={columns}
              dataSource={data}
              pagination={false}
              showHeader={props.draggableTableProps.showHeader}
              components={{
                body: {
                  wrapper: (wrapperProps: any) => {
                    return (
                      <tbody
                        className={wrapperProps.className}
                        ref={provided.innerRef}
                      >
                        {wrapperProps.children}
                        {provided.placeholder}
                      </tbody>
                    );
                  },
                  row: (rowProps: any) => DraggableRow({ ...rowProps }),
                },
              }}
            />
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
}
