import {
  HeatMapDashlet,
  LineChartDashlet,
  CustomUrlDashlet,
  NetworkDashlet,
  LatestAlertDashlet,
  MetricsOverviewDashlet,
  MonitoringEngineCheckDashlet,
  MonitoringEnginePerformanceDashlet,
  MonitoringEngineQueueDashlet,
  CoreComponentStatusDashlet,
  ServerStatisticsDashlet,
} from "..";
import {
  AvailabilityDashlet,
  HostStatusGroupSummaryDashlet,
  HostStatusSummaryDashlet,
  MonitoringEngineProcessDashlet,
  NetworkOutageDashlet,
  PerfDataGaugeDashlet,
  ServiceStatusGroupSummaryDashlet,
  ServiceStatusSummaryDashlet,
} from "../Atoms";
import { SLADashlet } from "../Atoms/SLADashlet";

export default function useDashletObject(demo: boolean, dashlet?: any) {
  return demo
    ? {
        availability: (
          <AvailabilityDashlet
            demo={true}
            properties={{ type: "service" }}
            dashletId="demo"
          />
        ),
        heatmap: (
          <HeatMapDashlet
            properties={{
              height: 3,
              width: 3,
            }}
            demo={true}
            dashletId="demo"
          />
        ),
        perfdata_line: (
          <LineChartDashlet
            properties={{
              y_axis_title: "demo",
              x_axis_title: "demo",
              colors: "nivo",
            }}
            demo={true}
            dashletId="demo"
          />
        ),
        perfdata_gauge: <PerfDataGaugeDashlet demo={true} dashletId="demo" />,

        customurl: <CustomUrlDashlet url="https://www.nagios.com/" />,
        network: <NetworkDashlet demo={true} dashletId="demo" />,
        network_outages: <NetworkOutageDashlet demo={true} dashletId="demo" />,
        latest_alerts: (
          <LatestAlertDashlet
            properties={{ height: 3, width: 3 }}
            demo={true}
            dashletId="demo"
          />
        ),
        metrics_overview: (
          <MetricsOverviewDashlet dashletId="demo" demo={true} />
        ),
        monitoring_engine_check: (
          <MonitoringEngineCheckDashlet demo={true} dashletId="demo" />
        ),
        monitoring_engine_performance: (
          <MonitoringEnginePerformanceDashlet demo={true} dashletId="demo" />
        ),
        monitoring_engine_event_queue: (
          <MonitoringEngineQueueDashlet demo={true} dashletId="demo" />
        ),
        monitoring_engine_processes: (
          <MonitoringEngineProcessDashlet
            properties={{}}
            demo={true}
            dashletId="demo"
          />
        ),
        monitoring_engine_stats: (
          <MonitoringEngineCheckDashlet demo={true} dashletId="demo" />
        ),

        core_component_status: (
          <CoreComponentStatusDashlet
            properties={{ height: 3, width: 3 }}
            demo={true}
            dashletId="demo"
          />
        ),
        server_stats: <ServerStatisticsDashlet demo={true} dashletId="demo" />,
        sla: <SLADashlet demo={true} dashletId="demo" />,
        status_summary_hosts: (
          <HostStatusSummaryDashlet demo={true} dashletId="demo" />
        ),
        status_summary_services: (
          <ServiceStatusSummaryDashlet demo={true} dashletId="demo" />
        ),
        status_summary_hostgroups: (
          <HostStatusGroupSummaryDashlet demo={true} dashletId="demo" />
        ),
        status_summary_servicegroups: (
          <ServiceStatusGroupSummaryDashlet demo={true} dashletId="demo" />
        ),
      }
    : {
        availability: (
          <AvailabilityDashlet
            demo={false}
            properties={{ type: "service" }}
            dashletId={dashlet.dashlet_id}
          />
        ),
        heatmap: (
          <HeatMapDashlet
            properties={dashlet.properties}
            demo={false}
            dashletId={dashlet.dashlet_id}
          />
        ),
        perfdata_line: (
          <LineChartDashlet
            properties={dashlet.properties}
            demo={false}
            dashletId={dashlet.dashlet_id}
          />
        ),

        perfdata_gauge: (
          <PerfDataGaugeDashlet demo={false} dashletId={dashlet.dashlet_id} />
        ),
        customurl: <CustomUrlDashlet url={dashlet.endpoint} />,
        network: <NetworkDashlet demo={false} dashletId={dashlet.dashlet_id} />,
        network_outages: (
          <NetworkOutageDashlet demo={false} dashletId={dashlet.dashlet_id} />
        ),
        latest_alerts: (
          <LatestAlertDashlet
            properties={{ height: 3, width: 3 }}
            demo={false}
            dashletId={dashlet.dashlet_id}
          />
        ),
        metrics_overview: (
          <MetricsOverviewDashlet dashletId={dashlet.dashlet_id} demo={false} />
        ),
        monitoring_engine_check: (
          <MonitoringEngineCheckDashlet
            demo={false}
            dashletId={dashlet.dashlet_id}
          />
        ),
        monitoring_engine_processes: (
          <MonitoringEngineProcessDashlet
            properties={{}}
            demo={false}
            dashletId={dashlet.dashlet_id}
          />
        ),
        monitoring_engine_performance: (
          <MonitoringEnginePerformanceDashlet
            demo={false}
            dashletId={dashlet.dashlet_id}
          />
        ),

        monitoring_engine_stats: (
          <MonitoringEngineCheckDashlet
            demo={false}
            dashletId={dashlet.dashlet_id}
          />
        ),
        monitoring_engine_event_queue: (
          <MonitoringEngineQueueDashlet
            demo={false}
            dashletId={dashlet.dashlet_id}
          />
        ),
        core_component_status: (
          <CoreComponentStatusDashlet
            properties={{ height: 3, width: 3 }}
            dashletId={dashlet.dashlet_id}
            demo={false}
          />
        ),
        server_stats: (
          <ServerStatisticsDashlet
            demo={false}
            dashletId={dashlet.dashlet_id}
          />
        ),
        sla: <SLADashlet demo={false} dashletId={dashlet.dashlet_id} />,
        status_summary_hostgroups: (
          <HostStatusGroupSummaryDashlet
            demo={false}
            dashletId={dashlet.dashlet_id}
          />
        ),
        status_summary_servicegroups: (
          <ServiceStatusGroupSummaryDashlet
            demo={false}
            dashletId={dashlet.dashlet_id}
          />
        ),
        status_summary_hosts: (
          <HostStatusSummaryDashlet
            demo={false}
            dashletId={dashlet.dashlet_id}
          />
        ),
        status_summary_services: (
          <ServiceStatusSummaryDashlet
            demo={false}
            dashletId={dashlet.dashlet_id}
          />
        ),
      };
}
