const languageObject = {
  de: {
    LoginForm: {
      TITLE: "Nagios XI Anmelden",
      USER_NAME_INPUT_TITLE: "Nutzername",
      PASSWORD_INPUT_TITLE: "Passwort",

      FORGET_PASSWORD_BUTTON_NAME: "Passwort vergessen",
      SUCESSS_MESSAGE: "Erfolge",
      PASSWORD_INPUT_ERROR_MESSAGE: "Bitte geben Sie Ihr Passwort ein!",
      USER_NAME_INPUT_ERROR_MESSAGE: "Bitte geben Sie Ihren Benutzernamen ein!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "Stornieren",
      DEPLOY_BUTTON_NAME: "Bereitstellen",
      DEPLOY_TITLE: "Bereitstellen -",
      MASS_DEPLOY_TITLE: "Massenbereitstellung",
      NO_SELECTED_DASHBOARDS_ERROR:
        "Bitte wählen Sie mindestens ein Dashboard für die Bereitstellung aus!",
      NO_SELECTED_USERS_ERROR:
        "Bitte wählen Sie mindestens einen Benutzer aus, für den die Bereitstellung erfolgen soll!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "Alle Dashboards",
    },
    DashboardsPage: {
      TITLE: "Dashboards Seite",
      DESCRIPTION:
        "Eine kurze Statusübersicht sowie Setup- und Onging-Verwaltungsaufgaben.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "Suche nach Dashboard-Namen",
    },
    DashboardTemplateCard: {
      TITLE: "Dashboard-Vorlagen",
      DESCRIPTION_ONE:
        "Tauschen Sie Ihre Ideen für Dashboards mit jedem Nagios XI-Benutzer aus, indem Sie Dashboard-Vorlagendateien freigeben.",
      DESCRIPTION_TWO:
        "Importieren oder exportieren Sie Nagios XI JSON-Dateien und fügen Sie Ihren Dashboards sofortige Leistung hinzu.",
    },
    DashboardTemplateImport: {
      TITLE: "Importieren",
      DESCRIPTION:
        "Laden Sie eine Dashboard-Vorlage (JSON) hoch, die für Sie freigegeben wurde, und sie wird in Ihrer Liste &quot;Alle Dashboards&quot; angezeigt.",
      SELECT_FILE_LABEL: "Datei aussuchen",
      SELECT_FILE_ERROR: "Bitte wählen Sie die zu importierende Datei aus!",
      BROWSE_BUTTON_NAME: "Durchsuche",
      IMPORT_BUTTON_NAME: "Importieren",
      INVALID_FILE_ERROR_TITLE: "Ungültiger Dateityp",
      INVALID_FILE_ERROR_MESSAGE:
        "Die Vorlagendatei muss im JSON-Format vorliegen!",
    },
    DashboardTemplateExport: {
      TITLE: "Export",
      DESCRIPTION:
        "Laden Sie eine Dashboard-Vorlage (JSON) hoch, die für Sie freigegeben wurde, und sie wird in Ihrer Liste &quot;Alle Dashboards&quot; angezeigt.",
      SELECt_TEMPLATE_LABEL: "Vorlage auswählen",
      SELECt_TEMPLATE_ERROR_MESSAGE:
        "Bitte wählen Sie die Vorlage zum Exportieren!",
      EXPORT_BUTTON_NAME: "Export",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "Löschen",
      POPCONFIRM_TEXT: "Möchten Sie das Dashboard wirklich löschen?",
      POPCONFIRM_OK: "In Ordnung",
      POPCONFIRM_NO: "Stornieren",
      CALL_REASON: "Dashboard löschen",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Duplikat",
      POPCONFIRM_TEXT:
        "Sind Sie sicher, dass Sie das Dashboard duplizieren möchten?",
      POPCONFIRM_OK: "In Ordnung",
      POPCONFIRM_NO: "Stornieren",
      CALL_REASON: "Dashboard duplizieren",
    },
    EditButton: {
      TOOLTIP_TEXT: "Bearbeiten",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Erstellen Sie ein Dashboard",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Dashboard-Titel",
      DASHBOARD_INPUT_ERROR_MESSAGE: "Bitte geben Sie den Dashboard-Titel ein!",
      SELECT_BACKGROUND_COLOR: "Wählen Sie Hintergrundfarbe",
      PUBLIC_SHARE_CHECKBOX: "Öffentlich / gemeinsam nutzbar",
      ALLOW_EDITING_CHECKBOX: "Bearbeitung zulassen",
      DASHLETS: "Dashlets",
    },
    FavoritesList: {
      BUTTON_NAME: "Favoriten bearbeiten",
    },
    FavoritesButton: {
      BUTTON_NAME: "Favoriten",
    },
    ShareButton: {
      BUTTON_NAME: "Aktie",
      TOOLTIP_TEXT: "Aktie",
      NOTIFICATION_TEXT1: "In die Zwischenablage kopiert",
      NOTIFICATION_TEXT2: "Dashboard-URL",
      DESCRIPTION: "Generierte URL",
    },
    ProfileButton: {
      BUTTON_NAME: "Profil",
    },
    Footer: {
      BUTTON_NAME: "Profil",
    },
    NagiosStatusBox: {
      TITLE: "Nagios Status",
    },
    StatusContent: {
      BUTTON_NAME: "Clear Unhandled",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "Sie überwachen noch keine Hosts!",
      NOT_MONITORING_SERVICE_MESSAGE: "Sie überwachen noch keine Dienste!",
      ONCE_SETUP_HOST_MESSAGE:
        "Sobald Sie einen Host eingerichtet haben, werden hier Daten angezeigt.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "Sobald Sie einen Dienst eingerichtet haben, werden hier Daten angezeigt.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "Dashboard speichern",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "Löschen",
      POPCONFIRM_TEXT: "Sind Sie sicher, dass Sie das Dashlet löschen möchten?",
      POPCONFIRM_OK: "In Ordnung",
      POPCONFIRM_NO: "Stornieren",
      CALL_REASON: "Dashlet löschen",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "Bearbeiten",
      POPCONFIRM_TEXT:
        "Sind Sie sicher, dass Sie das Dashlet bearbeiten möchten?",
      POPCONFIRM_OK: "In Ordnung",
      POPCONFIRM_NO: "Stornieren",
      CALL_REASON: "Dashlet bearbeiten",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Duplikat",
      POPCONFIRM_TEXT:
        "Sind Sie sicher, dass Sie das Dashlet duplizieren möchten?",
      POPCONFIRM_OK: "In Ordnung",
      POPCONFIRM_NO: "Stornieren",
      CALL_REASON: "Dashlet duplizieren",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Dashlet-Titel",
      SELECT_BACKGROUND_COLOR: "Wählen Sie Hintergrundfarbe",
      DASHLET_TYPE: "Dashlet-Typ",
      DASHLET_URL_INPUT: "Dashlet URL",
      DASHLET_DIMENSIONS: "Dashlet-Abmessungen",
      DASHLET_POSITION: "Dashlet Position",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE:
        "Bitte geben Sie den Dashlet-Titel ein!",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "Bitte geben Sie die Dashlet URL ein!",
    },
  },
  fr: {
    LoginForm: {
      TITLE: "Connexion à Nagios XI",
      USER_NAME_INPUT_TITLE: "Nom d&#39;utilisateur",
      PASSWORD_INPUT_TITLE: "Mot de passe",
      SUBMIT_BUTTON_NAME: "S&#39;identifier",
      FORGET_PASSWORD_BUTTON_NAME: "Mot de passe oublié",
      SUCESSS_MESSAGE: "Réussit",
      PASSWORD_INPUT_ERROR_MESSAGE: "Veuillez saisir votre mot de passe!",
      USER_NAME_INPUT_ERROR_MESSAGE:
        "Veuillez saisir votre nom d&#39;utilisateur!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "Annuler",
      DEPLOY_BUTTON_NAME: "Déployer",
      DEPLOY_TITLE: "Déployer -",
      MASS_DEPLOY_TITLE: "Déploiement en masse",
      NO_SELECTED_DASHBOARDS_ERROR:
        "Veuillez choisir au moins un tableau de bord à déployer!",
      NO_SELECTED_USERS_ERROR:
        "Veuillez choisir au moins un utilisateur sur lequel effectuer le déploiement!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "Tous les tableaux de bord",
    },
    DashboardsPage: {
      TITLE: "Page des tableaux de bord",
      DESCRIPTION:
        "Récapitulatif rapide de l&#39;état, configuration et tâches d&#39;administration en cours.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "Recherche par nom de tableau de bord",
    },
    DashboardTemplateCard: {
      TITLE: "Modèles de tableau de bord",
      DESCRIPTION_ONE:
        "Échangez vos idées de tableaux de bord avec n&#39;importe quel utilisateur de Nagios XI en partageant des fichiers de modèle de tableau de bord.",
      DESCRIPTION_TWO:
        "Importez ou exportez des fichiers JSON Nagios XI et ajoutez de la puissance instantanée à vos tableaux de bord.",
    },
    DashboardTemplateImport: {
      TITLE: "Importer",
      DESCRIPTION:
        "Téléchargez un modèle de tableau de bord (JSON) qui a été partagé avec vous, et il apparaîtra dans votre liste «Tous les tableaux de bord».",
      SELECT_FILE_LABEL: "Choisir le dossier",
      SELECT_FILE_ERROR: "Veuillez choisir le fichier à importer!",
      BROWSE_BUTTON_NAME: "Feuilleter",
      IMPORT_BUTTON_NAME: "Importer",
      INVALID_FILE_ERROR_TITLE: "Type de fichier invalide",
      INVALID_FILE_ERROR_MESSAGE:
        "Le fichier de modèle doit être au format JSON!",
    },
    DashboardTemplateExport: {
      TITLE: "Exportation",
      DESCRIPTION:
        "Téléchargez un modèle de tableau de bord (JSON) qui a été partagé avec vous, et il apparaîtra dans votre liste «Tous les tableaux de bord».",
      SELECt_TEMPLATE_LABEL: "Sélectionnez un modèle",
      SELECt_TEMPLATE_ERROR_MESSAGE: "Veuillez choisir le modèle à exporter!",
      EXPORT_BUTTON_NAME: "Exportation",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "Effacer",
      POPCONFIRM_TEXT: "Êtes-vous sûr de vouloir supprimer le tableau de bord",
      POPCONFIRM_OK: "D&#39;accord",
      POPCONFIRM_NO: "Annuler",
      CALL_REASON: "Supprimer le tableau de bord",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Dupliquer",
      POPCONFIRM_TEXT: "Êtes-vous sûr de vouloir dupliquer le tableau de bord",
      POPCONFIRM_OK: "D&#39;accord",
      POPCONFIRM_NO: "Annuler",
      CALL_REASON: "Dupliquer le tableau de bord",
    },
    EditButton: {
      TOOLTIP_TEXT: "Éditer",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Créer un tableau de bord",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Titre du tableau de bord",
      DASHBOARD_INPUT_ERROR_MESSAGE:
        "Veuillez saisir le titre du tableau de bord!",
      SELECT_BACKGROUND_COLOR: "Sélectionnez la couleur d&#39;arrière-plan",
      PUBLIC_SHARE_CHECKBOX: "Publique / partageable",
      ALLOW_EDITING_CHECKBOX: "Autoriser la modification",
      DASHLETS: "Dashlets",
    },
    FavoritesList: {
      BUTTON_NAME: "Éditer les favoris",
    },
    FavoritesButton: {
      BUTTON_NAME: "Favoris",
    },
    ShareButton: {
      BUTTON_NAME: "Partager",
      TOOLTIP_TEXT: "Partager",
      NOTIFICATION_TEXT1: "Copié dans le presse-papiers",
      NOTIFICATION_TEXT2: "URL du tableau de bord",
      DESCRIPTION: "URL générée",
    },
    ProfileButton: {
      BUTTON_NAME: "Profil",
    },
    Footer: {
      BUTTON_NAME: "Profil",
    },
    NagiosStatusBox: {
      TITLE: "Statut de Nagios",
    },
    StatusContent: {
      BUTTON_NAME: "Effacer non géré",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "Vous ne surveillez pas encore d&#39;hôtes!",
      NOT_MONITORING_SERVICE_MESSAGE:
        "Vous ne surveillez encore aucun service!",
      ONCE_SETUP_HOST_MESSAGE:
        "Une fois que vous avez configuré un hôte, vous verrez les données ici.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "Une fois que vous avez configuré un service, vous verrez des données ici.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "Enregistrer le tableau de bord",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "Effacer",
      POPCONFIRM_TEXT: "Êtes-vous sûr de vouloir supprimer le dashlet",
      POPCONFIRM_OK: "D&#39;accord",
      POPCONFIRM_NO: "Annuler",
      CALL_REASON: "Supprimer le dashlet",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "Éditer",
      POPCONFIRM_TEXT: "Êtes-vous sûr de vouloir modifier le dashlet",
      POPCONFIRM_OK: "D&#39;accord",
      POPCONFIRM_NO: "Annuler",
      CALL_REASON: "Modifier le dashlet",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Dupliquer",
      POPCONFIRM_TEXT: "Êtes-vous sûr de vouloir dupliquer le dashlet",
      POPCONFIRM_OK: "D&#39;accord",
      POPCONFIRM_NO: "Annuler",
      CALL_REASON: "Dupliquer le Dashlet",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Titre du dashlet",
      SELECT_BACKGROUND_COLOR: "Sélectionnez la couleur d&#39;arrière-plan",
      DASHLET_TYPE: "Type de dashlet",
      DASHLET_URL_INPUT: "URL du dashlet",
      DASHLET_DIMENSIONS: "Dimensions du dashlet",
      DASHLET_POSITION: "Position du dashlet",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE: "Veuillez saisir le titre du dashlet!",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "Veuillez saisir l&#39;URL du dashlet!",
    },
  },
  it: {
    LoginForm: {
      TITLE: "Nagios XI Accedi",
      USER_NAME_INPUT_TITLE: "Nome utente",
      PASSWORD_INPUT_TITLE: "Parola d&#39;ordine",
      SUBMIT_BUTTON_NAME: "Accesso",
      FORGET_PASSWORD_BUTTON_NAME: "Ha dimenticato la password",
      SUCESSS_MESSAGE: "Successo",
      PASSWORD_INPUT_ERROR_MESSAGE: "Inserisci la tua password!",
      USER_NAME_INPUT_ERROR_MESSAGE: "Inserisci il tuo nome utente!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "Annulla",
      DEPLOY_BUTTON_NAME: "Distribuisci",
      DEPLOY_TITLE: "Distribuire -",
      MASS_DEPLOY_TITLE: "Distribuzione di massa",
      NO_SELECTED_DASHBOARDS_ERROR:
        "Scegli almeno una dashboard da distribuire!",
      NO_SELECTED_USERS_ERROR:
        "Scegli almeno un utente a cui eseguire il deployment!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "Tutti i dashboard",
    },
    DashboardsPage: {
      TITLE: "Pagina dashboard",
      DESCRIPTION:
        "Un rapido riepilogo dello stato, configurazione e attività di amministrazione in corso",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "Cerca per nome dashboard",
    },
    DashboardTemplateCard: {
      TITLE: "Modelli di dashboard",
      DESCRIPTION_ONE:
        "Scambia le tue idee per i dashboard con qualsiasi utente Nagios XI condividendo i file modello del dashboard.",
      DESCRIPTION_TWO:
        "Importa o esporta file JSON Nagios XI e aggiungi potenza istantanea ai tuoi dashboard.",
    },
    DashboardTemplateImport: {
      TITLE: "Importare",
      DESCRIPTION:
        "Carica un modello di dashboard (JSON) che è stato condiviso con te e verrà visualizzato nell&#39;elenco &quot;Tutti i dashboard&quot;.",
      SELECT_FILE_LABEL: "Seleziona il file",
      SELECT_FILE_ERROR: "Scegli il file da importare!",
      BROWSE_BUTTON_NAME: "Navigare",
      IMPORT_BUTTON_NAME: "Importare",
      INVALID_FILE_ERROR_TITLE: "Tipo di file non valido",
      INVALID_FILE_ERROR_MESSAGE:
        "Il file modello deve essere in formato JSON!",
    },
    DashboardTemplateExport: {
      TITLE: "Esportare",
      DESCRIPTION:
        "Carica un modello di dashboard (JSON) che è stato condiviso con te e verrà visualizzato nell&#39;elenco &quot;Tutti i dashboard&quot;.",
      SELECt_TEMPLATE_LABEL: "Seleziona modello",
      SELECt_TEMPLATE_ERROR_MESSAGE: "Scegli il modello da esportare!",
      EXPORT_BUTTON_NAME: "Esportare",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "Elimina",
      POPCONFIRM_TEXT: "Sei sicuro di voler eliminare la dashboard",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Annulla",
      CALL_REASON: "Elimina dashboard",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Duplicare",
      POPCONFIRM_TEXT: "Sei sicuro di voler duplicare la dashboard",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Annulla",
      CALL_REASON: "Duplica dashboard",
    },
    EditButton: {
      TOOLTIP_TEXT: "modificare",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Crea una dashboard",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Titolo dashboard",
      DASHBOARD_INPUT_ERROR_MESSAGE: "Inserisci il titolo del dashboard!",
      SELECT_BACKGROUND_COLOR: "Seleziona il colore di sfondo",
      PUBLIC_SHARE_CHECKBOX: "Pubblico / condivisibile",
      ALLOW_EDITING_CHECKBOX: "Consenti modifica",
      DASHLETS: "Dashlet",
    },
    FavoritesList: {
      BUTTON_NAME: "Modifica Preferiti",
    },
    FavoritesButton: {
      BUTTON_NAME: "Preferiti",
    },
    ShareButton: {
      BUTTON_NAME: "Condividere",
      TOOLTIP_TEXT: "Condividere",
      NOTIFICATION_TEXT1: "Copiato negli appunti",
      NOTIFICATION_TEXT2: "URL dashboard",
      DESCRIPTION: "URL generato",
    },
    ProfileButton: {
      BUTTON_NAME: "Profilo",
    },
    Footer: {
      BUTTON_NAME: "Profilo",
    },
    NagiosStatusBox: {
      TITLE: "Stato di Nagios",
    },
    StatusContent: {
      BUTTON_NAME: "Cancella non gestito",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "Non stai ancora monitorando nessun host!",
      NOT_MONITORING_SERVICE_MESSAGE:
        "Non stai ancora monitorando alcun servizio!",
      ONCE_SETUP_HOST_MESSAGE:
        "Dopo aver configurato un host, vedrai i dati qui.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "Dopo aver configurato un servizio, vedrai i dati qui.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "Salva dashboard",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "Elimina",
      POPCONFIRM_TEXT: "Sei sicuro di voler eliminare Dashlet",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Annulla",
      CALL_REASON: "Elimina Dashlet",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "modificare",
      POPCONFIRM_TEXT: "Sei sicuro di voler modificare dashlet",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Annulla",
      CALL_REASON: "Modifica Dashlet",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Duplicare",
      POPCONFIRM_TEXT: "Sei sicuro di voler duplicare dashlet",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Annulla",
      CALL_REASON: "Duplica Dashlet",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Titolo Dashlet",
      SELECT_BACKGROUND_COLOR: "Seleziona il colore di sfondo",
      DASHLET_TYPE: "Tipo di dashboard",
      DASHLET_URL_INPUT: "URL del dashboard",
      DASHLET_DIMENSIONS: "Dimensioni del cruscotto",
      DASHLET_POSITION: "Posizione Dashlet",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE: "Inserisci il titolo del dashboard!",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "Inserisci l&#39;URL del dashboard!",
    },
  },
  ko: {
    LoginForm: {
      TITLE: "Nagios XI 로그인",
      USER_NAME_INPUT_TITLE: "사용자 이름",
      PASSWORD_INPUT_TITLE: "암호",
      SUBMIT_BUTTON_NAME: "로그인",
      FORGET_PASSWORD_BUTTON_NAME: "비밀번호를 잊으 셨나요",
      SUCESSS_MESSAGE: "성공",
      PASSWORD_INPUT_ERROR_MESSAGE: "비밀번호를 입력 해주세요!",
      USER_NAME_INPUT_ERROR_MESSAGE: "사용자 이름을 입력하십시오!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "취소",
      DEPLOY_BUTTON_NAME: "배포",
      DEPLOY_TITLE: "배포-",
      MASS_DEPLOY_TITLE: "대량 배포",
      NO_SELECTED_DASHBOARDS_ERROR: "배포 할 대시 보드를 하나 이상 선택하세요!",
      NO_SELECTED_USERS_ERROR: "배포 할 사용자를 한 명 이상 선택하십시오!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "모든 대시 보드",
    },
    DashboardsPage: {
      TITLE: "대시 보드 페이지",
      DESCRIPTION: "빠른 상태 요약, 설정 및 관리 작업 진행.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "대시 보드 이름으로 검색",
    },
    DashboardTemplateCard: {
      TITLE: "대시 보드 템플릿",
      DESCRIPTION_ONE:
        "대시 보드 템플릿 파일을 공유하여 Nagios XI 사용자와 대시 보드에 대한 아이디어를 교환하십시오.",
      DESCRIPTION_TWO:
        "Nagios XI JSON 파일을 가져 오거나 내보내고 대시 보드에 즉각적인 기능을 추가합니다.",
    },
    DashboardTemplateImport: {
      TITLE: "수입",
      DESCRIPTION:
        "나와 공유 된 대시 보드 템플릿 (JSON)을 업로드하면 &quot;모든 대시 보드&quot;목록에 표시됩니다.",
      SELECT_FILE_LABEL: "파일 선택",
      SELECT_FILE_ERROR: "가져올 파일을 선택하십시오!",
      BROWSE_BUTTON_NAME: "검색",
      IMPORT_BUTTON_NAME: "수입",
      INVALID_FILE_ERROR_TITLE: "잘못된 파일 유형",
      INVALID_FILE_ERROR_MESSAGE: "템플릿 파일은 JSON 형식이어야합니다!",
    },
    DashboardTemplateExport: {
      TITLE: "수출",
      DESCRIPTION:
        "나와 공유 된 대시 보드 템플릿 (JSON)을 업로드하면 &quot;모든 대시 보드&quot;목록에 표시됩니다.",
      SELECt_TEMPLATE_LABEL: "템플릿 선택",
      SELECt_TEMPLATE_ERROR_MESSAGE: "내보낼 템플릿을 선택하세요!",
      EXPORT_BUTTON_NAME: "수출",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "지우다",
      POPCONFIRM_TEXT: "대시 보드를 삭제 하시겠습니까?",
      POPCONFIRM_OK: "확인",
      POPCONFIRM_NO: "취소",
      CALL_REASON: "대시 보드 삭제",
    },
    CopyButton: {
      TOOLTIP_TEXT: "복제",
      POPCONFIRM_TEXT: "대시 보드를 복제 하시겠습니까?",
      POPCONFIRM_OK: "확인",
      POPCONFIRM_NO: "취소",
      CALL_REASON: "중복 대시 보드",
    },
    EditButton: {
      TOOLTIP_TEXT: "편집하다",
    },
    CreateDashboardCard: {
      CARD_TITLE: "대시 보드 만들기",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "대시 보드 제목",
      DASHBOARD_INPUT_ERROR_MESSAGE: "대시 보드 제목을 입력하세요!",
      SELECT_BACKGROUND_COLOR: "배경색 선택",
      PUBLIC_SHARE_CHECKBOX: "공개 / 공유 가능",
      ALLOW_EDITING_CHECKBOX: "편집 허용",
      DASHLETS: "대시 렛",
    },
    FavoritesList: {
      BUTTON_NAME: "즐겨 찾기 편집",
    },
    FavoritesButton: {
      BUTTON_NAME: "즐겨 찾기",
    },
    ShareButton: {
      BUTTON_NAME: "공유",
      TOOLTIP_TEXT: "공유",
      NOTIFICATION_TEXT1: "클립 보드에 복사 됨",
      NOTIFICATION_TEXT2: "대시 보드 URL",
      DESCRIPTION: "생성 된 URL",
    },
    ProfileButton: {
      BUTTON_NAME: "프로필",
    },
    Footer: {
      BUTTON_NAME: "프로필",
    },
    NagiosStatusBox: {
      TITLE: "Nagios 상태",
    },
    StatusContent: {
      BUTTON_NAME: "처리되지 않은 항목 지우기",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "아직 호스트를 모니터링하고 있지 않습니다!",
      NOT_MONITORING_SERVICE_MESSAGE: "아직 모니터링중인 서비스가 없습니다!",
      ONCE_SETUP_HOST_MESSAGE: "호스트를 설정하면 여기에 데이터가 표시됩니다.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "서비스를 설정하면 여기에 데이터가 표시됩니다.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "대시 보드 저장",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "지우다",
      POPCONFIRM_TEXT: "dashlet을 삭제 하시겠습니까?",
      POPCONFIRM_OK: "확인",
      POPCONFIRM_NO: "취소",
      CALL_REASON: "Dashlet 삭제",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "편집하다",
      POPCONFIRM_TEXT: "dashlet을 편집 하시겠습니까?",
      POPCONFIRM_OK: "확인",
      POPCONFIRM_NO: "취소",
      CALL_REASON: "Dashlet 편집",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "복제",
      POPCONFIRM_TEXT: "dashlet을 복제 하시겠습니까?",
      POPCONFIRM_OK: "확인",
      POPCONFIRM_NO: "취소",
      CALL_REASON: "Dashlet 중복",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Dashlet 제목",
      SELECT_BACKGROUND_COLOR: "배경색 선택",
      DASHLET_TYPE: "Dashlet 유형",
      DASHLET_URL_INPUT: "Dashlet URL",
      DASHLET_DIMENSIONS: "Dashlet 치수",
      DASHLET_POSITION: "Dashlet 위치",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE: "Dashlet 제목을 입력하세요!",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "Dashlet URL을 입력하세요!",
    },
  },
  pt: {
    LoginForm: {
      TITLE: "Login do Nagios XI",
      USER_NAME_INPUT_TITLE: "Nome do usuário",
      PASSWORD_INPUT_TITLE: "Senha",
      SUBMIT_BUTTON_NAME: "Conecte-se",
      FORGET_PASSWORD_BUTTON_NAME: "Esqueceu a senha",
      SUCESSS_MESSAGE: "Sucessos",
      PASSWORD_INPUT_ERROR_MESSAGE: "Por favor insira sua senha!",
      USER_NAME_INPUT_ERROR_MESSAGE: "Por favor insira seu nome de usuário!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "Cancelar",
      DEPLOY_BUTTON_NAME: "Implantar",
      DEPLOY_TITLE: "Implantar -",
      MASS_DEPLOY_TITLE: "Distribuição em massa",
      NO_SELECTED_DASHBOARDS_ERROR:
        "Escolha pelo menos um painel para implantar!",
      NO_SELECTED_USERS_ERROR: "Escolha pelo menos um usuário para implantar!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "Todos os painéis",
    },
    DashboardsPage: {
      TITLE: "Página de painéis",
      DESCRIPTION:
        "Um rápido resumo do status, configuração e tarefas administrativas contínuas.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "Pesquisa por nome do painel",
    },
    DashboardTemplateCard: {
      TITLE: "Modelos de painel",
      DESCRIPTION_ONE:
        "Troque ideias para painéis com qualquer usuário do Nagios XI, compartilhando arquivos de modelo de painel.",
      DESCRIPTION_TWO:
        "Importe ou exporte arquivos JSON do Nagios XI e adicione poder instantâneo aos seus painéis.",
    },
    DashboardTemplateImport: {
      TITLE: "Importar",
      DESCRIPTION:
        "Carregue um modelo de painel (JSON) que foi compartilhado com você e ele aparecerá na sua lista “Todos os painéis”.",
      SELECT_FILE_LABEL: "Selecione o arquivo",
      SELECT_FILE_ERROR: "Escolha o arquivo para importar!",
      BROWSE_BUTTON_NAME: "Squeaky toy",
      IMPORT_BUTTON_NAME: "Importar",
      INVALID_FILE_ERROR_TITLE: "Tipo de arquivo inválido",
      INVALID_FILE_ERROR_MESSAGE:
        "O arquivo de modelo precisa estar no formato JSON!",
    },
    DashboardTemplateExport: {
      TITLE: "Exportar",
      DESCRIPTION:
        "Carregue um modelo de painel (JSON) que foi compartilhado com você e ele aparecerá na sua lista “Todos os painéis”.",
      SELECt_TEMPLATE_LABEL: "Selecione o modelo",
      SELECt_TEMPLATE_ERROR_MESSAGE: "Escolha o modelo para exportar!",
      EXPORT_BUTTON_NAME: "Exportar",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "Excluir",
      POPCONFIRM_TEXT: "Tem certeza de que deseja excluir o painel",
      POPCONFIRM_OK: "Está bem",
      POPCONFIRM_NO: "Cancelar",
      CALL_REASON: "Excluir painel",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Duplicado",
      POPCONFIRM_TEXT: "Tem certeza de que deseja duplicar o painel",
      POPCONFIRM_OK: "Está bem",
      POPCONFIRM_NO: "Cancelar",
      CALL_REASON: "Painel duplicado",
    },
    EditButton: {
      TOOLTIP_TEXT: "Editar",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Crie um painel",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Título do painel",
      DASHBOARD_INPUT_ERROR_MESSAGE: "Insira o título do painel!",
      SELECT_BACKGROUND_COLOR: "Selecione a cor de fundo",
      PUBLIC_SHARE_CHECKBOX: "Público / Compartilhável",
      ALLOW_EDITING_CHECKBOX: "Permitir edição",
      DASHLETS: "Dashlets",
    },
    FavoritesList: {
      BUTTON_NAME: "Editar favoritos",
    },
    FavoritesButton: {
      BUTTON_NAME: "Favoritos",
    },
    ShareButton: {
      BUTTON_NAME: "Compartilhar",
      TOOLTIP_TEXT: "Compartilhar",
      NOTIFICATION_TEXT1: "Copiado para a área de transferência",
      NOTIFICATION_TEXT2: "URL do painel",
      DESCRIPTION: "URL gerado",
    },
    ProfileButton: {
      BUTTON_NAME: "Perfil",
    },
    Footer: {
      BUTTON_NAME: "Perfil",
    },
    NagiosStatusBox: {
      TITLE: "Status do Nagios",
    },
    StatusContent: {
      BUTTON_NAME: "Limpar não manipulado",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE:
        "Você ainda não está monitorando nenhum host!",
      NOT_MONITORING_SERVICE_MESSAGE:
        "Você ainda não está monitorando nenhum serviço!",
      ONCE_SETUP_HOST_MESSAGE:
        "Depois de configurar um host, você verá os dados aqui.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "Depois de configurar um serviço, você verá os dados aqui.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "Salvar painel",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "Excluir",
      POPCONFIRM_TEXT: "Tem certeza de que deseja excluir o painel",
      POPCONFIRM_OK: "Está bem",
      POPCONFIRM_NO: "Cancelar",
      CALL_REASON: "Apagar Dashlet",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "Editar",
      POPCONFIRM_TEXT: "Tem certeza que deseja editar o painel",
      POPCONFIRM_OK: "Está bem",
      POPCONFIRM_NO: "Cancelar",
      CALL_REASON: "Editar Dashlet",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Duplicado",
      POPCONFIRM_TEXT: "Tem certeza de que deseja duplicar o painel",
      POPCONFIRM_OK: "Está bem",
      POPCONFIRM_NO: "Cancelar",
      CALL_REASON: "Dashlet duplicado",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Título do Dashlet",
      SELECT_BACKGROUND_COLOR: "Selecione a cor de fundo",
      DASHLET_TYPE: "Tipo de Dashlet",
      DASHLET_URL_INPUT: "URL do Dashlet",
      DASHLET_DIMENSIONS: "Dimensões do Dashlet",
      DASHLET_POSITION: "Posição do Dashlet",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE: "Insira o título do Dashlet!",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "Insira o URL do Dashlet!",
    },
  },
  ru: {
    LoginForm: {
      TITLE: "Nagios XI Войти",
      USER_NAME_INPUT_TITLE: "Имя пользователя",
      PASSWORD_INPUT_TITLE: "пароль",
      SUBMIT_BUTTON_NAME: "Авторизоваться",
      FORGET_PASSWORD_BUTTON_NAME: "Забыли пароль",
      SUCESSS_MESSAGE: "Успехов",
      PASSWORD_INPUT_ERROR_MESSAGE: "Пожалуйста, введите свой пароль!",
      USER_NAME_INPUT_ERROR_MESSAGE:
        "Пожалуйста, введите ваше имя пользователя!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "отменить",
      DEPLOY_BUTTON_NAME: "Развернуть",
      DEPLOY_TITLE: "Развернуть -",
      MASS_DEPLOY_TITLE: "Массовое развертывание",
      NO_SELECTED_DASHBOARDS_ERROR:
        "Выберите хотя бы одну панель для развертывания!",
      NO_SELECTED_USERS_ERROR:
        "Выберите хотя бы одного пользователя для развертывания!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "Все информационные панели",
    },
    DashboardsPage: {
      TITLE: "Страница панели мониторинга",
      DESCRIPTION:
        "Краткое описание состояния, настройка и выполнение административных задач.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "Поиск по названию панели управления",
    },
    DashboardTemplateCard: {
      TITLE: "Шаблоны дашбордов",
      DESCRIPTION_ONE:
        "Обменивайтесь своими идеями о панелях мониторинга с любым пользователем Nagios XI, делясь файлами шаблонов панелей мониторинга.",
      DESCRIPTION_TWO:
        "Импортируйте или экспортируйте файлы JSON Nagios XI и мгновенно добавляйте мощность своим панелям мониторинга.",
    },
    DashboardTemplateImport: {
      TITLE: "импорт",
      DESCRIPTION:
        "Загрузите шаблон панели мониторинга (JSON), к которому вам поделились, и он появится в вашем списке «Все панели мониторинга».",
      SELECT_FILE_LABEL: "Выбрать файл",
      SELECT_FILE_ERROR: "Выберите файл для импорта!",
      BROWSE_BUTTON_NAME: "Просматривать",
      IMPORT_BUTTON_NAME: "импорт",
      INVALID_FILE_ERROR_TITLE: "Неверный тип файла",
      INVALID_FILE_ERROR_MESSAGE: "Файл шаблона должен быть в формате JSON!",
    },
    DashboardTemplateExport: {
      TITLE: "Экспорт",
      DESCRIPTION:
        "Загрузите шаблон панели мониторинга (JSON), к которому вам поделились, и он появится в вашем списке «Все панели мониторинга».",
      SELECt_TEMPLATE_LABEL: "Выбрать шаблон",
      SELECt_TEMPLATE_ERROR_MESSAGE: "Выберите шаблон для экспорта!",
      EXPORT_BUTTON_NAME: "Экспорт",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "удалять",
      POPCONFIRM_TEXT: "Вы уверены, что хотите удалить панель управления?",
      POPCONFIRM_OK: "Хорошо",
      POPCONFIRM_NO: "отменить",
      CALL_REASON: "Удалить панель мониторинга",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Дубликат",
      POPCONFIRM_TEXT:
        "Вы уверены, что хотите продублировать панель управления",
      POPCONFIRM_OK: "Хорошо",
      POPCONFIRM_NO: "отменить",
      CALL_REASON: "Дубликат панели управления",
    },
    EditButton: {
      TOOLTIP_TEXT: "редактировать",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Создать информационную панель",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Заголовок панели инструментов",
      DASHBOARD_INPUT_ERROR_MESSAGE:
        "Пожалуйста, введите заголовок приборной панели!",
      SELECT_BACKGROUND_COLOR: "Выбрать цвет фона",
      PUBLIC_SHARE_CHECKBOX: "Общедоступный / общий",
      ALLOW_EDITING_CHECKBOX: "Разрешить редактирование",
      DASHLETS: "Дашлец",
    },
    FavoritesList: {
      BUTTON_NAME: "Изменить избранное",
    },
    FavoritesButton: {
      BUTTON_NAME: "Избранное",
    },
    ShareButton: {
      BUTTON_NAME: "Поделиться",
      TOOLTIP_TEXT: "Поделиться",
      NOTIFICATION_TEXT1: "Скопировано в буфер обмена",
      NOTIFICATION_TEXT2: "URL панели управления",
      DESCRIPTION: "Созданный URL",
    },
    ProfileButton: {
      BUTTON_NAME: "Профиль",
    },
    Footer: {
      BUTTON_NAME: "Профиль",
    },
    NagiosStatusBox: {
      TITLE: "Статус Nagios",
    },
    StatusContent: {
      BUTTON_NAME: "Очистить необработанный",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "Вы еще не отслеживаете хосты!",
      NOT_MONITORING_SERVICE_MESSAGE: "Вы пока не следите за услугами!",
      ONCE_SETUP_HOST_MESSAGE:
        "После того, как вы настроите хост, вы увидите здесь данные.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "После настройки службы вы увидите здесь данные.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "Сохранить панель мониторинга",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "удалять",
      POPCONFIRM_TEXT: "Вы уверены, что хотите удалить дашлет",
      POPCONFIRM_OK: "Хорошо",
      POPCONFIRM_NO: "отменить",
      CALL_REASON: "Удалить дашлет",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "редактировать",
      POPCONFIRM_TEXT: "Вы уверены, что хотите редактировать дашлет",
      POPCONFIRM_OK: "Хорошо",
      POPCONFIRM_NO: "отменить",
      CALL_REASON: "Редактировать дашлет",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Дубликат",
      POPCONFIRM_TEXT: "Вы уверены, что хотите продублировать дашлет",
      POPCONFIRM_OK: "Хорошо",
      POPCONFIRM_NO: "отменить",
      CALL_REASON: "Дубликат дашлета",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Заголовок дашлета",
      SELECT_BACKGROUND_COLOR: "Выбрать цвет фона",
      DASHLET_TYPE: "Тип дашлета",
      DASHLET_URL_INPUT: "URL дашлета",
      DASHLET_DIMENSIONS: "Размеры дашлета",
      DASHLET_POSITION: "Позиция Дашлета",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE:
        "Пожалуйста, введите название дашлета!",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "Пожалуйста, введите URL-адрес дашлета!",
    },
  },
  es: {
    LoginForm: {
      TITLE: "Iniciar sesión en Nagios XI",
      USER_NAME_INPUT_TITLE: "Nombre de usuario",
      PASSWORD_INPUT_TITLE: "Contraseña",
      SUBMIT_BUTTON_NAME: "Iniciar sesión",
      FORGET_PASSWORD_BUTTON_NAME: "Se te olvidó tu contraseña",
      SUCESSS_MESSAGE: "Éxitos",
      PASSWORD_INPUT_ERROR_MESSAGE: "¡Ingrese su contraseña!",
      USER_NAME_INPUT_ERROR_MESSAGE: "¡Ingrese su nombre de usuario!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "Cancelar",
      DEPLOY_BUTTON_NAME: "Desplegar",
      DEPLOY_TITLE: "Implementar -",
      MASS_DEPLOY_TITLE: "Despliegue masivo",
      NO_SELECTED_DASHBOARDS_ERROR: "Elija al menos un panel para implementar.",
      NO_SELECTED_USERS_ERROR: "¡Elija al menos un usuario para implementar!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "Todos los paneles",
    },
    DashboardsPage: {
      TITLE: "Página de paneles",
      DESCRIPTION:
        "Un resumen de estado rápido, configuración y tareas administrativas continuas.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "Buscar por nombre del panel",
    },
    DashboardTemplateCard: {
      TITLE: "Plantillas de tablero",
      DESCRIPTION_ONE:
        "Intercambie sus ideas para paneles con cualquier usuario de Nagios XI compartiendo archivos de plantilla de panel.",
      DESCRIPTION_TWO:
        "Importe o exporte archivos JSON de Nagios XI y agregue energía instantánea a sus paneles.",
    },
    DashboardTemplateImport: {
      TITLE: "Importar",
      DESCRIPTION:
        "Sube una plantilla de panel (JSON) que se haya compartido contigo y aparecerá en tu lista &quot;Todos los paneles&quot;.",
      SELECT_FILE_LABEL: "Seleccione Archivo",
      SELECT_FILE_ERROR: "¡Elija el archivo para importar!",
      BROWSE_BUTTON_NAME: "Vistazo",
      IMPORT_BUTTON_NAME: "Importar",
      INVALID_FILE_ERROR_TITLE: "Tipo de archivo invalido",
      INVALID_FILE_ERROR_MESSAGE:
        "El archivo de plantilla debe estar en formato JSON.",
    },
    DashboardTemplateExport: {
      TITLE: "Exportar",
      DESCRIPTION:
        "Sube una plantilla de panel (JSON) que se haya compartido contigo y aparecerá en tu lista &quot;Todos los paneles&quot;.",
      SELECt_TEMPLATE_LABEL: "Seleccionar plantilla",
      SELECt_TEMPLATE_ERROR_MESSAGE: "¡Elija la plantilla para exportar!",
      EXPORT_BUTTON_NAME: "Exportar",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "Eliminar",
      POPCONFIRM_TEXT: "¿Está seguro de que desea eliminar el panel?",
      POPCONFIRM_OK: "Okay",
      POPCONFIRM_NO: "Cancelar",
      CALL_REASON: "Eliminar panel",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Duplicar",
      POPCONFIRM_TEXT: "¿Está seguro de que desea duplicar el panel?",
      POPCONFIRM_OK: "Okay",
      POPCONFIRM_NO: "Cancelar",
      CALL_REASON: "Panel de control duplicado",
    },
    EditButton: {
      TOOLTIP_TEXT: "Editar",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Crear un tablero",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Título del tablero",
      DASHBOARD_INPUT_ERROR_MESSAGE: "Introduzca el título del panel.",
      SELECT_BACKGROUND_COLOR: "Seleccionar color de fondo",
      PUBLIC_SHARE_CHECKBOX: "Público / Compartible",
      ALLOW_EDITING_CHECKBOX: "Permitir edición",
      DASHLETS: "Dashlets",
    },
    FavoritesList: {
      BUTTON_NAME: "Editar favoritos",
    },
    FavoritesButton: {
      BUTTON_NAME: "Favoritos",
    },
    ShareButton: {
      BUTTON_NAME: "Compartir",
      TOOLTIP_TEXT: "Compartir",
      NOTIFICATION_TEXT1: "Copiado al portapapeles",
      NOTIFICATION_TEXT2: "URL del panel",
      DESCRIPTION: "URL generada",
    },
    ProfileButton: {
      BUTTON_NAME: "Perfil",
    },
    Footer: {
      BUTTON_NAME: "Perfil",
    },
    NagiosStatusBox: {
      TITLE: "Estado de Nagios",
    },
    StatusContent: {
      BUTTON_NAME: "Claro sin manejar",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "¡Aún no estás monitoreando ningún host!",
      NOT_MONITORING_SERVICE_MESSAGE:
        "¡Aún no estás monitoreando ningún servicio!",
      ONCE_SETUP_HOST_MESSAGE:
        "Una vez que haya configurado un host, verá los datos aquí.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "Una vez que haya configurado un servicio, verá los datos aquí.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "Guardar panel",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "Eliminar",
      POPCONFIRM_TEXT: "¿Estás seguro de que quieres eliminar el dashlet?",
      POPCONFIRM_OK: "Okay",
      POPCONFIRM_NO: "Cancelar",
      CALL_REASON: "Eliminar Dashlet",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "Editar",
      POPCONFIRM_TEXT: "¿Está seguro de que desea editar el dashlet?",
      POPCONFIRM_OK: "Okay",
      POPCONFIRM_NO: "Cancelar",
      CALL_REASON: "Editar Dashlet",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Duplicar",
      POPCONFIRM_TEXT: "¿Está seguro de que desea duplicar el dashlet?",
      POPCONFIRM_OK: "Okay",
      POPCONFIRM_NO: "Cancelar",
      CALL_REASON: "Dashlet duplicado",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Título del tablero",
      SELECT_BACKGROUND_COLOR: "Seleccionar color de fondo",
      DASHLET_TYPE: "Tipo de tablero",
      DASHLET_URL_INPUT: "URL del tablero",
      DASHLET_DIMENSIONS: "Dimensiones del tablero",
      DASHLET_POSITION: "Posición del tablero",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE: "Ingrese el título del tablero.",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "Ingrese la URL del Dashlet.",
    },
  },
  ja: {
    LoginForm: {
      TITLE: "NagiosXIログイン",
      USER_NAME_INPUT_TITLE: "ユーザー名",
      PASSWORD_INPUT_TITLE: "パスワード",
      SUBMIT_BUTTON_NAME: "ログインする",
      FORGET_PASSWORD_BUTTON_NAME: "パスワードをお忘れですか",
      SUCESSS_MESSAGE: "成功",
      PASSWORD_INPUT_ERROR_MESSAGE: "パスワードを入力してください！",
      USER_NAME_INPUT_ERROR_MESSAGE: "ユーザー名を入力してください！",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "キャンセル",
      DEPLOY_BUTTON_NAME: "デプロイ",
      DEPLOY_TITLE: "デプロイ-",
      MASS_DEPLOY_TITLE: "大量展開",
      NO_SELECTED_DASHBOARDS_ERROR:
        "デプロイするダッシュボードを少なくとも1つ選択してください。",
      NO_SELECTED_USERS_ERROR:
        "デプロイするユーザーを少なくとも1人選択してください。",
    },
    AllDashboardsCard: {
      CARD_TITLE: "すべてのダッシュボード",
    },
    DashboardsPage: {
      TITLE: "ダッシュボードページ",
      DESCRIPTION:
        "簡単なステータスの概要、セットアップ、および管理タスクの実行。",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "ダッシュボード名で検索",
    },
    DashboardTemplateCard: {
      TITLE: "ダッシュボードテンプレート",
      DESCRIPTION_ONE:
        "ダッシュボードテンプレートファイルを共有して、ダッシュボードのアイデアをNagiosXIユーザーと交換します。",
      DESCRIPTION_TWO:
        "Nagios XI JSONファイルをインポートまたはエクスポートして、ダッシュボードに瞬時のパワーを追加します。",
    },
    DashboardTemplateImport: {
      TITLE: "インポート",
      DESCRIPTION:
        "共有されているダッシュボードテンプレート（JSON）をアップロードすると、「すべてのダッシュボード」リストに表示されます。",
      SELECT_FILE_LABEL: "ファイルを選択",
      SELECT_FILE_ERROR: "インポートするファイルを選択してください！",
      BROWSE_BUTTON_NAME: "ブラウズ",
      IMPORT_BUTTON_NAME: "インポート",
      INVALID_FILE_ERROR_TITLE: "無効なファイルタイプ",
      INVALID_FILE_ERROR_MESSAGE:
        "テンプレートファイルはJSON形式である必要があります！",
    },
    DashboardTemplateExport: {
      TITLE: "書き出す",
      DESCRIPTION:
        "共有されているダッシュボードテンプレート（JSON）をアップロードすると、「すべてのダッシュボード」リストに表示されます。",
      SELECt_TEMPLATE_LABEL: "テンプレートを選択",
      SELECt_TEMPLATE_ERROR_MESSAGE:
        "エクスポートするテンプレートを選択してください！",
      EXPORT_BUTTON_NAME: "書き出す",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "削除",
      POPCONFIRM_TEXT: "ダッシュボードを削除してもよろしいですか",
      POPCONFIRM_OK: "OK",
      POPCONFIRM_NO: "キャンセル",
      CALL_REASON: "ダッシュボードを削除する",
    },
    CopyButton: {
      TOOLTIP_TEXT: "複製",
      POPCONFIRM_TEXT: "ダッシュボードを複製してもよろしいですか",
      POPCONFIRM_OK: "OK",
      POPCONFIRM_NO: "キャンセル",
      CALL_REASON: "ダッシュボードの複製",
    },
    EditButton: {
      TOOLTIP_TEXT: "編集",
    },
    CreateDashboardCard: {
      CARD_TITLE: "ダッシュボードを作成する",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "ダッシュボードのタイトル",
      DASHBOARD_INPUT_ERROR_MESSAGE:
        "ダッシュボードのタイトルを入力してください！",
      SELECT_BACKGROUND_COLOR: "背景色を選択",
      PUBLIC_SHARE_CHECKBOX: "パブリック/共有可能",
      ALLOW_EDITING_CHECKBOX: "編集を許可する",
      DASHLETS: "ダッシュレット",
    },
    FavoritesList: {
      BUTTON_NAME: "お気に入りの編集",
    },
    FavoritesButton: {
      BUTTON_NAME: "お気に入り",
    },
    ShareButton: {
      BUTTON_NAME: "シェア",
      TOOLTIP_TEXT: "シェア",
      NOTIFICATION_TEXT1: "クリップボードにコピー",
      NOTIFICATION_TEXT2: "ダッシュボードURL",
      DESCRIPTION: "生成されたURL",
    },
    ProfileButton: {
      BUTTON_NAME: "プロフィール",
    },
    Footer: {
      BUTTON_NAME: "プロフィール",
    },
    NagiosStatusBox: {
      TITLE: "Nagiosステータス",
    },
    StatusContent: {
      BUTTON_NAME: "未処理をクリア",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "まだホストを監視していません！",
      NOT_MONITORING_SERVICE_MESSAGE: "まだサービスを監視していません！",
      ONCE_SETUP_HOST_MESSAGE:
        "ホストを設定すると、ここにデータが表示されます。",
      ONCE_SETUP_SERVICE_MESSAGE:
        "サービスを設定すると、ここにデータが表示されます。",
    },
    DashletSaveButton: {
      BUTTON_NAME: "ダッシュボードを保存",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "削除",
      POPCONFIRM_TEXT: "ダッシュレットを削除してもよろしいですか",
      POPCONFIRM_OK: "OK",
      POPCONFIRM_NO: "キャンセル",
      CALL_REASON: "ダッシュレットを削除する",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "編集",
      POPCONFIRM_TEXT: "ダッシュレットを編集してもよろしいですか",
      POPCONFIRM_OK: "OK",
      POPCONFIRM_NO: "キャンセル",
      CALL_REASON: "ダッシュレットを編集する",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "複製",
      POPCONFIRM_TEXT: "ダッシュレットを複製してもよろしいですか",
      POPCONFIRM_OK: "OK",
      POPCONFIRM_NO: "キャンセル",
      CALL_REASON: "重複したダッシュレット",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "ダッシュレットタイトル",
      SELECT_BACKGROUND_COLOR: "背景色を選択",
      DASHLET_TYPE: "ダッシュレットタイプ",
      DASHLET_URL_INPUT: "ダッシュレットURL",
      DASHLET_DIMENSIONS: "ダッシュレットの寸法",
      DASHLET_POSITION: "ダッシュレットの位置",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE:
        "ダッシュレットのタイトルを入力してください！",
      DASHLET_INPUT_URL_ERROR_MESSAGE:
        "ダッシュレットのURLを入力してください！",
    },
  },
  th: {
    LoginForm: {
      TITLE: "Nagios XI เข้าสู่ระบบ",
      USER_NAME_INPUT_TITLE: "ชื่อผู้ใช้",
      PASSWORD_INPUT_TITLE: "รหัสผ่าน",
      SUBMIT_BUTTON_NAME: "เข้าสู่ระบบ",
      FORGET_PASSWORD_BUTTON_NAME: "ลืมรหัสผ่าน",
      SUCESSS_MESSAGE: "ความสำเร็จ",
      PASSWORD_INPUT_ERROR_MESSAGE: "กรุณาใส่รหัสผ่านของคุณ!",
      USER_NAME_INPUT_ERROR_MESSAGE: "กรุณาใส่ชื่อผู้ใช้ของคุณ!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "ยกเลิก",
      DEPLOY_BUTTON_NAME: "ปรับใช้",
      DEPLOY_TITLE: "ปรับใช้ -",
      MASS_DEPLOY_TITLE: "การปรับใช้จำนวนมาก",
      NO_SELECTED_DASHBOARDS_ERROR:
        "โปรดเลือกอย่างน้อยหนึ่งแดชบอร์ดเพื่อปรับใช้!",
      NO_SELECTED_USERS_ERROR: "โปรดเลือกผู้ใช้อย่างน้อยหนึ่งคนเพื่อปรับใช้!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "แดชบอร์ดทั้งหมด",
    },
    DashboardsPage: {
      TITLE: "หน้าแดชบอร์ด",
      DESCRIPTION: "สรุปสถานะอย่างรวดเร็วและการตั้งค่าและการเริ่มงานผู้ดูแล",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "ค้นหาตามชื่อแดชบอร์ด",
    },
    DashboardTemplateCard: {
      TITLE: "เทมเพลตแดชบอร์ด",
      DESCRIPTION_ONE:
        "แลกเปลี่ยนแนวคิดของคุณสำหรับแดชบอร์ดกับผู้ใช้ Nagios XI โดยแชร์ไฟล์เทมเพลตแดชบอร์ด",
      DESCRIPTION_TWO:
        "นำเข้าหรือส่งออกไฟล์ Nagios XI JSON และเพิ่มพลังให้กับแดชบอร์ดของคุณได้ทันที",
    },
    DashboardTemplateImport: {
      TITLE: "นำเข้า",
      DESCRIPTION:
        "อัปโหลดเทมเพลตแดชบอร์ด (JSON) ที่แชร์กับคุณและจะปรากฏในรายการ &quot;แดชบอร์ดทั้งหมด&quot; ของคุณ",
      SELECT_FILE_LABEL: "เลือกไฟล์",
      SELECT_FILE_ERROR: "โปรดเลือกไฟล์ที่จะนำเข้า!",
      BROWSE_BUTTON_NAME: "เรียกดู",
      IMPORT_BUTTON_NAME: "นำเข้า",
      INVALID_FILE_ERROR_TITLE: "ประเภทไฟล์ไม่ถูกต้อง",
      INVALID_FILE_ERROR_MESSAGE: "ไฟล์เทมเพลตต้องอยู่ในรูปแบบ JSON!",
    },
    DashboardTemplateExport: {
      TITLE: "ส่งออก",
      DESCRIPTION:
        "อัปโหลดเทมเพลตแดชบอร์ด (JSON) ที่แชร์กับคุณและจะปรากฏในรายการ &quot;แดชบอร์ดทั้งหมด&quot; ของคุณ",
      SELECt_TEMPLATE_LABEL: "เลือกเทมเพลต",
      SELECt_TEMPLATE_ERROR_MESSAGE: "โปรดเลือกเทมเพลตเพื่อส่งออก!",
      EXPORT_BUTTON_NAME: "ส่งออก",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "ลบ",
      POPCONFIRM_TEXT: "แน่ใจไหมว่าต้องการลบแดชบอร์ด",
      POPCONFIRM_OK: "ตกลง",
      POPCONFIRM_NO: "ยกเลิก",
      CALL_REASON: "ลบแดชบอร์ด",
    },
    CopyButton: {
      TOOLTIP_TEXT: "ทำซ้ำ",
      POPCONFIRM_TEXT: "แน่ใจไหมว่าต้องการทำซ้ำแดชบอร์ด",
      POPCONFIRM_OK: "ตกลง",
      POPCONFIRM_NO: "ยกเลิก",
      CALL_REASON: "แดชบอร์ดที่ซ้ำกัน",
    },
    EditButton: {
      TOOLTIP_TEXT: "แก้ไข",
    },
    CreateDashboardCard: {
      CARD_TITLE: "สร้างแดชบอร์ด",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "ชื่อแดชบอร์ด",
      DASHBOARD_INPUT_ERROR_MESSAGE: "กรุณาใส่ชื่อแดชบอร์ด!",
      SELECT_BACKGROUND_COLOR: "เลือกสีพื้นหลัง",
      PUBLIC_SHARE_CHECKBOX: "สาธารณะ / แชร์ได้",
      ALLOW_EDITING_CHECKBOX: "อนุญาตให้แก้ไข",
      DASHLETS: "แดชเล็ต",
    },
    FavoritesList: {
      BUTTON_NAME: "แก้ไขรายการโปรด",
    },
    FavoritesButton: {
      BUTTON_NAME: "รายการโปรด",
    },
    ShareButton: {
      BUTTON_NAME: "แบ่งปัน",
      TOOLTIP_TEXT: "แบ่งปัน",
      NOTIFICATION_TEXT1: "คัดลอกไปยังคลิปบอร์ด",
      NOTIFICATION_TEXT2: "URL แดชบอร์ด",
      DESCRIPTION: "URL ที่สร้างขึ้น",
    },
    ProfileButton: {
      BUTTON_NAME: "ข้อมูลส่วนตัว",
    },
    Footer: {
      BUTTON_NAME: "ข้อมูลส่วนตัว",
    },
    NagiosStatusBox: {
      TITLE: "สถานะ Nagios",
    },
    StatusContent: {
      BUTTON_NAME: "ล้างไม่ได้จัดการ",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "คุณยังไม่ได้ตรวจสอบโฮสต์ใด ๆ !",
      NOT_MONITORING_SERVICE_MESSAGE: "คุณยังไม่ได้ตรวจสอบบริการใด ๆ !",
      ONCE_SETUP_HOST_MESSAGE: "เมื่อคุณตั้งค่าโฮสต์แล้วคุณจะเห็นข้อมูลที่นี่",
      ONCE_SETUP_SERVICE_MESSAGE:
        "เมื่อคุณตั้งค่าบริการแล้วคุณจะเห็นข้อมูลที่นี่",
    },
    DashletSaveButton: {
      BUTTON_NAME: "บันทึกแดชบอร์ด",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "ลบ",
      POPCONFIRM_TEXT: "แน่ใจไหมว่าต้องการลบแดชเล็ต",
      POPCONFIRM_OK: "ตกลง",
      POPCONFIRM_NO: "ยกเลิก",
      CALL_REASON: "ลบ Dashlet",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "แก้ไข",
      POPCONFIRM_TEXT: "แน่ใจไหมว่าต้องการแก้ไขแดชเล็ต",
      POPCONFIRM_OK: "ตกลง",
      POPCONFIRM_NO: "ยกเลิก",
      CALL_REASON: "แก้ไข Dashlet",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "ทำซ้ำ",
      POPCONFIRM_TEXT: "แน่ใจไหมว่าต้องการทำสำเนาแดชเล็ต",
      POPCONFIRM_OK: "ตกลง",
      POPCONFIRM_NO: "ยกเลิก",
      CALL_REASON: "Duplicate Dashlet",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "ชื่อ Dashlet",
      SELECT_BACKGROUND_COLOR: "เลือกสีพื้นหลัง",
      DASHLET_TYPE: "ประเภท Dashlet",
      DASHLET_URL_INPUT: "Dashlet URL",
      DASHLET_DIMENSIONS: "ขนาดแดชเล็ต",
      DASHLET_POSITION: "ตำแหน่งแดชเล็ต",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE: "กรุณาใส่ชื่อ Dashlet!",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "กรุณาใส่ URL ของ Dashlet!",
    },
  },
  vi: {
    LoginForm: {
      TITLE: "Nagios XI Đăng nhập",
      USER_NAME_INPUT_TITLE: "Tên tài khoản",
      PASSWORD_INPUT_TITLE: "Mật khẩu",
      SUBMIT_BUTTON_NAME: "Đăng nhập",
      FORGET_PASSWORD_BUTTON_NAME: "Quên mật khẩu",
      SUCESSS_MESSAGE: "Sucesss",
      PASSWORD_INPUT_ERROR_MESSAGE: "Vui lòng nhập mật khẩu của bạn!",
      USER_NAME_INPUT_ERROR_MESSAGE: "Vui lòng nhập tên người dùng của bạn!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "Huỷ bỏ",
      DEPLOY_BUTTON_NAME: "Triển khai",
      DEPLOY_TITLE: "Triển khai -",
      MASS_DEPLOY_TITLE: "Triển khai hàng loạt",
      NO_SELECTED_DASHBOARDS_ERROR:
        "Vui lòng chọn ít nhất một trang tổng quan để triển khai!",
      NO_SELECTED_USERS_ERROR:
        "Vui lòng chọn ít nhất một người dùng để triển khai!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "Tất cả các trang tổng quan",
    },
    DashboardsPage: {
      TITLE: "Trang tổng quan",
      DESCRIPTION:
        "Tóm tắt trạng thái nhanh, thiết lập và thực hiện các tác vụ quản trị.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "Tìm kiếm theo tên Trang tổng quan",
    },
    DashboardTemplateCard: {
      TITLE: "Mẫu bảng điều khiển",
      DESCRIPTION_ONE:
        "Trao đổi ý tưởng của bạn về trang tổng quan với bất kỳ người dùng Nagios XI nào bằng cách chia sẻ tệp mẫu trang tổng quan.",
      DESCRIPTION_TWO:
        "Nhập hoặc xuất các tệp Nagios XI JSON và thêm sức mạnh tức thì vào trang tổng quan của bạn.",
    },
    DashboardTemplateImport: {
      TITLE: "Nhập khẩu",
      DESCRIPTION:
        "Tải lên Mẫu trang tổng quan (JSON) đã được chia sẻ với bạn và nó sẽ hiển thị trong danh sách “Tất cả trang tổng quan” của bạn.",
      SELECT_FILE_LABEL: "Chọn tập tin",
      SELECT_FILE_ERROR: "Vui lòng chọn tệp để nhập!",
      BROWSE_BUTTON_NAME: "Duyệt qua",
      IMPORT_BUTTON_NAME: "Nhập khẩu",
      INVALID_FILE_ERROR_TITLE: "Loại tệp không hợp lệ",
      INVALID_FILE_ERROR_MESSAGE: "Tệp mẫu phải ở định dạng JSON!",
    },
    DashboardTemplateExport: {
      TITLE: "Xuất khẩu",
      DESCRIPTION:
        "Tải lên Mẫu trang tổng quan (JSON) đã được chia sẻ với bạn và nó sẽ hiển thị trong danh sách “Tất cả trang tổng quan” của bạn.",
      SELECt_TEMPLATE_LABEL: "Chọn mẫu",
      SELECt_TEMPLATE_ERROR_MESSAGE: "Vui lòng chọn mẫu để xuất!",
      EXPORT_BUTTON_NAME: "Xuất khẩu",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "Xóa bỏ",
      POPCONFIRM_TEXT: "Bạn có chắc chắn muốn xóa trang tổng quan không",
      POPCONFIRM_OK: "Đồng ý",
      POPCONFIRM_NO: "Huỷ bỏ",
      CALL_REASON: "Xóa trang tổng quan",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Bản sao",
      POPCONFIRM_TEXT: "Bạn có chắc chắn muốn sao chép trang tổng quan không",
      POPCONFIRM_OK: "Đồng ý",
      POPCONFIRM_NO: "Huỷ bỏ",
      CALL_REASON: "Trang tổng quan trùng lặp",
    },
    EditButton: {
      TOOLTIP_TEXT: "Biên tập",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Tạo Trang tổng quan",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Tiêu đề trang tổng quan",
      DASHBOARD_INPUT_ERROR_MESSAGE: "Vui lòng nhập Tiêu đề trang tổng quan!",
      SELECT_BACKGROUND_COLOR: "Chọn màu nền",
      PUBLIC_SHARE_CHECKBOX: "Công khai / Có thể chia sẻ",
      ALLOW_EDITING_CHECKBOX: "Cho phép chỉnh sửa",
      DASHLETS: "Dashlets",
    },
    FavoritesList: {
      BUTTON_NAME: "Chỉnh sửa mục yêu thích",
    },
    FavoritesButton: {
      BUTTON_NAME: "Yêu thích",
    },
    ShareButton: {
      BUTTON_NAME: "Chia sẻ",
      TOOLTIP_TEXT: "Chia sẻ",
      NOTIFICATION_TEXT1: "Đã sao chép vào khay nhớ tạm",
      NOTIFICATION_TEXT2: "URL bảng điều khiển",
      DESCRIPTION: "URL được tạo",
    },
    ProfileButton: {
      BUTTON_NAME: "Hồ sơ",
    },
    Footer: {
      BUTTON_NAME: "Hồ sơ",
    },
    NagiosStatusBox: {
      TITLE: "Trạng thái Nagios",
    },
    StatusContent: {
      BUTTON_NAME: "Xóa Không xử lý",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "Bạn chưa theo dõi bất kỳ máy chủ nào!",
      NOT_MONITORING_SERVICE_MESSAGE: "Bạn chưa theo dõi bất kỳ dịch vụ nào!",
      ONCE_SETUP_HOST_MESSAGE:
        "Khi bạn đã thiết lập máy chủ, bạn sẽ thấy dữ liệu ở đây.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "Sau khi thiết lập dịch vụ, bạn sẽ thấy dữ liệu ở đây.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "Lưu trang tổng quan",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "Xóa bỏ",
      POPCONFIRM_TEXT: "Bạn có chắc chắn muốn xóa dashlet không",
      POPCONFIRM_OK: "Đồng ý",
      POPCONFIRM_NO: "Huỷ bỏ",
      CALL_REASON: "Xóa Dashlet",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "Biên tập",
      POPCONFIRM_TEXT: "Bạn có chắc chắn muốn chỉnh sửa trang tổng quan không",
      POPCONFIRM_OK: "Đồng ý",
      POPCONFIRM_NO: "Huỷ bỏ",
      CALL_REASON: "Chỉnh sửa Dashlet",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Bản sao",
      POPCONFIRM_TEXT: "Bạn có chắc chắn muốn nhân bản dashlet không",
      POPCONFIRM_OK: "Đồng ý",
      POPCONFIRM_NO: "Huỷ bỏ",
      CALL_REASON: "Trang tổng quan trùng lặp",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Tiêu đề trang tổng quan",
      SELECT_BACKGROUND_COLOR: "Chọn màu nền",
      DASHLET_TYPE: "Loại bảng điều khiển",
      DASHLET_URL_INPUT: "URL bảng điều khiển",
      DASHLET_DIMENSIONS: "Kích thước bảng điều khiển",
      DASHLET_POSITION: "Vị trí bảng điều khiển",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE: "Vui lòng nhập Tiêu đề Dashlet!",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "Vui lòng nhập URL bảng điều khiển!",
    },
  },
  "zh-TW": {
    LoginForm: {
      TITLE: "Nagios XI登錄",
      USER_NAME_INPUT_TITLE: "用戶名",
      PASSWORD_INPUT_TITLE: "密碼",
      SUBMIT_BUTTON_NAME: "登錄",
      FORGET_PASSWORD_BUTTON_NAME: "忘記密碼",
      SUCESSS_MESSAGE: "成功",
      PASSWORD_INPUT_ERROR_MESSAGE: "請輸入您的密碼！",
      USER_NAME_INPUT_ERROR_MESSAGE: "請輸入您的用戶名！",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "取消",
      DEPLOY_BUTTON_NAME: "部署",
      DEPLOY_TITLE: "部署-",
      MASS_DEPLOY_TITLE: "大規模部署",
      NO_SELECTED_DASHBOARDS_ERROR: "請至少選擇一個儀表板進行部署！",
      NO_SELECTED_USERS_ERROR: "請選擇至少一個要部署的用戶！",
    },
    AllDashboardsCard: {
      CARD_TITLE: "所有儀表板",
    },
    DashboardsPage: {
      TITLE: "儀表板頁面",
      DESCRIPTION: "快速狀態摘要，設置和正在進行的管理任務。",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "按儀表板名稱搜索",
    },
    DashboardTemplateCard: {
      TITLE: "儀表板模板",
      DESCRIPTION_ONE:
        "通過共享儀表板模板文件，與任何Nagios XI用戶交換有關儀表板的想法。",
      DESCRIPTION_TWO:
        "導入或導出Nagios XI JSON文件，並為您的儀表板增加即時功能。",
    },
    DashboardTemplateImport: {
      TITLE: "進口",
      DESCRIPTION:
        "上載與您共享的儀表板模板（JSON），它將顯示在“所有儀表板”列表中。",
      SELECT_FILE_LABEL: "選擇文件",
      SELECT_FILE_ERROR: "請選擇要導入的文件！",
      BROWSE_BUTTON_NAME: "瀏覽",
      IMPORT_BUTTON_NAME: "進口",
      INVALID_FILE_ERROR_TITLE: "文件類型無效",
      INVALID_FILE_ERROR_MESSAGE: "模板文件必須為JSON格式！",
    },
    DashboardTemplateExport: {
      TITLE: "出口",
      DESCRIPTION:
        "上載與您共享的儀表板模板（JSON），它將顯示在“所有儀表板”列表中。",
      SELECt_TEMPLATE_LABEL: "選擇模板",
      SELECt_TEMPLATE_ERROR_MESSAGE: "請選擇要導出的模板！",
      EXPORT_BUTTON_NAME: "出口",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "刪除",
      POPCONFIRM_TEXT: "您確定要刪除儀表板嗎",
      POPCONFIRM_OK: "好",
      POPCONFIRM_NO: "取消",
      CALL_REASON: "刪除儀表板",
    },
    CopyButton: {
      TOOLTIP_TEXT: "重複",
      POPCONFIRM_TEXT: "您確定要復制儀表板嗎",
      POPCONFIRM_OK: "好",
      POPCONFIRM_NO: "取消",
      CALL_REASON: "儀表板重複",
    },
    EditButton: {
      TOOLTIP_TEXT: "編輯",
    },
    CreateDashboardCard: {
      CARD_TITLE: "創建儀表板",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "儀表板標題",
      DASHBOARD_INPUT_ERROR_MESSAGE: "請輸入儀表板標題！",
      SELECT_BACKGROUND_COLOR: "選擇背景色",
      PUBLIC_SHARE_CHECKBOX: "公開/共享",
      ALLOW_EDITING_CHECKBOX: "允許編輯",
      DASHLETS: "儀錶盤",
    },
    FavoritesList: {
      BUTTON_NAME: "編輯收藏夾",
    },
    FavoritesButton: {
      BUTTON_NAME: "收藏夾",
    },
    ShareButton: {
      BUTTON_NAME: "分享",
      TOOLTIP_TEXT: "分享",
      NOTIFICATION_TEXT1: "複製到剪貼板",
      NOTIFICATION_TEXT2: "儀表板URL",
      DESCRIPTION: "生成的URL",
    },
    ProfileButton: {
      BUTTON_NAME: "個人資料",
    },
    Footer: {
      BUTTON_NAME: "個人資料",
    },
    NagiosStatusBox: {
      TITLE: "Nagios狀態",
    },
    StatusContent: {
      BUTTON_NAME: "清除未處理",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "您尚未監視任何主機！",
      NOT_MONITORING_SERVICE_MESSAGE: "您尚未監視任何服務！",
      ONCE_SETUP_HOST_MESSAGE: "設置主機後，您將在此處看到數據。",
      ONCE_SETUP_SERVICE_MESSAGE: "設置服務後，您將在此處看到數據。",
    },
    DashletSaveButton: {
      BUTTON_NAME: "保存儀錶盤",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "刪除",
      POPCONFIRM_TEXT: "您確定要刪除破折號嗎",
      POPCONFIRM_OK: "好",
      POPCONFIRM_NO: "取消",
      CALL_REASON: "刪除儀錶盤",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "編輯",
      POPCONFIRM_TEXT: "您確定要編輯dashlet",
      POPCONFIRM_OK: "好",
      POPCONFIRM_NO: "取消",
      CALL_REASON: "編輯儀表板",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "重複",
      POPCONFIRM_TEXT: "您確定要復制破折號嗎",
      POPCONFIRM_OK: "好",
      POPCONFIRM_NO: "取消",
      CALL_REASON: "重複的儀表板",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "儀錶盤標題",
      SELECT_BACKGROUND_COLOR: "選擇背景色",
      DASHLET_TYPE: "儀錶盤類型",
      DASHLET_URL_INPUT: "Dashlet URL",
      DASHLET_DIMENSIONS: "儀錶盤尺寸",
      DASHLET_POSITION: "儀錶盤位置",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE: "請輸入儀錶盤標題！",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "請輸入Dashlet URL！",
    },
  },
  no: {
    LoginForm: {
      TITLE: "Nagios XI Logg inn",
      USER_NAME_INPUT_TITLE: "Brukernavn",
      PASSWORD_INPUT_TITLE: "Passord",
      SUBMIT_BUTTON_NAME: "Logg Inn",
      FORGET_PASSWORD_BUTTON_NAME: "Glemt passord",
      SUCESSS_MESSAGE: "Lykkes",
      PASSWORD_INPUT_ERROR_MESSAGE: "Vennligst skriv inn passordet ditt!",
      USER_NAME_INPUT_ERROR_MESSAGE: "Vennligst skriv inn brukernavnet ditt!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "Avbryt",
      DEPLOY_BUTTON_NAME: "Utplassere",
      DEPLOY_TITLE: "Implementere -",
      MASS_DEPLOY_TITLE: "Massedistribusjon",
      NO_SELECTED_DASHBOARDS_ERROR:
        "Velg minst ett instrumentbord for å distribuere!",
      NO_SELECTED_USERS_ERROR: "Velg minst én bruker å distribuere til!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "Alle dashbord",
    },
    DashboardsPage: {
      TITLE: "Dashboards Side",
      DESCRIPTION:
        "Et raskt statusoppsummering, oppsett og administrerende oppgaver.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "Søk etter dashbordnavn",
    },
    DashboardTemplateCard: {
      TITLE: "Dashboard-maler",
      DESCRIPTION_ONE:
        "Utveksle ideene dine til dashbord med hvilken som helst Nagios XI-bruker ved å dele dashboardmalfiler.",
      DESCRIPTION_TWO:
        "Importer eller eksporter Nagios XI JSON-filer og legg øyeblikkelig strøm til dashbordene dine.",
    },
    DashboardTemplateImport: {
      TITLE: "Import",
      DESCRIPTION:
        "Last opp en Dashboard Template (JSON) som er delt med deg, og den vil vises i listen &quot;Alle dashboards&quot;.",
      SELECT_FILE_LABEL: "Velg Fil",
      SELECT_FILE_ERROR: "Vennligst velg filen du vil importere!",
      BROWSE_BUTTON_NAME: "Bla gjennom",
      IMPORT_BUTTON_NAME: "Import",
      INVALID_FILE_ERROR_TITLE: "Ugyldig filtype",
      INVALID_FILE_ERROR_MESSAGE: "Malfilen må være i JSON-format!",
    },
    DashboardTemplateExport: {
      TITLE: "Eksport",
      DESCRIPTION:
        "Last opp en Dashboard Template (JSON) som er delt med deg, og den vil vises i listen &quot;Alle dashboards&quot;.",
      SELECt_TEMPLATE_LABEL: "Velg Mal",
      SELECt_TEMPLATE_ERROR_MESSAGE: "Velg mal for å eksportere!",
      EXPORT_BUTTON_NAME: "Eksport",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "Slett",
      POPCONFIRM_TEXT: "Er du sikker på at du vil slette dashbordet",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Avbryt",
      CALL_REASON: "Slett Dashboard",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Duplisere",
      POPCONFIRM_TEXT: "Er du sikker på at du vil duplisere dashbordet",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Avbryt",
      CALL_REASON: "Dupliser Dashboard",
    },
    EditButton: {
      TOOLTIP_TEXT: "Redigere",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Lag et dashbord",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Dashboard Tittel",
      DASHBOARD_INPUT_ERROR_MESSAGE: "Vennligst skriv inn Dashboard Tittel!",
      SELECT_BACKGROUND_COLOR: "Velg Bakgrunnsfarge",
      PUBLIC_SHARE_CHECKBOX: "Offentlig / delbar",
      ALLOW_EDITING_CHECKBOX: "Tillat redigering",
      DASHLETS: "Dashlets",
    },
    FavoritesList: {
      BUTTON_NAME: "Rediger favoritter",
    },
    FavoritesButton: {
      BUTTON_NAME: "Favoritter",
    },
    ShareButton: {
      BUTTON_NAME: "Dele",
      TOOLTIP_TEXT: "Dele",
      NOTIFICATION_TEXT1: "Kopiert til utklippstavlen",
      NOTIFICATION_TEXT2: "dashbord-URL",
      DESCRIPTION: "Generert URL",
    },
    ProfileButton: {
      BUTTON_NAME: "Profil",
    },
    Footer: {
      BUTTON_NAME: "Profil",
    },
    NagiosStatusBox: {
      TITLE: "Nagios Status",
    },
    StatusContent: {
      BUTTON_NAME: "Fjern ubehandlet",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "Du overvåker ikke noen verter ennå!",
      NOT_MONITORING_SERVICE_MESSAGE: "Du overvåker ingen tjenester ennå!",
      ONCE_SETUP_HOST_MESSAGE:
        "Når du har konfigurert en vert, vil du se data her.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "Når du har konfigurert en tjeneste, vil du se data her.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "Lagre Dashboard",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "Slett",
      POPCONFIRM_TEXT: "Er du sikker på at du vil slette dashlet",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Avbryt",
      CALL_REASON: "Slett Dashlet",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "Redigere",
      POPCONFIRM_TEXT: "Er du sikker på at du vil redigere dashlet",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Avbryt",
      CALL_REASON: "Rediger Dashlet",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Duplisere",
      POPCONFIRM_TEXT: "Er du sikker på at du vil duplisere dashlet",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Avbryt",
      CALL_REASON: "Dupliser Dashlet",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Dashlet-tittel",
      SELECT_BACKGROUND_COLOR: "Velg Bakgrunnsfarge",
      DASHLET_TYPE: "Dashlet Type",
      DASHLET_URL_INPUT: "Dashlet URL",
      DASHLET_DIMENSIONS: "Dashlet Dimensjoner",
      DASHLET_POSITION: "Dashletposisjon",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE:
        "Vennligst skriv inn Dashlet-tittelen!",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "Vennligst skriv inn Dashlet URL!",
    },
  },
  pl: {
    LoginForm: {
      TITLE: "Nagios XI Zaloguj się",
      USER_NAME_INPUT_TITLE: "Nazwa Użytkownika",
      PASSWORD_INPUT_TITLE: "Hasło",
      SUBMIT_BUTTON_NAME: "Zaloguj sie",
      FORGET_PASSWORD_BUTTON_NAME: "Zapomniałeś hasła",
      SUCESSS_MESSAGE: "Sukcesy",
      PASSWORD_INPUT_ERROR_MESSAGE: "Wprowadź swoje hasło!",
      USER_NAME_INPUT_ERROR_MESSAGE: "Wprowadź swoją nazwę użytkownika!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "Anuluj",
      DEPLOY_BUTTON_NAME: "Rozmieścić",
      DEPLOY_TITLE: "Rozmieścić -",
      MASS_DEPLOY_TITLE: "Masowe wdrażanie",
      NO_SELECTED_DASHBOARDS_ERROR:
        "Wybierz co najmniej jeden pulpit do wdrożenia!",
      NO_SELECTED_USERS_ERROR:
        "Wybierz co najmniej jednego użytkownika do wdrożenia!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "Wszystkie pulpity nawigacyjne",
    },
    DashboardsPage: {
      TITLE: "Strona pulpitów nawigacyjnych",
      DESCRIPTION:
        "Szybkie podsumowanie stanu, konfiguracja i bieżące zadania administracyjne.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "Szukaj według nazwy panelu",
    },
    DashboardTemplateCard: {
      TITLE: "Szablony pulpitu nawigacyjnego",
      DESCRIPTION_ONE:
        "Wymieniaj się pomysłami dotyczącymi pulpitów nawigacyjnych z dowolnym użytkownikiem Nagios XI, udostępniając pliki szablonów pulpitów nawigacyjnych.",
      DESCRIPTION_TWO:
        "Importuj lub eksportuj pliki JSON Nagios XI i dodawaj natychmiastową moc do swoich pulpitów nawigacyjnych.",
    },
    DashboardTemplateImport: {
      TITLE: "Import",
      DESCRIPTION:
        "Prześlij szablon pulpitu nawigacyjnego (JSON), który został Ci udostępniony, a pojawi się on na liście „Wszystkie pulpity”.",
      SELECT_FILE_LABEL: "Wybierz plik",
      SELECT_FILE_ERROR: "Wybierz plik do zaimportowania!",
      BROWSE_BUTTON_NAME: "Przeglądaj",
      IMPORT_BUTTON_NAME: "Import",
      INVALID_FILE_ERROR_TITLE: "Nieprawidłowy typ pliku",
      INVALID_FILE_ERROR_MESSAGE: "Plik szablonu musi być w formacie JSON!",
    },
    DashboardTemplateExport: {
      TITLE: "Eksport",
      DESCRIPTION:
        "Prześlij szablon pulpitu nawigacyjnego (JSON), który został Ci udostępniony, a pojawi się on na liście „Wszystkie pulpity”.",
      SELECt_TEMPLATE_LABEL: "Wybierz szablon",
      SELECt_TEMPLATE_ERROR_MESSAGE: "Wybierz szablon do eksportu!",
      EXPORT_BUTTON_NAME: "Eksport",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "Usunąć",
      POPCONFIRM_TEXT: "Czy na pewno chcesz usunąć pulpit nawigacyjny",
      POPCONFIRM_OK: "Dobrze",
      POPCONFIRM_NO: "Anuluj",
      CALL_REASON: "Usuń pulpit nawigacyjny",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Duplikować",
      POPCONFIRM_TEXT: "Czy na pewno chcesz zduplikować pulpit nawigacyjny",
      POPCONFIRM_OK: "Dobrze",
      POPCONFIRM_NO: "Anuluj",
      CALL_REASON: "Zduplikowany pulpit nawigacyjny",
    },
    EditButton: {
      TOOLTIP_TEXT: "Edytować",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Utwórz pulpit nawigacyjny",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Tytuł pulpitu nawigacyjnego",
      DASHBOARD_INPUT_ERROR_MESSAGE: "Wprowadź tytuł pulpitu!",
      SELECT_BACKGROUND_COLOR: "Wybierz kolor tła",
      PUBLIC_SHARE_CHECKBOX: "Publiczne / do udostępniania",
      ALLOW_EDITING_CHECKBOX: "Zezwalaj na edycję",
      DASHLETS: "Dashlets",
    },
    FavoritesList: {
      BUTTON_NAME: "Edytować ulubione",
    },
    FavoritesButton: {
      BUTTON_NAME: "Ulubione",
    },
    ShareButton: {
      BUTTON_NAME: "Dzielić",
      TOOLTIP_TEXT: "Dzielić",
      NOTIFICATION_TEXT1: "Skopiowano do schowka",
      NOTIFICATION_TEXT2: "adres URL panelu",
      DESCRIPTION: "Wygenerowany adres URL",
    },
    ProfileButton: {
      BUTTON_NAME: "Profil",
    },
    Footer: {
      BUTTON_NAME: "Profil",
    },
    NagiosStatusBox: {
      TITLE: "Status Nagios",
    },
    StatusContent: {
      BUTTON_NAME: "Wyczyść nieobsługiwane",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "Nie monitorujesz jeszcze żadnych hostów!",
      NOT_MONITORING_SERVICE_MESSAGE: "Nie monitorujesz jeszcze żadnych usług!",
      ONCE_SETUP_HOST_MESSAGE: "Po skonfigurowaniu hosta zobaczysz tutaj dane.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "Po skonfigurowaniu usługi zobaczysz tutaj dane.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "Zapisz panel",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "Usunąć",
      POPCONFIRM_TEXT: "Czy na pewno chcesz usunąć dashlet",
      POPCONFIRM_OK: "Dobrze",
      POPCONFIRM_NO: "Anuluj",
      CALL_REASON: "Usuń Dashlet",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "Edytować",
      POPCONFIRM_TEXT: "Czy na pewno chcesz edytować dashlet",
      POPCONFIRM_OK: "Dobrze",
      POPCONFIRM_NO: "Anuluj",
      CALL_REASON: "Edytuj Dashlet",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Duplikować",
      POPCONFIRM_TEXT: "Czy na pewno chcesz zduplikować dashlet",
      POPCONFIRM_OK: "Dobrze",
      POPCONFIRM_NO: "Anuluj",
      CALL_REASON: "Duplikat Dashlet",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Tytuł Dashlet",
      SELECT_BACKGROUND_COLOR: "Wybierz kolor tła",
      DASHLET_TYPE: "Dashlet Type",
      DASHLET_URL_INPUT: "Dashlet URL",
      DASHLET_DIMENSIONS: "Wymiary Dashlet",
      DASHLET_POSITION: "Pozycja Dashleta",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE: "Wprowadź tytuł Dashlet!",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "Wprowadź adres URL Dashlet!",
    },
  },
  cs: {
    LoginForm: {
      TITLE: "Nagios XI Přihlásit se",
      USER_NAME_INPUT_TITLE: "Uživatelské jméno",
      PASSWORD_INPUT_TITLE: "Heslo",
      SUBMIT_BUTTON_NAME: "Přihlásit se",
      FORGET_PASSWORD_BUTTON_NAME: "Zapomenuté heslo",
      SUCESSS_MESSAGE: "Úspěchy",
      PASSWORD_INPUT_ERROR_MESSAGE: "Zadejte své heslo!",
      USER_NAME_INPUT_ERROR_MESSAGE: "Zadejte své uživatelské jméno!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "zrušení",
      DEPLOY_BUTTON_NAME: "Nasadit",
      DEPLOY_TITLE: "Nasadit -",
      MASS_DEPLOY_TITLE: "Hromadné nasazení",
      NO_SELECTED_DASHBOARDS_ERROR:
        "Vyberte prosím alespoň jeden řídicí panel, který chcete nasadit!",
      NO_SELECTED_USERS_ERROR:
        "Vyberte prosím alespoň jednoho uživatele k nasazení!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "Všechny řídicí panely",
    },
    DashboardsPage: {
      TITLE: "Stránka Dashboards",
      DESCRIPTION: "Stručné shrnutí stavu a nastavení a další úkoly správce.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "Hledání podle názvu řídicího panelu",
    },
    DashboardTemplateCard: {
      TITLE: "Šablony řídicích panelů",
      DESCRIPTION_ONE:
        "Vyměňte své nápady za řídicí panely s jakýmkoli uživatelem Nagios XI sdílením souborů šablon řídicích panelů.",
      DESCRIPTION_TWO:
        "Importujte nebo exportujte soubory Nagios XI JSON a přidejte okamžitý výkon svým dashboardům.",
    },
    DashboardTemplateImport: {
      TITLE: "Import",
      DESCRIPTION:
        "Nahrajte šablonu řídicího panelu (JSON), která je s vámi sdílena, a zobrazí se ve vašem seznamu „Všechny řídicí panely“.",
      SELECT_FILE_LABEL: "Zvolte soubor",
      SELECT_FILE_ERROR: "Vyberte soubor, který chcete importovat!",
      BROWSE_BUTTON_NAME: "Procházet",
      IMPORT_BUTTON_NAME: "Import",
      INVALID_FILE_ERROR_TITLE: "Neplatný typ souboru",
      INVALID_FILE_ERROR_MESSAGE: "Soubor šablony musí být ve formátu JSON!",
    },
    DashboardTemplateExport: {
      TITLE: "Vývozní",
      DESCRIPTION:
        "Nahrajte šablonu řídicího panelu (JSON), která je s vámi sdílena, a zobrazí se ve vašem seznamu „Všechny řídicí panely“.",
      SELECt_TEMPLATE_LABEL: "Vyberte šablonu",
      SELECt_TEMPLATE_ERROR_MESSAGE: "Vyberte prosím šablonu pro export!",
      EXPORT_BUTTON_NAME: "Vývozní",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "Vymazat",
      POPCONFIRM_TEXT: "Opravdu chcete odstranit řídicí panel",
      POPCONFIRM_OK: "OK",
      POPCONFIRM_NO: "zrušení",
      CALL_REASON: "Smazat panel",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Duplikát",
      POPCONFIRM_TEXT: "Opravdu chcete duplikovat řídicí panel",
      POPCONFIRM_OK: "OK",
      POPCONFIRM_NO: "zrušení",
      CALL_REASON: "Duplicitní panel",
    },
    EditButton: {
      TOOLTIP_TEXT: "Upravit",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Vytvořte řídicí panel",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Název řídicího panelu",
      DASHBOARD_INPUT_ERROR_MESSAGE: "Zadejte prosím název panelu!",
      SELECT_BACKGROUND_COLOR: "Vyberte barvu pozadí",
      PUBLIC_SHARE_CHECKBOX: "Veřejné / sdílitelné",
      ALLOW_EDITING_CHECKBOX: "Povolit úpravy",
      DASHLETS: "Dashlety",
    },
    FavoritesList: {
      BUTTON_NAME: "Upravit oblíbené",
    },
    FavoritesButton: {
      BUTTON_NAME: "Oblíbené",
    },
    ShareButton: {
      BUTTON_NAME: "Podíl",
      TOOLTIP_TEXT: "Podíl",
      NOTIFICATION_TEXT1: "Zkopírováno do schránky",
      NOTIFICATION_TEXT2: "URL řídicího panelu",
      DESCRIPTION: "Generovaná adresa URL",
    },
    ProfileButton: {
      BUTTON_NAME: "Profil",
    },
    Footer: {
      BUTTON_NAME: "Profil",
    },
    NagiosStatusBox: {
      TITLE: "Stav Nagios",
    },
    StatusContent: {
      BUTTON_NAME: "Vymazat Neošetřené",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "Ještě nesledujete žádné hostitele!",
      NOT_MONITORING_SERVICE_MESSAGE: "Ještě nesledujete žádné služby!",
      ONCE_SETUP_HOST_MESSAGE: "Jakmile nastavíte hostitele, uvidíte zde data.",
      ONCE_SETUP_SERVICE_MESSAGE: "Jakmile nastavíte službu, uvidíte zde data.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "Uložit hlavní panel",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "Vymazat",
      POPCONFIRM_TEXT: "Opravdu chcete smazat dashlet",
      POPCONFIRM_OK: "OK",
      POPCONFIRM_NO: "zrušení",
      CALL_REASON: "Odstranit Dashlet",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "Upravit",
      POPCONFIRM_TEXT: "Opravdu chcete dashlet upravit",
      POPCONFIRM_OK: "OK",
      POPCONFIRM_NO: "zrušení",
      CALL_REASON: "Upravit Dashlet",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Duplikát",
      POPCONFIRM_TEXT: "Opravdu chcete duplikovat dashlet",
      POPCONFIRM_OK: "OK",
      POPCONFIRM_NO: "zrušení",
      CALL_REASON: "Duplikovat Dashlet",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Název Dashlet",
      SELECT_BACKGROUND_COLOR: "Vyberte barvu pozadí",
      DASHLET_TYPE: "Typ Dashlet",
      DASHLET_URL_INPUT: "Dashlet URL",
      DASHLET_DIMENSIONS: "Rozměry Dashletu",
      DASHLET_POSITION: "Poloha Dashletu",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE: "Zadejte prosím název Dashlet!",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "Zadejte adresu URL Dashlet!",
    },
  },
  hu: {
    LoginForm: {
      TITLE: "Nagios XI Bejelentkezés",
      USER_NAME_INPUT_TITLE: "Felhasználónév",
      PASSWORD_INPUT_TITLE: "Jelszó",
      SUBMIT_BUTTON_NAME: "Belépés",
      FORGET_PASSWORD_BUTTON_NAME: "Elfelejtette a jelszavát",
      SUCESSS_MESSAGE: "Sikerek",
      PASSWORD_INPUT_ERROR_MESSAGE: "Kérjük, adja meg jelszavát!",
      USER_NAME_INPUT_ERROR_MESSAGE: "Kérjük, adja meg felhasználónevét!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "Megszünteti",
      DEPLOY_BUTTON_NAME: "Telepítés",
      DEPLOY_TITLE: "Telepítés -",
      MASS_DEPLOY_TITLE: "Tömeges bevetés",
      NO_SELECTED_DASHBOARDS_ERROR:
        "Kérjük, válasszon legalább egy irányítópultot a telepítéshez!",
      NO_SELECTED_USERS_ERROR:
        "Válasszon legalább egy felhasználót, akit telepíteni kíván!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "Minden irányítópult",
    },
    DashboardsPage: {
      TITLE: "Irányítópultok oldal",
      DESCRIPTION:
        "Gyors állapot-összefoglaló, beállítás és adminisztrációs feladatok.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "Keresés az irányítópult neve alapján",
    },
    DashboardTemplateCard: {
      TITLE: "Irányítópult sablonok",
      DESCRIPTION_ONE:
        "Cserélje ki ötleteit irányítópultokra bármely Nagios XI felhasználóval az irányítópult sablonfájljainak megosztásával.",
      DESCRIPTION_TWO:
        "Nagios XI JSON fájlok importálása vagy exportálása és azonnali energiaellátás az irányítópultokon.",
    },
    DashboardTemplateImport: {
      TITLE: "Importálás",
      DESCRIPTION:
        "Töltsön fel egy Önnel megosztott irányítópult-sablont (JSON), amely megjelenik az „Összes irányítópult” listában.",
      SELECT_FILE_LABEL: "Válaszd ki a fájlt",
      SELECT_FILE_ERROR: "Kérjük, válassza ki az importálandó fájlt!",
      BROWSE_BUTTON_NAME: "Tallózás",
      IMPORT_BUTTON_NAME: "Importálás",
      INVALID_FILE_ERROR_TITLE: "Érvénytelen fájltípus",
      INVALID_FILE_ERROR_MESSAGE:
        "A sablonfájlnak JSON formátumúnak kell lennie!",
    },
    DashboardTemplateExport: {
      TITLE: "Export",
      DESCRIPTION:
        "Töltsön fel egy Önnel megosztott irányítópult-sablont (JSON), amely megjelenik az „Összes irányítópult” listában.",
      SELECt_TEMPLATE_LABEL: "Válassza a Sablon lehetőséget",
      SELECt_TEMPLATE_ERROR_MESSAGE:
        "Kérjük, válassza ki az exportálandó sablont!",
      EXPORT_BUTTON_NAME: "Export",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "Töröl",
      POPCONFIRM_TEXT: "Biztosan törli az irányítópultot",
      POPCONFIRM_OK: "Rendben",
      POPCONFIRM_NO: "Megszünteti",
      CALL_REASON: "Irányítópult törlése",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Másolat",
      POPCONFIRM_TEXT: "Biztosan másolni szeretné az irányítópultot",
      POPCONFIRM_OK: "Rendben",
      POPCONFIRM_NO: "Megszünteti",
      CALL_REASON: "Irányítópult duplikálása",
    },
    EditButton: {
      TOOLTIP_TEXT: "Szerkesztés",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Hozzon létre egy Irányítópultot",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Irányítópult címe",
      DASHBOARD_INPUT_ERROR_MESSAGE: "Kérjük, adja meg az Irányítópult címet!",
      SELECT_BACKGROUND_COLOR: "Válassza a Háttérszín lehetőséget",
      PUBLIC_SHARE_CHECKBOX: "Nyilvános / megosztható",
      ALLOW_EDITING_CHECKBOX: "Szerkesztés engedélyezése",
      DASHLETS: "Dashlets",
    },
    FavoritesList: {
      BUTTON_NAME: "Kedvencek szerkesztése",
    },
    FavoritesButton: {
      BUTTON_NAME: "Kedvencek",
    },
    ShareButton: {
      BUTTON_NAME: "Ossza meg",
      TOOLTIP_TEXT: "Ossza meg",
      NOTIFICATION_TEXT1: "A vágólapra másolva",
      NOTIFICATION_TEXT2: "irányítópult URL-je",
      DESCRIPTION: "Generált URL",
    },
    ProfileButton: {
      BUTTON_NAME: "Profil",
    },
    Footer: {
      BUTTON_NAME: "Profil",
    },
    NagiosStatusBox: {
      TITLE: "Nagios állapota",
    },
    StatusContent: {
      BUTTON_NAME: "Tiszta Kezeletlen",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "Még nem figyelsz egyetlen gazdagépet sem!",
      NOT_MONITORING_SERVICE_MESSAGE:
        "Még nem figyelsz egyetlen szolgáltatást sem!",
      ONCE_SETUP_HOST_MESSAGE:
        "Miután beállította a gazdagépet, itt láthatja az adatokat.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "Miután beállította a szolgáltatást, itt láthatja az adatokat.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "Irányítópult mentése",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "Töröl",
      POPCONFIRM_TEXT: "Biztosan törli a kötőjelet",
      POPCONFIRM_OK: "Rendben",
      POPCONFIRM_NO: "Megszünteti",
      CALL_REASON: "Törölje a Dashlet elemet",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "Szerkesztés",
      POPCONFIRM_TEXT: "Biztosan szerkeszti a kötőjelet",
      POPCONFIRM_OK: "Rendben",
      POPCONFIRM_NO: "Megszünteti",
      CALL_REASON: "Dashlet szerkesztése",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Másolat",
      POPCONFIRM_TEXT: "Biztosan másolni szeretné a kötőjelet",
      POPCONFIRM_OK: "Rendben",
      POPCONFIRM_NO: "Megszünteti",
      CALL_REASON: "Másolat dashlet",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Dashlet cím",
      SELECT_BACKGROUND_COLOR: "Válassza a Háttérszín lehetőséget",
      DASHLET_TYPE: "Dashlet Type",
      DASHLET_URL_INPUT: "Dashlet URL",
      DASHLET_DIMENSIONS: "Dashlet méretek",
      DASHLET_POSITION: "Dashlet pozíció",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE: "Kérjük, írja be a műszerfal címét!",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "Kérjük, írja be a Dashlet URL-t!",
    },
  },
  ga: {
    LoginForm: {
      TITLE: "Nagios XI Logáil Isteach",
      USER_NAME_INPUT_TITLE: "Ainm Úsáideora",
      PASSWORD_INPUT_TITLE: "Pasfhocal",
      SUBMIT_BUTTON_NAME: "Logáil isteach",
      FORGET_PASSWORD_BUTTON_NAME: "Dearmad ar pasfhocal",
      SUCESSS_MESSAGE: "Sucesss",
      PASSWORD_INPUT_ERROR_MESSAGE: "Cuir isteach do phasfhocal le do thoil!",
      USER_NAME_INPUT_ERROR_MESSAGE: "Ionchur d’ainm úsáideora le do thoil!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "Cealaigh",
      DEPLOY_BUTTON_NAME: "Imscaradh",
      DEPLOY_TITLE: "Imscaradh -",
      MASS_DEPLOY_TITLE: "Imscaradh Aifrinn",
      NO_SELECTED_DASHBOARDS_ERROR:
        "Roghnaigh painéal amháin ar a laghad le himscaradh!",
      NO_SELECTED_USERS_ERROR:
        "Roghnaigh úsáideoir amháin ar a laghad le himscaradh!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "Gach Painéal na nIonstraimí",
    },
    DashboardsPage: {
      TITLE: "Leathanach Painéal",
      DESCRIPTION:
        "Achoimre tapa ar stádas, agus socrú, agus tascanna riaracháin leanúnacha.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "Cuardaigh de réir ainm an Phainéil",
    },
    DashboardTemplateCard: {
      TITLE: "Teimpléid Painéal na nIonstraimí",
      DESCRIPTION_ONE:
        "Déan do chuid smaointe maidir le dashboards a mhalartú le haon úsáideoir Nagios XI trí chomhaid teimpléid painéal a roinnt.",
      DESCRIPTION_TWO:
        "Comhaid Nagios XI JSON a iompórtáil nó a easpórtáil agus cuir cumhacht láithreach le do dashboards.",
    },
    DashboardTemplateImport: {
      TITLE: "Iompórtáil",
      DESCRIPTION:
        "Uaslódáil Teimpléad Painéal (JSON) atá roinnte leat, agus taispeánfar é ar do liosta “Gach Painéal na nIonstraimí”.",
      SELECT_FILE_LABEL: "Roghnaigh Comhad",
      SELECT_FILE_ERROR: "Roghnaigh comhad le hiompórtáil!",
      BROWSE_BUTTON_NAME: "Brabhsáil",
      IMPORT_BUTTON_NAME: "Iompórtáil",
      INVALID_FILE_ERROR_TITLE: "Cineál Comhad Neamhbhailí",
      INVALID_FILE_ERROR_MESSAGE:
        "Caithfidh comhad teimpléad a bheith i bhformáid JSON!",
    },
    DashboardTemplateExport: {
      TITLE: "Easpórtáil",
      DESCRIPTION:
        "Uaslódáil Teimpléad Painéal (JSON) atá roinnte leat, agus taispeánfar é ar do liosta “Gach Painéal na nIonstraimí”.",
      SELECt_TEMPLATE_LABEL: "Roghnaigh Teimpléad",
      SELECt_TEMPLATE_ERROR_MESSAGE: "Roghnaigh teimpléad le honnmhairiú!",
      EXPORT_BUTTON_NAME: "Easpórtáil",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "Scrios",
      POPCONFIRM_TEXT:
        "An bhfuil tú cinnte gur mhaith leat painéal a scriosadh",
      POPCONFIRM_OK: "Ceart go leor",
      POPCONFIRM_NO: "Cealaigh",
      CALL_REASON: "Scrios Painéal na nIonstraimí",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Dúblach",
      POPCONFIRM_TEXT:
        "An bhfuil tú cinnte gur mhaith leat an painéal a mhacasamhlú",
      POPCONFIRM_OK: "Ceart go leor",
      POPCONFIRM_NO: "Cealaigh",
      CALL_REASON: "Painéal Dúblach",
    },
    EditButton: {
      TOOLTIP_TEXT: "Cuir in Eagar",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Cruthaigh Painéal na nIonstraimí",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Teideal an Phainéil",
      DASHBOARD_INPUT_ERROR_MESSAGE:
        "Cuir isteach Teideal an Phainéil le do thoil!",
      SELECT_BACKGROUND_COLOR: "Roghnaigh Dath Cúlra",
      PUBLIC_SHARE_CHECKBOX: "Poiblí / Inroinnte",
      ALLOW_EDITING_CHECKBOX: "Lig don Eagarthóireacht",
      DASHLETS: "Dashlets",
    },
    FavoritesList: {
      BUTTON_NAME: "Cuir Favorites in eagar",
    },
    FavoritesButton: {
      BUTTON_NAME: "Favorites",
    },
    ShareButton: {
      BUTTON_NAME: "Comhroinn",
      TOOLTIP_TEXT: "Comhroinn",
      NOTIFICATION_TEXT1: "Cóipeáladh chuig an gearrthaisce",
      NOTIFICATION_TEXT2: "URL an phainéil",
      DESCRIPTION: "URL ginte",
    },
    ProfileButton: {
      BUTTON_NAME: "Próifíl",
    },
    Footer: {
      BUTTON_NAME: "Próifíl",
    },
    NagiosStatusBox: {
      TITLE: "Stádas Nagios",
    },
    StatusContent: {
      BUTTON_NAME: "Glan Gan Láimhseáil",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE:
        "Níl tú ag déanamh monatóireachta ar aon óstach fós!",
      NOT_MONITORING_SERVICE_MESSAGE:
        "Níl tú ag déanamh monatóireachta ar aon seirbhísí fós!",
      ONCE_SETUP_HOST_MESSAGE:
        "Nuair a bheidh óstach socraithe agat, feicfidh tú sonraí anseo.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "Nuair a bheidh seirbhís curtha ar bun agat, feicfidh tú sonraí anseo.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "Sábháil Painéal na nIonstraimí",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "Scrios",
      POPCONFIRM_TEXT:
        "An bhfuil tú cinnte gur mhaith leat dashlet a scriosadh",
      POPCONFIRM_OK: "Ceart go leor",
      POPCONFIRM_NO: "Cealaigh",
      CALL_REASON: "Scrios Dashlet",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "Cuir in Eagar",
      POPCONFIRM_TEXT:
        "An bhfuil tú cinnte gur mhaith leat dashlet a chur in eagar",
      POPCONFIRM_OK: "Ceart go leor",
      POPCONFIRM_NO: "Cealaigh",
      CALL_REASON: "Cuir Dashlet in eagar",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Dúblach",
      POPCONFIRM_TEXT:
        "An bhfuil tú cinnte gur mhaith leat an dashlet a mhacasamhlú",
      POPCONFIRM_OK: "Ceart go leor",
      POPCONFIRM_NO: "Cealaigh",
      CALL_REASON: "Dashlet Dúblach",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Teideal Dashlet",
      SELECT_BACKGROUND_COLOR: "Roghnaigh Dath Cúlra",
      DASHLET_TYPE: "Cineál Dashlet",
      DASHLET_URL_INPUT: "URL Dashlet",
      DASHLET_DIMENSIONS: "Toisí Dashlet",
      DASHLET_POSITION: "Seasamh Dashlet",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE: "Ionchur Teideal Dashlet le do thoil!",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "Ionchur URL Dashlet le do thoil!",
    },
  },
  la: {
    LoginForm: {
      TITLE: "XII Nagios Log In",
      USER_NAME_INPUT_TITLE: "User Name",
      PASSWORD_INPUT_TITLE: "Password",
      SUBMIT_BUTTON_NAME: "login",
      FORGET_PASSWORD_BUTTON_NAME: "Forgot Password",
      SUCESSS_MESSAGE: "Sucesss",
      PASSWORD_INPUT_ERROR_MESSAGE: "Quaeso password input!",
      USER_NAME_INPUT_ERROR_MESSAGE: "User input quaeso nomen tuum?",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "Cancel",
      DEPLOY_BUTTON_NAME: "Deploy",
      DEPLOY_TITLE: "Deploy -",
      MASS_DEPLOY_TITLE: "missa Deploy",
      NO_SELECTED_DASHBOARDS_ERROR:
        "Elige unum quidem satis explicatis ordinibus ashboardday quaeso!",
      NO_SELECTED_USERS_ERROR:
        "Placere elige unum quidem satis explicatis ordinibus, ut user?",
    },
    AllDashboardsCard: {
      CARD_TITLE: "omnes Dashboards",
    },
    DashboardsPage: {
      TITLE: "Page Dashboards",
      DESCRIPTION:
        "A velox status summary et setup et Onging admin pressit officio.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "A search nomen eius Dashboard",
    },
    DashboardTemplateCard: {
      TITLE: "ashboardday Templates",
      DESCRIPTION_ONE:
        "Commutationem ideas tuas in omni dashboards Nagios XII user participat ashboardday template files.",
      DESCRIPTION_TWO:
        "Et export Import Nagios XII JSON files instant potestate addendi, et tuum dashboards.",
    },
    DashboardTemplateImport: {
      TITLE: "Import",
      DESCRIPTION:
        "Upload a Dashboard Template (JSON), quod suus &#39;been participatur ad te et ostendam in te &quot;Omnes Dashboards&#39; album.",
      SELECT_FILE_LABEL: "Select File",
      SELECT_FILE_ERROR: "Placere ad colligunt lima import!",
      BROWSE_BUTTON_NAME: "Browse",
      IMPORT_BUTTON_NAME: "Import",
      INVALID_FILE_ERROR_TITLE: "File Aliquam Type",
      INVALID_FILE_ERROR_MESSAGE: "Music file necessitates in JSON format?",
    },
    DashboardTemplateExport: {
      TITLE: "export",
      DESCRIPTION:
        "Upload a Dashboard Template (JSON), quod suus &#39;been participatur ad te et ostendam in te &quot;Omnes Dashboards&#39; album.",
      SELECt_TEMPLATE_LABEL: "Formula selecta",
      SELECt_TEMPLATE_ERROR_MESSAGE: "Placere colligunt usque Template export!",
      EXPORT_BUTTON_NAME: "export",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "Delete",
      POPCONFIRM_TEXT: "Visne tu delere ashboardday",
      POPCONFIRM_OK: "ok",
      POPCONFIRM_NO: "Cancel",
      CALL_REASON: "Delete Dashboard",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Effingo",
      POPCONFIRM_TEXT: "Visne tu habes duplicare ashboardday",
      POPCONFIRM_OK: "ok",
      POPCONFIRM_NO: "Cancel",
      CALL_REASON: "Effingo Dashboard",
    },
    EditButton: {
      TOOLTIP_TEXT: "recensere",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Partum a Dashboard",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Title ashboardday",
      DASHBOARD_INPUT_ERROR_MESSAGE: "Title Dashboard initus quaeso!",
      SELECT_BACKGROUND_COLOR: "Lego Background Colo colui cultum",
      PUBLIC_SHARE_CHECKBOX: "Public / Shareable",
      ALLOW_EDITING_CHECKBOX: "patitur PL |",
      DASHLETS: "Dashlets",
    },
    FavoritesList: {
      BUTTON_NAME: "recensere Favorites",
    },
    FavoritesButton: {
      BUTTON_NAME: "Favorites",
    },
    ShareButton: {
      BUTTON_NAME: "Share",
      TOOLTIP_TEXT: "Share",
      NOTIFICATION_TEXT1: "Copyied ut clipboard",
      NOTIFICATION_TEXT2: "URL ashboardday",
      DESCRIPTION: "URL Generatated",
    },
    ProfileButton: {
      BUTTON_NAME: "Profile",
    },
    Footer: {
      BUTTON_NAME: "Profile",
    },
    NagiosStatusBox: {
      TITLE: "Status Nagios",
    },
    StatusContent: {
      BUTTON_NAME: "patet Unhandled",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "Non tamen Deus exercituum quis adipiscing!",
      NOT_MONITORING_SERVICE_MESSAGE: "Non tamen magna aliqua officia!",
      ONCE_SETUP_HOST_MESSAGE:
        "Cum tu Ive &#39;setup est hospes, non videbo notitia hic.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "Cum tu Ive &#39;setup a officium, vos mos animadverto notitia hic.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "nisi Dashboard",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "Delete",
      POPCONFIRM_TEXT: "Visne tu delere dashlet",
      POPCONFIRM_OK: "ok",
      POPCONFIRM_NO: "Cancel",
      CALL_REASON: "Delete Dashlet",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "recensere",
      POPCONFIRM_TEXT: "Visne vero recensere es dashlet",
      POPCONFIRM_OK: "ok",
      POPCONFIRM_NO: "Cancel",
      CALL_REASON: "recensere Dashlet",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Effingo",
      POPCONFIRM_TEXT: "Visne tu habes duplicare dashlet",
      POPCONFIRM_OK: "ok",
      POPCONFIRM_NO: "Cancel",
      CALL_REASON: "Effingo Dashlet",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Title Dashlet",
      SELECT_BACKGROUND_COLOR: "Lego Background Colo colui cultum",
      DASHLET_TYPE: "Type Dashlet",
      DASHLET_URL_INPUT: "URL Dashlet",
      DASHLET_DIMENSIONS: "Dimensiones Dashlet",
      DASHLET_POSITION: "Dashlet Position",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE: "Title potenti Dashlet placent?",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "URL potenti Dashlet placent?",
    },
  },
  el: {
    LoginForm: {
      TITLE: "Nagios XI Σύνδεση",
      USER_NAME_INPUT_TITLE: "Ονομα χρήστη",
      PASSWORD_INPUT_TITLE: "Κωδικός πρόσβασης",
      SUBMIT_BUTTON_NAME: "Σύνδεση",
      FORGET_PASSWORD_BUTTON_NAME: "Ξεχάσατε τον κωδικό",
      SUCESSS_MESSAGE: "Επιτυχίες",
      PASSWORD_INPUT_ERROR_MESSAGE: "Εισαγάγετε τον κωδικό πρόσβασής σας!",
      USER_NAME_INPUT_ERROR_MESSAGE: "Εισαγάγετε το όνομα χρήστη σας!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "Ματαίωση",
      DEPLOY_BUTTON_NAME: "Παρατάσσω",
      DEPLOY_TITLE: "Ανάπτυξη -",
      MASS_DEPLOY_TITLE: "Μαζική ανάπτυξη",
      NO_SELECTED_DASHBOARDS_ERROR:
        "Επιλέξτε τουλάχιστον έναν πίνακα ελέγχου για ανάπτυξη!",
      NO_SELECTED_USERS_ERROR: "Επιλέξτε τουλάχιστον έναν χρήστη για ανάπτυξη!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "Όλοι οι πίνακες ελέγχου",
    },
    DashboardsPage: {
      TITLE: "Σελίδα ταμπλό",
      DESCRIPTION:
        "Μια γρήγορη περίληψη κατάστασης, ρύθμιση, και συνεχείς εργασίες διαχειριστή.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "Αναζήτηση με όνομα πίνακα ελέγχου",
    },
    DashboardTemplateCard: {
      TITLE: "Πρότυπα ταμπλό",
      DESCRIPTION_ONE:
        "Ανταλλάξτε τις ιδέες σας για πίνακες ελέγχου με οποιονδήποτε χρήστη Nagios XI, κοινοποιώντας αρχεία προτύπων πίνακα ελέγχου.",
      DESCRIPTION_TWO:
        "Εισαγάγετε ή εξαγάγετε αρχεία Nagios XI JSON και προσθέστε άμεση ισχύ στους πίνακες ελέγχου σας.",
    },
    DashboardTemplateImport: {
      TITLE: "Εισαγωγή",
      DESCRIPTION:
        "Ανεβάστε ένα πρότυπο πίνακα ελέγχου (JSON) που σας έχει κοινοποιηθεί και θα εμφανίζεται στη λίστα &quot;Όλοι οι πίνακες ελέγχου&quot;.",
      SELECT_FILE_LABEL: "Επιλέξτε Αρχείο",
      SELECT_FILE_ERROR: "Επιλέξτε αρχείο για εισαγωγή!",
      BROWSE_BUTTON_NAME: "Ξεφυλλίζω",
      IMPORT_BUTTON_NAME: "Εισαγωγή",
      INVALID_FILE_ERROR_TITLE: "Μη έγκυρος τύπος αρχείου",
      INVALID_FILE_ERROR_MESSAGE:
        "Το αρχείο προτύπου πρέπει να είναι σε μορφή JSON!",
    },
    DashboardTemplateExport: {
      TITLE: "Εξαγωγή",
      DESCRIPTION:
        "Ανεβάστε ένα πρότυπο πίνακα ελέγχου (JSON) που σας έχει κοινοποιηθεί και θα εμφανίζεται στη λίστα &quot;Όλοι οι πίνακες ελέγχου&quot;.",
      SELECt_TEMPLATE_LABEL: "Επιλέξτε Πρότυπο",
      SELECt_TEMPLATE_ERROR_MESSAGE: "Επιλέξτε πρότυπο για εξαγωγή!",
      EXPORT_BUTTON_NAME: "Εξαγωγή",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "Διαγράφω",
      POPCONFIRM_TEXT:
        "Είστε βέβαιοι ότι θέλετε να διαγράψετε τον πίνακα ελέγχου",
      POPCONFIRM_OK: "Εντάξει",
      POPCONFIRM_NO: "Ματαίωση",
      CALL_REASON: "Διαγραφή πίνακα ελέγχου",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Αντίγραφο",
      POPCONFIRM_TEXT:
        "Είστε βέβαιοι ότι θέλετε να αντιγράψετε τον πίνακα ελέγχου",
      POPCONFIRM_OK: "Εντάξει",
      POPCONFIRM_NO: "Ματαίωση",
      CALL_REASON: "Διπλός πίνακας ελέγχου",
    },
    EditButton: {
      TOOLTIP_TEXT: "Επεξεργασία",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Δημιουργήστε έναν πίνακα ελέγχου",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Τίτλος πίνακα ελέγχου",
      DASHBOARD_INPUT_ERROR_MESSAGE: "Εισαγάγετε τον Τίτλο πίνακα ελέγχου!",
      SELECT_BACKGROUND_COLOR: "Επιλέξτε Χρώμα φόντου",
      PUBLIC_SHARE_CHECKBOX: "Δημόσιο / Κοινόχρηστο",
      ALLOW_EDITING_CHECKBOX: "Να επιτρέπεται η επεξεργασία",
      DASHLETS: "Ταμπλέτες",
    },
    FavoritesList: {
      BUTTON_NAME: "Επεξεργασία Αγαπημένων",
    },
    FavoritesButton: {
      BUTTON_NAME: "Αγαπημένα",
    },
    ShareButton: {
      BUTTON_NAME: "Μερίδιο",
      TOOLTIP_TEXT: "Μερίδιο",
      NOTIFICATION_TEXT1: "Αντιγράφηκε στο πρόχειρο",
      NOTIFICATION_TEXT2: "διεύθυνση URL πίνακα ελέγχου",
      DESCRIPTION: "Δημιουργήθηκε διεύθυνση URL",
    },
    ProfileButton: {
      BUTTON_NAME: "Προφίλ",
    },
    Footer: {
      BUTTON_NAME: "Προφίλ",
    },
    NagiosStatusBox: {
      TITLE: "Κατάσταση Nagios",
    },
    StatusContent: {
      BUTTON_NAME: "Εκκαθάριση χωρίς χειρισμό",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE:
        "Δεν παρακολουθείτε ακόμη κεντρικούς υπολογιστές!",
      NOT_MONITORING_SERVICE_MESSAGE: "Δεν παρακολουθείτε ακόμη υπηρεσίες!",
      ONCE_SETUP_HOST_MESSAGE:
        "Μόλις ρυθμίσετε έναν κεντρικό υπολογιστή, θα δείτε δεδομένα εδώ.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "Μόλις ρυθμίσετε μια υπηρεσία, θα δείτε δεδομένα εδώ.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "Αποθήκευση πίνακα ελέγχου",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "Διαγράφω",
      POPCONFIRM_TEXT: "Είστε βέβαιοι ότι θέλετε να διαγράψετε το ταμπλό",
      POPCONFIRM_OK: "Εντάξει",
      POPCONFIRM_NO: "Ματαίωση",
      CALL_REASON: "Διαγραφή Dashlet",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "Επεξεργασία",
      POPCONFIRM_TEXT: "Είστε βέβαιοι ότι θέλετε να επεξεργαστείτε το ταμπλό",
      POPCONFIRM_OK: "Εντάξει",
      POPCONFIRM_NO: "Ματαίωση",
      CALL_REASON: "Επεξεργασία Dashlet",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Αντίγραφο",
      POPCONFIRM_TEXT: "Είστε βέβαιοι ότι θέλετε να αντιγράψετε το ταμπλό",
      POPCONFIRM_OK: "Εντάξει",
      POPCONFIRM_NO: "Ματαίωση",
      CALL_REASON: "Διπλό Dashlet",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Τίτλος Dashlet",
      SELECT_BACKGROUND_COLOR: "Επιλέξτε Χρώμα φόντου",
      DASHLET_TYPE: "Τύπος ταμπλό",
      DASHLET_URL_INPUT: "Διεύθυνση URL Dashlet",
      DASHLET_DIMENSIONS: "Διαστάσεις ταμπλό",
      DASHLET_POSITION: "Θέση ταμπλό",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE: "Εισαγάγετε τον τίτλο του ταμπλό!",
      DASHLET_INPUT_URL_ERROR_MESSAGE:
        "Εισαγάγετε τη διεύθυνση URL του Dashlet!",
    },
  },
  bg: {
    LoginForm: {
      TITLE: "Nagios XI Влезте",
      USER_NAME_INPUT_TITLE: "Потребителско име",
      PASSWORD_INPUT_TITLE: "Парола",
      SUBMIT_BUTTON_NAME: "Влизам",
      FORGET_PASSWORD_BUTTON_NAME: "Забравена парола",
      SUCESSS_MESSAGE: "Успехи",
      PASSWORD_INPUT_ERROR_MESSAGE: "Моля, въведете паролата си!",
      USER_NAME_INPUT_ERROR_MESSAGE: "Моля, въведете вашето потребителско име!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "Отказ",
      DEPLOY_BUTTON_NAME: "Разгръщане",
      DEPLOY_TITLE: "Разполагане -",
      MASS_DEPLOY_TITLE: "Масово разполагане",
      NO_SELECTED_DASHBOARDS_ERROR:
        "Моля, изберете поне едно табло за разполагане!",
      NO_SELECTED_USERS_ERROR:
        "Моля, изберете поне един потребител, към когото да разполагате!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "Всички табла за управление",
    },
    DashboardsPage: {
      TITLE: "Страница на таблата за управление",
      DESCRIPTION:
        "Бързо обобщение на състоянието, настройка и включване на администраторски задачи.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "Търсене по име на таблото за управление",
    },
    DashboardTemplateCard: {
      TITLE: "Шаблони на таблото за управление",
      DESCRIPTION_ONE:
        "Обменяйте идеите си за табла с всеки потребител на Nagios XI, като споделяте файлове с шаблони на таблото.",
      DESCRIPTION_TWO:
        "Импортирайте или експортирайте Nagios XI JSON файлове и добавете незабавна мощност към таблата си.",
    },
    DashboardTemplateImport: {
      TITLE: "Внос",
      DESCRIPTION:
        "Качете шаблон за табло за управление (JSON), който е споделен с вас, и той ще се покаже във вашия списък „Всички табла за управление“.",
      SELECT_FILE_LABEL: "Избери файл",
      SELECT_FILE_ERROR: "Моля, изберете файл за импортиране!",
      BROWSE_BUTTON_NAME: "Преглед",
      IMPORT_BUTTON_NAME: "Внос",
      INVALID_FILE_ERROR_TITLE: "Невалиден тип файл",
      INVALID_FILE_ERROR_MESSAGE:
        "Файлът на шаблона трябва да бъде във формат JSON!",
    },
    DashboardTemplateExport: {
      TITLE: "Износ",
      DESCRIPTION:
        "Качете шаблон за табло за управление (JSON), който е споделен с вас, и той ще се покаже във вашия списък „Всички табла за управление“.",
      SELECt_TEMPLATE_LABEL: "Изберете Шаблон",
      SELECt_TEMPLATE_ERROR_MESSAGE: "Моля, изберете шаблон за експортиране!",
      EXPORT_BUTTON_NAME: "Износ",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "Изтрий",
      POPCONFIRM_TEXT: "Наистина ли искате да изтриете таблото за управление",
      POPCONFIRM_OK: "Добре",
      POPCONFIRM_NO: "Отказ",
      CALL_REASON: "Изтриване на таблото за управление",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Дубликат",
      POPCONFIRM_TEXT: "Наистина ли искате да дублирате таблото за управление",
      POPCONFIRM_OK: "Добре",
      POPCONFIRM_NO: "Отказ",
      CALL_REASON: "Дублирано табло за управление",
    },
    EditButton: {
      TOOLTIP_TEXT: "редактиране",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Създайте табло за управление",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Заглавие на таблото",
      DASHBOARD_INPUT_ERROR_MESSAGE:
        "Моля, въведете заглавие на таблото за управление!",
      SELECT_BACKGROUND_COLOR: "Изберете Цвят на фона",
      PUBLIC_SHARE_CHECKBOX: "Публично / Споделяне",
      ALLOW_EDITING_CHECKBOX: "Разрешаване на редактиране",
      DASHLETS: "Табла",
    },
    FavoritesList: {
      BUTTON_NAME: "Редактиране на предпочитани",
    },
    FavoritesButton: {
      BUTTON_NAME: "Любими",
    },
    ShareButton: {
      BUTTON_NAME: "Дял",
      TOOLTIP_TEXT: "Дял",
      NOTIFICATION_TEXT1: "Копирано в клипборда",
      NOTIFICATION_TEXT2: "URL адрес на таблото за управление",
      DESCRIPTION: "Генериран URL адрес",
    },
    ProfileButton: {
      BUTTON_NAME: "Профил",
    },
    Footer: {
      BUTTON_NAME: "Профил",
    },
    NagiosStatusBox: {
      TITLE: "Статус на Нагиос",
    },
    StatusContent: {
      BUTTON_NAME: "Ясно Необработено",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "Все още не следите хостове!",
      NOT_MONITORING_SERVICE_MESSAGE: "Все още не следите нито една услуга!",
      ONCE_SETUP_HOST_MESSAGE: "След като настроите хост, тук ще видите данни.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "След като настроите услуга, ще видите данни тук.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "Запазване на таблото за управление",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "Изтрий",
      POPCONFIRM_TEXT: "Наистина ли искате да изтриете таблото за управление",
      POPCONFIRM_OK: "Добре",
      POPCONFIRM_NO: "Отказ",
      CALL_REASON: "Изтриване на Dashlet",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "редактиране",
      POPCONFIRM_TEXT:
        "Наистина ли искате да редактирате таблото за управление",
      POPCONFIRM_OK: "Добре",
      POPCONFIRM_NO: "Отказ",
      CALL_REASON: "Редактиране на таблото",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Дубликат",
      POPCONFIRM_TEXT: "Наистина ли искате да дублирате таблото за управление",
      POPCONFIRM_OK: "Добре",
      POPCONFIRM_NO: "Отказ",
      CALL_REASON: "Дублиран Dashlet",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Заглавие на таблото",
      SELECT_BACKGROUND_COLOR: "Изберете Цвят на фона",
      DASHLET_TYPE: "Тип табло",
      DASHLET_URL_INPUT: "URL адрес на таблото",
      DASHLET_DIMENSIONS: "Размери на таблото",
      DASHLET_POSITION: "Позиция на таблото",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE:
        "Моля, въведете заглавието на таблото!",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "Моля, въведете URL адрес на Dashlet!",
    },
  },
  zh: {
    LoginForm: {
      TITLE: "Nagios XI登录",
      USER_NAME_INPUT_TITLE: "用户名",
      PASSWORD_INPUT_TITLE: "密码",
      SUBMIT_BUTTON_NAME: "登录",
      FORGET_PASSWORD_BUTTON_NAME: "忘记密码",
      SUCESSS_MESSAGE: "成功",
      PASSWORD_INPUT_ERROR_MESSAGE: "请输入您的密码！",
      USER_NAME_INPUT_ERROR_MESSAGE: "请输入您的用户名！",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "取消",
      DEPLOY_BUTTON_NAME: "部署",
      DEPLOY_TITLE: "部署-",
      MASS_DEPLOY_TITLE: "大规模部署",
      NO_SELECTED_DASHBOARDS_ERROR: "请至少选择一个仪表板进行部署！",
      NO_SELECTED_USERS_ERROR: "请选择至少一个要部署的用户！",
    },
    AllDashboardsCard: {
      CARD_TITLE: "所有仪表板",
    },
    DashboardsPage: {
      TITLE: "仪表板页面",
      DESCRIPTION: "快速状态摘要，设置和正在进行的管理任务。",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "按仪表板名称搜索",
    },
    DashboardTemplateCard: {
      TITLE: "仪表板模板",
      DESCRIPTION_ONE:
        "通过共享仪表板模板文件，与任何Nagios XI用户交换有关仪表板的想法。",
      DESCRIPTION_TWO:
        "导入或导出Nagios XI JSON文件，并为您的仪表板增加即时功能。",
    },
    DashboardTemplateImport: {
      TITLE: "进口",
      DESCRIPTION:
        "上载与您共享的仪表板模板（JSON），它将显示在“所有仪表板”列表中。",
      SELECT_FILE_LABEL: "选择文件",
      SELECT_FILE_ERROR: "请选择要导入的文件！",
      BROWSE_BUTTON_NAME: "浏览",
      IMPORT_BUTTON_NAME: "进口",
      INVALID_FILE_ERROR_TITLE: "文件类型无效",
      INVALID_FILE_ERROR_MESSAGE: "模板文件必须为JSON格式！",
    },
    DashboardTemplateExport: {
      TITLE: "出口",
      DESCRIPTION:
        "上载与您共享的仪表板模板（JSON），它将显示在“所有仪表板”列表中。",
      SELECt_TEMPLATE_LABEL: "选择模板",
      SELECt_TEMPLATE_ERROR_MESSAGE: "请选择要导出的模板！",
      EXPORT_BUTTON_NAME: "出口",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "删除",
      POPCONFIRM_TEXT: "您确定要删除仪表板吗",
      POPCONFIRM_OK: "好",
      POPCONFIRM_NO: "取消",
      CALL_REASON: "删除仪表板",
    },
    CopyButton: {
      TOOLTIP_TEXT: "重复",
      POPCONFIRM_TEXT: "您确定要复制仪表板吗",
      POPCONFIRM_OK: "好",
      POPCONFIRM_NO: "取消",
      CALL_REASON: "仪表板重复",
    },
    EditButton: {
      TOOLTIP_TEXT: "编辑",
    },
    CreateDashboardCard: {
      CARD_TITLE: "创建仪表板",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "仪表板标题",
      DASHBOARD_INPUT_ERROR_MESSAGE: "请输入仪表板标题！",
      SELECT_BACKGROUND_COLOR: "选择背景色",
      PUBLIC_SHARE_CHECKBOX: "公开/共享",
      ALLOW_EDITING_CHECKBOX: "允许编辑",
      DASHLETS: "仪表盘",
    },
    FavoritesList: {
      BUTTON_NAME: "编辑收藏夹",
    },
    FavoritesButton: {
      BUTTON_NAME: "收藏夹",
    },
    ShareButton: {
      BUTTON_NAME: "分享",
      TOOLTIP_TEXT: "分享",
      NOTIFICATION_TEXT1: "复制到剪贴板",
      NOTIFICATION_TEXT2: "仪表板URL",
      DESCRIPTION: "生成的URL",
    },
    ProfileButton: {
      BUTTON_NAME: "个人资料",
    },
    Footer: {
      BUTTON_NAME: "个人资料",
    },
    NagiosStatusBox: {
      TITLE: "Nagios状态",
    },
    StatusContent: {
      BUTTON_NAME: "清除未处理",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "您尚未监视任何主机！",
      NOT_MONITORING_SERVICE_MESSAGE: "您尚未监视任何服务！",
      ONCE_SETUP_HOST_MESSAGE: "设置主机后，您将在此处看到数据。",
      ONCE_SETUP_SERVICE_MESSAGE: "设置服务后，您将在此处看到数据。",
    },
    DashletSaveButton: {
      BUTTON_NAME: "保存仪表盘",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "删除",
      POPCONFIRM_TEXT: "您确定要删除破折号吗",
      POPCONFIRM_OK: "好",
      POPCONFIRM_NO: "取消",
      CALL_REASON: "删除仪表盘",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "编辑",
      POPCONFIRM_TEXT: "您确定要编辑dashlet",
      POPCONFIRM_OK: "好",
      POPCONFIRM_NO: "取消",
      CALL_REASON: "编辑仪表板",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "重复",
      POPCONFIRM_TEXT: "您确定要复制破折号吗",
      POPCONFIRM_OK: "好",
      POPCONFIRM_NO: "取消",
      CALL_REASON: "重复的仪表板",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "仪表盘标题",
      SELECT_BACKGROUND_COLOR: "选择背景色",
      DASHLET_TYPE: "仪表盘类型",
      DASHLET_URL_INPUT: "Dashlet URL",
      DASHLET_DIMENSIONS: "仪表盘尺寸",
      DASHLET_POSITION: "仪表盘位置",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE: "请输入仪表盘标题！",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "请输入Dashlet URL！",
    },
  },
  nl: {
    LoginForm: {
      TITLE: "Nagios XI Inloggen",
      USER_NAME_INPUT_TITLE: "Gebruikersnaam",
      PASSWORD_INPUT_TITLE: "Wachtwoord",
      SUBMIT_BUTTON_NAME: "Log in",
      FORGET_PASSWORD_BUTTON_NAME: "Wachtwoord vergeten",
      SUCESSS_MESSAGE: "Slaagt",
      PASSWORD_INPUT_ERROR_MESSAGE: "Voer uw wachtwoord in!",
      USER_NAME_INPUT_ERROR_MESSAGE: "Voer uw gebruikersnaam in!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "annuleren",
      DEPLOY_BUTTON_NAME: "Inzetten",
      DEPLOY_TITLE: "Inzetten -",
      MASS_DEPLOY_TITLE: "Massa-implementatie",
      NO_SELECTED_DASHBOARDS_ERROR:
        "Kies ten minste één dashboard om te implementeren!",
      NO_SELECTED_USERS_ERROR:
        "Kies ten minste één gebruiker om naar te implementeren!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "Alle dashboards",
    },
    DashboardsPage: {
      TITLE: "Dashboards-pagina",
      DESCRIPTION: "Een snel statusoverzicht en instellingen en beheertaken.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "Zoek op Dashboard-naam",
    },
    DashboardTemplateCard: {
      TITLE: "Dashboardsjablonen",
      DESCRIPTION_ONE:
        "Wissel uw ideeën voor dashboards uit met elke Nagios XI-gebruiker door dashboardsjabloonbestanden te delen.",
      DESCRIPTION_TWO:
        "Importeer of exporteer Nagios XI JSON-bestanden en voeg direct power toe aan uw dashboards.",
    },
    DashboardTemplateImport: {
      TITLE: "Importeren",
      DESCRIPTION:
        "Upload een Dashboardsjabloon (JSON) die met u is gedeeld, en het wordt weergegeven in uw lijst &quot;Alle dashboards&quot;.",
      SELECT_FILE_LABEL: "Selecteer bestand",
      SELECT_FILE_ERROR: "Kies een bestand om te importeren!",
      BROWSE_BUTTON_NAME: "Bladeren",
      IMPORT_BUTTON_NAME: "Importeren",
      INVALID_FILE_ERROR_TITLE: "Ongeldig bestandstype",
      INVALID_FILE_ERROR_MESSAGE:
        "Het sjabloonbestand moet de JSON-indeling hebben!",
    },
    DashboardTemplateExport: {
      TITLE: "Exporteren",
      DESCRIPTION:
        "Upload een Dashboardsjabloon (JSON) die met u is gedeeld, en het wordt weergegeven in uw lijst &quot;Alle dashboards&quot;.",
      SELECt_TEMPLATE_LABEL: "Selecteer sjabloon",
      SELECt_TEMPLATE_ERROR_MESSAGE: "Kies een sjabloon om te exporteren!",
      EXPORT_BUTTON_NAME: "Exporteren",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "Verwijderen",
      POPCONFIRM_TEXT: "Weet u zeker dat u het dashboard wilt verwijderen?",
      POPCONFIRM_OK: "OK",
      POPCONFIRM_NO: "annuleren",
      CALL_REASON: "Dashboard verwijderen",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Duplicaat",
      POPCONFIRM_TEXT: "Weet u zeker dat u het dashboard wilt dupliceren",
      POPCONFIRM_OK: "OK",
      POPCONFIRM_NO: "annuleren",
      CALL_REASON: "Dubbel dashboard",
    },
    EditButton: {
      TOOLTIP_TEXT: "Bewerk",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Maak een dashboard",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Dashboard titel",
      DASHBOARD_INPUT_ERROR_MESSAGE: "Voer de Dashboard-titel in!",
      SELECT_BACKGROUND_COLOR: "Selecteer Achtergrondkleur",
      PUBLIC_SHARE_CHECKBOX: "Openbaar / deelbaar",
      ALLOW_EDITING_CHECKBOX: "Bewerken toestaan",
      DASHLETS: "Dashlets",
    },
    FavoritesList: {
      BUTTON_NAME: "Favorieten aanpassen",
    },
    FavoritesButton: {
      BUTTON_NAME: "Favorieten",
    },
    ShareButton: {
      BUTTON_NAME: "Delen",
      TOOLTIP_TEXT: "Delen",
      NOTIFICATION_TEXT1: "Gekopieerd naar klembord",
      NOTIFICATION_TEXT2: "dashboard-URL",
      DESCRIPTION: "Gegenereerde URL",
    },
    ProfileButton: {
      BUTTON_NAME: "Profiel",
    },
    Footer: {
      BUTTON_NAME: "Profiel",
    },
    NagiosStatusBox: {
      TITLE: "Nagios-status",
    },
    StatusContent: {
      BUTTON_NAME: "Wis onverwerkt",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "U controleert nog geen hosts!",
      NOT_MONITORING_SERVICE_MESSAGE: "U controleert nog geen services!",
      ONCE_SETUP_HOST_MESSAGE:
        "Nadat u een host heeft ingesteld, ziet u hier gegevens.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "Nadat u een service heeft ingesteld, ziet u hier gegevens.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "Dashboard opslaan",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "Verwijderen",
      POPCONFIRM_TEXT: "Weet u zeker dat u de dashlet wilt verwijderen?",
      POPCONFIRM_OK: "OK",
      POPCONFIRM_NO: "annuleren",
      CALL_REASON: "Dashlet verwijderen",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "Bewerk",
      POPCONFIRM_TEXT: "Weet u zeker dat u de dashlet wilt bewerken?",
      POPCONFIRM_OK: "OK",
      POPCONFIRM_NO: "annuleren",
      CALL_REASON: "Dashlet bewerken",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Duplicaat",
      POPCONFIRM_TEXT: "Weet u zeker dat u de dashlet wilt dupliceren",
      POPCONFIRM_OK: "OK",
      POPCONFIRM_NO: "annuleren",
      CALL_REASON: "Dubbele dashlet",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Dashlet-titel",
      SELECT_BACKGROUND_COLOR: "Selecteer Achtergrondkleur",
      DASHLET_TYPE: "Dashlet-type",
      DASHLET_URL_INPUT: "Dashlet-URL",
      DASHLET_DIMENSIONS: "Dashlet-afmetingen",
      DASHLET_POSITION: "Dashlet-positie",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE: "Voer de Dashlet-titel in!",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "Voer de Dashlet-URL in!",
    },
  },
  tl: {
    LoginForm: {
      TITLE: "Nagios XI Mag-log In",
      USER_NAME_INPUT_TITLE: "Pangalan ng Gumagamit",
      PASSWORD_INPUT_TITLE: "Password",
      SUBMIT_BUTTON_NAME: "Mag log in",
      FORGET_PASSWORD_BUTTON_NAME: "Nakalimutan ang password",
      SUCESSS_MESSAGE: "Mga Sucess",
      PASSWORD_INPUT_ERROR_MESSAGE: "Mangyaring i-input ang iyong password!",
      USER_NAME_INPUT_ERROR_MESSAGE:
        "Mangyaring i-input ang iyong pangalan ng gumagamit!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "Kanselahin",
      DEPLOY_BUTTON_NAME: "I-deploy",
      DEPLOY_TITLE: "I-deploy -",
      MASS_DEPLOY_TITLE: "Pag-deploy ng masa",
      NO_SELECTED_DASHBOARDS_ERROR:
        "Mangyaring pumili ng kahit isang dashboard upang mai-deploy!",
      NO_SELECTED_USERS_ERROR:
        "Mangyaring pumili ng kahit isang gumagamit lamang upang mai-deploy!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "Lahat ng Dashboard",
    },
    DashboardsPage: {
      TITLE: "Pahina ng Mga Dashboard",
      DESCRIPTION:
        "Isang mabilis na buod ng katayuan, at pag-set up, at mga onging na gawain ng admin.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER:
        "Maghanap sa pamamagitan ng pangalan ng Dashboard",
    },
    DashboardTemplateCard: {
      TITLE: "Mga Template ng Dashboard",
      DESCRIPTION_ONE:
        "Palitan ang iyong mga ideya para sa mga dashboard sa anumang gumagamit ng Nagios XI sa pamamagitan ng pagbabahagi ng mga file ng template ng dashboard.",
      DESCRIPTION_TWO:
        "I-import o i-export ang mga Nagios XI JSON file at magdagdag ng instant na lakas sa iyong mga dashboard.",
    },
    DashboardTemplateImport: {
      TITLE: "Angkat",
      DESCRIPTION:
        "Mag-upload ng isang Dashboard Template (JSON) na naibahagi sa iyo, at lalabas ito sa iyong listahan ng &quot;Lahat ng Mga Dashboard&quot;.",
      SELECT_FILE_LABEL: "Piliin ang File",
      SELECT_FILE_ERROR: "Mangyaring pumili ng file upang mai-import!",
      BROWSE_BUTTON_NAME: "Mag-browse",
      IMPORT_BUTTON_NAME: "Angkat",
      INVALID_FILE_ERROR_TITLE: "Di-wastong uri ng file",
      INVALID_FILE_ERROR_MESSAGE:
        "Ang file ng template ay kailangang nasa format na JSON!",
    },
    DashboardTemplateExport: {
      TITLE: "I-export",
      DESCRIPTION:
        "Mag-upload ng isang Dashboard Template (JSON) na naibahagi sa iyo, at lalabas ito sa iyong listahan ng &quot;Lahat ng Mga Dashboard&quot;.",
      SELECt_TEMPLATE_LABEL: "Piliin ang Template",
      SELECt_TEMPLATE_ERROR_MESSAGE:
        "Mangyaring pumili ng template upang mai-export!",
      EXPORT_BUTTON_NAME: "I-export",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "Tanggalin",
      POPCONFIRM_TEXT: "Sigurado ka bang nais mong tanggalin ang dashboard",
      POPCONFIRM_OK: "Sige",
      POPCONFIRM_NO: "Kanselahin",
      CALL_REASON: "Tanggalin ang Dashboard",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Kopyahin",
      POPCONFIRM_TEXT: "Sigurado ka bang nais mong doble ang dashboard",
      POPCONFIRM_OK: "Sige",
      POPCONFIRM_NO: "Kanselahin",
      CALL_REASON: "Dobleng Dashboard",
    },
    EditButton: {
      TOOLTIP_TEXT: "I-edit",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Lumikha ng isang Dashboard",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Pamagat ng Dashboard",
      DASHBOARD_INPUT_ERROR_MESSAGE:
        "Mangyaring i-input ang Pamagat ng Dashboard!",
      SELECT_BACKGROUND_COLOR: "Piliin ang Kulay sa Background",
      PUBLIC_SHARE_CHECKBOX: "Pampubliko / Maibabahagi",
      ALLOW_EDITING_CHECKBOX: "Payagan ang Pag-edit",
      DASHLETS: "Dashlet",
    },
    FavoritesList: {
      BUTTON_NAME: "I-edit ang Mga Paborito",
    },
    FavoritesButton: {
      BUTTON_NAME: "Mga paborito",
    },
    ShareButton: {
      BUTTON_NAME: "Magbahagi",
      TOOLTIP_TEXT: "Magbahagi",
      NOTIFICATION_TEXT1: "Kinopya sa clipboard",
      NOTIFICATION_TEXT2: "dashboard URL",
      DESCRIPTION: "Binuong URL",
    },
    ProfileButton: {
      BUTTON_NAME: "Profile",
    },
    Footer: {
      BUTTON_NAME: "Profile",
    },
    NagiosStatusBox: {
      TITLE: "Katayuan ng Nagios",
    },
    StatusContent: {
      BUTTON_NAME: "Malinaw na Walang Paghahawak",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "Wala ka pang sinusubaybayan na mga host!",
      NOT_MONITORING_SERVICE_MESSAGE:
        "Hindi ka pa sumusubaybay sa anumang mga serbisyo!",
      ONCE_SETUP_HOST_MESSAGE:
        "Kapag na-setup mo na ang isang host, makakakita ka ng data dito.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "Kapag na-setup mo na ang isang serbisyo, makakakita ka ng data dito.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "I-save ang Dashboard",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "Tanggalin",
      POPCONFIRM_TEXT: "Sigurado ka bang nais mong tanggalin ang dashlet",
      POPCONFIRM_OK: "Sige",
      POPCONFIRM_NO: "Kanselahin",
      CALL_REASON: "Tanggalin ang Dashlet",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "I-edit",
      POPCONFIRM_TEXT: "Sigurado ka bang nais mong i-edit ang dashlet",
      POPCONFIRM_OK: "Sige",
      POPCONFIRM_NO: "Kanselahin",
      CALL_REASON: "I-edit ang Dashlet",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Kopyahin",
      POPCONFIRM_TEXT: "Sigurado ka bang nais mong doble ang dashlet",
      POPCONFIRM_OK: "Sige",
      POPCONFIRM_NO: "Kanselahin",
      CALL_REASON: "Dobleng Dashlet",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Pamagat ng Dashlet",
      SELECT_BACKGROUND_COLOR: "Piliin ang Kulay sa Background",
      DASHLET_TYPE: "Uri ng Dashlet",
      DASHLET_URL_INPUT: "URL ng Dashlet",
      DASHLET_DIMENSIONS: "Mga Dimensyon ng Dashlet",
      DASHLET_POSITION: "Posisyon ng Dashlet",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE:
        "Mangyaring Mag-input ng Pamagat ng Dashlet!",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "Mangyaring Mag-input ng Dashlet URL!",
    },
  },
  sv: {
    LoginForm: {
      TITLE: "Nagios XI Logga in",
      USER_NAME_INPUT_TITLE: "Användarnamn",
      PASSWORD_INPUT_TITLE: "Lösenord",
      SUBMIT_BUTTON_NAME: "Logga in",
      FORGET_PASSWORD_BUTTON_NAME: "Glömt ditt lösenord",
      SUCESSS_MESSAGE: "Lyckas",
      PASSWORD_INPUT_ERROR_MESSAGE: "Ange ditt lösenord!",
      USER_NAME_INPUT_ERROR_MESSAGE: "Ange ditt användarnamn!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "Annullera",
      DEPLOY_BUTTON_NAME: "Implementera",
      DEPLOY_TITLE: "Implementera -",
      MASS_DEPLOY_TITLE: "Massdistribution",
      NO_SELECTED_DASHBOARDS_ERROR:
        "Välj minst en instrumentpanel att distribuera!",
      NO_SELECTED_USERS_ERROR: "Välj minst en användare att distribuera till!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "Alla instrumentpaneler",
    },
    DashboardsPage: {
      TITLE: "Dashboards sida",
      DESCRIPTION:
        "En snabb statusöversikt, inställning och pågående administratörsuppgifter.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "Sök efter instrumentpanelens namn",
    },
    DashboardTemplateCard: {
      TITLE: "Instrumentpanelmallar",
      DESCRIPTION_ONE:
        "Utbyta dina idéer för instrumentpaneler med alla Nagios XI-användare genom att dela instrumentpanelmallfiler.",
      DESCRIPTION_TWO:
        "Importera eller exportera Nagios XI JSON-filer och lägg omedelbart till dina instrumentpaneler.",
    },
    DashboardTemplateImport: {
      TITLE: "Importera",
      DESCRIPTION:
        "Ladda upp en Dashboard-mall (JSON) som har delats med dig och den kommer att visas i listan &quot;Alla instrumentpaneler&quot;.",
      SELECT_FILE_LABEL: "Välj fil",
      SELECT_FILE_ERROR: "Välj fil för att importera!",
      BROWSE_BUTTON_NAME: "Bläddra",
      IMPORT_BUTTON_NAME: "Importera",
      INVALID_FILE_ERROR_TITLE: "Ogiltig filtyp",
      INVALID_FILE_ERROR_MESSAGE: "Mallfilen måste vara i JSON-format!",
    },
    DashboardTemplateExport: {
      TITLE: "Exportera",
      DESCRIPTION:
        "Ladda upp en Dashboard-mall (JSON) som har delats med dig och den kommer att visas i listan &quot;Alla instrumentpaneler&quot;.",
      SELECt_TEMPLATE_LABEL: "Välj mall",
      SELECt_TEMPLATE_ERROR_MESSAGE: "Välj mall för att exportera!",
      EXPORT_BUTTON_NAME: "Exportera",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "Radera",
      POPCONFIRM_TEXT: "Är du säker på att du vill ta bort instrumentpanelen",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Annullera",
      CALL_REASON: "Ta bort instrumentpanelen",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Duplicera",
      POPCONFIRM_TEXT: "Är du säker på att du vill duplicera instrumentpanelen",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Annullera",
      CALL_REASON: "Duplicera instrumentpanelen",
    },
    EditButton: {
      TOOLTIP_TEXT: "Redigera",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Skapa en instrumentpanel",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Instrumentpanelens titel",
      DASHBOARD_INPUT_ERROR_MESSAGE: "Ange instrumentpanelens titel!",
      SELECT_BACKGROUND_COLOR: "Välj bakgrundsfärg",
      PUBLIC_SHARE_CHECKBOX: "Allmän / delbar",
      ALLOW_EDITING_CHECKBOX: "Tillåt redigering",
      DASHLETS: "Dashlets",
    },
    FavoritesList: {
      BUTTON_NAME: "Redigera favoriter",
    },
    FavoritesButton: {
      BUTTON_NAME: "Favoriter",
    },
    ShareButton: {
      BUTTON_NAME: "Dela med sig",
      TOOLTIP_TEXT: "Dela med sig",
      NOTIFICATION_TEXT1: "Kopieras till Urklipp",
      NOTIFICATION_TEXT2: "instrumentpanelens URL",
      DESCRIPTION: "Genererad URL",
    },
    ProfileButton: {
      BUTTON_NAME: "Profil",
    },
    Footer: {
      BUTTON_NAME: "Profil",
    },
    NagiosStatusBox: {
      TITLE: "Nagios-status",
    },
    StatusContent: {
      BUTTON_NAME: "Rensa Obehandlad",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "Du övervakar inga värdar än!",
      NOT_MONITORING_SERVICE_MESSAGE: "Du övervakar inga tjänster än!",
      ONCE_SETUP_HOST_MESSAGE:
        "När du har konfigurerat en värd kommer du att se data här.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "När du har konfigurerat en tjänst ser du data här.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "Spara instrumentpanelen",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "Radera",
      POPCONFIRM_TEXT: "Är du säker på att du vill ta bort dashlet",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Annullera",
      CALL_REASON: "Ta bort Dashlet",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "Redigera",
      POPCONFIRM_TEXT: "Är du säker på att du vill redigera dashlet",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Annullera",
      CALL_REASON: "Redigera Dashlet",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Duplicera",
      POPCONFIRM_TEXT: "Är du säker på att du vill duplicera dashlet",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Annullera",
      CALL_REASON: "Duplicera Dashlet",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Dashlet-titel",
      SELECT_BACKGROUND_COLOR: "Välj bakgrundsfärg",
      DASHLET_TYPE: "Dashlet-typ",
      DASHLET_URL_INPUT: "Dashlet URL",
      DASHLET_DIMENSIONS: "Dashlet Mått",
      DASHLET_POSITION: "Dashlet Position",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE: "Vänligen skriv in Dashlet-titel!",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "Ange Dashlet URL!",
    },
  },
  tr: {
    LoginForm: {
      TITLE: "Nagios XI Giriş Yap",
      USER_NAME_INPUT_TITLE: "Kullanıcı adı",
      PASSWORD_INPUT_TITLE: "Parola",
      SUBMIT_BUTTON_NAME: "Oturum aç",
      FORGET_PASSWORD_BUTTON_NAME: "Parolanızı mı unuttunuz",
      SUCESSS_MESSAGE: "Başarılar",
      PASSWORD_INPUT_ERROR_MESSAGE: "Lütfen şifrenizi giriniz!",
      USER_NAME_INPUT_ERROR_MESSAGE: "Lütfen kullanıcı adınızı giriniz!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "İptal etmek",
      DEPLOY_BUTTON_NAME: "Dağıtmak",
      DEPLOY_TITLE: "Dağıtmak -",
      MASS_DEPLOY_TITLE: "Toplu Dağıtım",
      NO_SELECTED_DASHBOARDS_ERROR:
        "Lütfen dağıtmak için en az bir kontrol paneli seçin!",
      NO_SELECTED_USERS_ERROR:
        "Lütfen dağıtmak için en az bir kullanıcı seçin!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "Tüm Gösterge Tabloları",
    },
    DashboardsPage: {
      TITLE: "Gösterge Tabloları Sayfası",
      DESCRIPTION:
        "Hızlı bir durum özeti, kurulum ve devam eden yönetici görevleri.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "Gösterge Tablosu adına göre ara",
    },
    DashboardTemplateCard: {
      TITLE: "Gösterge Tablosu Şablonları",
      DESCRIPTION_ONE:
        "Pano şablon dosyalarını paylaşarak herhangi bir Nagios XI kullanıcısı ile pano fikirlerinizi paylaşın.",
      DESCRIPTION_TWO:
        "Nagios XI JSON dosyalarını içe veya dışa aktarın ve gösterge tablolarınıza anında güç ekleyin.",
    },
    DashboardTemplateImport: {
      TITLE: "İthalat",
      DESCRIPTION:
        "Sizinle paylaşılan bir Gösterge Tablosu Şablonu (JSON) yükleyin ve &quot;Tüm Gösterge Tabloları&quot; listenizde görünecektir.",
      SELECT_FILE_LABEL: "Dosya Seç",
      SELECT_FILE_ERROR: "Lütfen içe aktarılacak dosyayı seçin!",
      BROWSE_BUTTON_NAME: "Araştır",
      IMPORT_BUTTON_NAME: "İthalat",
      INVALID_FILE_ERROR_TITLE: "Geçersiz dosya türü",
      INVALID_FILE_ERROR_MESSAGE:
        "Şablon dosyasının JSON formatında olması gerekir!",
    },
    DashboardTemplateExport: {
      TITLE: "İhracat",
      DESCRIPTION:
        "Sizinle paylaşılan bir Gösterge Tablosu Şablonu (JSON) yükleyin ve &quot;Tüm Gösterge Tabloları&quot; listenizde görünecektir.",
      SELECt_TEMPLATE_LABEL: "Şablon Seçin",
      SELECt_TEMPLATE_ERROR_MESSAGE: "Lütfen dışa aktarmak için şablon seçin!",
      EXPORT_BUTTON_NAME: "İhracat",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "Sil",
      POPCONFIRM_TEXT: "Gösterge tablosunu silmek istediğinizden emin misiniz",
      POPCONFIRM_OK: "Tamam",
      POPCONFIRM_NO: "İptal etmek",
      CALL_REASON: "Gösterge Tablosunu Sil",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Çiftleme",
      POPCONFIRM_TEXT:
        "Kontrol panelini çoğaltmak istediğinizden emin misiniz?",
      POPCONFIRM_OK: "Tamam",
      POPCONFIRM_NO: "İptal etmek",
      CALL_REASON: "Yinelenen Kontrol Paneli",
    },
    EditButton: {
      TOOLTIP_TEXT: "Düzenle",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Gösterge Tablosu Oluşturun",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Gösterge Tablosu Başlığı",
      DASHBOARD_INPUT_ERROR_MESSAGE: "Lütfen Gösterge Tablosu Başlığını girin!",
      SELECT_BACKGROUND_COLOR: "Arka Plan Rengini Seçin",
      PUBLIC_SHARE_CHECKBOX: "Genel / Paylaşılabilir",
      ALLOW_EDITING_CHECKBOX: "Düzenlemeye İzin Ver",
      DASHLETS: "Dashlet&#39;ler",
    },
    FavoritesList: {
      BUTTON_NAME: "Favorileri Düzenle",
    },
    FavoritesButton: {
      BUTTON_NAME: "Favoriler",
    },
    ShareButton: {
      BUTTON_NAME: "Paylaş",
      TOOLTIP_TEXT: "Paylaş",
      NOTIFICATION_TEXT1: "Panoya kopyalandı",
      NOTIFICATION_TEXT2: "gösterge tablosu URL&#39;si",
      DESCRIPTION: "Oluşturulan URL",
    },
    ProfileButton: {
      BUTTON_NAME: "Profil",
    },
    Footer: {
      BUTTON_NAME: "Profil",
    },
    NagiosStatusBox: {
      TITLE: "Nagios Durumu",
    },
    StatusContent: {
      BUTTON_NAME: "İşlenmeyenleri Temizle",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE:
        "Henüz herhangi bir ana bilgisayarı izlemiyorsunuz!",
      NOT_MONITORING_SERVICE_MESSAGE:
        "Henüz herhangi bir hizmeti izlemiyorsunuz!",
      ONCE_SETUP_HOST_MESSAGE:
        "Bir ana bilgisayar kurduğunuzda, verileri burada göreceksiniz.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "Bir hizmet kurduğunuzda, verileri burada göreceksiniz.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "Gösterge Tablosunu Kaydet",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "Sil",
      POPCONFIRM_TEXT:
        "Gösterge uygulamasını silmek istediğinizden emin misiniz",
      POPCONFIRM_OK: "Tamam",
      POPCONFIRM_NO: "İptal etmek",
      CALL_REASON: "Gösterge Uygulamasını Sil",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "Düzenle",
      POPCONFIRM_TEXT:
        "Gösterge uygulamasını düzenlemek istediğinizden emin misiniz",
      POPCONFIRM_OK: "Tamam",
      POPCONFIRM_NO: "İptal etmek",
      CALL_REASON: "Dashlet&#39;i Düzenle",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Çiftleme",
      POPCONFIRM_TEXT:
        "Kontrol panelini kopyalamak istediğinizden emin misiniz?",
      POPCONFIRM_OK: "Tamam",
      POPCONFIRM_NO: "İptal etmek",
      CALL_REASON: "Yinelenen Dashlet",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Gösterge Başlığı",
      SELECT_BACKGROUND_COLOR: "Arka Plan Rengini Seçin",
      DASHLET_TYPE: "Dashlet Türü",
      DASHLET_URL_INPUT: "Dashlet URL&#39;si",
      DASHLET_DIMENSIONS: "Dashlet Boyutları",
      DASHLET_POSITION: "Dashlet Konumu",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE: "Lütfen Gösterge Başlığını Girin!",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "Lütfen Dashlet URL&#39;sini Girin!",
    },
  },
  uk: {
    LoginForm: {
      TITLE: "Nagios XI Увійти",
      USER_NAME_INPUT_TITLE: "Ім&#39;я користувача",
      PASSWORD_INPUT_TITLE: "Пароль",
      SUBMIT_BUTTON_NAME: "Увійти",
      FORGET_PASSWORD_BUTTON_NAME: "Забули пароль",
      SUCESSS_MESSAGE: "Успіхів",
      PASSWORD_INPUT_ERROR_MESSAGE: "Введіть свій пароль!",
      USER_NAME_INPUT_ERROR_MESSAGE: "Введіть своє ім&#39;я користувача!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "Скасувати",
      DEPLOY_BUTTON_NAME: "Розгортати",
      DEPLOY_TITLE: "Розгортання -",
      MASS_DEPLOY_TITLE: "Масове розгортання",
      NO_SELECTED_DASHBOARDS_ERROR:
        "Виберіть принаймні одну інформаційну панель для розгортання!",
      NO_SELECTED_USERS_ERROR:
        "Виберіть принаймні одного користувача, до якого потрібно розгорнути!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "Усі інформаційні панелі",
    },
    DashboardsPage: {
      TITLE: "Сторінка інформаційних панелей",
      DESCRIPTION:
        "Швидкий підсумок стану, налаштування та постійні завдання адміністратора.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "Пошук за назвою інформаційної панелі",
    },
    DashboardTemplateCard: {
      TITLE: "Шаблони інформаційної панелі",
      DESCRIPTION_ONE:
        "Поділіться своїми ідеями щодо інформаційних панелей з будь-яким користувачем Nagios XI, поділившись файлами шаблонів інформаційної панелі.",
      DESCRIPTION_TWO:
        "Імпортуйте або експортуйте файли Nagios XI JSON і додайте миттєвої потужності на свої інформаційні панелі.",
    },
    DashboardTemplateImport: {
      TITLE: "Імпорт",
      DESCRIPTION:
        "Завантажте шаблон спільної з вами інформаційної панелі (JSON), який з’явиться у вашому списку «Усі інформаційні панелі».",
      SELECT_FILE_LABEL: "Виберіть Файл",
      SELECT_FILE_ERROR: "Виберіть файл для імпорту!",
      BROWSE_BUTTON_NAME: "Перегляньте",
      IMPORT_BUTTON_NAME: "Імпорт",
      INVALID_FILE_ERROR_TITLE: "Недійсний тип файлу",
      INVALID_FILE_ERROR_MESSAGE: "Файл шаблону повинен бути у форматі JSON!",
    },
    DashboardTemplateExport: {
      TITLE: "Експорт",
      DESCRIPTION:
        "Завантажте шаблон спільної з вами інформаційної панелі (JSON), який з’явиться у вашому списку «Усі інформаційні панелі».",
      SELECt_TEMPLATE_LABEL: "Виберіть Шаблон",
      SELECt_TEMPLATE_ERROR_MESSAGE: "Виберіть шаблон для експорту!",
      EXPORT_BUTTON_NAME: "Експорт",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "Видалити",
      POPCONFIRM_TEXT: "Ви впевнені, що хочете видалити інформаційну панель",
      POPCONFIRM_OK: "Гаразд",
      POPCONFIRM_NO: "Скасувати",
      CALL_REASON: "Видалити інформаційну панель",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Дублікат",
      POPCONFIRM_TEXT:
        "Ви впевнені, що хочете продублювати інформаційну панель",
      POPCONFIRM_OK: "Гаразд",
      POPCONFIRM_NO: "Скасувати",
      CALL_REASON: "Повторювана інформаційна панель",
    },
    EditButton: {
      TOOLTIP_TEXT: "Редагувати",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Створіть інформаційну панель",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Заголовок інформаційної панелі",
      DASHBOARD_INPUT_ERROR_MESSAGE: "Введіть заголовок інформаційної панелі!",
      SELECT_BACKGROUND_COLOR: "Виберіть Колір тла",
      PUBLIC_SHARE_CHECKBOX: "Публічний / спільний доступ",
      ALLOW_EDITING_CHECKBOX: "Дозволити редагування",
      DASHLETS: "Дашлети",
    },
    FavoritesList: {
      BUTTON_NAME: "Редагувати вибране",
    },
    FavoritesButton: {
      BUTTON_NAME: "Вибране",
    },
    ShareButton: {
      BUTTON_NAME: "Поділіться",
      TOOLTIP_TEXT: "Поділіться",
      NOTIFICATION_TEXT1: "Копіюється в буфер обміну",
      NOTIFICATION_TEXT2: "URL-адреса інформаційної панелі",
      DESCRIPTION: "Створена URL-адреса",
    },
    ProfileButton: {
      BUTTON_NAME: "Профіль",
    },
    Footer: {
      BUTTON_NAME: "Профіль",
    },
    NagiosStatusBox: {
      TITLE: "Статус Нагіос",
    },
    StatusContent: {
      BUTTON_NAME: "Очистити Не обробляється",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "Ви ще не стежите за хостом!",
      NOT_MONITORING_SERVICE_MESSAGE: "Ви ще не стежите за послугами!",
      ONCE_SETUP_HOST_MESSAGE: "Після налаштування хоста ви побачите дані тут.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "Після налаштування служби ви побачите дані тут.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "Зберегти інформаційну панель",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "Видалити",
      POPCONFIRM_TEXT: "Ви впевнені, що хочете видалити дашлет",
      POPCONFIRM_OK: "Гаразд",
      POPCONFIRM_NO: "Скасувати",
      CALL_REASON: "Видалити Дашлет",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "Редагувати",
      POPCONFIRM_TEXT: "Ви впевнені, що хочете відредагувати дашлет",
      POPCONFIRM_OK: "Гаразд",
      POPCONFIRM_NO: "Скасувати",
      CALL_REASON: "Редагувати Дашлет",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Дублікат",
      POPCONFIRM_TEXT: "Ви впевнені, що хочете продублювати дашлет",
      POPCONFIRM_OK: "Гаразд",
      POPCONFIRM_NO: "Скасувати",
      CALL_REASON: "Дублікат дашлета",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Назва дашлета",
      SELECT_BACKGROUND_COLOR: "Виберіть Колір тла",
      DASHLET_TYPE: "Тип дашлета",
      DASHLET_URL_INPUT: "URL-адреса дашлета",
      DASHLET_DIMENSIONS: "Розміри приладів",
      DASHLET_POSITION: "Положення дашлета",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE: "Введіть назву дашлета!",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "Введіть URL-адресу Dashlet!",
    },
  },
  fi: {
    LoginForm: {
      TITLE: "Nagios XI Kirjaudu",
      USER_NAME_INPUT_TITLE: "Käyttäjänimi",
      PASSWORD_INPUT_TITLE: "Salasana",
      SUBMIT_BUTTON_NAME: "Kirjaudu sisään",
      FORGET_PASSWORD_BUTTON_NAME: "Unohtuiko salasana",
      SUCESSS_MESSAGE: "Menestykset",
      PASSWORD_INPUT_ERROR_MESSAGE: "Anna salasanasi!",
      USER_NAME_INPUT_ERROR_MESSAGE: "Anna käyttäjänimesi!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "Peruuttaa",
      DEPLOY_BUTTON_NAME: "Ota käyttöön",
      DEPLOY_TITLE: "Ota käyttöön -",
      MASS_DEPLOY_TITLE: "Joukkotiedotus",
      NO_SELECTED_DASHBOARDS_ERROR:
        "Valitse ainakin yksi koontinäyttö käyttöönottoa varten!",
      NO_SELECTED_USERS_ERROR:
        "Valitse ainakin yksi käyttäjä, jolle haluat ottaa käyttöön!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "Kaikki koontinäytöt",
    },
    DashboardsPage: {
      TITLE: "Koontinäytöt Sivu",
      DESCRIPTION: "Nopea tilan yhteenveto, asennus ja ylläpitotehtävät.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "Hae kojelaudan nimen mukaan",
    },
    DashboardTemplateCard: {
      TITLE: "Hallintapaneelin mallit",
      DESCRIPTION_ONE:
        "Vaihda ideoita koontinäyttöihin minkä tahansa Nagios XI -käyttäjän kanssa jakamalla hallintapaneelin mallitiedostoja.",
      DESCRIPTION_TWO:
        "Tuo tai vie Nagios XI JSON -tiedostoja ja lisää välitöntä virtaa hallintapaneeleihisi.",
    },
    DashboardTemplateImport: {
      TITLE: "Tuonti",
      DESCRIPTION:
        "Lataa kanssasi jaettu hallintapaneelimalli (JSON), ja se näkyy Kaikki hallintapaneelit -luettelossasi.",
      SELECT_FILE_LABEL: "Valitse tiedosto",
      SELECT_FILE_ERROR: "Valitse tuotava tiedosto!",
      BROWSE_BUTTON_NAME: "Selaa",
      IMPORT_BUTTON_NAME: "Tuonti",
      INVALID_FILE_ERROR_TITLE: "Väärä tiedostotyyppi",
      INVALID_FILE_ERROR_MESSAGE: "Mallitiedoston on oltava JSON-muodossa!",
    },
    DashboardTemplateExport: {
      TITLE: "Viedä",
      DESCRIPTION:
        "Lataa kanssasi jaettu hallintapaneelimalli (JSON), ja se näkyy Kaikki hallintapaneelit -luettelossasi.",
      SELECt_TEMPLATE_LABEL: "Valitse malli",
      SELECt_TEMPLATE_ERROR_MESSAGE: "Valitse vietävä malli!",
      EXPORT_BUTTON_NAME: "Viedä",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "Poistaa",
      POPCONFIRM_TEXT: "Haluatko varmasti poistaa hallintapaneelin",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Peruuttaa",
      CALL_REASON: "Poista hallintapaneeli",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Kopioi",
      POPCONFIRM_TEXT: "Haluatko varmasti kopioida hallintapaneelin",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Peruuttaa",
      CALL_REASON: "Koontinäyttö",
    },
    EditButton: {
      TOOLTIP_TEXT: "Muokata",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Luo hallintapaneeli",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Hallintapaneelin otsikko",
      DASHBOARD_INPUT_ERROR_MESSAGE: "Anna kojelaudan otsikko!",
      SELECT_BACKGROUND_COLOR: "Valitse Taustaväri",
      PUBLIC_SHARE_CHECKBOX: "Julkinen / jaettava",
      ALLOW_EDITING_CHECKBOX: "Salli muokkaus",
      DASHLETS: "Dashletit",
    },
    FavoritesList: {
      BUTTON_NAME: "Muokkaa suosikkeja",
    },
    FavoritesButton: {
      BUTTON_NAME: "Suosikit",
    },
    ShareButton: {
      BUTTON_NAME: "Jaa",
      TOOLTIP_TEXT: "Jaa",
      NOTIFICATION_TEXT1: "Kopioitu leikepöydälle",
      NOTIFICATION_TEXT2: "hallintapaneelin URL-osoite",
      DESCRIPTION: "Luotu URL",
    },
    ProfileButton: {
      BUTTON_NAME: "Profiili",
    },
    Footer: {
      BUTTON_NAME: "Profiili",
    },
    NagiosStatusBox: {
      TITLE: "Nagios-tila",
    },
    StatusContent: {
      BUTTON_NAME: "Selkeä Käsittelemätön",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "Et vielä seuraa yhtään isäntää!",
      NOT_MONITORING_SERVICE_MESSAGE: "Et vielä seuraa mitään palveluita!",
      ONCE_SETUP_HOST_MESSAGE:
        "Kun olet määrittänyt isännän, näet tiedot täällä.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "Kun olet määrittänyt palvelun, näet tiedot täällä.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "Tallenna hallintapaneeli",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "Poistaa",
      POPCONFIRM_TEXT: "Haluatko varmasti poistaa viivan",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Peruuttaa",
      CALL_REASON: "Poista Dashlet",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "Muokata",
      POPCONFIRM_TEXT: "Haluatko varmasti muokata viivaa",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Peruuttaa",
      CALL_REASON: "Muokkaa viivaa",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Kopioi",
      POPCONFIRM_TEXT: "Haluatko varmasti kopioida viivaa",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Peruuttaa",
      CALL_REASON: "Duplicate Dashlet",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Dashlet-otsikko",
      SELECT_BACKGROUND_COLOR: "Valitse Taustaväri",
      DASHLET_TYPE: "Dashlet-tyyppi",
      DASHLET_URL_INPUT: "Dashlet-URL-osoite",
      DASHLET_DIMENSIONS: "Dashletin mitat",
      DASHLET_POSITION: "Viivan sijainti",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE: "Anna Dashlet-otsikko!",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "Anna Dashlet-URL-osoite!",
    },
  },
  en: {
    LoginForm: {
      TITLE: "Nagios XI Log In",
      USER_NAME_INPUT_TITLE: "User Name",
      PASSWORD_INPUT_TITLE: "Password",
      SUBMIT_BUTTON_NAME: "Login",
      FORGET_PASSWORD_BUTTON_NAME: "Forgot Password",
      SUCESSS_MESSAGE: "Sucesss",
      PASSWORD_INPUT_ERROR_MESSAGE: "Please input your password!",
      USER_NAME_INPUT_ERROR_MESSAGE: "Please input your user name!",
    },
    DeployDialogBox: {
      CANCEL_BUTTON_NAME: "Cancel",
      DEPLOY_BUTTON_NAME: "Deploy",
      DEPLOY_TITLE: "Deploy -",
      MASS_DEPLOY_TITLE: "Mass Deploy",
      NO_SELECTED_DASHBOARDS_ERROR:
        "Please pick at least one dashboard to deploy!",
      NO_SELECTED_USERS_ERROR: "Please pick at least one user to deploy to!",
    },
    AllDashboardsCard: {
      CARD_TITLE: "All Dashboards",
    },
    DashboardsPage: {
      TITLE: "Dashboards Page",
      DESCRIPTION: "A quick status summary, and setup, and onging admin tasks.",
    },
    DashboardSearchBox: {
      SEARCH_INPUT_PLACEHOLDER: "Search by Dashboard name",
    },
    DashboardTemplateCard: {
      TITLE: "Dashboard Templates",
      DESCRIPTION_ONE:
        "Exchange your ideas for dashboards with any Nagios XI user by sharing dashboard template files.",
      DESCRIPTION_TWO:
        "Import or export Nagios XI JSON files and add instant power to your dashboards.",
    },
    DashboardTemplateImport: {
      TITLE: "Import",
      DESCRIPTION:
        "Upload a Dashboard Template (JSON) that’s been shared with you, and it will show up in your “All Dashboards” list.",
      SELECT_FILE_LABEL: "Select File",
      SELECT_FILE_ERROR: "Please pick file to import!",
      BROWSE_BUTTON_NAME: "Browse",
      IMPORT_BUTTON_NAME: "Import",
      INVALID_FILE_ERROR_TITLE: "Invalid File Type",
      INVALID_FILE_ERROR_MESSAGE: "Template file needs to be in JSON format!",
    },
    DashboardTemplateExport: {
      TITLE: "Export",
      DESCRIPTION:
        "Upload a Dashboard Template (JSON) that’s been shared with you, and it will show up in your “All Dashboards” list.",
      SELECt_TEMPLATE_LABEL: "Select Template",
      SELECt_TEMPLATE_ERROR_MESSAGE: "Please pick template to export!",
      EXPORT_BUTTON_NAME: "Export",
    },
    Deletebutton: {
      TOOLTIP_TEXT: "Delete",
      POPCONFIRM_TEXT: "Are you sure you want to delete dashboard",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Cancel",
      CALL_REASON: "Delete Dashboard",
    },
    CopyButton: {
      TOOLTIP_TEXT: "Duplicate",
      POPCONFIRM_TEXT: "Are you sure you want to duplicate dashboard",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Cancel",
      CALL_REASON: "Duplicate Dashboard",
    },
    EditButton: {
      TOOLTIP_TEXT: "Edit",
    },
    CreateDashboardCard: {
      CARD_TITLE: "Create a Dashboard",
    },
    CreateDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Dashboard Title",
      DASHBOARD_INPUT_ERROR_MESSAGE: "Please input Dashboard Title!",
      SELECT_BACKGROUND_COLOR: "Select Background Color",
      PUBLIC_SHARE_CHECKBOX: "Public/Shareable",
      ALLOW_EDITING_CHECKBOX: "Allow Editing",
      DASHLETS: "Dashlets",
    },
    EditDashboardForm: {
      DASHBOARD_TITLE_INPUT: "Dashboard Title",
      DASHBOARD_INPUT_ERROR_MESSAGE: "Please input Dashboard Title!",
      SELECT_BACKGROUND_COLOR: "Select Background Color",
      PUBLIC_SHARE_CHECKBOX: "Public/Shareable",
      ALLOW_EDITING_CHECKBOX: "Allow Editing",
      DASHLETS: "Dashlets",
    },
    FavoritesList: {
      BUTTON_NAME: "Edit Favorites",
    },
    FavoritesButton: {
      BUTTON_NAME: "Favorites",
    },
    ShareButton: {
      BUTTON_NAME: "Share",
      TOOLTIP_TEXT: "Share",
      NOTIFICATION_TEXT1: "Copyied to clipboard",
      NOTIFICATION_TEXT2: "dashboard URL",
      DESCRIPTION: "Generatated URL",
    },
    ProfileButton: {
      BUTTON_NAME: "Profile",
    },
    Footer: {
      BUTTON_NAME: "Profile",
    },
    NagiosStatusBox: {
      TITLE: "Nagios Status",
    },
    StatusContent: {
      BUTTON_NAME: "Clear Unhandled",
    },
    StatusRowCard: {
      NOT_MONITORING_HOST_MESSAGE: "You aren't monitoring any hosts yet!",
      NOT_MONITORING_SERVICE_MESSAGE: "You aren't monitoring any services yet!",
      ONCE_SETUP_HOST_MESSAGE:
        "Once you've setup a host, you will see data here.",
      ONCE_SETUP_SERVICE_MESSAGE:
        "Once you've setup a service, you will see data here.",
    },
    DashletSaveButton: {
      BUTTON_NAME: "Save Dashboard",
    },
    DashletDeleteButton: {
      TOOLTIP_TEXT: "Delete",
      POPCONFIRM_TEXT: "Are you sure you want to delete dashlet",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Cancel",
      CALL_REASON: "Delete Dashlet",
    },
    DashletEditButton: {
      TOOLTIP_TEXT: "Edit",
      POPCONFIRM_TEXT: "Are you sure you want to edit dashlet",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Cancel",
      CALL_REASON: "Edit Dashlet",
    },
    DashletDuplicateButton: {
      TOOLTIP_TEXT: "Duplicate",
      POPCONFIRM_TEXT: "Are you sure you want to duplicate dashlet",
      POPCONFIRM_OK: "Ok",
      POPCONFIRM_NO: "Cancel",
      CALL_REASON: "Duplicate Dashlet",
    },
    EditDashletForm: {
      DASHLET_TITLE_INPUT: "Dashlet Title",
      SELECT_BACKGROUND_COLOR: "Select Background Color",
      DASHLET_TYPE: "Dashlet Type",
      DASHLET_URL_INPUT: "Dashlet URL",
      DASHLET_DIMENSIONS: "Dashlet Dimensions",
      DASHLET_POSITION: "Dashlet Position",
      DASHLET_INPUT_TITLE_ERROR_MESSAGE: "Please Input Dashlet Title!",
      DASHLET_INPUT_URL_ERROR_MESSAGE: "Please Input Dashlet URL!",
    },
  },
};

export default languageObject;
