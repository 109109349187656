import { useQuery } from "react-query";
import { HOST_OVERVIEW_URL } from "../../../api.config";
import { fetchData } from "../../../utils/axiosHelpers";

export const KEY = "Fetch Hosts Overview";

const fetchHostsOverview = async () => fetchData(HOST_OVERVIEW_URL);

export const preFetchHostsOverview = (queryClient) =>
  queryClient.prefetchQuery(KEY, fetchHostsOverview);

export default function useHostsOverview(options?: any) {
  return useQuery(KEY, fetchHostsOverview, options);
}
