import imgSrc from "../../Molecules/CommonScreens/thumbnail.png";

export type csOptions = {
  title: string;
  image: string;
  description: string;
  sub?: string;
  added: boolean;
  id: number;
};

export type dbOptions = {
  name: string;
  dashboardId: number;
  added: boolean;
};

export type oiOptions = {
  title: string;
  desc: string;
  directLink: boolean;
  url: string;
};

export type AddScreenMock = {
  CommonScreen: csOptions[];
  myDashboards: dbOptions[];
  otherIdeas: oiOptions[];
};

export const AddAScreenMockData: AddScreenMock = {
  CommonScreen: [
    {
      id: 1,
      title: "Birdseye",
      image: imgSrc,
      description:
        "Start monitoring devices, servers, applications, services and more in mintes",
      sub: "Call Centers • Energy Management",
      added: true,
    },
    {
      id: 2,
      title: "Operations Center",
      image: imgSrc,
      description:
        "See all host problems, show all states, and up and down hosts",
      added: false,
    },
    {
      id: 3,
      title: "Operations Screen",
      image: imgSrc,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam magna lectus, euismod quis mattis quis, fermentum id odio.",
      sub: "Aliquam Magna • Germentum Polio",
      added: false,
    },
  ],
  myDashboards: [
    { dashboardId: 1, name: "Chalvey", added: false },
    { dashboardId: 1, name: "Salt Hill", added: true },
    { dashboardId: 1, name: "Clewer Green", added: false },
  ],
  otherIdeas: [
    {
      title: "Monitoring Applications",
      desc: "Add in URLs from your existing monitoring software",
      directLink: false,
      url: "#",
    },
    {
      title: "NOAA Weather Monitors",
      desc:
        "Adding in a wether service dashboard allows some administrators to track network outages, as well as physical service outages. Put them in one place to compare and get a better understanding.",
      directLink: true,
      url: "#",
    },
    {
      title: "Access Point Dashboards",
      desc: "Seeing the health of your WLAN can be a helpful tool to see",
      directLink: false,
      url: "#",
    },
    {
      title: "Unseen Fourth Option",
      desc:
        "In the wireframe there is a fourth option but it is not visible due to being off screen, so lorum ipsum...",
      directLink: true,
      url: "#",
    },
  ],
};
