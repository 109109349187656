import React, { useState } from "react";
import { Space, Button, Card, Select } from "antd";
import { ColumnType } from "antd/lib/table";
import styled from "styled-components";
import { sortString } from "../../utils/formatUtils";
import useReportPageEvents from "./hooks/useReportPageEvents";
import useReports from "./hooks/useReports";
import useReportTypes from "./hooks/useReportTypes";
import { Report, ReportType } from "./ReportsPageTypes";
import { useGlobalModal } from "../../context/GlobalModalContext";
import useCreateFavorite from "../Frame/hooks/useCreateFavorite";
import { useRouteMatch } from "react-router-dom";
import { useCurrentReport } from "./context/CurrentReportContext";
import { useEditReportContext } from "./context/EditReportContent";
import { ProgressBar } from "../Shared";
import { useFavoriteReports } from "./hooks/useFavoriteReports";
import { find } from "lodash";
import { IconArrowRight } from "../Shared/Atoms/FontIcons/FontIcons";
import { TableIcons } from "../Shared/Molecules/TableIcons";
import CustomTable from "../Shared/Organisms/CustomTable/CustomTable";
import MyReportsFilters from "./Molecules/MyReportsFilters/MyReportsFilters";
import ReportTypeCard from "./Molecules/ReportTypeCard/ReportTypeCard";
import { shareRecipients } from "./Organisms/ShareReportModal/ShareReportMockData";
import ShareReportModal from "./Organisms/ShareReportModal/ShareReportModal";
import PageHeader from "../Shared/Organisms/PageHeader/PageHeader";
const { Option } = Select;

const StyledBody = styled.div`
  padding: 20px;
`;
const StyledButton = styled(Button)`
  height: 40px;
`;
const FlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ReportsPage = () => {
  //Header Functions
  const { url } = useRouteMatch<any>();
  const { setReportToEdit } = useEditReportContext();
  const [myReports, setMyReports] = useState(!url.includes("types"));
  const [isScheduled, setIsScheduled] = useState(url.includes("scheduled"));

  const [filterOption, setFilterOption] = useState(
    url.includes("scheduled")
      ? { name: "Scheduled", params: "true" }
      : { name: "", params: "" }
  );

  const { data: reports, isLoading: reportsIsLoading } =
    useReports(filterOption);
  const { data: favoriteReports, isLoading: favoriteReportsIsLoading } =
    useFavoriteReports();
  const { data: reportTypes, isLoading: reportTypesIsLoading } =
    useReportTypes();
  const { toggleCreateAReportModalOpen, toggleScheduleReportModalOpen } =
    useGlobalModal();
  const { mutate: createFavorite } = useCreateFavorite();

  const {
    onDeleteButtonClick,
    onRunReportButtonClick,
    onViewButtonClick,
    onToggleScheduledClick,
    onDownloadAsPDF,
    onEmailPdf,
    onShareToTeamsMyReports,
  } = useReportPageEvents();
  const { setCurrentReport } = useCurrentReport();

  //Filter Functions
  const filterSearch = (e) => setFilterOption({ name: "Search", params: e });
  const filterSearchOnChange = (e) =>
    setFilterOption({ name: "Search", params: e.target.value });
  const filterType = (e) =>
    setFilterOption(
      e === "" ? { name: "", params: "" } : { name: "Type", params: e }
    );
  const filterRange = (e) =>
    setFilterOption(
      e === null ? { name: "", params: "" } : { name: "DateRange", params: e }
    );

  const filterCustom = (e) =>
    setFilterOption({ name: "Custom", params: e.target.checked });
  const filterSchedule = (e) => {
    setFilterOption({ name: "Scheduled", params: e.target.checked });
    setIsScheduled((prev) => !prev);
  };

  //Report Card Functions
  const previewClicked = (e) =>
    console.log("Preview '" + e + "' function goes here.");

  const runClicked = (e) => {
    setCurrentReport(e.title);
    toggleCreateAReportModalOpen();
  };

  const scheduleClicked = (e) => {
    setCurrentReport(e.title);
    toggleScheduleReportModalOpen();
  };

  //Share Report Modal Functions
  const [reportModalVisible, setReportModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  const handleChange = (value) => console.log("Add recipient(s) " + value);

  const selectRow = (record) => {
    const tempRowKeys = [...selectedRowKeys];
    tempRowKeys.indexOf(record.key) >= 0
      ? tempRowKeys.splice(tempRowKeys.indexOf(record.key), 1)
      : tempRowKeys.push(record.key);
    setSelectedRowKeys(tempRowKeys);
  };

  const onSelectedRowKeysChange = (selectedRowKeys) =>
    setSelectedRowKeys(selectedRowKeys);

  const rowSelection = { selectedRowKeys, onChange: onSelectedRowKeysChange };

  //Report Table Functions

  const clickRun = (record) => {
    onRunReportButtonClick(record);
  };

  const clickEdit = (id) => {
    setReportToEdit(reports[id - 1]);
    toggleCreateAReportModalOpen();
  };

  const clickDelete = (id) => onDeleteButtonClick(reports[id - 1]);

  // Report Table Columns
  const columns: ColumnType<Report>[] = [
    {
      title: "View/Run",
      dataIndex: "id",
      key: "id",
      render: (id: number, record) => (
        <div>
          <Button
            type="link"
            onClick={() => onViewButtonClick(record)}
            disabled={record.running}
          >
            View
          </Button>
          <Button
            type="link"
            onClick={() => clickRun(record)}
            disabled={record.running}
          >
            Run
          </Button>
        </div>
      ),
    },
    {
      title: "Report Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => sortString(a.name, b.name),
    },
    {
      title: "Custom Name",
      dataIndex: "name",
      key: "customName",
      sorter: (a, b) => sortString(a.name, b.name),
    },
    {
      title: "Time/Date Run",
      dataIndex: "last_run_date",
      key: "last_run_date",
      render: (last_run_date, record) => {
        return !record.running ? (
          <p>{last_run_date}</p>
        ) : (
          {
            children: <ProgressBar whatToProcess="report" />,
            props: {
              colSpan: 3,
            },
          }
        );
      },
    },
    {
      title: "Scheduled",
      dataIndex: "schedulable",
      key: "schedulable",
      render: (schedulable: number, record) =>
        !record.running ? (
          schedulable ? (
            <Select
              onChange={(value) => onToggleScheduledClick(record, value)}
              defaultValue={record.is_paused ? "Paused" : "Scheduled"}
            >
              {record.is_paused ? (
                <>
                  <Option value="schedule">Schedule</Option>
                  <Option value="pause">Paused</Option>
                  <Option value="unschedule">Unschedule</Option>
                </>
              ) : (
                <>
                  <Option value="scheduled">Scheduled</Option>
                  <Option value="pause">Pause</Option>
                  <Option value="unschedule">Unschedule</Option>
                </>
              )}
            </Select>
          ) : (
            <Button
              type="link"
              onClick={() => onToggleScheduledClick(record, "schedule")}
            >
              Schedule Report
            </Button>
          )
        ) : (
          {
            props: {
              colSpan: 0,
              rowSpan: 0,
            },
          }
        ),
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (key: number, record: Report) => {
        return !record.running ? (
          <TableIcons
            icons={[
              { iconType: "share", onClick: () => setReportModalVisible(true) },
              { iconType: "edit", onClick: () => clickEdit(key) },
              { iconType: "delete", onClick: () => clickDelete(key) },
            ]}
          />
        ) : (
          {
            props: {
              colSpan: 0,
              rowSpan: 0,
            },
          }
        );
      },
    },
  ];

  const columns2: ColumnType<any>[] = [
    { dataIndex: "name", key: "name" },
    {
      dataIndex: "url",
      render: (url: string, report) => (
        <Button
          onClick={() => clickRun(report)}
          disabled={find(reports, { name: report.name })?.running}
        >
          <IconArrowRight size="1.5em" /> Run
        </Button>
      ),
    },
  ];

  if (reportsIsLoading || reportTypesIsLoading || favoriteReportsIsLoading) {
    return <div>Is Loading</div>;
  }

  return (
    <StyledBody>
      <PageHeader
        title={!myReports ? "Browse Report Types" : "Reports"}
        favoriteClicked={() =>
          createFavorite({ title: "Reports", url: "/reports" })
        }
        sub={
          !myReports
            ? "Run a report to [ lorem ipsum dit solor vitorum ]"
            : "Share, setup and run reports."
        }
        buttons={
          <Space style={{ marginRight: "10px" }}>
            {!myReports ? (
              <StyledButton type="default" onClick={() => setMyReports(true)}>
                My Reports
              </StyledButton>
            ) : (
              <StyledButton type="default" onClick={() => setMyReports(false)}>
                Browse Report Types
              </StyledButton>
            )}
            <StyledButton
              type="default"
              onClick={() => toggleCreateAReportModalOpen()}
            >
              Create a Report
            </StyledButton>
          </Space>
        }
      />
      {!myReports ? (
        <FlexBox>
          {reportTypes.map((report: ReportType) => (
            <ReportTypeCard
              key={report.type_title}
              image={""}
              title={report.type_title}
              description={report.description}
              previewClicked={() => previewClicked(report)}
              runClicked={() => runClicked(report)}
              scheduleClicked={() => scheduleClicked(report)}
            />
          ))}
        </FlexBox>
      ) : (
        <>
          <Card>
            <h3>My Reports</h3>
            <MyReportsFilters
              isScheduled={isScheduled}
              filterSearch={filterSearch}
              filterSearchOnChange={filterSearchOnChange}
              filterRange={filterRange}
              filterType={filterType}
              filterCustom={filterCustom}
              filterSchedule={filterSchedule}
            />
            <CustomTable
              rowKey={"id"}
              dataSource={reports}
              columns={columns}
              pagination={{ position: ["bottomLeft"] }}
            />
          </Card>
          <Card style={{ width: "500px", margin: "20px 0 40px" }}>
            <h3>Popular Reports</h3>
            <CustomTable
              dataSource={favoriteReports}
              columns={columns2}
              pagination={false}
              showHeader={false}
            />
          </Card>
        </>
      )}
      <ShareReportModal
        visible={reportModalVisible}
        close={() => setReportModalVisible(false)}
        downloadReport={(record) => onDownloadAsPDF(record)}
        emailReport={(record) => onEmailPdf(record)}
        shareReport={(record) => onShareToTeamsMyReports(record)}
        recipientsChange={handleChange}
        dataSource={shareRecipients}
        rowSelection={rowSelection}
        selectRow={selectRow}
      />
    </StyledBody>
  );
};

export default ReportsPage;
