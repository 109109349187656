import { TOOLS_URL } from "../../api.config";
import {
  createDeleteMock,
  createGetMock,
  createPostMock,
  createPutMock,
} from "../../mockServer/createMockHandler";
import { getRandomNumberInRange } from "../../utils/logic";

export const CREATE_TOOL_MESSAGE = "Successfully create tool 1234";
export const DELETE_TOOL_SUCCESS_MESSAGE = "Successfully deleted tool 1234";
export const EDIT_TOOL_MESSAGE = "Successfully edited tool 1234";

const createToolObject = (tool_id: number, name: string, url: string) => ({
  tool_id,
  name,
  url,
  shared: getRandomNumberInRange(2) === 1,
});

export const toolList = [
  createToolObject(1, "Cart and Checkout", "http://fake_tool.com"),
  createToolObject(2, "My News North America", "http://fake_tool_2.com"),
  createToolObject(3, "The Tool", "http://fake_tool_3.com"),
];

export const mocks = [
  createGetMock(TOOLS_URL, 200, {
    tool: toolList,
  }),
  createPostMock(TOOLS_URL, 200, {
    message: CREATE_TOOL_MESSAGE,
  }),

  ...toolList
    .map((tool) => {
      return [
        createDeleteMock(`${TOOLS_URL}/${tool.tool_id}`, 200, {
          message: DELETE_TOOL_SUCCESS_MESSAGE,
        }),
        createPutMock(`${TOOLS_URL}/${tool.tool_id}`, 200, {
          message: EDIT_TOOL_MESSAGE,
        }),

        createGetMock(`${TOOLS_URL}/${tool.tool_id}`, 200, {
          tool,
        }),
      ];
    })
    .flat(),
];
