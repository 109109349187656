import React from "react";

export default function ServersSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 55 55"
    >
      <g>
        <g>
          <path
            fill="#16c79b"
            d="M0 18C0 8.059 8.059 0 18 0h19c9.941 0 18 8.059 18 18v19c0 9.941-8.059 18-18 18H18C8.059 55 0 46.941 0 37z"
          />
        </g>
        <g>
          <g>
            <g>
              <path d="M16 19h2v2h-2z" />
            </g>
            <g>
              <path d="M20 19h14v2H20z" />
            </g>
            <g>
              <path d="M16 27h2v2h-2z" />
            </g>
            <g>
              <path d="M20 27h14v2H20z" />
            </g>
            <g>
              <path d="M14 39v-6h28v6zm0-14h28v6H14zm0-8h28v6H14zm28-2H14a2 2 0 0 0-2 2v22a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V17a2 2 0 0 0-2-2z" />
            </g>
            <g>
              <path d="M16 35h2v2h-2z" />
            </g>
            <g>
              <path d="M20 35h14v2H20z" />
            </g>
          </g>
          <g />
        </g>
      </g>
    </svg>
  );
}
