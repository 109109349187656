import {
  Button,
  Card as AntCard,
  Col,
  Modal,
  PageHeader,
  Row,
  Input,
} from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

import { useState } from "react";
import { chunk, find } from "lodash";
import styled from "styled-components";
import { Dashboard, Dashlet } from "../../../DashboardPage/DashboardPageTypes";
import { IconCheck } from "../../../Shared/Atoms/FontIcons/FontIcons";
import useDashletPageEvents from "../../hooks/useDashletPageEvents";
import { useToggle } from "../../../../hooks";
import { DashletModal } from "../../Molecules/DashletModal";
import SwipeableViews from "react-swipeable-views";
import { virtualize } from "react-swipeable-views-utils";
import { mod } from "react-swipeable-views-core";
import {
  dashletNameObject,
  dashletTypeList,
} from "../../DashletViewPageConstants";
import {
  createDashletObject,
  createDimensionObject,
  createPositionObject,
} from "../../../DashboardPage/DashboardsPageMock";
import useDashletObject from "../../hooks/useDashletObject";

const VirtualizeSwipeableViews = virtualize(SwipeableViews);
const { Search } = Input;

type CardProps = {
  color: string;
};

type DashletWrapperProps = {
  dashlet: Dashlet;
};

type Props = {
  dashboard: Dashboard;
  toggleOpen: Function;
  open: boolean;
};

const Card = styled(AntCard)`
  background: ${({ color }: CardProps) => color};
  height: inherit;
  width: inherit;
`;

const DashletWrapper = ({ dashlet }: DashletWrapperProps) => {
  const dashletTypeObject = useDashletObject(true);

  return dashletTypeObject[dashlet.type];
};

const DashletPreview = ({
  dashlet,
  dashboard,
  isDemo,
  initalData,
}): JSX.Element => {
  const dashletTypeObject = useDashletObject(true);

  const { onDeleteDashletButtonClick, onCreateDashletButtonClick } =
    useDashletPageEvents(dashboard.id);

  const { open: dashletModalOpen, toggleOpen: toggleDashletModalOpen } =
    useToggle();

  const alreadyInDashboard = find(dashboard.dashlets, { id: dashlet.id });

  return (
    <div>
      <Card
        title={isDemo ? dashletNameObject[dashlet.type] : dashlet.name}
        color={dashlet.color}
        extra={
          <Button
            type={alreadyInDashboard && !isDemo ? "primary" : "default"}
            onClick={() =>
              isDemo
                ? toggleDashletModalOpen()
                : alreadyInDashboard
                ? onDeleteDashletButtonClick(dashlet)
                : onCreateDashletButtonClick(dashlet)
            }
          >
            {alreadyInDashboard && !isDemo ? (
              <>
                <IconCheck size="1.4em" /> Added
              </>
            ) : (
              "Add to Dashboard"
            )}
          </Button>
        }
      >
        <Row style={{ height: `750px`, width: "inherit" }}>
          {isDemo ? (
            dashletTypeObject[dashlet.type]
          ) : (
            <DashletWrapper dashlet={dashlet} />
          )}
        </Row>
      </Card>
      <DashletModal
        open={dashletModalOpen}
        toggleOpen={toggleDashletModalOpen}
        dashlet={dashlet}
        dashboard={dashboard}
        editDashlet={false}
      />
    </div>
  );
};

export default function CreateDashletModal({
  dashboard,
  toggleOpen,
  open,
}: Props) {
  const [index, setIndex] = useState(0);
  const [filterOption, setFilterOption] = useState({ name: "", params: "" });
  const [toggleMode, setToggleMOde] = useState(true);

  const demoDashlets = dashletTypeList
    .map((dashlet) =>
      createDashletObject(
        dashlet,
        dashlet,
        createPositionObject(1, 1),
        createDimensionObject(1, 1),
        "",
        "white",
        []
      )
    )
    .filter(({ name }) => name.includes(filterOption.params));

  const slideRenderer = (params: any) => {
    const { index, key } = params;

    const data = chunk([...(toggleMode ? demoDashlets : [])], 3).map(
      (dashletList: any, i) => {
        return (
          <Row key={key} justify="space-around">
            {dashletList.map((dashlet: any, i) => (
              <Col span={7} key={i}>
                <DashletPreview
                  dashlet={dashlet}
                  dashboard={dashboard}
                  isDemo={toggleMode}
                  initalData={[]}
                />
              </Col>
            ))}
          </Row>
        );
      }
    );

    return data[mod(index, data.length)];
  };

  const onSearch = (value: any) => {
    setFilterOption({ name: "Search", params: value });
  };

  return (
    <Modal
      visible={open}
      width={1500}
      onCancel={() => toggleOpen()}
      footer={<Button onClick={() => toggleOpen()}>Close</Button>}
    >
      <PageHeader
        title={`Add ${
          toggleMode ? "Dashlet Template" : "Existing Dashlet"
        } to ${dashboard?.name}`}
        extra={[
          <Row>
            <Col span={12}>
              <Search
                placeholder="Search by Dashlet"
                data-testid="dashletSearch"
                onSearch={onSearch}
                onChange={(e) => onSearch(e.target.value)}
              />
            </Col>
            <Col span={10}>
              <Button onClick={() => setToggleMOde((prev) => !prev)}>
                {toggleMode ? "Add Existing Dashlet" : "Add Dashlet Templates"}
              </Button>
            </Col>
          </Row>,
        ]}
      />

      <Row>
        <Col span={1} onClick={() => setIndex((prev) => prev - 1)}>
          <Button icon={<ArrowLeftOutlined />} />
        </Col>
        <Col span={22}>
          <VirtualizeSwipeableViews
            index={index}
            enableMouseEvents
            slideRenderer={slideRenderer}
          />
        </Col>

        <Col span={1} onClick={() => setIndex((prev) => prev + 1)}>
          <Button icon={<ArrowRightOutlined />} />
        </Col>
      </Row>
    </Modal>
  );
}
