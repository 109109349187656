import { ResponsiveNetwork } from "@nivo/network";
import { Skeleton } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React from "react";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import { useQuery } from "react-query";
import { fetchData } from "../../../../utils/axiosHelpers";
import { NETWORK_DASHLET_URL } from "../../../../api.config";
import { dashletMockData } from "../../DashletMockData";

const covertToNodes = (data) => {
  const { id, level, children, ...rest } = data;
  const colorObject = {
    up: "#B1ED5B",
    pending: "#BDBFC2",
    down: "#FF0D0D",
    unreachable: "#343434",
  };

  const createChildrenNodes = (children) =>
    children.map((node) => {
      const { id, level, children, ...rest } = node;
      return [
        {
          id,
          depth: level + 1,
          radius: 8,
          color: colorObject[node.status],
          ...rest,
        },
        ...createChildrenNodes(children),
      ];
    });

  const childrenNodes = createChildrenNodes(children).flat(Infinity);

  const createChildrenLinks = (source, level, children) =>
    children.map((node) => {
      const { id: target, children } = node;

      return [
        {
          source,
          target,
          distance: 50,
        },
        ...createChildrenLinks(target, node.level, children),
      ];
    });

  const childrenLinks = createChildrenLinks(id, level, children).flat(Infinity);

  return {
    nodes: [
      {
        id,
        radius: 15,
        depth: level + 1,
        color: colorObject[data.status],
        ...rest,
      },
      ...childrenNodes,
    ],
    links: childrenLinks,
  };
};
export default function NetworkDashlet({ demo, dashletId }) {
  const theme = useMasterTheme().theme;
  const { data, isLoading } = useQuery(
    ["Fetch Network Dashlet Data", dashletId],
    async () => fetchData(NETWORK_DASHLET_URL),
    demo
      ? {
          enabled: false,
        }
      : {
          select: (data) => covertToNodes(data),
        }
  );

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <ResponsiveNetwork
      isInteractive
      theme={{
        textColor: theme.typographyPrimaryColor,
        tooltip: {
          container: {
            background: theme.cardBackgroundColor,
          },
        },
      }}
      tooltip={(node) => {
        const { id, status, status_text } = node;
        return (
          <div>
            <div>
              <strong>{id}</strong>
              <br />
              <Avatar />
              <br />
              Status: {status}
              <br />
              Info: {status_text}
            </div>
          </div>
        );
      }}
      layers={["links", "nodes"]}
      nodes={
        demo ? covertToNodes(dashletMockData["network"]).nodes : data?.nodes
      }
      links={
        demo ? covertToNodes(dashletMockData["network"]).links : data?.links
      }
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      repulsivity={50}
      iterations={60}
      nodeColor={(e) => e.color}
      linkColor={(e) => (e.target.color === "#FF0D0D" ? "#FF0D0D" : "gray")}
      nodeBorderWidth={1}
      nodeBorderColor={{ from: "color", modifiers: [["darker", 0.8]] }}
      linkThickness={(e) => 1}
      motionStiffness={160}
      motionDamping={12}
    />
  );
}
