import React, { useState } from "react";
import { Modal, Button, Select } from "antd";
import {
  IconDownload,
  IconEmail,
  IconShare,
  IconPdfDownload,
  IconPdfEmail,
  IconPdfShare,
} from "../../../Shared/Atoms/FontIcons/FontIcons";
import styled from "styled-components";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import CustomTable from "../../../Shared/Organisms/CustomTable/CustomTable";
import IconButton from "../../../Shared/Molecules/IconButton/IconButton";
const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding-top: 0px;
  }
`;

const MainBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  h4 {
    font-size: 14px;
    width: 100%;
  }
  > div {
    width: 100%;
    hr {
      margin: 10px -24px 20px;
      border-width: 1px;
      border-style: solid;
      opacity: 15%;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      width: 12px;
      transform: translate(-50%, -50%) scale(0.5);
    }
    .ant-checkbox-inner {
      border-radius: 2px;
    }
    h5 {
      font-size: 12px;
      > span {
        color: ${themeVariables.colorGrayLight2};
        font-style: italic;
      }
    }
  }
`;

interface DataType {
  key: number;
  name: string;
  username: string;
}

export type shareModalProps = {
  visible: boolean;
  close: () => void;
  downloadReport: (record) => void;
  emailReport: (record) => void;
  shareReport: (record) => void;
  recipientsChange: (value: any) => void;
  dataSource: DataType[];
  rowSelection: any;
  selectRow: (record: any) => void;
};

const columns = [
  {
    title: "Name (username)",
    render: (record) => `${record.name} (${record.username})`,
  },
];

const ShareReportModal = (props: shareModalProps) => {
  const [method, setMethod] = useState("download");
  const theme = useMasterTheme().theme;
  const children = [];
  return (
    <StyledModal
      title="Share a Report"
      width="468px"
      visible={props.visible}
      onCancel={props.close}
      footer={
        (method === "download" && (
          <Button type="primary" onClick={props.downloadReport}>
            <IconDownload size="1.5em" /> Download
          </Button>
        )) ||
        (method === "email" && (
          <Button type="primary" onClick={props.emailReport}>
            <IconEmail size="1.5em" /> Send
          </Button>
        )) ||
        (method === "share" && (
          <Button type="primary" onClick={props.shareReport}>
            <IconShare size="1.5em" /> Share
          </Button>
        ))
      }
    >
      <MainBody>
        <h4>Select Sharing Method</h4>
        <IconButton
          color1={theme.iconLightGreenBG}
          icon={<IconPdfDownload color={themeVariables.colorGreenDark} />}
          label="Download PDF"
          onClick={() => setMethod("download")}
        />
        <IconButton
          color1={theme.iconLightBlueBG}
          icon={<IconPdfEmail color={themeVariables.colorBlueDark} />}
          label="Email PDF"
          onClick={() => setMethod("email")}
        />
        <IconButton
          color1={theme.iconLightYellowBG}
          icon={<IconPdfShare color={themeVariables.colorYellowDark} />}
          label="Share to Team Member's 'My Reports'"
          onClick={() => setMethod("share")}
        />
        {(method === "email" && (
          <div>
            <h5>
              Recipients <span>(Comma-seperated list of email addresses)</span>
            </h5>
            <Select
              mode="tags"
              style={{ width: "100%" }}
              tokenSeparators={[","]}
              onChange={props.recipientsChange}
            >
              {children}
            </Select>
          </div>
        )) ||
          (method === "share" && (
            <div>
              <hr />
              <h4>Select Recipients</h4>
              <CustomTable
                rowSelection={props.rowSelection}
                onRow={(record) => ({ onClick: () => props.selectRow(record) })}
                columns={columns}
                dataSource={props.dataSource}
                pagination={false}
                scroll={{ y: 180 }}
              />
            </div>
          ))}
      </MainBody>
    </StyledModal>
  );
};

export default ShareReportModal;
