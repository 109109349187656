import { find } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { DUPLICATE_DASHLET_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { postData } from "../../../utils/axiosHelpers";
import { Dashboard } from "../../DashboardPage/DashboardPageTypes";
import {
  FETCH_DASHBOARD_KEY,
  KEY,
} from "../../DashboardPage/hooks/useDashboards";

export default function useDuplicateDashlet() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();
  const queryClient = useQueryClient();

  return useMutation(
    ({ dashletToDuplicate, dashboardToCopyToId }) =>
      postData(`${DUPLICATE_DASHLET_URL}/${dashletToDuplicate.dashlet_id}`, {
        dashboard_id: dashboardToCopyToId,
      }),
    {
      onMutate: async ({ dashletToDuplicate, dashboardToCopyToId }: any) => {
        const CALL_REASON = `Duplicate Dashlet ${dashletToDuplicate.name}`;

        const queryKey = [FETCH_DASHBOARD_KEY, dashboardToCopyToId];
        await queryClient.cancelQueries(queryKey);

        const previousDashboards: any = queryClient.getQueryData(KEY);

        const previousDashboard = find(previousDashboards.dashboards, {
          dashboard_id: dashboardToCopyToId,
        });

        const { dashlets, ...rest } = previousDashboard;

        const newDashboard = {
          ...rest,
          dashlets: [...dashlets, dashletToDuplicate],
        };

        const newDashboardList = previousDashboards.dashboards.map(
          (dashboard: Dashboard) =>
            dashboard.dashboard_id === dashboardToCopyToId
              ? newDashboard
              : dashboard
        );

        queryClient.setQueryData(queryKey, {
          dashboard: newDashboard,
        });

        queryClient.setQueryData(KEY, {
          dashboards: newDashboardList,
        });

        return {
          previousDashboards,
          previousDashboard,
          queryKey,
          CALL_REASON,
        };
      },
      onSuccess: (data, context) => {
        onSuccessNotification(data, context?.CALL_REASON);
      },
      onError: (data, context) => {
        onErrorNotification(data, context?.CALL_REASON);
        if (context?.previousDashboards) {
          queryClient.setQueryData<Dashboard>(
            context?.queryKey,
            context?.previousDashboard
          );
          queryClient.setQueryData<Dashboard>(
            FETCH_DASHBOARD_KEY,
            context?.previousDashboards
          );
        }
      },
      onSettled: (context) => {
        queryClient.invalidateQueries(context.queryKey);
        queryClient.invalidateQueries(KEY);
      },
    }
  );
}
