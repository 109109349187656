import { random } from "lodash";
const networkDashletStateTypes = ["pending", "up", "down", "unreachable"];

export const dashletMockData: any = {
  availability: {
    avg_host_up: 100,
    avg_host_down: 0,
    avg_host_unreachable: 0,
    avg_service_ok: 80,
    avg_service_warning: 0,
    avg_service_unknown: 0,
    avg_service_critical: 13.333333333333334,
  },

  heatmap: {
    service_names: [
      "Current Load",
      "Current Users",
      "HTTP",
      "HTTP_copy_1",
      "Memory Usage",
      "PING",
      "Root Partition",
      "SSH",
      "SSH_copy_1",
      "Service Status - crond",
      "Service Status - httpd",
      "Service Status - mysqld",
      "Swap Usage",
      "Total Processes",
    ],
    host_names: ["localhost", "localhost_copy_1"],
    service_data: {
      localhost: {
        "Current Load": {
          status_text: "OK - load average: 0.23, 0.16, 0.11",
          state: "ok",
        },
        "Current Users": {
          status_text: "USERS OK - 3 users currently logged in",
          state: "ok",
        },
        HTTP: {
          status_text:
            "HTTP OK: HTTP/1.1 302 Found - 420 bytes in 0.000 second response time",
          state: "ok",
        },
        "Memory Usage": {
          status_text:
            "OK - 4176 / 7802 MB (53%) Free Memory, Used: 3625 MB, Shared: 527 MB, Buffers + Cached: 3871 MB",
          state: "ok",
        },
        PING: {
          status_text: "PING OK - Packet loss = 0%, RTA = 0.04 ms",
          state: "ok",
        },
        "Root Partition": {
          status_text: "DISK OK - free space: / 17757 MiB (46.90% inode=96%):",
          state: "ok",
        },
        "Service Status - crond": {
          status_text: "\u25cf crond.service - Command Scheduler",
          state: "ok",
        },
        "Service Status - httpd": {
          status_text: "\u25cf httpd.service - The Apache HTTP Server",
          state: "ok",
        },
        "Service Status - mysqld": {
          status_text: "\u25cf mariadb.service - MariaDB database server",
          state: "ok",
        },
        SSH: {
          status_text:
            "connect to address 127.0.0.1 and port 22: Connection refused",
          state: "critical",
          handled: "downtime",
        },
        "Swap Usage": {
          status_text: "SWAP OK - 99% free (2019 MB out of 2047 MB)",
          state: "ok",
        },
        "Total Processes": {
          status_text: "PROCS OK: 77 processes with STATE = RSZDT",
          state: "ok",
        },
      },
      localhost_copy_1: {
        HTTP_copy_1: {
          status_text:
            "HTTP OK: HTTP/1.1 302 Found - 420 bytes in 0.000 second response time",
          state: "ok",
        },
        SSH_copy_1: {
          status_text:
            "connect to address 127.0.0.1 and port 22: Connection refused",
          state: "critical",
        },
      },
    },
    host_data: {
      localhost: {
        status_text: "OK - 127.0.0.1 rta 0.018ms lost 0%",
        state: "up",
      },
      localhost_copy_1: {
        status_text: "OK - 127.0.0.1 rta 0.015ms lost 0%",
        state: "up",
      },
    },
  },

  perfdata_line: {
    nivo_data: [
      {
        id: "pl",
        data: [
          {
            x: "2021-10-03T01:29:06Z",
            y: 0,
          },
          {
            x: "2021-10-03T01:34:06Z",
            y: 0,
          },
        ],
      },
      {
        id: "rtmax",
        data: [
          {
            x: "2021-10-03T01:29:06Z",
            y: 0.047,
          },
          {
            x: "2021-10-03T01:34:06Z",
            y: 0.061,
          },
        ],
      },
      {
        id: "rtmin",
        data: [
          {
            x: "2021-10-03T01:29:06Z",
            y: 0.007,
          },
          {
            x: "2021-10-03T01:34:06Z",
            y: 0.007,
          },
        ],
      },
      {
        id: "rta",
        data: [
          {
            x: "2021-10-03T01:29:06Z",
            y: 0.015,
          },
          {
            x: "2021-10-03T01:34:06Z",
            y: 0.018,
          },
        ],
      },
    ],
  },

  perfdata_gauge: {
    data: {
      timestamp: "2021-10-03T02:39:13Z",
      value: 0.015,
      warning: 3000.0,
      critical: 5000.0,
      minimum: 0,
      maximum: 10000.0,
    },
  },
  sla: {
    hosts: {
      localhost: {
        percent_time_up: 100,
        passed: true,
      },
      localhost_copy_1: {
        percent_time_up: 100,
        passed: true,
      },
    },
    services: {
      localhost_copy_1: {
        "Current Load": {
          percent_time_ok: 100,
          passed: true,
        },
        "Current Users": {
          percent_time_ok: 100,
          passed: true,
        },
        HTTP: {
          percent_time_ok: 100,
          passed: true,
        },
        "Memory Usage": {
          percent_time_ok: 100,
          passed: true,
        },
        PING: {
          percent_time_ok: 100,
          passed: true,
        },
        "Root Partition": {
          percent_time_ok: 100,
          passed: true,
        },
        SSH: {
          percent_time_ok: 0,
          passed: false,
        },
        "Service Status - crond": {
          percent_time_ok: 100,
          passed: true,
        },
        "Service Status - httpd": {
          percent_time_ok: 100,
          passed: true,
        },
        "Service Status - mysqld": {
          percent_time_ok: 100,
          passed: true,
        },
        "Swap Usage": {
          percent_time_ok: 100,
          passed: true,
        },
        "Total Processes": {
          percent_time_ok: 100,
          passed: true,
        },
      },
      localhost: {
        "Current Load": {
          percent_time_ok: 100,
          passed: true,
        },
        "Current Users": {
          percent_time_ok: 100,
          passed: true,
        },
        HTTP: {
          percent_time_ok: 100,
          passed: true,
        },
        "Memory Usage": {
          percent_time_ok: 100,
          passed: true,
        },
        PING: {
          percent_time_ok: 100,
          passed: true,
        },
        "Root Partition": {
          percent_time_ok: 100,
          passed: true,
        },
        SSH: {
          percent_time_ok: 0,
          passed: false,
        },
        "Service Status - crond": {
          percent_time_ok: 100,
          passed: true,
        },
        "Service Status - httpd": {
          percent_time_ok: 100,
          passed: true,
        },
        "Service Status - mysqld": {
          percent_time_ok: 100,
          passed: true,
        },
        "Swap Usage": {
          percent_time_ok: 100,
          passed: true,
        },
        "Total Processes": {
          percent_time_ok: 100,
          passed: true,
        },
      },
    },
    averages: {
      services: {
        percent_time_ok: 91.66666666666667,
        passed: false,
      },
    },
  },

  network: {
    id: "__root__",
    name: "Nagios Process",
    level: 0,
    status_url: "",
    status: "up",
    children: [
      {
        id: "localhost_copy_1",
        name: "localhost_copy_1",
        level: 1,
        status: "up",
        status_text: "Random text",
        status_url:
          "http://localhost:3000/nagiosxi//includes/components/xicore/status.php?show=hostdetail&host=localhost_copy_1",
        children: [
          {
            id: "localhost_sub_1",
            name: "localhost_sub_1",
            level: 2,
            status: "up",
            status_text: "Random text",
            status_url:
              "http://localhost:3000/nagiosxi//includes/components/xicore/status.php?show=hostdetail&host=localhost_copy_1",
            children: [
              {
                id: "localhost_sub_sub_1",
                name: "localhost_sub_sub_1",
                level: 3,
                status:
                  networkDashletStateTypes[
                    random(networkDashletStateTypes.length - 1)
                  ],
                status_text: "Random text",
                status_url:
                  "http://localhost:3000/nagiosxi//includes/components/xicore/status.php?show=hostdetail&host=localhost_copy_1",
                children: [],
              },
            ],
          },
          {
            id: "localhost_sub_2",
            name: "localhost_sub_2",
            level: 2,
            status:
              networkDashletStateTypes[
                random(networkDashletStateTypes.length - 1)
              ],
            status_url: "Random text",
            status_text:
              "http://localhost:3000/nagiosxi//includes/components/xicore/status.php?show=hostdetail&host=localhost_copy_1",
            children: [],
          },
        ],
      },
      {
        id: "localhost",
        name: "localhost",
        level: 1,
        status:
          networkDashletStateTypes[random(networkDashletStateTypes.length - 1)],
        status_text: "Random text",
        status_url:
          "http://localhost:3000/nagiosxi//includes/components/xicore/status.php?show=hostdetail&host=localhost",
        children: [],
      },
    ],
  },

  latest_alerts: [
    {
      host: "localhost",
      host_state: 0,
      latest_alert: 1633083722,
      latest_alert_text: "2021-10-01 10:22:02",
      service_problems: {
        critical: ["Other"],
        warning: ["SSH 2"],
        unknown: [],
        handled: ["SSH"],
      },
    },
    {
      host: "localhost_copy_1",
      host_state: 0,
      latest_alert: 1633083711,
      latest_alert_text: "2021-10-01 10:21:51",
      service_problems: {
        critical: ["SSH_copy_1"],
        warning: [],
        unknown: [],
        handled: ["SSH"],
      },
    },
  ],

  metrics_overview: {
    metric_name: "Disk Usage",
    value_name: "% Utilization",
    rows: [
      {
        host: "localhost",
        service: "Root Partition",
        value: "51.67%",
        status_text: "DISK OK - free space: / 18300 MiB (48.33% inode=97%):",
        color: "#B2FF5F",
      },
      {
        host: "localhost_2",
        service: "Root Partition",
        value: "100%",
        status_text: "DISK OK - free space: / 18300 MiB (48.33% inode=97%):",
        color: "#B2FF5F",
      },
    ],
  },
  monitoring_engine_event_queue: {
    event_counts: [
      1, 1, 0, 10, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 2, 2, 2, 5, 2, 2, 0,
    ],
  },
  monitoring_engine_stats: {
    host_active: {
      "1m": 10,
      "5m": 10,
      "15m": 5,
    },
    host_passive: {
      "1m": 0,
      "5m": 0,
      "15m": 0,
    },
    service_active: {
      "1m": 1,
      "5m": 14,
      "15m": 14,
    },
    service_passive: {
      "1m": 10,
      "5m": 2,
      "15m": 20,
    },
  },
  monitoring_engine_performance: {
    host_latency: {
      min: 1.0008280000183731318,
      max: 1.0020439999643713236,
      avg: 1.0014359999913722277,
    },
    host_execution_time: {
      min: 0.0031309999999999997,
      max: 0.003669,
      avg: 0.0034,
    },
    service_latency: {
      min: 0,
      max: 0.0022239999379962683,
      avg: 0.0012421428650018892,
    },
    service_execution_time: {
      min: 0.001935,
      max: 4.003606,
      avg: 0.29614650000000003,
    },
  },
  monitoring_engine_processes:
    random(0, 1) === 1
      ? {
          is_currently_running: 1,
          program_start_time: "2021-10-04 14:13:18",
          program_run_time: "3s",
          process_id: 39375,
          active_service_checks_enabled: 1,
          passive_service_checks_enabled: 1,
          active_host_checks_enabled: 1,
          passive_host_checks_enabled: 1,
          notifications_enabled: 1,
          event_handlers_enabled: 1,
          flap_detection_enabled: 1,
          process_performance_data: 1,
          obsess_over_services: 0,
          obsess_over_hosts: 0,
        }
      : {
          is_currently_running: 0,
          program_end_time: "2021-10-04 14:13:18",
        },
  server_stats: {
    cpu: {
      processor_count: "4",
      load1m: {
        val: 0,
        warn: 1,
        crit: 3,
      },
      load5m: {
        val: 0.13,
        warn: 1,
        crit: 3,
      },
      load15m: {
        val: 0.66,
        warn: 1,
        crit: 3,
      },
    },
    iostat: {
      user: {
        val: 1.41,
        warn: 75,
        crit: 95,
      },
      nice: {
        val: 0,
        warn: 75,
        crit: 95,
      },
      system: {
        val: 1.11,
        warn: 75,
        crit: 95,
      },
      iowait: {
        val: 0.05,
        warn: 5,
        crit: 15,
      },
      steal: {
        val: 0,
        warn: 5,
        crit: 15,
      },
      idle: {
        val: 97.43,
        warn: 100,
        crit: 100,
      },
    },
    memory: {
      total: 7802,
      used: {
        val: 4278,
        warn: 7645,
        crit: 7723,
      },
      free: 367,
      shared: 759,
      buffers: 3156,
      cached: 2466,
    },
    swap: {
      total: 2047,
      used: {
        "0": 121,
        warn: 1023,
        crit: 1637,
      },
      free: 1926,
    },
  },
  core_component_status: {
    nagioscore: {
      status: 0,
      status_text: "ok",
      start_command: 11,
      restart_command: 13,
      stop_command: 12,
      output:
        "           └─104174 /usr/local/nagios/bin/nagios -d /usr/local/nagios/etc/nagios.cfg",
      name: "Monitoring Engine",
    },
    ipdp: {
      status: 0,
      status_text: "ok",
      start_command: 111,
      restart_command: 113,
      stop_command: 112,
      output:
        "           └─113559 /usr/local/nagios/bin/ipdp /usr/local/nagios/etc/ipdp.cfg",
      name: "Performance Grapher",
    },
    dbmaint: {
      last_update: 1633472401,
      name: "Database Maintenance",
      status: 0,
      status_text: "ok",
    },
    cmdsubsys: {
      last_update: 1633472611,
      name: "Command Subsystem",
      status: 0,
      status_text: "ok",
    },
    eventman: {
      last_update: 1633472611,
      name: "Event Manager",
      status: 0,
      status_text: "ok",
    },
    feedproc: {
      last_update: 1633472602,
      name: "Feed Processor",
      status: 0,
      status_text: "ok",
    },
    nom: {
      last_update: 1633472581,
      name: "Network Operations Manager",
      status: 0,
      status_text: "ok",
    },
    reportengine: {
      last_update: 1633472581,
      name: "Report Engine",
      status: 0,
      status_text: "ok",
    },
    sysstat: {
      last_update: 1633470942,
      name: "System Statistics",
      status: 1,
      status_text: "down",
    },
  },
  status_summary_hosts: {
    up: 50,
    down: 3,
    handled_down: 0,
    unhandled_down: 3,
    unreachable: 4,
    handled_unreachable: 2,
    unhandled_unreachable: 2,
    pending: 6,
    unhandled_problems: 5,
    total_problems: 0,
    total_records: 25,
  },
  status_summary_services: {
    ok: 761,
    warning: 105,
    handled_warning: 50,
    unhandled_warning: 55,
    critical: 129,
    handled_critical: 100,
    unhandled__critical: 29,
    unknown: 5,
    handled_unknown: 3,
    unhandled_unknown: 2,
    pending: 7,
    unhandled_problems: 1,
    total_problems: 1,
    total_records: 17,
  },
  status_summary_hostgroups: {
    "linux-servers": {
      alias: "Linux Servers",
      host: {
        up: 1,
        down: 1,
        unreachable: 1,
      },
      service: {
        ok: 12,
        warning: 1,
        unknown: 1,
        critical: 1,
      },
    },
    "linux-servers_2": {
      alias: "Linux Servers 2",
      host: {
        up: 1,
        down: 1,
        unreachable: 0,
      },
      service: {
        ok: 12,
        warning: 0,
        unknown: 0,
        critical: 0,
      },
    },
  },
  status_summary_servicegroups: {
    six: {
      alias: "Its Just Six Services",
      host: {
        up: 1,
        down: 0,
        unreachable: 0,
      },
      service: {
        ok: 6,
        warning: 0,
        unknown: 0,
        critical: 0,
      },
    },
  },
  network_outages: {
    outages: [
      {
        severity: 2,
        host: "localhost_child",
        state: "down",
        duration: 17,
        duration_string: "17s",
        hosts_affected: 2,
        services_affected: 0,
      },
    ],
  },
};
