import { Button, Checkbox, Form, Input } from "antd";
import React, { useState } from "react";
import "react-colorful/dist/index.css";
import { useTranslator } from "../../../../hooks";
import { ColorPicker } from "../../Atoms";
import useCreateDashboard from "../../hooks/useCreateDashboard";
/* import { useDashlets } from "../../hooks/useDashboards"; */
const { useForm } = Form;

const FormCheckbox = ({ label, name }) => (
  <Form.Item label={label} name={name} valuePropName="checked">
    <Checkbox />
  </Form.Item>
);

export default function CreateDashboardForm() {
  const [color, setColor] = useState("#16C79B");

  const { mutate } = useCreateDashboard();
  const [form] = useForm();

  const {
    DASHBOARD_TITLE_INPUT,
    SELECT_BACKGROUND_COLOR,
    PUBLIC_SHARE_CHECKBOX,
    ALLOW_EDITING_CHECKBOX,
    DASHBOARD_INPUT_ERROR_MESSAGE,
  } = useTranslator("CreateDashboardForm");

  const onSubmit = () => {
    const formValues = form.getFieldsValue();
    mutate({ ...formValues, color: color });
  };

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      layout="vertical"
      initialValues={{ isSharable: false, isEditable: false }}
    >
      <Form.Item
        label={DASHBOARD_TITLE_INPUT}
        name="name"
        rules={[{ required: true, message: DASHBOARD_INPUT_ERROR_MESSAGE }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={SELECT_BACKGROUND_COLOR}>
        <ColorPicker color={color} updateColor={setColor} />
      </Form.Item>
      <FormCheckbox label={PUBLIC_SHARE_CHECKBOX} name="isSharable" />
      <FormCheckbox label={ALLOW_EDITING_CHECKBOX} name="isEditable" />

      <Form.Item>
        <Button htmlType="submit" type="primary">
          Generate Dashboard
        </Button>
      </Form.Item>
    </Form>
  );
}
