import React from "react";

export default function MoreSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 55 55"
    >
      <g>
        <g>
          <path
            fill="#a942e8"
            d="M0 18C0 8.059 8.059 0 18 0h19c9.941 0 18 8.059 18 18v19c0 9.941-8.059 18-18 18H18C8.059 55 0 46.941 0 37z"
          />
        </g>
        <g>
          <g>
            <g>
              <path d="M15 23v-8h8v8zm8-10h-8a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2z" />
            </g>
            <g>
              <path d="M31 23v-8h8v8zm8-10h-8a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2z" />
            </g>
            <g>
              <path d="M15 39v-8h8v8zm8-10h-8a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2z" />
            </g>
            <g>
              <path d="M31 39v-8h8v8zm8-10h-8a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2z" />
            </g>
          </g>
          <g />
        </g>
      </g>
    </svg>
  );
}
