import { useQuery } from "react-query";
import { SERVICE_URL } from "../../../api.config";
import { fetchData } from "../../../utils/axiosHelpers";

export const KEY = "Fetch Services Overview";

const fetchServicesOverview = async () => fetchData(`${SERVICE_URL}/overview`);

export const preFetchServicesOverview = (queryClient) =>
  queryClient.prefetchQuery(KEY, fetchServicesOverview);

export default function useServicesOverview() {
  return useQuery(KEY, fetchServicesOverview);
}
