import React from "react";
import { Card } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Host, Service } from "../../../../context";
import { useTranslator } from "../../../../hooks";
import { StatusContent } from "../../../Shared";
import DynamicSvg from "../../../Shared/Atoms/DynamicSvg";

const { Meta } = Card;

type Props = {
  title: string;
  statusArray: Host[] | Service[];
  lastUpdated: Date;
};

const StyledCard = styled(Card)`
  width: 349px;
  height: auto;
`;

const CenteredCard = styled(StyledCard)`
  text-align: center;
`;

const CardTitle = styled.div`
  font-family: "DM Sans - Bold";
  font-size: px;
  font-weight: 700;
`;

export default function StatusRowCard({ title, statusArray }: Props) {
  const isTitleHosts = title === "Hosts";

  const {
    NOT_MONITORING_HOST_MESSAGE,
    NOT_MONITORING_SERVICE_MESSAGE,
    ONCE_SETUP_SERVICE_MESSAGE,
    ONCE_SETUP_HOST_MESSAGE,
    LINK_TITLE,
    HOSTS_TITLE,
    SERVICES_TITLE,
  } = useTranslator("StatusRowCard");

  return (
    <>
      {statusArray.length === 0 ? (
        <CenteredCard bordered={false}>
          <DynamicSvg name={isTitleHosts ? "nohosts" : "noservices"} />
          <h1>
            {isTitleHosts
              ? NOT_MONITORING_HOST_MESSAGE
              : NOT_MONITORING_SERVICE_MESSAGE}
          </h1>
          <Meta
            description={
              isTitleHosts
                ? ONCE_SETUP_HOST_MESSAGE
                : ONCE_SETUP_SERVICE_MESSAGE
            }
          />
          <Link to="">{LINK_TITLE}</Link>
        </CenteredCard>
      ) : (
        <StyledCard bordered={false}>
          <CardTitle>{isTitleHosts ? HOSTS_TITLE : SERVICES_TITLE}</CardTitle>
          <StatusContent title={title.toLowerCase()} />
        </StyledCard>
      )}
    </>
  );
}
