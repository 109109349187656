import axios from "axios";

export const fetchData = (url: string, params?: any) =>
  axiosWrapper(url, params ? { params: {...params} } : {}, "get");

export const postData = (url: string, params?: any) =>
  axiosWrapper(url, params ? params : {}, "post");

export const putData = (url: string, params?: any) =>
  axiosWrapper(url, params ? params : {}, "put");

export const deleteData = (url: string, params?: any) =>
  axiosWrapper(url, params ? { data: {...params} } : {}, "delete");

async function axiosWrapper(url: string, params: Object, method: string) {
  const token = localStorage.getItem("token");
  const opts = {
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}` }
  };
  try {
    // Axios calls with post/put use the second variable as the data var,
    // whereas get/delete use "params" and "data" respectively inside the options 
    const { data } = method === 'get' || method === 'delete' ?
      await axios[method](url, { ...params, ...opts }) :
      await axios[method](url, params, opts);
    return data;
  } catch (error) {
    throw error;
  }
}
