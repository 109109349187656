import React, { createContext, useState } from "react";

export type User = {
  name: string;
  email: string;
  phoneNumber: string;
  lastLogin: Date;
  authType: string;
  isAdmin: boolean;
  isEnabled: boolean;
};

type UsersObject = {
  users: User[];
  updateUserList: Function;
};

export const UsersContext = createContext<UsersObject>({
  users: [],
  updateUserList: Function,
});

type Props = {
  children: JSX.Element[] | JSX.Element;
};

export function UsersProvider({ children }: Props) {
  const [users, setUsers] = useState<User[]>([]);

  return (
    <UsersContext.Provider
      value={{
        users,
        updateUserList: setUsers,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}

const createUserObject = (
  name: string,
  isAdmin: boolean,
  isEnabled: boolean
): User => ({
  name,
  email: "test@email.com",
  phoneNumber: "111-111-1111",
  lastLogin: new Date(),
  authType: "Local",
  isAdmin,
  isEnabled,
});

export function WithUsersContext({ children }: Props) {
  const [users, setUsers] = useState<User[]>([
    createUserObject("John", true, true),
    createUserObject("Tim", false, true),
    createUserObject("Sage", false, false),
  ]);
  return (
    <UsersContext.Provider
      value={{
        users,
        updateUserList: setUsers,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}
