import React from "react";
import {
  Button,
  Menu,
  MenuItem,
  PageRoute,
  RouterContext,
  RouterObject,
  SubItems,
} from "../context/";

const SamplePage = ({ text }: any) => <div>{text}</div>;

export const createButton = (label: string) => ({
  label,
  onClickFunction: () => console.log("Hello World"),
});

export const createMenuItem = (label: string, path: string) => ({
  RouterComponent: <div>{label}</div>,
  path,
  label,
});

export const createMenu = (label: string, menuItemList: MenuItem[]) => ({
  label,
  menuItemList,
});

export const createSubMenu = (buttons: Button[], menus: Menu[]) => ({
  buttons,
  menus,
});

export const createButtonArray = (nameArray: string[]) =>
  nameArray.map((buttonName) => createButton(buttonName));

export const createRouterObject = (
  label: string,
  component: any,
  path: string,
  subMenu?: SubItems
) => ({
  RouterComponent: () => <PageRoute PageComponent={component} />,
  path,
  label,
  subMenu,
});

export const createSamplepage = (
  label: string,
  path: string,
  subMenu?: SubItems
) =>
  createRouterObject(label, () => <SamplePage text={label} />, path, subMenu);

export const routerObjectArray: RouterObject[] = [
  createSamplepage("Home", "/"),
  createSamplepage(
    "Dashboards",
    "/dashboards",
    createSubMenu(createButtonArray(["Create A Dashboard", "Mass Deploy"]), [
      createMenu("Dashboards Main", [
        createMenuItem("All Dashboards", "/dashboards"),
      ]),
      createMenu("Dashboards Admin", [
        createMenuItem("Dashlet Administration", "/dashboards/admin/dashlet/"),
      ]),
    ])
  ),
  createSamplepage("Views", "/views"),
  createSamplepage("Reports", "/reports"),
  createSamplepage("Visualizations", "/visualizations"),
  createSamplepage("Incidents", "/incidents"),
  createSamplepage("Configure", "/configure"),
  createSamplepage("Administration", "/administration"),
  createSamplepage("Help", "/help"),
  createSamplepage("Tools", "/tools"),
];

export function WithRouterContext({ children }: any) {
  return (
    <RouterContext.Provider value={{ routerObjectArray }}>
      {children}
    </RouterContext.Provider>
  );
}
