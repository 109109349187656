import React, { useState } from "react";
import styled from "styled-components";
import useCreateFavorite from "../Frame/hooks/useCreateFavorite";
import PageHeader from "../Shared/Organisms/PageHeader/PageHeader";
import ContactSupport from "./Molecules/ContactSupport/ContactSupport";
import ExternalResources from "./Molecules/ExternalResources/ExternalResources";
import HelpLinks from "./Molecules/HelpLinks/HelpLinks";
import {
  supportTopic,
  documentationGuides,
} from "./Molecules/HelpLinks/HelpLinksMockData";
import SearchSupport from "./Molecules/SearchSupport/SearchSupport";
import SupportTeam from "./Molecules/SupportTeam/SupportTeam";
import { supportTeamMembers } from "./Molecules/SupportTeam/SupportTeamMockData";
import ContactModal from "./Organisms/ContactModal/ContactModal";
import FAQModal from "./Organisms/FAQModal/FAQModal";
import HelpFAQ from "./Organisms/HelpFAQ/HelpFAQ";
import { FAQ } from "./Organisms/HelpFAQ/HelpFAQMockData";
import InPageHelpModal from "./Organisms/InPageHelpModal/InPageHelpModal";
import SupportFTU from "./Organisms/SupportFTU/SupportFTU";

const MainBody = styled.div`
  padding: 20px;
  position: relative;
`;

const FlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > :first-child {
    margin-right: 20px;
  }
  & > section:first-child {
    flex: 2;
  }
  & > section:last-child {
    flex: 1;
    max-width: 300px;
  }
  & > div {
    flex: 1;
  }
  & .ant-card {
    margin-bottom: 20px;
  }
`;

const HelpPage = () => {
  // Support for First Time Users Functions
  const [showSupportFTU, setShowSupportFTU] = useState(true);
  const setupGuide = () => alert('Function for "Setup Guide" goes here');
  const learnHow = () => alert('Function for "Learn How" goes here');
  const hideSupportFTU = () => {
    alert('Function for "Don\'t show this section again" goes here.');
    setShowSupportFTU(false);
  };
  const [showIPHmodal, setShowIPHmodal] = useState(false);

  //Frequently Asked Questions Functions
  const [showFAQ, setShowFAQ] = useState("all");
  const [showFAQModal, setShowFAQModal] = useState(false);
  const [titleFAQ, setTitleFAQ] = useState("ModalTitle");
  const { mutate: createFavorite } = useCreateFavorite();
  const [bodyFAQ, setBodyFAQ] = useState(<h2>ModalBody</h2>);
  const filterFAQ = (event) => setShowFAQ(event.target.value);
  const clickFAQ = (event) => {
    setTitleFAQ(event[0]);
    setBodyFAQ(<h2>{event[1]}</h2>);
    setShowFAQModal(true);
  };

  //Search Support Documentation Functions
  const searchSupport = (event) =>
    alert("Function to search for '" + event + "' goes here.");

  //Contact Support Functions
  const accountUpgraded = false;
  const [contactModalVisible, setContactModalVisible] = useState(false);
  const submitForm = () => alert("Submit Form function goes here");

  return (
    <MainBody>
      <PageHeader
        title="Support and Customer Service"
        sub="Find answers here or find out where to get help."
        favoriteClicked={() => createFavorite({ title: "Help", url: "/help" })}
      />
      <FlexBox>
        <section>
          {" "}
          {/* Column 1 */}
          <SupportFTU
            showSupportFTU={showSupportFTU}
            setupGuide={setupGuide}
            learnHow={learnHow}
            seeDetails={() => setShowIPHmodal(true)}
            hideSupportFTU={hideSupportFTU}
          />
          <InPageHelpModal
            visibleIPH={showIPHmodal}
            closeIPH={() => setShowIPHmodal(false)}
          />
          <HelpFAQ
            selectFAQ={filterFAQ}
            value={showFAQ}
            faqData={FAQ}
            clickFAQ={clickFAQ}
          />
          <FAQModal
            visible={showFAQModal}
            title={titleFAQ}
            modalBody={bodyFAQ}
            close={() => setShowFAQModal(false)}
          />
          <FlexBox>
            <HelpLinks linkData={supportTopic} title="Support By Topic" />
            <HelpLinks
              linkData={documentationGuides}
              title="Documentation, Guides, Resources + Tutorials"
            />
          </FlexBox>
        </section>
        <section>
          {" "}
          {/* Column 2 */}
          <SearchSupport searchSupport={searchSupport} />
          <ContactSupport
            createTicket={() => setContactModalVisible(true)}
            upgrade={accountUpgraded}
          />
          <ContactModal
            visible={contactModalVisible}
            close={() => setContactModalVisible(false)}
            upgraded={accountUpgraded}
            submit={submitForm}
          />
          <SupportTeam teamMembers={supportTeamMembers} />
          <ExternalResources />
        </section>
      </FlexBox>
    </MainBody>
  );
};

export default HelpPage;
