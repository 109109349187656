import { useMasterTheme } from "../../../../context/MasterThemeContext";

const CustomCell = (cell, colorObject, properties) => {
  const {
    label,
    x,
    y,
    opacity,
    borderWidth,
    borderColor,
    onHover,
    onLeave,
    onClick,
  } = cell;

  const theme = useMasterTheme().theme;

  if (label === undefined) {
    return null;
  }

  let r = 29.73263888888889;

  return (
    <g
      transform={`translate(${x}, ${y})`}
      onMouseEnter={onHover}
      onMouseMove={onHover}
      onMouseLeave={onLeave}
      onClick={onClick}
    >
      <circle
        r={properties.height < 500 ? r / 3 : r / 1.5}
        fill={colorObject[label]}
        fillOpacity={opacity}
        strokeWidth={borderWidth}
        stroke={borderColor}
      >
        <text
          dominantBaseline="central"
          textAnchor="middle"
          style={{ fill: theme.typographyPrimaryColor }}
        >
          {label}
        </text>
      </circle>
    </g>
  );
};

export default CustomCell;
