import { find } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { DASHLET_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { putData } from "../../../utils/axiosHelpers";
import { Dashboard, Dashlet } from "../../DashboardPage/DashboardPageTypes";
import {
  FETCH_DASHBOARD_KEY,
  KEY,
} from "../../DashboardPage/hooks/useDashboards";

export default function useEditDashlet() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    ({ dashletToEdit, dashboard_id }) =>
      putData(`${DASHLET_URL}/${dashletToEdit.dashlet_id}`, {
        ...dashletToEdit,
        dashboard_id,
      }),
    {
      onMutate: async ({ dashletToEdit, dashboard_id }: any) => {
        const CALL_REASON = `Edit Dashlet ${dashletToEdit.name}`;

        const { dashlet_id } = dashletToEdit;
        const queryKey = [FETCH_DASHBOARD_KEY, dashboard_id];

        await queryClient.cancelQueries(queryKey);

        const previousDashboards: any = queryClient.getQueryData(KEY);
        const previousDashboard = find(previousDashboards.dashboards, {
          dashboard_id,
        });

        const { dashlets, ...rest } = previousDashboard;

        const newDashletList = dashlets.map((dashlet: Dashlet) =>
          dashlet.dashlet_id === dashlet_id
            ? { ...dashletToEdit, properties: dashlet.properties }
            : dashlet
        );

        const newDashboardObject = {
          ...rest,
          dashlets: newDashletList,
        };

        console.log(queryKey, newDashboardObject);

        queryClient.setQueryData(queryKey, {
          dashboard: newDashboardObject,
        });

        const newDashboardList = previousDashboards.dashboards.map(
          (dashboard: Dashboard) =>
            dashboard.dashboard_id === dashboard_id
              ? newDashboardObject
              : dashboard
        );

        queryClient.setQueryData(KEY, {
          dashboards: newDashboardList,
        });

        return {
          previousDashboard,
          newDashboardObject,
          CALL_REASON,
          queryKey,
        };
      },
      onSuccess: (data, _, context) =>
        onSuccessNotification(data, context?.CALL_REASON),
      onError: (data, _, context) => {
        onErrorNotification(data, context?.CALL_REASON);
        if (context?.previousDashboard) {
          queryClient.setQueryData<Dashboard[]>(
            context?.queryKey,
            context.previousDashboard
          );
        }
      },

      onSettled: (data, _1, _2, context) => {
        queryClient.invalidateQueries(context?.queryKey);
        queryClient.invalidateQueries(KEY);
      },
    }
  );
}
