import React from "react";
import { BrowserRouter } from "react-router-dom";
import { LanguageProvider, AuthProvider } from "./context";
import { MasterThemeProvider } from "./context/MasterThemeContext";
import "../src/styles/antd/compiled-css/antd-theme-master.css";
import Frame from "./components/Frame/Frame";

export default function App() {
  return (
    <BrowserRouter forceRefresh>
      <LanguageProvider>
        <MasterThemeProvider>
          <AuthProvider>
            <Frame />
          </AuthProvider>
        </MasterThemeProvider>
      </LanguageProvider>
    </BrowserRouter>
  );
}
