import { createContext, useContext, useState } from "react";
import { Notifications } from "../components/Frame/FrameTypes";

export type Notification = {
  description: string;
  message: string;
  type: string;
  timeStamp: string;
};

type NotificationsContextObject = {
  notifications: Notifications;
  updateNotifications: Function;
  clearNotifications: Function;
};

export const NotificationsContext = createContext<NotificationsContextObject>({
  notifications: [],
  updateNotifications: Function,
  clearNotifications: Function,
});

type Props = {
  children: JSX.Element[] | JSX.Element;
};

export function NotificationsProvider({ children }: Props) {
  const [notifications, setNotifcations] = useState<Notification[]>([]);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        updateNotifications: setNotifcations,
        clearNotifications: () => setNotifcations([]),
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}

export const useNotifications = () => {
  return useContext(NotificationsContext);
};
