import { Button as AntButton } from "antd";
import React from "react";
import styled from "styled-components";
import {
  IconArrowRight,
  IconCalendar,
} from "../../../Shared/Atoms/FontIcons/FontIcons";
import { useEditReportContext } from "../../context/EditReportContent";

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled(AntButton)`
  height: 40px;
  width: 150px;
`;

export type FooterProps = {
  page: number;
  toggleScheduledOptions: () => void;
  toggleReportOpitions: () => void;
  runReport: () => void;
  schedule: () => void;
};

const CreateReportModalFooter = (props: FooterProps) => {
  const { reportToEdit } = useEditReportContext();

  const ReportOptions = () => (
    <Button onClick={props.toggleReportOpitions}>Report Options</Button>
  );

  const ScheduledOptions = () => (
    <Button onClick={props.toggleScheduledOptions}>Schedule Options</Button>
  );

  const Run = () => (
    <Button type="primary" onClick={props.runReport}>
      {reportToEdit ? "Edit Report" : "Run Report"}{" "}
      <IconArrowRight size="1.5em" />
    </Button>
  );

  const Schedule = () => (
    <Button type="primary" onClick={props.schedule}>
      Schedule <IconCalendar size="1.5em" />
    </Button>
  );

  return (
    <div>
      {props.page === 0 && (
        <FlexBox>
          <ScheduledOptions />
          <Run />
        </FlexBox>
      )}
      {props.page === 1 && (
        <FlexBox>
          <ReportOptions />
          <Schedule />
        </FlexBox>
      )}
    </div>
  );
};

export default CreateReportModalFooter;
