import { createContext, useContext } from "react";
import { Dashboard } from "../components/DashboardPage/DashboardPageTypes";

type DashletViewPageContextObject = {
  dashboard: Dashboard;
  isEnabled: boolean;
  toggleIsEnabled: Function;
  toggleCreateDashletModal?: Function;
};

export const DashletViewPageContext =
  createContext<DashletViewPageContextObject>({
    dashboard: {
      dashboard_id: 0,
      name: "",
      color: "white",
      destination_name: "",
      tagList: [],
      dashlets: [],
    },
    isEnabled: true,
    toggleIsEnabled: Function,
    toggleCreateDashletModal: Function,
  });

export function useDashletViewPageContext() {
  return useContext(DashletViewPageContext);
}
