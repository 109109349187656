import { useQuery } from "react-query";
import { PLAYLIST_URL } from "../../../api.config";
import { fetchData } from "../../../utils/axiosHelpers";

export const KEY = "Fetch Playlists";

const fetchPlaylists = async () => fetchData(PLAYLIST_URL);

export const preFetchPlaylists = (queryClient) =>
  queryClient.prefetchQuery(KEY, fetchPlaylists);

export default function usePlaylist(options?: any) {
  return useQuery(KEY, fetchPlaylists, {
    select: (data) => data.playlists,
    ...options,
    placeholderData: [],
    refetchOnWindowFocus: false,
  });
}
