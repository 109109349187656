import { Checkbox, Form, Input, Modal } from "antd";
import styled from "styled-components";
import { Tool } from "../../ToolsPageTypes";

export type Props = {
  toolToEdit: Tool;
  visable: boolean;
  form: any;
  onOk: any;
  onCancel: any;
};

const StyledLabel = styled.label`
  font-size: 0.75em;
  font-weight: 600;
  & i {
    font-size: 15px;
  }
`;

export default function EditToolModal({
  toolToEdit,
  visable,
  form,
  onOk,
  onCancel,
}: Props) {
  return (
    <Modal
      visible={visable}
      title={` Edit Tool ${toolToEdit.name}`}
      onOk={() => form.submit()}
      onCancel={onCancel}
    >
      <Form
        form={form}
        onFinish={() => {
          const formValues = form.getFieldsValue();
          const editedTool = {
            ...toolToEdit,
            ...formValues,
          };
          onOk(editedTool);
        }}
        initialValues={{ ...toolToEdit }}
      >
        <Input.Group>
          <StyledLabel>Tool Name</StyledLabel>
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Please input tool name!" }]}
          >
            <Input data-testid="EditToolName" />
          </Form.Item>
        </Input.Group>
        <Input.Group>
          <StyledLabel>URL</StyledLabel>

          <Form.Item
            name="url"
            rules={[{ required: true, message: "Please input url name!" }]}
          >
            <Input data-testid="EditURL" />
          </Form.Item>
        </Input.Group>
        <Form.Item name="shared" valuePropName="checked">
          <Checkbox style={{ marginBottom: "4px" }}>
            Make this viewable to everyone
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
}
