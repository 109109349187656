import { useParams } from "react-router-dom";
import usePlaylistLoop from "./hooks/usePlayListLoop";
import usePlaylist from "./hooks/usePlaylists";
import { find } from "lodash";
import React, { useEffect } from "react";
import { Button, Col, Progress, Row } from "antd";
import { PauseOutlined, PlaySquareOutlined } from "@ant-design/icons";
import { isLengthZero } from "../../utils";

function ViewsPageContent({ playlist }: any) {
  const { currentScreen, isRunning, currentTime, firstStart, toggleIsPlaying } =
    usePlaylistLoop(
      Object.values(playlist.views).filter(({ active }: any) => active)
    );

  useEffect(() => {
    firstStart();
  });

  return (
    <>
      <ViewProgress
        currentTime={currentTime}
        playDuration={currentScreen.play_duration}
        isRunning={isRunning}
        toggleIsPlaying={toggleIsPlaying}
      />
      <iframe
        title={currentScreen?.name}
        src={currentScreen?.url}
        width="100%"
        height="100%"
      />
    </>
  );
}

function ViewProgress({
  currentTime,
  playDuration,
  isRunning,
  toggleIsPlaying,
}: any) {
  return (
    <Row>
      <Col xs={1}>
        <Button
          onClick={toggleIsPlaying}
          icon={isRunning ? <PauseOutlined /> : <PlaySquareOutlined />}
        />
      </Col>

      <Col xs={23}>
        <Row>
          <Col xs={12}>
            <Progress
              percent={(currentTime / playDuration) * 100}
              showInfo={false}
            />
          </Col>
          <p>{currentTime}</p>
        </Row>
      </Col>
    </Row>
  );
}

export default function ViewsPage() {
  const { playListId } = useParams<any>();
  const { data: playlists } = usePlaylist();

  return (
    <>
      {isLengthZero([...(playlists ? playlists : [])]) ? (
        <p>( ...Loading )</p>
      ) : (
        <ViewsPageContent
          playlist={find(playlists, { id: parseInt(playListId) })}
        />
      )}
    </>
  );
}
