import { find } from "lodash";
import { useQueryClient, useMutation } from "react-query";
import { USER_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { putData } from "../../../utils/axiosHelpers";
import { User, Users } from "../UsersPageTypes";
import { KEY } from "./useUsers";

type MutationProps = {
  users: Users;
  action: "Disable" | "Enable";
};

export default function useEnableUser() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation((values) => putData(USER_URL, values), {
    onMutate: async ({ users, action }: MutationProps) => {
      console.log(users);
      await queryClient.cancelQueries(KEY);

      const previousUserList: any = queryClient.getQueryData(KEY);

      const CALL_REASON = `${
        action === "Disable" ? "Disabling" : "Enabling"
      } Users`;

      const newUserList = previousUserList.users.map((user: User) => {
        if (find(users, { user_id: user.user_id })) {
          const { enabled, ...rest } = user;

          return { ...rest, enabled: action === "Disable" ? 0 : 1 };
        }
        return user;
      });

      queryClient.setQueryData(KEY, {
        users: newUserList,
      });

      return {
        previousUserList,
        newUserList,
        CALL_REASON,
      };
    },
    onSuccess: (data, _, context) => {
      onSuccessNotification(data, context?.CALL_REASON);

      queryClient.setQueryData(KEY, {
        users: context?.newUserList,
      });
    },
    onError: (data, _, context) => {
      onErrorNotification(data, context?.CALL_REASON);
      if (context?.previousUserList) {
        queryClient.setQueryData<Users>([KEY], context.previousUserList);
      }
    },

    onSettled: () => {
      queryClient.invalidateQueries(KEY);
    },
  });
}
