import { useMutation, useQueryClient } from "react-query";
import { SAVE_DASHLETS_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { putData } from "../../../utils/axiosHelpers";
import { Dashboard } from "../../DashboardPage/DashboardPageTypes";
import {
  KEY,
  FETCH_DASHBOARD_KEY,
} from "../../DashboardPage/hooks/useDashboards";

export default function useSaveDashlets() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      putData(SAVE_DASHLETS_URL(values.dashboard_id), {
        grid: values.dashlets,
      }),
    {
      onMutate: async (dashboardToSave: any) => {
        const queryKey = [
          FETCH_DASHBOARD_KEY,
          Number(dashboardToSave.dashboard_id),
        ];
        await queryClient.cancelQueries(KEY);
        const previousDashboards: any = queryClient.getQueryData(KEY);
        const previousDashboard: any = queryClient.getQueryData(queryKey);

        const CALL_REASON = `Update Dashlets for  ${previousDashboard.name}`;

        const newDashboards = previousDashboards.dashboards.map(
          (dashboard: Dashboard) => {
            if (dashboard.dashboard_id === dashboardToSave.dashboard_id) {
              return dashboardToSave;
            }

            return dashboard;
          }
        );

        queryClient.setQueryData(KEY, {
          dashboards: newDashboards,
        });

        queryClient.setQueryData(queryKey, {
          dashboard: dashboardToSave,
        });

        return {
          previousDashboards,
          newDashboards,
          CALL_REASON,
          queryKey,
        };
      },
      onSuccess: (data, context) => {
        onSuccessNotification(data, context?.CALL_REASON);
      },
      onError: (data, context) => {
        onErrorNotification(data, context?.CALL_REASON);
        if (context?.previousDashboards) {
          queryClient.setQueryData<Dashboard[]>(
            KEY,
            context.previousDashboards
          );
        }
      },
    }
  );
}
