import { useMutation } from "react-query";
import { PASSWORD_RESTART_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { postData } from "../../../utils/axiosHelpers";

type MutationProps = {
  username: string;
};
export default function usePasswordRestart() {
  const { onErrorNotification } = useNotifications();
  return useMutation(
    (values: MutationProps) => postData(PASSWORD_RESTART_URL, values),
    {
      onSuccess: () => {},
      onError: (data) => onErrorNotification(data, "Password Restart Failed"),
    }
  );
}
