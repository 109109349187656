import { useState } from "react";
import styled from "styled-components";
import AddScreenButtons from "../AddScreenButtons/AddScreenButtons";

const StyledBody = styled.div`
  width: 100%;
  & > hr {
    margin: 15px -10px 0;
    border-style: solid;
    border-top-width: 0;
    border-color: #ddd;
  }
  & > div:last-child {
    max-height: 360px;
    overflow: auto;
    padding: 10px;
  }
`;

export type BodyProps = {
  commonScreen: JSX.Element[];
  myDashboards: JSX.Element;
  customURL: JSX.Element;
  otherIdeas: JSX.Element[];
};

const AddScreenModalBody = (props: BodyProps) => {
  const [display, setDisplay] = useState(0);

  return (
    <StyledBody>
      <AddScreenButtons
        clickedCommon={() => setDisplay(0)}
        clickedDashboards={() => setDisplay(1)}
        clickedURL={() => setDisplay(2)}
        clickedIdeas={() => setDisplay(3)}
      />
      <hr />
      <div>
        {display === 0 && props.commonScreen}
        {display === 1 && props.myDashboards}
        {display === 2 && props.customURL}
        {display === 3 && props.otherIdeas}
      </div>
    </StyledBody>
  );
};

export default AddScreenModalBody;
