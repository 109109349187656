import { useMutation, useQueryClient } from "react-query";
import { USER_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { deleteData } from "../../../utils/axiosHelpers";
import { User, Users } from "../UsersPageTypes";
import { KEY } from "./useUsers";

export default function useDeleteUser() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation((values) => deleteData(USER_URL, values), {
    onMutate: async (usersToDelete: any) => {
      const CALL_REASON =
        usersToDelete.length > 1
          ? `Deleted ${usersToDelete.length} Users`
          : `Delete User ${usersToDelete[0].name}`;

      await queryClient.cancelQueries(KEY);
      const previousUserList: any = queryClient.getQueryData(KEY);

      const newUserList = previousUserList.users.filter(
        (user: User) =>
          !usersToDelete
            .map(({ user_id }: User) => user_id)
            .includes(user.user_id)
      );

      queryClient.setQueryData(KEY, {
        users: newUserList,
      });

      return {
        previousUserList,
        newUserList,
        CALL_REASON,
      };
    },
    onSuccess: (data, _, context) => {
      onSuccessNotification(data, context?.CALL_REASON);

      queryClient.setQueryData(KEY, {
        users: context?.newUserList,
      });
    },
    onError: (data, _, context) => {
      onErrorNotification(data, context?.CALL_REASON);
      if (context?.previousUserList) {
        queryClient.setQueryData<Users>([KEY], context.previousUserList);
      }
    },

    onSettled: () => {
      queryClient.invalidateQueries(KEY);
    },
  });
}
