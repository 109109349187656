import { useMutation, useQueryClient } from "react-query";
import { TOOLS_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { deleteData } from "../../../utils/axiosHelpers";
import { Tool, Tools } from "../ToolsPageTypes";
import { KEY } from "./useTools";

export default function useDeleteTool() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    (values) => deleteData(`${TOOLS_URL}/${values.tool_id}`, values),
    {
      onMutate: async (toolToDelete: any) => {
        const CALL_REASON = `Delete Tool ${toolToDelete.name}`;
        const { tool_id } = toolToDelete;

        await queryClient.cancelQueries(KEY);
        const previousToolList: any = queryClient.getQueryData(KEY);
        console.log(previousToolList);

        const newToolList = previousToolList.tool.filter(
          (tool: Tool) => tool.tool_id !== tool_id
        );

        queryClient.setQueryData(KEY, {
          tool: newToolList,
        });

        return {
          previousToolList,
          newToolList,
          CALL_REASON,
        };
      },
      onSuccess: (data, _, context) => {
        onSuccessNotification(data, context?.CALL_REASON);
      },
      onError: (data, _, context) => {
        onErrorNotification(data, context?.CALL_REASON);
        if (context?.previousToolList) {
          queryClient.setQueryData<Tools>([KEY], context.previousToolList);
        }
      },

      onSettled: () => {
        queryClient.invalidateQueries(KEY);
      },
    }
  );
}
