import { useMutation, useQueryClient } from "react-query";
import { DEPLOY_DASHBOARD_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { postData } from "../../../utils/axiosHelpers";
import { KEY } from "./useDashboards";

export default function useDeployDashboard() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation((values) => postData(DEPLOY_DASHBOARD_URL, values), {
    onMutate: (values: any) => {
      const CALL_REASON = values
        ? "Mass Deploy Dashboards"
        : `Deploy Dashboard ${values?.name}`;

      return {
        CALL_REASON,
      };
    },
    onSuccess: (data, _, context) => {
      onSuccessNotification(data, context?.CALL_REASON);
    },
    onError: (data, _, context) => {
      onErrorNotification(data, context?.CALL_REASON);
    },
    onSettled: () => {
      queryClient.invalidateQueries(KEY);
    },
  });
}
