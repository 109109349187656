import { useMutation, useQueryClient } from "react-query";
import { RUN_REPORT_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { putData } from "../../../utils/axiosHelpers";
import { Report } from "../ReportsPageTypes";
import { KEY } from "./useReports";

export default function useRunReport() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation((values) => putData(RUN_REPORT_URL, values), {
    onMutate: async (reportToRun: any) => {
      const previousReportList: any = queryClient.getQueryData(KEY);

      const CALL_REASON = `Ran Report ${reportToRun.name}`;

      const newReportList = previousReportList.reports.map((report: Report) => {
        if (reportToRun.id === report.id) {
          const { running, ...reportRest } = report;
          return { ...reportRest, running: true };
        }
        return report;
      });

      console.log(newReportList);
      queryClient.setQueryData(KEY, {
        reports: newReportList,
      });

      return {
        CALL_REASON,
      };
    },
    onSuccess: (data, _, context) => {
      onSuccessNotification(data, context?.CALL_REASON);
    },
    onError: (data, _, context) => {
      onErrorNotification(data, context?.CALL_REASON);
    },

    onSettled: (data, _, context) => {
      queryClient.invalidateQueries(KEY);
    },
  });
}
