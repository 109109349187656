import { Select, Table } from "antd";
import React, { useContext, useState } from "react";
import { Dashboard } from "../../DashboardPageTypes";
import { DeployContext } from "../../Organisms/MassDeployDialogBox/DeployDialogBox";
const { Option } = Select;

export type Props = {
  dashboards: Dashboard[];
};

function PermissionsDropdown() {
  const onChange = () => {
    console.log("Hello World");
  };

  return (
    <Select defaultValue="readOnly" onChange={onChange}>
      <Option value="readOnly">Read Only</Option>
      <Option value="editable">Editable</Option>
      <Option value="disabled">Disabled</Option>
    </Select>
  );
}

export default function DeployDashboardTable({ dashboards }: Props) {
  const isMassDeploy = dashboards.length > 1;

  const [selectedRowKeysList, setSelectedRowKeysList] = useState<any[]>([]);
  const { updateSelectedDashboards } = useContext(DeployContext);

  const columns = [
    {
      title: "Local Name",
      dataIndex: "name",
    },
    {
      title: "Destination  Name",
      dataIndex: "name",
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Permissions",
      dataIndex: "Permissions",
      render: () => <PermissionsDropdown />,
    },
  ];

  return isMassDeploy ? (
    <Table
      rowSelection={{
        selectedRowKeys: selectedRowKeysList,
        onChange: (selectedRowKeys) => {
          setSelectedRowKeysList(selectedRowKeys);
          updateSelectedDashboards(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
          name: record.name,
        }),
      }}
      dataSource={dashboards.map((dashboard, i) => ({ key: i, ...dashboard }))}
      columns={columns}
    />
  ) : (
    <Table
      dataSource={dashboards.map((dashboard, i) => ({ key: i, ...dashboard }))}
      columns={columns}
    />
  );
}
