import { Button, Card } from "antd";
import SortPlaylist, {
  SortProps,
} from "../../Molecules/SortPlaylist/SortPlaylist";
import PlaylistTable, {
  PlaylistTableProps,
} from "../../Molecules/PlaylistTable/PlaylistTable";
import styled from "styled-components";
import React from "react";
import { IconAdd, IconPlay } from "../../../Shared/Atoms/FontIcons/FontIcons";

const StyledButton = styled(Button)`
  height: 40px;
`;

const StyledHR = styled.hr`
  width: calc(100% + 48px);
  margin: 10px -24px 24px;
  opacity: 25%;
`;

export type TableProps = SortProps &
  PlaylistTableProps & {
    addScreen: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    play: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    record: any;
  };

const PlaylistNestedTable = (props: any) => {
  return (
    <Card>
      <SortPlaylist
        record={props.record}
        addScreen={props.addScreen}
        setDuration={props.setDuration}
      />
      <PlaylistTable
        record={props.record}
        playlistData={props.playlistData}
        editBtn={props.editBtn}
        deleteBtn={props.deleteBtn}
        activeCheckbox={props.activeCheckbox}
      />
      <Button block onClick={props.addScreen} style={{ height: "60px" }}>
        <IconAdd size="1.5em" /> Add a Screen
      </Button>
      <StyledHR />
      <StyledButton type="primary" onClick={props.play}>
        <IconPlay size="1.5em" /> Play
      </StyledButton>
    </Card>
  );
};

export default PlaylistNestedTable;
