import { useMutation, useQueryClient } from "react-query";
import { PLAYLIST_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { putData } from "../../../utils/axiosHelpers";
import { Playlist } from "../ViewsPageTypes";
import { KEY } from "./usePlaylists";

export default function useSavePlaylist() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation((values) => putData(PLAYLIST_URL, values), {
    onMutate: async ({ playListChanges, playlistId }: any) => {
      await queryClient.cancelQueries(KEY);

      const previousPlaylists: any = queryClient.getQueryData(KEY);

      const CALL_REASON = `Save changes to playlist`;

      const newPlaylists = previousPlaylists.playlists.map(
        (playlist: Playlist) => {
          if (playlist.playlist_id === playlistId) {
            const { screens, ...playlistRest } = playlist;
            return {
              ...playlistRest,
              screens: playListChanges,
            };
          }
          return playlist;
        }
      );

      queryClient.setQueryData(KEY, {
        playlists: newPlaylists,
      });

      return {
        previousPlaylists,
        newPlaylists,
        CALL_REASON,
      };
    },
    onSuccess: (data, _, context) => {
      onSuccessNotification(data, context?.CALL_REASON);
    },
    onError: (data, _, context) => {
      onErrorNotification(data, context?.CALL_REASON);
      if (context?.previousPlaylists) {
        queryClient.setQueryData<Playlist>([KEY], context.previousPlaylists);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(KEY);
    },
  });
}
