import { useQuery } from "react-query";
import { NAGIOS_STATUS_URL } from "../../../api.config";
import { fetchData } from "../../../utils/axiosHelpers";
import useLogout from "../../LoginPage/hooks/useLogout";

export const KEY = "Fetch Nagios Status";

// TODO: Clean up this weird logout functionality ...
const fetchNagiosStatus = async ({ queryKey }) => {
  // eslint-disable-next-line
  const [_key, { logout }] = queryKey;
  try {
    return await fetchData(NAGIOS_STATUS_URL);
  } catch (err: any) {
    if (err?.response?.status === 401) {
      logout();
    }
  }
};

export const preFetchNagiosStatus = (queryClient) =>
  queryClient.prefetchQuery([KEY, { logout: () => { return; } }], fetchNagiosStatus);

export default function useNagiosStatus() {
  const { mutate: logout } = useLogout();
  return useQuery([KEY, { logout }], fetchNagiosStatus, {
    refetchIntervalInBackground: true,
    refetchInterval: 30000,
  });
}
