import { useQuery } from "react-query";
import { FAVORITE_REPORT_URL } from "../../../api.config";
import { fetchData } from "../../../utils/axiosHelpers";

export const KEY = "Fetch Favorite Reports";

const fetchReports = async () => fetchData(FAVORITE_REPORT_URL);

export const preFetchFavoriteReports = (queryClient) =>
  queryClient.prefetchQuery(KEY, fetchReports);

export function useFavoriteReports() {
  return useQuery([KEY], () => fetchReports(), {
    select: (data) => data.reports,
  });
}
