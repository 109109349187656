import React from "react";
import styled from "styled-components";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";
import {
  IconIdeas,
  IconWebGlobal,
} from "../../../Shared/Atoms/FontIcons/FontIcons";
import IconButton from "../../../Shared/Molecules/IconButton/IconButton";

export type ButtonsProps = {
  clickedCommon: () => void;
  clickedDashboards: () => void;
  clickedURL: () => void;
  clickedIdeas: () => void;
};

const FlexBox = styled.div`
  display: flex;
`;

const AddScreenButtons = (props: ButtonsProps) => {
  const theme = useMasterTheme().theme;
  return (
    <FlexBox>
      <IconButton
        color1={theme.iconLightGreenBG}
        color2={themeVariables.colorGreenDark}
        num="XI"
        label="Common Screens"
        onClick={props.clickedCommon}
      />
      <IconButton
        color1={theme.iconLightBlueBG}
        color2={themeVariables.colorBlueDark}
        num="XI"
        label="My Dashboards"
        onClick={props.clickedDashboards}
      />
      <IconButton
        color1={theme.iconLightYellowBG}
        icon={<IconWebGlobal color={themeVariables.colorYellowDark} />}
        label="Custom URL"
        onClick={props.clickedURL}
      />
      <IconButton
        color1={theme.iconLightPurpleBG}
        icon={<IconIdeas color={themeVariables.colorPurpleDark} />}
        label="Other Ideas"
        onClick={props.clickedIdeas}
      />
    </FlexBox>
  );
};

export default AddScreenButtons;
