import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./index.css";
import worker from "./mockServer";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import MSWToggleButton from "./components/MSWToggleButton";

export const queryClient = new QueryClient();
const isDev = process.env.NODE_ENV === "development";

async function root() {
  if (isDev || process.env.REACT_APP_USE_MOCK === "on") {
    await worker.start();
  }
  return ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools />
      {isDev && (
        <div>
          <ReactQueryDevtools />
          <MSWToggleButton />
        </div>
      )}
    </QueryClientProvider>,
    document.getElementById("root")
  );
}

root();
