import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { LOGIN_URL } from "../../../api.config";
import { useAuth } from "../../../context";
import { useNotifications } from "../../../hooks";
import { postData } from "../../../utils/axiosHelpers";

export default function useLogin() {
  const history = useHistory();
  const { setIsAuthenticated } = useAuth();
  const { onErrorNotification } = useNotifications();

  return useMutation((values) => postData(LOGIN_URL, values), {
    onSuccess: (data) => {
      const { token } = data;
      localStorage.setItem("token", token);
      history.push("/home");
      setIsAuthenticated(true);
    },
    onError: (data) => onErrorNotification(data, "Login Failed"),
  });
}
