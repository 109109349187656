import { Form, Select, Input } from "antd";
import { Dashlet } from "../../../DashboardPage/DashboardPageTypes";
const { Option } = Select;

export type DashletProperty = {
  name: string;
  type: string;
};

export type Props = {
  dashletProperties: DashletProperty[];
  initalData: any[];
  dashletToEdit?: Dashlet | null;
};
export type Entry = string[];

export default function DashletForm({
  dashletProperties,
  initalData,
  dashletToEdit,
}: Props) {
  const filterByType = (object: Object, type: string) => {
    const entries: Entry[] = Object.entries(object);

    return entries.reduce((previousValue, currentValue) => {
      const [key, value] = currentValue;

      if (typeof value === type) {
        return previousValue !== undefined ? [...previousValue, key] : [key];
      }
      return previousValue;
    }, []);
  };

  const colorList = [
    "nivo",
    "category10",
    "accent",
    "dark2",
    "paired",
    "pastel1",
    "pastel2",
    "set1",
    "set2",
    "set3",
    "brown_blueGreen",
    "purple_redGreen",
    "pink_yellowGreen",
    "purple_orange",
    "red_blue",
    "red_grey",
    "red_yellow_blue",
    "red_yellow_green",
    "spectral",
    "blues",
    "greens",
    "greys",
    "oranges",
    "purples",
    "reds",
    "blue_green",
    "blue_purple",
    "orange_red",
    "purple_blue_green",
    "purple_blue",
    "purple_red",
    "red_purple",
    "yellow_green_blue",
    "yellow_green",
    "yellow_orange_brown",
    "yellow_orange_red",
  ];

  return (
    <>
      {dashletProperties.map(({ name, type }: DashletProperty, i) => {
        const stringKeys = filterByType(
          initalData ? initalData[0] : {},
          "string"
        );
        const numberKeys = filterByType(
          initalData ? initalData[0] : {},
          "number"
        );

        const keys =
          name === "index_by"
            ? stringKeys
            : name === "keys"
            ? numberKeys
            : name === "graph_color"
            ? colorList
            : Object.keys(initalData ? initalData[0] : {});

        return (
          <Form.Item
            label={name}
            name={name}
            initialValue={
              dashletToEdit
                ? dashletToEdit.properties[name]
                : type === "keys"
                ? [keys[0]]
                : keys[0]
            }
            key={i}
          >
            {type === "number" ? (
              <Input />
            ) : (
              <Select mode={type === "keys" ? "multiple" : undefined}>
                {keys.map((key, i) => (
                  <Option value={key} key={i}>
                    {key}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        );
      })}
    </>
  );
}
