import { Collapse } from "antd";
import { IconChevronUp, IconAdd } from "../FontIcons/FontIcons";

const Accordion = (props) => (
  <Collapse
    accordion
    className="styledAccordion"
    bordered={false}
    expandIcon={({ isActive }) => (isActive ? <IconChevronUp /> : <IconAdd />)}
    ghost
  >
    {props.children}
  </Collapse>
);

export default Accordion;
