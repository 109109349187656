import { Card, Button } from "antd";
import styled from "styled-components";

export type HelpLinkProps = {
  title: string;
  linkData: {
    name: string;
    url: string;
  }[];
};

const HelpCard = styled(Card)`
  & .ant-card-body {
    height: 100%;
    padding: 24px 34px;
    & h4 {
      height: 50px;
      font-size: 16px;
    }
    & div {
      overflow-x: auto;
      max-height: 300px;
      & a {
        text-align: left;
        padding: 0px;
      }
    }
  }
`;

const HelpLinks = (props: HelpLinkProps) => {
  return (
    <HelpCard>
      <h4>{props.title}</h4>
      <div>
        {props.linkData.map((link) => (
          <Button block type="link" href={link.url}>
            {link.name}
          </Button>
        ))}
      </div>
    </HelpCard>
  );
};

export default HelpLinks;
