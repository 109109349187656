import { ResponsivePie } from "@nivo/pie";
import { Skeleton } from "antd";
import { upperCase } from "lodash";
import React from "react";
import { useQuery } from "react-query";
import { AVAILABILITY_DASHLET_URL } from "../../../../api.config";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import { fetchData } from "../../../../utils/axiosHelpers";
import { dashletMockData } from "../../DashletMockData";
import { DashletProps } from "../../DashletViewPageTypes";

export default function AvailabilityDashlet({
  dashletId,
  demo,
  properties,
}: DashletProps) {
  const theme = useMasterTheme().theme;
  const formatData = (data) =>
    Object.entries(data).reduce(
      (prev: any, current: any) => {
        const [NAME, VALUE] = current;
        if (NAME.includes("host")) {
          const { host, ...rest } = prev;
          return {
            host: [
              ...host,
              {
                id: upperCase(NAME.replace("avg_host_", " ")),
                label: upperCase(NAME.replace("avg_host_", " ")),
                value: VALUE,
              },
            ],
            ...rest,
          };
        }

        const { service, ...rest } = prev;
        return {
          service: [
            ...service,
            {
              id: upperCase(NAME.replace("avg_service_", " ")),
              label: upperCase(NAME.replace("avg_service_", " ")),
              value: VALUE,
            },
          ],
          ...rest,
        };
      },
      { host: [], service: [] }
    );

  let { data, isLoading } = useQuery(
    ["Fetch Availability", dashletId],
    async () => fetchData(AVAILABILITY_DASHLET_URL),
    demo
      ? {
          enabled: false,
        }
      : {
          select: (data) => formatData(data),
        }
  );

  if (isLoading) {
    return <Skeleton />;
  }

  data = demo ? formatData(dashletMockData["availability"]) : data;

  return (
    <ResponsivePie
      data={data[properties.type]}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      theme={{
        textColor: theme.typographyPrimaryColor,
        tooltip: {
          container: {
            background: theme.cardBackgroundColor,
          },
        },
      }}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
    />
  );
}
