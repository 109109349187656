import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Menu,
  Space,
  Input,
  Divider,
  Button,
  Popover,
  Drawer,
  AutoComplete,
  Tooltip
} from "antd";
import * as Icon from "../../../Shared/Atoms/FontIcons/FontIcons";
import styled from "styled-components";
import { Fragment } from "react";
import { includes } from "lodash";
import { useAuth, NagiosContext, useNotifications } from "../../../../context";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";
import Logo from "../../../Shared/Atoms/Logo/Logo";
import FavoritesMenu from "../../../Shared/Molecules/FavoritesMenu/FavoritesMenu";
import HostStatus from "../../../Shared/Molecules/HostStatus/HostStatus";
import NagiosStatus from "../../../Shared/Molecules/NagiosStatus/NagiosStatus";
import NotificationsMenu from "../../../Shared/Molecules/NotificationsMenu/NotificationsMenu";
import ProfileMenu from "../../../Shared/Molecules/ProfileMenu/ProfileMenu";
import ServiceStatus from "../../../Shared/Molecules/ServiceStatus/ServiceStatus";
import ShareMenu from "../../../Shared/Molecules/ShareMenu/ShareMenu";
import useHostsOverview from "../../hooks/useHostsOverview";
import useNagiosStatus from "../../hooks/useNagiosStatus";
import useSearch from "../../hooks/useSearch";
import useServicesOverview from "../../hooks/useServicesOverview";
import useSideNav from "../../hooks/useSideNav";
import useUser from "../../hooks/useUser";
import useFavorites from "../../hooks/useFavorites";
import { useSideNavMask } from "../../../../context/SidenavMaskContext";

const { Search } = Input;

const SquareButton = styled(Button)`
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 10px !important;
  height: 40px !important;
  font-size: 16px !important;
`;

const StatusPopover = styled(Popover)`
  font-weight: 500;
  color: #fff;
  line-height: 2em;
  &.ant-popover-open {
    background-color: #15161E;
  }
`;

const StyledButton = styled(Button)`
  padding: 7px 14px;
  line-height: 1.5;
  font-weight bold;
  color: #FFF;
  height: 40px !important;
  &.ant-popover-open {
    border-color: ${themeVariables.colorPrimary};
    &.ant-btn-primary {
      background-color: ${themeVariables.colorPrimary};
    }
  }
`;

const StatusButton = styled(Button)`
  border: none;
  border-color: transparent;
  border-radius: 0px;
  height: 65px;
  margin-top: -1px;
  padding: 0 20px;
  :hover,:focus,:active {
    color: #FFF;
    outline: none;
    box-shadow: none;
  }
`;

const StyledAutoComplete = styled(AutoComplete)`
  width: 300px;
  .ant-input {
    height: 40px;
  }
  .ant-select-selector {
    border-radius: 8px;
  }
`;

const Header = () => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <FullHeader /> : <BasicHeader />;
};

const BasicHeader = () => {
  return (
    <Menu
      className="mainHeader"
      theme="dark"
      style={{
        padding: "13px 0 0 10px",
        height: "65px"
      }}
    >
      <Space>
        <Link to="/">
          <Logo />
        </Link>
      </Space>
    </Menu>
  );
};

const FullHeader = () => {
  const data = useContext(NagiosContext);

  const [searchValue, setSearchValue] = useState('');

  const { data: hostData } = useHostsOverview();
  const { data: serviceData } = useServicesOverview();
  const { notifications } = useNotifications();
  const { data: userData } = useUser();
  const { data: nagiosStatusData } = useNagiosStatus();
  const { data: favoritesData } = useFavorites();
  const { search } = useSearch();
  const { flattenSideNav } = useSideNav();
  const [isVisible, setIsVisible] = useState(false);
  const { setShowMask, showMask } = useSideNavMask();

  const shareData = {
    permalink: "#",
    schedule: "#",
  };

  const hasNotifications = notifications ? true : false;

  const iconColor = {
    primary: themeVariables.colorPrimary,
    ok: themeVariables.colorSuccess,
    warning: themeVariables.colorWarning,
    bad: themeVariables.colorDanger,
    info: themeVariables.colorInfo,
  };

  const getStatus = (status) => {
    switch (status) {
      case "OK":
        return iconColor.ok;
      case "WARNING":
        return iconColor.warning;
      case "CRITICAL":
        return iconColor.bad;
      default:
        return iconColor.info;
    }
  };

  const openDrawer = () => {
    const bool = !isVisible;
    setShowMask(!showMask);
    setIsVisible(bool);
  };

  const onSelect = (value) => {
    if (flattenSideNav.some((sideNavItem) => includes(sideNavItem, value))) {
      search(value);
      setSearchValue('');
    }
  };
  return (
    <Fragment>
      <div
        className="mainHeader ant-menu ant-menu-root ant-menu-vertical ant-menu-dark mainHeader"
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
          height: "65px",
          position: "relative",
          zIndex: 1003,
        }}
      >
        <Space align="center" style={{ marginRight: "auto" }}>
          <Link to="/home">
            <Logo />
          </Link>
          <StyledAutoComplete
            onSelect={onSelect}
            options={flattenSideNav.map(({ name }) => ({
              label: name,
              value: name,
            }))}
            value={searchValue}
            onChange={(v) => setSearchValue(v)}
            notFoundContent="Nothing Found"
            filterOption={(inputValue, option) =>
              option!.value
                ?.toUpperCase()
                .indexOf(inputValue?.toUpperCase()) !== -1
            }
            className="headerSearch"
          >
            <Search placeholder="Search" size="large" onSearch={onSelect} />
          </StyledAutoComplete>
          <Tooltip placement="bottom" title={<span>Use the search bar to look for a host, service, or any page in XI, selecting an item from the list will direct you to that page.<br /><br />Click the search or hit enter to search the service status page.</span>}>
            <span><Icon.IconIdeas /></span>
          </Tooltip>
        </Space>
        <Space
          split={<Divider type="vertical" />}
          align="center"
          style={{ margin: "0 auto" }}
        >
          <StatusPopover
            placement="bottom"
            trigger="hover"
            align={{offset: [0, -5]}}
            content={<HostStatus data={hostData ? hostData : undefined} />}
          >
            <StatusButton>
              <Icon.IconCircle
                size="1.1em"
                marginRight="10px"
                color={getStatus(hostData?.status)}
              />
              Host Satus
            </StatusButton>
          </StatusPopover>
          <StatusPopover
            placement="bottom"
            trigger="hover"
            align={{offset: [0, -5]}}
            content={
              <ServiceStatus data={serviceData ? serviceData : undefined} />
            }
          >
            <StatusButton>
              <Icon.IconCircle
                size="1.1em"
                marginRight="10px"
                color={getStatus(serviceData?.status)}
              />
              Service Status
            </StatusButton>
          </StatusPopover>
          <StatusPopover
            placement="bottom"
            trigger="hover"
            align={{offset: [0, -5]}}
            content={<NagiosStatus data={nagiosStatusData} />}
          >
            <StatusButton>
              <Icon.IconCircle
                size="1.1em"
                marginRight="10px"
                color={getStatus(data.nagiosStatus.status)}
              />
              Nagios Status
            </StatusButton>
          </StatusPopover>
        </Space>
        <Space style={{ marginLeft: "auto" }}>
          {hasNotifications ? (
            <Popover
              placement="bottom"
              trigger="click"
              align={{offset: [0, 7]}}
              content={<NotificationsMenu data={notifications} />}
            >
              <SquareButton ghost>
                <Icon.IconNotification size="1em">
                  {notifications.length > 0 ? <Icon.IconCircle color={iconColor.info} /> : <Fragment />}
                </Icon.IconNotification>
              </SquareButton>
            </Popover>
          ) : null}
          <Popover
            placement="bottom"
            trigger="click"
            align={{offset: [0, 7]}}
            content={<FavoritesMenu data={favoritesData?.favorites} />}
          >
            <StyledButton ghost>
              <Icon.IconStar /> Favorites
            </StyledButton>
          </Popover>
          <Popover
            placement="bottom"
            trigger="click"
            align={{offset: [0, 7]}}
            content={<ShareMenu data={shareData} />}>
            <StyledButton type="primary">
              <Icon.IconShare /> Share
            </StyledButton>
          </Popover>
          <StyledButton ghost onClick={openDrawer}>
            <Icon.IconGrid /> Profile
          </StyledButton>
        </Space>
      </div>
      <Drawer
        placement="right"
        width="320px"
        className="profileDrawer drawerscroll"
        closable={false}
        onClose={openDrawer}
        visible={isVisible}
        getContainer=".mainframe"
      >
        <ProfileMenu data={userData?.user} onLinkClick={openDrawer} />
      </Drawer>
    </Fragment>
  );
};

export default Header;
