import { useMutation, useQueryClient } from "react-query";
import { useNotifications } from "../../../hooks";
import { putData } from "../../../utils/axiosHelpers";
import { KEY, FETCH_DASHBOARD_KEY } from "./useDashboards";
import { DASHBOARD_URL } from "../../../api.config";
import { Dashboard } from "../DashboardPageTypes";
import { find } from "lodash";

export default function useEditDashboard() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    (values) => {
      const { tagList, ...rest } = values;
      const read_only = tagList.includes("read_only") ? 1 : 0;
      const publicValue = tagList.includes("public") ? 1 : 0;
      const editable = tagList.includes("editable") ? 1 : 0;

      return putData(`${DASHBOARD_URL}/${values.dashboard_id}`, {
        dashboards: {
          ...rest,
          read_only,
          public: publicValue,
          editable,
        },
      });
    },
    {
      onMutate: async (dashboardToChange: Dashboard) => {
        const CALL_REASON = `Edit Dashboard ${dashboardToChange.name}`;
        await queryClient.cancelQueries(KEY);
        await queryClient.cancelQueries([
          FETCH_DASHBOARD_KEY,
          dashboardToChange.dashboard_id,
        ]);
        const previousDashboards: any = queryClient.getQueryData(KEY);

        const newDashboardList = previousDashboards.dashboards.map(
          (dashboard: Dashboard) => {
            if (dashboard.dashboard_id === dashboardToChange.dashboard_id) {
              return { ...dashboard, ...dashboardToChange };
            }
            return dashboard;
          }
        );

        queryClient.setQueryData(KEY, {
          dashboards: newDashboardList,
        });

        queryClient.setQueryData(
          [FETCH_DASHBOARD_KEY, dashboardToChange.dashboard_id],
          {
            dashboards: find(newDashboardList, {
              dashboard_id: dashboardToChange.dashboard_id,
            }),
          }
        );

        return { previousDashboards, newDashboardList, CALL_REASON };
      },
      onSuccess: (data, _, context) => {
        onSuccessNotification(data, context?.CALL_REASON);
      },
      onError: (data, _, context) => {
        onErrorNotification(data, context?.CALL_REASON);
        if (context?.previousDashboards) {
          queryClient.setQueryData<Dashboard[]>(
            KEY,
            context.previousDashboards
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(KEY);
      },
    }
  );
}
