import React from "react";

export default function ExtensionsSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 55 55"
    >
      <g>
        <g>
          <path
            fill="#f0e1fd"
            d="M0 18C0 8.059 8.059 0 18 0h19c9.941 0 18 8.059 18 18v19c0 9.941-8.059 18-18 18H18C8.059 55 0 46.941 0 37z"
          />
        </g>
        <g>
          <g>
            <g>
              <path d="M42.22 32.003a3 3 0 0 1-2.22 1h-3v7.833H14v-6.832h1a5 5 0 0 0 5-5.512A5.15 5.15 0 0 0 14.81 24H14v-6.172h9v-1.83a3.002 3.002 0 0 1 1-2.221 3 3 0 0 1 2.31-.78 3.13 3.13 0 0 1 2.69 3.19v1.641h8v9.173h2.81A3.13 3.13 0 0 1 43 29.692a3.002 3.002 0 0 1-.78 2.311zm-2.41-7.007H39v-7.17a2 2 0 0 0-2-2h-6a5.14 5.14 0 0 0-4.49-4.83 5 5 0 0 0-5.51 4.83h-7a2 2 0 0 0-2 2v8.17h2.81a3.13 3.13 0 0 1 3.19 2.69 3 3 0 0 1-.78 2.31 3 3 0 0 1-2.22 1h-3v8.83a2 2 0 0 0 2 2h23a2 2 0 0 0 2-2v-5.83h1a5 5 0 0 0 5-5.51 5.15 5.15 0 0 0-5.19-4.49z" />
            </g>
          </g>
          <g />
        </g>
      </g>
    </svg>
  );
}
