import { Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import { UpCircleOutlined } from "@ant-design/icons";
import { createContext, useState } from "react";
import { useTranslator, useRole } from "../../../../hooks";
import { CardTitle, ErrorText } from "../../../Shared";
import { DeployDashboardTable, RecipientTable } from "../../Atoms";
import { isLengthZero } from "../../../../utils";
import useDashboards from "../../hooks/useDashboards";
import { Dashboard } from "../../DashboardPageTypes";
import useDeployDashboard from "../../hooks/useDeployDashboard";
import { useGlobalModal } from "../../../../context/GlobalModalContext";

export type Props = {
  dashboardName?: string;
};

type DeployObject = {
  selectedUsers: string[];
  selectedDashboards: Dashboard[];
  updateSelectedUsers: Function;
  updateSelectedDashboards: Function;
};

export const DeployContext = createContext<DeployObject>({
  selectedUsers: [],
  selectedDashboards: [],
  updateSelectedUsers: Function,
  updateSelectedDashboards: Function,
});

export default function DeployDialogBox({ dashboardName }: Props) {
  const isMassDeploy = dashboardName ? false : true;
  const { data } = useDashboards({ name: "", params: "" });

  const {
    CANCEL_BUTTON_NAME,
    DEPLOY_TITLE,
    DEPLOY_BUTTON_NAME,
    MASS_DEPLOY_TITLE,
    NO_SELECTED_DASHBOARDS_ERROR,
    NO_SELECTED_USERS_ERROR,
  } = useTranslator("DeployDialogBox");

  const { renderIfAdmin } = useRole();
  const {
    toggleMassDashboardModalOpen,
    massDeployDashboardModalOpen,
  } = useGlobalModal();

  const { mutate: deployDashboard } = useDeployDashboard();

  const [userErrorMessage, setUserErrorMessage] = useState("");
  const [dashboardErrorMessage, setDashboardErrorMessage] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedDashboards, setSelectedDashboards] = useState(
    isMassDeploy ? [] : data.filter(({ name }) => name === dashboardName)
  );

  const onClickDeployButton = () => {
    setUserErrorMessage("");
    setDashboardErrorMessage("");

    if (isMassDeploy && isLengthZero(selectedDashboards))
      setDashboardErrorMessage(NO_SELECTED_DASHBOARDS_ERROR);

    if (isLengthZero(selectedUsers)) {
      setUserErrorMessage(NO_SELECTED_USERS_ERROR);
    } else {
      deployDashboard({
        selectedDashboards,
        selectedUsers,
      });
      toggleMassDashboardModalOpen();
    }
  };

  const onCancelButtonClick = () => toggleMassDashboardModalOpen();
  return renderIfAdmin(
    <>
      <Modal
        visible={massDeployDashboardModalOpen}
        footer={[
          <Button key="back" onClick={onCancelButtonClick}>
            {CANCEL_BUTTON_NAME}
          </Button>,
          <Button
            data-testid="DeployDashboardsButton"
            key="submit"
            type="primary"
            icon={<UpCircleOutlined />}
            onClick={onClickDeployButton}
          >
            {isMassDeploy
              ? DEPLOY_BUTTON_NAME
              : `${DEPLOY_TITLE} ${dashboardName}`}
          </Button>,
        ]}
      >
        <CardTitle>
          {isMassDeploy
            ? MASS_DEPLOY_TITLE
            : `${DEPLOY_TITLE} ${dashboardName}`}
        </CardTitle>

        {!isLengthZero(dashboardErrorMessage) && (
          <ErrorText>{dashboardErrorMessage}</ErrorText>
        )}
        <DeployContext.Provider
          value={{
            selectedUsers,
            selectedDashboards,
            updateSelectedUsers: setSelectedUsers,
            updateSelectedDashboards: setSelectedDashboards,
          }}
        >
          <DeployDashboardTable
            dashboards={
              isMassDeploy
                ? data
                : data.filter(({ name }) => name === dashboardName)
            }
          />
          {!isLengthZero(userErrorMessage) && (
            <ErrorText>{userErrorMessage}</ErrorText>
          )}

          <RecipientTable />
        </DeployContext.Provider>
      </Modal>
    </>
  );
}
