import { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";

export default function usePlaylistLoop(playlist: any) {
  const [firstStart, setFirstStart] = useState(false);
  const [currentScreenIndex, setCurrentScreenIndex] = useState(0);
  const [currentScreen, setCurrentScreen] = useState<any>(
    Object.values(playlist)[0]
  );
  const time = new Date();
  time.setSeconds(time.getSeconds() + currentScreen?.play_duration);

  const { isRunning, seconds, start, pause, restart } = useTimer({
    expiryTimestamp: time,
    onExpire: () => {
      const newIndex =
        currentScreenIndex === Object.values(playlist).length - 1
          ? 0
          : currentScreenIndex + 1;

      const newScreen = Object.values(playlist)[newIndex];

      setCurrentScreenIndex(newIndex);
      setCurrentScreen(newScreen);
    },
  });

  useEffect(() => {
    if (!isRunning && seconds === 0) {
      const time = new Date();
      time.setSeconds(time.getSeconds() + currentScreen?.play_duration);

      restart(time, true);
    }
  }, [currentScreen, restart, isRunning, seconds]);

  return {
    currentScreen,
    isRunning,
    currentTime: seconds,
    toggleIsPlaying: () => (isRunning ? pause() : start()),
    firstStart: () => {
      if (!firstStart) {
        start();
        setFirstStart(true);
      }
    },
    start,
    pause,
  };
}
