import { Space } from "antd";
import { HeaderPopup, StyledButton } from "../../Atoms/HeaderPopup/HeaderPopup";

type ShareMenuProps = {
  data:
    | {
        permalink: string;
        schedule: string;
      }
    | undefined;
};

const ShareMenu = (props: ShareMenuProps) => {
  return (
    <HeaderPopup width="190px">
      {props.data && (
        <Space direction="vertical">
          <StyledButton value={props.data.permalink} type="text" block>
            Get Permalink
          </StyledButton>
          <StyledButton value={props.data.schedule} type="text" block>
            Schedule Page
          </StyledButton>
        </Space>
      )}
    </HeaderPopup>
  );
};

export default ShareMenu;
