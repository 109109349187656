import { useState } from "react";
import useNagiosInfo from "./useNagiosInfo";

export default function useNagiosInstallStatus() {
  //console.log(localStorage.getItem("installed"));
  const [isInstalled, setIsInstalled] = useState(
    localStorage.getItem("installed")
      ? JSON.parse(String(localStorage.getItem("installed")))
      : null
  );
  const { data, isSuccess } = useNagiosInfo(isInstalled);

  if (isSuccess && isInstalled !== data.is_installed) {
    localStorage.setItem("installed", JSON.stringify(data.is_installed));
    setIsInstalled(data.is_installed);
  }

  return {
    isInstalled,
  };
}
