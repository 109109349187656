import styled from "styled-components";
import { Select } from "antd";
import useViewPageEvents from "../../hooks/useViewsPageEvents";

const { Option } = Select;

const StyledDiv = styled.div`
  line-height: 1.2;
  & > h4 {
    font-size: 14px;
    margin-bottom: 8px;
  }
`;

export type TransitionProps = {
  record: any;
};

const TransitionStyle = (props: TransitionProps) => {
  const transitions = [
    "fade In",
    "fade In Left",
    "fade In Right",
    "fade In Up",
    "fade In Down",
    "zoom In Left",
    "zoom In Right",
  ];

  const { onEditTransitionStyle } = useViewPageEvents();
  return (
    <StyledDiv>
      <Select
        style={{ width: "140px", textTransform: "capitalize" }}
        placeholder="Select Style"
        onChange={(value) => onEditTransitionStyle(props.record, value)}
        defaultValue={props.record.transition}
      >
        {transitions.map((el) => (
          <Option
            key={el}
            style={{ textTransform: "capitalize" }}
            value={el.replace(/\s/g, "")}
          >
            {el}
          </Option>
        ))}
      </Select>
    </StyledDiv>
  );
};

export default TransitionStyle;
