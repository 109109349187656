import { useQuery } from "react-query";
import { WIZARDS_URL } from "../../../api.config";
import { fetchData } from "../../../utils/axiosHelpers";

export const KEY = "Fetch Wizards";

const fetchWizards = async () => fetchData(WIZARDS_URL);

export const preFetchWizards = (queryClient) =>
  queryClient.prefetchQuery(KEY, fetchWizards);

export default function useWizards(options?: any) {
  return useQuery(KEY, fetchWizards, {
    staleTime: Infinity,
    ...options,
    select: (data) => data.wizards,
  });
}
