import { DefaultTheme } from "styled-components";
import { hexToRgb } from "../util/StyleUtils";

//Put shared styled-component variables here.
// To override Ant Design theme variables, assign variables in styles/themes/antd -> light-theme.less -> dark-theme.less and run 'yarn update-themes' to re-compile css

// Colors - naming convention: color + main color category if any, followed by Descriptor (https://chir.ag/projects/name-that-color/#FDE1E1)
// For AntD themeing, these colors are maintained in variables.less file.

const colorBlack = "#000000";
const colorBlackCinder = "#101118";
const colorBlackCharade = "#232632";
const colorBluePale = "#edf2f9";
const colorBlueSlate = "#8a97a9";
const colorBlueWaterloo = "#80869d";
const colorGrayDark = "#333333";
const colorGrayLight = "#444444";
const colorGrayLight1 = "#666666";
const colorGrayLight2 = "#979797";
const colorGrayLight3 = "#d9d9d9";
const colorGrayLight4 = "#DBE2E9";
const colorGreenDark2 = "#2dcb75";
const colorGreenMeadow = "#800080";
const colorGreenShamrock = '#2DCB75"';
const colorWhite = "#ffffff";
const colorPinkCinderella = "#FDE1E1";
const colorOrange1 = "#ffbe38";

const colorPrimary = "#0b89ff";
const colorSuccess = "#2dcb75";
const colorOk = "#2dcb75";
const colorWarning = "#ffad50";
const colorCritical = "#e83e3e";
const colorPending = "#E5EBF3";
const colorUnhandled = "#FFBE38";
const colorProblems = "#FFE4AF";
const colorInfo = "#bf55ff";
const colorDanger = "#e83e3e";

//color variables, primarily used in icons
const colorBlueDark = "#0889ff";
const colorBlueLight = "#e5f7ff";
const colorGreenDark = "#16c79b";
const colorGreenLight = "#dbfbf5";
const colorPinkDark = "#a942e8";
const colorPinkLight = "#F0E1FD";
const colorPurpleDark = "#492fd3";
const colorPurpleLight = "#eceafb";
const colorPurpleLight2 = "#d5d5f3";
const colorRedDark = "#e8583e";
const colorRedLight = "#fde5e1";
const colorYellowDark = "#e8903e";
const colorYellowLight = "#fdf0e1";

const defaultBorderRadius = "8px";

//an example of a typescript-based mixin

const boxShadowDefault = (rgbColor: string = colorPrimary) =>
  `0 2px 4px 0 rgba(0,0,0,0.50), 1px 1px 5px 0 rgba(${hexToRgb(
    rgbColor
  )},0.08)`;

const darkThemeBoxShadow = `1px 1px 5px 0 rgba(23,45,83,0.08)`;

const draggingBoxShadow = `10px 7px 6px 0 rgba(0,0,0,0.10), 15px 15px 10px 0 rgba(0,0,0,0.10)`;

export const themeVariables = {
  colorBlack,
  colorBlackCinder,
  colorBlackCharade,
  colorBlueDark,
  colorBlueLight,
  colorBluePale,
  colorBlueSlate,
  colorBlueWaterloo,
  colorGrayDark,
  colorGrayLight,
  colorGrayLight1,
  colorGrayLight2,
  colorGrayLight3,
  colorGrayLight4,
  colorGreenDark,
  colorGreenDark2,
  colorGreenLight,
  colorGreenMeadow,
  colorGreenShamrock,
  colorPinkDark,
  colorPinkLight,
  colorPurpleDark,
  colorPurpleLight,
  colorPurpleLight2,
  colorRedDark,
  colorRedLight,
  colorYellowDark,
  colorYellowLight,
  colorWhite,
  colorPinkCinderella,
  colorOrange1,
  colorPrimary,
  colorSuccess,
  colorOk,
  colorWarning,
  colorCritical,
  colorPending,
  colorUnhandled,
  colorProblems,
  colorDanger,
  colorInfo,
  defaultBorderRadius,
  draggingBoxShadow,
};

//Export light theme - DefaultTheme definition is in progress, and lives in typings/styled-components.d.ts
export const styledComponentLightTheme: DefaultTheme = {
  primaryColor: colorPrimary,
  secondaryColor: colorGreenMeadow,
  warningColor: colorRedDark,
  typographyPrimaryColor: colorGrayDark,
  typographySecondaryColor: colorGrayLight1,
  defaultBorderRadius: defaultBorderRadius,
  boxShadowDefault: boxShadowDefault(),
  backgroundColorPrimary: colorWhite,
  cardBackgroundColor: colorWhite,
  tableBodyBackgroundColor: colorBluePale,
  tableHeadBackgroundColor: colorBlueSlate,
  paginationBorderColor: colorGrayLight3,
  paginationActiveColor: colorWhite,
  paginationActiveBackgroundColor: colorPrimary,
  iconPrimaryColor: colorPrimary,
  iconLightGreenBG: colorGreenLight,
  iconLightBlueBG: colorBlueLight,
  iconLightRedBG: colorRedLight,
  iconLightYellowBG: colorYellowLight,
  iconLightPinkBG: colorPinkLight,
  iconLightPurpleBG: colorPurpleLight,
  iconLightPurpleBG2: colorPurpleLight2,
  marginSmall: "10px",
  bodyBackgroundColor: colorBluePale,
};

//Export dark theme, overriding only the theme variables that need to be different.

export const styledComponentDarkTheme: DefaultTheme = {
  ...styledComponentLightTheme,
  typographyPrimaryColor: colorWhite,
  typographySecondaryColor: colorGrayLight2,
  boxShadowDefault: darkThemeBoxShadow,
  backgroundColorPrimary: colorBlackCinder,
  cardBackgroundColor: colorBlackCharade,
  tableBodyBackgroundColor: `rgba(${hexToRgb(colorBlueWaterloo)},0.10)`,
  tableHeadBackgroundColor: colorBlack,
  paginationActiveColor: colorGrayLight3,
  paginationActiveBackgroundColor: colorPrimary,
  iconPrimaryColor: colorPinkCinderella,
  iconLightGreenBG: colorBlackCinder,
  iconLightBlueBG: colorBlackCinder,
  iconLightRedBG: colorBlackCinder,
  iconLightYellowBG: colorBlackCinder,
  iconLightPinkBG: colorBlackCinder,
  iconLightPurpleBG: colorBlackCinder,
  iconLightPurpleBG2: colorBlackCinder,
  bodyBackgroundColor: colorBlackCinder,
};
