import { Button, Card, Col, Input, Popover, Row, Space } from "antd";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToggle } from "../../hooks";
import useCreateFavorite from "../Frame/hooks/useCreateFavorite";
import StyledButton from "../Shared/Atoms/Button/StyledButton";
import Actions from "../Shared/Molecules/Actions/Actions";
import PageHeader from "../Shared/Organisms/PageHeader/PageHeader";
import useUsers from "./hooks/useUsers";
import useUsersPageEvents from "./hooks/useUsersPageEvents";
import { EmailUserModal } from "./Molecules/EmailUserModal";
import { UserModal } from "./Molecules/UserModal";
import { UsersTable } from "./Molecules/UsersTable";
import { Users } from "./UsersPageTypes";
const { Search } = Input;

export default function UsersPage() {
  const userModalToggleHook = useToggle();
  const emailModalTookHook = useToggle();
  const { mutate: createFavorite } = useCreateFavorite();
  const history = useHistory();
  const [filterOption, setFilterOption] = useState({ name: "", params: "" });
  const [selectedUsers, setSelectedUsers] = useState<Users>([]);
  const { onDeleteUserClick, onDisableUserClick } = useUsersPageEvents();
  const { data: users, isLoading } = useUsers(filterOption);

  const onSearch = (value: any) => {
    setFilterOption({ name: "Search", params: value });
  };

  if (isLoading) {
    return <p>Is Loading</p>;
  }
  return (
    <div style={{ padding: "20px" }}>
      <PageHeader
        title="Mangage Users"
        sub="Add and modify users who monitor the network"
        favoriteClicked={() =>
          createFavorite({
            name: "Mangage Users",
            url: "/admin/mangage_users",
          })
        }
        buttons={
          <Space style={{ marginRight: "10px" }}>
            <StyledButton type="default" onClick={() => history.push("admin/")}>
              Admin Home
            </StyledButton>

            <StyledButton type="default" onClick={() => null}>
              System Status
            </StyledButton>
          </Space>
        }
      />

      <Card>
        <h3>User Listings</h3>
        <Row justify="space-between">
          <Col span={4}>
            <Search
              data-testid="userSearch"
              placeholder="Search by Name"
              onSearch={onSearch}
              onChange={(e) => onSearch(e.target.value)}
            />
          </Col>

          <Col>
            <Row justify="end">
              <Col span={8}>
                <Button onClick={() => userModalToggleHook.toggleOpen()}>
                  Add New User
                </Button>
              </Col>
              <Col span={8}>
                <Button onClick={() => emailModalTookHook.toggleOpen()}>
                  Email All Users
                </Button>
              </Col>

              <Col span={8}>
                {selectedUsers.length === 0 ? (
                  <Button
                    disabled
                    type="link"
                    style={{ padding: 0 }}
                    data-testid="Actions"
                  >
                    Selected User Actions
                  </Button>
                ) : (
                  <Popover
                    content={
                      <Actions
                        deleteClicked={() => onDeleteUserClick(selectedUsers)}
                        disableClicked={() =>
                          onDisableUserClick(selectedUsers, "Disable")
                        }
                        enableClicked={() =>
                          onDisableUserClick(selectedUsers, "Enable")
                        }
                        mutipleUsers={true}
                      />
                    }
                    placement={"bottom"}
                  >
                    <Button
                      disabled={selectedUsers.length === 0}
                      type="link"
                      style={{ padding: 0 }}
                      data-testid="Actions"
                    >
                      Selected User Actions
                    </Button>
                  </Popover>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <UsersTable
          filterOption={filterOption}
          setSelectedUsers={setSelectedUsers}
        />
      </Card>
      <UserModal toggleHook={userModalToggleHook} />
      <EmailUserModal toggleHook={emailModalTookHook} users={users} />
    </div>
  );
}
