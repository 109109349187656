import { useContext, useState } from "react";
import { Alert, Button } from "antd";
import { Form, Input } from "antd";
import styled from "styled-components";
import { AuthContext } from "../../../../context";
import { NotificationsContext } from "../../../../context";
import { useTranslator } from "../../../../hooks";
import { CenteredCard } from "../../../Shared/StyledComponents";
import useLogin from "../../hooks/useLogin";
import usePasswordRestart from "../../hooks/usePasswordRestart";

const SubmitButton = styled(Button)`
  width: 292.7px;
  height: 40px;
`;

const Card = styled(CenteredCard)`
  width: 540px;
  padding: 20px 0px;
`;

const StyledForm = styled(Form)`
  margin: 0 auto !important;
  width: 289px;
  input {
    height: 40px;
  }
`;

const StyledHeader = styled.h1`
  font-weight: bold;
  font-size: 28px;
  margin: 0 0 40px 0;
`;

const StyledAlert = styled(Alert)`
  margin: -10px 0 30px 0;
`;

type Props = {
  label: string;
  name: string;
  message: string;
};

function FormInput({ label, name, message }: Props) {
  const createRulesArray = () => [
    { required: true, message },
    { whitespace: true, message },
  ];

  return (
    <Form.Item label={label} name={name} rules={createRulesArray()}>
      {name === "password" ? <Input type="password" /> : <Input />}
    </Form.Item>
  );
}

export default function LoginForm() {
  const {
    TITLE,
    USER_NAME_INPUT_TITLE,
    PASSWORD_INPUT_TITLE,
    SUBMIT_BUTTON_NAME,
    FORGET_PASSWORD_BUTTON_NAME,
    PASSWORD_INPUT_ERROR_MESSAGE,
    USER_NAME_INPUT_ERROR_MESSAGE,
  } = useTranslator("LoginForm");

  const token = localStorage.getItem("token");
  const { setIsAuthenticated } = useContext(AuthContext);
  const [restartPasswordMode, setRestartPasswordMode] = useState(false);
  const { notifications } = useContext(NotificationsContext);

  const { mutate: login } = useLogin();
  const { mutate: restartPassword } = usePasswordRestart();

  const [form] = Form.useForm();

  token ? setIsAuthenticated(true) : setIsAuthenticated(false);

  const onSubmit = () => {
    const formValues = form.getFieldsValue();
    if (restartPasswordMode) {
      if (formValues.username)
        restartPassword({
          username: formValues.username,
        });
    } else {
      if (formValues.username && formValues.password) login(formValues);
    }
  };

  return (
    <Card bordered={false}>
      <StyledHeader>
        {restartPasswordMode ? "Restart Password" : TITLE}
      </StyledHeader>
      {notifications?.length > 0 && (
        <StyledAlert
          type="error"
          message={notifications[notifications.length - 1].message}
        />
      )}
      <StyledForm form={form} onFinish={onSubmit} layout="vertical">
        <FormInput
          label={USER_NAME_INPUT_TITLE}
          name="username"
          message={USER_NAME_INPUT_ERROR_MESSAGE}
        />
        {!restartPasswordMode && (
          <FormInput
            label={PASSWORD_INPUT_TITLE}
            name="password"
            message={PASSWORD_INPUT_ERROR_MESSAGE}
          />
        )}
        <Form.Item>
          <SubmitButton
            type="primary"
            // shape="round"
            htmlType="submit"
          >
            {!restartPasswordMode ? SUBMIT_BUTTON_NAME : "Restart Password"}
          </SubmitButton>
        </Form.Item>
      </StyledForm>
      <Button
        type="link"
        onClick={() => setRestartPasswordMode(!restartPasswordMode)}
      >
        {!restartPasswordMode
          ? `${FORGET_PASSWORD_BUTTON_NAME} ?`
          : "Back to Login"}
      </Button>
    </Card>
  );
}
