import { useQuery } from "react-query";
import { NAGIOS_INFO_URL } from "../../../api.config";
import { fetchData } from "../../../utils/axiosHelpers";

export const KEY = "Fetch Nagios Info";

const fetchNagiosInfo = () => fetchData(NAGIOS_INFO_URL);

export default function useNagiosInfo(isInstalled) {
  return useQuery(KEY, fetchNagiosInfo, {
    enabled: isInstalled !== true,
    refetchInterval: 1000,
    notifyOnChangeProps: ["data"]
  });
}
