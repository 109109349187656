import { find, range } from "lodash";

export default function createDashletArray(dashlets) {
  const createArrayItem = (x, y) => ({
    dashlet_id: "placeholder",
    name: "placeholder",
    type: "placeholder",
    position_x: x,
    position_y: y,
    dimension_width: 1,
    dimension_height: 1,
    properties: [],
    color: "white",
    endpoint: "",
  });

  const cols = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const rows = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const dashletsSpaceArray = dashlets
    .filter((dashlet) => dashlet.type !== "placeholder")
    .map(
      ({
        dimension_height,
        dimension_width,
        dashlet_id,
        position_x,
        position_y,
      }) => {
        const heightRange = range(position_x, position_x + dimension_height);
        const widthRange = range(position_y, position_y + dimension_width);

        return heightRange
          .map((height) => widthRange.map((width) => ({ x: height, y: width })))
          .flat()
          .flat();
      }
    );

  const dashletArray = rows
    .map((row) => cols.map((col) => createArrayItem(row, col)))
    .flat()
    .flat()
    .map((placeholder) => {
      if (
        find(dashletsSpaceArray[0], {
          x: placeholder.position_x,
          y: placeholder.position_y,
        })
      ) {
        return null;
      }
      return placeholder;
    });

  return [...dashletArray.filter((dashlet) => dashlet !== null), ...dashlets];
}
