import { useMutation, useQueryClient } from "react-query";
import { USER_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { putData } from "../../../utils/axiosHelpers";
import { User, Users } from "../UsersPageTypes";
import { KEY } from "./useUsers";

export default function useMasqueradeAsUser() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation((values) => putData(USER_URL, values), {
    onMutate: async (userToEdit: any) => {
      const { user_id } = userToEdit;

      await queryClient.cancelQueries(KEY);

      const previousUserList: any = queryClient.getQueryData(KEY);

      const [oldUser] = previousUserList.users.map(
        (user: User) => user.user_id === user_id
      );

      const CALL_REASON = `Edit User ${oldUser.name}`;

      const newUserList = previousUserList.users.map((user: User) =>
        user.user_id === user_id ? { ...user, ...userToEdit } : user
      );

      queryClient.setQueryData(KEY, {
        users: newUserList,
      });

      return {
        previousUserList,
        newUserList,
        CALL_REASON,
      };
    },
    onSuccess: (data, _, context) => {
      onSuccessNotification(data, context?.CALL_REASON);

      queryClient.setQueryData(KEY, {
        users: context?.newUserList,
      });
    },
    onError: (data, _, context) => {
      onErrorNotification(data, context?.CALL_REASON);
      if (context?.previousUserList) {
        queryClient.setQueryData<Users>([KEY], context.previousUserList);
      }
    },

    onSettled: () => {
      queryClient.invalidateQueries(KEY);
    },
  });
}
