import { Button, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";
import { UserForm } from "../../Atoms/UserForm";
import { User } from "../../UsersPageTypes";

export type Props = {
  toggleHook: any;
  user?: User;
};

export default function UserModal({ toggleHook, user }: Props) {
  const [form] = useForm();
  const { toggleOpen, open } = toggleHook;

  return (
    <Modal
      visible={open}
      width={1500}
      onCancel={() => toggleOpen()}
      footer={
        <>
          <Button onClick={() => toggleOpen()}>Close</Button>
          <Button
            onClick={() => {
              form.submit();
              toggleOpen();
            }}
            type="primary"
          >
            {user ? `Edit ${user.name}` : "Create New Uesr"}
          </Button>
        </>
      }
    >
      <UserForm form={form} user={user} />
    </Modal>
  );
}
