import { ResponsiveTreeMap } from "@nivo/treemap";
import { Skeleton } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { STATUS_SUMMARY_HOST_DASHLET_URL } from "../../../../api.config";
import { fetchData } from "../../../../utils/axiosHelpers";
import { dashletMockData } from "../../DashletMockData";
import { DashletProps } from "../../DashletViewPageTypes";

export default function HostStatusSummaryDashlet({
  demo,
  dashletId,
}: DashletProps) {
  const formatData = (data) => {
    return {
      name: `Total Records ${data.total_records}`,
      color: "hsl(305, 70%, 50%)",
      children: [
        { name: `Up ${data.up}`, color: "white", loc: data.up },
        { name: `Pending ${data.pending}`, color: "white", loc: data.pending },
        {
          name: `Down ${data.down}`,
          color: "white",
          children: [
            {
              name: `Down Handled ${data.handled_down}`,
              color: "white",
              loc: data.handled_down,
            },
            {
              name: `Down Unhandled ${data.unhandled_down}`,
              color: "white",
              loc: data.unhandled_down,
            },
          ],
        },
        {
          name: `Unreachable ${data.unreachable}`,
          color: "white",
          children: [
            {
              name: `Unreachable Handled ${data.handled_unreachable}`,
              color: "white",
              loc: data.handled_unreachable,
            },
            {
              name: `Unreachable Unhandled ${data.unhandled_unreachable}`,
              color: "white",
              loc: data.unhandled_unreachable,
            },
          ],
        },
      ],
    };
  };

  let { data, isLoading }: any = useQuery(
    ["Fetch Status Summary Hosts", dashletId],
    async () => await fetchData(STATUS_SUMMARY_HOST_DASHLET_URL),
    {
      select: (data) => formatData(data),
    }
  );

  if (isLoading) {
    return <Skeleton />;
  }

  data = demo ? formatData(dashletMockData["status_summary_hosts"]) : data;

  return (
    <ResponsiveTreeMap
      data={data}
      identity="name"
      value="loc"
      label="id"
      orientLabel={true}
      leavesOnly={true}
      valueFormat=".02s"
      isInteractive={true}
      margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
      labelSkipSize={1}
      labelTextColor={{ from: "theme", modifiers: [["darker", 1.2]] }}
      colors={["#B6FF67", "#EFEFEF", "#FF8067", "#FFC45F"]}
      parentLabelPosition="left"
      parentLabelTextColor={{ from: "color", modifiers: [["darker", 2]] }}
      borderColor={{ from: "color", modifiers: [["darker", 0.1]] }}
    />
  );
}
