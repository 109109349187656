import React, { createContext } from "react";

export type Host = {
  host_object_id: string;
  host_name: string;
  host_alias: string;
  display_name: string;
  address: string;
  icon_image: string;
  icon_image_alt: string;
  notes: string;
  notes_url: string;
  action_url: string;
  hoststatus_id: string;
  instance_id: string;
  status_update_time: string;
  output: string;
  long_output: string;
  perfdata: string;
  current_state: string;
  has_been_checked: string;
  should_be_scheduled: string;
  current_check_attempt: string;
  max_check_attempts: string;
  last_check: string;
  next_check: string;
  check_type: string;
  check_options: string;
  last_state_change: string;
  last_hard_state_change: string;
  last_hard_state: string;
  last_time_up: string;
  last_time_down: string;
  last_time_unreachable: string;
  state_type: string;
  last_notification: string;
  next_notification: string;
  no_more_notifications: string;
  notifications_enabled: string;
  problem_has_been_acknowledged: string;
  acknowledgement_type: string;
  current_notification_number: string;
  passive_checks_enabled: string;
  active_checks_enabled: string;
  event_handler_enabled: string;
  flap_detection_enabled: string;
  is_flapping: string;
  percent_state_change: string;
  latency: string;
  execution_time: string;
  scheduled_downtime_depth: string;
  failure_prediction_enabled: string;
  process_performance_data: string;
  obsess_over_host: string;
  modified_host_attributes: string;
  event_handler: string;
  check_command: string;
  normal_check_interval: string;
  retry_check_interval: string;
  check_timeperiod_object_id: string;
};

export type Service = {
  host_name: string;
  service_description: string;
  display_name: string;
  host_object_id: string;
  host_address: string;
  host_alias: string;
  icon_image: string;
  icon_image_alt: string;
  notes: string;
  notes_url: string;
  action_url: string;
  servicestatus_id: string;
  instance_id: string;
  service_object_id: string;
  status_update_time: string;
  output: string;
  long_output: string;
  perfdata: string;
  current_state: string;
  has_been_checked: string;
  should_be_scheduled: string;
  current_check_attempt: string;
  max_check_attempts: string;
  last_check: string;
  next_check: string;
  check_type: string;
  check_options: string;
  last_state_change: string;
  last_hard_state_change: string;
  last_hard_state: string;
  last_time_ok: string;
  last_time_warning: string;
  last_time_unknown: string;
  last_time_critical: string;
  state_type: string;
  last_notification: string;
  next_notification: string;
  no_more_notifications: string;
  notifications_enabled: string;
  problem_has_been_acknowledged: string;
  acknowledgement_type: string;
  current_notification_number: string;
  passive_checks_enabled: string;
  active_checks_enabled: string;
  event_handler_enabled: string;
  flap_detection_enabled: string;
  is_flapping: string;
  percent_state_change: string;
  latency: string;
  execution_time: string;
  scheduled_downtime_depth: string;
  failure_prediction_enabled: string;
  process_performance_data: string;
  obsess_over_service: string;
  modified_service_attributes: string;
  event_handler: string;
  check_command: string;
  normal_check_interval: string;
  retry_check_interval: string;
  check_timeperiod_object_id: string;
};

export type NagiosStatusObject = {
  status: string;
  active_host_checks: number;
  active_service_checks: number;
  notifications_enabled: number;
  database_backend_status: number;
  monitoring_engine_status: number;
  performance_grapher_status: number;
  last_updated: string;
  [key: string]: any;
};

type NagiosProviderObject = {
  nagiosStatus: NagiosStatusObject;
  favorites: string[];
  role: string;
  [key: string]: any;
};

type Props = {
  children: JSX.Element[] | JSX.Element;
  context: NagiosProviderObject;
};

export const createNagiosObject = (array: Host[] | Service[]) => ({
  array,
  count: array.length,
});

export const NagiosContext = createContext<NagiosProviderObject>({
  hosts: {
    up: createNagiosObject([]),
    down: createNagiosObject([]),
    unreachable: createNagiosObject([]),
    pending: createNagiosObject([]),
    all: createNagiosObject([]),
    status: "bad",
    last_updated: new Date(),
  },
  services: {
    ok: createNagiosObject([]),
    warning: createNagiosObject([]),
    critical: createNagiosObject([]),
    unknown: createNagiosObject([]),
    pending: createNagiosObject([]),
    all: createNagiosObject([]),
    status: "bad",
    last_updated: new Date(),
  },
  nagiosStatus: {
    status: "OK",
    active_host_checks: 1,
    active_service_checks: 1,
    notifications_enabled: 1,
    database_backend_status: 1,
    monitoring_engine_status: 1,
    performance_grapher_status: 1,
    last_updated: "now",
    version: "",
    update_available: false
  },
  settings: [],
  favorites: [],
  role: "nonAdmin",
});

export function NagiosProvider({ children, context }: Props) {
  return (
    <NagiosContext.Provider value={context}>{children}</NagiosContext.Provider>
  );
}
