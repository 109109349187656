import { Popover } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import Actions from "../../../Shared/Molecules/Actions/Actions";

import { useToggle } from "../../../../hooks";
import { Dashboard, Dashlet } from "../../../DashboardPage/DashboardPageTypes";
import { useDashletViewPageContext } from "../../../../context/DashletViewPageContext";
import useDashletPageEvents from "../../hooks/useDashletPageEvents";
import { DashletModal } from "../DashletModal";
import { useState } from "react";
import { DuplicateDashletModal } from "../DuplicateDashletModal";

export type Props = {
  dashlet: Dashlet;
  dashboard: Dashboard;
};

export default function DashletDropdown({ dashlet, dashboard }: Props) {
  const [popupVisible, setPopoverVisable] = useState(false);
  const { open: dashletModalOpen, toggleOpen: toggleDashletModalOpen } =
    useToggle();
  const {
    open: duplicateDashletModalOpen,
    toggleOpen: toggleDuplicateDashletModalOpen,
  } = useToggle();

  const { toggleIsEnabled, isEnabled } = useDashletViewPageContext();

  const { onDeleteDashletButtonClick, onPinDashletButtonClick } =
    useDashletPageEvents(Number(dashboard.dashboard_id));

  return (
    <>
      <Popover
        trigger="hover"
        popupVisible={popupVisible}
        onVisibleChange={() => {
          toggleIsEnabled(!isEnabled);
        }}
        content={
          <Actions
            deleteClicked={() => {
              onDeleteDashletButtonClick(dashlet);
            }}
            duplicateClicked={() => {
              setPopoverVisable(false);
              toggleIsEnabled(!isEnabled);
              toggleDuplicateDashletModalOpen();
            }}
            editClicked={() => {
              setPopoverVisable(false);
              toggleIsEnabled(!isEnabled);
              toggleDashletModalOpen();
            }}
            pinClicked={() => {
              setPopoverVisable(false);
              onPinDashletButtonClick(dashlet);
            }}
            isPinned={dashlet.properties.pinned}
          />
        }
        placement={"right"}
      >
        <MenuOutlined />
      </Popover>
      <DashletModal
        open={dashletModalOpen}
        dashboard={dashboard}
        toggleOpen={toggleDashletModalOpen}
        dashlet={dashlet}
        editDashlet={true}
      />
      <DuplicateDashletModal
        dashboard={dashboard}
        dashlet={dashlet}
        open={duplicateDashletModalOpen}
        toggleOpen={toggleDuplicateDashletModalOpen}
      />
    </>
  );
}
