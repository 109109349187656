import { useMutation, useQueryClient } from "react-query";
import { PLAYLIST_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { postData } from "../../../utils/axiosHelpers";
import { Playlists } from "../ViewsPageTypes";
import { KEY } from "./usePlaylists";

export default function useCreatePlaylist() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    (values) => {
      console.log(values);
      return postData(PLAYLIST_URL, values);
    },
    {
      onMutate: async (playlistToCreate: any) => {
        const CALL_REASON = `Create New Playlist ${playlistToCreate.name}`;
        await queryClient.cancelQueries(KEY);

        const previousPlaylist: any = queryClient.getQueryData(KEY);

        const newPlaylist = [
          ...previousPlaylist?.playlists,
          {
            id: Math.random().toString(),
            name: playlistToCreate.name,
            views: Object.assign(
              {},
              ...Object.values(playlistToCreate.views).map((viewName, i) => ({
                [`${i + 1}`]: {
                  id: Math.random.toString(),
                  name: viewName,
                  active: true,
                },
              }))
            ),
          },
        ];

        queryClient.setQueryData(KEY, {
          playlists: newPlaylist,
        });

        return {
          previousPlaylist,
          newPlaylist,
          CALL_REASON,
        };
      },
      onSuccess: (data, _, context) => {
        onSuccessNotification(data, context?.CALL_REASON);
      },
      onError: (data, _, context) => {
        onErrorNotification(data, context?.CALL_REASON);
        if (context?.previousPlaylist) {
          queryClient.setQueryData<Playlists>([KEY], context.previousPlaylist);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(KEY);
      },
    }
  );
}
