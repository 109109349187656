import { Checkbox, Form, Input, Modal } from "antd";
import { useState } from "react";
import { ColorPicker } from "../..";
import { useGlobalModal } from "../../../../context/GlobalModalContext";
import { useTranslator } from "../../../../hooks";
import useDashboardPageEvents from "../../hooks/useDashboardPageEvents";

export type Props = {
  sample: string;
};

const FormCheckbox = ({ label, name }) => (
  <Form.Item label={label} name={name} valuePropName="checked">
    <Checkbox />
  </Form.Item>
);

export default function CreateDashboardModal() {
  const [color, setColor] = useState("#16C79B");
  const {
    createNewDashboardForm,
    onCreateButtonClick,
  } = useDashboardPageEvents();
  const {
    createDashboardModalOpen,
    toggleCreateDashboardModalOpen,
  } = useGlobalModal();

  const {
    DASHBOARD_TITLE_INPUT,
    SELECT_BACKGROUND_COLOR,
    PUBLIC_SHARE_CHECKBOX,
    ALLOW_EDITING_CHECKBOX,
    DASHBOARD_INPUT_ERROR_MESSAGE,
  } = useTranslator("CreateDashboardForm");

  return (
    <Modal
      title={`Create New Dashboard`}
      onOk={() => {
        createNewDashboardForm.submit();
        toggleCreateDashboardModalOpen();
      }}
      onCancel={() => toggleCreateDashboardModalOpen()}
      visible={createDashboardModalOpen}
    >
      <Form
        form={createNewDashboardForm}
        onFinish={onCreateButtonClick}
        layout="vertical"
        initialValues={{ isSharable: false, isEditable: false }}
      >
        <Form.Item
          label={DASHBOARD_TITLE_INPUT}
          name="name"
          rules={[{ required: true, message: DASHBOARD_INPUT_ERROR_MESSAGE }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={SELECT_BACKGROUND_COLOR}>
          <ColorPicker color={color} updateColor={setColor} />
        </Form.Item>
        <FormCheckbox label={PUBLIC_SHARE_CHECKBOX} name="isSharable" />
        <FormCheckbox label={ALLOW_EDITING_CHECKBOX} name="isEditable" />
      </Form>
    </Modal>
  );
}
