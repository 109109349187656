import { useEffect } from "react";
import { useQueryClient } from "react-query";
import {
  preFetchDashboards,
  preFetchDashlets,
} from "../components/DashboardPage/hooks/useDashboards";
import { preFetchFavorites } from "../components/Frame/hooks/useFavorites";
import { preFetchHosts } from "../components/Frame/hooks/useHosts";
import { preFetchServices } from "../components/Frame/hooks/useServices";
import { preFetchHostsOverview } from "../components/Frame/hooks/useHostsOverview";
import { preFetchServicesOverview } from "../components/Frame/hooks/useServicesOverview";
import { preFetchNagiosStatus } from "../components/Frame/hooks/useNagiosStatus";
import { preFetchUser } from "../components/Frame/hooks/useUser";
import { preFetchWizards } from "../components/Frame/hooks/useWizards";
import { preFetchHomeCardOrder } from "../components/HomePage/hooks/useHomePageOrder";
import { preFetchMostViewed } from "../components/HomePage/hooks/useMostViewed";
import { preFetchFavoriteReports } from "../components/ReportsPage/hooks/useFavoriteReports";
import { preFetchReports } from "../components/ReportsPage/hooks/useReports";
import { preFetchReportTypes } from "../components/ReportsPage/hooks/useReportTypes";
import { preFetchTools } from "../components/ToolsPage/hooks/useTools";
import { preFetchPlaylists } from "../components/ViewsPage/hooks/usePlaylists";
//import { preFetchViews } from "../components/ViewsPage/hooks/useViews";
import { preFetchAvailableScreens } from "../components/Frame/hooks/useAvailableScreens";
import { preFetchDashletProperties } from "../components/DashletPage/hooks/useDashletProperties";

type Props = {
  children: JSX.Element[] | JSX.Element;
};

export default function Hydrator({ children }: Props) {
  const queryClient = useQueryClient();

  const hydrate = async () => {
    await Promise.all([
      preFetchHomeCardOrder(queryClient),
      preFetchMostViewed(queryClient),
      preFetchFavorites(queryClient),
      preFetchHosts(queryClient),
      preFetchServices(queryClient),
      preFetchHostsOverview(queryClient),
      preFetchServicesOverview(queryClient),
      preFetchNagiosStatus(queryClient),
      preFetchUser(queryClient),
      preFetchDashboards(queryClient),
      preFetchDashlets(queryClient),
      preFetchDashletProperties(queryClient),
      preFetchTools(queryClient),
      preFetchPlaylists(queryClient),
      //preFetchViews(queryClient),
      preFetchReports(queryClient),
      preFetchReportTypes(queryClient),
      preFetchFavoriteReports(queryClient),
      preFetchWizards(queryClient),
      preFetchAvailableScreens(queryClient),
    ]);

    // const { dashboards }: any = queryClient.getQueryData(KEY);
    // await Promise.all(
    //   dashboards
    //     .map((dashboard: Dashboard) => dashboard.dashlets)
    //     .flat()
    //     .filter((dashlet: Dashlet) => dashlet.type !== "customUrl")
    //     .map((dashlet: Dashlet) => preFetchDashletData(queryClient, dashlet))
    // );
  };

  useEffect(() => {
    hydrate();
  });

  return <>{children}</>;
}
