import React, { createContext, useContext, useState } from "react";

type AuthObject = {
  isAuthenticated: boolean;
  setIsAuthenticated: Function;
  role: string;
  setRole: Function;
};

export const AuthContext = createContext<AuthObject>({
  isAuthenticated: false,
  setIsAuthenticated: Function,
  role: "",
  setRole: Function,
});

type Props = {
  children: JSX.Element[] | JSX.Element;
  context?: AuthObject;
};

export function AuthProvider({ children }: Props) {
  const token = localStorage.getItem("token");
  const [isAuthenticated, setIsAuthenticated] = useState(token ? true : false);
  const [role, setRole] = useState("");
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        role,
        setRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
