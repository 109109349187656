import { useQuery } from "react-query";
import { AVAILABLE_SCREENS_URL } from "../../../api.config";
import { fetchData } from "../../../utils/axiosHelpers";

export const KEY = "Fetch Available Screens";

const fetchAvailableScreens = async () => fetchData(AVAILABLE_SCREENS_URL);

export const preFetchAvailableScreens = (queryClient) =>
  queryClient.prefetchQuery(KEY, fetchAvailableScreens);

export default function useAvailableScreens(options?: any) {
  return useQuery(KEY, fetchAvailableScreens, {
    select: (data) => data,
    ...options,
  });
}
