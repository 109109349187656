import Iframe from "iframe-resizer-react";

export type Props = {
  url: string;
};

export default function CustomUrlDashlet({ url }: Props) {
  return (
    <Iframe
      src={url}
      style={{
        height: `90%`,
        width: "1px",
        minWidth: "100%",
      }}
    />
  );
}
