import { Card, Radio, Button, Collapse } from "antd";
import React from "react";
import styled from "styled-components";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";
import Accordion from "../../../Shared/Atoms/Accordion/Accordion";
import { IconQuestionFilled } from "../../../Shared/Atoms/FontIcons/FontIcons";

const { Panel } = Collapse;

const FAQCard = styled(Card)`
  & .ant-card-body {
    display: flex;
    flex-wrap: wrap;
    & > header {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & h4 {
        margin: 0 10px;
      }
    }
    & > p {
      width: 100%;
      margin: 0 10px 10px;
      color: ${themeVariables.colorGrayLight1};
      font-size: 14px;
    }
    & > div {
      width: 100%;
      display: flex;
      & > div {
        height: 300px;
        overflow-y: auto;
        flex: 1;
        margin: 0 10px;
      }
    }
  }
`;

export type FAQProps = {
  selectFAQ: (event: any) => void;
  value: string;
  faqData: {
    name: string;
    filter: string;
    questions: {
      title: string;
      value: string;
    }[];
  }[];
  clickFAQ: (event: any) => void;
};

const HelpFAQ = (props: FAQProps) => {
  const options = [
    { label: "All", value: "all" },
    { label: "Basic", value: "basic" },
    { label: "Advanced", value: "advanced" },
  ];

  return (
    <FAQCard>
      <header>
        <h4>Frequently Asked Questions</h4>
        <Radio.Group
          options={options}
          onChange={props.selectFAQ}
          value={props.value}
          optionType="button"
        />
      </header>
      <p>
        Content below can be filtered for just basic tasks or to only see
        advanced feature help.
      </p>
      <div>
        <Accordion>
          {props.faqData.map((el, i) => {
            if ((i + 1) % 2 !== 0) {
              return (
                <Panel
                  header={el.name}
                  key={i}
                  style={{
                    display:
                      props.value === "all" || props.value === el.filter
                        ? "block"
                        : "none",
                  }}
                >
                  {el.questions.map((question) => (
                    <Button
                      type="link"
                      value={question.value}
                      onClick={() =>
                        props.clickFAQ([question.title, question.value])
                      }
                    >
                      <IconQuestionFilled size="1.5em" /> {question.title}
                    </Button>
                  ))}
                </Panel>
              );
            } else return null;
          })}
        </Accordion>
        <Accordion>
          {props.faqData.map((el, i) => {
            if ((i + 1) % 2 === 0) {
              return (
                <Panel
                  header={el.name}
                  key={i}
                  style={{
                    display:
                      props.value === "all" || props.value === el.filter
                        ? "block"
                        : "none",
                  }}
                >
                  {el.questions.map((question) => (
                    <Button
                      type="link"
                      value={question.value}
                      onClick={() =>
                        props.clickFAQ([question.title, question.value])
                      }
                    >
                      <IconQuestionFilled size="1.5em" /> {question.title}
                    </Button>
                  ))}
                </Panel>
              );
            } else return null;
          })}
        </Accordion>
      </div>
    </FAQCard>
  );
};

export default HelpFAQ;
