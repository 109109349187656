import React from "react";

import { Table, TableProps } from "antd";
import styled, { DefaultTheme } from "styled-components";
import { IconAdd, IconChevronUp } from "../../Atoms/FontIcons/FontIcons";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import { paginationStyles } from "../../../../styles/styled-components/StyledComponentSharedStyles";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";

const tableBase = (theme: DefaultTheme): string => {
  return `
  &&& table { 
    border-collapse: separate; 
    border-spacing: 0px 6px;
  }
  &&& .ant-table-tbody > tr> td > .ant-table-wrapper .ant-table {
    margin-left: 33px
  }`;
};

const tableTypography = (theme: DefaultTheme): string => {
  return `
  .ant-typography-secondary {
    color: ${theme.typographySecondaryColor};
  }`;
};

const tableRow = (theme: DefaultTheme): string => {
  const tableBodyBorderActive = `3px solid ${theme.tableBodyBackgroundColor}`;
  const tableBodyBorderInactive = `3px solid transparent`;
  return `
  &&& tr {
    border-radius: ${theme.defaultBorderRadius}; 
    box-shadow: none
  }
  &&& tr > td { 
    border-top: ${tableBodyBorderInactive}; 
    border-bottom: ${tableBodyBorderInactive}; 
  }
  &&& tr > td:first-child { 
    border-left: ${tableBodyBorderInactive};
  }
  &&& tr > td:last-child { 
    border-right: ${tableBodyBorderInactive};
  }
  &&& tr.active-drag > td, &&& tr:hover > td, &&& tr:active > td { 
    background-color: ${theme.cardBackgroundColor}; 
    border-top: ${tableBodyBorderActive}; 
    border-bottom: ${tableBodyBorderActive}; 
  }
  &&& tr.active-drag > td:first-child, &&& tr:hover > td:first-child, &&& tr:active > td:first-child { 
    background-color: ${theme.cardBackgroundColor}; 
    border-left: ${tableBodyBorderActive};
  }
  &&& tr.active-drag > td:last-child, &&& tr:hover > td:last-child, &&& tr:active > td:last-child { 
    background-color: ${theme.cardBackgroundColor}; 
    border-right: ${tableBodyBorderActive};
  }
  &&& tr.active-drag > td {
      box-shadow: ${themeVariables.draggingBoxShadow}; 
      transition: box-shadow 1s;
    }
  && tr.active-drag {
      margin-top: -10px
    }
  && tr.active-drag {
      display: table;
    }
  `;
};

const tableData = (theme: DefaultTheme): string => {
  return `
  td {
    background-color: ${theme.tableBodyBackgroundColor}; 
    padding-top: 20px; padding-bottom: 20px;
  }
  &&& td {
    transition: border .2s ease
  }
  &&& td:first-child, &&& th:first-child { 
    border-top-left-radius: ${theme.defaultBorderRadius}; 
    border-bottom-left-radius: ${theme.defaultBorderRadius};
  }
  &&& td:last-child, &&& th:last-child { 
    border-top-right-radius: ${theme.defaultBorderRadius}; 
    border-bottom-right-radius: ${theme.defaultBorderRadius};
  }
  `;
};

const tableHeader = (theme: DefaultTheme): string => {
  return `  
  &&& .ant-table-header.ant-table-sticky-header > table {
    background-color: ${theme.tableHeadBackgroundColor}; 
    border-radius: ${theme.defaultBorderRadius}
  }
  &&& th, .ant-table-column-sorters {
    padding-top: 5px; padding-bottom: 5px; 
    background-color: ${theme.tableHeadBackgroundColor}!important;
  }
  &&& .ant-table-column-sorter-inner {
    color: ${themeVariables.colorWhite};
  }
  &&& .ant-table-header.ant-table-sticky-header > table {
    border-collapse: collapse;
  }
  `;
};
const tableExpanded = (): string => {
  return `&&& .ant-table-expanded-row > td > .ant-table-wrapper {
    max-width: 97%;
  }`;
};

export const styledTableBase = (theme: DefaultTheme) => {
  return `
  ${tableBase(theme)}
  ${tableTypography(theme)}
  ${tableHeader(theme)}
  ${tableRow(theme)}
  ${tableData(theme)}
  ${tableExpanded()}
  ${paginationStyles(theme)}
  `;
};

const StyledTable = styled(Table)`
  ${(props) => styledTableBase(props.theme)}
`;

export function ExpandButton(isActive: boolean | undefined) {
  const theme = useMasterTheme().theme;
  if (isActive) {
    return <IconChevronUp color={theme.primaryColor} />;
  } else {
    return <IconAdd color={theme.primaryColor} />;
  }
}

export default function CustomTable(props: TableProps<any>) {
  return <StyledTable {...props} />;
}
