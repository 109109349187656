import { Button, Space } from "antd";
import styled from "styled-components";
import { useNotifications } from "../../../../context";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";
import { Notifications } from "../../../Frame/FrameTypes";
import { IconCircle, IconClose } from "../../Atoms/FontIcons/FontIcons";
import { HeaderPopup } from "../../Atoms/HeaderPopup/HeaderPopup";

type NotificationMenuProps = {
  data: Notifications;
};

const NotificationMenu = (props: NotificationMenuProps) => {
  const iconColor = {
    primary: themeVariables.colorPrimary,
    success: themeVariables.colorSuccess,
    warning: themeVariables.colorWarning,
    danger: themeVariables.colorDanger,
    info: themeVariables.colorInfo,
  };

  const getStatus = (status) => {
    switch (status) {
      case "ok":
        return iconColor.success;
      case "warning":
        return iconColor.warning;
      case "danger":
        return iconColor.danger;
      default:
        return iconColor.info;
    }
  };

  const StyledRow = styled.tr`
    vertical-align: top;
    width: 100%;
    & td {
      padding: 0 0 10px 0;
    }
    & > td:first-child {
      width: 18px;
    }
    & > td:nth-child(2) {
      width: 210px;
    }
    & > td:last-child {
      width: 32px;
    }
    & > td > span {
      font-size: 0.75em;
      font-style: italic;
      color: ${themeVariables.colorGrayLight1};
    }
  `;

  const { clearNotifications } = useNotifications();

  return (
    <HeaderPopup width="300px" align="left">
      <Space direction="vertical" size="middle">
        {props.data.map((notification, i) => (
          <table style={{ borderBottom: "1px solid" }} key={i}>
            <tbody>
              <StyledRow>
                <td>
                  <IconCircle color={getStatus(notification.type)} size="1em" />
                </td>
                <td>
                  {notification.message}
                  <br />
                  <span>Updated: {notification.timeStamp}</span>
                </td>
                <td>
                  <Button type="text">View</Button>
                </td>
              </StyledRow>
            </tbody>
          </table>
        ))}
        <Space>
          <Button ghost style={{ marginRight: "4px" }}>
            View All Notifications
          </Button>
          <Button ghost onClick={() => clearNotifications()}>
            <IconClose /> Clear
          </Button>
        </Space>
      </Space>
    </HeaderPopup>
  );
};

export default NotificationMenu;
