import { Button, Tooltip } from "antd";
import styled from "styled-components";
import CustomTable from "../../../Shared/Organisms/CustomTable/CustomTable";
import useToolPageEvents from "../../hooks/useToolPageEvents";
import ToolkitTableActions from "./ToolkitTableActions";

const StyledTable = styled(CustomTable)`
  table {
    table-layout: fixed !important;
    width: 100%;
  }
  tr > * {
    padding: 16px 4px !important;
    :first-child {
      width: 70px;
      text-align: center !important;
    }
    :last-child {
      width: 80px;
      text-align: center !important;
    }
  }
  tr > :nth-child(2),
  tr > :nth-child(3) {
    width: calc(50% - 75px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

type ToolkitProps = {
  data: {
    id: number;
    name: string;
    url: string;
    shared: number;
  }[];
  viewClicked?: any;
};

const ToolkitTable = (props: ToolkitProps) => {
  const { onViewButtonClick } = useToolPageEvents();

  const columns = [
    {
      title: "View",
      dataIndex: "view",
      key: "view",
      render: (text, row) => (
        <Button
          type="link"
          style={{ padding: 0 }}
          onClick={() => onViewButtonClick(row)}
        >
          View
        </Button>
      ),
    },
    {
      title: "Tool Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <Tooltip placement="top" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (text) => (
        <Tooltip
          title={
            <Button ghost style={{ borderWidth: 0 }} href={text}>
              {text}
            </Button>
          }
        >
          {text}
        </Tooltip>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => <ToolkitTableActions tool={row} />,
    },
  ];

  return (
    <>
      <StyledTable
        dataSource={props.data}
        columns={columns}
        pagination={false}
      />
    </>
  );
};

export default ToolkitTable;
