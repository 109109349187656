import { Form, Select, Input } from "antd";
import styled from "styled-components";

const { TextArea } = Input;

export type FormProps = {};

const StyledForm = styled(Form)`
  padding-top: 10px;
  & .ant-form-item {
    margin-bottom: 15px !important;
    & > .ant-form-item-label {
      padding-bottom: 0px !important;
    }
  }
`;

const ContactForm = (props: FormProps) => {
  return (
    <StyledForm layout="vertical">
      <Form.Item label="What do you want to ask us about?">
        <Select placeholder="Select a category">
          <Select.Option value="option 1">Option 1</Select.Option>
          <Select.Option value="option 2">Option 2</Select.Option>
          <Select.Option value="option 3">Option 3</Select.Option>
          <Select.Option value="option 4">Option 4</Select.Option>
          <Select.Option value="option 5">Option 5</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={["user", "first name"]}
        label="First name"
        rules={[{ required: true }]}
        wrapperCol={{ span: 22 }}
        style={{ width: "50%", float: "left" }}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["user", "last name"]}
        label="Last name"
        rules={[{ required: true }]}
        style={{ width: "50%", clear: "right" }}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["user", "company"]}
        label="Company"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["user", "email"]}
        label="Email"
        rules={[{ required: true, type: "email" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["user", "phone"]}
        label="Phone (optional)"
        wrapperCol={{ span: 22 }}
        style={{ width: "50%", float: "left" }}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["user", "role"]}
        label="Role (optional)"
        style={{ width: "50%", clear: "right" }}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["user", "comment"]}
        label="Comment"
        rules={[{ required: true }]}
      >
        <TextArea rows={4} />
      </Form.Item>
    </StyledForm>
  );
};

export default ContactForm;
