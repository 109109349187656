import { Button, Card } from "antd";
import styled from "styled-components";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";

const StyledCard = styled(Card)`
  margin-bottom: 10px;
  background-color: ${themeVariables.colorBluePale};
  & .ant-card-body {
    width: 100%;
    display: flex;
    align-items: center;
    & > div:first-child {
      flex: 1;
      line-height: 1.2;
      margin-right: 20px;
      & > h4 {
        font-size: 14px;
      }
      & > p {
        color: ${themeVariables.colorGrayLight2};
        margin-bottom: 0;
        font-size: 12px;
      }
    }
  }
`;

const OtherIdeas = (props) => {
  return (
    <StyledCard>
      <div>
        <h4>{props.title}</h4>
        <p>{props.desc}</p>
      </div>
      <Button onClick={props.clicked} href={props.link}>
        {props.directLink ? "Add to Playlist" : "View Examples"}
      </Button>
    </StyledCard>
  );
};

export default OtherIdeas;
