import React, { useState } from "react";
import useUser from "../components/Frame/hooks/useUser";

export default function useRole() {
  const { data: userData } = useUser();
  const isAdmin = parseInt(userData?.meta.userlevel) === 255;
  const [currentRole, setCurrentRole] = useState(isAdmin ? 'admin' : 'nonAdmin');

  const renderIfAdmin = (
    component: React.Component,
    fallback?: React.Component
  ) => {
    return <>{isAdmin ? component : fallback ? fallback : null}</>;
  };

  return {
    role: currentRole,
    isAdmin,
    updateRole: () => setCurrentRole(isAdmin ? "admin" : "nonAdmin"),
    renderIfAdmin: (component: any, fallbackComponent?: any) =>
      renderIfAdmin(component, fallbackComponent),
  };
}
