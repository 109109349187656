import { useMutation, useQueryClient } from "react-query";
import { DASHBOARD_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { deleteData } from "../../../utils/axiosHelpers";
import { Dashboard } from "../DashboardPageTypes";
import { KEY } from "./useDashboards";

export default function useDeleteDashboard() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    (values) => deleteData(`${DASHBOARD_URL}/${values.dashboard_id}`, values),
    {
      onMutate: async (dashboardToRemove: any) => {
        const CALL_REASON = `Delete Dashboard ${dashboardToRemove.name}`;
        await queryClient.cancelQueries(KEY);
        const previousDashboards: any = queryClient.getQueryData(KEY);

        const { dashboard_id } = dashboardToRemove;

        const newDashboards = previousDashboards.dashboards.filter(
          (row) => row.dashboard_id !== dashboard_id
        );

        queryClient.setQueryData(KEY, {
          dashboards: newDashboards,
        });

        return { previousDashboards, newDashboards, CALL_REASON };
      },
      onSuccess: (data, _, context) => {
        onSuccessNotification(data, context?.CALL_REASON);

        queryClient.setQueryData(KEY, {
          dashboards: context?.newDashboards,
        });
      },
      onError: (data, _, context) => {
        onErrorNotification(data, context?.CALL_REASON);

        if (context?.previousDashboards) {
          queryClient.setQueryData<Dashboard[]>(
            KEY,
            context.previousDashboards
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(KEY);
      },
    }
  );
}
