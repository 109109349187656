import { Col, Input, Popover, Row, Select, Tabs } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Form from "antd/lib/form";
import { useState } from "react";
import { languageFlagObject } from "../../../../context";
import { RoundedInput } from "../../../Shared";
import { IconQuestionCircle } from "../../../Shared/Atoms/FontIcons/FontIcons";
import SwipeableViews from "react-swipeable-views";
import useUsersPageEvents from "../../hooks/useUsersPageEvents";
import { User } from "../../UsersPageTypes";
const Option = Select.Option;
const { Password } = Input;
const { TabPane } = Tabs;

export type Props = {
  form: any;
  user?: User;
};

export type FormDropdownProps = {
  label: string;
  name: string;
  options: any[];
  onChange?: any;
};

export type FormCheckProps = {
  label: string;
  name: string;
  labelOnRight: boolean;
  helpText?: any;
};

const FormInput = ({ label, name }) => (
  <Form.Item name={name} label={label}>
    <RoundedInput />
  </Form.Item>
);

const FormCheckbox = ({
  label,
  name,
  labelOnRight,
  helpText,
}: FormCheckProps) => (
  <Row justify={"space-between"}>
    <Col span={22}>
      <Form.Item
        name={name}
        label={!labelOnRight ? label : ""}
        valuePropName="checked"
      >
        <Checkbox>{labelOnRight ? label : ""}</Checkbox>
      </Form.Item>
    </Col>
    {helpText && (
      <Col span={2} pull={18}>
        <Popover content={helpText} trigger="click" placement="right">
          <IconQuestionCircle />
        </Popover>
      </Col>
    )}
  </Row>
);

const FormDropdown = ({
  label,
  name,
  options,
  onChange,
}: FormDropdownProps) => (
  <Form.Item name={name} label={label}>
    <Select onChange={onChange ? onChange : () => null}>
      {options.map((option, i) => (
        <Option key={i} value={option}>
          {option}
        </Option>
      ))}
    </Select>
  </Form.Item>
);
type AuthLevel = "Admin" | "User";

export default function UserForm({ form, user }: Props) {
  const [index, setIndex] = useState("0");
  const { onEditUserClick, onCreateUserClick } = useUsersPageEvents();
  const [authLevel, setAuthLevel] = useState<AuthLevel>(
    user ? (user.is_admin === 1 ? "Admin" : "User") : "User"
  );
  const [authType, setAuthType] = useState(
    user ? user.auth_type : "Local(Default)"
  );

  const createCheckboxObject = (name, key, helpText) => ({
    name,
    key,
    helpText,
  });

  const dateFormatOptions = [
    "YYYY/DD/MM HH:MM:SS",
    "MM/DD/YYYY HH:MM:SS",
    "DD/MM/YYYY HH:MM:SS",
  ];

  const numberFormatOptions = [
    "1000.00",
    "1,000.00",
    "1.000,00",
    "1 000,00",
    "1`000,00",
  ];

  const weekFormatOptions = ["Sunday - Saturday", "Monday - Sunday"];
  const authLevelOptions = ["User", "Admin"];

  const checkboxList = [
    createCheckboxObject(
      "Can see all hosts and services",
      "authorized_for_all_objects",
      () => (
        <div>
          <p>
            Allows a user to view all host and services that are applied in the
            system.
          </p>
          <p>
            User does not need to be a contact of an object to see it with this
            enabled.
          </p>
        </div>
      )
    ),
    createCheckboxObject(
      "Can control all hosts and services",
      "authorized_to_configure_objects",
      () => (
        <div>
          <p>
            Allows the user run commands on all hosts and services. These are
            commands that can be ran on the Nagios command file.
          </p>
          <p>
            Without this option users are still allowed to run commands on hosts
            and servies they are contacts of. This is normally used if you have
            selected the Can see all hosts and services above.
          </p>
        </div>
      )
    ),
    createCheckboxObject(
      "Can configure hosts and services",
      "authorized_for_all_object_commands",
      () => (
        <div>
          <p>
            Allows a user to be able to access the following for hosts and
            services.
          </p>
          <ul>
            <li>Run Configuration Wizards</li>
            <li>Delete from detail page</li>
            <li>Re-configure from detail page"</li>
          </ul>
        </div>
      )
    ),
    createCheckboxObject(
      "Can access advanced features",
      "advanced_user",
      () => (
        <div>
          <p>
            Allows the editing of check command in the re-configure host/service
            page.
          </p>
          <p>Shows advanced tab and commands in host/service detail pages.</p>
          <p>
            Allows setting host parents during wizards and re-configure on host
            detail page.
          </p>
        </div>
      )
    ),
    createCheckboxObject(
      "Can access monitoring engine",
      "authorized_for_monitoring_system",
      () => (
        <div>
          <p>
            Allows access to the Monitoring Process section in the main page.
          </p>
          <p>
            Allows managing Nagios Core process such as starting, stopping,
            restarting, and changing process settings.
          </p>
          <p>Allows access to the Event Log.</p>
        </div>
      )
    ),
    createCheckboxObject("Read-only access", "readonly_user", () => (
      <div>
        <p>Restrict the user to have read-only access.</p>
        <p>Note: This overwrites the following:</p>
        <ul>
          <li>Can control hosts and services</li>
          <li>Can configure hosts and services</li>
          <li>Can access advanced features</li>
        </ul>
      </div>
    )),
    createCheckboxObject("API Access", "api_access", () => (
      <div>
        <p>Allow access to use the API and integrated help documentation.</p>
        <p>Note: Users can only access the objects API endpoint.</p>
      </div>
    )),
    createCheckboxObject("Auto deploy access", "autodeploy_access", () => (
      <div>
        <p>Allow access to use the Auto Deployment component.</p>{" "}
      </div>
    )),
    createCheckboxObject("Core Config Manager access", "ccm_access", () => (
      <div>
        <p>Allow user to view and access the CCM.</p>
        <p>
          <strong>None</strong> - Cannot use or view the CCM.
        </p>
        <p>
          <strong>Login</strong> - Can view CCM links and must log in with a CCM
          user account.
        </p>
        <p>
          <strong>Limited</strong> - Integrated CCM access. User can only access
          the objects they can view in the interface normally. Allows for
          setting specific permissions for the user.
        </p>
        <p>
          <strong>Full</strong> - Integrated CCM access. Can access all objects
          with no admin features
        </p>
      </div>
    )),
  ];

  const onSubmit = (values) =>
    user
      ? onEditUserClick({ ...values, user_id: user?.user_id })
      : onCreateUserClick(values);

  console.log(user?.meta?.authorized_for_all_object_commands === "1");
  return (
    <Form
      {...{ labelCol: { span: 4 }, wrapperCol: { span: 14 } }}
      form={form}
      initialValues={
        user
          ? {
              user_id: 1,
              username: user.username,
              email: user.email,
              name: user.name,
              phone: user.phone,
              enabled: user.enabled === 1,
              is_admin: user.is_admin === 1,
              auth_level: authLevelOptions[user?.is_admin === 1 ? 1 : 0],
              auth_type: user.auth_type === "local" ? "Local" : user.auth_type,
              ad_server: user.ad_server,
              ad_username: user.ad_username,
              ldap_server: user.ldap_server,
              ldap_username: user.ldap_username,
              allow_secondary_local_login:
                user.allow_secondary_local_login === 1,
              api_enabled: user.api_enabled === 1,
              language: user?.meta?.language,
              date_format:
                dateFormatOptions[Number(user?.meta?.date_format) - 1],
              number_format:
                numberFormatOptions[Number(user?.meta?.number_format) - 1],
              week_format:
                weekFormatOptions[Number(user?.meta?.week_format) - 1],
              authorized_for_all_objects:
                user?.meta?.authorized_for_all_object_commands === "1",
              authorized_to_configure_objects:
                user?.meta?.authorized_for_all_object_commands === "1",
              authorized_for_all_object_commands:
                user?.meta?.authorized_for_all_object_commands === "1",
              authorized_for_monitoring_system:
                user?.meta?.authorized_for_monitoring_system === "1",
              advanced_user: user?.meta?.advanced_user === "1",
              readonly_user: user?.meta?.readonly_user === "1",
              mobile_number: user?.meta?.mobile_number,
              autodeploy_access: user?.meta?.autodeploy_access === "1",
              ccm_access: user?.meta?.ccm_access === "1",
              ccm_access_list: user?.meta?.ccm_access_list,
              allow_local: user?.meta?.allow_local === "1",
              auth_server_id: user?.meta?.auth_server_id,
              ldap_ad_username: user?.meta?.ldap_ad_username,
              ldap_ad_dn: user?.meta?.ldap_ad_dn,
            }
          : {
              language: "English",
              auth_type: "Local(Default)",
              auth_level: authLevelOptions[0],
              date_format: "YYYY/DD/MM HH:MM:SS",
              week_format: "Sunday - Saturday",
              number_format: "1,000.00",
            }
      }
      onFinish={onSubmit}
    >
      <Tabs activeKey={index} centered onChange={(index) => setIndex(index)}>
        <TabPane tab="General Settings" key="0" />
        <TabPane tab="Preferences" key="1" />
        <TabPane tab="Authentication Settings" key="2" />
        <TabPane tab="Security Settings" key="3" />
      </Tabs>
      <SwipeableViews
        index={Number(index)}
        onChangeIndex={(i) => setIndex(`${i}`)}
        enableMouseEvents
      >
        <div>
          <FormInput label="Username" name="username" />
          <Form.Item label="Password" name="password">
            <Password />
          </Form.Item>
          <FormCheckbox
            label="Email User Account Information"
            name="email_user_account"
            labelOnRight={false}
          />
          <FormInput label="Name" name="name" />
          <FormInput label="Email Address" name="email" />
          <FormInput label="Phone" name="phone" />
          <FormCheckbox
            label="Create as Mointoring Contact"
            name="monitoring_contact"
            labelOnRight={false}
          />
          <FormCheckbox
            label="Enable Notifications"
            name="notifications"
            labelOnRight={false}
          />
          <FormCheckbox
            label="Account Enabled"
            name="enabled"
            labelOnRight={false}
          />
        </div>
        <div>
          <FormDropdown
            label="Language"
            name="language"
            options={languageFlagObject.map(({ languageName }) => languageName)}
          />
          <FormDropdown
            name="date_format"
            label="Date Format"
            options={dateFormatOptions}
          />
          <FormDropdown
            label="Number Format"
            name="number_format"
            options={numberFormatOptions}
          />
          <FormDropdown
            label="Week Format"
            name="week_format"
            options={weekFormatOptions}
          />
        </div>
        <div>
          <FormDropdown
            label="Auth Type"
            name="auth_type"
            options={["Local", "AD Server", "LDAP"]}
            onChange={(value) => setAuthType(value)}
          />

          {authType === "Active Directory" && (
            <div>
              <FormDropdown
                label="AD Server"
                name="auth_server_id"
                options={[]}
                onChange={(value) => setAuthType(value)}
              />

              <FormInput label="AD Username" name="ad_username" />
              <FormCheckbox
                label="Allow local login if auth server login fails"
                name="allow_fail"
                labelOnRight={true}
              />
            </div>
          )}
          {authType === "LDAP" && (
            <div>
              <FormDropdown
                label="LDAP Server"
                name="ldap_ad_dn"
                options={[]}
                onChange={(value) => setAuthType(value)}
              />

              <FormInput label="User's Full DN:" name="ldap_ad_username" />
              <FormCheckbox
                label="Allow local login if auth server login fails"
                name="allow_fail"
                labelOnRight={true}
              />
            </div>
          )}
        </div>
        <div>
          <FormDropdown
            label="Authentication Level"
            name="auth_level"
            options={authLevelOptions}
            onChange={(value) => setAuthLevel(value)}
          />
          {authLevel === "User" && (
            <div>
              {checkboxList.map(({ name, key, helpText }) => (
                <FormCheckbox
                  label={name}
                  name={key}
                  labelOnRight={false}
                  helpText={helpText}
                />
              ))}
            </div>
          )}
          <FormCheckbox
            label="API Access"
            name="api_access"
            labelOnRight={false}
          />
        </div>
      </SwipeableViews>
    </Form>
  );
}
