import { Button } from "antd";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import useDeleteFavorite from "../../../Frame/hooks/useDeleteFavorite";
import { HeaderPopup, StyledButton } from "../../Atoms/HeaderPopup/HeaderPopup";

type favoritesProps = {
  data: {
    favorite_id: string;
    url: string;
    title: string;
  }[];
};

const FavoritesMenu = (props: favoritesProps) => {
  const [isEdit, setIsEdit] = useState(false);

  const history = useHistory();
  const handleButton = () => setIsEdit(!isEdit);

  const { mutate: deleteFavorite } = useDeleteFavorite();

  return (
    <HeaderPopup width="200px">
      <div>
        {props.data &&
          props.data.map((el) => (
            <>
              {isEdit ? (
                <StyledButton
                  onClick={() => deleteFavorite(el)}
                  type="text"
                  key={el.favorite_id}
                  block
                  data-testid="DeleteFavorite"
                >
                  {`Delete ${el.title}`}
                </StyledButton>
              ) : (
                <StyledButton
                  type="text"
                  key={el.favorite_id}
                  block
                  onClick={() => history.push(el.url)}
                >
                  <a href={el.url}>{el.title}</a>
                </StyledButton>
              )}
            </>
          ))}
        <Button ghost block onClick={handleButton}>
          {isEdit ? "Disable Edit Mode" : "Edit Favorites"}
        </Button>
      </div>
    </HeaderPopup>
  );
};

export default FavoritesMenu;
