import React from "react";
import { useToggle } from "../../../../hooks";
import { TableIcons } from "../../../Shared/Molecules/TableIcons";
import useDashboardPageEvents from "../../hooks/useDashboardPageEvents";
import { EditDashboardModal } from "../EditDashboardModal";

export default function DashboardTableActions({ dashboard }) {
  const {
    onShareButtonClick,
    onDeleteButtonClick,
    onDuplicateButtonClick,
  } = useDashboardPageEvents();

  const { open, toggleOpen } = useToggle();

  const { id, ...rest } = dashboard;
  return (
    <>
      <TableIcons
        icons={[
          {
            iconType: "share",
            onClick: () => onShareButtonClick(dashboard),
            withTooltip: true,
          },
          {
            iconType: "edit",
            onClick: () => toggleOpen(),
            withTooltip: true,
          },
          {
            iconType: "duplicate",
            onClick: () => onDuplicateButtonClick(dashboard),
            withTooltip: true,
          },
          {
            iconType: "download",
            withTooltip: true,
          },
          {
            iconType: "delete",
            onClick: () => onDeleteButtonClick(dashboard),
            withTooltip: true,
          },
        ]}
        dashboard={rest}
      />

      <EditDashboardModal
        open={open}
        toggleOpen={toggleOpen}
        dashboard={dashboard}
      />
    </>
  );
}
