type dataType = {
  id: number;
  value: number | string;
  text: string;
};

export const YesNo: dataType[] = [
  { id: 0, value: 0, text: "No" },
  { id: 1, value: 1, text: "Yes" },
];

export const HostState: dataType[] = [
  { id: 0, value: "up", text: "Host Up" },
  { id: 1, value: "down", text: "Host Down" },
  { id: 2, value: "unreachable", text: "Host Unreachable" },
  { id: 3, value: "pending", text: "Host Pending" },
  { id: 4, value: "all", text: "Host All" },
  { id: 5, value: "unhandled", text: "Host Unhandled" },
  { id: 6, value: "problems", text: "Host Problems" },
];

export const ServiceState: dataType[] = [
  { id: 0, value: "ok", text: "Service Ok" },
  { id: 0, value: "warning", text: "Service Warning" },
  { id: 0, value: "critical", text: "Service Critical" },
  { id: 0, value: "unknown", text: "Service Unknown" },
  { id: 0, value: "pending", text: "Service Pending" },
  { id: 0, value: "all", text: "Service All" },
  { id: 0, value: "unhandled", text: "Service Unhandled" },
  { id: 0, value: "problems", text: "Service Problems" },
];

export const TimePeriod: dataType[] = [
  { id: 1, value: 0.5, text: "Next 12 hours" },
  { id: 2, value: 1, text: "Next 24 hours" },
  { id: 3, value: 7, text: "Next Week" },
  { id: 4, value: 14, text: "Next 2 Weeks" },
  { id: 5, value: 30, text: "Next Month" },
  { id: 6, value: 365, text: "Next Year" },
];
