import React from "react";

export default function GlobalConfigSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 55 55"
    >
      <g>
        <g>
          <path
            fill="#eceafb"
            d="M0 18C0 8.059 8.059 0 18 0h19c9.941 0 18 8.059 18 18v19c0 9.941-8.059 18-18 18H18C8.059 55 0 46.941 0 37z"
          />
        </g>
        <g>
          <g>
            <path d="M39.41 30.747a12.623 12.623 0 0 1-1.688 4.064l-.272.426 2.33 3.26-2.283 2.282-3.259-2.329-.427.272a12.63 12.63 0 0 1-4.063 1.688l-.494.11-.658 3.946h-3.227l-.658-3.946-.494-.11a12.63 12.63 0 0 1-4.064-1.688l-.426-.272-3.26 2.329-2.281-2.281 2.329-3.26-.273-.427a12.599 12.599 0 0 1-1.687-4.064l-.11-.494-3.946-.658V26.37l3.946-.657.11-.494a12.623 12.623 0 0 1 1.688-4.064l.272-.426-2.33-3.26 2.282-2.282 3.26 2.329.426-.272a12.63 12.63 0 0 1 4.064-1.688l.494-.11.658-3.946h3.227l.658 3.946.494.11a12.63 12.63 0 0 1 4.063 1.688l.427.272 3.26-2.329 2.281 2.281-2.329 3.26.272.427a12.623 12.623 0 0 1 1.688 4.064l.11.494 3.946.657v3.226l-3.946.658zm5.555.118V25.1l-4.211-.702a14.076 14.076 0 0 0-1.502-3.615l2.484-3.478-4.076-4.076-3.478 2.484a14.13 14.13 0 0 0-3.615-1.502L29.865 10H24.1l-.702 4.211c-1.266.33-2.478.833-3.616 1.502l-3.477-2.484-4.076 4.076 2.484 3.478a14.076 14.076 0 0 0-1.502 3.615L9 25.1v5.765l4.211.702a14.07 14.07 0 0 0 1.502 3.615L12.23 38.66l4.076 4.076 3.477-2.484c1.138.67 2.35 1.173 3.616 1.502l.702 4.21h5.765l.702-4.21a14.13 14.13 0 0 0 3.615-1.502l3.478 2.484 4.076-4.076-2.484-3.478a14.076 14.076 0 0 0 1.502-3.615z" />
          </g>
          <g>
            <path d="M33.813 33.808a10.48 10.48 0 0 0-1.63-1.154c.321-1.192.521-2.515.574-3.925h3.176a8.944 8.944 0 0 1-2.12 5.079zm-3.193 2.386c.414-.597.778-1.298 1.086-2.08.363.225.714.472 1.045.751a9.004 9.004 0 0 1-2.13 1.329zm-2.891.625V32.8a8.934 8.934 0 0 1 2.64.624c-.646 1.737-1.581 2.986-2.64 3.395zm0-8.09h3.53a16.623 16.623 0 0 1-.453 3.261 10.43 10.43 0 0 0-3.077-.688zm0-4.095a10.43 10.43 0 0 0 3.072-.687c.249 1.008.41 2.117.459 3.283h-3.531zm0-5.493c1.054.406 1.987 1.647 2.632 3.373a8.935 8.935 0 0 1-2.632.622zm5.009 1.942c-.33.276-.68.52-1.04.744a10.206 10.206 0 0 0-1.078-2.061c.768.341 1.481.784 2.118 1.317zm3.185 6.147h-3.168a17.726 17.726 0 0 0-.577-3.945c.57-.328 1.112-.71 1.62-1.146a8.956 8.956 0 0 1 2.125 5.091zm-9.693-4.094a8.937 8.937 0 0 1-2.631-.622c.645-1.726 1.577-2.967 2.631-3.373zM22.7 27.23c.048-1.166.21-2.275.459-3.283.974.381 2.007.612 3.071.687v2.596zm.454 4.76a16.62 16.62 0 0 1-.454-3.261h3.53v2.573a10.43 10.43 0 0 0-3.076.688zm.437 1.434a8.938 8.938 0 0 1 2.64-.624v4.019c-1.06-.409-1.994-1.658-2.64-3.395zm-2.382 1.441a9 9 0 0 1 1.045-.752c.307.783.671 1.484 1.085 2.08a9.008 9.008 0 0 1-2.13-1.328zm-3.182-6.136h3.176c.053 1.41.252 2.733.573 3.925-.574.33-1.12.714-1.63 1.154a8.943 8.943 0 0 1-2.12-5.079zm2.126-6.59c.506.436 1.048.818 1.619 1.146a17.728 17.728 0 0 0-.578 3.945h-3.167a8.957 8.957 0 0 1 2.126-5.092zm3.186-2.373c-.41.592-.771 1.287-1.077 2.061a8.98 8.98 0 0 1-1.04-.745 9.005 9.005 0 0 1 2.117-1.316zm3.64-2.276c-5.783 0-10.489 4.706-10.489 10.49s4.706 10.49 10.49 10.49 10.49-4.706 10.49-10.49-4.706-10.49-10.49-10.49z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
