import { Card } from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { IconHorizontalFill } from "../Shared/Atoms/FontIcons/FontIcons";
import MonitorHosts from "../Shared/Organisms/MonitorHosts/MonitorHosts";
import MonitorServices from "../Shared/Organisms/MonitorServices/MonitorServices";
import PageHeader from "../Shared/Organisms/PageHeader/PageHeader";
import useHostsOverview from "../Frame/hooks/useHostsOverview";
import useServicesOverview from "../Frame/hooks/useServicesOverview";
import OnboardingAssistant from "../HelpPage/Molecules/OnboardingAssistant/OnboardingAssistant";
import {
  Administration,
  MostViewed,
  SetupHostsServices,
  StartMonitoring,
  ViewHostGroupsServiceGroups,
  ViewHostsServices,
} from "./Organisms/HomePageCards/HomePageCards";
import useCreateFavorite from "../Frame/hooks/useCreateFavorite";
import useHomeCardOrder from "./hooks/useHomePageOrder";
import useEditHomePageOrder from "./hooks/useEditHomePageOrder";

const completed = 2; //temporary data until onboarding assistant is finished

const StyledBody = styled.div`
  padding: 20px;
  position: relative;
`;

const FlexBox = styled.div`
  display: flex;
  padding: 10px;
  > section:first-child {
    flex: 2;
  }
  > section:last-child {
    flex: 1;
    max-width: 350px;
    margin-left: 25px;
  }
  .ant-card {
    margin-bottom: 25px;
    .dragButton > i {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
`;

const HomePage = (props) => {
  const { data: cardOrder, isLoading } = useHomeCardOrder();
  const { mutate: editCardOrder } = useEditHomePageOrder();

  const { data: hostData } = useHostsOverview();
  const { data: serviceData } = useServicesOverview();
  const { mutate: createFavorite } = useCreateFavorite();
  const onDragEnd = (result) => {
    let updateOrder = [...cardOrder];
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableID === source.droppableID &&
      destination.index === source.index
    ) {
      return;
    }
    let selectedCard = updateOrder.splice(source.index, 1);
    updateOrder.splice(destination.index, 0, selectedCard[0]);

    const finalOrder = updateOrder.map(({ display_order, ...rest }, i) => ({
      ...rest,
      display_order: i + 1,
    }));

    editCardOrder(finalOrder);
  };

  const DraggableCard = (props) => (
    <Draggable draggableId={props.id} index={props.index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Card bordered={false}>
            <div {...provided.dragHandleProps} className="dragButton">
              <IconHorizontalFill size="25px" color="rgba(0,0,0,0.4)" />
            </div>
            {props.children}
          </Card>
        </div>
      )}
    </Draggable>
  );

  if (isLoading) {
    return <p>Is Loading</p>;
  }

  const cardObject = {
    "Start Monitoring": <StartMonitoring />,
    "Setup Hosts/services": <SetupHostsServices />,
    "Hosts/services": <ViewHostsServices />,
    "Most Visited": <MostViewed />,
    "Hostgroups/Servicegroups": <ViewHostGroupsServiceGroups />,
    Admin: <Administration />,
  };

  return (
    <StyledBody>
      <PageHeader
        title="Home"
        sub="Check status summary, and setup and ongoing administrative tasks"
        favoriteClicked={() => createFavorite({ title: "Home", url: "/home/" })}
      />
      <FlexBox>
        <section>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="home_page_cards">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {cardOrder.map((el, i) => (
                    <DraggableCard id={el.card} index={i} key={i}>
                      <h3>{el.card}</h3>
                      {cardObject[el.card]}
                    </DraggableCard>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </section>
        <section>
          <Card bordered={false}>
            <h3>Onboarding Assistant</h3>
            <OnboardingAssistant completed={completed} />
          </Card>
          <Card bordered={false}>
            <h3>Host Status</h3>
            <MonitorHosts
              data={hostData ? hostData : undefined}
              isMonitoring={hostData ? true : false}
            />
          </Card>
          <Card bordered={false}>
            <h3>Service Status</h3>
            <MonitorServices
              data={serviceData ? serviceData : undefined}
              isMonitoring={serviceData ? true : false}
            />
          </Card>
        </section>
      </FlexBox>
    </StyledBody>
  );
};

export default HomePage;
