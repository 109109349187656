import { ResponsivePie } from "@nivo/pie";
import { Skeleton } from "antd";
import React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { PERFORMANCE_DATA_GAUGE_DASHLET_URL } from "../../../../api.config";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import { fetchData } from "../../../../utils/axiosHelpers";
import { dashletMockData } from "../../DashletMockData";
import { DashletProps } from "../../DashletViewPageTypes";

const StyledText = styled.text`
  font-size: 50px;
  font-weight: 600;
  color: ${(props) => props.color};
`;

export default function PerfDataGaugeDashlet({
  demo,
  dashletId,
}: DashletProps) {
  const theme = useMasterTheme().theme;

  let { data: graphData, isLoading } = useQuery(
    ["Fetch Performance Gauge Data", dashletId],
    async () => await fetchData(PERFORMANCE_DATA_GAUGE_DASHLET_URL),
    demo
      ? {
          enabled: false,
        }
      : {
          select: (data) => data,
        }
  );

  if (isLoading) {
    return <Skeleton />;
  }

  graphData = demo ? dashletMockData["perfdata_gauge"].data : graphData.data;

  const percentFull = Math.round(
    (graphData?.value / (graphData?.maximum ? graphData?.maximum : 100)) * 100
  );

  const remaining =
    (graphData?.maximum ? graphData?.maximum : 100) - graphData?.value;

  const warning = graphData?.warning
    ? graphData?.warning / (graphData?.maximum ? graphData?.maximum : 100)
    : 50;

  const critical = graphData?.critical
    ? graphData?.critical / (graphData?.maximum ? graphData?.maximum : 100)
    : 50;

  const color =
    percentFull < warning ? "green" : percentFull < critical ? "yellow" : "red";

  const CenteredMetric = ({ centerX, centerY }) => {
    return (
      <>
        <StyledText
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="central"
          color={theme.typographyPrimaryColor}
          style={{
            background: theme.typographyPrimaryColor,
          }}
        >
          {`${percentFull}%`}
        </StyledText>
      </>
    );
  };

  return (
    <ResponsivePie
      data={[
        {
          value: graphData?.value,
          color: color,
        },

        {
          value: remaining,
          color: "rgb(230, 229, 229)",
        },
      ]}
      theme={{
        textColor: theme.typographyPrimaryColor,
        labels: {
          text: { color: "white" },
        },
        tooltip: {
          container: {
            background: theme.cardBackgroundColor,
          },
        },
      }}
      margin={{ top: 50, bottom: 100, left: 50, right: 50 }}
      startAngle={-90}
      endAngle={90}
      activeOuterRadiusOffset={0}
      colors={{ datum: "data.color" }}
      innerRadius={0.8}
      enableArcLinkLabels={true}
      layers={["arcs", "arcLabels", CenteredMetric]}
    />
  );
}
