import { Button, Card, Upload } from "antd";
import { useHistory } from "react-router-dom";
import { DashboardViewRowTag } from "../../Atoms";
import useDashboards from "../../hooks/useDashboards";
import CustomTable, {
  ExpandButton,
} from "../../../Shared/Organisms/CustomTable/CustomTable";
import { useFilterDashboard } from "../../Organisms/AllDashboardsCard/AllDashboardsCard";
import DashboardTableActions from "./DashboardTableActions";
import { Dashboard } from "../../DashboardPageTypes";
import useDashboardPageEvents from "../../hooks/useDashboardPageEvents";
import DashletViewTable from "./DashletViewTable";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
const { Dragger } = Upload;

export default function DashboardViewTable() {
  const theme = useMasterTheme().theme;
  const history = useHistory();
  const { filterOption } = useFilterDashboard();
  const { data: dashboards } = useDashboards(filterOption);

  const { onImportDashboard } = useDashboardPageEvents();
  const columns = [
    {
      title: "",
      key: "view",
      render: (row) => (
        <Button
          type="link"
          onClick={() => {
            history.push(`/dashboards/${row.dashboard_id}`);
          }}
        >
          View
        </Button>
      ),
    },
    {
      title: "",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      key: "tags",
      dataIndex: "tagList",
      render: (tagList: string[]) => (
        <>
          {tagList.map((tagName, key) => (
            <DashboardViewRowTag name={tagName} key={key} />
          ))}
        </>
      ),
    },
    {
      title: "",
      key: "action",
      render: (row: Dashboard) => <DashboardTableActions dashboard={row} />,
    },
  ];

  return (
    <Card>
      <CustomTable
        data-testid="DashboadViewTable"
        dataSource={dashboards}
        columns={columns}
        showHeader={false}
        pagination={{ hideOnSinglePage: true }}
        rowKey="dashboard_id"
        expandable={{
          expandedRowRender: (record) => (
            <DashletViewTable dashboard={record} />
          ),
          expandIcon: (props) => (
            <span onClick={(e) => props.onExpand(props.record, e)}>
              {ExpandButton(props.expanded)}
            </span>
          ),
          rowExpandable: () => {
            return true;
          },
        }}
      />
      <Dragger
        style={{
          background: theme.cardBackgroundColor,
          color: theme.typographyPrimaryColor,
        }}
        accept=".json"
        showUploadList={false}
        beforeUpload={(file) => {
          const regex = /Nagios_(.*)_Export/;
          return regex.test(file.name);
        }}
        onChange={(info) => {
          if (info.file.status !== "uploading") {
            onImportDashboard(info.file);
          }
        }}
      >
        Drag or Click to create Dashboards with export.json file.
      </Dragger>
    </Card>
  );
}
