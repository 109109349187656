import { ResponsiveHeatMap } from "@nivo/heatmap";
import { Row, Skeleton } from "antd";
import { uniq } from "lodash";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import { useQuery } from "react-query";
import { fetchData } from "../../../../utils/axiosHelpers";
import { HEATMAP_DASHLET_URL } from "../../../../api.config";
import CustomCell from "../Shared/Custom";
import { dashletMockData } from "../../DashletMockData";
import { DashletProps } from "../../DashletViewPageTypes";

const formatData = (data) => {
  return Object.entries(data?.service_data).map((entry) => {
    const [host_name, values]: any = entry;

    const services = Object.entries(values).map(
      ([display_name, { status_text, state }]: any) => ({
        display_name,
        status_text,
        state,
      })
    );

    const formatedServices = services.map((service) => {
      const { display_name, state, status_text } = service;
      const outputLabel = `${display_name} Output`;

      return {
        [display_name]: state,
        [outputLabel]: status_text,
      };
    });

    return Object.assign({ host_name }, ...formatedServices);
  });
};

export default function HeatMapDashlet({
  properties,
  demo,
  dashletId,
}: DashletProps) {
  const theme = useMasterTheme().theme;
  let { data, isLoading }: any = useQuery(
    ["Fetch Heatmap Data", dashletId],
    async () => await fetchData(HEATMAP_DASHLET_URL),
    demo
      ? {
          enabled: false,
        }
      : {
          select: (data) => formatData(data),
        }
  );

  if (isLoading) {
    return <Skeleton />;
  }

  // const { filter_by_host, filter_by_service } = properties;

  data = demo ? formatData(dashletMockData["heatmap"]) : data;

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <ResponsiveHeatMap
          data={demo ? data : dashletMockData["heatmap"]}
          tooltip={({ xKey, yKey, value }) => {
            const outputLabel = `${xKey} Output`;

            const [{ [outputLabel]: output }] = data.filter(
              ({ host_name }) => host_name === yKey
            );

            return (
              <>
                <Row>
                  <strong>{`${yKey} - ${xKey}`}</strong>
                </Row>
                <Row>
                  <strong>{value}</strong>
                </Row>
                <Row>
                  <strong>{output}</strong>
                </Row>
              </>
            );
          }}
          theme={{
            textColor: theme.typographyPrimaryColor,
            tooltip: {
              container: {
                background: theme.cardBackgroundColor,
              },
            },
          }}
          indexBy="host_name"
          keys={uniq(
            Object.entries(data)
              .map(([_, VALUES]: any) => {
                return Object.keys(VALUES);
              })
              .flat()
              .filter((key: string) => !key.includes("Output"))
              .filter((key: string) => key !== "host_name")
          )}
          cellShape={(cell) =>
            CustomCell(
              cell,
              {
                ok: "green",
                1: "yellow",
                critical: "red",
                3: "gray",
              },
              properties
            )
          }
          enableLabels={false}
          enableGridX={true}
          enableGridY={true}
          margin={{ top: 150, bottom: 100, left: 100, right: 50 }}
          axisTop={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: -90,
            legend: "",
            legendOffset: 36,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "",
            legendPosition: "middle",
            legendOffset: 40,
          }}
        />
      )}
    </>
  );
}
