import React, { createContext, Suspense, useContext } from "react";
import { Iframe } from "../components/Frame";

export type Button = {
  label: string;
  onClickFunction: any;
};

export type MenuItem = {
  RouterComponent: any;
  path: string;
  label: string;
};

export type Menu = {
  label: string;
  menuItemList: MenuItem[];
};

export type SubItems = {
  buttons: Button[];
  menus: Menu[];
};

export type RouterObject = {
  RouterComponent?: any;
  path: string;
  label: string;
  subMenu?: SubItems;
};

export type OtherRoutes = {
  RouterComponent?: any;
  path: string;
};

type RouterProviderObject = {
  routerObjectArray: RouterObject[];
};

type Props = {
  children: JSX.Element[] | JSX.Element;
  context: RouterProviderObject;
};

export function PageRoute({ PageComponent }: any) {
  return (
    <Suspense fallback={<div />}>
      <PageComponent />
    </Suspense>
  );
}

export const createRouterObjectWithIframe = (title: string) =>
  createRouterObject(
    title,
    () => <Iframe url={title.toLowerCase()} />,
    `/${title.toLowerCase()}`,
    undefined
  );

export const createRouterObject = (
  label: string,
  component: any,
  path: string,
  subMenu?: SubItems
) => ({
  RouterComponent: () => <PageRoute PageComponent={component} />,
  path,
  label,
  subMenu,
});

export const RouterContext = createContext<RouterProviderObject>({
  routerObjectArray: [],
});

export function RouterProvider({ children, context }: Props) {
  return (
    <RouterContext.Provider value={context}>{children}</RouterContext.Provider>
  );
}

export function useRouter() {
  return useContext(RouterContext);
}
