import { ResponsiveHeatMap } from "@nivo/heatmap";
import { Skeleton } from "antd";
import { useQuery } from "react-query";
import { CORE_COMPONENT_STATUS_DASHLET_URL } from "../../../../api.config";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import { fetchData } from "../../../../utils/axiosHelpers";
import { dashletMockData } from "../../DashletMockData";
import { DashletProps } from "../../DashletViewPageTypes";
import CustomCell from "../Shared/Custom";

export default function CoreComponentStatusDashlet({
  dashletId,
  demo,
  properties,
}: DashletProps) {
  const theme = useMasterTheme().theme;
  const { data, isLoading }: any = useQuery(
    ["Fetch Core Component Status", dashletId],
    () => fetchData(CORE_COMPONENT_STATUS_DASHLET_URL),
    demo
      ? {
          enabled: false,
        }
      : {
          select: (data) => {
            return Object.values(data);
          },
        }
  );

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <ResponsiveHeatMap
      data={
        demo ? Object.values(dashletMockData["core_component_status"]) : data
      }
      keys={["status"]}
      indexBy="name"
      enableGridX={true}
      enableGridY={true}
      theme={{
        textColor: theme.typographyPrimaryColor,
        tooltip: {
          container: {
            background: theme.cardBackgroundColor,
          },
        },
      }}
      cellShape={(cell) =>
        CustomCell(
          cell,
          {
            0: "red",
            1: "green",
          },
          properties
        )
      }
      margin={{ top: 150, bottom: 100, left: 200, right: 50 }}
      axisTop={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: -90,
        legend: "",
        legendOffset: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Component",
        legendPosition: "middle",
        legendOffset: -150,
      }}
    />
  );
}
