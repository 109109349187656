import { Button, Form, Input, Modal, Select } from "antd";
import React from "react";
import styled from "styled-components";
import { useGlobalModal } from "../../../../context/GlobalModalContext";
import usePlaylist from "../../hooks/usePlaylists";
import useViewPageEvents from "../../hooks/useViewsPageEvents";
import { flatten, uniqBy } from "lodash";
const { Option } = Select;

export type Props = {
  sample: string;
};

const StyledModal = styled(Modal)`
  & .ant-modal-header {
    padding-bottom: 0px;
  }
  & .ant-modal-body {
    padding: 0 10px 10px;
  }
`;

export default function AddAPLaylistModal() {
  const { data: playlists } = usePlaylist();
  const { createPlaylistForm, onCreatePlaylistButtonClick } =
    useViewPageEvents();
  const { toggleAddAPlaylistModalOpen, addAPlaylistModalOpen } =
    useGlobalModal();
  const closeModal = () => toggleAddAPlaylistModalOpen();
  const viewsList = Object.values([...(playlists ? playlists : [])]).map(
    ({ views }: any) => views ? Object.values(views) : []
  );

  return (
    <StyledModal
      visible={addAPlaylistModalOpen}
      title="Create a Playlist"
      width={"600px"}
      onCancel={closeModal}
      footer={
        <Button key="close" onClick={closeModal}>
          Close Window
        </Button>
      }
    >
      <Form
        form={createPlaylistForm}
        onFinish={() => {
          onCreatePlaylistButtonClick();
          toggleAddAPlaylistModalOpen();
        }}
        layout="vertical"
        initialValues={{ name: "", views: [] }}
      >
        <Form.Item
          data-testid="PlaylistName"
          label="Playlist Name"
          name="name"
          rules={[{ required: true, message: "Please enter playlist name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item data-testid="PlaylistViews" label="Views" name="views">
          <Select data-testid="viewsSelect" mode="multiple">
            {uniqBy(flatten(viewsList), "name").map((view: any, i: number) => (
              <Option value={view.name} key={i}>
                {view.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Button onClick={() => createPlaylistForm.submit()}>
          Create Playlist
        </Button>
      </Form>
    </StyledModal>
  );
}
