import { HOME_PAGE_ORDER_URL, MOST_VIEWED_URL } from "../../api.config";
import {
  createGetMock,
  createPutMock,
} from "../../mockServer/createMockHandler";
import { MostViewedPages } from "./HomePageTypes";

export const createMostViewObject = (
  name: string,
  url,
  timesVisted: number
) => ({
  name,
  url,
  times_visted: timesVisted,
});

export const cardOrder = [
  {
    card_id: 1,
    card: "Start Monitoring",
    display_order: 1,
  },
  {
    card_id: 2,
    card: "Most Visited",
    display_order: 2,
  },
  {
    card_id: 3,
    card: "Hosts/services",
    display_order: 3,
  },
  {
    card_id: 4,
    card: "Hostgroups/Servicegroups",
    display_order: 4,
  },
  {
    card_id: 5,
    card: "Admin",
    display_order: 5,
  },
];

export const mostViewedList: MostViewedPages = [
  createMostViewObject("Tools Page", "", 100),
  createMostViewObject("Dashboard Page", "", 5),
  createMostViewObject("Corporate", "", 4),
  createMostViewObject("Test List", "", 3),
  createMostViewObject("BPI", "", 1),
];

export const mocks = [
  createGetMock(HOME_PAGE_ORDER_URL, 200, {
    cards: cardOrder,
  }),
  createPutMock(HOME_PAGE_ORDER_URL, 200, {
    message: "Updated Page Order",
  }),
  createGetMock(MOST_VIEWED_URL, 200, {
    most_viewed: mostViewedList,
  }),
  createPutMock(MOST_VIEWED_URL, 200, {
    message: "Updated Most Viewed",
  }),
];
