import {
  HeaderPopup,
  StyledH2,
  StyledHR,
  StyledSub,
  StyledIcon,
} from "../../Atoms/HeaderPopup/HeaderPopup";
import * as Icon from "../../Atoms/FontIcons/FontIcons";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";

type StatusMenuProps = {
  data:
    | {
        active_host_checks: number;
        active_service_checks: number;
        database_backend_status: number;
        monitoring_engine_status: number;
        notifications_enabled: number;
        performance_grapher_status: number;
        status: string;
        last_updated: string;
      }
    | undefined;
};

const NagiosStatus = (props: StatusMenuProps) => {
  const icon = {
    primary: themeVariables.colorPrimary,
    success: themeVariables.colorSuccess,
    warning: themeVariables.colorWarning,
    danger: themeVariables.colorDanger,
    info: themeVariables.colorInfo,
  };

  const getStatus = (status) => {
    switch (status) {
      case 1:
        return (
          <Icon.IconCircle color={icon.success}>
            <Icon.IconCheck />
          </Icon.IconCircle>
        );
      case 0:
        return <Icon.IconCircle color={icon.danger} />;
      default:
        return <Icon.IconRefresh />;
    }
  };

  /*
  <StyledIcon>
    {getStatus(props.data.performanceGrapherstatus)} Performance Grapher
  </StyledIcon>
  */

  return (
    <HeaderPopup width="240px">
      {props.data && (
        <div>
          <StyledH2>Nagios XI System is {props.data.status}</StyledH2>
          <StyledSub>Last Updated: {props.data.last_updated}</StyledSub>
          <StyledHR />
          <StyledSub>Processess</StyledSub>
          <StyledIcon>
            {getStatus(props.data.monitoring_engine_status)} Monitoring Engine
          </StyledIcon>
          <StyledIcon>
            {getStatus(props.data.database_backend_status)} Backend API
          </StyledIcon>
          <StyledSub>Settings</StyledSub>
          <StyledIcon>
            {getStatus(props.data.active_host_checks)} Active Host Checks
          </StyledIcon>
          <StyledIcon>
            {getStatus(props.data.active_service_checks)} Active Service Checks
          </StyledIcon>
          <StyledIcon>
            {getStatus(props.data.notifications_enabled)} Notifications
          </StyledIcon>
        </div>
      )}
    </HeaderPopup>
  );
};

export default NagiosStatus;
