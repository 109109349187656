import { Card, Checkbox } from "antd";
import React from "react";
import styled from "styled-components";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";
import {
  IconMagicWand,
  IconQuestionCircle,
  IconSearchObject,
} from "../../../Shared/Atoms/FontIcons/FontIcons";
import IconButtonLarge from "../../../Shared/Molecules/IconButtonLarge/IconButtonLarge";

const SupportCard = styled(Card)`
  & .ant-card-body {
    display: flex;
    flex-wrap: wrap;
    & h4 {
      font-size: 16px;
      width: 100%;
      margin: 0 10px 20px;
    }
    & label.ant-checkbox-wrapper {
      width: 100%;
      margin: 0 10px 10px;
      color: ${themeVariables.colorGrayLight1};
      font-size: 14px;
      & .ant-checkbox-inner {
        border-radius: 4px;
        height: 20px;
        width: 20px;
        background-color: ${themeVariables.colorBluePale};
      }
    }
  }
`;

export type FTUProps = {
  showSupportFTU: boolean;
  setupGuide: () => void;
  learnHow: () => void;
  seeDetails: () => void;
  hideSupportFTU: () => void;
};

const SupportFTU = (props: FTUProps) => {
  const theme = useMasterTheme().theme;

  if (props.showSupportFTU) {
    return (
      <SupportCard>
        <h4>Support for First Time Users</h4>
        <Checkbox onChange={props.hideSupportFTU}>
          Installation and set up are complete. Don't show this section again.
        </Checkbox>
        <IconButtonLarge
          color={theme.iconLightGreenBG}
          icon={<IconMagicWand color={themeVariables.colorGreenDark} />}
          heading={"Setup"}
          sub={"Get your new software up and running fast"}
          buttonOneLabel={"Setup Guide"}
          buttonOneClicked={props.setupGuide}
        />
        <IconButtonLarge
          color={theme.iconLightPinkBG}
          icon={<IconSearchObject color={themeVariables.colorPinkDark} />}
          heading={"Nagios Integration"}
          sub={
            "Integrate with other Nagios products like Log Server or Network Analyzer"
          }
          buttonOneLabel={"Learn How"}
          buttonOneClicked={props.learnHow}
        />
        <IconButtonLarge
          color={theme.iconLightPurpleBG2}
          icon={<IconQuestionCircle color={themeVariables.colorPurpleDark} />}
          heading={"Don't forget about in-page help"}
          buttonOneLabel={"See Details"}
          buttonOneClicked={props.seeDetails}
          disabled
        />
      </SupportCard>
    );
  } else {
    return null;
  }
};

export default SupportFTU;
