import { Card } from "antd";
import React from "react";
import { ImageSharedToolkit } from "../../../Shared/Atoms/ClipArt/ClipArt";
import EmptyTable from "../../../Shared/Atoms/EmptyTable/EmptyTable";
import ToolkitTable from "../../Molecules/ToolkitTable/ToolkitTable";

const SharedToolkit = (props) => {
  return (
    <Card>
      <h3>Shared Toolkit</h3>
      {props.data && props.data.length > 0 ? (
        <ToolkitTable data={props.data} />
      ) : (
        <EmptyTable>
          <ImageSharedToolkit />
          <h4>No shared tools available.</h4>
          <p>There are no shared tools available at this time.</p>
        </EmptyTable>
      )}
    </Card>
  );
};

export default SharedToolkit;
