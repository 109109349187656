import { useMutation, useQueryClient } from "react-query";
import { FAVORITES_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { deleteData } from "../../../utils/axiosHelpers";
import { Favorite, Favorites } from "../FrameTypes";
import { KEY } from "./useFavorites";

export default function useDeleteFavorite() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    (values) => deleteData(`${FAVORITES_URL}/${values.favorite_id}`, values),
    {
      onMutate: async (favoriteToDelete: any) => {
        const CALL_REASON = `Delete favorite ${favoriteToDelete.name}`;
        const { favorite_id } = favoriteToDelete;

        await queryClient.cancelQueries(KEY);

        const previousFavoriteList: any = queryClient.getQueryData(KEY);

        const newFavoriteList = previousFavoriteList.favorites.filter(
          (favorite: Favorite) => favorite.favorite_id !== favorite_id
        );

        queryClient.setQueryData(KEY, {
          favorites: newFavoriteList,
        });

        return {
          previousFavoriteList,
          newFavoriteList,
          CALL_REASON,
        };
      },
      onSuccess: (data, _, context) => {
        onSuccessNotification(data, context?.CALL_REASON);

        queryClient.setQueryData(KEY, {
          favorites: context?.newFavoriteList,
        });
      },
      onError: (data, _, context) => {
        onErrorNotification(data, context?.CALL_REASON);
        if (context?.previousFavoriteList) {
          queryClient.setQueryData<Favorites>(
            [KEY],
            context.previousFavoriteList
          );
        }
      },

      onSettled: () => {
        queryClient.invalidateQueries(KEY);
      },
    }
  );
}
