import {
  Card as AntCard,
  PageHeader,
  Row,
  Col as AntCol,
  Button,
  Input,
  Skeleton,
} from "antd";
import { chunk, find } from "lodash";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { useToggle } from "../../hooks";
import { Dashlet } from "../DashboardPage/DashboardPageTypes";
import {
  createDashletObject,
  createPositionObject,
  createDimensionObject,
} from "../DashboardPage/DashboardsPageMock";
import { useDashboard } from "../DashboardPage/hooks/useDashboards";
import { IconCheck } from "../Shared/Atoms/FontIcons/FontIcons";
import { dashletNameObject, dashletTypeList } from "./DashletViewPageConstants";
import useDashletObject from "./hooks/useDashletObject";
import useDashletPageEvents from "./hooks/useDashletPageEvents";
import { DashletModal } from "./Molecules/DashletModal";
const { Search } = Input;

type CardProps = {
  color: string;
};

type DashletWrapperProps = {
  dashlet: Dashlet;
};

const Card = styled(AntCard)`
  background: ${({ color }: CardProps) => color};
  height: inherit;
  width: inherit;
`;

const Col = styled(AntCol)`
  padding-top: 25px;
`;

const DashletWrapper = ({ dashlet }: DashletWrapperProps) => {
  const dashletTypeObject = useDashletObject(true);

  return dashletTypeObject[dashlet.type];
};

const DashletPreview = ({
  dashlet,
  dashboard,
  isDemo,
  initalData,
}): JSX.Element => {
  const dashletTypeObject = useDashletObject(true);

  const { onDeleteDashletButtonClick, onCreateDashletButtonClick } =
    useDashletPageEvents(dashboard?.id);

  const { open: dashletModalOpen, toggleOpen: toggleDashletModalOpen } =
    useToggle();

  const alreadyInDashboard = find(dashboard.dashlets, { id: dashlet.id });

  return (
    <div>
      <Card
        title={isDemo ? dashletNameObject[dashlet.type] : dashlet.name}
        color={dashlet.color}
        extra={
          <Button
            type={alreadyInDashboard && !isDemo ? "primary" : "default"}
            onClick={() =>
              isDemo
                ? toggleDashletModalOpen()
                : alreadyInDashboard
                ? onDeleteDashletButtonClick(dashlet)
                : onCreateDashletButtonClick(dashlet)
            }
          >
            {alreadyInDashboard && !isDemo ? (
              <>
                <IconCheck size="1.4em" /> Added
              </>
            ) : (
              "Add to Dashboard"
            )}
          </Button>
        }
      >
        <Row style={{ height: `750px`, width: "inherit" }}>
          {isDemo ? (
            dashletTypeObject[dashlet.type]
          ) : (
            <DashletWrapper dashlet={dashlet} />
          )}
        </Row>
      </Card>
      <DashletModal
        open={dashletModalOpen}
        toggleOpen={toggleDashletModalOpen}
        dashlet={dashlet}
        dashboard={dashboard}
        editDashlet={false}
      />
    </div>
  );
};

export default function CreateDashletPage({ demo }) {
  const { dashboardId } = useParams<any>();
  const history = useHistory();
  const { data: dashboard, isLoading } = useDashboard(demo ? 3 : dashboardId);

  const [filterOption, setFilterOption] = useState({ name: "", params: "" });
  const [toggleMode, setToggleMOde] = useState(true);

  const demoDashlets = dashletTypeList
    .map((dashlet) =>
      createDashletObject(
        dashlet,
        dashlet,
        createPositionObject(1, 1),
        createDimensionObject(1, 1),
        "",
        "white",
        []
      )
    )
    .filter(({ name }) => name.includes(filterOption.params));

  const onSearch = (value: any) => {
    setFilterOption({ name: "Search", params: value });
  };

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <>
      <PageHeader
        title={`Add ${
          toggleMode ? "Dashlet Template" : "Existing Dashlet"
        } to ${dashboard?.name}`}
        extra={[
          <Row>
            <Col span={12}>
              <Button
                onClick={() => history.push(`/dashboards/${dashboardId}`)}
              >
                View Dashboard
              </Button>
            </Col>
            <Col span={12}>
              <Button onClick={() => history.push(`/dashboards/`)}>
                Mangage Dashboards
              </Button>
            </Col>
          </Row>,

          <Row>
            <Col span={12}>
              <Search
                placeholder="Search by Dashlet"
                data-testid="dashletSearch"
                onSearch={onSearch}
                onChange={(e) => onSearch(e.target.value)}
              />
            </Col>
            <Col span={10}>
              <Button onClick={() => setToggleMOde((prev) => !prev)}>
                {toggleMode ? "Add Existing Dashlet" : "Add Dashlet Templates"}
              </Button>
            </Col>
          </Row>,
        ]}
      />
      <>
        {chunk([...(toggleMode ? demoDashlets : [])], 3).map(
          (dashletList: any, i) => (
            <Row justify="space-around">
              {dashletList.map((dashlet: any, i) => (
                <Col span={7} key={i} style={{}}>
                  <DashletPreview
                    dashlet={dashlet}
                    dashboard={dashboard}
                    isDemo={toggleMode}
                    initalData={[]}
                  />
                </Col>
              ))}
            </Row>
          )
        )}
      </>
    </>
  );
}
