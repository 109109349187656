import { Link } from "react-router-dom";
import { ImageMonitorService } from "../../Atoms/ClipArt/ClipArt";
import { Button } from "antd";
import { IconClose } from "../../Atoms/FontIcons/FontIcons";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";
import {
  StyledTable,
  StyledRow,
  StyledDiv,
  LastCheck,
  TableDivider,
  Centered,
  CenteredTable,
  StatusCircle
} from "../../Atoms/MonitorCard/MonitorCard";

const MonitorServices = (props) => {
  const handleButton = () => alert("You clicked the 'Clear Unhandled' button");
  const Monitoring = () =>
    props.data && (
      <>
        <CenteredTable>
          <StyledTable>
            <tbody>
              <StyledRow>
                <td>
                  <StatusCircle style={{
                      backgroundColor: themeVariables.colorOk,
                      borderColor: themeVariables.colorOk
                    }} />
                </td>
                <td>Ok</td>
                <td>{props.data.ok}</td>
                <td>
                  <Button type="link">
                    <Link to="/status/services/ok">
                      View
                    </Link>
                  </Button>
                </td>
              </StyledRow>
              <StyledRow>
                <td>
                  <StatusCircle style={{
                      backgroundColor: themeVariables.colorWarning,
                      borderColor: themeVariables.colorWarning
                    }} />
                </td>
                <td>Warning</td>
                <td>{props.data.warning}</td>
                <td>
                  <Button type="link">
                    <Link to="/status/services/warning">
                      View
                    </Link>
                  </Button>
                </td>
              </StyledRow>
              <StyledRow>
                <td>
                  <StatusCircle style={{
                      backgroundColor: themeVariables.colorCritical,
                      borderColor: themeVariables.colorCritical
                    }} />
                </td>
                <td>Critical</td>
                <td>{props.data.critical}</td>
                <td>
                  <Button type="link">
                    <Link to="/status/services/critical">
                      View
                    </Link>
                  </Button>
                </td>
              </StyledRow>
              <StyledRow>
                <td>
                  <StatusCircle />
                </td>
                <td>Unknown</td>
                <td>{props.data.unknown}</td>
                <td>
                  <Button type="link">
                    <Link to="/status/services/unknown">
                      View
                    </Link>
                  </Button>
                </td>
              </StyledRow>
              <StyledRow>
                <td>
                  <StatusCircle />
                </td>
                <td>Pending</td>
                <td>{props.data.pending}</td>
                <td>
                  <Button type="link">
                    <Link to="/status/services/pending">
                      View
                    </Link>
                  </Button>
                </td>
              </StyledRow>
              <StyledRow>
                <td>
                  <StatusCircle />
                </td>
                <td>All</td>
                <td>{props.data.all}</td>
                <td>
                  <Button type="link">
                    <Link to="/status/services">
                      View
                    </Link>
                  </Button>
                </td>
              </StyledRow>
            </tbody>
          </StyledTable>
          <TableDivider />
          <StyledTable>
            <tbody>
              <StyledRow>
                <td>
                  <StatusCircle style={{
                      backgroundColor: themeVariables.colorUnhandled,
                      borderColor: themeVariables.colorUnhandled
                    }} />
                </td>
                <td>Unhandled</td>
                <td>{props.data.unhandled}</td>
                <td>
                  <Button type="link">
                    <Link to="/status/services/unhandled">
                      View
                    </Link>
                  </Button>
                </td>
              </StyledRow>
              <StyledRow>
                <td>
                  <StatusCircle style={{
                      backgroundColor: themeVariables.colorProblems,
                      borderColor: themeVariables.colorProblems
                    }} />
                </td>
                <td>Problems</td>
                <td>{props.data.problems}</td>
                <td>
                  <Button type="link">
                    <Link to="/status/services/problems">
                      View
                    </Link>
                  </Button>
                </td>
              </StyledRow>
            </tbody>
          </StyledTable>
        </CenteredTable>
        {props.data.unhandled && (
          <StyledDiv>
            <Button block onClick={handleButton}>
              <IconClose /> Clear Unhandled
            </Button>
          </StyledDiv>
        )}
        {props.data.last_updated && (
          <LastCheck>Last check: {props.data.last_updated}</LastCheck>
        )}
      </>
    );

  const NotMonitoring = () => (
    <Centered>
      <ImageMonitorService />
      <h3>You aren't monitoring any Services yet!</h3>
      <p>Once you've setup a service, you will see data here.</p>
      <a href="/">See Setup Wizards</a>
    </Centered>
  );

  return props.isMonitoring ? <Monitoring /> : <NotMonitoring />;
};

export default MonitorServices;
