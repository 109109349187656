import {
  SUBMENU_URL,
  FAVORITES_URL,
  HOST_URL,
  NAGIOS_INFO_URL,
  NAGIOS_STATUS_URL,
  SERVICE_URL,
  SIDENAV_URL,
  USER_CURRENT_URL,
  WIZARDS_URL,
  HOST_OVERVIEW_URL,
} from "../../api.config";
import {
  createDeleteMock,
  createGetMock,
  createPostMock,
} from "../../mockServer/createMockHandler";
import { getRandomNumberInRange } from "../../utils/logic";
import { SubMenu } from "./FrameTypes";

const DELETE_FAVORITE_MESSAGE = "Deleted Favorite 12345";
const CREATED_FAVORITE_MESSAGE = "CREATED Favorite 12345";
const EDIT_SIDENAV_SUBMENU_MESSAGE = "Edited Submenu State";

const hostNameArray = ["localhost", "Supply Chain", "HR"];
const serviceNameArray = ["Test", "Check CPU Usage", "Printer Queue"];

export const stateTypeObject = {
  0: "OK",
  1: "Warning",
  2: "Critical",
  3: "Unknown",
};

const createSideNavObject = (
  label: string,
  description: string,
  path: string,
  subMenuItems: SubMenu,
  isReact: boolean
) => ({
  label,
  description,
  path,
  subMenuItems,
  isReact,
});

const homeTooltip = "A brief description of what the home menu provides";

const createHostObject = (hostName: string, currentState: number) => ({
  host_object_id: `${Math.random()}`,
  host_name: hostName,
  host_alias: hostName,
  display_name: hostName,
  address: "127.0.0.1",
  icon_image: "",
  icon_image_alt: "",
  notes: "",
  notes_url: "",
  action_url: "",
  hoststatus_id: "3",
  instance_id: "1",
  status_update_time: "2020-04-06 14:18:15",
  output: "OK - 127.0.0.1: rta 0.011ms, lost 0%",
  long_output: "",
  perfdata:
    "rta=0.011ms;3000.000;5000.000;0; pl=0%;80;100;; rtmax=0.034ms;;;; rtmin=0.005ms;;;;",
  current_state: `${currentState}`,
  has_been_checked: "1",
  should_be_scheduled: "1",
  current_check_attempt: "1",
  max_check_attempts: "10",
  last_check: "2020-04-06 14:18:15",
  next_check: "2020-04-06 14:23:15",
  check_type: "0",
  check_options: "0",
  last_state_change: "2020-02-01 16:10:11",
  last_hard_state_change: "2020-02-01 16:10:11",
  last_hard_state: "0",
  last_time_up: "2020-04-06 14:18:15",
  last_time_down: "1969-12-31 18:00:00",
  last_time_unreachable: "1969-12-31 18:00:00",
  state_type: stateTypeObject[currentState],
  last_notification: "1969-12-31 18:00:00",
  next_notification: "1969-12-31 18:00:00",
  no_more_notifications: "0",
  notifications_enabled: "1",
  problem_has_been_acknowledged: "0",
  acknowledgement_type: "0",
  current_notification_number: "0",
  passive_checks_enabled: "1",
  active_checks_enabled: "1",
  event_handler_enabled: "1",
  flap_detection_enabled: "1",
  is_flapping: "0",
  percent_state_change: "0",
  latency: "0.00153100001625717",
  execution_time: "0.001404",
  scheduled_downtime_depth: "0",
  failure_prediction_enabled: "0",
  process_performance_data: "1",
  obsess_over_host: "1",
  modified_host_attributes: "0",
  event_handler: "",
  check_command: "check-host-alive!!!!!!!!",
  normal_check_interval: "5",
  retry_check_interval: "1",
  check_timeperiod_object_id: "129",
  services: serviceNameArray.map((name) =>
    createServiceObject(hostName, name, getRandomNumberInRange(4))
  ),
});

const createServiceObject = (
  hostName: string,
  serviceName: string,
  currentState: number
) => ({
  host_name: hostName,
  service_description: serviceName,
  display_name: serviceName,
  host_object_id: `${Math.random()}`,
  host_address: "127.0.0.1",
  host_alias: hostName,
  icon_image: "",
  icon_image_alt: "",
  notes: "Testing 123",
  notes_url: "",
  action_url: "",
  servicestatus_id: "21",
  instance_id: "1",
  service_object_id: "157",
  status_update_time: "2020-04-06 14:16:30",
  output: "OK - load average: 0.26, 0.32, 0.30",
  long_output: "",
  perfdata:
    "load1=0.260;5.000;10.000;0; load5=0.320;4.000;6.000;0; load15=0.300;3.000;4.000;0;",
  current_state: `${currentState}`,
  has_been_checked: "1",
  should_be_scheduled: "1",
  current_check_attempt: "1",
  max_check_attempts: "4",
  last_check: "2020-04-06 14:16:30",
  next_check: "2020-04-06 14:21:30",
  check_type: "0",
  check_options: "0",
  last_state_change: "2020-02-01 16:10:38",
  last_hard_state_change: "2020-02-01 16:10:38",
  last_hard_state: "0",
  last_time_ok: "2020-04-06 14:16:30",
  last_time_warning: "1969-12-31 18:00:00",
  last_time_unknown: "1969-12-31 18:00:00",
  last_time_critical: "1969-12-31 18:00:00",
  state_type: stateTypeObject[currentState],
  last_notification: "1969-12-31 18:00:00",
  next_notification: "1969-12-31 18:00:00",
  no_more_notifications: "0",
  notifications_enabled: "1",
  problem_has_been_acknowledged: "0",
  acknowledgement_type: "0",
  current_notification_number: "0",
  passive_checks_enabled: "1",
  active_checks_enabled: "1",
  event_handler_enabled: "1",
  flap_detection_enabled: "1",
  is_flapping: "0",
  percent_state_change: "0",
  latency: "0.00219999998807907",
  execution_time: "0.00254",
  scheduled_downtime_depth: "0",
  failure_prediction_enabled: "0",
  process_performance_data: "1",
  obsess_over_service: "1",
  modified_service_attributes: "0",
  event_handler: "",
  check_command: "check_local_load!5.0,4.0,3.0!10.0,6.0,4.0\\!test!!!!!!",
  normal_check_interval: "5",
  retry_check_interval: "1",
  check_timeperiod_object_id: "129",
});

export const hostArray = hostNameArray.map((name) =>
  createHostObject(name, getRandomNumberInRange(4))
);

export const serviceArray = hostNameArray.map((name, i) =>
  createServiceObject(name, serviceNameArray[i], getRandomNumberInRange(4))
);

export const favoriteList = [
  { favorite_id: "1", url: "/dashboards/1", title: "HR" },
  { favorite_id: "2", url: "/tools/", title: "Tools" },
];

export const wizardsList = [
  { id: "1", label: "Windows Server", fileName: "windowsserver" },
  { id: "2", label: "Website", fileName: "website" },
];
export const mockUser = {
  user: {
    user_id: 2,
    username: "api_admin",
    name: "API Administrator",
    email: "api_admin@nagios.com",
    role: "admin",
  },
  settings: {
    dark_mode: true,
  },
  meta: { userLevel: 255 },
  favorites: favoriteList,
  menu: {
    Home: ["details", "quickView"],
    Dashboards: ["dashboardMain", "dashboardsAdmin", "dashlets"],
    Views: ["myPlaylist", "playlistSettings"],
    Reports: ["reportTypes", "myReports"],
    Tools: ["toolsList"],
    Incidents: ["checkProblems"],
    Configure: [
      "quickConfig",
      "advancedConfig",
      "configWizards",
      "configResults",
    ],
    Visualizations: ["maps"],
    Administration: [
      "frequentTools",
      "userAdmin",
      "systemConfig",
      "systemExtensions",
    ],
  },
};

export const sideNavList = [
  createSideNavObject("Home", homeTooltip, "/home", [], true),
  createSideNavObject("Dashboard", homeTooltip, "/dashboards", [], true),
  createSideNavObject("Views", homeTooltip, "/views", [], true),
  createSideNavObject("Reports", homeTooltip, "/reports", [], true),
  createSideNavObject(
    "Visualizations",
    homeTooltip,
    "/visualizations",
    [],
    true
  ),
  createSideNavObject("Configure", homeTooltip, "/config", [], false),
  createSideNavObject(
    "Administration",
    homeTooltip,
    "/administration",
    [],
    true
  ),
  createSideNavObject("Help", homeTooltip, "/help", [], true),
  createSideNavObject("Tools", homeTooltip, "/tools", [], true),
];

const upHostArray = hostArray.filter(
  ({ current_state }) => current_state === "0"
);

// const warningHostArray = hostArray.filter(
//   ({ current_state }) => current_state === "1"
// );

const downHostArray = hostArray.filter(
  ({ current_state }) => current_state === "2"
);

const statusArray = ["ok", "warning", "danger", "other"];

export const mocks = [
  createGetMock(SIDENAV_URL, 200, {
    sidenav: sideNavList,
  }),

  createGetMock(SERVICE_URL, 200, {
    status: "good",
    ok: { array: [], count: 0 },
    warning: { array: [], count: 0 },
    unreachable: {
      array: [],
      count: 0,
    },
    pending: { array: [], count: 0 },
    all: { array: serviceArray, count: serviceArray.length },
    unhandled: { array: [], count: 0 },
    problems: { array: [], count: 0 },
    last_updated: new Date(),
  }),
  createGetMock(HOST_URL, 200, {
    status: "good",
    up: { array: upHostArray, count: upHostArray.length },
    down: { array: downHostArray, count: downHostArray.length },
    unreachable: {
      array: [],
      count: 0,
    },
    pending: { array: [], count: 0 },
    all: { array: hostArray, count: hostArray.length },
    unhandled: { array: [], count: 0 },
    problems: { array: [], count: 0 },
    last_updated: new Date(),
  }),
  createGetMock(NAGIOS_STATUS_URL, 200, {
    status: statusArray[getRandomNumberInRange(2)],
    active_host_checks: statusArray[getRandomNumberInRange(3)],
    active_service_checks: statusArray[getRandomNumberInRange(3)],
    notifications_enabled: statusArray[getRandomNumberInRange(3)],
    database_backend_status: statusArray[getRandomNumberInRange(3)],
    monitoring_engine_status: statusArray[getRandomNumberInRange(3)],
    performance_grapher_status: statusArray[getRandomNumberInRange(3)],
    last_updated: new Date(),
  }),
  createGetMock(USER_CURRENT_URL, 200, mockUser),
  createDeleteMock(FAVORITES_URL, 200, {
    message: DELETE_FAVORITE_MESSAGE,
  }),
  createPostMock(FAVORITES_URL, 200, { message: CREATED_FAVORITE_MESSAGE }),
  createPostMock(SUBMENU_URL, 200, {
    message: EDIT_SIDENAV_SUBMENU_MESSAGE,
  }),
  createPostMock(FAVORITES_URL, 200, { message: CREATED_FAVORITE_MESSAGE }),
  createGetMock(FAVORITES_URL, 200, { favorites: favoriteList }),
  createGetMock(WIZARDS_URL, 200, { wizards: wizardsList }),

  createGetMock(HOST_OVERVIEW_URL, 200, {
    hosts: [],
  }),
  createGetMock(NAGIOS_INFO_URL, 200, {
    is_installed: getRandomNumberInRange(2) === 1,
  }),
];
