import { Card as AntCard, Col, Row } from "antd";
import { CardTitle } from "../../../Shared";
import "react-resizable/css/styles.css";
import { DashletDropdown } from "../../Molecules";
import { Dashboard, Dashlet } from "../../../DashboardPage/DashboardPageTypes";
import styled from "styled-components";
import useDashletObject from "../../hooks/useDashletObject";
import { useState } from "react";

export type Props = {
  dashlet: Dashlet;
  dashboard: Dashboard;
  height: number;
  width: number;
};
type CardProps = {
  color: string;
  height: number;
  width: number;
  isVisable: boolean;
};

const Card = styled(AntCard)`
  background: ${({ color }: CardProps) => color};
  visibility: ${({ isVisable }: CardProps) =>
    isVisable ? "visible" : "hidden"};
  height: inherit;
  width: inherit;
`;

export default function DynamicDashlet({
  dashlet,
  height,
  width,
  dashboard,
}: Props) {
  const dashletTypeObject = useDashletObject(false, dashlet);
  const [isHovered, setIsHovered] = useState(false);

  console.log(dashlet.type !== "placeholder");
  return (
    <Card
      isVisable={dashlet.type !== "placeholder"}
      color={dashlet.color}
      height={height}
      width={width}
      onDragStart={() => setIsHovered(true)}
      onDragEnd={() => setIsHovered(false)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={
        isHovered
          ? {
              border: "2px solid #6DBBFF",
              borderRadius: "7px",
            }
          : {}
      }
    >
      {dashlet.type !== "placeholder" ? (
        <>
          <Row>
            <Col span={23}>
              <CardTitle>{dashlet.name}</CardTitle>
            </Col>
            <Col span={1}>
              <Row>
                <Col span={11}>
                  <DashletDropdown dashlet={dashlet} dashboard={dashboard} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            style={{
              height: `${height}px`,
              width: "inherit",
            }}
          >
            {dashletTypeObject[dashlet.type]}
          </Row>
        </>
      ) : null}
    </Card>
  );
}
