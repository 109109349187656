import { useQuery } from "react-query";
import { SERVICE_URL } from "../../../api.config";
import { fetchData } from "../../../utils/axiosHelpers";

export const KEY = "Fetch Services";

const fetchServices = async () => fetchData(SERVICE_URL);

export const preFetchServices = (queryClient) =>
  queryClient.prefetchQuery(KEY, fetchServices);

export default function useServices() {
  return useQuery(KEY, fetchServices);
}
