import { Card, Input } from "antd";

const { Search } = Input;

export type SearchProps = {
  searchSupport: (event: any) => void;
};

const SearchSupport = (props: SearchProps) => {
  return (
    <Card>
      <h4 style={{ fontSize: "16px" }}>Search Support Documentation</h4>
      <Search
        placeholder="Search by Topic or Keyword"
        onSearch={props.searchSupport}
      />
    </Card>
  );
};

export default SearchSupport;
