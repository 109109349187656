import { useMutation, useQueryClient } from "react-query";
import { DUPLICATE_DASHBOARD_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { postData } from "../../../utils/axiosHelpers";
import { Dashboard } from "../DashboardPageTypes";
import { KEY, FETCH_DASHBOARD_KEY } from "./useDashboards";

export default function useDuplicateDashboard() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      postData(`${DUPLICATE_DASHBOARD_URL}/${values.dashboard_id}`, values),
    {
      onMutate: async (dashboardToDuplicate: any) => {
        const CALL_REASON = `Duplicate Dashboard ${dashboardToDuplicate.name}`;
        await queryClient.cancelQueries(KEY);

        const previousDashboards: any = queryClient.getQueryData(KEY);

        const newKey = Math.random().toString();
        const duplicateDashboard = Object.assign({}, dashboardToDuplicate, {
          dashboard_id: newKey,
          name: `${dashboardToDuplicate.name}_copy`,
        });

        const newDashboardList = [
          ...previousDashboards?.dashboards,
          duplicateDashboard,
        ];

        queryClient.setQueryData(KEY, {
          dashboards: newDashboardList,
        });

        queryClient.setQueryData([KEY, FETCH_DASHBOARD_KEY], {
          dashboards: duplicateDashboard,
        });

        return { previousDashboards, newDashboardList, CALL_REASON };
      },
      onError: (data, _, context) => {
        onErrorNotification(data, context?.CALL_REASON);
        if (context?.previousDashboards) {
          queryClient.setQueryData<Dashboard[]>(
            KEY,
            context.previousDashboards
          );
        }
      },
      onSuccess: (data, _, context) => {
        onSuccessNotification(data, context?.CALL_REASON);
      },
      onSettled: () => {
        queryClient.invalidateQueries(KEY);
      },
    }
  );
}
