import { createContext, useContext } from "react";
import { useToggle } from "../hooks";

export const GlobalModalContext = createContext<any>({
  createDashboardModalOpen: false,
  toggleCreateDashboardModalOpen: Function,
  editDashboardModalOpen: false,
  toggleEditDashboardModalOpen: Function,
  massDeployDashboardModalOpen: false,
  toggleMassDashboardModalOpen: Function,
  editDashletModalIsOpen: false,
  toggleEditDashletModalIsOpen: Function,
  createDashletModalIsOpen: false,
  toggleCreateDashletModalIsOpen: Function,
  importDashboardModalIsOpen: false,
  toggleImportDashboardModalIsOpen: Function,
  addAToolModalOpen: false,
  toggleAddAToolModalOpen: Function,
  playPlaylistModalOpen: false,
  togglePlaylistModalOpen: Function,
  addAScreenModalOpen: false,
  toggleAddAScreenModalOpen: Function,
  addAPlaylistModalOpen: false,
  toggleAddAPlaylistModalOpen: Function,
  createAReportModalOpen: false,
  toggleCreateAReportModalOpen: Function,
  scheduleReportModal: false,
  toggleScheduleReportModalOpen: Function,
});

export function GlobalModalProvider({ children }) {
  const {
    open: createDashboardModalOpen,
    toggleOpen: toggleCreateDashboardModalOpen,
  } = useToggle();

  const {
    open: editDashboardModalOpen,
    toggleOpen: toggleEditDashboardModalOpen,
  } = useToggle();

  const {
    open: massDeployDashboardModalOpen,
    toggleOpen: toggleMassDashboardModalOpen,
  } = useToggle();

  const {
    open: editDashletModalIsOpen,
    toggleOpen: toggleEditDashletModalIsOpen,
  } = useToggle();
  const {
    open: importDashboardModalIsOpen,
    toggleOpen: toggleImportDashboardModalIsOpen,
  } = useToggle();

  const {
    open: addAToolModalOpen,
    toggleOpen: toggleAddAToolModalOpen,
  } = useToggle();

  const {
    open: playPlaylistModalOpen,
    toggleOpen: togglePlaylistModalOpen,
  } = useToggle();

  const {
    open: addAScreenModalOpen,
    toggleOpen: toggleAddAScreenModalOpen,
  } = useToggle();

  const {
    open: addAPlaylistModalOpen,
    toggleOpen: toggleAddAPlaylistModalOpen,
  } = useToggle();

  const {
    open: createAReportModalOpen,
    toggleOpen: toggleCreateAReportModalOpen,
  } = useToggle();

  const {
    open: scheduleReportModal,
    toggleOpen: toggleScheduleReportModalOpen,
  } = useToggle();

  const {
    open: createDashletModalIsOpen,
    toggleOpen: toggleCreateDashletModalIsOpen,
  } = useToggle();

  return (
    <GlobalModalContext.Provider
      value={{
        createDashboardModalOpen,
        toggleCreateDashboardModalOpen,
        editDashboardModalOpen,
        toggleEditDashboardModalOpen,
        toggleEditDashletModalIsOpen,
        massDeployDashboardModalOpen,
        importDashboardModalIsOpen,
        toggleImportDashboardModalIsOpen,
        toggleMassDashboardModalOpen,
        createDashletModalIsOpen,
        editDashletModalIsOpen,
        toggleCreateDashletModalIsOpen,
        addAToolModalOpen,
        toggleAddAToolModalOpen,
        playPlaylistModalOpen,
        togglePlaylistModalOpen,
        addAScreenModalOpen,
        toggleAddAScreenModalOpen,
        addAPlaylistModalOpen,
        toggleAddAPlaylistModalOpen,
        createAReportModalOpen,
        toggleCreateAReportModalOpen,
        scheduleReportModal,
        toggleScheduleReportModalOpen,
      }}
    >
      {children}
    </GlobalModalContext.Provider>
  );
}

export function useGlobalModal() {
  return useContext(GlobalModalContext);
}

export function GlobalModalProviderTest({ children, modalToMock }) {
  return (
    <GlobalModalContext.Provider
      value={{
        [modalToMock]: true,
      }}
    >
      {children}
    </GlobalModalContext.Provider>
  );
}
