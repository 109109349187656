import { useQuery } from "react-query";
import { REPORT_TYPES_URL } from "../../../api.config";
import { fetchData } from "../../../utils/axiosHelpers";

export const KEY = "Fetch ReportTypess";
export const FETCH_TOOL_KEY = "Fetch ReportTypes";

const fetchReportTypes = async () => fetchData(REPORT_TYPES_URL);

export const preFetchReportTypes = (queryClient) =>
  queryClient.prefetchQuery(KEY, fetchReportTypes);

export default function useReportTypes(options?: any) {
  return useQuery(KEY, fetchReportTypes, {
    select: (data) => data.reports,
    ...options,
    initalData: true,
    placeholderData: [],
    staleTime: Infinity,
  });
}
