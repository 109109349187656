import styled from "styled-components";
import { Select } from "antd";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";
import useViewPageEvents from "../../hooks/useViewsPageEvents";

const { Option } = Select;

const StyledDiv = styled.div`
  line-height: 1.2;
  margin-bottom: 20px;
  & > h4 {
    font-size: 14px;
    margin-bottom: 4px;
  }
  & > p {
    color: #666;
    color: ${themeVariables.colorGrayLight2};
    margin-bottom: 8px;
  }
`;

export type GlobalProps = {
  record: any;
};

const GlobalDuration = (props: GlobalProps) => {
  const min: number = 5;
  const max: number = 30;
  let duration: number[] = [];

  for (let i = min; i <= max; i++) {
    duration.push(i);
  }

  const { onEditDurationToGlobal } = useViewPageEvents();

  return (
    <StyledDiv>
      <Select
        style={{ width: "140px" }}
        placeholder="Set Duration"
        onChange={(value) => onEditDurationToGlobal(props.record, value)}
        defaultValue={props.record.play_duration}
      >
        {duration.map((el, i) => (
          <Option value={el} key={i}>
            {el} seconds
          </Option>
        ))}
      </Select>
    </StyledDiv>
  );
};

export default GlobalDuration;
