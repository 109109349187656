import { useQuery } from "react-query";
import { MOST_VIEWED_URL } from "../../../api.config";
import { fetchData } from "../../../utils/axiosHelpers";

export const KEY = "Fetch Most Viewed ";

const fetchMostViewed = async () => fetchData(MOST_VIEWED_URL);

export const preFetchMostViewed = (queryClient) =>
  queryClient.prefetchQuery(KEY, fetchMostViewed);

export default function useMostViewed(options?: any) {
  return useQuery(KEY, fetchMostViewed, {
    select: (data) => data.most_viewed,
    ...options,
    initalData: true,
    placeholderData: [],
  });
}
