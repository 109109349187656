import React from "react";

export default function NotificationSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 55 55"
    >
      <g>
        <g>
          <path
            fill="#e5f7ff"
            d="M0 18C0 8.059 8.059 0 18 0h19c9.941 0 18 8.059 18 18v19c0 9.941-8.059 18-18 18H18C8.059 55 0 46.941 0 37z"
          />
        </g>
        <g>
          <g>
            <g>
              <path d="M15.13 37.945a16.17 16.17 0 0 0 2.44-3 14.24 14.24 0 0 0 1.65-5.85v-4.94a8.74 8.74 0 1 1 17.47 0v4.94a14.24 14.24 0 0 0 1.65 5.85 16.17 16.17 0 0 0 2.44 3zM40 33.913a12.637 12.637 0 0 1-1.35-4.812V24.16a10.812 10.812 0 0 0-9.44-10.754v-1.29a1.335 1.335 0 1 0-2.67 0v1.31a10.813 10.813 0 0 0-9.33 10.734V29.1a12.637 12.637 0 0 1-1.35 4.812 14.404 14.404 0 0 1-2.47 2.93 1 1 0 0 0-.34.751v1.36a1 1 0 0 0 1 1.001h27.8a1 1 0 0 0 1-1v-1.36a1 1 0 0 0-.34-.751A14.404 14.404 0 0 1 40 33.913z" />
            </g>
            <g>
              <path d="M28 43.28A2.67 2.67 0 0 0 30.58 41h-5.26A2.67 2.67 0 0 0 28 43.28z" />
            </g>
          </g>
          <g />
        </g>
      </g>
    </svg>
  );
}
