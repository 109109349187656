import { Button, Col, Row, Space } from "antd";
import { createContext } from "react";
import styled from "styled-components";
import { AllDashboardsCard } from ".";
import { useGlobalModal } from "../../context/GlobalModalContext";
import { useTranslator } from "../../hooks";
import useCreateFavorite from "../Frame/hooks/useCreateFavorite";
import PageHeader from "../Shared/Organisms/PageHeader/PageHeader";
import CurrentDashboardProvider from "./context/CurrentDashboardContext";
import { Dashboard } from "./DashboardPageTypes";
import useDashboards from "./hooks/useDashboards";

type SelectedDashboardObject = {
  selectedDashboard: Dashboard | null;
  updateSelectedDashboard: Function;
};

export const SelectedDashboardContext = createContext<SelectedDashboardObject>({
  selectedDashboard: null,
  updateSelectedDashboard: Function,
});

const StyledButton = styled(Button)`
  height: 40px;
`;

const StyledDiv = styled.div`
  padding: 20px;
`;

export default function DashboardsPage() {
  const { TITLE, DESCRIPTION } = useTranslator("DashboardsPage");
  const { mutate: createFavorite } = useCreateFavorite();
  const { toggleCreateDashboardModalOpen } = useGlobalModal();
  const { data: dashboards } = useDashboards({
    name: "",
    params: "",
  });

  const dashboardsToExport = [...(dashboards ? dashboards : [])].map(
    ({ dashboard_id, ...rest }: Dashboard) => ({
      ...rest,
    })
  );

  return (
    <StyledDiv>
      <PageHeader
        title={TITLE}
        sub={DESCRIPTION}
        favoriteClicked={() =>
          createFavorite({ title: "Dashboard", url: "/dashboards/" })
        }
        buttons={
          <>
            <Space style={{ marginRight: "10px" }}>
              <StyledButton
                data-testid="AddADashboard"
                onClick={() => toggleCreateDashboardModalOpen()}
              >
                Add a Dashboard
              </StyledButton>
            </Space>
            <Space style={{ marginRight: "10px" }}>
              <StyledButton
                href={`data:text/json;charset=utf-8,${encodeURIComponent(
                  JSON.stringify(dashboardsToExport, null, 2)
                )}`}
                download={`Nagios_Dashboards_Export.json`}
              >
                Export All Dashboards
              </StyledButton>
            </Space>
          </>
        }
      />

      <Row>
        <Col span={24}>
          <CurrentDashboardProvider>
            <AllDashboardsCard />
          </CurrentDashboardProvider>
        </Col>
      </Row>
    </StyledDiv>
  );
}
