import { Avatar, Button } from "antd";
import {
  StyledButton,
  StyledP,
  StyledP2,
  StyledHR,
} from "../../Atoms/HeaderPopup/HeaderPopup";
import styled from "styled-components";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { User } from "../../../Frame/FrameTypes";
import useLogout from "../../../LoginPage/hooks/useLogout";
//import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
//import LanguageDropdown from "../../Atoms/LanguageDropdown";
import useSideNav from "../../../Frame/hooks/useSideNav";

type UserProps = {
  data: User | undefined;
  onLinkClick?: any;
};

const ProfileMenu = (props: UserProps) => {
  const { mutate: logout } = useLogout();
  const { sideNav } = useSideNav();
  const { Profile: profileMenu } = sideNav;
  const {
    submenus: [profileSettings, myNotification, help, customerSupport],
  } = profileMenu;

  /*
  <LanguageDropdown />
  <ThemeSwitcher />
  <StyledHR />
  */

  const StyledH2 = styled.h2`
    font-size: 1.15em;
    font-weight: 600;
  `;

  return (
    <Fragment>
      {props.data && (
        <div style={{ display: "flex", marginBottom: "20px" }}>
          <Avatar
            size={42}
            style={{ backgroundColor: "#492fd3", color: "#fff" }}
          >
            {props.data.name &&
              props.data.name
                .split(" ")
                .map((item) => item[0])
                .join("")
                .toUpperCase()}
          </Avatar>
          <div style={{ marginLeft: "20px" }}>
            <p style={{ margin: "0" }}>
              <b>You are logged in as</b>
              <br />
              {props.data.name}
            </p>
          </div>
        </div>
      )}
      <Button type="primary" block onClick={() => logout()}>
        Logout
      </Button>
      <StyledHR />
      <Button
        block
        onClick={() => props.onLinkClick()}
      >
        <Link to={profileSettings.menuItems[0].link}>
          Profile Settings
        </Link>
      </Button>
      <StyledP>
        Change your password, your email, API key and data formats
      </StyledP>
      <Button
        block
        onClick={() => props.onLinkClick()}
      >
        <Link to={profileSettings.menuItems[1].link}>
          My Sessions
        </Link>
      </Button>
      <StyledP>View your session history</StyledP>
      <StyledHR />
      <StyledH2>My Notifications</StyledH2>
      {myNotification.menuItems.map(({ name, link }) => (
        <StyledButton type="text" block onClick={() => props.onLinkClick()}>
          <Link to={link}>
            {name}
          </Link>
        </StyledButton>
      ))}
      <StyledHR />
      <StyledH2>Help</StyledH2>
      {help.menuItems.map(({ name, link }) => (
        <StyledButton type="text" block onClick={() => props.onLinkClick()}>
          <Link to={link}>
            {name}
          </Link>
        </StyledButton>
      ))}
      <StyledHR />
      <StyledH2>Contact us</StyledH2>
      <StyledP2>
        <span>Customer Phone Support</span>
      </StyledP2>
      <StyledP2>
        <h4 style={{ marginBottom: 0 }}>+1 651-204-9102</h4>
      </StyledP2>
      <Button
        block
        onClick={() => props.onLinkClick()}
      >
        <Link to={customerSupport.menuItems[0].link}>
          Customer Ticket Support Center
        </Link>
      </Button>
    </Fragment>
  );
};

export default ProfileMenu;
