import { find } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { DASHBOARD_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { putData } from "../../../utils/axiosHelpers";
import { Dashboard, Dashlet } from "../../DashboardPage/DashboardPageTypes";
import {
  FETCH_DASHBOARD_KEY,
  KEY,
} from "../../DashboardPage/hooks/useDashboards";

export default function usePinDashlet() {
  const { onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation((values) => putData(DASHBOARD_URL, values), {
    onMutate: async ({ dashletToPin, dashboardId }: any) => {
      const queryKey = [FETCH_DASHBOARD_KEY, dashboardId];
      const CALL_REASON = `${
        dashletToPin.properties.pinned ? "Unpinned" : "Pinned"
      } Dashlet ${dashletToPin.name}`;

      await queryClient.cancelQueries(queryKey);

      const previousDashboards: any = queryClient.getQueryData(KEY);

      const previousDashboard = find(previousDashboards.dashboards, {
        dashboard_id: dashboardId,
      });

      const newDashletList = previousDashboard.dashlets.map(
        (dashlet: Dashlet) => {
          if (dashlet.dashlet_id === dashletToPin?.dashlet_id) {
            const { properties, ...rest } = dashlet;
            const { pinned, ...otherProperties } = properties;

            return {
              ...rest,
              properties: {
                ...otherProperties,
                pinned: pinned ? false : true,
              },
            };
          }
          return dashlet;
        }
      );

      const { dashlets, ...rest } = previousDashboard;

      const newDashboardObject = {
        ...rest,
        dashlets: newDashletList,
      };

      const newDashboardList = previousDashboards.dashboards.map(
        (dashboard: Dashboard) =>
          dashboard.dashboard_id === dashboardId
            ? newDashboardObject
            : dashboard
      );

      queryClient.setQueryData(queryKey, {
        dashboards: newDashboardObject,
      });

      queryClient.setQueryData(KEY, {
        dashboards: newDashboardList,
      });

      return {
        previousDashboard,
        newDashboardObject,
        CALL_REASON,
        queryKey,
      };
    },
    onError: (data, _, context) => {
      onErrorNotification(data, context?.CALL_REASON);
      if (context?.previousDashboard) {
        queryClient.setQueryData<Dashboard[]>(
          context?.queryKey,
          context.previousDashboard
        );
      }
    },

    onSettled: () => {
      queryClient.invalidateQueries(KEY);
    },
  });
}
