import { find } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { VIEW_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { deleteData } from "../../../utils/axiosHelpers";
import { Screen, Screens, Playlist } from "../ViewsPageTypes";
import { KEY } from "./usePlaylists";

export type Props = {
  viewToDelete: Screen;
  playlistId: number;
};

export default function useDeleteView() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      deleteData(
        VIEW_URL(values.playlistId, values.viewToDelete.screen_id),
        values
      ),
    {
      onMutate: async ({ viewToDelete, playlistId }: Props) => {
        await queryClient.cancelQueries(KEY);

        const previousPlaylists: any = queryClient.getQueryData(KEY);

        const oldPlayList: any = find(previousPlaylists.playlists, {
          playlist_id: playlistId,
        });

        console.log(oldPlayList, previousPlaylists, playlistId);
        const CALL_REASON = `Delete View ${viewToDelete.name} from ${oldPlayList.name}`;

        const newPlaylists = previousPlaylists.playlists.map(
          (playlist: Playlist) =>
            playlist.playlist_id === playlistId
              ? {
                  playlist_id: playlist.playlist_id,
                  name: playlist.name,
                  screens: Object.assign(
                    {},
                    ...Object.values(playlist.screens)
                      .filter(
                        (view: Screen) =>
                          view.screen_id !== viewToDelete.screen_id
                      )
                      .map((view: Screen, i) => {
                        const { play_order, ...viewRest } = view;
                        return {
                          [`${view.play_order - 1}`]: {
                            ...viewRest,
                            play_order: play_order - 1,
                          },
                        };
                      })
                  ),
                }
              : playlist
        );

        queryClient.setQueryData(KEY, {
          playlists: newPlaylists,
        });

        return {
          previousPlaylists,
          newPlaylists,
          CALL_REASON,
        };
      },
      onSuccess: (data, _, context) => {
        onSuccessNotification(data, context?.CALL_REASON);
      },
      onError: (data, _, context) => {
        onErrorNotification(data, context?.CALL_REASON);
        if (context?.previousPlaylists) {
          queryClient.setQueryData<Screens>([KEY], context.previousPlaylists);
        }
      },

      onSettled: () => {
        queryClient.invalidateQueries(KEY);
      },
    }
  );
}
