import React from "react";

export default function NetworkSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 55 55"
    >
      <g>
        <g>
          <path
            fill="#e8903e"
            d="M0 18C0 8.059 8.059 0 18 0h19c9.941 0 18 8.059 18 18v19c0 9.941-8.059 18-18 18H18C8.059 55 0 46.941 0 37z"
          />
        </g>
        <g>
          <g>
            <g>
              <path d="M32.43 26.83A1.004 1.004 0 1 0 31 28.24L34.72 32H16.83a1 1 0 1 0 0 2h17.89L31 37.7a1 1 0 1 0 1.42 1.41L38.53 33z" />
            </g>
            <g>
              <path d="M22.24 28.446a1 1 0 0 0 .71-1.71l-3.71-3.74h17.88a1 1 0 0 0 0-2H19.24l3.71-3.73a1 1 0 0 0-1.42-1.41l-6.11 6.14 6.11 6.14a1 1 0 0 0 .71.31z" />
            </g>
          </g>
          <g />
        </g>
      </g>
    </svg>
  );
}
