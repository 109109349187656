import styled from "styled-components";
import { Space } from "antd";
import GlobalDuration from "../../Molecules/GlobalDuration/GlobalDuration";

import TransitionStyle from "../../Molecules/TransitionStyle/TransitionStyle";
const FlexBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export type SortProps = {
  record: any;
  addScreen: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  setDuration: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const SortPlaylist = (props: SortProps) => {
  return (
    <FlexBox>
      <h4>Sort Playlist</h4>
      <Space align="baseline">
        <h4>Play Settings</h4>
        <GlobalDuration record={props.record} />
        <TransitionStyle record={props.record} />
      </Space>
    </FlexBox>
  );
};

export default SortPlaylist;
