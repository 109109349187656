import { Form, Select, Row, Col, Input } from "antd";
import React from "react";
import { AdvForm, StyledCheckbox } from "../../../Shared/Atoms/Form/Form";
import { useEditReportContext } from "../../context/EditReportContent";

const { TextArea } = Input;

export type formType = {
  form: any;
};

const ScheduleReportForm = ({ form }: formType) => {
  const children = [];
  const { reportToEdit } = useEditReportContext();

  return (
    <AdvForm
      layout="vertical"
      name="createReportRunTest"
      initialValues={{
        scheduleReport: reportToEdit ? { ...reportToEdit.schedule } : {},
      }}
    >
      <Form.Item
        name={["scheduleReport", "frequency"]}
        label="Schedule Frequency"
      >
        <Select>
          {["Daily", "Weekly", "Monthly", "Quarterly", "Yearly"].map((data) => (
            <Select.Option value={data.toLowerCase()} key={data}>
              {data}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={["scheduleReport", "timeToRun"]}
        label="Time"
        className="short float"
      >
        <Select>
          {["Morning: 6 AM", "Afternoon: 12 PM", "Night: 6 PM>"].map((data) => (
            <Select.Option value={data.toLowerCase()} key={data}>
              {data}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={["scheduleReport", "attachments"]}
        label="Report Attachments (Choose as many as apply)"
      >
        <Row>
          <Col span={8}>
            <StyledCheckbox value="pdf">PDF</StyledCheckbox>
          </Col>
          <Col span={16}>
            <StyledCheckbox value="csv1">
              CSV (Hosts and Services)
            </StyledCheckbox>
          </Col>
          <Col span={8}>
            <StyledCheckbox value="jpg">JPG</StyledCheckbox>
          </Col>
          <Col span={16}>
            <StyledCheckbox value="csv2">CSV (Hosts Data Only)</StyledCheckbox>
          </Col>
          <Col span={8}>
            <StyledCheckbox value="csv">CSV</StyledCheckbox>
          </Col>
          <Col span={16}>
            <StyledCheckbox value="csv3">
              CSV (Services Data Only)
            </StyledCheckbox>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item
        name={["scheduleReport", "recipients"]}
        label="Recipients (Comma-separated list of email addresses)"
      >
        <Select mode="tags" tokenSeparators={[", "]}>
          {children}
        </Select>
      </Form.Item>
      <Form.Item name={["scheduleReport", "subject"]} label="Subject">
        <Input />
      </Form.Item>
      <Form.Item
        name={["scheduleReport", "emailMessage"]}
        label="Email Message"
      >
        <TextArea rows={4} />
      </Form.Item>
    </AdvForm>
  );
};

export default ScheduleReportForm;
