import { Tag } from "antd";
import React from "react";

export type Props = {
  name: string;
};

export default function DashboardViewRowTag({ name }: Props) {
  const colorObject = {
    public: "#492FD3",
    "read_only": "#666666",
    editable: "#16C79B",
  };
  return <Tag color={colorObject[name]}>{name}</Tag>;
}
