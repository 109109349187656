import { Modal, Button } from "antd";
import { EditDashboardForm } from "..";
import useDashboardPageEvents from "../../hooks/useDashboardPageEvents";

export default function EditDashboardModal({ open, toggleOpen, dashboard }) {
  const { editDashboardForm } = useDashboardPageEvents();

  return (
    <Modal
      visible={open}
      footer={[
        <Button key="back" onClick={() => toggleOpen()}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={() => editDashboardForm.submit()}
        >
          Edit Dashboard {dashboard?.name}
        </Button>,
      ]}
    >
      <EditDashboardForm
        form={editDashboardForm}
        dashboard={dashboard}
        toggleClose={toggleOpen}
      />
    </Modal>
  );
}
