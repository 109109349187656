import { useForm } from "antd/lib/form/Form";
import { useHistory } from "react-router-dom";
import { Report } from "../ReportsPageTypes";
import useCreateReport from "./useCreateReport";
import useDeleteReport from "./useDeleteReport";
import useEditReport from "./useEditReport";
import useRunReport from "./useRunReport";
import useScheduledReport from "./useScheduleReport";
import useShareReport from "./useShareReport";

export default function useReportPageEvents() {
  const [createReportForm] = useForm();
  const [scheduleReportForm] = useForm();
  const history = useHistory();

  const {
    mutate: createReport,
    isLoading: createReportIsLoading,
  } = useCreateReport();

  const {
    mutate: deleteReport,
    isLoading: deleteReportIsLoading,
  } = useDeleteReport();

  const {
    mutate: editReport,
    isLoading: editReportIsLoading,
  } = useEditReport();

  const {
    mutate: scheduleReport,
    isLoading: scheduleReportIsLoading,
  } = useScheduledReport();

  const { mutate: runReport, isLoading: runReportIsLoading } = useRunReport();

  const { downloadAsPDF, emailPDF, shareToTeamsMyReports } = useShareReport();

  const onCreateReportButtonClick = (values) => {
    console.log(values);
    const reportBasic = values.reportBasic;

    const reportObject = {
      name: reportBasic?.name,
      type: reportBasic?.type,
    };

    createReport(reportObject);
  };

  const onToggleScheduledClick = (reportToRun: Report, action: string) => {
    const { schedulable, ...reportToRunRest } = reportToRun;

    const actionObject = {
      schedule: () =>
        editReport({ ...reportToRunRest, schedulable: 1, is_paused: 0 }),
      scheduled: () =>
        editReport({ ...reportToRunRest, schedulable: 1, is_paused: 0 }),
      pause: () =>
        editReport({ ...reportToRunRest, schedulable: 1, is_paused: 1 }),
      unschedule: () => editReport({ ...reportToRunRest, schedulable: 0 }),
    };

    actionObject[action]();
  };
  const onTestRunReportButtonClick = (values) => {
    runReport({ ...values, run_test: true });
  };
  const onRunReportButtonClick = (values) => {
    runReport({ ...values, run_test: false });
  };
  const onScheduledButtonClick = () => {
    const values = scheduleReportForm.getFieldsValue();
    scheduleReport(values);
  };
  const onEditButtonClick = (reportToEdit) => {
    editReport(reportToEdit);
  };

  const onDeleteButtonClick = (reportToDelete: Report) =>
    deleteReport(reportToDelete);

  const onDownloadAsPDF = (report: Report) => {
    downloadAsPDF(report);
  };
  const onEmailPdf = (report: Report) => {
    emailPDF(report);
  };
  const onShareToTeamsMyReports = (report: Report) => {
    shareToTeamsMyReports(report);
  };

  const onViewButtonClick = (report: Report) => {
    history.push(`/reports/view/${report.id}`);
  };

  return {
    createReportForm,
    scheduleReportForm,
    onTestRunReportButtonClick,
    onRunReportButtonClick,
    onCreateReportButtonClick,
    onEditButtonClick,
    onScheduledButtonClick,
    onDeleteButtonClick,
    onViewButtonClick,
    onToggleScheduledClick,
    onDownloadAsPDF,
    onEmailPdf,
    onShareToTeamsMyReports,
    loading:
      createReportIsLoading ||
      deleteReportIsLoading ||
      editReportIsLoading ||
      scheduleReportIsLoading ||
      runReportIsLoading,
  };
}
