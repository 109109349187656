import { useQuery } from "react-query";
import { USER_URL } from "../../../api.config";
import { fetchData } from "../../../utils/axiosHelpers";

export const KEY = "Fetch Users";
export const FETCH_USER_KEY = "Fetch User";

const fetchUsers = async () => fetchData(USER_URL);

export const preFetchUsers = (queryClient) =>
  queryClient.prefetchQuery(KEY, fetchUsers);

type FilterProps = {
  name: string;
  params: any;
};

export default function useUsers(filters: FilterProps, options?: any) {
  return useQuery(KEY, fetchUsers, {
    select: (data) => {
      if (filters.name === "") {
        return data.users;
      }

      if (filters.name === "Search") {
        return data.users.filter(({ name }) => name.includes(filters.params));
      }
    },

    ...options,
    initalData: true,
    placeholderData: [],
  });
}

export function useUser(userId?: any, options?: any) {
  return useQuery(
    [FETCH_USER_KEY, userId],
    () => fetchData(`${USER_URL}/${userId}`),
    {
      select: (data) => data.reports,
      ...options,
    }
  );
}
