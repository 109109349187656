import { Form, Input, Select, Popover, DatePicker, Checkbox } from "antd";
import React, { useState } from "react";
import { HostGroup, ServiceGroup } from "./BasicData";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";
import useHosts from "../../../Frame/hooks/useHosts";
import useServices from "../../../Frame/hooks/useServices";
import { useEditReportContext } from "../../context/EditReportContent";
import useReportTypes from "../../hooks/useReportTypes";
import { AdvForm } from "../../../Shared/Atoms/Form/Form";
import { IconInfo } from "../../../Shared/Atoms/FontIcons/FontIcons";
import CreateReportAdvancedForm from "../CreateReportAdvancedForm/CreateReportAdvancedForm";

const { RangePicker } = DatePicker;

export type formType = {
  form: any;
};

const CreateReportModalBasic = (props: formType): JSX.Element => {
  const [timePeriod, setTimePeriod] = useState<number | string>("");
  const checkTimePeriod = (option) => setTimePeriod(option);
  const { data: reportTypes } = useReportTypes();
  const { data: hosts } = useHosts();
  const { data: services } = useServices();
  const { reportToEdit } = useEditReportContext();
  const [advancedOptions, setAdvancedOptions] = useState(false);

  const tooltipText = "Info text goes here.";

  const initialFormValues = {
    type: "Availability Summary",
    timePeriod: "Last 12 hours",
    host: "All",
    service: "All",
  };

  const values = props.form.getFieldsValue();

  return (
    <>
      <AdvForm
        layout="vertical"
        name="createReportBasic"
        form={props.form}
        initialValues={{
          reportBasic: reportToEdit
            ? { ...reportToEdit }
            : { ...initialFormValues },
        }}
      >
        <Form.Item
          name={["reportBasic", "type"]}
          label="Select Report Type"
          className="short float"
        >
          <Select>
            {[reportTypes ? reportTypes : []].map((el) => (
              <Select.Option value={el.title} key={el.key}>
                {el.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={["reportBasic", "timePeriod"]}
          label="Time Period"
          className="short"
        >
          <Select onChange={(OptionData) => checkTimePeriod(OptionData)}>
            {[
              "Today",
              "Last 24 hours",
              "Yesterday",
              "This Week",
              "This Month",
              "This Quarter",
              "This Year",
              "Last Week",
              "Last Month",
              "Last Quarter",
              "Last Year",
              "Custom",
            ].map((data) => (
              <Select.Option value={data} key={data}>
                {data}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {timePeriod === "Custom" && (
          <Form.Item
            name={["reportBasic", "customStart"]}
            label="Select Date Range"
          >
            <RangePicker format="YYYY-MM-DD h:mm a" showTime />
          </Form.Item>
        )}
        <Form.Item name={["reportBasic", "name"]} label="Custom Title">
          <Input />
        </Form.Item>
        <h4>
          Select [ ] to be included
          <Popover content={tooltipText}>
            <IconInfo color={themeVariables.colorGrayLight2} />{" "}
          </Popover>
        </h4>
        <Form.Item
          name={["reportBasic", "host"]}
          label="Host"
          className="short float"
        >
          <Select>
            <Select.Option value="All">All</Select.Option>
            {hosts?.all?.array.map((data) => (
              <Select.Option value={data.host_name} key={data.host_object_id}>
                {data.host_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={["reportBasic", "service"]}
          label="Service"
          className="short"
        >
          <Select>
            <Select.Option value="All">All</Select.Option>
            {services?.all?.array.map((data) => (
              <Select.Option value={data.display_name} key={data.id}>
                {data.display_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={["reportBasic", "hostGroup"]}
          label="Hostgroup"
          className="short float"
        >
          <Select>
            {HostGroup.map((data) => (
              <Select.Option value={data.value} key={data.id}>
                {data.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={["reportBasic", "serviceGroup"]}
          label="Servicegroup"
          className="short"
        >
          <Select>
            {ServiceGroup.map((data) => (
              <Select.Option value={data.value} key={data.id}>
                {data.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </AdvForm>
      {["Availability Summary", "Executive Summary", "SLA Report"].includes(
        values?.reportBasic?.type
      ) && (
        <Checkbox onChange={() => setAdvancedOptions((prev) => !prev)}>
          Toggle Advanced Opitions
        </Checkbox>
      )}
      {advancedOptions && <CreateReportAdvancedForm form={props.form} />}
    </>
  );
};

export default CreateReportModalBasic;
