import FAQModal from "../FAQModal/FAQModal";
import styled from "styled-components";
import usingInPageHelp from "../../../Shared/Atoms/Images/usingInPageHelp.png";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";

const FlexBox = styled.div`
  display: flex;
  > div:first-child {
    flex: 2;
    padding: 0 10px;
    > img {
      width: 100%;
    }
  }
  >div: last-child {
    flex: 3;
    padding: 0 10px;
    > h4 {
      font-size: 16px;
    }
    > ol {
      padding-left: 14px;
      > li {
        padding-left: 10px;
        color: ${themeVariables.colorGrayLight2};
        &::marker {
          color: ${themeVariables.colorGrayLight1};
        }
        > ul {
          padding-left: 16px;
          list-style: disc;
        }
      }
    }
  }
`;

const InPageHelpModal = (props) => {
  return (
    <FAQModal
      title="Using In-Page Help"
      visible={props.visibleIPH}
      close={props.closeIPH}
      modalBody={
        <FlexBox>
          <div>
            <img src={usingInPageHelp} alt={"diagram for using in page help"} />
          </div>
          <div>
            <h4>Using In-Page Help</h4>
            <ol>
              <li>
                In the upper right corner of each page, select the Question Mark
                icon
              </li>
              <li>
                The help popup will appear in the lower right corner of the page
                and will include In-Page assistance like:
                <ul>
                  <li>Contextual videos</li>
                  <li>Stepped, "How-To" instructions</li>
                  <li>Tutorials</li>
                  <li>Links to related forums, articles and other resources</li>
                </ul>
              </li>
            </ol>
          </div>
        </FlexBox>
      }
    />
  );
};

export default InPageHelpModal;
