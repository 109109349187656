import {
  DASHBOARD_URL,
  DASHLET_URL,
  DEPLOY_DASHBOARD_URL,
  DUPLICATE_DASHBOARD_URL,
  FETCH_DASHBOARD_URL,
  PERFORMANCE_DATA_LINE_DASHLET_URL,
} from "../../api.config";
import {
  createDeleteMock,
  createGetMock,
  createPostMock,
  createPutMock,
} from "../../mockServer/createMockHandler";
import { dashletTypeList } from "../DashletPage/DashletViewPageConstants";
import createDashletArray from "../DashletPage/utils/createDashletArray";
import { Dashlet, Dimension, Position } from "./DashboardPageTypes";

export const CREATE_DASHBOARD_SUCCESS_MESSAGE =
  "Successfully created dashboard 1234";
export const DELETE_DASHBOARD_SUCCESS_MESSAGE =
  "Successfully deleted dashboard 1234";
export const DUPLICATE_DASHBOARD_SUCCESS_MESSAGE =
  "Successfully duplicated dashboard 1234 to 12345";
export const EDIT_DASHBOARD_SUCCESS_MESSAGE =
  "Successfully edited dashboard 1234";
export const DEPLOY_DASHBOARD_SUCCESS_MESSAGE =
  "Successfully deployed dashboard 1234";
export const DEPLOY_MASS_DASHBOARD_SUCCESS_MESSAGE =
  "Successfully deployed dashboard 1234";

export const createPositionObject = (x: number, y: number) => ({ x, y });
export const createDimensionObject = (height: number, width: number) => ({
  height,
  width,
});

export const createDashletObject = (
  name: string,
  type: string,
  position: Position,
  dimension: Dimension,
  endpoint: string,
  color?: string,
  properties?: any
) => ({
  dashlet_id: `1234${name}`,
  name,
  type,
  position_x: position.x,
  position_y: position.y,
  dimension_height: dimension.height,
  dimension_width: dimension.width,
  color: color ? color : "#FFFF",
  endpoint,
  properties,
});

const createDashboardObject = (
  dashboard_id: number,
  name: string,
  tagList: string[],
  dashlets: Dashlet[],
  color?: string,
  properties?: any
) => ({
  dashboard_id,
  name,
  destination_name: name,
  color: color ? color : "#FFFF",
  tagList,
  dashlets,
  properties,
});

export const dashboardArray = [
  createDashboardObject(
    1,
    "Corporate",
    ["Read Only"],
    createDashletArray([
      createDashletObject(
        "Reporting Server",
        "line",
        createPositionObject(0, 0),
        createDimensionObject(3, 3),
        PERFORMANCE_DATA_LINE_DASHLET_URL,
        "#FFFF",
        {
          pinned: false,
          x_axis_title: "Food",
          y_axis_title: "Count",
          colors: "nivo",
        }
      ),
    ])
  ),
  createDashboardObject(
    2,
    "Supply Chain",
    ["Editable", "Public"],
    createDashletArray([])
  ),
  createDashboardObject(
    3,
    "Fleet",
    ["Editable", "Public"],

    createDashletArray(
      dashletTypeList.map((dashletType, i: number) =>
        createDashletObject(
          dashletType,
          dashletType,
          createPositionObject(i, 2),
          createDimensionObject(3, 3),
          "",
          "#FFFF",
          {
            pinned: false,
            x_axis_title: "Food",
            y_axis_title: "Count",
            colors: "nivo",
          }
        )
      )
    )
  ),
];

const mocks = [
  createPostMock(DASHBOARD_URL, 200, {
    message: CREATE_DASHBOARD_SUCCESS_MESSAGE,
  }),

  ...dashboardArray.map((dashboard) =>
    createDeleteMock(`${DASHBOARD_URL}/${dashboard.dashboard_id}`, 200, {
      message: DELETE_DASHBOARD_SUCCESS_MESSAGE,
    })
  ),

  ...dashboardArray.map((dashboard) =>
    createPostMock(
      `${DUPLICATE_DASHBOARD_URL}/${dashboard.dashboard_id}`,
      200,
      {
        message: DUPLICATE_DASHBOARD_SUCCESS_MESSAGE,
      }
    )
  ),

  ...dashboardArray.map((dashboard) =>
    createPutMock(`${DASHBOARD_URL}/${dashboard.dashboard_id}`, 200, {
      message: EDIT_DASHBOARD_SUCCESS_MESSAGE,
    })
  ),
  createPostMock(DEPLOY_DASHBOARD_URL, 200, {
    message: DEPLOY_DASHBOARD_SUCCESS_MESSAGE,
  }),
  createGetMock(DASHBOARD_URL, 200, {
    dashboards: dashboardArray,
  }),

  createGetMock(DASHLET_URL, 200, {
    dashlets: dashboardArray.map(({ dashlets }) => dashlets).flat(),
  }),

  ...dashboardArray.map((dashboard, i) =>
    createGetMock(FETCH_DASHBOARD_URL(dashboard.dashboard_id), 200, {
      dashboard: dashboardArray[i],
    })
  ),

  ...dashletTypeList.map((dashletType, i: number) =>
    createGetMock(`${DASHBOARD_URL}/${90 + i}?dashlet_detail_level=2`, 200, {
      dashboard: dashboardArray[4 + (i + 1)],
    })
  ),
];

export default mocks;
