import React, { createContext, useState } from "react";
type LanguageObject = {
  language: string;
  currentLanguageFlagObject: LanguageFlagObject;
  updateLanguage: Function;
  languageFlagObject: LanguageFlagObject[];
};

type LanguageFlagObject = {
  languageName: string;
  countryCode: string;
  languageCode: string;
};

type Props = {
  children: JSX.Element[] | JSX.Element;
};

const createLanguageObject = (
  languageName: string,
  countryCode: string,
  languageCode: string
) => ({
  languageName,
  countryCode,
  languageCode,
});

export const languageFlagObject: LanguageFlagObject[] = [
  createLanguageObject("English", "us", "en"),
  createLanguageObject("Japanese", "jp", "ja"),
  createLanguageObject("Spanish", "es", "es"),
  createLanguageObject("Bulgarian", "bg", "bg"),
  createLanguageObject("Dutch", "nl", "nl"),
  createLanguageObject("Filipino", "ph", "tl"),
  createLanguageObject("Greek", "gr", "el"),
  createLanguageObject("Hungarian", "hu", "hu"),
  createLanguageObject("Norwegian", "no", "no"),
  createLanguageObject("Finnish", "fi", "fi"),
  createLanguageObject("Chinese (S)", "cn", "zh"),
  createLanguageObject("Chinese (T)", "tw", "zh-TW"),
  createLanguageObject("Czech", "cz", "cs"),
  createLanguageObject("French", "fr", "fr"),
  createLanguageObject("German", "de", "de"),
  createLanguageObject("Italian", "it", "it"),
  createLanguageObject("Irish", "ie", "it"),
  createLanguageObject("Thai", "th", "th"),
  createLanguageObject("Korean", "kr", "ko"),
  createLanguageObject("Polish", "pl", "pl"),
  createLanguageObject("Swedish", "se", "sv"),
  createLanguageObject("Turkish", "tr", "tr"),
  createLanguageObject("Vietnamese", "vn", "vi"),
  createLanguageObject("Russian", "ru", "ru"),
  createLanguageObject("Ukrainian", "ua", "uk"),
  createLanguageObject("Norwegian", "no", "no"),
];

export const LanguageContext = createContext<LanguageObject>({
  language: "en",
  currentLanguageFlagObject: languageFlagObject[0],
  updateLanguage: Function,
  languageFlagObject,
});

export function LanguageProvider({ children }: Props) {
  const [language, setLanguage] = useState(0);

  const currentObject = languageFlagObject[language];

  return (
    <LanguageContext.Provider
      value={{
        language: currentObject.languageCode,
        currentLanguageFlagObject: currentObject,
        updateLanguage: setLanguage,
        languageFlagObject,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

export const testWrapper = ({ children }) => (
  <LanguageProvider>{children}</LanguageProvider>
);
