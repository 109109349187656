import React from "react";
import styled from "styled-components";
import useNagiosStatus from "../../hooks/useNagiosStatus";

const StyledVersion = styled.div`
  font-weight: bold;
`;

const UpgradeFooter = (props) => {
  const { data: nagiosStatusData } = useNagiosStatus();
  const upgrade_url = "https://assets.nagios.com/downloads/nagiosxi/docs/XI-Upgrade-Instructions.pdf";
  return (
    <StyledVersion>
      {nagiosStatusData && <span>Version {nagiosStatusData.version}</span>}
      {nagiosStatusData && nagiosStatusData.update_available !== false && (
        <span>
          <a href={upgrade_url} target="_blank" rel="noopener noreferrer">Update to {nagiosStatusData.update_available}</a>
        </span>
      )}
    </StyledVersion>
  );
};

export default UpgradeFooter;