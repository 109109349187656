import { Card, Button } from "antd";
import React from "react";
import styled from "styled-components";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";
import {
  IconAdd,
  IconHeadset,
  IconHighlight,
} from "../../../Shared/Atoms/FontIcons/FontIcons";

const ContactCard = styled(Card)`
  & .ant-card-body {
    & h4 {
      font-size: 16px;
    }
    & h5 {
      font-size: 14px;
      margin-bottom: 0px;
    }
    & p {
      font-size: 14px;
      color: ${themeVariables.colorGrayLight1};
    }
    & div > a {
      padding: 0px;
      line-height: 1;
    }
    & button {
      height: 40px;
      margin: 20px 0;
    }
    & > a {
      padding: 0 40px;
      line-height: 1.4;
      white-space: normal;
    }
    & hr {
      opacity: 20%;
      margin: 20px 0;
    }
  }
`;

const IconCard = styled.div`
  padding: 0px;
  display: flex;
  align-items: flex-start;
  & div {
    margin-left: 20px;
    & p {
      font-size: 12px !important;
    }
  }
`;

export type ContactProps = {
  upgrade: boolean;
  createTicket: () => void;
};

const ContactSupport = (props: ContactProps): JSX.Element => {
  const theme = useMasterTheme().theme;
  return (
    <ContactCard>
      <h4>Contact Support</h4>
      <p>
        Create a ticket {props.upgrade && "or call our support line"} for Nagios
        XI assistance. Tickets will be resonded to within a{" "}
        {props.upgrade ? "24" : "48"} hour timeframe.
      </p>
      {props.upgrade && (
        <div>
          <p>
            <em>
              We're here to serve you by phone 9am to 5pm, CST, Monday through
              Friday.
            </em>
          </p>
          <h5>US</h5>
          <Button type="link" href="tel:16512049102">
            +1 651-204-9102
          </Button>
          <h5>International</h5>
          <Button type="link" href="tel:18886244671">
            1 888-NAGIOS-1
          </Button>{" "}
          (624-4671)
        </div>
      )}
      <Button block type="primary" onClick={props.createTicket}>
        <IconAdd size="1.5em" /> Create a Support Ticket
      </Button>
      <Button block type="link" href="#">
        Or need us for something other than support?
      </Button>
      {!props.upgrade && (
        <div>
          <hr />
          <IconCard>
            <IconHighlight color={theme.iconLightYellowBG} size="75px">
              <IconHeadset color={themeVariables.colorYellowDark} />
            </IconHighlight>
            <div>
              <h5>We also have phone support</h5>
              <p>
                Upgrade your support plan to receive instant form our call
                center
              </p>
              <Button type="link" href="#">
                See upgrade details
              </Button>
            </div>
          </IconCard>
        </div>
      )}
    </ContactCard>
  );
};

export default ContactSupport;
