import { find } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { DASHLET_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { postData } from "../../../utils/axiosHelpers";
import { Dashboard } from "../../DashboardPage/DashboardPageTypes";
import {
  KEY,
  FETCH_DASHBOARD_KEY,
} from "../../DashboardPage/hooks/useDashboards";

export default function useCreateDashlet() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    ({ dashletToCreate, dashboard_id }) => {
      const { dimension, position, isCustomUrl, endpoint, id, ...rest } =
        dashletToCreate;

      return postData(DASHLET_URL, {
        ...rest,
        color: "white",
        endpoint: "https://www.google.com/",
        position_x: 3,
        position_y: 3,
        dimension_width: 3,
        dimension_height: 3,
        dashboard_id,
      });
    },
    {
      onMutate: async ({ dashletToCreate, dashboard_id }: any) => {
        const queryKey = [FETCH_DASHBOARD_KEY, dashboard_id];
        await queryClient.cancelQueries(queryKey);

        const previousDashboards: any = queryClient.getQueryData(KEY);
        const previousDashboard = find(previousDashboards.dashboards, {
          dashboard_id,
        });

        const { name, destinationName, color, tagList, dashlets } =
          previousDashboard;

        const CALL_REASON = `Created Dashlet ${dashletToCreate.name} in dashboard ${name}`;

        const newDashboardObject = {
          dashboard_id,
          name,
          destinationName,
          color,
          tagList,
          dashlets: [
            ...dashlets,
            {
              id: Math.random().toString(),
              ...dashletToCreate,
              properties: { isPinned: false },
              position_x: 3,
              position_y: 3,
              dimension_width: 3,
              dimension_height: 3,
            },
          ],
        };

        queryClient.setQueryData(queryKey, {
          dashboard: newDashboardObject,
        });

        queryClient.setQueryData(KEY, {
          dashboards: previousDashboards.dashboards.map(
            (dashboard: Dashboard) =>
              dashboard.dashboard_id === dashboard_id
                ? newDashboardObject
                : dashboard
          ),
        });

        return { previousDashboard, newDashboardObject, CALL_REASON, queryKey };
      },
      onSuccess: (data, _, context) => {
        onSuccessNotification(data, context?.CALL_REASON);
      },
      onError: (data, context) => {
        onErrorNotification(data, context?.CALL_REASON);
        if (context?.previousDashboard) {
          queryClient.setQueryData<Dashboard[]>(
            context?.queryKey,
            context.previousDashboard
          );
        }
      },

      onSettled: (data, _1, _2, context) => {
        queryClient.invalidateQueries(context?.queryKey);
        queryClient.invalidateQueries(KEY);
      },
    }
  );
}
