import { Form, Row, Col, Input, DatePicker, Select } from "antd";
import React from "react";
import { AdvForm, StyledCheckbox } from "../../../Shared/Atoms/Form/Form";
import { reportsList } from "../../ReportsPageMocks";

const { Search } = Input;
const { RangePicker } = DatePicker;

export type filterProps = {
  isScheduled: boolean;
  filterSearch: (e: any) => void;
  filterSearchOnChange: (e: any) => void;
  filterRange: (e: any) => void;
  filterType: (e: any) => void;
  filterCustom: (e: any) => void;
  filterSchedule: (e: any) => void;
};

const MyReportsFilters = (props: filterProps) => {
  const unfiltered: string[] = [];
  reportsList.map((el) => unfiltered.push(el.type));
  const filteredOptions = Array.from(new Set(unfiltered));

  return (
    <AdvForm layout="vertical">
      <Row gutter={20}>
        <Col span={7}>
          <Form.Item name={["reportFilters", "search"]} label="&nbsp;">
            <Search
              onSearch={props.filterSearch}
              onChange={props.filterSearchOnChange}
              placeholder="Search by Report or Custom name"
            />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            name={["reportFilters", "range"]}
            label="Filter Date Range"
          >
            <RangePicker onChange={props.filterRange} />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            name={["reportFilters", "type"]}
            label="Filter By Report Type"
          >
            <Select onChange={(OptionData) => props.filterType(OptionData)}>
              <Select.Option value={""}>{""}</Select.Option>
              {filteredOptions.map((data) => (
                <Select.Option value={data}>{data}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            name={["reportFilters", "customNames"]}
            style={{ marginBottom: "0px" }}
          >
            <StyledCheckbox onChange={props.filterCustom}>
              Show Custom Names Only
            </StyledCheckbox>
          </Form.Item>
          <Form.Item
            name={["reportFilters", "scheduled"]}
            style={{ marginBottom: "0px" }}
          >
            <StyledCheckbox
              checked={props.isScheduled}
              onChange={props.filterSchedule}
            >
              Show Scheduled Only
            </StyledCheckbox>
          </Form.Item>
        </Col>
      </Row>
    </AdvForm>
  );
};

export default MyReportsFilters;
