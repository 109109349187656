import { Space } from "antd";
import React from "react";
import { DashboardSearchBox, DashboardTableFilter } from "../../Atoms";
import DeployDialogBox from "../../Organisms/MassDeployDialogBox/DeployDialogBox";

export default function DashboardViewActionRow() {
  return (
    <Space>
      <DashboardSearchBox />
      <DashboardTableFilter />
      <DeployDialogBox />
    </Space>
  );
}
