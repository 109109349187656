import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useAuth, useRouter } from "../context";
import { DashletViewPage } from "./DashletPage";
import CreateDashletPage from "./DashletPage/CreateDashletPage";
import { Iframe } from "./Frame";
import useSideNav from "./Frame/hooks/useSideNav";
import { ReportsPage } from "./ReportsPage";
import { UsersPage } from "./UsersPage";
import { ViewsPage } from "./ViewsPage";
import PlaylistPage from "./ViewsPage/PlaylistsPage";

function PrviateRoute({ children, ...rest }) {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export function Routes(): JSX.Element {
  const { routerObjectArray } = useRouter();

  const { flattenSideNav } = useSideNav();

  return (
    <Switch>
      {routerObjectArray.map(({ RouterComponent, path }) => (
        <PrviateRoute exact path={path} key={path}>
          <RouterComponent />
        </PrviateRoute>
      ))}

      <PrviateRoute exact path="/">
        <Redirect
          to={{
            pathname: "/home",
          }}
        />
      </PrviateRoute>

      <PrviateRoute exact path="/dashboards/:dashboardId">
        <DashletViewPage demo={false} />
      </PrviateRoute>

      <PrviateRoute exact path="/dashboards/:dashboardId/add_dashlet">
        <CreateDashletPage demo={false} />
      </PrviateRoute>

      <PrviateRoute exact path="/playlists/:playListId">
        <ViewsPage />
      </PrviateRoute>

      <PrviateRoute exact path="/views/playlists/manage/">
        <PlaylistPage />
      </PrviateRoute>

      <PrviateRoute exact path="/reports/view/:reportId">
        <Iframe url="" />
      </PrviateRoute>

      <PrviateRoute exact path="/reports/types">
        <ReportsPage />
      </PrviateRoute>

      <PrviateRoute exact path="/reports/scheduled">
        <ReportsPage />
      </PrviateRoute>

      <PrviateRoute exact path="/admin/mangage_users">
        <UsersPage />
      </PrviateRoute>

      {flattenSideNav
        .filter((sideNavObject) => "legacyPath" in sideNavObject)
        .map(({ path, link, legacyPath }) => (
          <PrviateRoute
            exact
            path={path ? path : link}
            key={path ? path : link}
          >
            <Iframe url={legacyPath} />
          </PrviateRoute>
        ))}
    </Switch>
  );
}
