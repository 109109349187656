import { useMutation, useQueryClient } from "react-query";
import { PLAYLIST_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { putData } from "../../../utils/axiosHelpers";
import { Playlist, Playlists } from "../ViewsPageTypes";
import { KEY } from "./usePlaylists";

type MutateProps = {
  playlistToEdit: Playlist;
  newTransition: string;
};

export default function useUpdateTransition() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation(
    ({ playlistToEdit, newTransition }) =>
      putData(`${PLAYLIST_URL}/${playlistToEdit.playlist_id}`, {
        ...playlistToEdit,
        transition: newTransition,
      }),
    {
      onMutate: async ({ playlistToEdit, newTransition }: MutateProps) => {
        const { playlist_id } = playlistToEdit;

        await queryClient.cancelQueries(KEY);

        const previousPlaylists: any = queryClient.getQueryData(KEY);

        const CALL_REASON = `Edit Playlist ${playlistToEdit.name}`;

        const newPlaylists = previousPlaylists.playlists.map(
          (playlist: Playlist) => {
            if (playlist.playlist_id === playlist_id) {
              const { transition, ...playlistRest } = playlist;

              return {
                transition: newTransition,
                ...playlistRest,
              };
            }
            return playlist;
          }
        );

        queryClient.setQueryData(KEY, {
          playlists: newPlaylists,
        });

        return {
          previousPlaylists,
          newPlaylists,
          CALL_REASON,
        };
      },
      onSuccess: (data, _, context) => {
        onSuccessNotification(data, context?.CALL_REASON);
      },
      onError: (data, _, context) => {
        onErrorNotification(data, context?.CALL_REASON);
        if (context?.previousPlaylists) {
          queryClient.setQueryData<Playlists>([KEY], context.previousPlaylists);
        }
      },

      onSettled: () => {
        queryClient.invalidateQueries(KEY);
      },
    }
  );
}
