import { Button, Popover } from "antd";
import React from "react";
import Actions from "../../../Shared/Molecules/Actions/Actions";
import useToolPageEvents from "../../hooks/useToolPageEvents";
import { Tool } from "../../ToolsPageTypes";
import { EditToolModal } from "../EditToolModal";

export type Props = {
  tool: Tool;
};

export default function ToolkitTableActions({ tool }: Props) {
  const {
    open,
    editToolForm,
    onDeleteButtonClick,
    onEditModalCancel,
    onViewButtonClick,
    onEditButtonClick,
  } = useToolPageEvents();

  return (
    <>
      <Popover
        content={
          <Actions
            viewClicked={() => onViewButtonClick(tool)}
            deleteClicked={() => onDeleteButtonClick(tool)}
            editClicked={() => onEditButtonClick(tool)}
          />
        }
        placement={"right"}
      >
        <Button
          type="link"
          style={{ padding: 0 }}
          data-testid={`Actions_${tool.name}`}
        >
          Actions
        </Button>
      </Popover>

      <EditToolModal
        visable={open}
        form={editToolForm}
        toolToEdit={tool}
        onCancel={onEditModalCancel}
        onOk={onEditButtonClick}
      />
    </>
  );
}
