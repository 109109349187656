import {
  IconArrowExpand,
  IconSettingsConfig,
  IconQuestionCircle,
  IconStar,
} from "../../Atoms/FontIcons/FontIcons";
import { Button, Space } from "antd";
import styled from "styled-components";

export type ArrayProps = {
  favoriteClicked?: (event: React.MouseEvent<HTMLElement>) => void;
  expandClicked?: (event: React.MouseEvent<HTMLElement>) => void;
  settingsClicked?: (event: React.MouseEvent<HTMLElement>) => void;
  infoClicked?: (event: React.MouseEvent<HTMLElement>) => void;
};

const StyledButton = styled(Button)`
  padding: 4px 6px !important;
`;

const ButtonArray = (props: ArrayProps) => {
  return (
    <Space>
      <StyledButton size="large" onClick={props.favoriteClicked}>
        <IconStar />
      </StyledButton>
      <StyledButton size="large" onClick={props.expandClicked}>
        <IconArrowExpand />
      </StyledButton>
      <StyledButton size="large" onClick={props.settingsClicked}>
        <IconSettingsConfig />
      </StyledButton>
      <StyledButton size="large" onClick={props.infoClicked}>
        <IconQuestionCircle />
      </StyledButton>
    </Space>
  );
};

export default ButtonArray;
