import { DefaultTheme, CSSObject } from "styled-components";

//Not sure if we should be setting props as conditional
export const borderRadiusBoxShadowBackground = (
  theme: DefaultTheme
): CSSObject => {
  return {
    borderRadius: theme.defaultBorderRadius,
    boxShadow: theme.boxShadowDefault,
    backgroundColor: theme.backgroundColorPrimary,
  };
};

export const paginationStyles = (theme: DefaultTheme) => {
  const borderColor = theme.paginationBorderColor;
  const paginationBorder = `2px solid ${borderColor}`;
  const baseTransition = ".1s ease";
  return `
  font-weight: 500!important;
  .ant-pagination-next, .ant-pagination-item-link, .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-item-link:hover {border: none;}
  .ant-pagination-item {border: 0px solid ${borderColor};}
  &&&.ant-pagination-item-ellipses, .ant-pagination-jump-prev, .ant-pagination-jump-next, .ant-pagination-prev, .ant-pagination-prev.ant-pagination-disabled, .ant-pagination-next, .ant-pagination-next.ant-pagination-disabled, .ant-pagination-item, .ant-pagination-item:focus, .ant-pagination-item:hover {border-radius: 0px; margin: 0; border-top: ${paginationBorder}; border-bottom: ${paginationBorder}; border-right: ${paginationBorder};} 
  &&&.ant-pagination-prev, .ant-pagination-prev.ant-pagination-disabled {border-left: ${paginationBorder}; transition: border-color ${baseTransition};}
  &&&.ant-pagination-jump-prev, .ant-pagination-prev, .ant-pagination-prev.ant-pagination-disabled {border-bottom-left-radius: ${theme.defaultBorderRadius}; border-top-left-radius: ${theme.defaultBorderRadius}; border-left: ${paginationBorder}; transition: border-color ${baseTransition};} 
  &&&.ant-pagination-jump-next, .ant-pagination-next, .ant-pagination-next.ant-pagination-disabled {border-bottom-right-radius: ${theme.defaultBorderRadius}; border-top-right-radius: ${theme.defaultBorderRadius}; border-right: ${paginationBorder}; transition: border-color ${baseTransition};} 
  .ant-pagination-item-active, .ant-pagination-item-active:focus, .ant-pagination-item-active a, .ant-pagination-item-active a:focus {color: ${theme.paginationActiveColor}!important; border-color: ${theme.paginationActiveBackgroundColor}; margin-left: -2px; padding-left: 4px; transition: border-color ${baseTransition}};
  .ant-pagination-next .ant-pagination-item-link {border: none}  `;
};
