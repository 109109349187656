import { Card, Button } from "antd";
import React from "react";
import styled from "styled-components";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";
import {
  IconCheck,
  IconSearch,
} from "../../../Shared/Atoms/FontIcons/FontIcons";

export type ScreenProps = {
  image: string;
  title: string;
  description?: string;
  sub?: string;
  added: boolean;
  id: number;
  imgClicked: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  addbtnClicked: (id: number) => void;
  removeBtnClicked: (id: number) => void;
};

const StyledCard = styled(Card)`
  margin: 10px !important;
  padding: 0px;
  background-color: ${themeVariables.colorBluePale};
  & .ant-card-body {
    padding: 20px;
    line-height: 1.2;
    display: flex;
    & div:nth-child(1) {
      position: relative;
      width: 110px;
      align-self: flex-start;
      cursor: pointer;
      & > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        color: ${themeVariables.colorWhite};
        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        & > i {
          margin-right: 8px;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
    & div:nth-child(2) {
      align-self: flex-start;
      flex: 1;
      margin: 0 20px;
      & h4 {
        font-size: 14px;
      }
      & p {
        font-size: 12px;
        color: ${themeVariables.colorGrayLight2};
      }
      & h5 {
        font-size: 12px;
        font-style: italic;
      }
    }
    & button {
      align-self: center;
      width: 130px;
    }
  }
`;

const CommonScreens = (props: ScreenProps) => {
  return (
    <StyledCard>
      <div onClick={props.imgClicked}>
        <img src={props.image} alt="preview" />
        <div>
          <IconSearch size="1.2em" />
          Preview
        </div>
      </div>
      <div>
        <h4>{props.title}</h4>
        <p>{props.description}</p>
        <h5>{props.sub}</h5>
      </div>
      {props.added ? (
        <Button
          block
          type="primary"
          onClick={() => props.removeBtnClicked(props.id)}
        >
          <IconCheck size="1.4em" /> Added
        </Button>
      ) : (
        <Button
          block
          type="default"
          onClick={() => props.addbtnClicked(props.id)}
        >
          Add to Playlist
        </Button>
      )}
    </StyledCard>
  );
};

export default CommonScreens;
