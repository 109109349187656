import { last } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { DUPLICATE_USER_URL } from "../../../api.config";
import { useNotifications } from "../../../hooks";
import { putData } from "../../../utils/axiosHelpers";
import { Users } from "../UsersPageTypes";
import { KEY } from "./useUsers";

export default function useDuplicateUser() {
  const { onSuccessNotification, onErrorNotification } = useNotifications();

  const queryClient = useQueryClient();

  return useMutation((values) => putData(DUPLICATE_USER_URL, values), {
    onMutate: async (userToDuplicate: any) => {
      await queryClient.cancelQueries(KEY);

      const previousUserList: any = queryClient.getQueryData(KEY);

      const CALL_REASON = `Duplicate User ${userToDuplicate.name}`;

      const { user_id: oldId, ...rest } = userToDuplicate;
      const { user_id: last_user_id }: any = last(previousUserList.users);

      const newUser = {
        user_id: last_user_id + 1,
        ...rest,
      };

      const newUserList = [...previousUserList.users, newUser];

      queryClient.setQueryData(KEY, {
        users: newUserList,
      });

      return {
        previousUserList,
        newUserList,
        CALL_REASON,
      };
    },
    onSuccess: (data, _, context) => {
      onSuccessNotification(data, context?.CALL_REASON);
    },
    onError: (data, _, context) => {
      onErrorNotification(data, context?.CALL_REASON);
      if (context?.previousUserList) {
        queryClient.setQueryData<Users>([KEY], context.previousUserList);
      }
    },

    onSettled: () => {
      queryClient.invalidateQueries(KEY);
    },
  });
}
