import React from "react";

export default function NoHostSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="208"
      height="216"
      viewBox="0 0 208 216"
    >
      <g>
        <g>
          <path
            fill="#0b89ff"
            d="M104 216c57.438 0 104-46.562 104-104S161.438 8 104 8 0 54.562 0 112s46.562 104 104 104z"
          />
        </g>
        <g>
          <g opacity=".15">
            <path d="M103 178.435v-7.87L93.628 165H80.376L71 170.565v7.87L80.376 184h13.252z" />
          </g>
          <g opacity=".15">
            <path d="M48 138.435v-7.87L38.628 125H25.376L16 130.565v7.87L25.376 144h13.252z" />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M65.898 127.016H57.92l-2.44-6.675 6.787-2.661 6.62 2.716z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M86.086 115.358L65.9 127.012v-5.827l8.419-7.96 11.767-3.695z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M86.09 109.532v-4.608L69.543 95.37h-7.978L41.38 107.023v4.609l16.546 9.555h7.978z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M57.926 127.011l-16.546-9.553v-5.828l16.546 9.555z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M65.949 125.678h-8.212l-2.507-17.84 6.979-2.738 6.815 2.794z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M86.72 113.687l-20.77 11.992v-16.968l8.66-8.188 12.11-3.803z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M86.72 96.714V91.97l-17.028-9.83h-8.208L40.71 94.131v4.743l17.025 9.83h8.214z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M57.735 125.68l-17.025-9.834V98.88l17.025 9.83z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M65.898 108.14H57.92l-2.44-6.67 6.787-2.66 6.62 2.715z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M86.086 96.488L65.9 108.14v-5.827l8.419-7.955 11.767-3.699z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M86.09 90.659v-4.605L69.543 76.5h-7.978L41.38 88.156v4.606l16.546 9.552h7.978z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M57.926 108.138L41.38 98.586V92.76l16.546 9.552z"
            />
          </g>
          <g>
            <path
              fill="#dde65c"
              d="M57.927 108.142l-6.107-3.527v-2.765l6.107 3.526z"
            />
          </g>
          <g>
            <path d="M67.396 100.296l-11.33.335-14.686-8.484v5.83l16.546 9.554h7.978L86.09 95.877V90.05z" />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M65.949 106.426h-8.212L55.23 95.5l6.979-2.739 6.815 2.794z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M86.72 94.43l-20.77 11.99V96.36l8.66-8.187 12.11-3.803z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M86.72 84.372V79.63L69.691 69.8h-8.208L40.71 81.793v4.737l17.025 9.833h8.214z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M57.735 106.421L40.71 96.592V86.53l17.025 9.832z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M65.898 95.804H57.92l-2.44-6.673 6.787-2.661 6.62 2.717z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M86.086 84.146L65.9 95.8V89.97l8.419-7.954 11.767-3.697z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M86.09 78.322v-4.608L69.543 64.16h-7.978L41.38 75.815v4.604l16.546 9.554h7.978z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M57.926 95.802L41.38 86.25V80.42l16.546 9.554z"
            />
          </g>
          <g>
            <path
              fill="#dde65c"
              d="M57.927 95.804l-6.107-3.526V89.51l6.107 3.528z"
            />
          </g>
          <g>
            <path d="M67.396 87.957l-11.33.334-14.686-8.483v5.83l16.546 9.55h7.978L86.09 83.538V77.71z" />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M65.952 94.151h-8.213v-9.213L55.75 80.77l13.276 2.507z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M86.72 82.162L65.95 94.154v-10.06l8.66-8.186L86.72 72.1z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M86.72 72.1v-4.739L69.691 57.53h-8.208L40.71 69.523v4.738l17.025 9.833h8.214z"
            />
          </g>
          <g>
            <path fill="#505666" d="M40.71 74.26v10.062l17.025 9.83v-10.06z" />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M65.898 83.537H57.92l-2.44-6.677 6.787-2.66 6.62 2.717z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M86.086 71.876L65.9 83.532v-5.827l8.419-7.958 11.767-3.697z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M86.09 66.053v-4.606L69.543 51.89h-7.978L41.38 63.546v4.606l16.546 9.556h7.978z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M57.926 83.534L41.38 73.98v-5.83l16.546 9.556z"
            />
          </g>
          <g>
            <path
              fill="#dde65c"
              d="M57.927 83.535l-6.107-3.528V77.24l6.107 3.526z"
            />
          </g>
          <g>
            <path d="M67.396 75.681l-11.33.33-14.686-8.48v5.827l16.546 9.556h7.978L86.09 71.26v-5.83z" />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M65.949 81.92h-8.212L55.23 70.987l6.979-2.737 6.815 2.794z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M86.72 69.93L65.95 81.923V71.86l8.66-8.188 12.11-3.803z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M86.72 59.864v-4.74L69.691 45.29h-8.208L40.71 57.284v4.74l17.025 9.83h8.214z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M57.735 81.924L40.71 72.09V62.03l17.025 9.831z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M65.898 71.297H57.92l-2.44-6.677 6.787-2.66 6.62 2.717z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M86.086 59.646L65.9 71.302v-5.827l8.419-7.958 11.767-3.697z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M86.09 53.813v-4.606L69.543 39.65h-7.978L41.38 51.306v4.606l16.546 9.556h7.978z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M57.926 71.294L41.38 61.74v-5.83l16.546 9.556z"
            />
          </g>
          <g>
            <path
              fill="#dde65c"
              d="M57.927 71.295l-6.107-3.528V65l6.107 3.526z"
            />
          </g>
          <g>
            <path d="M67.396 63.451l-11.33.33-14.686-8.48v5.827l16.546 9.556h7.978L86.09 59.03V53.2z" />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M65.949 69.69h-8.212L55.23 58.757l6.979-2.737 6.815 2.794z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M86.72 57.69L65.95 69.683V59.62l8.66-8.188 12.11-3.803z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M86.72 47.634v-4.74L69.691 33.06h-8.208L40.71 45.054v4.74l17.025 9.83h8.214z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M57.735 69.684L40.71 59.85V49.79l17.025 9.831z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M57.834 71.819l-.773.446L39.43 62.086l.774-.446z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M57.835 74.358l-.774.446-.531-1.493.531-1.045.774-.446z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M57.061 72.259L39.43 62.08v2.54l17.631 10.177z"
            />
          </g>
          <g>
            <path
              fill="#574943"
              d="M57.834 60.468l-.773.447-17.631-10.18.774-.445z"
            />
          </g>
          <g>
            <path fill="#0a0808" d="M57.064 60.907V72.26l.772-.446V60.46z" />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M57.061 60.909L39.43 50.73v11.352L57.061 72.26z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M56.791 71.641v.158L39.7 61.932V51.2l.137.079V61.85z"
            />
          </g>
          <g>
            <path
              fill="#8ccccb"
              d="M56.794 61.068v10.574l-16.954-9.79V51.28z"
            />
          </g>
          <g>
            <g>
              <path
                fill="#fff"
                d="M41.08 61.526l10.831 6.254v-7.776L41.08 53.75z"
              />
            </g>
            <g>
              <g>
                <path
                  fill="#428aa8"
                  d="M51.936 67.71l-.018-.01v-7.693L41.15 53.789v-.019l10.786 6.227z"
                />
              </g>
              <g>
                <path
                  fill="#428aa8"
                  d="M51.259 67.323l-.018-.01V59.61l.018.01z"
                />
              </g>
              <g>
                <path fill="#428aa8" d="M50.58 66.933l-.02-.01V59.22l.02.01z" />
              </g>
              <g>
                <path fill="#428aa8" d="M49.9 66.543l-.02-.01V58.83l.02.01z" />
              </g>
              <g>
                <path fill="#428aa8" d="M49.23 66.153l-.02-.01V58.44l.02.01z" />
              </g>
              <g>
                <path
                  fill="#428aa8"
                  d="M48.549 65.763l-.018-.01V58.05l.018.01z"
                />
              </g>
              <g>
                <path
                  fill="#428aa8"
                  d="M47.878 65.373l-.016-.01V57.66l.016.01z"
                />
              </g>
              <g>
                <path fill="#428aa8" d="M47.2 64.983l-.02-.01V57.27l.02.01z" />
              </g>
              <g>
                <path fill="#428aa8" d="M46.52 64.593l-.02-.01V56.88l.02.01z" />
              </g>
              <g>
                <path
                  fill="#428aa8"
                  d="M45.848 64.203l-.016-.01V56.49l.016.01z"
                />
              </g>
              <g>
                <path
                  fill="#428aa8"
                  d="M45.169 63.813l-.018-.01V56.1l.018.01z"
                />
              </g>
              <g>
                <path
                  fill="#428aa8"
                  d="M44.499 63.413l-.018-.01V55.7l.018.01z"
                />
              </g>
              <g>
                <path fill="#428aa8" d="M43.82 63.023l-.02-.01V55.31l.02.01z" />
              </g>
              <g>
                <path fill="#428aa8" d="M43.14 62.633l-.02-.01V54.92l.02.01z" />
              </g>
              <g>
                <path fill="#428aa8" d="M42.47 62.243l-.02-.01V54.53l.02.01z" />
              </g>
              <g>
                <path
                  fill="#428aa8"
                  d="M41.789 61.853l-.018-.01V54.14l.018.01z"
                />
              </g>
              <g>
                <path
                  fill="#428aa8"
                  d="M51.927 60.791L41.15 54.57v-.019l10.777 6.22z"
                />
              </g>
              <g>
                <path
                  fill="#428aa8"
                  d="M51.927 61.571L41.15 55.35v-.019l10.777 6.222z"
                />
              </g>
              <g>
                <path
                  fill="#428aa8"
                  d="M51.927 62.351L41.15 56.13v-.019l10.777 6.222z"
                />
              </g>
              <g>
                <path
                  fill="#428aa8"
                  d="M51.927 63.141L41.15 56.92V56.9l10.777 6.222z"
                />
              </g>
              <g>
                <path
                  fill="#428aa8"
                  d="M51.927 63.921L41.15 57.7v-.019l10.777 6.222z"
                />
              </g>
              <g>
                <path
                  fill="#428aa8"
                  d="M51.927 64.701L41.15 58.48v-.019l10.777 6.222z"
                />
              </g>
              <g>
                <path
                  fill="#428aa8"
                  d="M51.927 65.481L41.15 59.26v-.019l10.777 6.222z"
                />
              </g>
              <g>
                <path
                  fill="#428aa8"
                  d="M51.927 66.261L41.15 60.04v-.019l10.777 6.222z"
                />
              </g>
              <g>
                <path
                  fill="#428aa8"
                  d="M51.927 67.041L41.15 60.82V60.8l10.777 6.222z"
                />
              </g>
            </g>
          </g>
          <g>
            <path
              fill="#428aa8"
              d="M41.15 61.479v-7.833l-.13-.076v7.984l10.962 6.33v-.152z"
            />
          </g>
          <g>
            <path
              fill="#fdb053"
              d="M50.352 63.746v.285l.173.302.247.143.176-.102v-.284l-.176-.302-.247-.144z"
            />
          </g>
          <g>
            <path
              fill="#fdb053"
              d="M49.292 64.196v.285l.174.302.248.142.174-.1v-.284l-.174-.304-.248-.142z"
            />
          </g>
          <g>
            <path
              fill="#fdb053"
              d="M47.862 63.025v.285l.174.303.247.142.175-.101v-.284l-.175-.303-.247-.142z"
            />
          </g>
          <g>
            <path
              fill="#fdb053"
              d="M45.602 62.965v.285l.175.304.247.141.174-.101v-.284l-.174-.302-.247-.143z"
            />
          </g>
          <g>
            <path
              fill="#fdb053"
              d="M44.272 61.736v.285l.174.303.249.141.173-.1v-.284l-.173-.303-.249-.143z"
            />
          </g>
          <g>
            <path
              fill="#fdb053"
              d="M42.871 61.215v.285l.176.303.248.142.174-.101v-.284l-.174-.303-.248-.142z"
            />
          </g>
          <g>
            <path
              fill="#fdb053"
              d="M51.15 64.049v.354l.218.376.306.177.216-.125v-.354l-.216-.376-.306-.177z"
            />
          </g>
          <g>
            <path
              fill="#fdb053"
              d="M45.915 63.37l-1.34-1.27-1.4-.49-2.035-1.496.02-.144 2.01 1.48 1.413.493 1.32 1.254 2.253.02 1.41 1.207 1.048-.477.9.414.013.163-.856-.394-1.07.486-1.431-1.226z"
            />
          </g>
          <g>
            <path
              fill="#fa5c52"
              d="M44.452 59.956v.285l.175.302.246.142.175-.101V60.3l-.175-.303-.246-.142z"
            />
          </g>
          <g>
            <path
              fill="#fa5c52"
              d="M45.892 61.047v.285l.175.302.247.14.174-.1v-.284l-.174-.301-.247-.144z"
            />
          </g>
          <g>
            <path
              fill="#fa5c52"
              d="M49.232 61.746v.285l.175.303.246.141.175-.1v-.284l-.175-.303-.246-.143z"
            />
          </g>
          <g>
            <path
              fill="#fa5c52"
              d="M50.042 61.416v.285l.176.302.247.143.173-.103v-.283l-.173-.302-.247-.144z"
            />
          </g>
          <g>
            <path
              fill="#fa5c52"
              d="M51.15 61.589v.354l.218.376.306.177.216-.125v-.355l-.216-.376-.306-.176z"
            />
          </g>
          <g>
            <path
              fill="#fa5c52"
              d="M41.163 58.997l-.023-.167 3.602 1.337.012.009 1.437 1.102 3.335.685.795-.342 1.18.266.04.172-1.153-.26-.794.341-3.391-.694-.018-.013-1.441-1.107z"
            />
          </g>
          <g>
            <path
              fill="#5f9fad"
              d="M51.15 60.139v.354l.218.376.306.177.216-.126v-.354l-.216-.375-.306-.177z"
            />
          </g>
          <g>
            <path
              fill="#5f9fad"
              d="M49.032 59.725v.285l.173.303.248.142.175-.101v-.284l-.175-.303-.248-.142z"
            />
          </g>
          <g>
            <path
              fill="#5f9fad"
              d="M47.023 60.126v.284l.173.303.247.142.174-.1v-.285l-.174-.303-.247-.142z"
            />
          </g>
          <g>
            <path
              fill="#5f9fad"
              d="M44.701 58.895v.286l.176.302.248.142.174-.1v-.284l-.174-.304-.248-.142z"
            />
          </g>
          <g>
            <path
              fill="#5f9fad"
              d="M43.152 58.466v.285l.173.303.248.141.175-.101v-.284l-.175-.302-.248-.143z"
            />
          </g>
          <g>
            <path
              fill="#5f9fad"
              d="M43.454 58.866l-.018-.014-2.306-1.957.031-.135 2.291 1.944 1.537.402 2.335 1.27 1.983-.45 2.189.52.039.172-2.169-.515-1.998.453-2.367-1.287z"
            />
          </g>
          <g>
            <path
              fill="#fa5c52"
              d="M54.674 62.088l-.622-.36-.438.255v.717l.438.762.292.17v.628l-.517-.3-.757-1.313v-1.239l.757-.438 1.074.619z"
            />
          </g>
          <g>
            <path
              fill="#428aa8"
              d="M54.35 63.632l.33.19.439-.253v-.719l-.44-.761.227-.499.757 1.314v1.24l-.757.437-.556-.32z"
            />
          </g>
          <g>
            <path
              fill="#e95156"
              d="M54.674 65.738l-.622-.36-.438.255v.717l.438.762.292.17v.627l-.517-.3-.757-1.312v-1.24l.757-.437 1.074.619z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M54.68 67.467l-.33-.19v.629l.556.32.757-.438-.544-.575z"
            />
          </g>
          <g>
            <path
              fill="#ffec7a"
              d="M54.678 65.739l.44.762v.718l.544.574v-1.239l-.757-1.314z"
            />
          </g>
          <g>
            <path
              fill="#428aa8"
              d="M55.657 70.005l-2.587-1.493v-.732l2.587 1.493z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M65.898 58.915H57.92l-2.44-1.758 6.787-2.657 6.143 2.515z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M86.086 47.263L65.9 58.917v-.913l8.419-7.956 11.767-3.698z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M86.09 46.352v-4.606L69.543 32.19h-7.978L41.38 43.849v4.604l16.546 9.553h7.978z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M57.926 58.915L41.38 49.363v-.913l16.546 9.553z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M96.748 144.826H88.77l-2.44-6.675 6.786-2.661 6.621 2.716z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M116.926 133.166L96.74 144.82v-5.827l8.419-7.96 11.767-3.694z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M116.93 127.343v-4.61l-16.547-9.553h-7.979L72.22 124.833v4.609l16.546 9.555h7.978z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M88.766 144.821l-16.546-9.553v-5.828l16.546 9.555z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M96.788 143.478h-8.212l-2.506-17.84 6.979-2.738 6.815 2.795z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M117.562 131.487L96.79 143.479v-16.968l8.662-8.188 12.11-3.803z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M117.57 114.524v-4.744l-17.03-9.83h-8.206L71.56 111.941v4.743l17.025 9.83h8.212z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M88.585 143.48l-17.025-9.834V116.68l17.025 9.83z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M96.748 125.951H88.77l-2.44-6.672 6.786-2.659 6.621 2.715z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M116.926 114.298L96.74 125.95v-5.827l8.419-7.956 11.767-3.698z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M116.93 108.469v-4.606l-16.547-9.553h-7.979L72.22 105.966v4.604l16.546 9.553h7.978z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M88.766 125.95l-16.546-9.554v-5.826l16.546 9.552z"
            />
          </g>
          <g>
            <path
              fill="#dde65c"
              d="M88.767 125.953l-6.107-3.528v-2.765l6.107 3.526z"
            />
          </g>
          <g>
            <path d="M98.236 118.106l-11.33.335-14.686-8.484v5.83l16.546 9.555h7.978l20.187-11.655v-5.827z" />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M96.788 124.226h-8.212L86.07 113.3l6.979-2.739 6.815 2.794z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M117.562 112.24L96.79 124.23v-10.06l8.662-8.188 12.11-3.802z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M117.57 102.182V97.44l-17.03-9.83h-8.206L71.56 99.603v4.737l17.025 9.834h8.212z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M88.585 124.231l-17.025-9.829V104.34l17.025 9.833z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M96.748 113.614H88.77l-2.44-6.673 6.786-2.661 6.621 2.717z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M116.926 101.957L96.74 113.61v-5.828l8.419-7.954 11.767-3.698z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M116.93 96.132v-4.608l-16.547-9.554h-7.979L72.22 93.625v4.604l16.546 9.554h7.978z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M88.766 113.612L72.22 104.06V98.23l16.546 9.555z"
            />
          </g>
          <g>
            <path
              fill="#dde65c"
              d="M88.767 113.613l-6.107-3.525v-2.768l6.107 3.527z"
            />
          </g>
          <g>
            <path d="M98.236 105.758l-11.33.334-14.686-8.484v5.83l16.546 9.55h7.978l20.187-11.65V95.51z" />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M96.79 111.961h-8.212v-9.214L86.59 98.58l13.276 2.507z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M117.562 99.972L96.79 111.965v-10.062l8.662-8.185 12.11-3.808z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M117.57 89.91v-4.739l-17.03-9.831h-8.206L71.56 87.333v4.738l17.025 9.833h8.212z"
            />
          </g>
          <g>
            <path fill="#505666" d="M71.56 92.07v10.062l17.025 9.83v-10.06z" />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M96.748 101.338H88.77l-2.44-6.677L93.116 92l6.621 2.717z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M116.926 89.686L96.74 101.343v-5.828l8.419-7.958 11.767-3.697z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M116.93 83.863v-4.606L100.383 69.7h-7.979L72.22 81.356v4.606l16.546 9.556h7.978z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M88.766 101.344L72.22 91.789V85.96l16.546 9.556z"
            />
          </g>
          <g>
            <path
              fill="#dde65c"
              d="M88.767 101.346l-6.107-3.53V95.05l6.107 3.526z"
            />
          </g>
          <g>
            <path d="M98.236 93.491l-11.33.33-14.686-8.48v5.827l16.546 9.556h7.978L116.93 89.07v-5.83z" />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M96.788 99.73h-8.212L86.07 88.796l6.979-2.736 6.815 2.794z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M117.562 87.74L96.79 99.733V89.67l8.662-8.188 12.11-3.803z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M117.57 77.674v-4.74L100.54 63.1h-8.206L71.56 75.094v4.74l17.025 9.83h8.212z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M88.585 99.734L71.56 89.9V79.84l17.025 9.83z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M96.748 89.107H88.77l-2.44-6.677 6.786-2.66 6.621 2.717z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M116.926 77.446L96.74 89.102v-5.827l8.419-7.958 11.767-3.697z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M116.93 71.623v-4.606l-16.547-9.557h-7.979L72.22 69.116v4.606l16.546 9.556h7.978z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M88.766 89.104L72.22 79.55v-5.83l16.546 9.556z"
            />
          </g>
          <g>
            <path
              fill="#dde65c"
              d="M88.767 89.105l-6.107-3.528V82.81l6.107 3.526z"
            />
          </g>
          <g>
            <path d="M98.236 81.261l-11.33.33-14.686-8.48v5.827l16.546 9.556h7.978L116.93 76.84v-5.83z" />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M96.788 87.49h-8.212L86.07 76.557l6.979-2.737 6.815 2.794z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M117.562 75.5L96.79 87.493V77.43l8.662-8.188 12.11-3.803z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M117.57 65.444v-4.74l-17.03-9.834h-8.206L71.56 62.864v4.74l17.025 9.83h8.212z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M88.585 87.494L71.56 77.66V67.6l17.025 9.831z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M96.748 76.725H88.77l-2.44-1.758 6.786-2.657 6.144 2.515z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M116.926 65.073L96.74 76.727v-.913l8.419-7.956 11.767-3.698z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M116.93 64.152v-4.606l-16.547-9.556h-7.979L72.22 61.649v4.604l16.546 9.553h7.978z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M88.766 76.725L72.22 67.173v-.913l16.546 9.553z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M127.588 162.636h-7.978l-2.44-6.675 6.786-2.661 6.621 2.716z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M147.776 150.977L127.59 162.63v-5.827l8.419-7.96 11.767-3.694z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M147.779 145.152v-4.61l-16.547-9.552h-7.979l-20.183 11.652v4.608l16.545 9.556h7.977z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M119.615 162.631l-16.545-9.553v-5.828l16.545 9.555z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M127.638 161.288h-8.212l-2.506-17.84 6.979-2.738 6.815 2.794z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M148.412 149.297l-20.772 11.992v-16.968l8.662-8.188 12.11-3.803z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M148.41 132.334v-4.744l-17.03-9.83h-8.206L102.4 129.751v4.743l17.025 9.83h8.212z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M119.425 161.29l-17.025-9.834V134.49l17.025 9.83z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M127.588 143.76h-7.978l-2.44-6.67 6.786-2.66 6.621 2.715z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M147.776 132.109L127.59 143.76v-5.826l8.419-7.955 11.767-3.7z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M147.779 126.278v-4.605l-16.547-9.553h-7.979l-20.183 11.656v4.604l16.545 9.553h7.977z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M119.615 143.758l-16.545-9.552v-5.826l16.545 9.552z"
            />
          </g>
          <g>
            <path
              fill="#dde65c"
              d="M119.607 143.762l-6.107-3.527v-2.765l6.107 3.525z"
            />
          </g>
          <g>
            <path d="M129.075 135.916l-11.33.335-14.685-8.484v5.831l16.545 9.554h7.977l20.188-11.655v-5.827z" />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M127.638 142.034h-8.212l-2.506-10.926 6.979-2.738 6.815 2.793z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M148.412 130.05l-20.772 11.988V131.98l8.662-8.186 12.11-3.803z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M148.41 119.992v-4.742l-17.03-9.83h-8.206L102.4 117.413v4.737l17.025 9.833h8.212z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M119.425 142.04l-17.025-9.828V122.15l17.025 9.832z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M127.588 131.416h-7.978l-2.44-6.675 6.786-2.661 6.621 2.717z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M147.776 119.767L127.59 131.42v-5.829l8.419-7.954 11.767-3.698z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M147.779 113.942v-4.608l-16.547-9.554h-7.979l-20.183 11.655v4.603l16.545 9.555h7.977z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M119.615 131.424l-16.545-9.554v-5.83l16.545 9.555z"
            />
          </g>
          <g>
            <path
              fill="#c84030"
              d="M119.607 131.424l-6.107-3.527v-2.767l6.107 3.527z"
            />
          </g>
          <g>
            <path d="M129.075 123.568l-11.33.334-14.685-8.484v5.83l16.545 9.55h7.977l20.188-11.651v-5.827z" />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M119.425 129.774l-17.025-9.83v-9.214l8.808 4.57 8.217 5.261z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M85.37 129.769l.664.382 1.002-8.131-1.666.198z"
            />
          </g>
          <g>
            <path
              fill="#f4efe9"
              d="M86.034 122.594l3.551-.32 9.97-5.625 4.936-4.717-.66-.382-18.461 10.662z"
            />
          </g>
          <g>
            <path
              fill="#d1d1d1"
              d="M86.04 130.153l25.026-14.421-6.569-3.792-18.457 10.662z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M119.878 120.195l-.339-.255-.05.482-1.409 2.991 1.41 5.329.388-.228z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M118.997 119.567l.042.976.454-.12.39-.227-.493-.856z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M118.994 119.567l-6.92-1.414-7.974-7.186.391-.227 14.895 8.6z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M119.495 120.416l-.496-.856-14.894-8.6-.495.285v8.32l.495.857 14.894 8.6.496-.285z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M110.07 114.41v-.656l-.703-1.22-3.594-2.074-.703.407v.656z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M109.363 112.536l-3.593-2.075.192-.111 3.592 2.074z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M109.362 112.532l.703 1.22.193-.113-.706-1.219z"
            />
          </g>
          <g>
            <path fill="#cc934c" d="M110.073 113.748v.656l.194-.11v-.658z" />
          </g>
          <g>
            <path
              fill="#8ccccb"
              d="M119.368 119.127l-.44.252-15.098-8.715.438-.254z"
            />
          </g>
          <g>
            <path fill="#3e6669" d="M118.92 119.384v1.563l.44-.253v-1.564z" />
          </g>
          <g>
            <path fill="#d1d1d1" d="M118.92 120.943v7.313l.44-.252v-7.314z" />
          </g>
          <g>
            <path
              fill="#389998"
              d="M118.927 119.386l-15.097-8.716v1.563l15.097 8.715z"
            />
          </g>
          <g>
            <path
              fill="#fff"
              d="M103.83 119.545l15.097 8.714v-7.313l-15.097-8.716z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M118.78 120.825l-.34-.255-.05.482-1.41 2.993 1.41 5.326.39-.227z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M118.79 128.503l-.162.403.162.238.705-.404.117-.321-.117-.323z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M117.897 120.197l.04.975.455-.12.39-.227-.494-.855z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M117.896 120.198l-6.92-1.415-7.976-7.186.392-.227 14.895 8.601z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M118.396 121.046l-.495-.855-14.896-8.601-.495.287v8.32l.495.855 14.896 8.6.495-.286z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M109.363 114.82v-.656l-.706-1.22-3.592-2.074-.703.405-.002.658z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M109.17 114.922v-.657l-.702-1.221-3.595-2.074-.703.407v.657z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M108.474 113.053l-3.594-2.074.193-.109 3.591 2.073z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M108.472 113.05l.703 1.22.193-.11-.706-1.22z"
            />
          </g>
          <g>
            <path fill="#cc934c" d="M109.173 114.267v.657l.194-.11v-.658z" />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M127.64 129.77h-8.212v-9.212l-1.988-4.168 13.276 2.507z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M148.412 117.782l-20.772 11.992v-10.06l8.662-8.185 12.11-3.809z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M148.41 107.72v-4.74l-17.03-9.83h-8.206L102.4 105.142v4.74l17.025 9.832h8.212z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M119.425 120.559l-17.025-9.831v-.848l17.025 9.833z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M114.92 123.055l-.338-.255-.054.482-1.408 2.992 1.408 5.328.391-.227z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M114.037 122.427l.04.976.454-.12.392-.227-.494-.856z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M114.035 122.426l-6.922-1.414-7.973-7.186.391-.226 14.895 8.599z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M114.535 123.276l-.494-.856-14.895-8.6-.496.285v8.32l.496.857 14.895 8.601.494-.286z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M105.11 117.27v-.655l-.705-1.221-3.592-2.074-.703.407v.656z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M104.411 115.394l-3.591-2.074.192-.11 3.591 2.073z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M104.41 115.391l.706 1.22.193-.11-.707-1.221z"
            />
          </g>
          <g>
            <path fill="#cc934c" d="M105.114 116.618v.656l.192-.11v-.658z" />
          </g>
          <g>
            <path
              fill="#f7c9e7"
              d="M114.406 121.987l-.44.252-15.096-8.716.438-.253z"
            />
          </g>
          <g>
            <path fill="#9c7a98" d="M113.97 122.243v1.562l.44-.252v-1.563z" />
          </g>
          <g>
            <path fill="#d1d1d1" d="M113.97 123.803v7.313l.44-.252v-7.314z" />
          </g>
          <g>
            <path
              fill="#cca7cf"
              d="M113.966 122.247L98.87 113.53v1.563l15.096 8.716z"
            />
          </g>
          <g>
            <path
              fill="#fff"
              d="M98.87 122.405l15.096 8.715v-7.313L98.87 115.09z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M113.83 123.685l-.34-.255-.05.48-1.41 2.994 1.41 5.328.39-.229z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M113.829 131.363l-.16.403.16.238.703-.404.12-.322-.12-.322z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M112.947 123.058l.04.975.455-.12.39-.227-.493-.856z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M112.945 123.058l-6.921-1.416-7.974-7.186.391-.226 14.895 8.6z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M113.435 123.916l-.495-.854-14.895-8.602-.495.287v8.32l.495.856 14.895 8.6.495-.285z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M104.41 117.682v-.658l-.703-1.22-3.592-2.074-.705.406v.657z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M104.22 117.792v-.658l-.705-1.22-3.592-2.074-.703.407v.657z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M103.511 115.915l-3.591-2.075.193-.11 3.591 2.073z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M103.511 115.912l.705 1.22.193-.112-.704-1.22z"
            />
          </g>
          <g>
            <path fill="#cc934c" d="M104.214 117.127v.657l.192-.11v-.658z" />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M109.97 125.915l-.34-.255-.05.48-1.41 5.722 1.41 2.599.39-.225z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M109.087 125.287l.04.976.455-.12.39-.227-.494-.856z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M109.085 125.286l-6.92-1.414-7.975-7.187.39-.225 14.896 8.599z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M109.575 126.146l-.495-.855-14.895-8.601-.495.286v8.32l.495.857 14.895 8.598.495-.284z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M100.163 120.13v-.656l-.705-1.22-3.593-2.074-.705.407v.657z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M99.452 118.256l-3.592-2.075.193-.111 3.591 2.075z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M99.452 118.261l.705 1.22.19-.11-.702-1.221z"
            />
          </g>
          <g>
            <path fill="#cc934c" d="M100.155 119.477v.656l.19-.109v-.657z" />
          </g>
          <g>
            <path
              fill="#fa5c52"
              d="M109.449 124.856l-.44.254-15.099-8.716.44-.254z"
            />
          </g>
          <g>
            <path fill="#991f16" d="M109.01 125.105v1.562l.44-.253v-1.564z" />
          </g>
          <g>
            <path fill="#d1d1d1" d="M109.01 126.673v7.316l.44-.255v-7.314z" />
          </g>
          <g>
            <path
              fill="#c84030"
              d="M109.007 125.107L93.91 116.39v1.563l15.097 8.715z"
            />
          </g>
          <g>
            <path
              fill="#fff"
              d="M93.91 125.266l15.097 8.716v-7.316L93.91 117.95z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M108.87 126.544l-.339-.254-.053.482-1.408 5.72 1.408 2.6.392-.229z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M108.869 134.222l-.16.403.16.24.705-.406.117-.32-.117-.323z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M107.987 125.915l.04.976.453-.118.393-.228-.493-.855z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M107.985 125.915l-6.92-1.414-7.975-7.186.39-.225 14.897 8.6z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M108.482 126.779l-.492-.859-14.895-8.6-.495.287v8.32l.495.856 14.895 8.6.492-.284z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M99.451 120.54v-.657l-.703-1.22-3.592-2.073-.705.406-.001.657z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M99.26 120.65v-.658l-.703-1.22-3.592-2.072-.705.406v.657z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M98.561 118.773L94.97 116.7l.193-.111 3.591 2.073z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M98.562 118.77l.703 1.22.193-.11-.704-1.22z"
            />
          </g>
          <g>
            <path fill="#cc934c" d="M99.263 119.996v.659l.194-.111v-.659z" />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M105.01 126.506l-.342-.256-.052.482-1.406 2.993 1.406 5.327.393-.227z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M104.126 125.877l.038.977.456-.122.394-.226-.495-.856z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M104.126 125.876l-6.92-1.414-7.976-7.186.393-.226 14.895 8.599z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M104.622 126.725l-.493-.855-14.897-8.6-.492.286v8.318l.492.858 14.897 8.599.493-.285z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M95.201 120.72v-.656l-.705-1.22-3.59-2.074-.706.406v.657z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M94.499 118.846l-3.589-2.075.19-.111 3.591 2.074z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M94.49 118.842l.706 1.22.193-.113-.706-1.219z"
            />
          </g>
          <g>
            <path fill="#cc934c" d="M95.203 120.058v.656l.194-.108v-.66z" />
          </g>
          <g>
            <path
              fill="#8ccccb"
              d="M104.495 116.578l-.438.255-14.747-8.51.44-.253z"
            />
          </g>
          <g>
            <path fill="#3e6669" d="M104.06 116.836v1.562l.44-.254v-1.564z" />
          </g>
          <g>
            <path fill="#d1d1d1" d="M104.06 118.394v13.284l.44-.254V118.14z" />
          </g>
          <g>
            <path
              fill="#389998"
              d="M104.056 116.83l-14.746-8.51v1.563l14.746 8.508z"
            />
          </g>
          <g>
            <path
              fill="#fff"
              d="M89.31 123.163l14.746 8.51v-13.285L89.31 109.88z"
            />
          </g>
          <g>
            <path
              fill="#d1d1d1"
              d="M103.13 129.807v.422l-12.9-7.447V111.45l.365.21v10.912z"
            />
          </g>
          <g>
            <path
              fill="#17435c"
              d="M103.134 118.894v10.912L90.6 122.57v-10.91z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M92.1 114.874l-.195-.34v-.713l.194-.112.18.103.194.34v.713l-.194.112zm-.38-1.25v.884l.302.524.334.195.305-.174v-.885l-.305-.526-.334-.192z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M94.491 116.233l-.194-.336v-.707l.194-.11.18.104.193.334v.706l-.193.112zm-.381-1.24v.885l.303.527.334.192.303-.175v-.885l-.303-.524-.334-.193z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M95.791 116.983l-.192-.335v-.705l.192-.113.18.104.195.337v.705l-.194.112zm-.381-1.238v.886l.303.523.335.194.303-.175v-.885l-.303-.525-.335-.193z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M97.63 118.053l-.194-.336v-.705l.194-.113.18.105.193.335v.705l-.192.113zm-.38-1.24v.885l.303.526.333.194.305-.176v-.886l-.305-.523-.333-.193z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M93.016 115.604l.188.107v-1.583l-.188-.108z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M93.566 115.914l.188.11v-1.585l-.188-.109z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M96.708 117.733l.184.108v-1.583l-.184-.108z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M98.547 118.792l.186.11v-1.585l-.186-.107z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M91.177 114.544l.186.108v-1.585l-.186-.107z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M99.471 119.113l-.193-.335v-.705l.193-.113.18.106.193.333v.706l-.193.112zm-.381-1.238v.886l.301.522.336.194.304-.174v-.885l-.304-.525-.336-.193z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M101.86 120.493l-.191-.335v-.706l.19-.112.183.104.19.335v.706l-.19.112zm-.38-1.24v.885l.301.525.336.195.304-.174v-.887l-.304-.524-.336-.193z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M100.397 119.864l.186.107v-1.583l-.186-.108z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M100.937 120.173l.186.108v-1.584l-.186-.107z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M92.1 122.574l-.195-.337v-.707l.194-.112.18.104.194.335v.707l-.194.114zm-.38-1.24v.883l.302.526.334.194.305-.175v-.887l-.305-.523-.334-.192z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M94.491 123.944l-.194-.335v-.706l.194-.112.18.103.193.337v.703l-.193.114zm-.381-1.239v.886l.303.523.334.193.303-.173v-.886l-.303-.525-.334-.193z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M95.791 124.693l-.192-.335v-.706l.192-.112.18.105.195.334v.706l-.194.112zm-.381-1.238v.883l.303.525.335.194.303-.174v-.885l-.303-.525-.335-.193z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M97.63 125.764l-.194-.336v-.708l.194-.112.18.104.193.337v.708l-.192.113zm-.38-1.24v.887l.303.523.333.191.305-.171v-.887l-.305-.525-.333-.192z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M93.016 123.314l.188.11v-1.585l-.188-.109z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M93.566 123.634l.188.106v-1.582l-.188-.108z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M96.708 125.445l.184.106v-1.584l-.184-.107z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M98.547 126.504l.186.108v-1.583l-.186-.109z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M91.177 122.254l.186.11v-1.585l-.186-.109z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M99.471 126.824l-.193-.335v-.706l.193-.113.18.104.193.336v.705l-.193.112zm-.381-1.239v.883l.301.526.336.194.304-.175v-.885l-.304-.524-.336-.194z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M101.86 128.203l-.191-.335v-.706l.19-.113.183.105.19.336v.705l-.19.113zm-.38-1.238v.886l.301.522.336.195.304-.176v-.884l-.304-.525-.336-.193z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M100.397 127.575l.186.108V126.1l-.186-.11z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M100.937 127.881l.186.108v-1.583l-.186-.106z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M92.65 117.743l-.194-.335v-.706l.193-.113.18.105.194.334v.707l-.194.11zm-.38-1.238v.883l.302.524.336.195.303-.175v-.884l-.303-.526-.336-.192z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M91.177 117.114l.186.108v-1.583l-.186-.109z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M91.718 117.423l.184.107v-1.583l-.184-.107z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M94.491 118.813l-.194-.335v-.708l.194-.112.18.104.193.337v.708l-.193.112zm-.381-1.24v.886l.303.525.334.193.303-.174v-.885l-.303-.525-.334-.193z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M96.881 120.194l-.193-.338v-.706l.193-.112.18.105.193.334v.708l-.193.113zm-.381-1.24v.884l.302.525.335.194.303-.177v-.884l-.303-.524-.335-.192z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M98.18 120.935l-.194-.337v-.705l.193-.113.18.104.195.337v.704l-.195.112zm-.38-1.24v.883l.303.526.336.193.302-.175v-.884l-.302-.525-.336-.193z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M100.022 122.003l-.195-.336v-.707l.195-.11.179.103.194.336v.705l-.194.112zm-.382-1.24v.884l.306.526.331.194.304-.175v-.885l-.304-.526-.331-.191z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M95.406 119.553l.188.108v-1.584l-.188-.107z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M95.956 119.874l.188.108v-1.585l-.188-.107z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M99.096 121.683l.188.108v-1.583l-.188-.108z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M100.937 122.744l.186.107v-1.583l-.186-.108z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M93.566 118.493l.188.106v-1.581l-.188-.108z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M101.86 123.073l-.191-.338v-.711l.19-.113.183.104.19.337v.712l-.19.112zm-.38-1.248v.884l.302.524.335.194.304-.173v-.886l-.304-.526-.335-.192z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M92.65 125.453l-.194-.334v-.706l.193-.114.18.105.194.337v.705l-.194.11zm-.38-1.238v.887l.302.525.336.192.303-.176v-.885l-.303-.524-.336-.194z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M91.177 124.823l.186.108v-1.583l-.186-.108z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M91.718 125.132l.184.11v-1.586l-.184-.106z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M94.491 126.513l-.194-.337v-.704l.194-.113.18.104.193.336v.706l-.193.111zm-.381-1.238v.886l.303.522.334.194.303-.175v-.884l-.303-.525-.334-.193z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M96.881 127.895l-.193-.336v-.706l.193-.113.18.106.193.335v.705l-.193.112zm-.381-1.24v.886l.302.523.335.195.303-.177v-.883l-.303-.525-.335-.194z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M98.18 128.645l-.194-.336v-.706l.193-.112.18.104.195.333v.707l-.195.112zm-.38-1.239v.882l.303.526.336.194.302-.175v-.885l-.302-.526-.336-.192z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M100.022 129.715l-.195-.338v-.708l.195-.112.179.106.194.335v.707l-.194.113zm-.382-1.241v.884l.306.525.331.192.304-.172v-.887l-.304-.524-.331-.192z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M95.406 127.264l.188.108v-1.584l-.188-.108z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M95.956 127.572l.188.11v-1.583l-.188-.109z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M99.096 129.394l.188.106v-1.58l-.188-.11z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M100.937 130.454l.186.107v-1.582l-.186-.109z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M93.566 126.194l.188.108v-1.584l-.188-.108z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M101.86 130.773l-.191-.335v-.705l.19-.114.183.105.19.335v.707l-.19.111zm-.38-1.24v.886l.302.525.335.194.304-.175v-.886l-.304-.523-.335-.194z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M91.561 119.685l-.194-.336v-.706l.194-.113.178.105.195.334v.706l-.195.112zm-.381-1.239v.882l.303.527.334.192.304-.174v-.885l-.304-.524-.334-.194z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M92.475 120.434l.19.107v-1.583l-.19-.108z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M93.016 120.742l.188.11v-1.583l-.188-.109z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M93.566 121.055l.188.106v-1.583l-.188-.108z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M94.107 121.372l.186.108v-1.585l-.186-.105z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M95.03 121.694l-.194-.336v-.708l.193-.111.18.105.194.335v.707l-.194.112zm-.38-1.24v.884l.301.526.335.191.305-.173v-.884l-.305-.527-.335-.191z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M96.881 122.752l-.193-.335v-.705l.193-.114.18.105.193.335v.706l-.193.111zm-.381-1.24v.886l.302.525.336.193.302-.175v-.884l-.302-.526-.336-.191z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M97.797 123.503l.186.11v-1.585l-.186-.108z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M98.337 123.814l.186.108v-1.584l-.186-.108z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M101.486 125.632l.188.11v-1.585l-.189-.107z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M102.028 125.944l.184.108v-1.583l-.184-.109z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M95.956 122.434l.188.108v-1.584l-.188-.108z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M99.26 124.133l-.194-.336v-.704l.194-.113.18.104.193.336v.706l-.194.11zm-.38-1.237v.886l.302.522.335.193.304-.175v-.885l-.304-.523-.335-.194z"
            />
          </g>
          <g>
            <path
              fill="#65c6e5"
              d="M100.562 124.884l-.194-.336v-.706l.194-.113.18.105.193.336v.706l-.193.111zm-.382-1.238v.885l.305.522.333.194.303-.173v-.886l-.303-.524-.333-.194z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M103.92 127.135l-.34-.255-.05.48-1.41 2.993 1.41 5.328.39-.228z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M103.92 134.813l-.161.403.16.238.705-.405.117-.321-.117-.322z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M103.027 126.506l.04.976.454-.12.392-.227-.495-.855z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M103.026 126.506l-6.921-1.415-7.975-7.185.393-.226 14.894 8.6z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M103.526 127.365l-.495-.855-14.896-8.6-.495.285v8.32l.495.856 14.896 8.602.495-.287z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M94.5 121.12v-.655l-.705-1.22-3.592-2.075-.703.408v.656z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M94.3 121.242v-.658l-.702-1.22-3.595-2.074-.703.408v.656z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M93.604 119.355l-3.594-2.074.193-.111 3.591 2.074z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M93.602 119.361l.703 1.22.193-.11-.707-1.221z"
            />
          </g>
          <g>
            <path fill="#cc934c" d="M94.304 120.577v.657l.192-.112v-.656z" />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M101.74 139.218l.661.382 1.003-8.13-1.664.198z"
            />
          </g>
          <g>
            <path
              fill="#f4efe9"
              d="M102.401 132.048l3.551-.32 9.972-5.625 3.502-3.888v-.765l-17.686 10.216z"
            />
          </g>
          <g>
            <path
              fill="#d1d1d1"
              d="M102.4 139.605l17.025-9.831v-7.554l-17.025 9.834z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M102.395 139.596l-.66.382-1.005-10.638 1.665.198z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M101.736 129.926l-3.553-.321-9.97-5.625-3.503-3.887.661-.383 17.026 9.833z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M101.736 139.981l-17.026-9.83V120.09l17.026 9.833z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M127.588 119.147h-7.978l-2.44-6.676 6.786-2.661 6.621 2.717z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M147.776 107.496l-20.186 11.656v-5.828l8.419-7.957 11.767-3.697z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M147.779 101.663v-4.606L131.232 87.5h-7.979L103.07 99.156v4.606l16.545 9.555h7.977z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M119.615 119.152L103.07 109.6v-5.829l16.545 9.555z"
            />
          </g>
          <g>
            <path
              fill="#dde65c"
              d="M119.607 119.145l-6.107-3.528v-2.767l6.107 3.527z"
            />
          </g>
          <g>
            <path d="M129.075 111.3l-11.33.33-14.685-8.48v5.828l16.545 9.555h7.977l20.188-11.654v-5.829z" />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M127.638 117.54h-8.212l-2.506-10.934 6.979-2.736 6.815 2.794z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M148.412 105.54l-20.772 11.994V107.47l8.662-8.188 12.11-3.803z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M148.41 95.484v-4.74l-17.03-9.834h-8.206L102.4 92.904v4.74l17.025 9.832h8.212z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M119.425 117.534L102.4 107.7V97.64l17.025 9.831z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M127.588 106.917h-7.978l-2.44-6.676 6.786-2.661 6.621 2.717z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M147.776 95.256l-20.186 11.656v-5.828l8.419-7.957 11.767-3.697z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M147.779 89.433v-4.606l-16.547-9.557h-7.979L103.07 86.926v4.606l16.545 9.555h7.977z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M119.615 106.912L103.07 97.36V91.53l16.545 9.555z"
            />
          </g>
          <g>
            <path
              fill="#dde65c"
              d="M119.607 106.915l-6.107-3.528v-2.767l6.107 3.527z"
            />
          </g>
          <g>
            <path d="M129.075 99.061l-11.33.33-14.685-8.48v5.827l16.545 9.556h7.977L147.77 94.64v-5.83z" />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M127.638 105.3h-8.212l-2.506-10.934 6.979-2.736 6.815 2.794z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M148.412 93.31l-20.772 11.994V95.24l8.662-8.188 12.11-3.803z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M148.41 83.244v-4.74l-17.03-9.834h-8.206L102.4 80.664v4.74l17.025 9.83h8.212z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M119.425 105.304L102.4 95.47V85.41l17.025 9.83z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M127.588 94.535h-7.978l-2.44-1.758 6.786-2.657 6.145 2.515z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M147.776 82.873L127.59 94.527v-.913l8.419-7.956 11.767-3.698z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M147.779 81.962v-4.606L131.232 67.8h-7.979L103.07 79.459v4.604l16.545 9.553h7.977z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M119.615 94.535l-16.545-9.552v-.913l16.545 9.553z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M148.01 173.761l2.44 6.673h7.978l2.99-6.619-6.622-2.715z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M166.849 166.655l-8.419 7.958v5.828l20.186-11.655v-5.826z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M178.619 162.963v-4.61l-16.547-9.553h-7.979l-20.183 11.653v4.609l16.545 9.554h7.977z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M133.91 170.888l16.546 9.552v-5.826l-16.546-9.554z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M158.478 179.098h-8.212l-2.506-17.84 6.978-2.738 6.817 2.795z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M179.252 167.107l-20.772 11.992v-16.968l8.662-8.188 12.11-3.803z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M179.25 150.144V145.4l-17.03-9.83h-8.207l-20.773 11.991v4.743l17.024 9.83h8.213z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M150.264 179.1l-17.024-9.834V152.3l17.024 9.83z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M158.428 161.571h-7.978l-2.44-6.672 6.786-2.659 6.622 2.714z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M178.616 149.918L158.43 161.57v-5.827l8.419-7.955 11.767-3.699z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M178.619 144.089v-4.606l-16.547-9.553h-7.979l-20.183 11.656v4.604l16.545 9.553h7.977z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M150.455 161.57l-16.545-9.554v-5.826l16.545 9.552z"
            />
          </g>
          <g>
            <path
              fill="#dde65c"
              d="M150.448 161.573l-6.108-3.528v-2.765l6.108 3.526z"
            />
          </g>
          <g>
            <path d="M159.925 153.716l-11.33.335-14.685-8.484v5.83l16.545 9.554h7.977l20.188-11.655v-5.826z" />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M158.478 159.845h-8.212l-2.506-10.927 6.978-2.738 6.817 2.793z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M179.252 147.86l-20.772 11.99v-10.06l8.662-8.188 12.11-3.802z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M179.25 137.801v-4.742l-17.03-9.829h-8.207l-20.773 11.992v4.737l17.024 9.834h8.213z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M150.264 159.851l-17.024-9.829V139.96l17.024 9.833z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M158.428 149.224h-7.978l-2.44-6.673 6.786-2.661 6.622 2.717z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M178.616 137.576L158.43 149.23v-5.828l8.419-7.955 11.767-3.697z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M178.619 131.752v-4.608l-16.547-9.554h-7.979l-20.183 11.655v4.604l16.545 9.554h7.977z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M150.455 149.231l-16.545-9.553v-5.828l16.545 9.554z"
            />
          </g>
          <g>
            <path
              fill="#dde65c"
              d="M150.448 149.224l-6.108-3.525v-2.769l6.108 3.528z"
            />
          </g>
          <g>
            <path d="M159.925 141.377l-11.33.334-14.685-8.484v5.83l16.545 9.55h7.977l20.188-11.65v-5.827z" />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M158.48 147.581h-8.212v-9.213l-1.988-4.168 13.278 2.507z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M179.252 135.591l-20.772 11.993v-10.06l8.662-8.187 12.11-3.807z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M179.25 125.52v-4.739l-17.03-9.831h-8.207l-20.773 11.992v4.74l17.024 9.832h8.213z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M133.24 127.69v10.061l17.024 9.832v-10.06z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M158.428 136.958h-7.978l-2.44-6.677 6.786-2.661 6.622 2.717z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M178.616 125.306l-20.186 11.657v-5.828l8.419-7.96 11.767-3.695z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M178.619 119.473v-4.607l-16.547-9.556h-7.979l-20.183 11.658v4.604l16.545 9.556h7.977z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M150.455 136.964l-16.545-9.555v-5.829l16.545 9.556z"
            />
          </g>
          <g>
            <path
              fill="#dde65c"
              d="M150.448 136.955l-6.108-3.53v-2.765l6.108 3.525z"
            />
          </g>
          <g>
            <path d="M159.925 129.111l-11.33.33-14.685-8.48v5.828l16.545 9.555h7.977l20.188-11.654v-5.83z" />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M158.478 135.348h-8.212l-2.506-10.931 6.978-2.737 6.817 2.794z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M179.252 123.35l-20.772 11.993V125.28l8.662-8.188 12.11-3.802z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M179.25 113.294v-4.74l-17.03-9.834h-8.207l-20.773 11.994v4.74l17.024 9.83h8.213z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M150.264 135.343l-17.024-9.833v-10.06l17.024 9.83z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M158.428 124.575h-7.978l-2.44-1.758 6.786-2.657 6.145 2.515z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M178.616 112.924l-20.186 11.653v-.913l8.419-7.955 11.767-3.699z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M178.619 112.012v-4.605l-16.547-9.557h-7.979l-20.183 11.66v4.603l16.545 9.553h7.977z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M150.455 124.575l-16.545-9.552v-.913l16.545 9.553z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M158.428 124.728h-7.978l-2.44-6.677 6.786-2.661 6.622 2.717z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M178.616 113.066l-20.186 11.657v-5.828l8.419-7.96 11.767-3.695z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M178.619 107.243v-4.607l-16.547-9.556h-7.979l-20.183 11.658v4.604l16.545 9.556h7.977z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M150.455 124.724l-16.545-9.555v-5.829l16.545 9.556z"
            />
          </g>
          <g>
            <path
              fill="#dde65c"
              d="M150.448 124.725l-6.108-3.53v-2.765l6.108 3.525z"
            />
          </g>
          <g>
            <path d="M159.925 116.871l-11.33.33-14.685-8.48v5.827l16.545 9.556h7.977l20.188-11.654v-5.83z" />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M158.478 123.108h-8.212l-2.506-10.931 6.978-2.737 6.817 2.794z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M179.252 111.12l-20.772 11.993V113.05l8.662-8.187 12.11-3.803z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M179.25 101.054v-4.74l-17.03-9.834h-8.207L133.24 98.474v4.74l17.024 9.83h8.213z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M150.264 123.113l-17.024-9.833v-10.06l17.024 9.83z"
            />
          </g>
          <g>
            <path
              fill="#31353f"
              d="M158.428 112.335h-7.978l-2.44-1.758 6.786-2.657 6.145 2.515z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M178.616 100.684l-20.186 11.653v-.913l8.419-7.955 11.767-3.699z"
            />
          </g>
          <g>
            <path
              fill="#6a7282"
              d="M178.619 99.772v-4.605l-16.547-9.557h-7.979L133.91 97.27v4.603l16.545 9.553h7.977z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M150.455 112.335l-16.545-9.552v-.913l16.545 9.553z"
            />
          </g>
          <g>
            <g />
          </g>
          <g>
            <path
              fill="#c7a495"
              d="M98.433 111.937l-.223 1.015-1.344-.776-.186-.239.571-.567z"
            />
          </g>
          <g>
            <path
              fill="#fbbd9e"
              d="M88.848 111.173l3.885-.62 3.885-.303 2.783 1.129.57 1.562-.713-.153-.506-.673-1.65-.14-.941.711.634.508.89 1.117-.737.178-.761-.483-1.398-.254-1.726-1.116-4.012 1.999h-1.04L87.09 112z"
            />
          </g>
          <g>
            <path
              fill="#c69695"
              d="M89.133 111.13l.563.688-.233.905.342.562-.734 1.344-.646-.258-.095-2.668z"
            />
          </g>
          <g>
            <path
              fill="#2dcb75"
              d="M76.21 122.914l-1.47-2.22.097-2.389 1.104-1.331 4.837-2.344 1.22.743-.074 4.724z"
            />
          </g>
          <g>
            <path
              fill="#9b866e"
              d="M77.39 173.697v.323l.636.965h1.548l.943-1.702.202-1.167 1.472-1.727.304-1.625v-.324l-2.436 2.362z"
            />
          </g>
          <g>
            <path
              fill="#fff"
              d="M77.57 171.265l-.18 2.439.636.963h1.548l.943-1.7.202-1.168 1.472-1.726.304-1.626-.277-.508-.993-.229z"
            />
          </g>
          <g>
            <path
              fill="#9b866e"
              d="M89.884 178.402l6.5-.612v.353l-1.574 1.243-5.23.127-.533.382-2.285.28-1.142-.636v-.353z"
            />
          </g>
          <g>
            <path
              fill="#fff"
              d="M90.85 175.05l2.64.812h2.742l.152 1.93-1.574 1.245-5.23.128-.533.379-2.284.28-1.143-.635v-3.062z"
            />
          </g>
          <g>
            <path
              fill="#134f70"
              d="M78.016 142.35v13.627l-1.306 3.732v10.817l1.118 1.574 2.243-.056 1.363-1.633 1.27-12.378 2.435-8.025v-5.18z"
            />
          </g>
          <g>
            <path
              fill="#00738e"
              d="M79.876 134.98l-2.046 7.005 1.02 1.98 5.1 3.2 2.005 12.798-.71 3.808-.053 12.087 1.83 1.117h2.691l1.625-1.981 1.116-16.452v-19.858l-1.116-2.991z"
            />
          </g>
          <g>
            <path
              fill="#573711"
              d="M80.21 133.31l-.34 1.67 1.14 1.267L86 138.073l5.333-.762-.685-2.818z"
            />
          </g>
          <g>
            <path
              fill="#2dcb75"
              d="M80.315 118.11l-2.13.37-1.878 2.299-.357 2.818 1.728 4.85 2.082 3.275v1.067l1.93 2.187 4.418 1.47 4.47-.609 1.27-6.044.102-4.267-2.552-3.452-2.124-1.295z"
            />
          </g>
          <g>
            <path
              fill="#fbbd9e"
              d="M81.104 116.096l-.094 1.466.476 1.18 2.252 1.087 2.513-.21.17-1.562.285-.876.379-1.675-3.58-.876z"
            />
          </g>
          <g>
            <path
              fill="#fbbd9e"
              d="M88.703 109.71l.696 2.277-.35.82.196.323-.196 1.675-1.198 2.38h-1.138l-.351-.944-1.212-.864.725-5.18z"
            />
          </g>
          <g>
            <path
              fill="#944e39"
              d="M88.65 109.445l.302 1.508-.915 1.906-.187 1.067-.142.227-.204-.38-.482.025-.661 1.117v1.32l-1.295 1.194-2.818-.152-1.593-1.657-.895-2.863v-2.565l1.931-2.284 2.918-1.118 3.683.407.761 1.243z"
            />
          </g>
          <g>
            <path fill="#693121" d="M84.2 109.64l1.142.33 3.303-.52z" />
          </g>
          <g>
            <path
              fill="#fbbd9e"
              d="M93.161 131.37l.97 3.566 4.115.457.457-1.486 1.181-.952.8-.972.457.82-.342.495-.421.388h1.602l1.921-1.664.345.246-.82 2.914-1.142 1.24-2.59 1.103-5.009 1.62-3.41-.285-2.575-5.419 1.72-2.621z"
            />
          </g>
          <g>
            <path
              fill="#2dcb75"
              d="M88.827 123.22l-1.7.914-.637 3.098 1.955 6.171 2.03.28 2.11-.915.863-1.4-1.752-5.913-1.142-1.474z"
            />
          </g>
          <g>
            <path
              fill="#2dcb75"
              d="M80.21 118.304l1.717 1.79 2.855.572 2.486.114v-2.019l-2.125.267-2.877-.724-1.485-1.124z"
            />
          </g>
          <g>
            <path
              fill="#573711"
              d="M40.93 80.649l5.693 3.287-5.27 9.83-5.693-3.288 4.922-9.948z"
            />
          </g>
          <g>
            <path
              fill="#7b4a19"
              d="M46.63 83.939l-4.923 9.948-.347-.12 4.924-9.947z"
            />
          </g>
          <g>
            <path
              fill="#a39c95"
              d="M45.596 83.353l1.028.593v-.044l-.377-.741-.41-.087z"
            />
          </g>
          <g>
            <path
              fill="#fff"
              d="M46.25 83.094l-5.71-3.234-1.09.675-.409 1.99-1.013 2.437-2.578 4.899 5.73 3.305.092-.23 2.462-4.706.999-2.391.393-2.002 1.072-.667z"
            />
          </g>
          <g>
            <path
              fill="#d1d1d1"
              d="M37.65 86.52l4.994 2.883.235-.452-4.99-2.881z"
            />
          </g>
          <g>
            <path
              fill="#d1d1d1"
              d="M38.01 85.821l4.994 2.882.236-.452-4.992-2.881z"
            />
          </g>
          <g>
            <path
              fill="#d1d1d1"
              d="M40.876 85.965l-2.297-1.325-.199.473 2.257 1.303z"
            />
          </g>
          <g>
            <path
              fill="#d1d1d1"
              d="M36.17 89.32l4.992 2.883.237-.452-4.99-2.881z"
            />
          </g>
          <g>
            <path
              fill="#d1d1d1"
              d="M36.54 88.621l4.992 2.882.237-.45-4.994-2.883z"
            />
          </g>
          <g>
            <path
              fill="#d1d1d1"
              d="M38.69 84.383l4.99 2.883.2-.475-4.992-2.881z"
            />
          </g>
          <g>
            <path
              fill="#d1d1d1"
              d="M40.02 80.585l4.987 2.879.492-.304-4.987-2.88z"
            />
          </g>
          <g>
            <path
              fill="#d1d1d1"
              d="M43.292 85.54l-4.104-2.37-.198.474 4.064 2.347z"
            />
          </g>
          <g>
            <path
              fill="#d1d1d1"
              d="M36.042 89.57l-.252.48 4.994 2.881.252-.48z"
            />
          </g>
          <g>
            <path
              fill="#d1d1d1"
              d="M39.379 82.718l-.079.187 4.992 2.883.075-.184.067-.323-4.99-2.881z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M39.448 80.53l-.408 1.99 5.661 3.274.045-.07.358-1.787.005-.143z"
            />
          </g>
          <g>
            <path
              fill="#d1d1d1"
              d="M41.177 93.162l-.067-.036 2.577-4.898 1.014-2.433.407-2 1.14-.705.412.832-.034.017-.41-.74-1.04.644-.404 1.974-1.017 2.443z"
            />
          </g>
          <g>
            <path
              fill="#fbbd9e"
              d="M14 85.129l.354 2.284 1.194 2.309 6.522 5 .864 1.211h1.7l1.193-.525.761-2.182-.113-1.448-1.26.3-1.494.69-2.893-3.299-2.033-2.945 1.123-.853.2-3.36-3.349-.271z"
            />
          </g>
          <g>
            <path
              fill="#c69695"
              d="M24.632 95.934l-.702-.365.563-.738 1.143-.421z"
            />
          </g>
          <g>
            <path fill="#c69695" d="M16.03 87.306l1.382.075 1.384-.862z" />
          </g>
          <g>
            <path fill="#c69695" d="M24.492 93.785v-.515l-.744-.515z" />
          </g>
          <g>
            <path
              fill="#f4efe9"
              d="M24.402 76.458l1.692.69-1.293 3.932-4.396 4.745-1.956-.613-1.514-1.725-.185-1.633 4.105-4.6 1.66-.844z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M22.95 132.494v.324l.635.964h1.548l.94-1.7.202-1.168 1.472-1.727.306-1.623v-.324l-2.438 2.36z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M23.13 130.052l-.18 2.438.635.964h1.548l.94-1.7.202-1.168 1.472-1.726.306-1.625-.28-.508-.989-.227z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M23.565 101.16v13.622l-1.305 3.73v10.812l1.117 1.573 2.24-.056 1.364-1.631 1.269-12.374 2.438-8.021v-5.176z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M35.224 137.341l6.498-.611v.351l-1.573 1.244-5.23.127-.531.38-2.286.28-1.142-.635v-.352z"
            />
          </g>
          <g>
            <path
              fill="#505666"
              d="M36.188 133.99l2.64.812h2.74l.154 1.929-1.573 1.243-5.23.128-.531.38-2.285.278-1.143-.634v-3.06z"
            />
          </g>
          <g>
            <path
              fill="#292d35"
              d="M25.213 93.94l-2.043 7 1.022 1.98 5.094 3.2 2.007 12.792-.711 3.807-.05 12.082 1.826 1.116h2.69l1.625-1.98 1.117-16.444v-19.85l-1.117-2.99z"
            />
          </g>
          <g>
            <path
              fill="#0a0808"
              d="M25.607 92.19l-.387 1.75 1.142 1.266 4.986 1.828 5.332-.762-.688-2.817z"
            />
          </g>
          <g>
            <path
              fill="#fff"
              d="M25.621 77.24l-2.129.37-1.876 2.298-.356 2.816 1.725 4.848 2.082 3.275v1.065l1.927 2.186 4.418 1.47 4.468-.61 1.685-5.922v-4.188l-.803-1.574-1.218-1.7-2.87-1.859z"
            />
          </g>
          <g>
            <path
              fill="#fbbd9e"
              d="M40.67 88.578l.484-1.015.855-.483 1.099.584 1.499.164.99.33-.09.648-.698.178.456.634-.582.917-1.548.936-1.43-.406-.678-1.295z"
            />
          </g>
          <g>
            <path
              fill="#fbbd9e"
              d="M37.908 90.815l.189.657 2.575-2.892 1.092.151.248 1.663-2.201 4.86-1.452 2.132h-3.2l-1.103-2.251-.936-3.13 1.004-1.776 2.209-.43z"
            />
          </g>
          <g>
            <path
              fill="#c69695"
              d="M41.778 88.814l.235 1.58.197-.43-.33-1.22-.47-.254z"
            />
          </g>
          <g>
            <path
              fill="#fbbd9e"
              d="M32.075 76.112l-.23.648v1.408l-1.446.4-2.799-.572-1.18-.97.098-.666.047-2.128.826-.804 3.807-.228 1.333.78z"
            />
          </g>
          <g>
            <path
              fill="#fbbd9e"
              d="M32.933 67.487l.952.674.761 1.464v1.295l-.265.781.265.837-.584 2.69-.844.89h-1.141l-1.156-1.225-1.111-3.401.495-4.302z"
            />
          </g>
          <g>
            <path
              fill="#a39c95"
              d="M31.87 69.313v-.964l1.064-.864-.787-1.142-2.106-.203-2.081.838-1.75 1.446-.739 1.397-.151 2.208.762 1.675 1.115 1.219 1.549.634 1.39-.108.786-.558.516-1.01.937-1.243h.407l.202.711.584-.255v-1.522l.203-1.422-1.142-.305z"
            />
          </g>
          <g>
            <path
              fill="#f4efe9"
              d="M34.598 81.9l-1.808.68-1.04 2.984 1.119 6.374 1.974.546 2.209-.626 1.042-1.273-.95-6.09-.938-1.611z"
            />
          </g>
          <g>
            <path
              fill="#f4efe9"
              d="M25.63 77.243l1.714 1.79 2.853.571 2.485.115V77.7l-2.12.267-2.876-.724-1.485-1.123z"
            />
          </g>
          <g>
            <path fill="#c69695" d="M38.393 95.132l.23-1.695-.527-1.967z" />
          </g>
          <g>
            <path
              fill="#e6fbff"
              d="M78.9 9.191l-6.19-3.577 5.358-3.094 6.19 3.578z"
            />
          </g>
          <g>
            <path
              fill="#c9e7ed"
              d="M78.905 5.625v3.572l-6.195-3.58-4.37 2.529v7.147l4.37 7.579 27.323 15.77 4.373-2.526v-7.147l-4.373-7.578-6.192-3.575v-3.574l-4.375-7.566-6.19-3.576z"
            />
          </g>
          <g>
            <path
              fill="#e6fbff"
              d="M88.638 0L83.28 3.096l6.19 3.576 5.36-3.102z"
            />
          </g>
          <g>
            <path
              fill="#e6fbff"
              d="M100.03 21.385l-6.19-3.575 5.359-3.09 6.188 3.572z"
            />
          </g>
          <g>
            <path
              fill="#bedee5"
              d="M93.845 14.238L89.47 6.673l5.36-3.103 4.373 7.575z"
            />
          </g>
          <g>
            <path
              fill="#bedee5"
              d="M104.403 28.961l-4.373-7.578 5.358-3.093 4.37 7.58z"
            />
          </g>
          <g>
            <path
              fill="#9fcfd6"
              d="M99.198 14.725l-5.358 3.092v-3.574l5.358-3.093z"
            />
          </g>
          <g>
            <path
              fill="#9fcfd6"
              d="M109.766 33.024l-5.356 3.092V28.97l5.356-3.09z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M76.859 24.715l-.349-1.59 2.402-.895v1.297z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M71.986 14.973h-.517l-.609-.648 2.054-1.185 1.122.648z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M79.996 23.97l-.796-1.378 2.056-1.182.792 1.376z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M79.2 22.586l.324-.71.865-.847 1.184-.339-.317.715z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M73.8 18.228l-.21-2.183 2.262-.945v1.94l-.727.693z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M76.485 21.298l-1.535-1.085.604-.838 1.445-.345.952.546-.047 1.004z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M73.873 13.354l-.514-.378-.279-.998 2.052-1.188.794 1.376z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M74.652 13.433l-.782-.076 2.054-1.187.78.078z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M79.2 22.589l.797 1.378-1.566.902-.792-1.375-.779-.082v1.304l-2.21-1.282v-1.299l-.781-.813-.793.455-1.562-2.707.793-.465-.32-1.08-1.127-.645v-2.557l1.126.648.321-.712-.793-1.375 1.562-.904.793 1.377.782.076v-1.299l2.21 1.274v1.304l.778.816.792-.457 1.566 2.71-.796.463.324 1.078 1.124.647v2.56l-1.124-.65zm-1.49-3.975l-1.145-1.98-1.618-.932-1.145.66v1.872l1.145 1.978 1.618.936 1.145-.663z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M76.86 13.41l-2.21-1.274 2.052-1.186 2.21 1.277z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M79.996 17.784l-1.566-2.71 2.053-1.184 1.565 2.709z"
            />
          </g>
          <g>
            <path fill="#cc934c" d="M80.65 22.531v-2.56l2.05-1.181v2.551z" />
          </g>
          <g>
            <path fill="#cc934c" d="M76.86 14.707v-1.304l2.053-1.183v1.304z" />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M79.524 19.317l-.324-1.079 2.056-1.188.317 1.082z"
            />
          </g>
          <g>
            <path
              fill="#e09d38"
              d="M74.946 20.207L73.8 18.228l2.052-1.188 1.142 1.984z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M77.639 15.528l-.779-.814 2.054-1.184.777.813z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M80.654 19.972l-1.124-.647 2.049-1.185 1.125.65z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M85.112 29.978l-.242-1.103 1.67-.625v.902z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M81.724 23.195h-.358l-.426-.449 1.428-.826.784.45z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M87.293 29.45l-.553-.956 1.428-.824.55.958z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M86.74 28.495l.225-.497.598-.584.827-.234-.223.491z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M82.984 25.466l-.144-1.517 1.572-.659v1.352l-.507.484z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M84.853 27.599l-1.073-.754.424-.586 1.002-.239.663.383-.033.692z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M83.032 22.07l-.355-.263-.197-.696 1.429-.821.55.956z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M83.03 22.074l.542.056L85 21.303l-.542-.053z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M86.74 28.5l.554.957-1.086.626-.557-.953-.54-.054v.903l-1.537-.892v-.903l-.542-.569-.55.322-1.09-1.885.556-.32-.225-.753-.783-.453v-1.774l.783.45.225-.492-.557-.961 1.09-.629.55.959.543.054v-.903l1.537.885v.905l.54.571.557-.32 1.089 1.888-.556.318.224.75.782.454v1.774l-.782-.452zm-1.033-2.762l-.8-1.38-1.129-.65-.799.459v1.301l.8 1.38 1.128.65.8-.46z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M85.107 22.11l-1.537-.885 1.428-.825 1.538.889z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M87.289 25.154L86.2 23.265l1.427-.825 1.084 1.888z"
            />
          </g>
          <g>
            <path fill="#cc934c" d="M87.74 28.452v-1.775l1.426-.827v1.783z" />
          </g>
          <g>
            <path fill="#cc934c" d="M85.11 23.016v-.905l1.429-.821v.905z" />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M86.965 26.225l-.225-.751 1.427-.824.223.748z"
            />
          </g>
          <g>
            <path
              fill="#e09d38"
              d="M83.776 26.844l-.796-1.38 1.429-.824.793 1.38z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M85.65 23.582l-.54-.571 1.429-.821.539.567z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M87.742 26.68l-.782-.454 1.427-.826.78.453z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M85.924 22.095l-.394-1.756 2.662-.989v1.436z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M80.533 11.313h-.571l-.672-.717 2.27-1.316 1.243.717z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M89.392 21.264l-.882-1.524 2.271-1.31.885 1.526z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M88.51 19.745l.356-.788.96-.931 1.313-.376-.357.785z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M82.536 14.918l-.226-2.413 2.498-1.045v2.148l-.807.766z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M85.513 18.318l-1.703-1.201.672-.926 1.598-.381 1.051.605-.044 1.104z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M82.621 9.528l-.567-.42-.314-1.105 2.271-1.313.88 1.53z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M83.48 9.615l-.86-.086 2.27-1.309.862.084z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M88.516 19.742l.884 1.524-1.732.998-.877-1.525-.86-.08v1.435l-2.45-1.415v-1.437l-.86-.905-.882.507-1.73-2.997.88-.51-.355-1.197-1.244-.72v-2.823l1.244.716.355-.791-.88-1.523L81.74 8l.882 1.525.86.086V8.176l2.45 1.412v1.435l.86.907.877-.504L89.4 14.42l-.884.515.357 1.192 1.248.723v2.83l-1.248-.727zm-1.649-4.394L85.6 13.155l-1.791-1.03-1.268.726v2.068l1.268 2.192 1.791 1.037 1.267-.734z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M85.93 9.594L83.48 8.18l2.272-1.311L88.2 8.277z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M89.392 14.424L87.66 11.43l2.272-1.31 1.734 2.996z"
            />
          </g>
          <g>
            <path fill="#cc934c" d="M90.12 19.683v-2.83l2.27-1.313v2.83z" />
          </g>
          <g>
            <path fill="#cc934c" d="M85.93 11.022V9.587l2.268-1.317v1.44z" />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M88.866 16.126l-.356-1.193 2.271-1.313.358 1.199z"
            />
          </g>
          <g>
            <path
              fill="#e09d38"
              d="M83.808 17.113L82.54 14.92l2.273-1.31 1.266 2.197z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M86.79 11.93l-.86-.908 2.268-1.312.862.91z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M90.117 16.85l-1.247-.723 2.271-1.307 1.247.716z"
            />
          </g>
          <g>
            <path
              fill="#d66b24"
              d="M94.507 34.485l-.377-1.69 2.56-.955v1.385z"
            />
          </g>
          <g>
            <path
              fill="#fdbd6f"
              d="M89.313 24.108h-.554l-.649-.693 2.188-1.265 1.198.696z"
            />
          </g>
          <g>
            <path
              fill="#fdb053"
              d="M97.847 33.69L97 32.224l2.184-1.264.849 1.466z"
            />
          </g>
          <g>
            <path
              fill="#d66b24"
              d="M97 32.22l.341-.756.923-.902 1.264-.362-.344.757z"
            />
          </g>
          <g>
            <path
              fill="#d66b24"
              d="M91.241 27.58l-.221-2.327 2.408-1.003v2.07l-.774.73z"
            />
          </g>
          <g>
            <path
              fill="#fdbd6f"
              d="M94.094 30.843l-1.634-1.15.645-.9 1.539-.363 1.013.584-.047 1.065z"
            />
          </g>
          <g>
            <path
              fill="#fdb053"
              d="M91.32 22.387l-.553-.406-.297-1.06 2.187-1.261.846 1.462z"
            />
          </g>
          <g>
            <path
              fill="#fdbd6f"
              d="M92.144 22.47l-.824-.085 2.183-1.265.83.085z"
            />
          </g>
          <g>
            <path
              fill="#ef8f2d"
              d="M97.005 32.228l.847 1.466-1.672.961-.844-1.47-.826-.075v1.38l-2.36-1.363v-1.379l-.825-.877-.85.49-1.666-2.886.849-.493-.344-1.152-1.204-.691v-2.724l1.204.693.344-.758-.85-1.469 1.667-.961.85 1.467.824.085v-1.389l2.361 1.363v1.388l.826.872.844-.494 1.672 2.893-.847.487.341 1.156 1.197.689v2.728l-1.197-.694zm-1.59-4.236l-1.224-2.113-1.723-.996-1.223.701v1.995l1.223 2.11 1.723.997 1.224-.707z"
            />
          </g>
          <g>
            <path
              fill="#fdbd6f"
              d="M94.502 22.442L92.14 21.08l2.19-1.26 2.355 1.362z"
            />
          </g>
          <g>
            <path
              fill="#fdbd6f"
              d="M97.852 27.105L96.18 24.21l2.188-1.261 1.67 2.89z"
            />
          </g>
          <g>
            <path fill="#d66b24" d="M98.54 32.158V29.43l2.187-1.26v2.724z" />
          </g>
          <g>
            <path fill="#d66b24" d="M94.51 23.828V22.44l2.183-1.259v1.383z" />
          </g>
          <g>
            <path
              fill="#fdb053"
              d="M97.341 28.747L97 27.592l2.184-1.262.343 1.152z"
            />
          </g>
          <g>
            <path
              fill="#e09d38"
              d="M92.464 29.693l-1.224-2.114 2.188-1.259 1.218 2.11z"
            />
          </g>
          <g>
            <path
              fill="#fdb053"
              d="M95.336 24.697l-.826-.872 2.184-1.265.825.875z"
            />
          </g>
          <g>
            <path
              fill="#fdbd6f"
              d="M98.537 29.435l-1.197-.69 2.186-1.265 1.198.694z"
            />
          </g>
          <g>
            <path
              fill="#e6fbff"
              d="M118.068 11.954L114 9.603l3.522-2.033 4.068 2.351z"
            />
          </g>
          <g>
            <path
              fill="#c9e7ed"
              d="M118.073 9.609v2.347l-4.071-2.353-2.872 1.663v4.697l2.872 4.98 17.955 10.363 2.874-1.661v-4.696l-2.874-4.98-4.069-2.349v-2.349l-2.875-4.97-4.068-2.351z"
            />
          </g>
          <g>
            <path
              fill="#e6fbff"
              d="M125.008 10.295l-4.068-2.35 3.522-2.035 4.07 2.346z"
            />
          </g>
          <g>
            <path
              fill="#e6fbff"
              d="M131.948 19.97l-4.068-2.35 3.523-2.03 4.066 2.347z"
            />
          </g>
          <g>
            <path
              fill="#bedee5"
              d="M127.885 15.27L125.01 10.3l3.523-2.039 2.873 4.979z"
            />
          </g>
          <g>
            <path
              fill="#bedee5"
              d="M134.823 24.942l-2.873-4.98 3.52-2.032 2.874 4.98z"
            />
          </g>
          <g>
            <path
              fill="#9fcfd6"
              d="M131.4 15.579l-3.52 2.033v-2.35l3.52-2.032z"
            />
          </g>
          <g>
            <path
              fill="#9fcfd6"
              d="M138.35 27.606l-3.52 2.031v-4.696l3.52-2.031z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M116.73 22.153l-.23-1.047 1.578-.586v.852z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M113.52 15.744h-.338l-.402-.425 1.35-.779.738.425z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M118.784 21.662l-.524-.905 1.351-.777.52.904z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M118.26 20.756l.212-.467.57-.557.778-.222-.209.47z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M114.718 17.895l-.138-1.434 1.486-.621v1.274l-.477.456z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M116.478 19.9l-1.008-.712.397-.551.949-.227.626.36-.03.66z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M114.762 14.685l-.34-.249-.182-.655 1.348-.781.521.904z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M114.76 14.686l.512.049 1.349-.779-.514-.05z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M118.261 20.75l.525.905-1.03.593-.522-.904-.51-.053v.858l-1.452-.844v-.853l-.513-.534-.522.299-1.027-1.779.521-.307-.21-.708-.741-.425v-1.68l.74.427.211-.47-.521-.902 1.027-.593.522.904.513.049v-.854l1.452.838v.858l.51.535.522-.3 1.03 1.781-.525.304.213.708.738.426v1.682l-.738-.427zm-.983-2.611l-.75-1.302-1.06-.613-.752.435v1.23l.751 1.3 1.06.614.751-.435z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M116.724 14.717l-1.454-.837 1.348-.78 1.454.84z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M118.79 17.6l-1.03-1.781 1.349-.779 1.028 1.78z"
            />
          </g>
          <g>
            <path fill="#cc934c" d="M119.22 20.72v-1.683l1.348-.777v1.676z" />
          </g>
          <g>
            <path fill="#cc934c" d="M116.73 15.574v-.858l1.348-.776v.856z" />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M118.472 18.61l-.212-.71 1.351-.78.209.71z"
            />
          </g>
          <g>
            <path
              fill="#e09d38"
              d="M115.473 19.191l-.753-1.3 1.348-.781.75 1.304z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M117.24 16.113l-.51-.535 1.348-.778.512.535z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M119.22 19.034l-.74-.425 1.348-.779.74.428z"
            />
          </g>
          <g>
            <path fill="#cc934c" d="M122.15 25.605l-.16-.725 1.097-.41v.593z" />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M119.4 20.863l.28.296h.236l.937-.543-.516-.295z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M123.583 25.27l-.363-.628.937-.542.362.63z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M123.759 23.922l-.392.383-.147.328.936-.542.147-.324z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M120.654 21.652l.095.997.604-.224.333-.317v-.888z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M121.549 23.168l-.279.386.705.494.647-.33.02-.455-.436-.251z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M120.783 20.42l-.234-.173-.129-.457.939-.54.362.629z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M120.78 20.413l.356.035.94-.543-.358-.033z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M123.212 24.641l.363.628-.714.412-.366-.626-.353-.037v.594l-1.01-.586v-.593l-.357-.375-.363.213-.716-1.239.367-.21-.148-.495-.515-.298v-1.165l.515.294.148-.324-.367-.63.716-.414.363.63.356.036v-.594l1.011.582v.594l.353.376.366-.21.716 1.24-.365.21.148.493.512.298v1.166l-.512-.298zm-.685-1.816l-.522-.906-.739-.428-.52.301v.856l.52.907.739.428.522-.304z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M122.14 20.443l-1.01-.582.94-.541 1.008.583z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M123.576 22.444l-.716-1.24.937-.544.713 1.24z"
            />
          </g>
          <g>
            <path fill="#cc934c" d="M123.871 23.444v1.166l.938-.538V22.9z" />
          </g>
          <g>
            <path fill="#cc934c" d="M122.141 20.439v.594l.938-.54v.001-.594z" />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M123.22 22.653l.148.494.935-.543-.146-.49z"
            />
          </g>
          <g>
            <path
              fill="#e09d38"
              d="M121.272 23.548l-.522-.907.937-.541.522.906z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M122.14 21.032l.354.375.939-.541-.356-.373z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M123.36 23.143l.512.298.938-.545-.514-.297z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M122.678 20.434l-.258-1.154 1.749-.65v.944z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M119.138 13.346h-.377l-.441-.47 1.49-.866.819.472z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M124.961 19.892l-.581-1.001 1.494-.861.58 1.002z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M124.38 18.887l.234-.517.631-.613.862-.247-.233.516z"
            />
          </g>
          <g>
            <path
              fill="#cc934c"
              d="M120.459 15.712l-.149-1.586 1.643-.686v1.412l-.53.502z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M122.409 17.948l-1.119-.788.443-.608 1.05-.252.69.397-.03.726z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M120.509 12.175l-.373-.275-.206-.727 1.494-.863.579 1.005z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M120.51 12.172l.566.056 1.492-.86-.567-.056z"
            />
          </g>
          <g>
            <path
              fill="#e3bd5e"
              d="M124.382 18.886l.581 1-1.14.656-.575-1.002-.566-.052v.943l-1.61-.93v-.945l-.565-.594-.579.334-1.136-1.97.578-.335-.234-.787-.816-.473v-1.855l.816.47.234-.52-.578-1 1.136-.656.579 1.001.565.057v-.944l1.61.93v.941l.566.597.576-.332 1.14 1.967-.582.34.234.783.819.475v1.858l-.819-.475zm-1.084-2.888l-.833-1.442-1.177-.678-.833.478v1.36l.833 1.442 1.177.682.833-.483z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M122.68 12.21l-1.61-.927 1.494-.863 1.606.926z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M124.96 15.389l-1.14-1.968 1.494-.861 1.139 1.969z"
            />
          </g>
          <g>
            <path fill="#cc934c" d="M125.44 18.843v-1.859l1.493-.864v1.86z" />
          </g>
          <g>
            <path fill="#cc934c" d="M122.68 13.158v-.943l1.49-.865v.946z" />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M124.614 16.507l-.234-.783 1.494-.864.233.788z"
            />
          </g>
          <g>
            <path
              fill="#e09d38"
              d="M121.283 17.163l-.833-1.441 1.494-.862.832 1.444z"
            />
          </g>
          <g>
            <path
              fill="#ffc654"
              d="M123.246 13.75l-.566-.598 1.49-.862.566.598z"
            />
          </g>
          <g>
            <path
              fill="#fbe979"
              d="M125.44 16.984l-.82-.475 1.493-.859.82.47z"
            />
          </g>
          <g>
            <path
              fill="#d66b24"
              d="M128.317 28.568l-.247-1.11 1.683-.628v.91z"
            />
          </g>
          <g>
            <path
              fill="#fdbd6f"
              d="M124.9 21.746h-.363l-.427-.454 1.439-.832.786.457z"
            />
          </g>
          <g>
            <path
              fill="#fdb053"
              d="M130.517 28.045l-.557-.963 1.434-.832.56.963z"
            />
          </g>
          <g>
            <path
              fill="#d66b24"
              d="M129.96 27.088l.224-.497.607-.593.83-.238-.227.497z"
            />
          </g>
          <g>
            <path
              fill="#d66b24"
              d="M126.176 24.037l-.146-1.53 1.584-.657v1.36l-.51.48z"
            />
          </g>
          <g>
            <path
              fill="#fdbd6f"
              d="M128.052 26.175l-1.072-.756.422-.59 1.012-.239.667.384-.03.7z"
            />
          </g>
          <g>
            <path
              fill="#fdb053"
              d="M126.23 20.623l-.365-.268-.195-.697 1.436-.828.556.961z"
            />
          </g>
          <g>
            <path
              fill="#fdbd6f"
              d="M126.23 20.618l.539.055 1.44-.83-.547-.056z"
            />
          </g>
          <g>
            <path
              fill="#ef8f2d"
              d="M129.955 27.091l.556.964-1.098.63-.554-.966-.543-.049v.908l-1.552-.897v-.905l-.54-.577-.56.322-1.096-1.896.558-.324-.225-.757-.791-.455v-1.79l.79.456.226-.498-.558-.965 1.096-.632.56.964.54.057v-.914l1.552.895v.913l.543.573.554-.325 1.098 1.902-.556.32.224.759.787.454v1.791l-.787-.455zm-1.044-2.785l-.805-1.388-1.132-.655-.804.46v1.31l.804 1.388 1.132.654.805-.464z"
            />
          </g>
          <g>
            <path
              fill="#fdbd6f"
              d="M128.322 20.653l-1.552-.896 1.44-.827 1.547.894z"
            />
          </g>
          <g>
            <path
              fill="#fdbd6f"
              d="M130.52 23.72l-1.1-1.9 1.436-.83 1.1 1.899z"
            />
          </g>
          <g>
            <path fill="#d66b24" d="M130.97 27.041v-1.793l1.437-.828v1.79z" />
          </g>
          <g>
            <path fill="#d66b24" d="M128.32 21.571v-.913l1.435-.828v.91z" />
          </g>
          <g>
            <path
              fill="#fdb053"
              d="M130.184 24.8l-.224-.76 1.434-.83.227.758z"
            />
          </g>
          <g>
            <path
              fill="#e09d38"
              d="M126.975 25.426l-.805-1.389 1.439-.827.8 1.386z"
            />
          </g>
          <g>
            <path
              fill="#fdb053"
              d="M128.863 22.144l-.543-.572 1.435-.832.541.575z"
            />
          </g>
          <g>
            <path
              fill="#fdbd6f"
              d="M130.967 25.254l-.787-.452 1.437-.832.788.456z"
            />
          </g>
          <g>
            <path
              fill="#e6fbff"
              d="M147.413 43.563l-7.613-4.396 6.59-3.807 7.612 4.401z"
            />
          </g>
          <g>
            <path
              fill="#c9e7ed"
              d="M147.421 39.166v4.392l-7.617-4.4-5.374 3.11v8.787l5.374 9.32 33.6 19.396 5.376-3.107v-8.786l-5.376-9.32-7.616-4.4-.001-4.39-5.377-9.31-7.612-4.398z"
            />
          </g>
          <g>
            <path
              fill="#e6fbff"
              d="M160.404 40.466l-7.614-4.399 6.591-3.807 7.612 4.395z"
            />
          </g>
          <g>
            <path
              fill="#e6fbff"
              d="M173.403 58.558l-7.613-4.395 6.59-3.803 7.614 4.397z"
            />
          </g>
          <g>
            <path
              fill="#bedee5"
              d="M165.786 49.77l-5.376-9.31 6.59-3.81 5.377 9.316z"
            />
          </g>
          <g>
            <path
              fill="#bedee5"
              d="M173.4 58.562l5.377 9.32 6.587-3.805-5.373-9.317z"
            />
          </g>
          <g>
            <path
              fill="#afd4da"
              d="M172.371 50.362l-6.59 3.803-.001-4.39 6.591-3.805z"
            />
          </g>
          <g>
            <path fill="#afd4da" d="M178.78 67.885v8.786l6.587-3.804V64.08z" />
          </g>
          <g>
            <path fill="#47a05e" d="M151.81 57.542v-4.793l2.023-1.169v4.795z" />
          </g>
          <g>
            <path
              fill="#47a05e"
              d="M154.525 54.313l-4.395-10.344 2.025-1.169 4.395 10.343z"
            />
          </g>
          <g>
            <path
              fill="#2dcb75"
              d="M150.13 43.97l-4.4 5.265 2.71 1.566v4.787l3.377 1.954V52.75l2.707 1.565z"
            />
          </g>
          <g>
            <path
              fill="#ff958d"
              d="M155.228 60.771l-2.708-1.562 2.024-1.169 2.707 1.563z"
            />
          </g>
          <g>
            <path
              fill="#e8583e"
              d="M158.604 62.715v-4.793l-3.38-1.952v4.797l-2.704-1.56 4.393 10.338 4.397-5.263z"
            />
          </g>
          <g>
            <path
              fill="#ff958d"
              d="M158.6 57.928l-3.38-1.952 2.024-1.166 3.38 1.952z"
            />
          </g>
          <g>
            <path
              fill="#ff958d"
              d="M161.306 64.287L158.6 62.72l2.023-1.17 2.708 1.566z"
            />
          </g>
          <g>
            <path fill="#bf3a28" d="M158.6 62.72v-4.794l2.024-1.166v4.79z" />
          </g>
          <g>
            <path
              fill="#bf3a28"
              d="M156.91 69.544l4.397-5.263 2.025-1.171-4.397 5.264z"
            />
          </g>
          <g>
            <g>
              <path
                fill="#0a0808"
                d="M65.898 127.016H57.92l-2.44-6.675 6.787-2.661 6.62 2.716z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M86.086 115.358L65.9 127.012v-5.827l8.419-7.96 11.767-3.695z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M86.09 109.532v-4.608L69.543 95.37h-7.978L41.38 107.023v4.609l16.546 9.555h7.978z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M57.926 127.011l-16.546-9.553v-5.828l16.546 9.555z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M65.949 125.678h-8.212l-2.507-17.84 6.979-2.738 6.815 2.794z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M86.72 113.687l-20.77 11.992v-16.968l8.66-8.188 12.11-3.803z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M86.72 96.714V91.97l-17.028-9.83h-8.208L40.71 94.131v4.743l17.025 9.83h8.214z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M57.735 125.68l-17.025-9.834V98.88l17.025 9.83z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M65.898 108.14H57.92l-2.44-6.67 6.787-2.66 6.62 2.715z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M86.086 96.488L65.9 108.14v-5.827l8.419-7.955 11.767-3.699z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M86.09 90.659v-4.605L69.543 76.5h-7.978L41.38 88.156v4.606l16.546 9.552h7.978z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M57.926 108.138L41.38 98.586V92.76l16.546 9.552z"
              />
            </g>
            <g>
              <path
                fill="#dde65c"
                d="M57.927 108.142l-6.107-3.527v-2.765l6.107 3.526z"
              />
            </g>
            <g>
              <path d="M67.396 100.296l-11.33.335-14.686-8.484v5.83l16.546 9.554h7.978L86.09 95.877V90.05z" />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M65.949 106.426h-8.212L55.23 95.5l6.979-2.739 6.815 2.794z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M86.72 94.43l-20.77 11.99V96.36l8.66-8.187 12.11-3.803z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M86.72 84.372V79.63L69.691 69.8h-8.208L40.71 81.793v4.737l17.025 9.833h8.214z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M57.735 106.421L40.71 96.592V86.53l17.025 9.832z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M65.898 95.804H57.92l-2.44-6.673 6.787-2.661 6.62 2.717z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M86.086 84.146L65.9 95.8V89.97l8.419-7.954 11.767-3.697z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M86.09 78.322v-4.608L69.543 64.16h-7.978L41.38 75.815v4.604l16.546 9.554h7.978z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M57.926 95.802L41.38 86.25V80.42l16.546 9.554z"
              />
            </g>
            <g>
              <path
                fill="#dde65c"
                d="M57.927 95.804l-6.107-3.526V89.51l6.107 3.528z"
              />
            </g>
            <g>
              <path d="M67.396 87.957l-11.33.334-14.686-8.483v5.83l16.546 9.55h7.978L86.09 83.538V77.71z" />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M65.952 94.151h-8.213v-9.213L55.75 80.77l13.276 2.507z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M86.72 82.162L65.95 94.154v-10.06l8.66-8.186L86.72 72.1z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M86.72 72.1v-4.739L69.691 57.53h-8.208L40.71 69.523v4.738l17.025 9.833h8.214z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M40.71 74.26v10.062l17.025 9.83v-10.06z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M65.898 83.537H57.92l-2.44-6.677 6.787-2.66 6.62 2.717z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M86.086 71.876L65.9 83.532v-5.827l8.419-7.958 11.767-3.697z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M86.09 66.053v-4.606L69.543 51.89h-7.978L41.38 63.546v4.606l16.546 9.556h7.978z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M57.926 83.534L41.38 73.98v-5.83l16.546 9.556z"
              />
            </g>
            <g>
              <path
                fill="#dde65c"
                d="M57.927 83.535l-6.107-3.528V77.24l6.107 3.526z"
              />
            </g>
            <g>
              <path d="M67.396 75.681l-11.33.33-14.686-8.48v5.827l16.546 9.556h7.978L86.09 71.26v-5.83z" />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M65.949 81.92h-8.212L55.23 70.987l6.979-2.737 6.815 2.794z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M86.72 69.93L65.95 81.923V71.86l8.66-8.188 12.11-3.803z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M86.72 59.864v-4.74L69.691 45.29h-8.208L40.71 57.284v4.74l17.025 9.83h8.214z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M57.735 81.924L40.71 72.09V62.03l17.025 9.831z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M65.898 71.297H57.92l-2.44-6.677 6.787-2.66 6.62 2.717z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M86.086 59.646L65.9 71.302v-5.827l8.419-7.958 11.767-3.697z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M86.09 53.813v-4.606L69.543 39.65h-7.978L41.38 51.306v4.606l16.546 9.556h7.978z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M57.926 71.294L41.38 61.74v-5.83l16.546 9.556z"
              />
            </g>
            <g>
              <path
                fill="#dde65c"
                d="M57.927 71.295l-6.107-3.528V65l6.107 3.526z"
              />
            </g>
            <g>
              <path d="M67.396 63.451l-11.33.33-14.686-8.48v5.827l16.546 9.556h7.978L86.09 59.03V53.2z" />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M65.949 69.69h-8.212L55.23 58.757l6.979-2.737 6.815 2.794z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M86.72 57.69L65.95 69.683V59.62l8.66-8.188 12.11-3.803z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M86.72 47.634v-4.74L69.691 33.06h-8.208L40.71 45.054v4.74l17.025 9.83h8.214z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M57.735 69.684L40.71 59.85V49.79l17.025 9.831z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M57.834 71.819l-.773.446L39.43 62.086l.774-.446z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M57.835 74.358l-.774.446-.531-1.493.531-1.045.774-.446z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M57.061 72.259L39.43 62.08v2.54l17.631 10.177z"
              />
            </g>
            <g>
              <path
                fill="#574943"
                d="M57.834 60.468l-.773.447-17.631-10.18.774-.445z"
              />
            </g>
            <g>
              <path fill="#0a0808" d="M57.064 60.907V72.26l.772-.446V60.46z" />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M57.061 60.909L39.43 50.73v11.352L57.061 72.26z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M56.791 71.641v.158L39.7 61.932V51.2l.137.079V61.85z"
              />
            </g>
            <g>
              <path
                fill="#8ccccb"
                d="M56.794 61.068v10.574l-16.954-9.79V51.28z"
              />
            </g>
            <g>
              <g>
                <path
                  fill="#fff"
                  d="M41.08 61.526l10.831 6.254v-7.776L41.08 53.75z"
                />
              </g>
              <g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M51.936 67.71l-.018-.01v-7.693L41.15 53.789v-.019l10.786 6.227z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M51.259 67.323l-.018-.01V59.61l.018.01z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M50.58 66.933l-.02-.01V59.22l.02.01z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M49.9 66.543l-.02-.01V58.83l.02.01z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M49.23 66.153l-.02-.01V58.44l.02.01z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M48.549 65.763l-.018-.01V58.05l.018.01z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M47.878 65.373l-.016-.01V57.66l.016.01z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M47.2 64.983l-.02-.01V57.27l.02.01z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M46.52 64.593l-.02-.01V56.88l.02.01z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M45.848 64.203l-.016-.01V56.49l.016.01z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M45.169 63.813l-.018-.01V56.1l.018.01z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M44.499 63.413l-.018-.01V55.7l.018.01z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M43.82 63.023l-.02-.01V55.31l.02.01z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M43.14 62.633l-.02-.01V54.92l.02.01z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M42.47 62.243l-.02-.01V54.53l.02.01z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M41.789 61.853l-.018-.01V54.14l.018.01z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M51.927 60.791L41.15 54.57v-.019l10.777 6.22z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M51.927 61.571L41.15 55.35v-.019l10.777 6.222z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M51.927 62.351L41.15 56.13v-.019l10.777 6.222z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M51.927 63.141L41.15 56.92V56.9l10.777 6.222z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M51.927 63.921L41.15 57.7v-.019l10.777 6.222z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M51.927 64.701L41.15 58.48v-.019l10.777 6.222z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M51.927 65.481L41.15 59.26v-.019l10.777 6.222z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M51.927 66.261L41.15 60.04v-.019l10.777 6.222z"
                  />
                </g>
                <g>
                  <path
                    fill="#428aa8"
                    d="M51.927 67.041L41.15 60.82V60.8l10.777 6.222z"
                  />
                </g>
              </g>
            </g>
            <g>
              <path
                fill="#428aa8"
                d="M41.15 61.479v-7.833l-.13-.076v7.984l10.962 6.33v-.152z"
              />
            </g>
            <g>
              <path
                fill="#fdb053"
                d="M50.352 63.746v.285l.173.302.247.143.176-.102v-.284l-.176-.302-.247-.144z"
              />
            </g>
            <g>
              <path
                fill="#fdb053"
                d="M49.292 64.196v.285l.174.302.248.142.174-.1v-.284l-.174-.304-.248-.142z"
              />
            </g>
            <g>
              <path
                fill="#fdb053"
                d="M47.862 63.025v.285l.174.303.247.142.175-.101v-.284l-.175-.303-.247-.142z"
              />
            </g>
            <g>
              <path
                fill="#fdb053"
                d="M45.602 62.965v.285l.175.304.247.141.174-.101v-.284l-.174-.302-.247-.143z"
              />
            </g>
            <g>
              <path
                fill="#fdb053"
                d="M44.272 61.736v.285l.174.303.249.141.173-.1v-.284l-.173-.303-.249-.143z"
              />
            </g>
            <g>
              <path
                fill="#fdb053"
                d="M42.871 61.215v.285l.176.303.248.142.174-.101v-.284l-.174-.303-.248-.142z"
              />
            </g>
            <g>
              <path
                fill="#fdb053"
                d="M51.15 64.049v.354l.218.376.306.177.216-.125v-.354l-.216-.376-.306-.177z"
              />
            </g>
            <g>
              <path
                fill="#fdb053"
                d="M45.915 63.37l-1.34-1.27-1.4-.49-2.035-1.496.02-.144 2.01 1.48 1.413.493 1.32 1.254 2.253.02 1.41 1.207 1.048-.477.9.414.013.163-.856-.394-1.07.486-1.431-1.226z"
              />
            </g>
            <g>
              <path
                fill="#fa5c52"
                d="M44.452 59.956v.285l.175.302.246.142.175-.101V60.3l-.175-.303-.246-.142z"
              />
            </g>
            <g>
              <path
                fill="#fa5c52"
                d="M45.892 61.047v.285l.175.302.247.14.174-.1v-.284l-.174-.301-.247-.144z"
              />
            </g>
            <g>
              <path
                fill="#fa5c52"
                d="M49.232 61.746v.285l.175.303.246.141.175-.1v-.284l-.175-.303-.246-.143z"
              />
            </g>
            <g>
              <path
                fill="#fa5c52"
                d="M50.042 61.416v.285l.176.302.247.143.173-.103v-.283l-.173-.302-.247-.144z"
              />
            </g>
            <g>
              <path
                fill="#fa5c52"
                d="M51.15 61.589v.354l.218.376.306.177.216-.125v-.355l-.216-.376-.306-.176z"
              />
            </g>
            <g>
              <path
                fill="#fa5c52"
                d="M41.163 58.997l-.023-.167 3.602 1.337.012.009 1.437 1.102 3.335.685.795-.342 1.18.266.04.172-1.153-.26-.794.341-3.391-.694-.018-.013-1.441-1.107z"
              />
            </g>
            <g>
              <path
                fill="#5f9fad"
                d="M51.15 60.139v.354l.218.376.306.177.216-.126v-.354l-.216-.375-.306-.177z"
              />
            </g>
            <g>
              <path
                fill="#5f9fad"
                d="M49.032 59.725v.285l.173.303.248.142.175-.101v-.284l-.175-.303-.248-.142z"
              />
            </g>
            <g>
              <path
                fill="#5f9fad"
                d="M47.023 60.126v.284l.173.303.247.142.174-.1v-.285l-.174-.303-.247-.142z"
              />
            </g>
            <g>
              <path
                fill="#5f9fad"
                d="M44.701 58.895v.286l.176.302.248.142.174-.1v-.284l-.174-.304-.248-.142z"
              />
            </g>
            <g>
              <path
                fill="#5f9fad"
                d="M43.152 58.466v.285l.173.303.248.141.175-.101v-.284l-.175-.302-.248-.143z"
              />
            </g>
            <g>
              <path
                fill="#5f9fad"
                d="M43.454 58.866l-.018-.014-2.306-1.957.031-.135 2.291 1.944 1.537.402 2.335 1.27 1.983-.45 2.189.52.039.172-2.169-.515-1.998.453-2.367-1.287z"
              />
            </g>
            <g>
              <path
                fill="#fa5c52"
                d="M54.674 62.088l-.622-.36-.438.255v.717l.438.762.292.17v.628l-.517-.3-.757-1.313v-1.239l.757-.438 1.074.619z"
              />
            </g>
            <g>
              <path
                fill="#428aa8"
                d="M54.35 63.632l.33.19.439-.253v-.719l-.44-.761.227-.499.757 1.314v1.24l-.757.437-.556-.32z"
              />
            </g>
            <g>
              <path
                fill="#e95156"
                d="M54.674 65.738l-.622-.36-.438.255v.717l.438.762.292.17v.627l-.517-.3-.757-1.312v-1.24l.757-.437 1.074.619z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M54.68 67.467l-.33-.19v.629l.556.32.757-.438-.544-.575z"
              />
            </g>
            <g>
              <path
                fill="#ffec7a"
                d="M54.678 65.739l.44.762v.718l.544.574v-1.239l-.757-1.314z"
              />
            </g>
            <g>
              <path
                fill="#428aa8"
                d="M55.657 70.005l-2.587-1.493v-.732l2.587 1.493z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M65.898 58.915H57.92l-2.44-1.758 6.787-2.657 6.143 2.515z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M86.086 47.263L65.9 58.917v-.913l8.419-7.956 11.767-3.698z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M86.09 46.352v-4.606L69.543 32.19h-7.978L41.38 43.849v4.604l16.546 9.553h7.978z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M57.926 58.915L41.38 49.363v-.913l16.546 9.553z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M96.748 144.826H88.77l-2.44-6.675 6.786-2.661 6.621 2.716z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M116.926 133.166L96.74 144.82v-5.827l8.419-7.96 11.767-3.694z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M116.93 127.343v-4.61l-16.547-9.553h-7.979L72.22 124.833v4.609l16.546 9.555h7.978z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M88.766 144.821l-16.546-9.553v-5.828l16.546 9.555z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M96.788 143.478h-8.212l-2.506-17.84 6.979-2.738 6.815 2.795z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M117.562 131.487L96.79 143.479v-16.968l8.662-8.188 12.11-3.803z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M117.57 114.524v-4.744l-17.03-9.83h-8.206L71.56 111.941v4.743l17.025 9.83h8.212z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M88.585 143.48l-17.025-9.834V116.68l17.025 9.83z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M96.748 125.951H88.77l-2.44-6.672 6.786-2.659 6.621 2.715z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M116.926 114.298L96.74 125.95v-5.827l8.419-7.956 11.767-3.698z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M116.93 108.469v-4.606l-16.547-9.553h-7.979L72.22 105.966v4.604l16.546 9.553h7.978z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M88.766 125.95l-16.546-9.554v-5.826l16.546 9.552z"
              />
            </g>
            <g>
              <path
                fill="#dde65c"
                d="M88.767 125.953l-6.107-3.528v-2.765l6.107 3.526z"
              />
            </g>
            <g>
              <path d="M98.236 118.106l-11.33.335-14.686-8.484v5.83l16.546 9.555h7.978l20.187-11.655v-5.827z" />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M96.788 124.226h-8.212L86.07 113.3l6.979-2.739 6.815 2.794z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M117.562 112.24L96.79 124.23v-10.06l8.662-8.188 12.11-3.802z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M117.57 102.182V97.44l-17.03-9.83h-8.206L71.56 99.603v4.737l17.025 9.834h8.212z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M88.585 124.231l-17.025-9.829V104.34l17.025 9.833z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M96.748 113.614H88.77l-2.44-6.673 6.786-2.661 6.621 2.717z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M116.926 101.957L96.74 113.61v-5.828l8.419-7.954 11.767-3.698z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M116.93 96.132v-4.608l-16.547-9.554h-7.979L72.22 93.625v4.604l16.546 9.554h7.978z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M88.766 113.612L72.22 104.06V98.23l16.546 9.555z"
              />
            </g>
            <g>
              <path
                fill="#dde65c"
                d="M88.767 113.613l-6.107-3.525v-2.768l6.107 3.527z"
              />
            </g>
            <g>
              <path d="M98.236 105.758l-11.33.334-14.686-8.484v5.83l16.546 9.55h7.978l20.187-11.65V95.51z" />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M96.79 111.961h-8.212v-9.214L86.59 98.58l13.276 2.507z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M117.562 99.972L96.79 111.965v-10.062l8.662-8.185 12.11-3.808z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M117.57 89.91v-4.739l-17.03-9.831h-8.206L71.56 87.333v4.738l17.025 9.833h8.212z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M71.56 92.07v10.062l17.025 9.83v-10.06z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M96.748 101.338H88.77l-2.44-6.677L93.116 92l6.621 2.717z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M116.926 89.686L96.74 101.343v-5.828l8.419-7.958 11.767-3.697z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M116.93 83.863v-4.606L100.383 69.7h-7.979L72.22 81.356v4.606l16.546 9.556h7.978z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M88.766 101.344L72.22 91.789V85.96l16.546 9.556z"
              />
            </g>
            <g>
              <path
                fill="#dde65c"
                d="M88.767 101.346l-6.107-3.53V95.05l6.107 3.526z"
              />
            </g>
            <g>
              <path d="M98.236 93.491l-11.33.33-14.686-8.48v5.827l16.546 9.556h7.978L116.93 89.07v-5.83z" />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M96.788 99.73h-8.212L86.07 88.796l6.979-2.736 6.815 2.794z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M117.562 87.74L96.79 99.733V89.67l8.662-8.188 12.11-3.803z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M117.57 77.674v-4.74L100.54 63.1h-8.206L71.56 75.094v4.74l17.025 9.83h8.212z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M88.585 99.734L71.56 89.9V79.84l17.025 9.83z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M96.748 89.107H88.77l-2.44-6.677 6.786-2.66 6.621 2.717z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M116.926 77.446L96.74 89.102v-5.827l8.419-7.958 11.767-3.697z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M116.93 71.623v-4.606l-16.547-9.557h-7.979L72.22 69.116v4.606l16.546 9.556h7.978z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M88.766 89.104L72.22 79.55v-5.83l16.546 9.556z"
              />
            </g>
            <g>
              <path
                fill="#dde65c"
                d="M88.767 89.105l-6.107-3.528V82.81l6.107 3.526z"
              />
            </g>
            <g>
              <path d="M98.236 81.261l-11.33.33-14.686-8.48v5.827l16.546 9.556h7.978L116.93 76.84v-5.83z" />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M96.788 87.49h-8.212L86.07 76.557l6.979-2.737 6.815 2.794z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M117.562 75.5L96.79 87.493V77.43l8.662-8.188 12.11-3.803z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M117.57 65.444v-4.74l-17.03-9.834h-8.206L71.56 62.864v4.74l17.025 9.83h8.212z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M88.585 87.494L71.56 77.66V67.6l17.025 9.831z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M96.748 76.725H88.77l-2.44-1.758 6.786-2.657 6.144 2.515z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M116.926 65.073L96.74 76.727v-.913l8.419-7.956 11.767-3.698z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M116.93 64.152v-4.606l-16.547-9.556h-7.979L72.22 61.649v4.604l16.546 9.553h7.978z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M88.766 76.725L72.22 67.173v-.913l16.546 9.553z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M127.588 162.636h-7.978l-2.44-6.675 6.786-2.661 6.621 2.716z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M147.776 150.977L127.59 162.63v-5.827l8.419-7.96 11.767-3.694z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M147.779 145.152v-4.61l-16.547-9.552h-7.979l-20.183 11.652v4.608l16.545 9.556h7.977z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M119.615 162.631l-16.545-9.553v-5.828l16.545 9.555z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M127.638 161.288h-8.212l-2.506-17.84 6.979-2.738 6.815 2.794z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M148.412 149.297l-20.772 11.992v-16.968l8.662-8.188 12.11-3.803z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M148.41 132.334v-4.744l-17.03-9.83h-8.206L102.4 129.751v4.743l17.025 9.83h8.212z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M119.425 161.29l-17.025-9.834V134.49l17.025 9.83z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M127.588 143.76h-7.978l-2.44-6.67 6.786-2.66 6.621 2.715z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M147.776 132.109L127.59 143.76v-5.826l8.419-7.955 11.767-3.7z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M147.779 126.278v-4.605l-16.547-9.553h-7.979l-20.183 11.656v4.604l16.545 9.553h7.977z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M119.615 143.758l-16.545-9.552v-5.826l16.545 9.552z"
              />
            </g>
            <g>
              <path
                fill="#dde65c"
                d="M119.607 143.762l-6.107-3.527v-2.765l6.107 3.525z"
              />
            </g>
            <g>
              <path d="M129.075 135.916l-11.33.335-14.685-8.484v5.831l16.545 9.554h7.977l20.188-11.655v-5.827z" />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M127.638 142.034h-8.212l-2.506-10.926 6.979-2.738 6.815 2.793z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M148.412 130.05l-20.772 11.988V131.98l8.662-8.186 12.11-3.803z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M148.41 119.992v-4.742l-17.03-9.83h-8.206L102.4 117.413v4.737l17.025 9.833h8.212z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M119.425 142.04l-17.025-9.828V122.15l17.025 9.832z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M127.588 131.416h-7.978l-2.44-6.675 6.786-2.661 6.621 2.717z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M147.776 119.767L127.59 131.42v-5.829l8.419-7.954 11.767-3.698z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M147.779 113.942v-4.608l-16.547-9.554h-7.979l-20.183 11.655v4.603l16.545 9.555h7.977z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M119.615 131.424l-16.545-9.554v-5.83l16.545 9.555z"
              />
            </g>
            <g>
              <path
                fill="#c84030"
                d="M119.607 131.424l-6.107-3.527v-2.767l6.107 3.527z"
              />
            </g>
            <g>
              <path d="M129.075 123.568l-11.33.334-14.685-8.484v5.83l16.545 9.55h7.977l20.188-11.651v-5.827z" />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M119.425 129.774l-17.025-9.83v-9.214l8.808 4.57 8.217 5.261z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M85.37 129.769l.664.382 1.002-8.131-1.666.198z"
              />
            </g>
            <g>
              <path
                fill="#f4efe9"
                d="M86.034 122.594l3.551-.32 9.97-5.625 4.936-4.717-.66-.382-18.461 10.662z"
              />
            </g>
            <g>
              <path
                fill="#d1d1d1"
                d="M86.04 130.153l25.026-14.421-6.569-3.792-18.457 10.662z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M119.878 120.195l-.339-.255-.05.482-1.409 2.991 1.41 5.329.388-.228z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M118.997 119.567l.042.976.454-.12.39-.227-.493-.856z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M118.994 119.567l-6.92-1.414-7.974-7.186.391-.227 14.895 8.6z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M119.495 120.416l-.496-.856-14.894-8.6-.495.285v8.32l.495.857 14.894 8.6.496-.285z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M110.07 114.41v-.656l-.703-1.22-3.594-2.074-.703.407v.656z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M109.363 112.536l-3.593-2.075.192-.111 3.592 2.074z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M109.362 112.532l.703 1.22.193-.113-.706-1.219z"
              />
            </g>
            <g>
              <path fill="#cc934c" d="M110.073 113.748v.656l.194-.11v-.658z" />
            </g>
            <g>
              <path
                fill="#8ccccb"
                d="M119.368 119.127l-.44.252-15.098-8.715.438-.254z"
              />
            </g>
            <g>
              <path fill="#3e6669" d="M118.92 119.384v1.563l.44-.253v-1.564z" />
            </g>
            <g>
              <path fill="#d1d1d1" d="M118.92 120.943v7.313l.44-.252v-7.314z" />
            </g>
            <g>
              <path
                fill="#389998"
                d="M118.927 119.386l-15.097-8.716v1.563l15.097 8.715z"
              />
            </g>
            <g>
              <path
                fill="#fff"
                d="M103.83 119.545l15.097 8.714v-7.313l-15.097-8.716z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M118.78 120.825l-.34-.255-.05.482-1.41 2.993 1.41 5.326.39-.227z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M118.79 128.503l-.162.403.162.238.705-.404.117-.321-.117-.323z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M117.897 120.197l.04.975.455-.12.39-.227-.494-.855z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M117.896 120.198l-6.92-1.415-7.976-7.186.392-.227 14.895 8.601z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M118.396 121.046l-.495-.855-14.896-8.601-.495.287v8.32l.495.855 14.896 8.6.495-.286z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M109.363 114.82v-.656l-.706-1.22-3.592-2.074-.703.405-.002.658z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M109.17 114.922v-.657l-.702-1.221-3.595-2.074-.703.407v.657z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M108.474 113.053l-3.594-2.074.193-.109 3.591 2.073z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M108.472 113.05l.703 1.22.193-.11-.706-1.22z"
              />
            </g>
            <g>
              <path fill="#cc934c" d="M109.173 114.267v.657l.194-.11v-.658z" />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M127.64 129.77h-8.212v-9.212l-1.988-4.168 13.276 2.507z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M148.412 117.782l-20.772 11.992v-10.06l8.662-8.185 12.11-3.809z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M148.41 107.72v-4.74l-17.03-9.83h-8.206L102.4 105.142v4.74l17.025 9.832h8.212z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M119.425 120.559l-17.025-9.831v-.848l17.025 9.833z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M114.92 123.055l-.338-.255-.054.482-1.408 2.992 1.408 5.328.391-.227z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M114.037 122.427l.04.976.454-.12.392-.227-.494-.856z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M114.035 122.426l-6.922-1.414-7.973-7.186.391-.226 14.895 8.599z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M114.535 123.276l-.494-.856-14.895-8.6-.496.285v8.32l.496.857 14.895 8.601.494-.286z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M105.11 117.27v-.655l-.705-1.221-3.592-2.074-.703.407v.656z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M104.411 115.394l-3.591-2.074.192-.11 3.591 2.073z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M104.41 115.391l.706 1.22.193-.11-.707-1.221z"
              />
            </g>
            <g>
              <path fill="#cc934c" d="M105.114 116.618v.656l.192-.11v-.658z" />
            </g>
            <g>
              <path
                fill="#f7c9e7"
                d="M114.406 121.987l-.44.252-15.096-8.716.438-.253z"
              />
            </g>
            <g>
              <path fill="#9c7a98" d="M113.97 122.243v1.562l.44-.252v-1.563z" />
            </g>
            <g>
              <path fill="#d1d1d1" d="M113.97 123.803v7.313l.44-.252v-7.314z" />
            </g>
            <g>
              <path
                fill="#cca7cf"
                d="M113.966 122.247L98.87 113.53v1.563l15.096 8.716z"
              />
            </g>
            <g>
              <path
                fill="#fff"
                d="M98.87 122.405l15.096 8.715v-7.313L98.87 115.09z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M113.83 123.685l-.34-.255-.05.48-1.41 2.994 1.41 5.328.39-.229z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M113.829 131.363l-.16.403.16.238.703-.404.12-.322-.12-.322z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M112.947 123.058l.04.975.455-.12.39-.227-.493-.856z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M112.945 123.058l-6.921-1.416-7.974-7.186.391-.226 14.895 8.6z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M113.435 123.916l-.495-.854-14.895-8.602-.495.287v8.32l.495.856 14.895 8.6.495-.285z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M104.41 117.682v-.658l-.703-1.22-3.592-2.074-.705.406v.657z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M104.22 117.792v-.658l-.705-1.22-3.592-2.074-.703.407v.657z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M103.511 115.915l-3.591-2.075.193-.11 3.591 2.073z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M103.511 115.912l.705 1.22.193-.112-.704-1.22z"
              />
            </g>
            <g>
              <path fill="#cc934c" d="M104.214 117.127v.657l.192-.11v-.658z" />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M109.97 125.915l-.34-.255-.05.48-1.41 5.722 1.41 2.599.39-.225z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M109.087 125.287l.04.976.455-.12.39-.227-.494-.856z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M109.085 125.286l-6.92-1.414-7.975-7.187.39-.225 14.896 8.599z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M109.575 126.146l-.495-.855-14.895-8.601-.495.286v8.32l.495.857 14.895 8.598.495-.284z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M100.163 120.13v-.656l-.705-1.22-3.593-2.074-.705.407v.657z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M99.452 118.256l-3.592-2.075.193-.111 3.591 2.075z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M99.452 118.261l.705 1.22.19-.11-.702-1.221z"
              />
            </g>
            <g>
              <path fill="#cc934c" d="M100.155 119.477v.656l.19-.109v-.657z" />
            </g>
            <g>
              <path
                fill="#fa5c52"
                d="M109.449 124.856l-.44.254-15.099-8.716.44-.254z"
              />
            </g>
            <g>
              <path fill="#991f16" d="M109.01 125.105v1.562l.44-.253v-1.564z" />
            </g>
            <g>
              <path fill="#d1d1d1" d="M109.01 126.673v7.316l.44-.255v-7.314z" />
            </g>
            <g>
              <path
                fill="#c84030"
                d="M109.007 125.107L93.91 116.39v1.563l15.097 8.715z"
              />
            </g>
            <g>
              <path
                fill="#fff"
                d="M93.91 125.266l15.097 8.716v-7.316L93.91 117.95z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M108.87 126.544l-.339-.254-.053.482-1.408 5.72 1.408 2.6.392-.229z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M108.869 134.222l-.16.403.16.24.705-.406.117-.32-.117-.323z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M107.987 125.915l.04.976.453-.118.393-.228-.493-.855z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M107.985 125.915l-6.92-1.414-7.975-7.186.39-.225 14.897 8.6z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M108.482 126.779l-.492-.859-14.895-8.6-.495.287v8.32l.495.856 14.895 8.6.492-.284z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M99.451 120.54v-.657l-.703-1.22-3.592-2.073-.705.406-.001.657z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M99.26 120.65v-.658l-.703-1.22-3.592-2.072-.705.406v.657z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M98.561 118.773L94.97 116.7l.193-.111 3.591 2.073z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M98.562 118.77l.703 1.22.193-.11-.704-1.22z"
              />
            </g>
            <g>
              <path fill="#cc934c" d="M99.263 119.996v.659l.194-.111v-.659z" />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M105.01 126.506l-.342-.256-.052.482-1.406 2.993 1.406 5.327.393-.227z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M104.126 125.877l.038.977.456-.122.394-.226-.495-.856z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M104.126 125.876l-6.92-1.414-7.976-7.186.393-.226 14.895 8.599z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M104.622 126.725l-.493-.855-14.897-8.6-.492.286v8.318l.492.858 14.897 8.599.493-.285z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M95.201 120.72v-.656l-.705-1.22-3.59-2.074-.706.406v.657z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M94.499 118.846l-3.589-2.075.19-.111 3.591 2.074z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M94.49 118.842l.706 1.22.193-.113-.706-1.219z"
              />
            </g>
            <g>
              <path fill="#cc934c" d="M95.203 120.058v.656l.194-.108v-.66z" />
            </g>
            <g>
              <path
                fill="#8ccccb"
                d="M104.495 116.578l-.438.255-14.747-8.51.44-.253z"
              />
            </g>
            <g>
              <path fill="#3e6669" d="M104.06 116.836v1.562l.44-.254v-1.564z" />
            </g>
            <g>
              <path
                fill="#d1d1d1"
                d="M104.06 118.394v13.284l.44-.254V118.14z"
              />
            </g>
            <g>
              <path
                fill="#389998"
                d="M104.056 116.83l-14.746-8.51v1.563l14.746 8.508z"
              />
            </g>
            <g>
              <path
                fill="#fff"
                d="M89.31 123.163l14.746 8.51v-13.285L89.31 109.88z"
              />
            </g>
            <g>
              <path
                fill="#d1d1d1"
                d="M103.13 129.807v.422l-12.9-7.447V111.45l.365.21v10.912z"
              />
            </g>
            <g>
              <path
                fill="#17435c"
                d="M103.134 118.894v10.912L90.6 122.57v-10.91z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M92.1 114.874l-.195-.34v-.713l.194-.112.18.103.194.34v.713l-.194.112zm-.38-1.25v.884l.302.524.334.195.305-.174v-.885l-.305-.526-.334-.192z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M94.491 116.233l-.194-.336v-.707l.194-.11.18.104.193.334v.706l-.193.112zm-.381-1.24v.885l.303.527.334.192.303-.175v-.885l-.303-.524-.334-.193z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M95.791 116.983l-.192-.335v-.705l.192-.113.18.104.195.337v.705l-.194.112zm-.381-1.238v.886l.303.523.335.194.303-.175v-.885l-.303-.525-.335-.193z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M97.63 118.053l-.194-.336v-.705l.194-.113.18.105.193.335v.705l-.192.113zm-.38-1.24v.885l.303.526.333.194.305-.176v-.886l-.305-.523-.333-.193z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M93.016 115.604l.188.107v-1.583l-.188-.108z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M93.566 115.914l.188.11v-1.585l-.188-.109z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M96.708 117.733l.184.108v-1.583l-.184-.108z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M98.547 118.792l.186.11v-1.585l-.186-.107z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M91.177 114.544l.186.108v-1.585l-.186-.107z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M99.471 119.113l-.193-.335v-.705l.193-.113.18.106.193.333v.706l-.193.112zm-.381-1.238v.886l.301.522.336.194.304-.174v-.885l-.304-.525-.336-.193z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M101.86 120.493l-.191-.335v-.706l.19-.112.183.104.19.335v.706l-.19.112zm-.38-1.24v.885l.301.525.336.195.304-.174v-.887l-.304-.524-.336-.193z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M100.397 119.864l.186.107v-1.583l-.186-.108z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M100.937 120.173l.186.108v-1.584l-.186-.107z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M92.1 122.574l-.195-.337v-.707l.194-.112.18.104.194.335v.707l-.194.114zm-.38-1.24v.883l.302.526.334.194.305-.175v-.887l-.305-.523-.334-.192z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M94.491 123.944l-.194-.335v-.706l.194-.112.18.103.193.337v.703l-.193.114zm-.381-1.239v.886l.303.523.334.193.303-.173v-.886l-.303-.525-.334-.193z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M95.791 124.693l-.192-.335v-.706l.192-.112.18.105.195.334v.706l-.194.112zm-.381-1.238v.883l.303.525.335.194.303-.174v-.885l-.303-.525-.335-.193z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M97.63 125.764l-.194-.336v-.708l.194-.112.18.104.193.337v.708l-.192.113zm-.38-1.24v.887l.303.523.333.191.305-.171v-.887l-.305-.525-.333-.192z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M93.016 123.314l.188.11v-1.585l-.188-.109z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M93.566 123.634l.188.106v-1.582l-.188-.108z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M96.708 125.445l.184.106v-1.584l-.184-.107z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M98.547 126.504l.186.108v-1.583l-.186-.109z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M91.177 122.254l.186.11v-1.585l-.186-.109z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M99.471 126.824l-.193-.335v-.706l.193-.113.18.104.193.336v.705l-.193.112zm-.381-1.239v.883l.301.526.336.194.304-.175v-.885l-.304-.524-.336-.194z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M101.86 128.203l-.191-.335v-.706l.19-.113.183.105.19.336v.705l-.19.113zm-.38-1.238v.886l.301.522.336.195.304-.176v-.884l-.304-.525-.336-.193z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M100.397 127.575l.186.108V126.1l-.186-.11z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M100.937 127.881l.186.108v-1.583l-.186-.106z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M92.65 117.743l-.194-.335v-.706l.193-.113.18.105.194.334v.707l-.194.11zm-.38-1.238v.883l.302.524.336.195.303-.175v-.884l-.303-.526-.336-.192z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M91.177 117.114l.186.108v-1.583l-.186-.109z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M91.718 117.423l.184.107v-1.583l-.184-.107z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M94.491 118.813l-.194-.335v-.708l.194-.112.18.104.193.337v.708l-.193.112zm-.381-1.24v.886l.303.525.334.193.303-.174v-.885l-.303-.525-.334-.193z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M96.881 120.194l-.193-.338v-.706l.193-.112.18.105.193.334v.708l-.193.113zm-.381-1.24v.884l.302.525.335.194.303-.177v-.884l-.303-.524-.335-.192z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M98.18 120.935l-.194-.337v-.705l.193-.113.18.104.195.337v.704l-.195.112zm-.38-1.24v.883l.303.526.336.193.302-.175v-.884l-.302-.525-.336-.193z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M100.022 122.003l-.195-.336v-.707l.195-.11.179.103.194.336v.705l-.194.112zm-.382-1.24v.884l.306.526.331.194.304-.175v-.885l-.304-.526-.331-.191z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M95.406 119.553l.188.108v-1.584l-.188-.107z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M95.956 119.874l.188.108v-1.585l-.188-.107z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M99.096 121.683l.188.108v-1.583l-.188-.108z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M100.937 122.744l.186.107v-1.583l-.186-.108z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M93.566 118.493l.188.106v-1.581l-.188-.108z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M101.86 123.073l-.191-.338v-.711l.19-.113.183.104.19.337v.712l-.19.112zm-.38-1.248v.884l.302.524.335.194.304-.173v-.886l-.304-.526-.335-.192z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M92.65 125.453l-.194-.334v-.706l.193-.114.18.105.194.337v.705l-.194.11zm-.38-1.238v.887l.302.525.336.192.303-.176v-.885l-.303-.524-.336-.194z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M91.177 124.823l.186.108v-1.583l-.186-.108z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M91.718 125.132l.184.11v-1.586l-.184-.106z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M94.491 126.513l-.194-.337v-.704l.194-.113.18.104.193.336v.706l-.193.111zm-.381-1.238v.886l.303.522.334.194.303-.175v-.884l-.303-.525-.334-.193z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M96.881 127.895l-.193-.336v-.706l.193-.113.18.106.193.335v.705l-.193.112zm-.381-1.24v.886l.302.523.335.195.303-.177v-.883l-.303-.525-.335-.194z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M98.18 128.645l-.194-.336v-.706l.193-.112.18.104.195.333v.707l-.195.112zm-.38-1.239v.882l.303.526.336.194.302-.175v-.885l-.302-.526-.336-.192z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M100.022 129.715l-.195-.338v-.708l.195-.112.179.106.194.335v.707l-.194.113zm-.382-1.241v.884l.306.525.331.192.304-.172v-.887l-.304-.524-.331-.192z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M95.406 127.264l.188.108v-1.584l-.188-.108z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M95.956 127.572l.188.11v-1.583l-.188-.109z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M99.096 129.394l.188.106v-1.58l-.188-.11z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M100.937 130.454l.186.107v-1.582l-.186-.109z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M93.566 126.194l.188.108v-1.584l-.188-.108z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M101.86 130.773l-.191-.335v-.705l.19-.114.183.105.19.335v.707l-.19.111zm-.38-1.24v.886l.302.525.335.194.304-.175v-.886l-.304-.523-.335-.194z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M91.561 119.685l-.194-.336v-.706l.194-.113.178.105.195.334v.706l-.195.112zm-.381-1.239v.882l.303.527.334.192.304-.174v-.885l-.304-.524-.334-.194z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M92.475 120.434l.19.107v-1.583l-.19-.108z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M93.016 120.742l.188.11v-1.583l-.188-.109z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M93.566 121.055l.188.106v-1.583l-.188-.108z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M94.107 121.372l.186.108v-1.585l-.186-.105z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M95.03 121.694l-.194-.336v-.708l.193-.111.18.105.194.335v.707l-.194.112zm-.38-1.24v.884l.301.526.335.191.305-.173v-.884l-.305-.527-.335-.191z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M96.881 122.752l-.193-.335v-.705l.193-.114.18.105.193.335v.706l-.193.111zm-.381-1.24v.886l.302.525.336.193.302-.175v-.884l-.302-.526-.336-.191z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M97.797 123.503l.186.11v-1.585l-.186-.108z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M98.337 123.814l.186.108v-1.584l-.186-.108z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M101.486 125.632l.188.11v-1.585l-.189-.107z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M102.028 125.944l.184.108v-1.583l-.184-.109z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M95.956 122.434l.188.108v-1.584l-.188-.108z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M99.26 124.133l-.194-.336v-.704l.194-.113.18.104.193.336v.706l-.194.11zm-.38-1.237v.886l.302.522.335.193.304-.175v-.885l-.304-.523-.335-.194z"
              />
            </g>
            <g>
              <path
                fill="#65c6e5"
                d="M100.562 124.884l-.194-.336v-.706l.194-.113.18.105.193.336v.706l-.193.111zm-.382-1.238v.885l.305.522.333.194.303-.173v-.886l-.303-.524-.333-.194z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M103.92 127.135l-.34-.255-.05.48-1.41 2.993 1.41 5.328.39-.228z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M103.92 134.813l-.161.403.16.238.705-.405.117-.321-.117-.322z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M103.027 126.506l.04.976.454-.12.392-.227-.495-.855z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M103.026 126.506l-6.921-1.415-7.975-7.185.393-.226 14.894 8.6z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M103.526 127.365l-.495-.855-14.896-8.6-.495.285v8.32l.495.856 14.896 8.602.495-.287z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M94.5 121.12v-.655l-.705-1.22-3.592-2.075-.703.408v.656z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M94.3 121.242v-.658l-.702-1.22-3.595-2.074-.703.408v.656z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M93.604 119.355l-3.594-2.074.193-.111 3.591 2.074z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M93.602 119.361l.703 1.22.193-.11-.707-1.221z"
              />
            </g>
            <g>
              <path fill="#cc934c" d="M94.304 120.577v.657l.192-.112v-.656z" />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M101.74 139.218l.661.382 1.003-8.13-1.664.198z"
              />
            </g>
            <g>
              <path
                fill="#f4efe9"
                d="M102.401 132.048l3.551-.32 9.972-5.625 3.502-3.888v-.765l-17.686 10.216z"
              />
            </g>
            <g>
              <path
                fill="#d1d1d1"
                d="M102.4 139.605l17.025-9.831v-7.554l-17.025 9.834z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M102.395 139.596l-.66.382-1.005-10.638 1.665.198z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M101.736 129.926l-3.553-.321-9.97-5.625-3.503-3.887.661-.383 17.026 9.833z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M101.736 139.981l-17.026-9.83V120.09l17.026 9.833z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M127.588 119.147h-7.978l-2.44-6.676 6.786-2.661 6.621 2.717z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M147.776 107.496l-20.186 11.656v-5.828l8.419-7.957 11.767-3.697z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M147.779 101.663v-4.606L131.232 87.5h-7.979L103.07 99.156v4.606l16.545 9.555h7.977z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M119.615 119.152L103.07 109.6v-5.829l16.545 9.555z"
              />
            </g>
            <g>
              <path
                fill="#dde65c"
                d="M119.607 119.145l-6.107-3.528v-2.767l6.107 3.527z"
              />
            </g>
            <g>
              <path d="M129.075 111.3l-11.33.33-14.685-8.48v5.828l16.545 9.555h7.977l20.188-11.654v-5.829z" />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M127.638 117.54h-8.212l-2.506-10.934 6.979-2.736 6.815 2.794z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M148.412 105.54l-20.772 11.994V107.47l8.662-8.188 12.11-3.803z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M148.41 95.484v-4.74l-17.03-9.834h-8.206L102.4 92.904v4.74l17.025 9.832h8.212z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M119.425 117.534L102.4 107.7V97.64l17.025 9.831z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M127.588 106.917h-7.978l-2.44-6.676 6.786-2.661 6.621 2.717z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M147.776 95.256l-20.186 11.656v-5.828l8.419-7.957 11.767-3.697z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M147.779 89.433v-4.606l-16.547-9.557h-7.979L103.07 86.926v4.606l16.545 9.555h7.977z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M119.615 106.912L103.07 97.36V91.53l16.545 9.555z"
              />
            </g>
            <g>
              <path
                fill="#dde65c"
                d="M119.607 106.915l-6.107-3.528v-2.767l6.107 3.527z"
              />
            </g>
            <g>
              <path d="M129.075 99.061l-11.33.33-14.685-8.48v5.827l16.545 9.556h7.977L147.77 94.64v-5.83z" />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M127.638 105.3h-8.212l-2.506-10.934 6.979-2.736 6.815 2.794z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M148.412 93.31l-20.772 11.994V95.24l8.662-8.188 12.11-3.803z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M148.41 83.244v-4.74l-17.03-9.834h-8.206L102.4 80.664v4.74l17.025 9.83h8.212z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M119.425 105.304L102.4 95.47V85.41l17.025 9.83z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M127.588 94.535h-7.978l-2.44-1.758 6.786-2.657 6.145 2.515z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M147.776 82.873L127.59 94.527v-.913l8.419-7.956 11.767-3.698z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M147.779 81.962v-4.606L131.232 67.8h-7.979L103.07 79.459v4.604l16.545 9.553h7.977z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M119.615 94.535l-16.545-9.552v-.913l16.545 9.553z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M148.01 173.761l2.44 6.673h7.978l2.99-6.619-6.622-2.715z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M166.849 166.655l-8.419 7.958v5.828l20.186-11.655v-5.826z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M178.619 162.963v-4.61l-16.547-9.553h-7.979l-20.183 11.653v4.609l16.545 9.554h7.977z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M133.91 170.888l16.546 9.552v-5.826l-16.546-9.554z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M158.478 179.098h-8.212l-2.506-17.84 6.978-2.738 6.817 2.795z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M179.252 167.107l-20.772 11.992v-16.968l8.662-8.188 12.11-3.803z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M179.25 150.144V145.4l-17.03-9.83h-8.207l-20.773 11.991v4.743l17.024 9.83h8.213z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M150.264 179.1l-17.024-9.834V152.3l17.024 9.83z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M158.428 161.571h-7.978l-2.44-6.672 6.786-2.659 6.622 2.714z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M178.616 149.918L158.43 161.57v-5.827l8.419-7.955 11.767-3.699z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M178.619 144.089v-4.606l-16.547-9.553h-7.979l-20.183 11.656v4.604l16.545 9.553h7.977z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M150.455 161.57l-16.545-9.554v-5.826l16.545 9.552z"
              />
            </g>
            <g>
              <path
                fill="#dde65c"
                d="M150.448 161.573l-6.108-3.528v-2.765l6.108 3.526z"
              />
            </g>
            <g>
              <path d="M159.925 153.716l-11.33.335-14.685-8.484v5.83l16.545 9.554h7.977l20.188-11.655v-5.826z" />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M158.478 159.845h-8.212l-2.506-10.927 6.978-2.738 6.817 2.793z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M179.252 147.86l-20.772 11.99v-10.06l8.662-8.188 12.11-3.802z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M179.25 137.801v-4.742l-17.03-9.829h-8.207l-20.773 11.992v4.737l17.024 9.834h8.213z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M150.264 159.851l-17.024-9.829V139.96l17.024 9.833z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M158.428 149.224h-7.978l-2.44-6.673 6.786-2.661 6.622 2.717z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M178.616 137.576L158.43 149.23v-5.828l8.419-7.955 11.767-3.697z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M178.619 131.752v-4.608l-16.547-9.554h-7.979l-20.183 11.655v4.604l16.545 9.554h7.977z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M150.455 149.231l-16.545-9.553v-5.828l16.545 9.554z"
              />
            </g>
            <g>
              <path
                fill="#dde65c"
                d="M150.448 149.224l-6.108-3.525v-2.769l6.108 3.528z"
              />
            </g>
            <g>
              <path d="M159.925 141.377l-11.33.334-14.685-8.484v5.83l16.545 9.55h7.977l20.188-11.65v-5.827z" />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M158.48 147.581h-8.212v-9.213l-1.988-4.168 13.278 2.507z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M179.252 135.591l-20.772 11.993v-10.06l8.662-8.187 12.11-3.807z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M179.25 125.52v-4.739l-17.03-9.831h-8.207l-20.773 11.992v4.74l17.024 9.832h8.213z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M133.24 127.69v10.061l17.024 9.832v-10.06z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M158.428 136.958h-7.978l-2.44-6.677 6.786-2.661 6.622 2.717z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M178.616 125.306l-20.186 11.657v-5.828l8.419-7.96 11.767-3.695z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M178.619 119.473v-4.607l-16.547-9.556h-7.979l-20.183 11.658v4.604l16.545 9.556h7.977z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M150.455 136.964l-16.545-9.555v-5.829l16.545 9.556z"
              />
            </g>
            <g>
              <path
                fill="#dde65c"
                d="M150.448 136.955l-6.108-3.53v-2.765l6.108 3.525z"
              />
            </g>
            <g>
              <path d="M159.925 129.111l-11.33.33-14.685-8.48v5.828l16.545 9.555h7.977l20.188-11.654v-5.83z" />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M158.478 135.348h-8.212l-2.506-10.931 6.978-2.737 6.817 2.794z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M179.252 123.35l-20.772 11.993V125.28l8.662-8.188 12.11-3.802z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M179.25 113.294v-4.74l-17.03-9.834h-8.207l-20.773 11.994v4.74l17.024 9.83h8.213z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M150.264 135.343l-17.024-9.833v-10.06l17.024 9.83z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M158.428 124.575h-7.978l-2.44-1.758 6.786-2.657 6.145 2.515z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M178.616 112.924l-20.186 11.653v-.913l8.419-7.955 11.767-3.699z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M178.619 112.012v-4.605l-16.547-9.557h-7.979l-20.183 11.66v4.603l16.545 9.553h7.977z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M150.455 124.575l-16.545-9.552v-.913l16.545 9.553z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M158.428 124.728h-7.978l-2.44-6.677 6.786-2.661 6.622 2.717z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M178.616 113.066l-20.186 11.657v-5.828l8.419-7.96 11.767-3.695z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M178.619 107.243v-4.607l-16.547-9.556h-7.979l-20.183 11.658v4.604l16.545 9.556h7.977z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M150.455 124.724l-16.545-9.555v-5.829l16.545 9.556z"
              />
            </g>
            <g>
              <path
                fill="#dde65c"
                d="M150.448 124.725l-6.108-3.53v-2.765l6.108 3.525z"
              />
            </g>
            <g>
              <path d="M159.925 116.871l-11.33.33-14.685-8.48v5.827l16.545 9.556h7.977l20.188-11.654v-5.83z" />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M158.478 123.108h-8.212l-2.506-10.931 6.978-2.737 6.817 2.794z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M179.252 111.12l-20.772 11.993V113.05l8.662-8.187 12.11-3.803z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M179.25 101.054v-4.74l-17.03-9.834h-8.207L133.24 98.474v4.74l17.024 9.83h8.213z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M150.264 123.113l-17.024-9.833v-10.06l17.024 9.83z"
              />
            </g>
            <g>
              <path
                fill="#31353f"
                d="M158.428 112.335h-7.978l-2.44-1.758 6.786-2.657 6.145 2.515z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M178.616 100.684l-20.186 11.653v-.913l8.419-7.955 11.767-3.699z"
              />
            </g>
            <g>
              <path
                fill="#6a7282"
                d="M178.619 99.772v-4.605l-16.547-9.557h-7.979L133.91 97.27v4.603l16.545 9.553h7.977z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M150.455 112.335l-16.545-9.552v-.913l16.545 9.553z"
              />
            </g>
            <g>
              <g />
            </g>
            <g>
              <path
                fill="#c7a495"
                d="M98.433 111.937l-.223 1.015-1.344-.776-.186-.239.571-.567z"
              />
            </g>
            <g>
              <path
                fill="#fbbd9e"
                d="M88.848 111.173l3.885-.62 3.885-.303 2.783 1.129.57 1.562-.713-.153-.506-.673-1.65-.14-.941.711.634.508.89 1.117-.737.178-.761-.483-1.398-.254-1.726-1.116-4.012 1.999h-1.04L87.09 112z"
              />
            </g>
            <g>
              <path
                fill="#c69695"
                d="M89.133 111.13l.563.688-.233.905.342.562-.734 1.344-.646-.258-.095-2.668z"
              />
            </g>
            <g>
              <path
                fill="#2dcb75"
                d="M76.21 122.914l-1.47-2.22.097-2.389 1.104-1.331 4.837-2.344 1.22.743-.074 4.724z"
              />
            </g>
            <g>
              <path
                fill="#9b866e"
                d="M77.39 173.697v.323l.636.965h1.548l.943-1.702.202-1.167 1.472-1.727.304-1.625v-.324l-2.436 2.362z"
              />
            </g>
            <g>
              <path
                fill="#fff"
                d="M77.57 171.265l-.18 2.439.636.963h1.548l.943-1.7.202-1.168 1.472-1.726.304-1.626-.277-.508-.993-.229z"
              />
            </g>
            <g>
              <path
                fill="#9b866e"
                d="M89.884 178.402l6.5-.612v.353l-1.574 1.243-5.23.127-.533.382-2.285.28-1.142-.636v-.353z"
              />
            </g>
            <g>
              <path
                fill="#fff"
                d="M90.85 175.05l2.64.812h2.742l.152 1.93-1.574 1.245-5.23.128-.533.379-2.284.28-1.143-.635v-3.062z"
              />
            </g>
            <g>
              <path
                fill="#134f70"
                d="M78.016 142.35v13.627l-1.306 3.732v10.817l1.118 1.574 2.243-.056 1.363-1.633 1.27-12.378 2.435-8.025v-5.18z"
              />
            </g>
            <g>
              <path
                fill="#00738e"
                d="M79.876 134.98l-2.046 7.005 1.02 1.98 5.1 3.2 2.005 12.798-.71 3.808-.053 12.087 1.83 1.117h2.691l1.625-1.981 1.116-16.452v-19.858l-1.116-2.991z"
              />
            </g>
            <g>
              <path
                fill="#573711"
                d="M80.21 133.31l-.34 1.67 1.14 1.267L86 138.073l5.333-.762-.685-2.818z"
              />
            </g>
            <g>
              <path
                fill="#2dcb75"
                d="M80.315 118.11l-2.13.37-1.878 2.299-.357 2.818 1.728 4.85 2.082 3.275v1.067l1.93 2.187 4.418 1.47 4.47-.609 1.27-6.044.102-4.267-2.552-3.452-2.124-1.295z"
              />
            </g>
            <g>
              <path
                fill="#fbbd9e"
                d="M81.104 116.096l-.094 1.466.476 1.18 2.252 1.087 2.513-.21.17-1.562.285-.876.379-1.675-3.58-.876z"
              />
            </g>
            <g>
              <path
                fill="#fbbd9e"
                d="M88.703 109.71l.696 2.277-.35.82.196.323-.196 1.675-1.198 2.38h-1.138l-.351-.944-1.212-.864.725-5.18z"
              />
            </g>
            <g>
              <path
                fill="#944e39"
                d="M88.65 109.445l.302 1.508-.915 1.906-.187 1.067-.142.227-.204-.38-.482.025-.661 1.117v1.32l-1.295 1.194-2.818-.152-1.593-1.657-.895-2.863v-2.565l1.931-2.284 2.918-1.118 3.683.407.761 1.243z"
              />
            </g>
            <g>
              <path fill="#693121" d="M84.2 109.64l1.142.33 3.303-.52z" />
            </g>
            <g>
              <path
                fill="#fbbd9e"
                d="M93.161 131.37l.97 3.566 4.115.457.457-1.486 1.181-.952.8-.972.457.82-.342.495-.421.388h1.602l1.921-1.664.345.246-.82 2.914-1.142 1.24-2.59 1.103-5.009 1.62-3.41-.285-2.575-5.419 1.72-2.621z"
              />
            </g>
            <g>
              <path
                fill="#2dcb75"
                d="M88.827 123.22l-1.7.914-.637 3.098 1.955 6.171 2.03.28 2.11-.915.863-1.4-1.752-5.913-1.142-1.474z"
              />
            </g>
            <g>
              <path
                fill="#2dcb75"
                d="M80.21 118.304l1.717 1.79 2.855.572 2.486.114v-2.019l-2.125.267-2.877-.724-1.485-1.124z"
              />
            </g>
            <g>
              <path
                fill="#573711"
                d="M40.93 80.649l5.693 3.287-5.27 9.83-5.693-3.288 4.922-9.948z"
              />
            </g>
            <g>
              <path
                fill="#7b4a19"
                d="M46.63 83.939l-4.923 9.948-.347-.12 4.924-9.947z"
              />
            </g>
            <g>
              <path
                fill="#a39c95"
                d="M45.596 83.353l1.028.593v-.044l-.377-.741-.41-.087z"
              />
            </g>
            <g>
              <path
                fill="#fff"
                d="M46.25 83.094l-5.71-3.234-1.09.675-.409 1.99-1.013 2.437-2.578 4.899 5.73 3.305.092-.23 2.462-4.706.999-2.391.393-2.002 1.072-.667z"
              />
            </g>
            <g>
              <path
                fill="#d1d1d1"
                d="M37.65 86.52l4.994 2.883.235-.452-4.99-2.881z"
              />
            </g>
            <g>
              <path
                fill="#d1d1d1"
                d="M38.01 85.821l4.994 2.882.236-.452-4.992-2.881z"
              />
            </g>
            <g>
              <path
                fill="#d1d1d1"
                d="M40.876 85.965l-2.297-1.325-.199.473 2.257 1.303z"
              />
            </g>
            <g>
              <path
                fill="#d1d1d1"
                d="M36.17 89.32l4.992 2.883.237-.452-4.99-2.881z"
              />
            </g>
            <g>
              <path
                fill="#d1d1d1"
                d="M36.54 88.621l4.992 2.882.237-.45-4.994-2.883z"
              />
            </g>
            <g>
              <path
                fill="#d1d1d1"
                d="M38.69 84.383l4.99 2.883.2-.475-4.992-2.881z"
              />
            </g>
            <g>
              <path
                fill="#d1d1d1"
                d="M40.02 80.585l4.987 2.879.492-.304-4.987-2.88z"
              />
            </g>
            <g>
              <path
                fill="#d1d1d1"
                d="M43.292 85.54l-4.104-2.37-.198.474 4.064 2.347z"
              />
            </g>
            <g>
              <path
                fill="#d1d1d1"
                d="M36.042 89.57l-.252.48 4.994 2.881.252-.48z"
              />
            </g>
            <g>
              <path
                fill="#d1d1d1"
                d="M39.379 82.718l-.079.187 4.992 2.883.075-.184.067-.323-4.99-2.881z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M39.448 80.53l-.408 1.99 5.661 3.274.045-.07.358-1.787.005-.143z"
              />
            </g>
            <g>
              <path
                fill="#d1d1d1"
                d="M41.177 93.162l-.067-.036 2.577-4.898 1.014-2.433.407-2 1.14-.705.412.832-.034.017-.41-.74-1.04.644-.404 1.974-1.017 2.443z"
              />
            </g>
            <g>
              <path
                fill="#fbbd9e"
                d="M14 85.129l.354 2.284 1.194 2.309 6.522 5 .864 1.211h1.7l1.193-.525.761-2.182-.113-1.448-1.26.3-1.494.69-2.893-3.299-2.033-2.945 1.123-.853.2-3.36-3.349-.271z"
              />
            </g>
            <g>
              <path
                fill="#c69695"
                d="M24.632 95.934l-.702-.365.563-.738 1.143-.421z"
              />
            </g>
            <g>
              <path fill="#c69695" d="M16.03 87.306l1.382.075 1.384-.862z" />
            </g>
            <g>
              <path fill="#c69695" d="M24.492 93.785v-.515l-.744-.515z" />
            </g>
            <g>
              <path
                fill="#f4efe9"
                d="M24.402 76.458l1.692.69-1.293 3.932-4.396 4.745-1.956-.613-1.514-1.725-.185-1.633 4.105-4.6 1.66-.844z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M22.95 132.494v.324l.635.964h1.548l.94-1.7.202-1.168 1.472-1.727.306-1.623v-.324l-2.438 2.36z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M23.13 130.052l-.18 2.438.635.964h1.548l.94-1.7.202-1.168 1.472-1.726.306-1.625-.28-.508-.989-.227z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M23.565 101.16v13.622l-1.305 3.73v10.812l1.117 1.573 2.24-.056 1.364-1.631 1.269-12.374 2.438-8.021v-5.176z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M35.224 137.341l6.498-.611v.351l-1.573 1.244-5.23.127-.531.38-2.286.28-1.142-.635v-.352z"
              />
            </g>
            <g>
              <path
                fill="#505666"
                d="M36.188 133.99l2.64.812h2.74l.154 1.929-1.573 1.243-5.23.128-.531.38-2.285.278-1.143-.634v-3.06z"
              />
            </g>
            <g>
              <path
                fill="#292d35"
                d="M25.213 93.94l-2.043 7 1.022 1.98 5.094 3.2 2.007 12.792-.711 3.807-.05 12.082 1.826 1.116h2.69l1.625-1.98 1.117-16.444v-19.85l-1.117-2.99z"
              />
            </g>
            <g>
              <path
                fill="#0a0808"
                d="M25.607 92.19l-.387 1.75 1.142 1.266 4.986 1.828 5.332-.762-.688-2.817z"
              />
            </g>
            <g>
              <path
                fill="#fff"
                d="M25.621 77.24l-2.129.37-1.876 2.298-.356 2.816 1.725 4.848 2.082 3.275v1.065l1.927 2.186 4.418 1.47 4.468-.61 1.685-5.922v-4.188l-.803-1.574-1.218-1.7-2.87-1.859z"
              />
            </g>
            <g>
              <path
                fill="#fbbd9e"
                d="M40.67 88.578l.484-1.015.855-.483 1.099.584 1.499.164.99.33-.09.648-.698.178.456.634-.582.917-1.548.936-1.43-.406-.678-1.295z"
              />
            </g>
            <g>
              <path
                fill="#fbbd9e"
                d="M37.908 90.815l.189.657 2.575-2.892 1.092.151.248 1.663-2.201 4.86-1.452 2.132h-3.2l-1.103-2.251-.936-3.13 1.004-1.776 2.209-.43z"
              />
            </g>
            <g>
              <path
                fill="#c69695"
                d="M41.778 88.814l.235 1.58.197-.43-.33-1.22-.47-.254z"
              />
            </g>
            <g>
              <path
                fill="#fbbd9e"
                d="M32.075 76.112l-.23.648v1.408l-1.446.4-2.799-.572-1.18-.97.098-.666.047-2.128.826-.804 3.807-.228 1.333.78z"
              />
            </g>
            <g>
              <path
                fill="#fbbd9e"
                d="M32.933 67.487l.952.674.761 1.464v1.295l-.265.781.265.837-.584 2.69-.844.89h-1.141l-1.156-1.225-1.111-3.401.495-4.302z"
              />
            </g>
            <g>
              <path
                fill="#a39c95"
                d="M31.87 69.313v-.964l1.064-.864-.787-1.142-2.106-.203-2.081.838-1.75 1.446-.739 1.397-.151 2.208.762 1.675 1.115 1.219 1.549.634 1.39-.108.786-.558.516-1.01.937-1.243h.407l.202.711.584-.255v-1.522l.203-1.422-1.142-.305z"
              />
            </g>
            <g>
              <path
                fill="#f4efe9"
                d="M34.598 81.9l-1.808.68-1.04 2.984 1.119 6.374 1.974.546 2.209-.626 1.042-1.273-.95-6.09-.938-1.611z"
              />
            </g>
            <g>
              <path
                fill="#f4efe9"
                d="M25.63 77.243l1.714 1.79 2.853.571 2.485.115V77.7l-2.12.267-2.876-.724-1.485-1.123z"
              />
            </g>
            <g>
              <path fill="#c69695" d="M38.393 95.132l.23-1.695-.527-1.967z" />
            </g>
            <g>
              <path
                fill="#e6fbff"
                d="M78.9 9.191l-6.19-3.577 5.358-3.094 6.19 3.578z"
              />
            </g>
            <g>
              <path
                fill="#c9e7ed"
                d="M78.905 5.625v3.572l-6.195-3.58-4.37 2.529v7.147l4.37 7.579 27.323 15.77 4.373-2.526v-7.147l-4.373-7.578-6.192-3.575v-3.574l-4.375-7.566-6.19-3.576z"
              />
            </g>
            <g>
              <path
                fill="#e6fbff"
                d="M88.638 0L83.28 3.096l6.19 3.576 5.36-3.102z"
              />
            </g>
            <g>
              <path
                fill="#e6fbff"
                d="M100.03 21.385l-6.19-3.575 5.359-3.09 6.188 3.572z"
              />
            </g>
            <g>
              <path
                fill="#bedee5"
                d="M93.845 14.238L89.47 6.673l5.36-3.103 4.373 7.575z"
              />
            </g>
            <g>
              <path
                fill="#bedee5"
                d="M104.403 28.961l-4.373-7.578 5.358-3.093 4.37 7.58z"
              />
            </g>
            <g>
              <path
                fill="#9fcfd6"
                d="M99.198 14.725l-5.358 3.092v-3.574l5.358-3.093z"
              />
            </g>
            <g>
              <path
                fill="#9fcfd6"
                d="M109.766 33.024l-5.356 3.092V28.97l5.356-3.09z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M76.859 24.715l-.349-1.59 2.402-.895v1.297z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M71.986 14.973h-.517l-.609-.648 2.054-1.185 1.122.648z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M79.996 23.97l-.796-1.378 2.056-1.182.792 1.376z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M79.2 22.586l.324-.71.865-.847 1.184-.339-.317.715z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M73.8 18.228l-.21-2.183 2.262-.945v1.94l-.727.693z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M76.485 21.298l-1.535-1.085.604-.838 1.445-.345.952.546-.047 1.004z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M73.873 13.354l-.514-.378-.279-.998 2.052-1.188.794 1.376z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M74.652 13.433l-.782-.076 2.054-1.187.78.078z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M79.2 22.589l.797 1.378-1.566.902-.792-1.375-.779-.082v1.304l-2.21-1.282v-1.299l-.781-.813-.793.455-1.562-2.707.793-.465-.32-1.08-1.127-.645v-2.557l1.126.648.321-.712-.793-1.375 1.562-.904.793 1.377.782.076v-1.299l2.21 1.274v1.304l.778.816.792-.457 1.566 2.71-.796.463.324 1.078 1.124.647v2.56l-1.124-.65zm-1.49-3.975l-1.145-1.98-1.618-.932-1.145.66v1.872l1.145 1.978 1.618.936 1.145-.663z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M76.86 13.41l-2.21-1.274 2.052-1.186 2.21 1.277z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M79.996 17.784l-1.566-2.71 2.053-1.184 1.565 2.709z"
              />
            </g>
            <g>
              <path fill="#cc934c" d="M80.65 22.531v-2.56l2.05-1.181v2.551z" />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M76.86 14.707v-1.304l2.053-1.183v1.304z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M79.524 19.317l-.324-1.079 2.056-1.188.317 1.082z"
              />
            </g>
            <g>
              <path
                fill="#e09d38"
                d="M74.946 20.207L73.8 18.228l2.052-1.188 1.142 1.984z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M77.639 15.528l-.779-.814 2.054-1.184.777.813z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M80.654 19.972l-1.124-.647 2.049-1.185 1.125.65z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M85.112 29.978l-.242-1.103 1.67-.625v.902z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M81.724 23.195h-.358l-.426-.449 1.428-.826.784.45z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M87.293 29.45l-.553-.956 1.428-.824.55.958z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M86.74 28.495l.225-.497.598-.584.827-.234-.223.491z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M82.984 25.466l-.144-1.517 1.572-.659v1.352l-.507.484z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M84.853 27.599l-1.073-.754.424-.586 1.002-.239.663.383-.033.692z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M83.032 22.07l-.355-.263-.197-.696 1.429-.821.55.956z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M83.03 22.074l.542.056L85 21.303l-.542-.053z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M86.74 28.5l.554.957-1.086.626-.557-.953-.54-.054v.903l-1.537-.892v-.903l-.542-.569-.55.322-1.09-1.885.556-.32-.225-.753-.783-.453v-1.774l.783.45.225-.492-.557-.961 1.09-.629.55.959.543.054v-.903l1.537.885v.905l.54.571.557-.32 1.089 1.888-.556.318.224.75.782.454v1.774l-.782-.452zm-1.033-2.762l-.8-1.38-1.129-.65-.799.459v1.301l.8 1.38 1.128.65.8-.46z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M85.107 22.11l-1.537-.885 1.428-.825 1.538.889z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M87.289 25.154L86.2 23.265l1.427-.825 1.084 1.888z"
              />
            </g>
            <g>
              <path fill="#cc934c" d="M87.74 28.452v-1.775l1.426-.827v1.783z" />
            </g>
            <g>
              <path fill="#cc934c" d="M85.11 23.016v-.905l1.429-.821v.905z" />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M86.965 26.225l-.225-.751 1.427-.824.223.748z"
              />
            </g>
            <g>
              <path
                fill="#e09d38"
                d="M83.776 26.844l-.796-1.38 1.429-.824.793 1.38z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M85.65 23.582l-.54-.571 1.429-.821.539.567z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M87.742 26.68l-.782-.454 1.427-.826.78.453z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M85.924 22.095l-.394-1.756 2.662-.989v1.436z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M80.533 11.313h-.571l-.672-.717 2.27-1.316 1.243.717z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M89.392 21.264l-.882-1.524 2.271-1.31.885 1.526z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M88.51 19.745l.356-.788.96-.931 1.313-.376-.357.785z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M82.536 14.918l-.226-2.413 2.498-1.045v2.148l-.807.766z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M85.513 18.318l-1.703-1.201.672-.926 1.598-.381 1.051.605-.044 1.104z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M82.621 9.528l-.567-.42-.314-1.105 2.271-1.313.88 1.53z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M83.48 9.615l-.86-.086 2.27-1.309.862.084z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M88.516 19.742l.884 1.524-1.732.998-.877-1.525-.86-.08v1.435l-2.45-1.415v-1.437l-.86-.905-.882.507-1.73-2.997.88-.51-.355-1.197-1.244-.72v-2.823l1.244.716.355-.791-.88-1.523L81.74 8l.882 1.525.86.086V8.176l2.45 1.412v1.435l.86.907.877-.504L89.4 14.42l-.884.515.357 1.192 1.248.723v2.83l-1.248-.727zm-1.649-4.394L85.6 13.155l-1.791-1.03-1.268.726v2.068l1.268 2.192 1.791 1.037 1.267-.734z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M85.93 9.594L83.48 8.18l2.272-1.311L88.2 8.277z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M89.392 14.424L87.66 11.43l2.272-1.31 1.734 2.996z"
              />
            </g>
            <g>
              <path fill="#cc934c" d="M90.12 19.683v-2.83l2.27-1.313v2.83z" />
            </g>
            <g>
              <path fill="#cc934c" d="M85.93 11.022V9.587l2.268-1.317v1.44z" />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M88.866 16.126l-.356-1.193 2.271-1.313.358 1.199z"
              />
            </g>
            <g>
              <path
                fill="#e09d38"
                d="M83.808 17.113L82.54 14.92l2.273-1.31 1.266 2.197z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M86.79 11.93l-.86-.908 2.268-1.312.862.91z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M90.117 16.85l-1.247-.723 2.271-1.307 1.247.716z"
              />
            </g>
            <g>
              <path
                fill="#d66b24"
                d="M94.507 34.485l-.377-1.69 2.56-.955v1.385z"
              />
            </g>
            <g>
              <path
                fill="#fdbd6f"
                d="M89.313 24.108h-.554l-.649-.693 2.188-1.265 1.198.696z"
              />
            </g>
            <g>
              <path
                fill="#fdb053"
                d="M97.847 33.69L97 32.224l2.184-1.264.849 1.466z"
              />
            </g>
            <g>
              <path
                fill="#d66b24"
                d="M97 32.22l.341-.756.923-.902 1.264-.362-.344.757z"
              />
            </g>
            <g>
              <path
                fill="#d66b24"
                d="M91.241 27.58l-.221-2.327 2.408-1.003v2.07l-.774.73z"
              />
            </g>
            <g>
              <path
                fill="#fdbd6f"
                d="M94.094 30.843l-1.634-1.15.645-.9 1.539-.363 1.013.584-.047 1.065z"
              />
            </g>
            <g>
              <path
                fill="#fdb053"
                d="M91.32 22.387l-.553-.406-.297-1.06 2.187-1.261.846 1.462z"
              />
            </g>
            <g>
              <path
                fill="#fdbd6f"
                d="M92.144 22.47l-.824-.085 2.183-1.265.83.085z"
              />
            </g>
            <g>
              <path
                fill="#ef8f2d"
                d="M97.005 32.228l.847 1.466-1.672.961-.844-1.47-.826-.075v1.38l-2.36-1.363v-1.379l-.825-.877-.85.49-1.666-2.886.849-.493-.344-1.152-1.204-.691v-2.724l1.204.693.344-.758-.85-1.469 1.667-.961.85 1.467.824.085v-1.389l2.361 1.363v1.388l.826.872.844-.494 1.672 2.893-.847.487.341 1.156 1.197.689v2.728l-1.197-.694zm-1.59-4.236l-1.224-2.113-1.723-.996-1.223.701v1.995l1.223 2.11 1.723.997 1.224-.707z"
              />
            </g>
            <g>
              <path
                fill="#fdbd6f"
                d="M94.502 22.442L92.14 21.08l2.19-1.26 2.355 1.362z"
              />
            </g>
            <g>
              <path
                fill="#fdbd6f"
                d="M97.852 27.105L96.18 24.21l2.188-1.261 1.67 2.89z"
              />
            </g>
            <g>
              <path fill="#d66b24" d="M98.54 32.158V29.43l2.187-1.26v2.724z" />
            </g>
            <g>
              <path fill="#d66b24" d="M94.51 23.828V22.44l2.183-1.259v1.383z" />
            </g>
            <g>
              <path
                fill="#fdb053"
                d="M97.341 28.747L97 27.592l2.184-1.262.343 1.152z"
              />
            </g>
            <g>
              <path
                fill="#e09d38"
                d="M92.464 29.693l-1.224-2.114 2.188-1.259 1.218 2.11z"
              />
            </g>
            <g>
              <path
                fill="#fdb053"
                d="M95.336 24.697l-.826-.872 2.184-1.265.825.875z"
              />
            </g>
            <g>
              <path
                fill="#fdbd6f"
                d="M98.537 29.435l-1.197-.69 2.186-1.265 1.198.694z"
              />
            </g>
            <g>
              <path
                fill="#e6fbff"
                d="M118.068 11.954L114 9.603l3.522-2.033 4.068 2.351z"
              />
            </g>
            <g>
              <path
                fill="#c9e7ed"
                d="M118.073 9.609v2.347l-4.071-2.353-2.872 1.663v4.697l2.872 4.98 17.955 10.363 2.874-1.661v-4.696l-2.874-4.98-4.069-2.349v-2.349l-2.875-4.97-4.068-2.351z"
              />
            </g>
            <g>
              <path
                fill="#e6fbff"
                d="M125.008 10.295l-4.068-2.35 3.522-2.035 4.07 2.346z"
              />
            </g>
            <g>
              <path
                fill="#e6fbff"
                d="M131.948 19.97l-4.068-2.35 3.523-2.03 4.066 2.347z"
              />
            </g>
            <g>
              <path
                fill="#bedee5"
                d="M127.885 15.27L125.01 10.3l3.523-2.039 2.873 4.979z"
              />
            </g>
            <g>
              <path
                fill="#bedee5"
                d="M134.823 24.942l-2.873-4.98 3.52-2.032 2.874 4.98z"
              />
            </g>
            <g>
              <path
                fill="#9fcfd6"
                d="M131.4 15.579l-3.52 2.033v-2.35l3.52-2.032z"
              />
            </g>
            <g>
              <path
                fill="#9fcfd6"
                d="M138.35 27.606l-3.52 2.031v-4.696l3.52-2.031z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M116.73 22.153l-.23-1.047 1.578-.586v.852z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M113.52 15.744h-.338l-.402-.425 1.35-.779.738.425z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M118.784 21.662l-.524-.905 1.351-.777.52.904z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M118.26 20.756l.212-.467.57-.557.778-.222-.209.47z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M114.718 17.895l-.138-1.434 1.486-.621v1.274l-.477.456z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M116.478 19.9l-1.008-.712.397-.551.949-.227.626.36-.03.66z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M114.762 14.685l-.34-.249-.182-.655 1.348-.781.521.904z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M114.76 14.686l.512.049 1.349-.779-.514-.05z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M118.261 20.75l.525.905-1.03.593-.522-.904-.51-.053v.858l-1.452-.844v-.853l-.513-.534-.522.299-1.027-1.779.521-.307-.21-.708-.741-.425v-1.68l.74.427.211-.47-.521-.902 1.027-.593.522.904.513.049v-.854l1.452.838v.858l.51.535.522-.3 1.03 1.781-.525.304.213.708.738.426v1.682l-.738-.427zm-.983-2.611l-.75-1.302-1.06-.613-.752.435v1.23l.751 1.3 1.06.614.751-.435z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M116.724 14.717l-1.454-.837 1.348-.78 1.454.84z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M118.79 17.6l-1.03-1.781 1.349-.779 1.028 1.78z"
              />
            </g>
            <g>
              <path fill="#cc934c" d="M119.22 20.72v-1.683l1.348-.777v1.676z" />
            </g>
            <g>
              <path fill="#cc934c" d="M116.73 15.574v-.858l1.348-.776v.856z" />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M118.472 18.61l-.212-.71 1.351-.78.209.71z"
              />
            </g>
            <g>
              <path
                fill="#e09d38"
                d="M115.473 19.191l-.753-1.3 1.348-.781.75 1.304z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M117.24 16.113l-.51-.535 1.348-.778.512.535z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M119.22 19.034l-.74-.425 1.348-.779.74.428z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M122.15 25.605l-.16-.725 1.097-.41v.593z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M119.4 20.863l.28.296h.236l.937-.543-.516-.295z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M123.583 25.27l-.363-.628.937-.542.362.63z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M123.759 23.922l-.392.383-.147.328.936-.542.147-.324z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M120.654 21.652l.095.997.604-.224.333-.317v-.888z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M121.549 23.168l-.279.386.705.494.647-.33.02-.455-.436-.251z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M120.783 20.42l-.234-.173-.129-.457.939-.54.362.629z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M120.78 20.413l.356.035.94-.543-.358-.033z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M123.212 24.641l.363.628-.714.412-.366-.626-.353-.037v.594l-1.01-.586v-.593l-.357-.375-.363.213-.716-1.239.367-.21-.148-.495-.515-.298v-1.165l.515.294.148-.324-.367-.63.716-.414.363.63.356.036v-.594l1.011.582v.594l.353.376.366-.21.716 1.24-.365.21.148.493.512.298v1.166l-.512-.298zm-.685-1.816l-.522-.906-.739-.428-.52.301v.856l.52.907.739.428.522-.304z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M122.14 20.443l-1.01-.582.94-.541 1.008.583z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M123.576 22.444l-.716-1.24.937-.544.713 1.24z"
              />
            </g>
            <g>
              <path fill="#cc934c" d="M123.871 23.444v1.166l.938-.538V22.9z" />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M122.141 20.439v.594l.938-.54v.001-.594z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M123.22 22.653l.148.494.935-.543-.146-.49z"
              />
            </g>
            <g>
              <path
                fill="#e09d38"
                d="M121.272 23.548l-.522-.907.937-.541.522.906z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M122.14 21.032l.354.375.939-.541-.356-.373z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M123.36 23.143l.512.298.938-.545-.514-.297z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M122.678 20.434l-.258-1.154 1.749-.65v.944z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M119.138 13.346h-.377l-.441-.47 1.49-.866.819.472z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M124.961 19.892l-.581-1.001 1.494-.861.58 1.002z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M124.38 18.887l.234-.517.631-.613.862-.247-.233.516z"
              />
            </g>
            <g>
              <path
                fill="#cc934c"
                d="M120.459 15.712l-.149-1.586 1.643-.686v1.412l-.53.502z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M122.409 17.948l-1.119-.788.443-.608 1.05-.252.69.397-.03.726z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M120.509 12.175l-.373-.275-.206-.727 1.494-.863.579 1.005z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M120.51 12.172l.566.056 1.492-.86-.567-.056z"
              />
            </g>
            <g>
              <path
                fill="#e3bd5e"
                d="M124.382 18.886l.581 1-1.14.656-.575-1.002-.566-.052v.943l-1.61-.93v-.945l-.565-.594-.579.334-1.136-1.97.578-.335-.234-.787-.816-.473v-1.855l.816.47.234-.52-.578-1 1.136-.656.579 1.001.565.057v-.944l1.61.93v.941l.566.597.576-.332 1.14 1.967-.582.34.234.783.819.475v1.858l-.819-.475zm-1.084-2.888l-.833-1.442-1.177-.678-.833.478v1.36l.833 1.442 1.177.682.833-.483z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M122.68 12.21l-1.61-.927 1.494-.863 1.606.926z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M124.96 15.389l-1.14-1.968 1.494-.861 1.139 1.969z"
              />
            </g>
            <g>
              <path fill="#cc934c" d="M125.44 18.843v-1.859l1.493-.864v1.86z" />
            </g>
            <g>
              <path fill="#cc934c" d="M122.68 13.158v-.943l1.49-.865v.946z" />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M124.614 16.507l-.234-.783 1.494-.864.233.788z"
              />
            </g>
            <g>
              <path
                fill="#e09d38"
                d="M121.283 17.163l-.833-1.441 1.494-.862.832 1.444z"
              />
            </g>
            <g>
              <path
                fill="#ffc654"
                d="M123.246 13.75l-.566-.598 1.49-.862.566.598z"
              />
            </g>
            <g>
              <path
                fill="#fbe979"
                d="M125.44 16.984l-.82-.475 1.493-.859.82.47z"
              />
            </g>
            <g>
              <path
                fill="#d66b24"
                d="M128.317 28.568l-.247-1.11 1.683-.628v.91z"
              />
            </g>
            <g>
              <path
                fill="#fdbd6f"
                d="M124.9 21.746h-.363l-.427-.454 1.439-.832.786.457z"
              />
            </g>
            <g>
              <path
                fill="#fdb053"
                d="M130.517 28.045l-.557-.963 1.434-.832.56.963z"
              />
            </g>
            <g>
              <path
                fill="#d66b24"
                d="M129.96 27.088l.224-.497.607-.593.83-.238-.227.497z"
              />
            </g>
            <g>
              <path
                fill="#d66b24"
                d="M126.176 24.037l-.146-1.53 1.584-.657v1.36l-.51.48z"
              />
            </g>
            <g>
              <path
                fill="#fdbd6f"
                d="M128.052 26.175l-1.072-.756.422-.59 1.012-.239.667.384-.03.7z"
              />
            </g>
            <g>
              <path
                fill="#fdb053"
                d="M126.23 20.623l-.365-.268-.195-.697 1.436-.828.556.961z"
              />
            </g>
            <g>
              <path
                fill="#fdbd6f"
                d="M126.23 20.618l.539.055 1.44-.83-.547-.056z"
              />
            </g>
            <g>
              <path
                fill="#ef8f2d"
                d="M129.955 27.091l.556.964-1.098.63-.554-.966-.543-.049v.908l-1.552-.897v-.905l-.54-.577-.56.322-1.096-1.896.558-.324-.225-.757-.791-.455v-1.79l.79.456.226-.498-.558-.965 1.096-.632.56.964.54.057v-.914l1.552.895v.913l.543.573.554-.325 1.098 1.902-.556.32.224.759.787.454v1.791l-.787-.455zm-1.044-2.785l-.805-1.388-1.132-.655-.804.46v1.31l.804 1.388 1.132.654.805-.464z"
              />
            </g>
            <g>
              <path
                fill="#fdbd6f"
                d="M128.322 20.653l-1.552-.896 1.44-.827 1.547.894z"
              />
            </g>
            <g>
              <path
                fill="#fdbd6f"
                d="M130.52 23.72l-1.1-1.9 1.436-.83 1.1 1.899z"
              />
            </g>
            <g>
              <path fill="#d66b24" d="M130.97 27.041v-1.793l1.437-.828v1.79z" />
            </g>
            <g>
              <path fill="#d66b24" d="M128.32 21.571v-.913l1.435-.828v.91z" />
            </g>
            <g>
              <path
                fill="#fdb053"
                d="M130.184 24.8l-.224-.76 1.434-.83.227.758z"
              />
            </g>
            <g>
              <path
                fill="#e09d38"
                d="M126.975 25.426l-.805-1.389 1.439-.827.8 1.386z"
              />
            </g>
            <g>
              <path
                fill="#fdb053"
                d="M128.863 22.144l-.543-.572 1.435-.832.541.575z"
              />
            </g>
            <g>
              <path
                fill="#fdbd6f"
                d="M130.967 25.254l-.787-.452 1.437-.832.788.456z"
              />
            </g>
            <g>
              <path
                fill="#e6fbff"
                d="M147.413 43.563l-7.613-4.396 6.59-3.807 7.612 4.401z"
              />
            </g>
            <g>
              <path
                fill="#c9e7ed"
                d="M147.421 39.166v4.392l-7.617-4.4-5.374 3.11v8.787l5.374 9.32 33.6 19.396 5.376-3.107v-8.786l-5.376-9.32-7.616-4.4-.001-4.39-5.377-9.31-7.612-4.398z"
              />
            </g>
            <g>
              <path
                fill="#e6fbff"
                d="M160.404 40.466l-7.614-4.399 6.591-3.807 7.612 4.395z"
              />
            </g>
            <g>
              <path
                fill="#e6fbff"
                d="M173.403 58.558l-7.613-4.395 6.59-3.803 7.614 4.397z"
              />
            </g>
            <g>
              <path
                fill="#bedee5"
                d="M165.786 49.77l-5.376-9.31 6.59-3.81 5.377 9.316z"
              />
            </g>
            <g>
              <path
                fill="#bedee5"
                d="M173.4 58.562l5.377 9.32 6.587-3.805-5.373-9.317z"
              />
            </g>
            <g>
              <path
                fill="#afd4da"
                d="M172.371 50.362l-6.59 3.803-.001-4.39 6.591-3.805z"
              />
            </g>
            <g>
              <path
                fill="#afd4da"
                d="M178.78 67.885v8.786l6.587-3.804V64.08z"
              />
            </g>
            <g>
              <path
                fill="#47a05e"
                d="M151.81 57.542v-4.793l2.023-1.169v4.795z"
              />
            </g>
            <g>
              <path
                fill="#47a05e"
                d="M154.525 54.313l-4.395-10.344 2.025-1.169 4.395 10.343z"
              />
            </g>
            <g>
              <path
                fill="#2dcb75"
                d="M150.13 43.97l-4.4 5.265 2.71 1.566v4.787l3.377 1.954V52.75l2.707 1.565z"
              />
            </g>
            <g>
              <path
                fill="#ff958d"
                d="M155.228 60.771l-2.708-1.562 2.024-1.169 2.707 1.563z"
              />
            </g>
            <g>
              <path
                fill="#e8583e"
                d="M158.604 62.715v-4.793l-3.38-1.952v4.797l-2.704-1.56 4.393 10.338 4.397-5.263z"
              />
            </g>
            <g>
              <path
                fill="#ff958d"
                d="M158.6 57.928l-3.38-1.952 2.024-1.166 3.38 1.952z"
              />
            </g>
            <g>
              <path
                fill="#ff958d"
                d="M161.306 64.287L158.6 62.72l2.023-1.17 2.708 1.566z"
              />
            </g>
            <g>
              <path fill="#bf3a28" d="M158.6 62.72v-4.794l2.024-1.166v4.79z" />
            </g>
            <g>
              <path
                fill="#bf3a28"
                d="M156.91 69.544l4.397-5.263 2.025-1.171-4.397 5.264z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
