import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Playlist, Screen, Screens } from "../ViewsPageTypes";
import useCreatePlaylist from "./useCreatePlaylist";
import useCreateView from "./useCreateView";
import useDeletePlaylist from "./useDeletePlaylist";
import useDeleteView from "./useDeleteView";
import useEditView from "./useEditView";
import useSavePlaylist from "./useSavePlaylist";
import useSetActiveView from "./useSetActiveView";
import useUpdateDurationToGlobal from "./useUpdateDurationToGlobal";
import useUpdateTransition from "./useUpdateTransition";

export default function useViewPageEvents() {
  const [createCustomUrlForm] = useForm();
  const [createPlaylistForm] = useForm();

  const history = useHistory();

  const { mutate: createView } = useCreateView();

  const { mutate: deleteView } = useDeleteView();

  const { mutate: editView } = useEditView();

  const { mutate: savePlayList } = useSavePlaylist();

  const { mutate: deletePlaylist } = useDeletePlaylist();

  const { mutate: createPlaylist } = useCreatePlaylist();

  const { mutate: setActiveView } = useSetActiveView();

  const { mutate: updateDurationToGlobal } = useUpdateDurationToGlobal();

  const { mutate: updateTransition } = useUpdateTransition();

  const [isPlaying, setIsPlaying] = useState(false);

  const onCreateButtonClick = (
    viewType: string,
    playlist: Playlist,
    viewName?: string
  ) => {
    const formValues = createCustomUrlForm.getFieldsValue();

    console.log(formValues);

    const viewObject = {
      "Common Screens": { name: viewName, type: viewType },
      "Custom URL": { ...formValues, type: viewType },
      "My Dashboards": { name: viewName, type: viewType },
    };

    createView({
      newViewObject: viewObject[viewType],
      playlist,
    });
  };

  const onPlayButtonClick = () => setIsPlaying(!isPlaying);
  const onDeletePlaylistButtonClick = (playListToDelete: Playlist) => {
    deletePlaylist(playListToDelete);
  };

  const onEditPlaylistButtonClick = (
    playListChanges: Screens,
    playlistId: number
  ) => savePlayList({ playListChanges, playlistId });

  const onEditViewDurationButtonClick = (
    viewToEdit: Screen,
    newTime: number
  ) => {
    const { play_duration, ...rest } = viewToEdit;
    editView({ ...rest, play_duration: newTime });
  };

  const onEditDurationToGlobal = (playlistToEdit: Playlist, newTime: number) =>
    updateDurationToGlobal({ playlistToEdit, newTime });

  const onEditTransitionStyle = (
    playlistToEdit: Playlist,
    newTransition: string
  ) => updateTransition({ playlistToEdit, newTransition });

  const onDeleteButtonClick = (viewToDelete: Screen, playlistId: number) =>
    deleteView({ viewToDelete, playlistId });

  const onDeleteFromAddModalClick = (viewName: string, playlist: Playlist) => {
    const [viewToDelete] = Object.values(playlist.screens).filter(
      (view: Screen) => view.name === viewName
    );

    deleteView({ viewToDelete, playlistId: playlist.playlist_id });
  };

  const onCreatePlaylistButtonClick = () => {
    const formValues = createPlaylistForm.getFieldsValue();
    createPlaylist({ ...formValues, play_duration: "10" });
  };

  const onSetActiveToggle = (
    activate: boolean,
    viewId: number,
    playlistId: number
  ) =>
    setActiveView({
      activate,
      viewId,
      playlistId,
    });
  const onViewButtonClick = (screen: Screen) => {
    history.push(`/views/${screen.screen_id}`);
  };

  return {
    createCustomUrlForm,
    createPlaylistForm,
    isPlaying,
    onDeletePlaylistButtonClick,
    onEditPlaylistButtonClick,
    onEditTransitionStyle,
    onPlayButtonClick,
    onCreateButtonClick,
    onCreatePlaylistButtonClick,
    onEditViewDurationButtonClick,
    onEditDurationToGlobal,
    onDeleteButtonClick,
    onDeleteFromAddModalClick,
    onViewButtonClick,
    onSetActiveToggle,
  };
}
