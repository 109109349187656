import { DUPLICATE_USER_URL, USER_URL } from "../../api.config";
import {
  createDeleteMock,
  createGetMock,
  createPostMock,
  createPutMock,
} from "../../mockServer/createMockHandler";
import { Users } from "./UsersPageTypes";

export const CREATE_USER_MESSAGE = "Successfully created user 1234";
export const DUPLICATE__USER_MESSAGE = "Successfully duplicated user 1234";
export const DELETE_USER_SUCCESS_MESSAGE = "Successfully deleted user 1234";
export const EDIT_USER_MESSAGE = "Successfully edited user 1234";

export const usersList: Users = [
  {
    user_id: 1,
    username: "nagiosadmin",
    email: "test@nagios.com",
    name: "Nagios Admin",
    phone: null,
    enabled: 1,
    is_admin: 1,
    last_password_change: "2021-08-20 13:35:06",
    auth_type: "local",
    ad_server: null,
    ad_username: null,
    ldap_server: null,
    ldap_username: null,
    allow_secondary_local_login: 1,
    api_enabled: 1,
    backend_ticket: null,
    is_authenticated: 0,
    api_key: null,
    created_at: "2021-08-20 13:35:06",
    updated_at: null,
    meta: {
      language: "en_US",
      date_format: "1",
      number_format: "1",
      week_format: "1",
      authorized_for_all_objects: "",
      authorized_to_configure_objects: "",
      authorized_for_all_object_commands: "",
      authorized_for_monitoring_system: "",
      advanced_user: "",
      readonly_user: "",
      mobile_number: "",
      autodeploy_access: "",
      ccm_access: "",
      ccm_access_list: "",
      auth_type: "local",
      allow_local: "",
      auth_server_id: "",
      ldap_ad_username: "",
      ldap_ad_dn: "",
    },
  },
  {
    user_id: 2,
    username: "admin_bob",
    email: "bobadmin@nagios.com",
    name: "Bob the Admin, I",
    phone: "867-5309",
    enabled: 1,
    is_admin: 1,
    last_password_change: null,
    auth_type: "local",
    ad_server: null,
    ad_username: null,
    ldap_server: null,
    ldap_username: null,
    allow_secondary_local_login: 1,
    api_enabled: 0,

    is_authenticated: 0,
    backend_ticket: null,
    api_key: null,
    created_at: null,
    updated_at: null,
  },
  {
    user_id: 3,
    username: "admin_bob2",
    email: "bobadmin2@nagios.com",
    name: "Bob the Admin, II",
    phone: "867-5309",
    enabled: 1,
    is_admin: 1,
    last_password_change: null,
    auth_type: "local",
    ad_server: null,
    ad_username: null,
    ldap_server: null,
    ldap_username: null,
    allow_secondary_local_login: 1,
    api_enabled: 0,

    is_authenticated: 0,
    backend_ticket: null,
    api_key: null,
    created_at: null,
    updated_at: null,
  },
  {
    user_id: 4,
    username: "admin_bob3",
    email: "bobadmin3@nagios.com",
    name: "Bob the Admin, III",
    phone: "867-5309",
    enabled: 1,
    is_admin: 1,
    last_password_change: null,
    auth_type: "local",
    ad_server: null,
    ad_username: null,
    ldap_server: null,
    ldap_username: null,
    allow_secondary_local_login: 1,
    api_enabled: 0,
    backend_ticket: null,
    api_key: null,
    created_at: null,
    updated_at: null,

    is_authenticated: 0,
  },
  {
    user_id: 5,
    username: "john_doe",
    email: "john_doe@nagios.com",
    name: "John the Dev",
    phone: "867-5309",
    enabled: 1,
    is_admin: 0,
    last_password_change: null,
    auth_type: "local",
    ad_server: null,
    ad_username: null,
    ldap_server: null,
    ldap_username: null,
    allow_secondary_local_login: 1,
    api_enabled: 0,
    backend_ticket: null,
    api_key: null,
    created_at: null,
    is_authenticated: 1,
    updated_at: null,
    meta: {
      language: "en_US",
      date_format: "1",
      number_format: "1",
      week_format: "1",
      authorized_for_all_objects: "1",
      authorized_to_configure_objects: "1",
      authorized_for_all_object_commands: "1",
      authorized_for_monitoring_system: "1",
      advanced_user: "",
      readonly_user: "",
      mobile_number: "",
      autodeploy_access: "",
      ccm_access: "",
      ccm_access_list: "",
      auth_type: "local",
      allow_local: "",
      auth_server_id: "",
      ldap_ad_username: "",
      ldap_ad_dn: "",
    },
  },
  {
    user_id: 6,
    username: "inactive_user",
    email: "inactive@nagios.com",
    name: "Inactive Ian",
    phone: "867-5309",
    enabled: 0,
    is_admin: 0,
    last_password_change: null,
    auth_type: "local",
    ad_server: null,
    ad_username: null,
    is_authenticated: 1,
    ldap_server: null,
    ldap_username: null,
    allow_secondary_local_login: 1,
    api_enabled: 0,
    backend_ticket: null,
    api_key: null,
    created_at: null,
    updated_at: null,
  },
];
export const mocks = [
  createGetMock(USER_URL, 200, {
    users: usersList,
  }),
  createDeleteMock(USER_URL, 200, {
    message: DELETE_USER_SUCCESS_MESSAGE,
  }),
  createPutMock(USER_URL, 200, {
    message: EDIT_USER_MESSAGE,
  }),
  createPostMock(USER_URL, 200, {
    message: CREATE_USER_MESSAGE,
  }),
  createPutMock(DUPLICATE_USER_URL, 200, {
    message: CREATE_USER_MESSAGE,
  }),
];
