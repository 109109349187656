import styled from "styled-components";
import { Divider } from "antd";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";

export const StyledTable = styled.table`
  width: 100%;
`;

export const StyledRow = styled.tr`
  :hover {
    background-color: ${themeVariables.colorBluePale};
    border-radius: 6px;
  }
  > td:first-child {
    padding-left: 15px;
    width: 15%;
  }
  > td:nth-child(2) {
    font-weight: bold;
    text-align: left !important;
  }
  > td:nth-child(3) {
    width: 10%;
    font-weight: 500;
  }
  > td:last-child {
    width: 20%;
    text-align: right !important;
    padding-right: 15px;
    & .ant-btn-link {
    	padding: 4px 0;
    }
  }
`;

export const StyledDiv = styled.div`
  margin: 20px 0px;
`;

export const LastCheck = styled.div`
  color: ${themeVariables.colorGrayLight2};
  font-size: 0.9em;
  font-style: italic;
  margin-top: 20px;
`;

export const TableDivider = styled(Divider)`
  border-top: 1px solid ${themeVariables.colorGrayLight4};
  margin: 15px !important;
  width: 300px;
  min-width: 200px;
`;

export const Centered = styled.div`
  margin: 10px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    font-size: 20px;
    color: ${themeVariables.colorGrayLight};
  }
  p {
    color: ${themeVariables.colorGrayLight2};
    font-size: 12px;
  }
`;

export const CenteredTable = styled(Centered)`
  margin: 10px -15px;
`;

export const StatusCircle = styled.div`
  border-radius: 50%;
  width: 19px;
  height: 19px;
  background-color: ${themeVariables.colorPending};
  border: 1px solid #C9CDD3;
`;
