import { Button, Popover } from "antd";
import React from "react";
import { useToggle } from "../../../../hooks";
import Actions from "../../../Shared/Molecules/Actions/Actions";
import useUsersPageEvents from "../../hooks/useUsersPageEvents";
import { EmailUserModal } from "../../Molecules/EmailUserModal";
import { UserModal } from "../../Molecules/UserModal";
import { User } from "../../UsersPageTypes";

export type Props = {
  user: User;
};

export default function UserTableActions({ user }: Props) {
  const editUserToggleHook = useToggle();
  const emailUserToggleHook = useToggle();
  const {
    onDeleteUserClick,
    onDisableUserClick,
    onMasqueradeClicked,
    onDuplicateUserClick,
  } = useUsersPageEvents();
  return (
    <>
      <Popover
        content={
          <Actions
            deleteClicked={() => onDeleteUserClick([user])}
            editClicked={() => editUserToggleHook.toggleOpen()}
            duplicateClicked={() => onDuplicateUserClick(user)}
            emailClicked={() => emailUserToggleHook.toggleOpen()}
            disableClicked={() => onDisableUserClick([user], "Disable")}
            enableClicked={() => onDisableUserClick([user], "Enable")}
            masqueradeClicked={() => onMasqueradeClicked(user)}
          />
        }
        placement={"bottom"}
      >
        <Button type="link" style={{ padding: 0 }} data-testid="Actions">
          Actions
        </Button>
      </Popover>
      <UserModal toggleHook={editUserToggleHook} user={user} />
      <EmailUserModal toggleHook={emailUserToggleHook} users={[user]} />
    </>
  );
}
