import { Form, Select } from "antd";
import React from "react";
import { AdvForm, StyledCheckbox } from "../../../Shared/Atoms/Form/Form";
import { YesNo, HostState, ServiceState, TimePeriod } from "./AdvancedData";

export type formType = {
  form: any;
};

const CreateReportAdvancedForm = (props: formType) => {
  return (
    <AdvForm
      layout="vertical"
      name="createReportAdvanced"
      initialValues={{
        displayGraphChecked: false,
        hideUnknownState: false,
        hideDowntimeChecked: false,
      }}
    >
      <Form.Item
        name={["reportAdv", "assumeInit"]}
        label="Assume Initial States"
        className="short float"
      >
        <Select>
          {YesNo.map((data) => (
            <Select.Option value={data.value} key={data.id}>
              {data.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={["reportAdv", "assumeRetention"]}
        label="Assume State Retention"
        className="short"
      >
        <Select>
          {YesNo.map((data) => (
            <Select.Option value={data.value} key={data.id}>
              {data.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={["reportAdv", "assumeDowntime"]}
        label="Assume States During Program Downtime"
        className="short float"
      >
        <Select>
          {YesNo.map((data) => (
            <Select.Option value={data.value} key={data.id}>
              {data.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={["reportAdv", "includeSoftState"]}
        label="Include Soft States"
        className="short"
      >
        <Select>
          {YesNo.map((data) => (
            <Select.Option value={data.value} key={data.id}>
              {data.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={["reportAdv", "firstHostState"]}
        label="First Assumed Host State"
        className="short float"
      >
        <Select>
          {HostState.map((data) => (
            <Select.Option value={data.value} key={data.id}>
              {data.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={["reportAdv", "firstServiceState"]}
        label="First Assumed Service State"
        className="short"
      >
        <Select>
          {ServiceState.map((data) => (
            <Select.Option value={data.value} key={data.id}>
              {data.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={["reportAdv", "reportTimePeriod"]}
        label="Report Time Period"
        className="short"
      >
        <Select>
          {TimePeriod.map((data) => (
            <Select.Option value={data.value} key={data.id}>
              {data.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={["reportAdv", "displaySvcGrph"]}
        valuePropName="checked"
        className="check short float"
      >
        <StyledCheckbox>Display Service Performance Graphs</StyledCheckbox>
      </Form.Item>
      <Form.Item
        name={["reportAdv", "hideUnknownState"]}
        valuePropName="checked"
        className="check short"
      >
        <StyledCheckbox>Hide Unknown / Unreachable States</StyledCheckbox>
      </Form.Item>
      <Form.Item
        name={["reportAdv", "hideDowntime"]}
        valuePropName="checked"
        className="check short float"
      >
        <StyledCheckbox>Hide Scheduled Downtime</StyledCheckbox>
      </Form.Item>
      <Form.Item
        name={["reportAdv", "dontShowSvcData"]}
        valuePropName="checked"
        className="check short"
      >
        <StyledCheckbox>Do Not Show Service Data</StyledCheckbox>
      </Form.Item>
      <Form.Item
        name={["reportAdv", "hideWarningStates"]}
        valuePropName="checked"
        className="short"
      >
        <StyledCheckbox>Hide Warning States</StyledCheckbox>
      </Form.Item>
    </AdvForm>
  );
};

export default CreateReportAdvancedForm;
