import { VIEW_URL, PLAYLIST_URL, CREATE_VIEW_URL } from "../../api.config";
import {
  createDeleteMock,
  createPutMock,
  createPostMock,
  createGetMock,
} from "../../mockServer/createMockHandler";
import { getRandomNumberInRange } from "../../utils/logic";
import { Playlist, Playlists } from "./ViewsPageTypes";

export const CREATE_PLAYLIST_SUCCESS_MESSAGE =
  "Successfully created playlist 1234";
export const DELETE_PLAYLIST_SUCCESS_MESSAGE =
  "Successfully deleted playlist 1234";
export const EDIT_PLAYLIST_SUCCESS_MESSAGE =
  "Successfully edited playlist 1234";
export const CREATE_VIEW_SUCCESS_MESSAGE =
  "Successfully added screen 12345 to playlist 12345";
export const DELETE_VIEW_PLAYLIST_SUCCESS_MESSAGE =
  "Successfully deleted screen 12345 from playlist 12345";
export const EDIT_VIEW_SUCCESS_MESSAGE =
  "Successfully edited screen 12345 in playlist 12345";

export const createScreenObject = (
  screen_id: number,
  playListId: number,
  name: string,
  url: string,
  type: string,
  isActive?: boolean,
  playDuration?: number
) => ({
  screen_id,
  playlist_id: playListId,
  name,
  url,
  type,
  active: isActive ? isActive : getRandomNumberInRange(2) === 1,
  play_duration: playDuration ? playDuration : 10,
  play_order: screen_id,
});

export const createPlaylist = (id: number) => ({
  "1": createScreenObject(
    1,
    id,
    "Corporate",
    "https://www.nagios.com/",
    "Dashboards"
  ),
  "2": createScreenObject(
    2,
    id,
    "Birdseye",
    "https://nagiosxi.demos.nagios.com/nagiosxi/login.php?redirect=/nagiosxi/index.php%3f&noauth=1",
    "Common Screen"
  ),
  "3": createScreenObject(
    3,
    id,
    "Operations Screen",
    "https://www.nagios.com/resources/",
    "Dashboards"
  ),
});

export const playlists: Playlists = [
  {
    playlist_id: 1,
    name: "Test List",
    screens: createPlaylist(1),
    play_duration: 10,
    transition: "fade In",
  },
  {
    playlist_id: 2,
    name: "Test List 2",
    screens: createPlaylist(2),
    play_duration: 10,
    transition: "fade In Down",
  },
];

export const mocks = [
  createGetMock(PLAYLIST_URL, 200, {
    playlists: playlists,
  }),
  createGetMock(VIEW_URL(1, 1), 200, {
    views: createPlaylist(1),
  }),
  createDeleteMock(PLAYLIST_URL, 200, {
    message: DELETE_PLAYLIST_SUCCESS_MESSAGE,
  }),
  createPutMock(PLAYLIST_URL, 200, {
    message: EDIT_PLAYLIST_SUCCESS_MESSAGE,
  }),
  createPostMock(PLAYLIST_URL, 200, {
    message: CREATE_PLAYLIST_SUCCESS_MESSAGE,
  }),

  ...playlists
    .map(({ playlist_id, screens }: Playlist) =>
      Object.values(screens).map(({ screen_id: screenId }) =>
        createDeleteMock(VIEW_URL(playlist_id, screenId), 200, {
          message: DELETE_VIEW_PLAYLIST_SUCCESS_MESSAGE,
        })
      )
    )
    .flat()
    .flat(),
  ...playlists.map(({ playlist_id }: Playlist) =>
    createPostMock(CREATE_VIEW_URL(playlist_id), 200, {
      message: CREATE_VIEW_SUCCESS_MESSAGE,
    })
  ),
  ...playlists
    .map(({ playlist_id, screens }: Playlist) =>
      Object.values(screens).map(({ screen_id: screenId }) =>
        createPutMock(VIEW_URL(playlist_id, screenId), 200, {
          message: EDIT_PLAYLIST_SUCCESS_MESSAGE,
        })
      )
    )
    .flat()
    .flat(),
];
