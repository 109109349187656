import { Dashboard, Dashlet } from "../../DashboardPage/DashboardPageTypes";
import useCreateDashlet from "./useCreateDashlet";
import useDeleteDashlet from "./useDeleteDashlet";
import useDuplicateDashlet from "./useDuplicateDashlet";
import useEditDashlet from "./useEditDashlet";
import usePinDashlet from "./usePinDashlet";
import useSaveDashlets from "./useSaveDashboards";

export default function useDashletPageEvents(dashboard_id) {
  const { mutate: createDashlet } = useCreateDashlet();
  const { mutate: editDashlet } = useEditDashlet();
  const { mutate: deleteDashlet } = useDeleteDashlet();
  const { mutate: duplicateDashlet } = useDuplicateDashlet();
  const { mutate: pinDashlet } = usePinDashlet();
  const { mutate: saveDashlets } = useSaveDashlets();

  const onCreateDashletButtonClick = (dashletToCreate: Dashlet) =>
    createDashlet({
      dashletToCreate,
      dashboard_id,
    });

  const onDeleteDashletButtonClick = (dashletToDelete: Dashlet) => {
    deleteDashlet({ dashletToDelete, dashboard_id });
  };

  const onDuplicateDashletButtonClick = (
    dashletToDuplicate: Dashlet,
    id: number
  ) => duplicateDashlet({ dashletToDuplicate, dashboardToCopyToId: id });

  const onEditDashletButtonClick = (dashletToEdit: Dashlet) =>
    editDashlet({ dashletToEdit, dashboard_id });

  const onPinDashletButtonClick = (dashletToPin: Dashlet) =>
    pinDashlet({ dashletToPin, dashboard_id });

  const onSaveDashlets = (dashboardToSave: Dashboard) =>
    saveDashlets(dashboardToSave);

  return {
    onCreateDashletButtonClick,
    onDuplicateDashletButtonClick,
    onDeleteDashletButtonClick,
    onEditDashletButtonClick,
    onPinDashletButtonClick,
    onSaveDashlets,
  };
}
