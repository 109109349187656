import { ResponsiveTreeMap } from "@nivo/treemap";
import { Skeleton } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { STATUS_SUMMARY_SERVICES_DASHLET_URL } from "../../../../api.config";
import { fetchData } from "../../../../utils/axiosHelpers";
import { dashletMockData } from "../../DashletMockData";
import { DashletProps } from "../../DashletViewPageTypes";

export default function ServiceStatusSummaryDashlet({
  demo,
  dashletId,
}: DashletProps) {
  const formatData = (data) => {
    return {
      name: `Total Records ${data.total_records}`,
      color: "hsl(305, 70%, 50%)",
      children: [
        { name: `Ok ${data.ok}`, color: "white", loc: data.ok },
        { name: `Pending ${data.pending}`, color: "white", loc: data.pending },
        {
          name: `Warning ${data.warning}`,
          color: "white",
          children: [
            {
              name: `Warning Handled ${data.handled_warning}`,
              color: "white",
              loc: data.handled_warning,
            },
            {
              name: `Warning Unhandled ${data.unhandled_warning}`,
              color: "white",
              loc: data.unhandled_warning,
            },
          ],
        },
        {
          name: `Unknown ${data.unhandled_unknown}`,
          color: "white",
          children: [
            {
              name: `Unknown Handled ${data.handled_warning}`,
              color: "white",
              loc: data.handled_unknown,
            },
            {
              name: `Unknown Unhandled ${data.unhandled_unknown}`,
              color: "white",
              loc: data.unhandled_unknown,
            },
          ],
        },
        {
          name: `Critical ${data.critical}`,
          color: "white",
          children: [
            {
              name: `Critical Handled ${data.handled_critical}`,
              color: "white",
              loc: data.handled_critical,
            },
            {
              name: `Critical Unhandled ${data.handled_critical}`,
              color: "white",
              loc: data.handled_critical,
            },
          ],
        },
      ],
    };
  };

  let { data, isLoading }: any = useQuery(
    ["Fetch Status Summary Services", dashletId],
    async () => await fetchData(STATUS_SUMMARY_SERVICES_DASHLET_URL),
    demo
      ? {
          enabled: false,
        }
      : {
          select: (data) => formatData(data),
        }
  );

  if (isLoading) {
    return <Skeleton />;
  }

  data = demo ? formatData(dashletMockData["status_summary_services"]) : data;

  return (
    <ResponsiveTreeMap
      data={data}
      identity="name"
      value="loc"
      label="id"
      orientLabel={true}
      leavesOnly={true}
      valueFormat=".02s"
      margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
      labelSkipSize={1}
      isInteractive={true}
      labelTextColor={{ from: "theme", modifiers: [["darker", 1.2]] }}
      colors={["#B6FF67", "#EFEFEF", "#83878D", "#FEFF67", "#FF8067"]}
      parentLabelPosition="left"
      parentLabelTextColor={{ from: "color", modifiers: [["darker", 2]] }}
      borderColor={{ from: "color", modifiers: [["darker", 0.1]] }}
    />
  );
}
