import { Modal, Button } from "antd";
import ContactForm from "../../Molecules/ContactForm/ContactForm";
import styled from "styled-components";
import React from "react";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";
import {
  IconClock,
  IconEmail,
  IconPhone,
  IconPostalMail,
} from "../../../Shared/Atoms/FontIcons/FontIcons";

const FlexBox = styled.div`
  display: flex;
  & > div {
    padding: 0px 15px;
    max-height: 400px;
    overflow: auto;
  }
  & > div:first-child {
    flex: 2;
    & p {
      margin-bottom: 16px;
      color: ${themeVariables.colorGrayLight2};
    }
  }
  & > div:last-child {
    flex: 3;
    & > h4 {
      color: ${themeVariables.colorGrayLight2};
    }
  }
`;

const Head = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const Link = styled.div`
  font-size: 14px;
  font-weight: 500;
  & > span {
    font-weight: 400;
    color: ${themeVariables.colorGrayLight2};
  }
`;

const BottomLink = styled(Link)`
  margin-bottom: 15px;
`;

const Hours = styled.div`
  & > span:first-child {
    display: inline-block;
    width: 52px;
    font-size: 14px;
    font-weight: 500;
  }
  & > span:last-child {
    font-weight: 400;
    color: ${themeVariables.colorGrayLight2};
  }
`;

export type ContactProps = {
  visible: boolean;
  close: () => void;
  upgraded: boolean;
  submit: () => void;
};

const ContactModal = (props: ContactProps) => {
  const iconColor = themeVariables.colorYellowDark;
  const iconSize = "20px";
  return (
    <Modal
      title="Contact Headline"
      width="800px"
      visible={props.visible}
      onCancel={props.close}
      footer={[
        <Button key="back" onClick={props.close}>
          Cancel
        </Button>,
        <Button key="submit" onClick={props.submit} type="primary">
          Send
        </Button>,
      ]}
    >
      <FlexBox>
        <div>
          <Head>
            <IconEmail size={iconSize} color={iconColor} /> Email
          </Head>
          <BottomLink>
            Sales and Upgrades{" "}
            <a href="mailto:sales@nagios.com">sales@nagios.com</a>
          </BottomLink>
          {props.upgraded ? (
            <div style={{ marginBottom: "16px" }}>
              <Head>
                <IconPhone size={iconSize} color={iconColor} /> Phone
              </Head>
              <Link>
                US <a href="tel:16512049102">+1 651-204-9102</a>
              </Link>
              <Link>
                International <a href="tel:18886244671">1 888-NAGIOS-1</a>{" "}
                <span>(624-4671)</span>
              </Link>
              <BottomLink>
                Fax <span>+1 651-204-9103</span>
              </BottomLink>
            </div>
          ) : null}
          <Head>
            <IconPostalMail size={iconSize} color={iconColor} /> Postal Mail
          </Head>
          <p>
            Nagios Enterprises, LLC
            <br />
            1295 Bandana Blvd N, Suite 165
            <br />
            Saint Paul, MN 55108 USA
          </p>
          <Head>
            <IconClock size={iconSize} color={iconColor} /> Hours of Operation
          </Head>
          <Link>
            <a href="/">Holiday Schedule</a>
          </Link>
          <Hours>
            <span>M-Th</span>
            <span>9 am - 5 pm (Central)</span>
          </Hours>
          <Hours>
            <span>F</span>
            <span>9 am - 2 pm (Central)</span>
          </Hours>
          <Hours>
            <span>Sa-Su</span>
            <span>Closed</span>
          </Hours>
        </div>
        <div>
          <h4>You can also write to us by filling out the following.</h4>
          <ContactForm />
        </div>
      </FlexBox>
    </Modal>
  );
};

export default ContactModal;
