import React from "react";

export default function LicensingSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 55 55"
    >
      <g>
        <g>
          <path
            fill="#fdf0e1"
            d="M0 18C0 8.059 8.059 0 18 0h19c9.941 0 18 8.059 18 18v19c0 9.941-8.059 18-18 18H18C8.059 55 0 46.941 0 37z"
          />
        </g>
        <g>
          <g>
            <g>
              <path d="M40 16H12a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h15l.57-.7.93-1.14-.09-.16H12V18h28v8.56a8.41 8.41 0 0 1 2 1.81V18a2 2 0 0 0-2-2z" />
            </g>
            <g>
              <path d="M15 22h17v1.6H15z" />
            </g>
            <g>
              <path d="M15 26h11v1.6H15z" />
            </g>
            <g>
              <path d="M15 33h10v1.6H15z" />
            </g>
            <g>
              <path d="M31.21 33.56a4.31 4.31 0 0 1 8.62.04 4.35 4.35 0 0 1-4.37 4.31 4.31 4.31 0 0 1-4.25-4.35zm-1.705-2.332a6.36 6.36 0 0 0 1.556 7.002l-1.94 2.37.9 3.61 3.66-4.46a6.26 6.26 0 0 0 3.55 0l3.66 4.46.9-3.61-1.94-2.37a6.36 6.36 0 0 0-4.395-11 6.36 6.36 0 0 0-5.95 3.998z" />
            </g>
          </g>
          <g />
        </g>
      </g>
    </svg>
  );
}
