import { createContext, useContext, useState } from "react";

type CurrentReportContextObject = {
  currentReport: string;
  setCurrentReport: Function;
};

const CurrentReportContext = createContext<CurrentReportContextObject>({
  currentReport: "",
  setCurrentReport: Function,
});

export default function CurrentReportProvider({ children }) {
  const [currentReport, setCurrentReport] = useState("");
  return (
    <CurrentReportContext.Provider
      value={{
        currentReport,
        setCurrentReport,
      }}
    >
      {children}
    </CurrentReportContext.Provider>
  );
}

export function useCurrentReport() {
  return useContext(CurrentReportContext);
}
