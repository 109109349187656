import { useState } from "react";
import { Menu, Drawer, Tooltip } from "antd";
import * as Icon from "../../../Shared/Atoms/FontIcons/FontIcons";
import styled, { keyframes } from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { useToggle, useRole } from "../../../../hooks";
import { useSideNavMask } from "../../../../context/SidenavMaskContext";
import { useMasterTheme } from "../../../../context/MasterThemeContext";
import { SideNavContent } from "../SideNavContent";
// import { useSubMenuState } from "../../hooks/useUser";

function SideNav() {
  const { open: isCollapsed, toggleOpen: toggleIsCollapsed } = useToggle();
  const { open: subMenuIsOpen, toggleOpen: toggleSubMenuIsOpen } = useToggle();

  const [activeSubMenu, setActiveSubMenu] = useState("Home");
  const [sideNavHighlightValueToUse, setSideNavHighlightValueToUse] =
    useState("path");
  const { currentMasterTheme } = useMasterTheme();

  const isDarkMode = currentMasterTheme === "dark";

  const { isAdmin } = useRole();
  const history = useHistory();

  const { showMask } = useSideNavMask();
  const location = useLocation();

  // Get our current path... so we can select the proper menu item
  // TODO: change this to better JSON-style path selector
  const getpath = (path: string) => {
    switch (path) {
      case "status":
        return "home";
      case "components":
        return "home";
      case "admin":
        return "administration";
      case "config":
        return "configure";
      case "playlists":
        return "views";
      default:
        return path;
    }
  };

  const slideAnimation = keyframes`
    0% {transform: translateX(-200%)}
    100% {transfrom: translateX(0%)}
  `;

  const StyledDrawer = styled(Drawer)<{ styleCollapsed: boolean }>`
    & .ant-drawer-content-wrapper {
      left: ${(props) => (props.styleCollapsed ? "80px" : "256px")} !important;
      animation-name: ${slideAnimation};
      animation-duration: 0s !important;
    }
  `;

  let path = location.pathname;
  path = path.slice(1, path.length);
  path = path.indexOf("/") >= 0 ? path.substring(0, path.indexOf("/")) : path;
  path = getpath(path.toLowerCase());

  const createSubMenuObject = (icon, tooltip?, adminOnly?) => ({
    icon,
    tooltip,
    adminOnly,
  });

  const subMenuContentObject: any = {
    home: createSubMenuObject(
      <Icon.IconHome size="20px" color={isDarkMode ? "white" : " "} />
    ),
    dashboards: createSubMenuObject(
      <Icon.IconDashboard size="20px" color={isDarkMode ? "white" : " "} />
    ),

    views: createSubMenuObject(
      <Icon.IconImageOutline size="20px" color={isDarkMode ? "white" : " "} />
    ),

    reports: createSubMenuObject(
      <Icon.IconChartPie size="20px" color={isDarkMode ? "white" : " "} />
    ),

    visualizations: createSubMenuObject(
      <Icon.IconChartLine size="20px" color={isDarkMode ? "white" : " "} />
    ),

    incidents: createSubMenuObject(
      <Icon.IconAlert size="20px" color={isDarkMode ? "white" : " "} />
    ),

    configure: createSubMenuObject(
      <Icon.IconSettings size="20px" color={isDarkMode ? "white" : " "} />,
      '', true
    ),

    administration: createSubMenuObject(
      <Icon.IconPerson size="20px" color={isDarkMode ? "white" : " "} />,
      '', true
    ),

    help: createSubMenuObject(
      <Icon.IconQuestionCircle size="20px" color={isDarkMode ? "white" : ""} />,
    ),
    tools: createSubMenuObject(
      <Icon.IconBriefcase size="20px" color={isDarkMode ? "white" : ""} />,
    ),
  };

  return (
    <>
      <Menu
        className="mainSideNav"
        mode="inline"
        inlineCollapsed={isCollapsed}
        selectedKeys={[
          sideNavHighlightValueToUse === "path"
            ? path
            : activeSubMenu.toLowerCase(),
        ]}
      >
        {[
          "Home",
          "Dashboards",
          "Views",
          "Reports",
          "Visualizations",
          "Incidents",
          "Configure",
          "Administration",
          "Help",
          "Tools",
        ].map((menuItemName) => (
          <>
            {subMenuContentObject[menuItemName.toLowerCase()].adminOnly &&
            !isAdmin ? null : (
              <>
                <Menu.Item
                  style={{
                    paddingLeft: isCollapsed ? "6px" : "15px",
                  }}
                  key={menuItemName.toLowerCase()}
                  onClick={() => {
                    if (menuItemName === "Help") {
                      history.push(`/${menuItemName.toLowerCase()}`);
                      setActiveSubMenu(menuItemName);
                      setSideNavHighlightValueToUse("activeSubMenu");

                      if (subMenuIsOpen) {
                        toggleSubMenuIsOpen();
                      }
                    } else {
                      if (activeSubMenu === menuItemName.toLowerCase()) {
                        toggleSubMenuIsOpen();
                      } else {
                        setActiveSubMenu(menuItemName);
                        setSideNavHighlightValueToUse("activeSubMenu");

                        if (!subMenuIsOpen) {
                          toggleSubMenuIsOpen();
                        }
                      }
                    }
                  }}
                  icon={
                    <Icon.IconHighlight size="40px">
                      {subMenuContentObject[menuItemName.toLowerCase()].icon}
                    </Icon.IconHighlight>
                  }
                >
                  <Tooltip
                    placement="right"
                    title={
                      subMenuContentObject[menuItemName.toLowerCase()].tooltip
                    }
                  >
                    {menuItemName}
                  </Tooltip>
                </Menu.Item>
              </>
            )}
          </>
        ))}
        <Menu.Item
          key="collapse"
          icon={
            isCollapsed ? <Icon.IconChevronRight /> : <Icon.IconChevronLeft />
          }
          className={
            isCollapsed ? "collapseButton collapsed" : "collapseButton"
          }
          onClick={() => toggleIsCollapsed()}
        >
          {isCollapsed ? "" : "Collapse Menu"}
        </Menu.Item>
      </Menu>

      {activeSubMenu !== "Help" && (
        <StyledDrawer
          mask={showMask}
          width={300}
          closable={false}
          onClose={() => { 
            toggleSubMenuIsOpen();
            setSideNavHighlightValueToUse("path");
          }}
          visible={subMenuIsOpen}
          placement="left"
          className="mainSideNav__drawer drawerscroll"
          getContainer=".mainframe"
          styleCollapsed={isCollapsed}
        >
          <SideNavContent
            activeSubMenu={activeSubMenu}
            toggleSubMenuIsOpen={toggleSubMenuIsOpen}
          />
        </StyledDrawer>
      )}
    </>
  );
}

export default SideNav;
