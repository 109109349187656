import { useContext } from "react";
import { useHistory } from "react-router";
import { AuthContext } from "../../../context";
import { postData } from "../../../utils/axiosHelpers";
import { LOGOUT_URL } from "../../../api.config";
import { useMutation } from "react-query";

export default function useLogout() {
  const history = useHistory();
  const { setIsAuthenticated } = useContext(AuthContext);

  const doLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    history.push("/login");
  };

  return useMutation((values) => postData(LOGOUT_URL, values), {
    onSuccess: (data) => doLogout(),
    onError: (data) => doLogout(),
  });
}
