import React, { useState } from "react";
import { Button, Modal } from "antd";
import { useToggle } from "../../../../hooks";
import styled from "styled-components";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";
import { IconClose } from "../../../Shared/Atoms/FontIcons/FontIcons";

export type Props = {
  sample: string;
};

const Flex = styled.div`
  display: flex;
  justify-content: center;
`;

const HelpModal = styled(Modal)`
  width: 351px !important;
  margin-top: 6%;
  .ant-modal-header {
    padding-right: 100px;
  }
  .ant-modal-body {
    padding-top: 0px;
    p {
      color: ${themeVariables.colorGrayLight1};
    }
    a {
      text-align: left;
      padding: 0px;
      height: 24px;
    }
  }
  .ant-modal-footer {
    button {
      height: 40px;
    }
  }
`;

const StyledUl = styled.ul`
  padding-left: 20px;
`;

const Help = () => {
  return (
    <>
      <Button
        block
        type="link"
        href="https://www.nagios.com/products/nagios-xi/faq/"
        target="_blank"
        rel="noreferrer noopener"
      >
        FAQs
      </Button>
      <Button
        block
        type="link"
        href="https://www.nagios.org/support/"
        target="_blank"
        rel="noreferrer noopener"
      >
        Help Page
      </Button>
      <Button
        block
        type="link"
        href="https://library.nagios.com/"
        target="_blank"
        rel="noreferrer noopener"
      >
        Nagios Library
      </Button>
      <Button
        block
        type="link"
        href="https://support.nagios.com/forum/"
        target="_blank"
        rel="noreferrer noopener"
      >
        Support Forum
      </Button>
      <Button
        block
        type="link"
        href="https://support.nagios.com/wiki/index.php/Main_Page"
        target="_blank"
        rel="noreferrer noopener"
      >
        Support Wiki
      </Button>
    </>
  );
};

const SoftwareSuite = () => {
  return (
    <>
      <p>
        Never be left having to explain why an outage hurt your organization’s
        bottom line. Our product line delivers outstanding value to thousands of
        organizations worldwide.
      </p>
      <h3>Questions? Contact Sales</h3>
      <StyledUl>
        <li><Button block type="link" href="mailto:sales@nagios.com" target="_top">sales@nagios.com</Button></li>
        <li><Button block type="link" href="tel:18886844611">1-888-NAGIOS-1</Button></li>
      </StyledUl>
      <Button
        block
        type="link"
        href="https://www.nagios.com/casestudies/#Users"
        target="_blank"
        rel="norefferer noopener"
      >
        Our Clients
      </Button>
      <Button
        block
        type="link"
        href="https://www.nagios.com/products/#themo_tour_1_1"
        target="_blank"
        rel="noreferrer noopener"
      >
        Download Free Trial
      </Button>
    </>
  );
};

const ContactUs = () => {
  return (
    <>
      <p>
        Customer Phone Support
        <br />
        +1 651-204-9102
      </p>
      <p>
        Are you a new user?
        <br />
        You can also try our{" "}
        <a
          href="https://support.nagios.com/forum/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Support Forum
        </a>
      </p>
    </>
  );
};

export default function LoginPageDialogBox() {
  const [modalBody, setModalBody] = useState<string>("");
  const [modalTitle, setModalTitle] = useState<string>("");
  const { open, toggleOpen } = useToggle();

  const openModal = (topic: string) => {
    setModalBody(topic);
    topic === "contact" && setModalTitle("Contact us");
    topic === "help" && setModalTitle("Help");
    topic === "software" &&
      setModalTitle("Nagios IT Management Software Suite");
    toggleOpen();
  };

  return (
    <>
      <Flex style={{ marginTop: '10px' }}>
        <Button onClick={() => openModal("contact")} type="link">
          Contact
        </Button>
        <Button onClick={() => openModal("help")} type="link">
          Help
        </Button>
        <Button onClick={() => openModal("software")} type="link">
          Nagios Software Suite
        </Button>
      </Flex>
      <HelpModal
        title={modalTitle}
        visible={open}
        onOk={toggleOpen}
        onCancel={toggleOpen}
        footer={[
          <Button onClick={toggleOpen} type="primary">
            <IconClose size="1.5em" /> Close
          </Button>,
        ]}
      >
        {modalBody === "help" && <Help />}
        {modalBody === "software" && <SoftwareSuite />}
        {modalBody === "contact" && <ContactUs />}
      </HelpModal>
    </>
  );
}
