type dataType = {
  id: number;
  value: number | string;
  text: string;
};

export const TimePeriod: dataType[] = [
  { id: 1, value: 0.5, text: "Last 12 hours" },
  { id: 2, value: 1, text: "Last 24 hours" },
  { id: 3, value: 7, text: "Last Week" },
  { id: 4, value: 14, text: "Last 2 Weeks" },
  { id: 5, value: 30, text: "Last Month" },
  { id: 6, value: 365, text: "Last Year" },
  { id: 7, value: "custom", text: "Custom Date Range" },
];

export const HostData: dataType[] = [{ id: 1, value: "all", text: "All (#)" }];

export const Service: dataType[] = [{ id: 1, value: "all", text: "All (#)" }];

export const HostGroup: dataType[] = [
  { id: 1, value: 1, text: "Option 1" },
  { id: 2, value: 2, text: "Option 2" },
  { id: 3, value: 3, text: "Option 3" },
];

export const ServiceGroup: dataType[] = [
  { id: 1, value: 1, text: "Option 1" },
  { id: 2, value: 2, text: "Option 2" },
  { id: 3, value: 3, text: "Option 3" },
];
