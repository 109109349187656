import { Form, Checkbox } from "antd";
import styled from "styled-components";
import { themeVariables } from "../../../../styles/styled-components/StyledComponentThemesVariables";

export const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 4px;
  .ant-checkbox-inner {
    border-radius: 2px;
    background-color: ${themeVariables.colorBluePale};
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
    background-color: ${themeVariables.colorPrimary};
  }
  span {
    color: ${themeVariables.colorGrayLight1};
  }
  .ant-checkbox-disabled ~ span {
    color: ${themeVariables.colorGrayLight3};
    font-style: italic;
  }
`;

export const Flex = styled.div`
  padding: 50px 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  > img {
    margin-bottom: 20px;
  }
  > h4 {
    font-size: 16px;
  }
  > p {
    color: ${themeVariables.colorGrayLight2};
    font-size: 14px;
    width: 240px;
    text-align: center;
  }
`;

export const AdvForm = styled(Form)`
  padding: 10px 0;
  .ant-form-item-label {
    line-height: 1.2;
    padding-bottom: 4px;
    > label {
      font-weight: 500;
      font-size: 12px;
    }
  }
  > .short {
    margin-bottom: 8px;
    height: 66px;
    width: calc(50% - 10px) !important;
  }
  > .short.float {
    float: left;
    margin-right: 20px;
  }
  > .check.short {
    margin-bottom: 4px;
  }
  .ant-picker-range {
    width: 100%;
  }
`;
