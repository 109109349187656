export const dashletTypeList: string[] = [
  "availability",
  "core_component_status",
  "customurl",
  "heatmap",
  "latest_alerts",
  "metrics_overview",
  "monitoring_engine_event_queue",
  "monitoring_engine_stats",
  "monitoring_engine_performance",
  "monitoring_engine_processes",
  "network",
  "network_outages",
  "perfdata_gauge",
  "perfdata_line",
  "server_stats",
  "sla",
  "status_summary_hosts",
  "status_summary_services",
  "status_summary_hostgroups",
  "status_summary_servicegroups",
];

export const dashletNameObject = {
  availability: "Availability",
  core_component_status: "Core Component Status",
  customurl: "Custom Url",
  heatmap: "HeatMap",
  latest_alerts: "Latest Alerts",
  metrics_overview: "Metrics Overview",
  monitoring_engine_event_queue: "Monitoring Engine Event Queue",
  monitoring_engine_stats: "Monitoring Engine Statistics",
  monitoring_engine_performance: "Monitoring Engine Performance",
  monitoring_engine_processes: "Monitoring Engine Processes",
  monitoring_engine_check: "Monitoring Engine Check",
  network: "Network",
  network_outages: "Network Outages",
  nagiosStatus: "Nagios Status",
  perfdata_gauge: "Perfdata Gauge",
  perfdata_line: "Perfdata Line",
  server_stats: "Monitoring Engine Server Stats",
  sla: "SLA",
  status_summary_hosts: "Host Status",
  status_summary_services: "Service Status",
  status_summary_hostgroups: "Hostgroups Summary",
  status_summary_servicegroups: "Servicegroups Summary",
};
