import React, { createContext, useContext, useEffect, useState } from "react";
import { ThemeProvider, DefaultTheme } from "styled-components";
import {
  styledComponentDarkTheme,
  styledComponentLightTheme,
} from "../styles/styled-components/StyledComponentThemesVariables";

type ThemeTypes = "light" | "dark";

const StyledComponentThemes: Record<ThemeTypes, DefaultTheme> = {
  dark: styledComponentDarkTheme,
  light: styledComponentLightTheme,
};

//Sets namespaces on body for antD themes
const setThemeClass = (theme: ThemeTypes) => {
  // const removeTheme = theme === "light" ? "dark" : "light";
  // const addTheme = theme === "light" ? "light" : "dark";

  // document.body.classList.remove(removeTheme);
  // document.body.classList.add(addTheme);
  if (theme === "light") {
    document.body.classList.remove("dark");
  } else {
    document.body.classList.add("dark");
  }
};

// type ThemeSet = {
//   antD: string;
//   styledComponents: DefaultTheme;
// }

type ThemeObject = {
  currentMasterTheme: ThemeTypes;
  setCurrentMasterTheme: (themeName: ThemeTypes) => void;
  theme: DefaultTheme;
};

export const MasterThemeContext = createContext<ThemeObject>({
  currentMasterTheme: "light",
  theme: styledComponentLightTheme,
  setCurrentMasterTheme: () => console.log("initial light theme set"),
});

type ThemeProps = {
  children: JSX.Element[] | JSX.Element;
  currentTheme?: ThemeTypes;
};

//ThemeProvider is a service to set StyledComponents dark and light less themesev ia context
//ThemeSwitcherProvider is a library that sets compiled AntD less resources based on theme "light / dark"
export function MasterThemeProvider({ children, currentTheme }: ThemeProps) {
  const [currentMasterTheme, setCurrentMasterTheme] = useState<ThemeTypes>(
    currentTheme ? currentTheme : "light"
  );

  // READS USERS BROWSERS SETTINGS AND SETS LIGHT/DARK MODE ACCORDINGLY

  /*
   * To activate, uncomment lines 61-62 and comment out lines 64-68
   * Leaving as is for now because it breaks theming in storybook
   */

  // const isDark: boolean = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  // useEffect(() => isDark ? setCurrentMasterTheme('dark') : setCurrentMasterTheme("light"), [isDark]);

  useEffect(() => {
    if (currentTheme) {
      setCurrentMasterTheme(currentTheme);
    }
  }, [currentTheme]);

  // const masterThemes: ThemeSet = { antD: AntLessThemes[currentMasterTheme], styledComponents: StyledComponentThemes[currentMasterTheme] }
  const theme: DefaultTheme = StyledComponentThemes[currentMasterTheme];

  setThemeClass(currentMasterTheme);

  return (
    <MasterThemeContext.Provider
      value={{
        currentMasterTheme: currentMasterTheme,
        setCurrentMasterTheme: setCurrentMasterTheme,
        // themes: masterThemes
        theme: theme,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </MasterThemeContext.Provider>
  );
}

export function useMasterTheme() {
  return useContext(MasterThemeContext);
}
