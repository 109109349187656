import { Button as AntButton, Col, Popover, Row } from "antd";
import { BgColorsOutlined } from "@ant-design/icons";
import React from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";
import styled from "styled-components";

const Button = styled(AntButton)`
  background: ${(props) => props.color};
`;

type Props = {
  color: string;
  updateColor: any;
};

export default function ColorPicker({ color, updateColor }: Props) {
  return (
    <Row>
      <Col span={6}>
        <Popover
          placement="top"
          title="Change Color"
          content={<HexColorPicker color={color} onChange={updateColor} />}
          trigger="click"
        >
          <Button color={color} icon={<BgColorsOutlined />} />
        </Popover>
      </Col>

      <Col span={10}>
        <HexColorInput
          data-testid="colorPickerInput"
          color={color}
          onChange={updateColor}
        />
      </Col>
    </Row>
  );
}
