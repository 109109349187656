import DashboardsPageMock from "../components/DashboardPage/DashboardsPageMock";
import { LoginPageMocks } from "../components/LoginPage";
import { mocks as DashletPageMock } from "../components/DashletPage/DashletPageMock";
import { ToolPageMocks } from "../components/ToolsPage";
import { ViewsPageMocks } from "../components/ViewsPage";
import { ReportsPageMocks } from "../components/ReportsPage";
import { mocks as FrameMocks } from "../components/Frame/FrameMocks";
import { HomePageMocks } from "../components/HomePage";
import { UsersPageMocks } from "../components/UsersPage";

export const mocks = [
  ...LoginPageMocks,
  ...FrameMocks,
  ...DashboardsPageMock,
  ...DashletPageMock,
  ...ToolPageMocks,
  ...ViewsPageMocks,
  ...ReportsPageMocks,
  ...HomePageMocks,
  ...UsersPageMocks,
];
