import imgSrc from "../Molecules/CommonScreens/thumbnail.png";
import { Playlist } from "../ViewsPageTypes";
import usePlaylist from "./usePlaylists";

export default function useCommonScreens() {
  const { data: playlists } = usePlaylist();

  const isAdded = (title: string, playlist: Playlist) =>
    Object.values(playlists ? playlist?.screens : [])
      .map(({ name }) => name)
      .includes(title);

  const createScreen = (
    id: number,
    title: string,
    description: string,
    playlist: Playlist,
    sub?: string
  ) => ({
    id: 1,
    title,
    image: imgSrc,
    description,
    sub: sub ? sub : "",
    added: isAdded(title, playlist),
  });

  const commonScreenObject = [...(playlists ? playlists : [])].map(
    (playlist: any) => [
      createScreen(
        1,
        "Birdseye",
        "Start monitoring devices, servers, applications, services and more in mintes",
        playlist,
        "Call Centers • Energy Management"
      ),
      createScreen(
        2,
        "Operations Center",
        "See all host problems, show all states, and up and down hosts",
        playlist
      ),
      createScreen(
        2,
        "Operations Screen",
        "See all host problems, show all states, and up and down hosts",
        playlist
      ),
    ]
  );

  return commonScreenObject;
}
