import React from "react";
import "../../_Settings/Fonts/nagios-icons.css";

type iconProps = {
  color?: string;
  size?: string;
  marginRight?: string;
  onClick?: () => void;
  children?: JSX.Element;
};

const getStyleFromProps = (props: iconProps): iconProps => {
  let style = { };
  style = props.color ? { ...style, color: props.color } : style;
  style = props.size ? { ...style, fontSize: props.size } : style;
  style = props.onClick ? { ...style, cursor: "pointer" } : style;
  style = props.marginRight
    ? { ...style, marginRight: props.marginRight }
    : style;
  return style;
};

export const IconAdd = (props: iconProps) => (
  <i
    className="icon-add"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconAddCircle = (props: iconProps) => (
  <i
    className="icon-add-circle"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconAlert = (props: iconProps) => (
  <i
    className="icon-alert"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconApi = (props: iconProps) => (
  <i
    className="icon-api"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconArrowExpand = (props: iconProps) => (
  <i
    className="icon-arrow-expand"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconArrowRight = (props: iconProps) => (
  <i
    className="icon-arrow-right"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconArrowTwoWay = (props: iconProps) => (
  <i
    className="icon-arrow-two-way"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconArrowTwoWayThin = (props: iconProps) => (
  <i
    className="icon-arrow-two-way-thin"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconArrowUpCircle = (props: iconProps) => (
  <i
    className="icon-arrow-up-circle"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconBackups = (props: iconProps) => (
  <i
    className="icon-backups"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconBriefcase = (props: iconProps) => (
  <i
    className="icon-briefcase"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconCalendar = (props: iconProps) => (
  <i
    className="icon-calendar"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconCalendarPlus = (props: iconProps) => (
  <i
    className="icon-calendar-plus"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconChartBar = (props: iconProps) => (
  <i
    className="icon-chart-bar"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconChartLine = (props: iconProps) => (
  <i
    className="icon-chart-line"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconChartPie = (props: iconProps) => (
  <i
    className="icon-chart-pie"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconChartPie2 = (props: iconProps) => (
  <i
    className="icon-chart-pie-2"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconCheck = (props: iconProps) => (
  <i
    className="icon-check"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconChevronCollapse = (props: iconProps) => (
  <i
    className="icon-chevron-collapse"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconChevronDoubleLeft = (props: iconProps) => (
  <i
    className="icon-chevron-double-left"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconChevronDoubleRight = (props: iconProps) => (
  <i
    className="icon-chevron-double-right"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconChevronDown = (props: iconProps) => (
  <i
    className="icon-chevron-down"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconChevronExpand = (props: iconProps) => (
  <i
    className="icon-chevron-expand"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconChevronLeft = (props: iconProps) => (
  <i
    className="icon-chevron-left"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconChevronRight = (props: iconProps) => (
  <i
    className="icon-chevron-right"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconChevronUp = (props: iconProps) => (
  <i
    className="icon-chevron-up"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconCircle = (props: iconProps) => (
  <i
    className="icon-circle"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconClock = (props: iconProps) => (
  <i
    className="icon-clock"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconClose = (props: iconProps) => (
  <i
    className="icon-close"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconCommands = (props: iconProps) => (
  <i
    className="icon-commands"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconCommentAdd = (props: iconProps) => (
  <i
    className="icon-comment-add"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconConfig = (props: iconProps) => (
  <i
    className="icon-config"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconContacts = (props: iconProps) => (
  <i
    className="icon-contacts"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconCube = (props: iconProps) => (
  <i
    className="icon-cube"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconDashboard = (props: iconProps) => (
  <i
    className="icon-dashboard"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconDelete = (props: iconProps) => (
  <i
    className="icon-delete"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconDesktop = (props: iconProps) => (
  <i
    className="icon-desktop"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconDisable = (props: iconProps) => (
  <i
    className="icon-disable"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconDots = (props: iconProps) => (
  <i
    className="icon-dots"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconDownload = (props: iconProps) => (
  <i
    className="icon-download"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconDuplicate = (props: iconProps) => (
  <i
    className="icon-duplicate"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconEdit = (props: iconProps) => (
  <i
    className="icon-edit"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconEmail = (props: iconProps) => (
  <i
    className="icon-email"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconExtensions = (props: iconProps) => (
  <i
    className="icon-extensions"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconExternalLink = (props: iconProps) => (
  <i
    className="icon-external-link"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconFileSettings = (props: iconProps) => (
  <i
    className="icon-file-settings"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconFilter = (props: iconProps) => (
  <i
    className="icon-filter"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconFolder = (props: iconProps) => (
  <i
    className="icon-folder"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconGrid = (props: iconProps) => (
  <i
    className="icon-grid"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconGridOutline = (props: iconProps) => (
  <i
    className="icon-grid-outline"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconHeadset = (props: iconProps) => (
  <i
    className="icon-headset"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconHighlight = (props: iconProps) => (
  <i
    className="icon-highlight"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconHistory = (props: iconProps) => (
  <i
    className="icon-history"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconHome = (props: iconProps) => (
  <i
    className="icon-home"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconHorizontalFill = (props: iconProps) => (
  <i
    className="icon-horizontal-fill"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconHost = (props: iconProps) => (
  <i
    className="icon-host"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconHostGroups2 = (props: iconProps) => (
  <i
    className="icon-host-groups-2"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconHostGroups3 = (props: iconProps) => (
  <i
    className="icon-host-groups-3"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconHostSmall = (props: iconProps) => (
  <i
    className="icon-host-small"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconIdeas = (props: iconProps) => (
  <i
    className="icon-ideas"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconImageOutline = (props: iconProps) => (
  <i
    className="icon-image-outline"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconInfo = (props: iconProps) => (
  <i
    className="icon-info"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconLicensing = (props: iconProps) => (
  <i
    className="icon-licensing"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconLog = (props: iconProps) => (
  <i
    className="icon-log"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconMagicWand = (props: iconProps) => (
  <i
    className="icon-magic-wand"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconMask = (props: iconProps) => (
  <i
    className="icon-mask"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconMessage = (props: iconProps) => (
  <i
    className="icon-message"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconMonitor = (props: iconProps) => (
  <i
    className="icon-monitor"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconNetwork = (props: iconProps) => (
  <i
    className="icon-network"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconNetworkOutline = (props: iconProps) => (
  <i
    className="icon-network-outline"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconNodeTree2 = (props: iconProps) => (
  <i
    className="icon-node-tree-2"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconNodeTree3 = (props: iconProps) => (
  <i
    className="icon-node-tree-3"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconNotification = (props: iconProps) => (
  <i
    className="icon-notification"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconNotificationOutline = (props: iconProps) => (
  <i
    className="icon-notification-outline"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconPdfDownload = (props: iconProps) => (
  <i
    className="icon-pdf-download"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconPdfEmail = (props: iconProps) => (
  <i
    className="icon-pdf-email"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconPdfShare = (props: iconProps) => (
  <i
    className="icon-pdf-share"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconPerson = (props: iconProps) => (
  <i
    className="icon-person"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconPhone = (props: iconProps) => (
  <i
    className="icon-phone"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconPlay = (props: iconProps) => (
  <i
    className="icon-play"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconPlug = (props: iconProps) => (
  <i
    className="icon-plug"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconPostalMail = (props: iconProps) => (
  <i
    className="icon-postal-mail"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconQuestion = (props: iconProps) => (
  <i
    className="icon-question"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconQuestionCircle = (props: iconProps) => (
  <i
    className="icon-question-circle"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconQuestionFilled = (props: iconProps) => (
  <i
    className="icon-question-filled"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconRefresh = (props: iconProps) => (
  <i
    className="icon-refresh"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconSearch = (props: iconProps) => (
  <i
    className="icon-search"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconSearchObject = (props: iconProps) => (
  <i
    className="icon-search-object"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconSend = (props: iconProps) => (
  <i
    className="icon-send"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconServer = (props: iconProps) => (
  <i
    className="icon-server"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconServers = (props: iconProps) => (
  <i
    className="icon-servers"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconSettings = (props: iconProps) => (
  <i
    className="icon-settings"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconSettingsConfig = (props: iconProps) => (
  <i
    className="icon-settings-config"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconSettingsGlobal = (props: iconProps) => (
  <i
    className="icon-settings-global"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconShare = (props: iconProps) => (
  <i
    className="icon-share"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconShareOutline = (props: iconProps) => (
  <i
    className="icon-share-outline"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconSpeedometer = (props: iconProps) => (
  <i
    className="icon-speedometer"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconStack = (props: iconProps) => (
  <i
    className="icon-stack"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconStackAdd = (props: iconProps) => (
  <i
    className="icon-stack-add"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconStar = (props: iconProps) => (
  <i
    className="icon-star"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconStop = (props: iconProps) => (
  <i
    className="icon-stop"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconTerminal = (props: iconProps) => (
  <i
    className="icon-terminal"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconTrash = (props: iconProps) => (
  <i
    className="icon-trash"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconUpload = (props: iconProps) => (
  <i
    className="icon-upload"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconUserActivity = (props: iconProps) => (
  <i
    className="icon-user-activity"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconUserData = (props: iconProps) => (
  <i
    className="icon-user-data"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconView = (props: iconProps) => (
  <i
    className="icon-view"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconWeb = (props: iconProps) => (
  <i
    className="icon-web"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);

export const IconWebGlobal = (props: iconProps) => (
  <i
    className="icon-web-global"
    onClick={props.onClick ? props.onClick : undefined}
    style={getStyleFromProps(props)}
  >
    {props.children}
  </i>
);
