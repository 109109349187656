import {
  REPORT_URL,
  REPORT_TYPES_URL,
  FAVORITE_REPORT_URL,
} from "../../api.config";

import {
  createDeleteMock,
  createGetMock,
  createPostMock,
  createPutMock,
} from "../../mockServer/createMockHandler";
import { getRandomNumberInRange } from "../../utils/logic";
import { Reports, ReportTypes, Schedule } from "./ReportsPageTypes";

export const CREATE_REPORT_MESSAGE = "Successfully created report 1234";
export const DELETE_REPORT_SUCCESS_MESSAGE = "Successfully deleted report 1234";
export const EDIT_REPORT_MESSAGE = "Successfully edited report 1234";
export const RUN_REPORT_MESSAGE = "Successfully ran report 1234";

export const createReportObject = (
  id: number,
  name: string,
  type: string,
  settings?: any,
  schedule?: Schedule
) => ({
  id,
  name,
  type,
  next_run_date:
    getRandomNumberInRange(2) === 1
      ? new Date()
      : new Date(new Date().getDate() + 1),
  last_run_date: new Date(),
  schedulable: getRandomNumberInRange(2) === 1,
  is_paused: getRandomNumberInRange(2) === 1,
  running: getRandomNumberInRange(2) === 1,
  settings,
  test_run: false,
  schedule,
});

export const reportsList: Reports = [
  createReportObject(1, "", "SLA Report"),
  createReportObject(2, "The Test Report 2", "Network Report", {
    dontShowSvcData: true,
  }),
  createReportObject(
    3,
    "The Test Report 3",
    "Network Report",
    {},
    {
      frequency: "Today",
      timeToRun: "Morning",
      attachments: [],
      recipients: ["John Doe"],
      subject: "Test Report",
      emailMessage: "This is a Test",
    }
  ),
];

export const reportTypesList: ReportTypes = [
  {
    template_id: 1,
    type_title: "Availability Summary",
    schedulable: 1,
    active: 1,
    description: "",
    created_at: "2021-06-07T13:12:02.000000Z",
    updated_at: null,
    advanced_opts: 1,
  },
  {
    template_id: 2,
    type_title: "Executive Summary",
    schedulable: 1,
    active: 1,
    description: "",
    created_at: "2021-06-07T13:12:02.000000Z",
    updated_at: null,
    advanced_opts: 1,
  },
  {
    template_id: 3,
    type_title: "SLA Report",
    schedulable: 1,
    active: 1,
    description: "",
    created_at: "2021-06-07T13:12:02.000000Z",
    updated_at: null,
    advanced_opts: 1,
  },
  {
    template_id: 4,
    type_title: "State History",
    schedulable: 1,
    active: 1,
    description: "",
    created_at: "2021-06-07T13:12:02.000000Z",
    updated_at: null,
    advanced_opts: 0,
  },
  {
    template_id: 5,
    type_title: "Top Alert Producers",
    schedulable: 1,
    active: 1,
    description: "",
    created_at: "2021-06-07T13:12:02.000000Z",
    updated_at: null,
    advanced_opts: 0,
  },
  {
    template_id: 6,
    type_title: "Top Alert Histogram",
    schedulable: 1,
    active: 1,
    description: "",
    created_at: "2021-06-07T13:12:02.000000Z",
    updated_at: null,
    advanced_opts: 0,
  },
  {
    template_id: 7,
    type_title: "Notifications",
    schedulable: 1,
    active: 1,
    description: "",
    created_at: "2021-06-07T13:12:02.000000Z",
    updated_at: null,
    advanced_opts: 0,
  },
  {
    template_id: 8,
    type_title: "Event Log",
    schedulable: 1,
    active: 1,
    description: "",
    created_at: "2021-06-07T13:12:02.000000Z",
    updated_at: null,
    advanced_opts: 0,
  },
  {
    template_id: 9,
    type_title: "Bandwidth Usage",
    schedulable: 1,
    active: 1,
    description: "",
    created_at: "2021-06-07T13:12:02.000000Z",
    updated_at: null,
    advanced_opts: 0,
  },
  {
    template_id: 10,
    type_title: "Capacity Planning",
    schedulable: 1,
    active: 1,
    description: "",
    created_at: "2021-06-07T13:12:02.000000Z",
    updated_at: null,
    advanced_opts: 0,
  },
  {
    template_id: 11,
    type_title: "Network Report",
    schedulable: 1,
    active: 1,
    description: "",
    created_at: "2021-06-07T13:12:02.000000Z",
    updated_at: null,
    advanced_opts: 0,
  },
  {
    template_id: 12,
    type_title: "Network Query",
    schedulable: 1,
    active: 1,
    description: "",
    created_at: "2021-06-07T13:12:02.000000Z",
    updated_at: null,
    advanced_opts: 0,
  },
  {
    template_id: 13,
    type_title: "Alert Heatmap",
    schedulable: 1,
    active: 1,
    description: "",
    created_at: "2021-06-07T13:12:02.000000Z",
    updated_at: null,
    advanced_opts: 0,
  },
  {
    template_id: 14,
    type_title: "Alert Cloud",
    schedulable: 1,
    active: 1,
    description: "",
    created_at: "2021-06-07T13:12:02.000000Z",
    updated_at: null,
    advanced_opts: 0,
  },
  {
    template_id: 15,
    type_title: "Alert Stream",
    schedulable: 1,
    active: 1,
    description: "",
    created_at: "2021-06-07T13:12:02.000000Z",
    updated_at: null,
    advanced_opts: 0,
  },
  {
    template_id: 16,
    type_title: "Alert Timeline",
    schedulable: 1,
    active: 1,
    description: "",
    created_at: "2021-06-07T13:12:02.000000Z",
    updated_at: null,
    advanced_opts: 0,
  },
  {
    template_id: 17,
    type_title: "Network Replay",
    schedulable: 1,
    active: 1,
    description: "",
    created_at: "2021-06-07T13:12:02.000000Z",
    updated_at: null,
    advanced_opts: 0,
  },
];

export const mocks = [
  createGetMock(REPORT_URL, 200, {
    reports: reportsList,
  }),
  createGetMock(FAVORITE_REPORT_URL, 200, {
    reports: [reportsList[1]],
  }),
  createGetMock(REPORT_TYPES_URL, 200, {
    reports: reportTypesList,
  }),
  createDeleteMock(REPORT_URL, 200, {
    message: DELETE_REPORT_SUCCESS_MESSAGE,
  }),
  createPutMock(REPORT_URL, 200, {
    message: EDIT_REPORT_MESSAGE,
  }),
  createPostMock(REPORT_URL, 200, {
    message: CREATE_REPORT_MESSAGE,
  }),

  createGetMock(`${REPORT_URL}/1`, 200, {
    reports: reportsList[0],
  }),

  createGetMock(`${REPORT_URL}/2`, 200, {
    reports: reportsList[1],
  }),

  createGetMock(`${REPORT_URL}/3`, 200, {
    reports: reportsList[2],
  }),
];
