import React from "react";
import { Spin as AntSpiner } from "antd";
import { useIsFetching } from "react-query";
import styled from "styled-components";

const Spinner: any = styled(AntSpiner)`
  color: "white";
`;

export default function Loader() {
  const isFetching = useIsFetching();
  return <Spinner data-testid="Loader" spinning={isFetching !== 0} />;
}
