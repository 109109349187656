import { BrowserRouter as Router, Redirect } from "react-router-dom";
import styled from "styled-components";
import {
  useAuth,
  NotificationsProvider,
  AfterAuthProvider,
  SideNavProvider,
} from "../../context";
import { GlobalModalProvider } from "../../context/GlobalModalContext";
import { useMasterTheme } from "../../context/MasterThemeContext";
import { CreateDashboardModal } from "../DashboardPage";
import { LoginPage } from "../LoginPage";
import CurrentReportProvider from "../ReportsPage/context/CurrentReportContext";
import EditReportProvider from "../ReportsPage/context/EditReportContent";
import CreateReportModal from "../ReportsPage/Organisms/CreateReportModal/CreateReportModal";
import ScheduleReportsModal from "../ReportsPage/Organisms/ScheduleReportsModal/ScheduleReportsModal";
import { Routes } from "../Routes";
import { AddAToolModal } from "../ToolsPage/Molecules/AddAToolModal";
import { AddAPLaylistModal } from "../ViewsPage/Molecules/AddAPLaylistModal";
import SideNav from "./Molecules/Sidenav/Sidenav";
import Footer from "./Molecules/Footer/Footer";
import Header from "./Molecules/Header";
import { SideNavOpenProvider } from "./context/SideNavContext";
import CurrentDashletProvider from "../DashboardPage/context/CurrentDashletContext";
import Hydrator from "../../context/Hydrator";
import SideNavMaskProvider from "../../context/SidenavMaskContext";
import Iframe from "react-iframe";
import useNagiosInstallStatus from "./hooks/useNagiosInstallStatus";

const FullScreenIframe = styled(Iframe)`
  display: block;
  width: 100%;
  border: none;
  height: calc(100vh);
  overflow-y: scroll;
`;

const StyledBody = styled.div<{ bg: string }>`
  background-color: ${(props) => props.bg};
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 65px);
  overflow: auto;
`;

export default function Frame() {
  const { isAuthenticated } = useAuth();
  const { isInstalled } = useNagiosInstallStatus();

  const theme = useMasterTheme().theme;

  return (
    <div>
      <SideNavMaskProvider>
        <GlobalModalProvider>
          <NotificationsProvider>
            <Router>
              <Header />
              {isInstalled === false ? (
                <>
                  <Redirect
                    to={{
                      pathname: "/install",
                    }}
                  />
                  <StyledBody id="frame" bg={theme.bodyBackgroundColor} className="fancyscroll">
                    <FullScreenIframe url="/nagiosxi/install.php" />
                  </StyledBody>
                </>
              ) : (
                <div className="mainframe" style={{ display: "flex" }}>
                  {isAuthenticated ? (
                    <Hydrator>
                      <AfterAuthProvider>
                        <EditReportProvider>
                          <SideNavProvider>
                            <SideNavOpenProvider>
                              <CurrentDashletProvider>
                                <CurrentReportProvider>
                                  <SideNav />
                                  <StyledBody
                                    bg={theme.bodyBackgroundColor}
                                    id="frame"
                                    className="fancyscroll"
                                  >
                                    <Routes />
                                    <CreateDashboardModal />
                                    <AddAToolModal />
                                    <AddAPLaylistModal />
                                    <CreateReportModal />
                                    <ScheduleReportsModal />
                                    <Footer loggedIn={isAuthenticated} />
                                  </StyledBody>
                                </CurrentReportProvider>
                              </CurrentDashletProvider>
                            </SideNavOpenProvider>
                          </SideNavProvider>
                        </EditReportProvider>
                      </AfterAuthProvider>
                    </Hydrator>
                  ) : (
                    <StyledBody bg={theme.bodyBackgroundColor} className="fancyscroll">
                      <Redirect
                        to={{
                          pathname: "/login",
                        }}
                      />
                      <LoginPage />
                      <Footer loggedIn={isAuthenticated} />
                    </StyledBody>
                  )}
                </div>
              )}
            </Router>
          </NotificationsProvider>
        </GlobalModalProvider>
      </SideNavMaskProvider>
    </div>
  );
}
