import { LOGIN_URL, LOGOUT_URL, PASSWORD_RESTART_URL } from "../../api.config";
import { createPostMock } from "../../mockServer/createMockHandler";

const mocks = [
  createPostMock(LOGIN_URL, 200, {
    token: "1234",
    token_type: "bearer",
    expires_in: 3600,
  }),

  createPostMock(LOGOUT_URL, 200, {
    message: "Logout was Sucessful.",
  }),
  createPostMock(PASSWORD_RESTART_URL, 200, {
    message: "Password Restart was Sucessful",
  }),
];

export default mocks;
