import React from "react";
import {
  createNagiosObject,
  Host,
  NagiosContext,
  Service,
} from "../context/NagiosContext";

const createHostObject = (state_type: string) => ({
  host_object_id: "145",
  host_name: "localhost",
  host_alias: "localhost",
  display_name: "localhost",
  address: "127.0.0.1",
  icon_image: "",
  icon_image_alt: "",
  notes: "",
  notes_url: "",
  action_url: "",
  hoststatus_id: "3",
  instance_id: "1",
  status_update_time: "2020-04-06 14:18:15",
  output: "OK - 127.0.0.1: rta 0.011ms, lost 0%",
  long_output: "",
  perfdata:
    "rta=0.011ms;3000.000;5000.000;0; pl=0%;80;100;; rtmax=0.034ms;;;; rtmin=0.005ms;;;;",
  current_state: "0",
  has_been_checked: "1",
  should_be_scheduled: "1",
  current_check_attempt: "1",
  max_check_attempts: "10",
  last_check: "2020-04-06 14:18:15",
  next_check: "2020-04-06 14:23:15",
  check_type: "0",
  check_options: "0",
  last_state_change: "2020-02-01 16:10:11",
  last_hard_state_change: "2020-02-01 16:10:11",
  last_hard_state: "0",
  last_time_up: "2020-04-06 14:18:15",
  last_time_down: "1969-12-31 18:00:00",
  last_time_unreachable: "1969-12-31 18:00:00",
  state_type: state_type,
  last_notification: "1969-12-31 18:00:00",
  next_notification: "1969-12-31 18:00:00",
  no_more_notifications: "0",
  notifications_enabled: "1",
  problem_has_been_acknowledged: "0",
  acknowledgement_type: "0",
  current_notification_number: "0",
  passive_checks_enabled: "1",
  active_checks_enabled: "1",
  event_handler_enabled: "1",
  flap_detection_enabled: "1",
  is_flapping: "0",
  percent_state_change: "0",
  latency: "0.00153100001625717",
  execution_time: "0.001404",
  scheduled_downtime_depth: "0",
  failure_prediction_enabled: "0",
  process_performance_data: "1",
  obsess_over_host: "1",
  modified_host_attributes: "0",
  event_handler: "",
  check_command: "check-host-alive!!!!!!!!",
  normal_check_interval: "5",
  retry_check_interval: "1",
  check_timeperiod_object_id: "129",
});

const createServiceObject = (state_type: string) => ({
  host_name: "localhost",
  service_description: "Current Load",
  display_name: "Current Load",
  host_object_id: "145",
  host_address: "127.0.0.1",
  host_alias: "localhost",
  icon_image: "",
  icon_image_alt: "",
  notes: "Testing 123",
  notes_url: "",
  action_url: "",
  servicestatus_id: "21",
  instance_id: "1",
  service_object_id: "157",
  status_update_time: "2020-04-06 14:16:30",
  output: "OK - load average: 0.26, 0.32, 0.30",
  long_output: "",
  perfdata:
    "load1=0.260;5.000;10.000;0; load5=0.320;4.000;6.000;0; load15=0.300;3.000;4.000;0;",
  current_state: "0",
  has_been_checked: "1",
  should_be_scheduled: "1",
  current_check_attempt: "1",
  max_check_attempts: "4",
  last_check: "2020-04-06 14:16:30",
  next_check: "2020-04-06 14:21:30",
  check_type: "0",
  check_options: "0",
  last_state_change: "2020-02-01 16:10:38",
  last_hard_state_change: "2020-02-01 16:10:38",
  last_hard_state: "0",
  last_time_ok: "2020-04-06 14:16:30",
  last_time_warning: "1969-12-31 18:00:00",
  last_time_unknown: "1969-12-31 18:00:00",
  last_time_critical: "1969-12-31 18:00:00",
  state_type: state_type,
  last_notification: "1969-12-31 18:00:00",
  next_notification: "1969-12-31 18:00:00",
  no_more_notifications: "0",
  notifications_enabled: "1",
  problem_has_been_acknowledged: "0",
  acknowledgement_type: "0",
  current_notification_number: "0",
  passive_checks_enabled: "1",
  active_checks_enabled: "1",
  event_handler_enabled: "1",
  flap_detection_enabled: "1",
  is_flapping: "0",
  percent_state_change: "0",
  latency: "0.00219999998807907",
  execution_time: "0.00254",
  scheduled_downtime_depth: "0",
  failure_prediction_enabled: "0",
  process_performance_data: "1",
  obsess_over_service: "1",
  modified_service_attributes: "0",
  event_handler: "",
  check_command: "check_local_load!5.0,4.0,3.0!10.0,6.0,4.0\\!test!!!!!!",
  normal_check_interval: "5",
  retry_check_interval: "1",
  check_timeperiod_object_id: "129",
});

export const hostExamples: Host[] = ["5", "0", "0", "0"].map((number) =>
  createHostObject(number)
);

export const serviceExamples: Service[] = ["5", "0", "2", "3"].map((number) =>
  createServiceObject(number)
);

export const returnHostObject = (value: string) =>
  createNagiosObject(
    hostExamples.filter(({ state_type }) => state_type === value)
  );

export const returnServiceObject = (value: string) =>
  createNagiosObject(
    serviceExamples.filter(({ state_type }) => state_type === value)
  );

export default function WithNagiosContext({ children }: any) {
  return (
    <NagiosContext.Provider
      value={{
        hosts: {
          up: returnHostObject("0"),
          down: returnHostObject("1"),
          unreachable: returnHostObject("2"),
          pending: returnHostObject("3"),
          all: createNagiosObject(hostExamples),
          status: "bad",
          last_updated: new Date(),
        },
        services: {
          ok: returnServiceObject("0"),
          warning: returnServiceObject("0"),
          critical: returnServiceObject("0"),
          unknown: returnServiceObject("0"),
          pending: returnServiceObject("0"),
          all: createNagiosObject(serviceExamples),
          status: "bad",
          last_updated: new Date(),
        },
        nagiosStatus: {
          status: "ok",
          active_host_checks: 1,
          active_service_checks: 1,
          notifications_enabled: 1,
          database_backend_status: 1,
          monitoring_engine_status: 1,
          performance_grapher_status: 1,
          last_updated: "now"
        },

        favorites: ["Test", "Test2"],
        role: "admin",
      }}
    >
      {children}
    </NagiosContext.Provider>
  );
}

export function WithNagiosContextWithRole({ children, role }: any) {
  return (
    <NagiosContext.Provider
      value={{
        hosts: {
          up: returnHostObject("0"),
          down: returnHostObject("1"),
          unreachable: returnHostObject("2"),
          pending: returnHostObject("3"),
          all: createNagiosObject(hostExamples),
          status: "bad",
          last_updated: new Date(),
        },
        services: {
          ok: returnServiceObject("0"),
          warning: returnServiceObject("0"),
          critical: returnServiceObject("0"),
          unknown: returnServiceObject("0"),
          pending: returnServiceObject("0"),
          all: createNagiosObject(serviceExamples),
          status: "bad",
          last_updated: new Date(),
        },
        nagiosStatus: {
          status: "ok",
          active_host_checks: 1,
          active_service_checks: 1,
          notifications_enabled: 1,
          database_backend_status: 1,
          monitoring_engine_status: 1,
          performance_grapher_status: 1,
          last_updated: "now"
        },

        favorites: ["Test", "Test2"],
        role: role,
      }}
    >
      {children}
    </NagiosContext.Provider>
  );
}
