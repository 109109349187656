import { Card as AntCard, Col, Row } from "antd";
import styled from "styled-components";
import { Dashlet } from "../../../DashboardPage/DashboardPageTypes";
import { CardTitle } from "../../../Shared";
import useDashletObject from "../../hooks/useDashletObject";

export type Props = {
  dashlet: Dashlet;
  initalData?: any;
  isCreate: boolean;
};

type CardProps = {
  color: string;
};

const Card = styled(AntCard)`
  background: ${({ color }: CardProps) => color};
  height: inherit;
  width: inherit;
`;

export default function DashletPreview({
  dashlet,
  initalData,
  isCreate,
}: Props) {
  const { name, type } = dashlet;
  const dashletTypeObject = useDashletObject(isCreate);

  return (
    <>
      <p>Preview</p>
      <Card color={dashlet.color}>
        <Row>
          <Col span={23}>
            <CardTitle>{name}</CardTitle>
          </Col>
        </Row>
        <Row
          style={{
            height: `500px`,
            width: "inherit",
          }}
        >
          {dashletTypeObject[type]}
        </Row>
      </Card>
    </>
  );
}
